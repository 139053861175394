import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { IconWrapper } from './Controls';
import onClickOutside from 'react-onclickoutside';


const SwitchWrapper = styled('button')(({ theme, active }) => ({
  height: 30,
  width: '100%',
  maxWidth: 118,
  background: active ? theme.blue.color : theme.primary.color,
  padding: 0,
  border: 'none',
  position: 'relative',
  cursor: 'pointer',
  transition: 'background 200ms',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  willChange: 'background, left, width',
  '&:active .fob': {
    width: 45,
    transform: `translateX(${active ? 80 : 0}px)`
  },
  '&:focus': {
    outline: 'none'
  }
}));

const SwitchFob = styled('div')(({ theme, active }) => ({
  height: 30,
  width: 27,
  background: active ? '#fff' : theme.primary.lightMedium,
  position: 'absolute',
  top: 0,
  left: 0,
  transform: active ? 'translateX(91px)' : '',
  transition: 'transform 200ms, width 200ms, background 200ms'
  // willChange: 'left, width, background'
}));

const SwitchLabel = styled('span')(({ theme, active }) => ({
  color: active ? '#fff' : theme.primary.lightMedium,
  transition: 'transform 200ms, color 200ms',
  paddingLeft: 30,
  transform: active ? 'translateX(-30px)' : '',
  // paddingRight: active ? 30 : 0,
  fontSize: 14
  // willChange: 'padding, color'
}));

export class SidebarSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: props.active };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.cache) {
      if (prevProps.active !== this.props.active) {
        this.setState({ active: prevProps.active });
      }
    }
  }

  static defaultProps = {
    onChange: () => {},
    onLabel: '',
    offLabel: ''
  }

  setActive = () => {
    if (this.props.cache) {
      this.setState(state => ({ active: !state.active }));
    }
    this.props.onChange(!this.props.active);
  }

  render() {
    const { onLabel, offLabel, cache } = this.props;
    const active = cache ? this.state.active : this.props.active;
    return (
      <SwitchWrapper active={active} onClick={this.setActive}>
        <SwitchFob className="fob" active={active} />
        <SwitchLabel active={active}>{active ? onLabel : offLabel}</SwitchLabel>
      </SwitchWrapper>
    );
  }
}





const SelectWrapper = styled('div')({
  width: 125,
  height: 30,
  position: 'relative'
});

const Selected = styled('button')(({ theme }) => ({
  zIndex: 9,
  background: theme.blue.color,
  width: '100%',
  height: 30,
  border: 'none',
  fontSize: 14,
  color: '#fff',
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  '&:hover': {
    background: theme.blue.light
  },
  '&:focus': {
    outline: 'none'
  }
}));

const ValueWrapper = styled('span')({
  marginTop: 2
});

const DropdownWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 2
});

const Option = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  width: '100%',
  height: 30,
  background: theme.blue.color,
  color: '#fff',
  fontSize: 14,
  border: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  transition: 'transform 200ms',
  willChange: 'transform',
  '&:hover': {
    background: theme.blue.light
  },
  '&:focus': {
    outline: 'none'
  }
}));

export class SidebarSelect extends React.Component {
  static defaultProps = {
    value: '',
    icon: null,
    options: [],
    onChange: () => {}
  }
  constructor(props) {
    super(props);
    this.state = { open: false, value: props.value };
  }
  componentDidUpdate = prevProps => {
    if (this.props.cache) {
      if (prevProps.value !== this.props.value) {
        this.setState({ value: prevProps.value });
      }
    }
  }
  handleClickOutside = () => {
    this.setState({ open: false });
    this.props.disableOnClickOutside();
  }
  toggle = () => {
    if (this.state.open) {
      this.props.disableOnClickOutside();
    } else {
      this.props.enableOnClickOutside();
    }
    this.setState(state => ({ open: !state.open }));
  }
  select = value => {
    if (this.props.cache) {
      if (value !== this.state.value) {
        this.props.onChange(value);
      }
      this.setState({
        open: false,
        value
      });
      this.props.disableOnClickOutside();
    } else {
      this.setState({ open: false });
      if (value !== this.props.value) {
        this.props.onChange(value);
      }
      this.props.disableOnClickOutside();
    }
  }
  render() {
    const { icon, options, cache } = this.props;
    const value = cache ? this.state.value : this.props.value;
    const selected = options.find(opt => opt.value === value);
    const selectedCss = selected
      ? {
        background: selected.color,
        '&:hover': { background: selected.hoverColor }
      }
      : {};
    return (
      <SelectWrapper>
        <Selected onClick={this.toggle} className={css(selectedCss)}>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <ValueWrapper>{selected ? selected.label : ''}</ValueWrapper>
        </Selected>
        <DropdownWrapper>
          {options.map((opt, i) => {
            const cssOverrides = opt.color
              ? {
                background: opt.color,
                '&:hover': { background: opt.hoverColor }
              }
              : {};
            return (
              <Option
                onClick={() => {
                  this.select(opt.value);
                }}
                style={{
                  transform: this.state.open
                    ? `translateY(${(i + 1) * 30}px)`
                    : '',
                  zIndex: options.length - i,
                  transitionDelay: this.state.open
                    ? `${i * 30}ms`
                    : `${(options.length - i) * 30}ms`
                }}
                className={css(cssOverrides)}
                key={opt.value}
              >
                {opt.label}
              </Option>
            );
          })}
        </DropdownWrapper>
      </SelectWrapper>
    );
  }
}
SidebarSelect = onClickOutside(SidebarSelect);


const TextAreaWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

const TextArea = styled('textarea')(
  {
    width: '100%',
    height: '100%',
    minHeight: 120,
    lineHeight: 1.5,
    border: 'none',
    padding: 10,
    fontSize: 12,
    resize: 'vertical',
    fontWeight: 400
  },
  ({ theme, light }) => ({
    color: theme.primary.textOn,
    background: light ? theme.primary.medium : theme.primary.color
  })
);

const TextAreaLabel = styled('label')(
  {
    fontSize: 12,
    padding: 5,
    fontWeight: 200
  },
  ({ theme }) => ({
    color: theme.primary.extraExtraLight
  })
);

export class SidebarTextArea extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  static defaultProps = {
    label: '',
    placeholder: ''
  }

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value
      });
    }
  }
  setValue = e => {
    this.setState({
      value: e.target.value
    });
  }
  pushValue = () => {
    this.props.onBlur(this.state.value);
  }
  render() {
    const { label, placeholder, light } = this.props;
    return (
      <TextAreaWrapper>
        {label !== '' && <TextAreaLabel>{label}</TextAreaLabel>}
        <TextArea
          light={light}
          placeholder={placeholder}
          value={this.state.value || ''}
          onChange={this.setValue}
          onBlur={this.pushValue}
        />
      </TextAreaWrapper>
    );
  }
}
