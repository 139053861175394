const initialState = {
  cameFrom: null
};

const SystemAdminReducer = (state=initialState, action) => {
  switch(action.type){
  case 'SA.SET_CAME_FROM':
    return {...state, cameFrom: action.cameFrom};
  default:
    return state;
  }
};

export default SystemAdminReducer;
