import React, { Fragment } from 'react';
import Gallery from './Gallery';
import styled from '@emotion/styled';

export default class ThumbnailGallery extends React.Component {
  state = {
    galleryOpen: false,
    targetIndex: 0
  }
  openGallery = ({ index }) => {
    this.setState({
      galleryOpen: true,
      targetIndex: index
    });
  }
  closeGallery = () => {
    this.setState({ galleryOpen: false });
  }
  render() {
    const images = this.props.images || {thumbs: [], full: []};
    return (
      <Fragment>
        <ThumbsWrapper>
          {images.thumbs.map((thumb, i) => (
            <Thumb
              src={thumb}
              index={i}
              openGallery={this.openGallery}
              key={i}
            />
          ))}
        </ThumbsWrapper>
        <Gallery
          images={images.full}
          isOpen={this.state.galleryOpen}
          targetIndex={this.state.targetIndex}
          onModalClose={this.closeGallery}
        />
      </Fragment>
    );
  }
}

const ThumbsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%'
});

class Thumb extends React.Component {
  openGallery = () => {
    const { width, height, x, y } = this.wrapper.getBoundingClientRect();
    const dimensions = { width, height, x, y };
    this.props.openGallery({
      index: this.props.index,
      dimensions
    });
  }
  render() {
    const { src } = this.props;
    return (
      <ThumbWrapper
        ref={ref => {
          this.wrapper = ref;
        }}
      >
        <ThumbImage src={src} onClick={this.openGallery} />
      </ThumbWrapper>
    );
  }
}

const ThumbWrapper = styled('div')({
  width: 'calc(25% - 10px)',
  padding: 10
});

const ThumbImage = styled('img')({
  width: '100%',
  borderRadius: 6,
  transition: 'box-shadow 200ms, transform 300ms',
  boxShadow: '0 0px 0px rgba(0,0,0,0.19), 0 0px 0px rgba(0,0,0,0.23)'
}, ({ theme }) => ({
  '&:hover': {
    boxShadow: theme.shadows.d3,
    transition: 'box-shadow 300ms, transform 300ms',
    transform: 'scale(1.03) translateY(-1%)'
  }
}));
