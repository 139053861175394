import React from 'react';
import MuiModal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';

interface ModalProps {
  open: boolean,
  onClose: () => void,
  title: string,
}

export default function Modal(props: React.PropsWithChildren<ModalProps>) {
  return(
    <MuiModal
      open={props.open}
      onClose={props.onClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: {xs: '100%', lg: '80%'},
          height: {xs: '100%', lg: '80%'},
          backgroundColor: 'white',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
        >
          <IconButton
            onClick={props.onClose}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            fontSize="130%"
            fontWeight="600"
            align="center"
            width="100%"
            marginLeft="-40px"
          >
            {props.title.toUpperCase()}
          </Typography>
        </Stack>
        {props.children}
      </Box>
    </MuiModal>
  );
}
