import React from 'react';
import Stock from '@components/Stock.js';

export default function CheckStockOrders(props) {
  return (
    <div>
      <h2>In-Stock</h2>
      {props.orders.map((order) => {
        console.log(order);
        return(
          <div
            key={order}
          >
            <Stock
              document_id={order}
              noTitle={true}
            />
            <hr/>
          </div>
        );
      })}
    </div>
  );
}
