import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import compose from './utils/compose';
import { 
  Route, 
  Switch, 
  withRouter, 
  Redirect, 
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { credentialsHeader, axios } from '@/api.js';
import '@/App.css';
import theme from '@/theme.ts';

import loggedInSuccess from '@utils/auth/loggedInSuccess.js';

import { CommandBox } from '@components/CommandBox';
import ErrorToast from '@components/ErrorToast.js';
import Sidebar from '@components/shell/Sidebar';

import SidebarRoutes from '@components/Containers/auth/SidebarRoutes';

//Pages
import AllOrders from '@pages/WorkCenters/AllOrders';
import Customers from '@pages/Customers/Customers.tsx';
import Calendar from '@pages/Calendar/Calendar';
import Edit from '@pages/Workorder/Edit';
import EmployeeOrders from '@pages/EmployeeOrders/EmployeeOrders.js';
import EULA from './pages/EULA';
import FullWorkorderPDF from '@pages/Workorder/Workorder.FullPDF';
import Inventory from '@pages/Inventory/Inventory.tsx';
import Login from '@pages/Login/Login';
import Metrics from '@pages/Admin/Metrics';
import MyOrders from '@pages/WorkCenters/MyOrders';
import PackagingLabel from '@pages/Labels/Packaging.js';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProductSheets from '@pages/ProductSheets/productSheets';
import Sales from '@pages/Sales/Sales';
import Settings from '@pages/Settings/Settings';
import Stock from '@pages/Inventory/Stock';
import SurveyResults from '@pages/SurveyResults/SurveyResults';
import TagAdmin from '@pages/TagAdmin/TagAdmin';
import Tasks from '@pages/Tasks/Tasks';
import Users from '@pages/Users/Users';
import WorkCenters from '@pages/WorkCenters/WorkCenters';
import WorkorderFooter from '@pages/Workorder/Workorder.Edit.Sidebar.Footer';
import ManageQuickbooks from './pages/Admin/ManageQuickbooks';
import Estimate from './pages/Estimate/Estimate';
import NamesNumsPdf from './pages/Workorder/NamesNumsPdf';

const createdTheme = createTheme(theme);

export const ROUTES = {
  workorder: {
    sidebarFooter: true
  },
  workcenters: {
    allowMini: true
  },
  stock: {
    allowMini: true
  },
  sales: {
    allowMini: true
  },
  calendar: {
    allowMini: true
  },
  admin: {
    allowMini: true
  },
  auth: {
    allowMini: true
  }
};

const noAuthNecessary = [
  'eula', 
  'privacypolicy', 
  //'/password/reset', 
  'password', 
  //'/password/update/:reset_token',
  'login'
];

const noShellRoutes = ['fullpdf', 'customerpdf', 'label', 'playground', 'namesnumspdf'];

function App(props) {
  const [title, setTitle] = useState('Badger Admin');
  const [commandBox, setCommandBox] = useState(false);
  const commandBoxRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if(commandBox === true) {
      commandBoxRef.current.focus();
    }
  }, [commandBox]);

  useEffect(() => {
    // To deal with viewing the commandBox
    window.addEventListener('keydown', (e) => {
      if(e.key === '~') {
        e.preventDefault();
        setCommandBox(!commandBox);
      } else if(e.keyCode === 27) { //27 == ESC
        e.preventDefault();
        setCommandBox(false);
      }
    });

    validateUser();
    getWorkCenters();
  }, []);

  useEffect(() => {
    axios.get('/quickbooks', {
      params: {
        redirect: window.location.href
      }
    })
      .then((res) => {
        if(res.data.newUrl) {
          window.location.href = res.data.newUrl;
        }
      });
  }, [props.user_account_id]);

  function getWorkCenters() {
    if(props.workcenters.length > 0) return;
    axios.get('/workcenters')
      .then((res) => {
        props.dispatch({
          type: 'WORKCENTERS/SET',
          workCenters: res.data.result.sort((a, b) => a.wcord - b.wcord)
        });
      });
  }

  function resolvePathname() {
    const pathname = window.location.pathname.split('/');
    return pathname[1];
  }

  function validateUser() {
    if (!props.user_group_id) {
      const headers = credentialsHeader();
      if (headers.credentials === 'include') axios.defaults.withCredentials = true;

      axios({
        method: 'GET',
        url: '/auth/validate',
        headers,
        data: { token: localStorage.getItem('token') },
      })
        .then(response => {
          loggedInSuccess(response);
        })
        .catch(error => {
          axios({
            method: 'GET',
            url: '/auth/refresh',
            crossDomain: true,
            headers, // include, same-origin, *omit
          })
            .then(response => {
              localStorage.setItem('token', response.token);
              setTimeout(() => {
                loggedInSuccess(response);
              }, 200);
            })
            .catch(error => {
              console.log(error.message ? error.message : 'failed to login.');
            });
          console.log(error.message ? error.message : 'failed to login.');
        });
    }

  }

  const pathname = resolvePathname();
  const currentRoute = ROUTES[pathname] || {};
  const hideShell = noShellRoutes.reduce((bool, rt) => {
    if (window.location.pathname.includes(rt)) {
      return true;
    }
    return bool;
  }, false);

  const {
    user_account_id = null,
    user_group_id = null,
  } = props;

  const loggedIn = user_account_id !== null;
  const isSuperAdmin = user_group_id == '3';

  // This is for routes where it is not necessary to be logged in
  if(noAuthNecessary.includes(location.pathname.split('/')[1])) {
    return (
      <ThemeProvider theme={createdTheme}>
        <Route
          render={props => { 
            return(
              <React.Fragment>
                {loggedIn && <Redirect to="/workcenters/myorders" />}
                <Login {...props} />
              </React.Fragment>
            );
          }}
          path="/login"
          exact
        />
        <Route
          render={(props) => {
            return (
              <React.Fragment>
                {loggedIn && <Redirect to="/workcenters/myorders" />}
                <Login
                  {...props}
                  mode="update"
                />
              </React.Fragment>
            );
          }}
          path="/password/update/:reset_token"
          exact
        />
        <Route
          render={props => (
            <React.Fragment>
              {loggedIn && <Redirect to="/workcenters/myorders" />}
              <Login
                {...props}
                mode="reset"
              />
            </React.Fragment>
          )}
          path="/password/reset"
          exact
        />
        <Route
          render={() => <PrivacyPolicy />}
          path="/privacypolicy"
          exact
        />
        <Route
          render={() => <EULA />}
          path="/eula"
          exact
        />
      </ThemeProvider>
    );
  }

  // If you are not logged in this route will be the one to go
  if(!loggedIn) {
    return (
      <div className="App">
        <ThemeProvider theme={createdTheme}>
          <Login />
        </ThemeProvider>
      </div>
    );
  }

  // Place Routes here that need to be logged in
  return (
    <div className="App">
      <ThemeProvider theme={createdTheme}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {/** This is the sidebar area */}
        {currentRoute.sidebar !== false && !hideShell && (
          <Sidebar
            allowMini={currentRoute.allowMini}
            footer={
              currentRoute.sidebarFooter ? (
                <React.Fragment>
                  <Route
                    loggedIn={loggedIn}
                    path="/workorder/edit/:document_id"
                    component={WorkorderFooter}
                    exact
                  />
                </React.Fragment>
              ) : null
            }
          >
            <SidebarRoutes />
          </Sidebar>
        )}
        <Content
          hasSidebar={currentRoute.sidebar !== false && !hideShell}
          allowMini={currentRoute.allowMini}
        >
          <Switch location={window.location}>
            <Route 
              render={props => 
                <SuperUser
                  {...props}
                  setTitle={setTitle}
                  isSuperAdmin={isSuperAdmin}
                />
              }
              path="/admin"
            />
            <Route
              setTitle={setTitle}
              pageTitle="My Orders"
              component={MyOrders}
              path="/workcenters/myorders"
              exact
            />
            <Route
              setTitle={setTitle}
              pageTitle="Estimate"
              component={Estimate}
              path="/workcenters/estimate"
              exact
            />
            <Route
              setTitle={setTitle}
              pageTitle="Customers"
              component={Customers}
              path="/workcenters/customers"
              exact
            />
            <Route
              setTitle={setTitle}
              pageTitle="Work Order Search"
              component={AllOrders}
              path="/workcenters/all"
            />
            <Route
              render={props => <WorkCenters {...props} setTitle={setTitle} />}
              path="/workcenters/:workCenterId"
            />
            <Route
              render={props => <Edit setTitle={setTitle} {...props} />}
              path="/workorder/edit/:document_id"
              exact
            />
            <Route
              render={props => <Calendar 
                {...props} 
                setTitle={setTitle}
                pageTitle="Calendar"
              /> }
              path="/calendar"
              exact
            />
            <Route
              render={props => <Tasks
                {...props}
                setTitle={setTitle}
                pageTitle="Tasks"
              /> }
              path="/tasks"
              exact
            />
            <Route
              render={props => <Stock
                {...props}
                setTitle={setTitle}
                pageTitle="Stock"
              /> }
              path="/stock"
              exact
            />
            <Route
              render={props => <Sales
                {...props}
                setTitle={setTitle}
                pageTitle="Sales"
              /> }
              path="/sales"
              exact
            />
            <Route
              render={props => <Calendar 
                {...props} 
                setTitle={setTitle}
                pageTitle="Calendar"
              /> }
              path="/calendar/:date"
              exact
            />
            <Route
              component={FullWorkorderPDF}
              path="/workorder/fullpdf/:document_id"
              exact
            />
            <Route
              component={PackagingLabel}
              path="/workorder/label/:document_id"
              exact
            />
            <Route
              render={props => <FullWorkorderPDF {...props} hideProductionNotes={true} />}
              path="/workorder/customerpdf/:document_id"
              exact
            />
            <Route 
              render={props => <NamesNumsPdf {...props} />}
              path="/workorder/namesnumspdf/:document_id"
              exact
            />
            <Route
              render={() => (
                <Redirect to={loggedIn ? '/workcenters/myorders' : '/login'} />
              )}
              path="/"
              exact
            />
          </Switch>
        </Content>
        { commandBox &&
              <CommandBox
                ref={commandBoxRef}
              />
        }
      </ThemeProvider>
      <ErrorToast />
    </div>
  );
}

export default compose(
  withRouter,
  connect((state) => {
    return {
      user_account_id: state.app.user_account_id,
      user_group_id: state.app.user_group_id,
      workcenters: state.workCenters.workCenters
    };
  })
)(App);

const Content = styled('div')({
}, ({hasSidebar}) => ({
  paddingLeft: hasSidebar ? 250 : ''
}), ({allowMini, theme}) => (
  allowMini ?
    {
      transition: 'padding-left 300ms',
      [`${theme.breakpoints.down('lg')}`]: {
        paddingLeft: 0
      }
    }
    : {}
));

const SuperUser = ({setTitle, isSuperAdmin}) => {

  const { path } = useRouteMatch();

  if(!isSuperAdmin) {
    return (
      <Redirect to="/workcenters/1" />
    );
  }

  return (
    <>
      <Route
        render={props =>
          <Users
            {...props}
            setTitle={setTitle}
            pageTitle="Employees"
          />
        }
        path={`${path}/employees`}
        exact
      />
      <Route
        render={props =>
          <EmployeeOrders
            {...props}
            setTitle={setTitle}
            pageTitle="Employee Orders"
          />
        }
        path={`${path}/employeeorders`}
        exact
      />
      <Route
        render={props =>
          <Inventory {...props}
            {...props}
            setTitle={setTitle}
            pageTitle="Inventory"
          />
        }
        path={`${path}/inventory`}
        exact
      />
      <Route
        render={props =>
          <ProductSheets {...props}
            {...props}
            setTitle={setTitle}
            pageTitle="Product Sheets"
          />
        }
        path={`${path}/productsheets`}
        exact
      />
      <Route
        render={props =>
          <TagAdmin 
            {...props}
            setTitle={setTitle}
            pageTitle="Edit Tags"
          />
        }
        path={`${path}/tags`}
        exact
      />
      <Route
        render={props =>
          <Metrics
            {...props}
            setTitle={setTitle}
            pageTitle="Metrics"
          />
        }
        path={`${path}/metrics`}
        exact
      />
      <Route
        render={props =>
          <SurveyResults
            {...props}
            setTitle={setTitle}
            pageTitle="Survey Results"
          />
        }
        path={`${path}/surveyresults`}
        exact
      />
      <Route
        render={props =>
          <Settings
            {...props}
            setTitle={setTitle}
            pageTitle="Settings"
          />
        }
        path={`${path}/settings`}
        exact
      />
      <Route
        render={() => 
          <ManageQuickbooks />
        }
        path={`${path}/manageQuickbooks`}
        exact
      />
    </>
  );
};
