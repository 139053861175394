import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import {
  format,
  isToday,
  areRangesOverlapping,
  isWithinRange,
  isSameDay,
  differenceInDays,
  isBefore,
  addDays
} from 'date-fns';
import theme from '@/theme';
import NewEventModal from './NewEventModal';
import ConnectedFollower from './ConnectedFollower';
import { Days } from '@/data/calendarData';

import Box from '@mui/material/Box';
import Cards from './Cards';

export default class CalendarGrid extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ...this.getNumOverlappingEvents(props.events, props.dateRange),
      cardDrag: false,
      garbageRow: 0
    };
  }

  componentDidUpdate(prevProps) {

    if (this.props.events !== prevProps.events ||
        this.props.tasks !== prevProps.tasks ||
        this.props.pauses !== prevProps.pauses
    ) {

      this.setState({
        ...this.getNumOverlappingEvents(this.props.events, this.props.dateRange)
      });

    }

  }

  getNumOverlappingEvents = (events) => {
    let overlapping = 0;

    events.forEach(e => {
      e.level = 0;
    });

    for (let i = 0; i < events.length - 1; i++) {

      const e1 = events[i];

      if(e1.type === 'Pause' && !this?.props?.pauses) {
        continue;
      } 

      if(e1.type !== 'Pause' && e1.type !== 'Event' && !this?.props?.tasks) {
        continue;
      }

      for (let j = i + 1; j < events.length; j++) {
        const e2 = events[j];
        if (e1.end_date === null) {
          if (e2.end_date === null) {
            if (isSameDay(e1.start_date, e2.start_date)) {
              e2.level = e1.level + 1;
              if (e2.level > overlapping) overlapping = e2.level;
            }
          } else {
            if (isWithinRange(e1.start_date, e2.start_date, e2.end_date)) {
              e2.level = e1.level + 1;
              if (e2.level > overlapping) overlapping = e2.level;
            }
          }
        } else {
          if (e2.end_date === null) {
            if (isWithinRange(e2.start_date, e1.start_date, e1.end_date)) {
              e2.level = e1.level + 1;
              if (e2.level > overlapping) overlapping = e2.level;
            }
          } else {
            if (areRangesOverlapping(e1.start_date, addDays(e1.end_date, 1), e2.start_date, addDays(e2.end_date, 1))) {
              e2.level = e1.level + 1;
              if (e2.level > overlapping) overlapping = e2.level;
            }
          }
        }
      }
    }
    return { events: events, overlappingEvents: overlapping };
  }

  render () {
    const { dateRange, events, reloadEvents, dragDateChange, users, tasks, pauses } = this.props;
    const { overlappingEvents, cardDrag } = this.state;
    return (
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: '70px',
          gridTemplateColumns: '120px repeat(5, minmax(130px, 1fr)) 120px',
          gridTemplateRows: `50px repeat(${overlappingEvents + 2}, 20px)`,
          position: 'relative',
          width: '100%',
        }}
      >
        <HeaderRow
          dateRange={dateRange}
          cardDrag={cardDrag}
          dropHandler={dragDateChange}
        />
        <EventsRows
          events={events}
          overlappingEvents={overlappingEvents}
          dateRange={dateRange}
          reloadEvents={reloadEvents}
          users={users}
          tasks={tasks}
          pauses={pauses}
        />
        <Cards
          match={this.props.match}
          dateRange={dateRange}
          overlappingEvents={overlappingEvents}
          cardDragStart={() => this.setState({ cardDrag: true })}
          cardDragEnd={() => this.setState({ cardDrag: false })}
          reloadCalendar={reloadEvents}
          users={users}
          filterByUser={this.props.filterByUser}
          sortBy={this.props.sortBy}
        />
      </Box>
    );
  }
}

const HeaderRow = (props) => {
  const { dateRange, cardDrag, dropHandler } = props;
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoRows: '70px',
        gridTemplateColumns: '120px repeat(5, minmax(130px, 1fr)) 120px',
        position: 'fixed',
        zIndex: '99',
        width: { xs: '97%', lg: 'calc(97% - 250px)'},
        backgroundColor:'white',
      }}
    >
      {Days.map((val, i) => {
        return (
          <React.Fragment
            key={i}
          >
            <DayName
              key={val}
              style={{
                ...(isToday(dateRange[val]) ? {
                  fontWeight : 'bold',
                  backgroundColor: 'black',
                  color: theme.primary.textOn
                } : {}),
              }}
            >
              {val} - {format(dateRange[val], 'D')}
              {cardDrag &&
               <Droppable
                 onDragOver={(e) => {
                   e.preventDefault();
                 }}
                 onDrop={(e) => {
                   console.log(e);
                   dropHandler(e, dateRange[val]);
                 }}
                 cardDrag={cardDrag}
               />
              }
            </DayName>
          </React.Fragment>
        );
      })}
    </Box>
  );
};

const Droppable = styled('div')({
  height: '90vh',
  width: '100%',
});

const EventBase = styled('div')({
  borderLeftWidth: '4px',
  padding: '2px 2px',
  marginRight: '5px',
  marginLeft: '5px',
  borderLeftStyle: 'solid',
  fontSize: '12px',
  top: '60px',
  position: 'relative',
  borderLeftColor: '#000',
  background: '#333',
  alignSelf: 'center',
  color: '#fff',
  fontWeight: 500,
});

class EventsRows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newEventModalOpen: false,
    };
  }

  closeEventModal = () => {
    this.setState({
      newEventModalOpen: false
    });
  }

  openNewEventModal = (e, val) => {

    const page = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    const left = ((page.width - e.clientX) < (288 - 30)) ? (e.clientX - 288 - 30) : e.clientX;
    this.setState({
      newEventModalOpen: true,
      newEventModalStyle: {
        left,
        top: e.clientY
      },
      editEvent: val
    });

  }

  render () {
    const { events, dateRange, reloadEvents, users, tasks, pauses } = this.props;
    return (
      <>
        {events.map((val) => {
          let start = 0;
          if (!isBefore(val.start_date, dateRange.startOfWeek)) {
            start = Number(format(val.start_date, 'd'));
          }
          let difference = 0;
          if (!isBefore(val.start_date, dateRange.startOfWeek)) {
            difference = differenceInDays(val.end_date, val.start_date);
          } else {
            difference = differenceInDays(val.end_date, dateRange.startOfWeek);
          }

          if(!tasks && val.type !== 'Event' && val.type !== 'Pause') {
            return;
          } else if(!pauses && val.type === 'Pause') {
            return;
          } else {

            return (
              <EventBase
                style={{
                  background: val.type === 'Event' ? '#333' : val.type === 'Pause' ? '#F27165' : '#90BAE3',
                  color: val.type === 'Event' ? '#fff' : '#020202'

                }}
                className={css({
                  gridRow: `${val.level + 2 /*+ (start === 3 ? 1 : 0)*/}`,
                  gridColumn: `${start + 1}${val.end_date ? `/span ${difference + 1}` : ''}`
                })}
                key={val.calendar_event_id}
                onClick={(e) => this.openNewEventModal(e, val)}
              >
                {val.type === 'Event' ? val.note : val.type}
                {val.user_id && 
                  <ConnectedFollower
                    user_id={val.user_id}
                    size="x-small"
                    align="right"
                  />
                }
              </EventBase>
            );
          }
        })}
        <EventBase
          style={{
            background: '#957DA8',
            color: '#020202'
          }}
          className={css({
            gridColumn: 4
          })}
        >Garbage {format(this.props.dateRange.wednesday, 'W') % 2 !== 0 ? ' & Recycling ' : ''}</EventBase>
        <NewEventModal
          isOpen={this.state.newEventModalOpen}
          onModalClose={this.closeEventModal}
          onNewEvent={this.selectDueDate}
          style={this.state.newEventModalStyle}
          users={users}
          editEvent={this.state.editEvent}
          reloadEvents={reloadEvents}
        />
      </>
    );
  }
}

const DayName = styled('div') ({
  fontSize: '14px',
  textTransform: 'uppercase',
  textAlign: 'center',
  borderBottom: '1px solid rgba(166, 168, 179, 0.62)',
  lineHeight: '50px',
  fontWeight: 500,
},
({ theme, cardDrag }) => ({
  color: theme.primary.color,
  ...(cardDrag ? {
    borderBottomWidth: '4px',
    borderBottomStyle: 'dotted',
    '&:hover': {background: 'black'}
  } : {}),
}));
