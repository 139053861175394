import React, { useState, useEffect } from 'react';
import Modal from '../../Reusable/layout/Modal.tsx';
import { axios } from '../../../api';
import { Box, Button, Stack, Typography } from '@mui/material';
import designGroup from '../../../types/models/designGroup.ts';
import { Loading } from '../../Controls.js';

interface InvoicesProps { 
  document_id: number,
  open: boolean,
  onClose: () => void
}

export default function Invoices(props: InvoicesProps) {

  const [designs, setDesigns] = useState<designGroup[]>([]);
  const [quickbooksCustomerAccount, setQuickbooksCustomerAccount] = useState<number | undefined | null>();
  const [quickbooksInvoiceId, setQuickbooksInvoiceId] = useState<string | undefined | null>();
  const [itemsWithoutQBId, setItemsWithoutQBId] = useState<string[]>([]);
  const [creatingInvoice, setCreatingInvoice] = useState<boolean>(false);

  useEffect(() => {

    if(!props.open) {
      setItemsWithoutQBId([]);
    }

    loadDocument();

    axios.get('/lines', {
      params: {
        document_id: props.document_id
      }
    })
      .then((res) => {
        setDesigns(res.data.results);
      });

  }, [props.open]);

  useEffect(() => {
    checkItemsForQBId();
  }, [designs]);

  function loadDocument(): void {
    axios.get('/document', {
      params: {
        id: props.document_id,
        attributes: ['quickbooksAccount', 'quickbooksInvoiceId']
      }
    })
      .then((res) => {
        setQuickbooksCustomerAccount(Number(res.data.document.quickbooksAccount));
        setQuickbooksInvoiceId(res.data.document.quickbooksInvoiceId);
      });
  }

  function disableCreateInvoice(): boolean { return itemsWithoutQBId.length > 0 || !quickbooksCustomerAccount; }

  function checkItemsForQBId(): any[] {
    const itemsNeedingId: string[] = [];
    const itemsThatDontHaveId: string[] = [];
    const getItemsPromises: Promise<any>[] = [];
    for(const design of designs) {
      for(const line of design.orderLines) {
        const itemString = line.stylenumber;
        if(!itemString) continue;

        if(!itemsNeedingId.includes(itemString)) {
          itemsNeedingId.push(itemString);
          getItemsPromises.push(
            axios.get('/inventory/individual', {
              params: {
                stylenumber: line.stylenumber,
              }
            })
              .then((res) => {
                if(!res.data?.result?.QuickbooksItemID) {
                  itemsThatDontHaveId.push(line.stylenumber);
                }
              })
          );
        }

      } 
    }

    Promise.all(getItemsPromises)
      .then(() => {
        setItemsWithoutQBId([...itemsThatDontHaveId]);
      });

    return itemsNeedingId;
  }

  function createInvoice() {
    axios.post('/invoices', {
      document_id: props.document_id
    })
      .then(() => {
        loadDocument();
      });
    
  }

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      title="Invoices"
    >
      <Box
        sx={{ px: 4, py: 2 }}
      >
        <Stack>
          { !quickbooksCustomerAccount && <Typography sx={{ color: 'danger.main' }}>There is not a customer quickbooks account associated with this workorder.</Typography>}
          { itemsWithoutQBId.map((item, i) => {
            return(
              <Typography 
                key={i}
                sx={{ color: 'danger.main' }}
              >Item {item} Does not have a quickbooks ID</Typography> 
            );
          })}
          {!disableCreateInvoice() && 
            <Typography
              sx={{ m: 2 }}
            >No errors with document</Typography>
          }
        </Stack>
        <Button
          disabled={disableCreateInvoice()}
          variant="contained"
          fullWidth
          onClick={createInvoice}
        >Create Invoice</Button>
        {quickbooksInvoiceId &&
          <Button
            href={
              (process.env.NODE_ENV === 'production' 
                ? 'https://app.qbo.intuit.com/app/invoice?txnId='
                :'https://app.sandbox.qbo.intuit.com/app/invoice?txnId=')
                + quickbooksInvoiceId
            }
            fullWidth
            variant="outlined"
            sx={{ mt: 2 }}
          >Go to Invoice</Button>
        }
      </Box>
      <Loading 
        isOpen={creatingInvoice}
      />
    </Modal>
  );
}
