import React from 'react';

export default class ImageFallback extends React.Component{
  state = {errored: false}
  setError = () => {
    this.setState({errored: true});
  }
  render(){
    const { children, fallback } = this.props;
    return(
      this.state.errored ? fallback : children({onError: this.setError})
    );
  }
}
