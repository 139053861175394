import React from 'react';
import millisecondsToHours from 'date-fns/millisecondsToHours';
import millisecondsToMinutes from 'date-fns/millisecondsToMinutes';
import millisecondsToSeconds from 'date-fns/millisecondsToSeconds';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Timer(props) {
  function timeString() {
    const hours = millisecondsToHours(props.timeDiff);
    const minutes = millisecondsToMinutes(props.timeDiff) % 60;
    const seconds = millisecondsToSeconds(props.timeDiff) % 60;

    return `${String(hours).length > 1 ? '' : '0'}${hours}:${String(minutes).length > 1 ? '' : '0'}${minutes}:${String(seconds).length > 1 ? '' : '0'}${seconds}`;
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography
          variant="h1"
        >
          {timeString()}
        </Typography>
      </Box>
    </>
  );

}  
