import {
  QuotesIcon,
  DesignIcon,
  BillingIcon,
  SeparationsIcon,
  OrderIcon,
  ProductionIcon,
  FinishedIcon,
  AllIcon,
  FrontDeskIcon,
  ManagerCheckIcon
} from './common/img/icons/workCenters';

export const testWorkCenters = {
  11: {
    title: 'Alternate',
    id: 'alternate',
    color: '#366089',
    icon: AllIcon
  },
  7: {
    title: 'Quotes',
    id: 'quotes',
    color: '#2ab6f6',
    icon: QuotesIcon
  },
  9: {
    title: 'Front Desk',
    id: 'frontdesk',
    color: '#ff3399',
    icon: FrontDeskIcon
  },
  1: {
    title: 'Design',
    id: 'design',
    color: 'rgb(255, 122, 69)',
    icon: DesignIcon
  },
  10: {
    title: 'Manager Check',
    id: 'managercheck',
    color: '#aaaaaa',
    icon: ManagerCheckIcon
  },
  2: {
    title: 'Billing',
    id: 'billing',
    color: '#9ccc64',
    icon: BillingIcon
  },
  3: {
    title: 'Order',
    id: 'order',
    color: '#ed5851',
    icon: OrderIcon
  },
  4: {
    title: 'Separations',
    id: 'separations',
    color: '#ffeb3b',
    icon: SeparationsIcon
  },
  5: {
    title: 'Production',
    id: 'production',
    color: '#7e58c2',
    icon: ProductionIcon
  },
  6: {
    title: 'Finished',
    id: 'finished',
    color: '#78909c',
    icon: FinishedIcon
  },
  100: {
    title: 'All Orders',
    id: 'all',
    color: '#ffffff',
    icon: AllIcon
  }
};

export const testWorkCenterOrder = [
  'quotes',
  'design',
  'billing',
  'order',
  'separations',
  'production',
  'finished',
  'all'
];
