import React from 'react';
import styled from '@emotion/styled';
import { Portal } from 'react-portal';

const PageWrapper = styled('div')({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 99
});

const Shade = styled('div')({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.3)',
  zIndex: -1
});

export default class ModalStateController extends React.Component {
  static defaultProps = {
    enterAnimationClassName: 'fade-in',
    exitAnimationClassName: 'fade-out'
  }

  constructor(props) {
    super(props);
    this.state = {
      exiting: false,
      isOpen: props.isOpen || false
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isOpen === true &&
       this.props.isOpen === false &&
       !this.state.exiting) {
      this.startModalClose();
    }

    if(!prevProps.isOpen && this.props.isOpen === true) {
      this.openModal();
    }
  }

  getWrapperProps = () => {
    return {
      className: this.state.exiting
        ? this.props.exitAnimationClassName
        : this.props.enterAnimationClassName,
      onAnimationEnd: this.state.exiting ? this.closeModal : undefined
    };
  }
  openModal = () => {
    this.setState(() => ({
      isOpen: true,
      exiting: false
    }));
  }
  startModalClose = () => {
    this.setState(() => ({
      exiting: true
    }));
  }
  closeModal = () => {
    this.setState(() => ({
      exiting: false,
      isOpen: false
    }));
    if (this.props.onModalClose) {
      this.props.onModalClose();
    }
  }
  render() {
    const {
      children,
      closeOnClickOutside,
      shadeStyle = {},
      shade,
      wrapperStyle = {}
    } = this.props;
    return (
      this.state.isOpen && (
        <Portal>
          <PageWrapper style={wrapperStyle}>
            {children({
              getProps: this.getWrapperProps,
              closeModal: this.startModalClose,
              exiting: this.state.exiting
            })}
            {shade !== false && (
              <Shade
                onClick={
                  closeOnClickOutside === false
                    ? undefined
                    : this.startModalClose
                }
                style={shadeStyle}
                className={this.state.exiting ? 'fade-out' : 'fade-in'}
              />
            )}
          </PageWrapper>
        </Portal>
      )
    );
  }
}
