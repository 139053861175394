import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';

export default function StaticLines({ lines, template, allowNewLine, separatorLines = false }) {
  let colorBit = true;
  let backgroundColor = null;
  return (
    <Table
      id="lines"
    >
      <thead>
        <TableRow>
          {template.map(t => (
            <TableHeadCell key={t.id}>{t.label}</TableHeadCell>
          ))}
        </TableRow>
      </thead>
      <tbody>
        {lines.map((row, i) => {

          if(separatorLines) {
            let compareIndex = i - 1;
            const design = row.printoption;
            if(i !== 0 && row.printoption !== undefined) {

              while(lines[compareIndex].printoption === undefined && compareIndex > 0) {
                compareIndex--;
              }

              if(lines[compareIndex].printoption !== design) {
                colorBit = !colorBit;
              }

            }
            backgroundColor = css({
              borderTop: '1px dotted black',
              borderBottom: '1px dotted black',
              background: colorBit === true ? 'rgba(0, 0, 0, 0.1)' : 'rgb(255, 255, 255)',
              '&:nth-of-type(even)': ''
            });
          }
          return (
            <TableRow
              key={i}
              className={backgroundColor}
            >
              {template.map(t => {
                return (
                  <TableCell
                    style={t.maxWidth ? { width: t.maxWidth } : {}}
                    className={backgroundColor}
                    key={t.id}
                  >
                    <div style={allowNewLine ? { whiteSpace: 'pre-wrap' } : {}}>
                      {t.format === 'currency' ? '$' : ''}
                      {row[t.id]}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
}

const Table = styled('table')({
  margin: '0px auto',
  borderCollapse: 'collapse',
  borderBottom: '1px solid #444',
  width: '100%',
});

const TableRow = styled('tr')({
  '&:nth-of-type(even)': {
    background: 'rgba(0, 0, 0, 0.1)',
  },
});

const TableCell = styled('td')({
  // border: '1px solid rgb(208, 208, 208)',
  borderLeft: '1px solid #444',

  borderRight: '1px solid #444',
  padding: '7px 5px',
  fontSize: 12,

  // textAlign: 'center',
  verticalAlign: 'middle',
});

const TableHeadCell = styled('th')(
  {
    fontWeight: 400,
    padding: 5,
    fontSize: 13,

    '&:first-of-type': {
      borderTopLeftRadius: 5,
    },

    '&:last-child': {
      borderTopRightRadius: 5,
    },
  },
  ({ theme }) => ({
    background: theme.primary.color,
    color: theme.primary.textOn,
  })
);
