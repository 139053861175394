import React, { useState, useEffect } from 'react';

import Approval from '@components/Containers/settings/Approval.js';
import OldPricing from '@components/Containers/settings/OldPricing.js';
import Email from '@components/Containers/settings/Email.js';
import Calendar from '@components/Containers/settings/Calendar.js';
import Inventory from '@components/Containers/settings/Inventory.js';
import PricingSettings from '@components/Containers/settings/PricingSettings.js';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

export default function Settings(props) {

  const [ openTab, setOpenTab ] = useState('approval');

  useEffect(() => {
    const { setTitle = () => {}, pageTitle } = props;
    setTitle(pageTitle);
  }, []);

  return (
    <Box>
      <Box>
        <Tabs
          value={openTab}
          onChange={(_, value) => { setOpenTab(value); }}
          variant="fullWidth"
        >
          <Tab label="Approval" value="approval"/>
          <Tab label="Pricing" value="pricing"/>
          <Tab label="Email" value="email"/>
          <Tab label="Calendar" value="calendar"/>
          <Tab label="Inventory" value="inventory"/>
        </Tabs>
      </Box>

      <TabPanel value={openTab} index="inventory">
        <Inventory />
      </TabPanel>

      <TabPanel value={openTab} index="calendar">
        <Calendar/>
      </TabPanel>

      <TabPanel value={openTab} index="email">
        <Email/>
      </TabPanel>

      <TabPanel value={openTab} index="approval">
        <Approval/>
      </TabPanel>

      <TabPanel value={openTab} index="pricing">
        <PricingSettings/>
      </TabPanel>
    </Box>
  );

}

// Taken straight from mui.com
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
