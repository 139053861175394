import React from 'react';
import styled from '@emotion/styled';

const Icon = styled('svg')({
  width: '100%'
});

export const CalendarIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0"
    y="0"
    viewBox="0 0 73.05 73.05"
    enableBackground="new 0 0 73.053 73.053"
    style={{ fill: color }}
  >
    <path d="M36.53,0A36.53,36.53,0,1,0,73.05,36.53,36.57,36.57,0,0,0,36.53,0ZM37,66.34A29.82,29.82,0,1,1,66.79,36.53,29.85,29.85,0,0,1,37,66.34Z"/>
    <path d="M52.63,18.08H49V15.17a2.79,2.79,0,1,0-5.57,0v2.91H39.31V15.17a2.79,2.79,0,1,0-5.57,0v2.91h-4.9V15.17a2.79,2.79,0,1,0-5.57,0v2.91H20.42a3.34,3.34,0,0,0-3.33,3.34v6h0V53.62A3.33,3.33,0,0,0,20.42,57H52.63A3.33,3.33,0,0,0,56,53.62V27.41h0v-6A3.34,3.34,0,0,0,52.63,18.08ZM45,15.17a1.18,1.18,0,1,1,2.36,0V21A1.18,1.18,0,1,1,45,21Zm-9.68,0a1.18,1.18,0,1,1,2.35,0V21a1.18,1.18,0,1,1-2.35,0ZM24.88,18.08V15.17a1.18,1.18,0,1,1,2.36,0V21a1.18,1.18,0,0,1-2.36,0ZM53.6,53.62a1,1,0,0,1-1,1H20.42a1,1,0,0,1-1-1V27.41H53.6Z"/>
    <path d="M23.72,33a2.74,2.74,0,0,0,.69.45,2.09,2.09,0,0,0,.81.17H31.5s0,.06.07.06v.16l-.59.95-.59.92-.55.91-.59,1L29,38l-.26.39-.26.43-.26.42a3.51,3.51,0,0,0-.33.88A2,2,0,0,0,28,41a2.37,2.37,0,0,0,.46.78,2.1,2.1,0,0,0,.75.52l.43.2.45.16.43.17.42.19h0l.06,0h.07l.06,0a4.93,4.93,0,0,1,1.25.65,3.58,3.58,0,0,1,.94.92,2.41,2.41,0,0,1,.43,1.18,2.69,2.69,0,0,1-.33,1.4,2.48,2.48,0,0,1-.91,1,3.51,3.51,0,0,1-1.28.42,4,4,0,0,1-1.37-.13,3.25,3.25,0,0,1-1.18-.65,1.84,1.84,0,0,1-.23-.27.83.83,0,0,1-.23-.26c0-.06-.1-.16-.16-.26a.89.89,0,0,0-.13-.23,2.17,2.17,0,0,0-.36-.55,1.72,1.72,0,0,0-.46-.4,2.36,2.36,0,0,0-.59-.26,2.19,2.19,0,0,0-1.76.2,2.33,2.33,0,0,0-1.15,1.76,2.38,2.38,0,0,0,.2,1.15,5.5,5.5,0,0,0,.39.72,7.38,7.38,0,0,0,.46.68,7.54,7.54,0,0,0,.55.59,5,5,0,0,0,.66.56,6.08,6.08,0,0,0,1.63.91,6.55,6.55,0,0,0,1.8.53,9,9,0,0,0,1.87.13A11.89,11.89,0,0,0,33,52.65,7.63,7.63,0,0,0,34.67,52a6.48,6.48,0,0,0,1.41-1,6.64,6.64,0,0,0,1.11-1.31,5.53,5.53,0,0,0,.75-1.57,7.19,7.19,0,0,0,.36-1.73,7.24,7.24,0,0,0-.55-3.5,6.15,6.15,0,0,0-1-1.47l-.72-.69a6.89,6.89,0,0,0-.82-.55,4.62,4.62,0,0,0-.68-.43c-.23-.13-.46-.23-.72-.36a.32.32,0,0,1-.17-.19.26.26,0,0,1,0-.26L35,36.89l1.11-1.8L37,33.75l.3-.53a2.16,2.16,0,0,0,.32-1.34,2.66,2.66,0,0,0-1.24-2.15A2.63,2.63,0,0,0,35,29.37H25.22a2.09,2.09,0,0,0-.81.16,2.52,2.52,0,0,0-.69.46,1.86,1.86,0,0,0-.46.68,2.54,2.54,0,0,0-.16.82,2.49,2.49,0,0,0,.16.82A2,2,0,0,0,23.72,33Z"/>
    <path d="M42.75,33.62h.49a.32.32,0,0,1,.33.32V50.32a2.22,2.22,0,0,0,.59,1.51,2.09,2.09,0,0,0,1.5.62h.53a2.11,2.11,0,0,0,1.5-.62,2.25,2.25,0,0,0,.62-1.51V31.49a2.22,2.22,0,0,0-.62-1.5,2.11,2.11,0,0,0-1.5-.62H42.75a2.12,2.12,0,0,0-2.12,2.12A2.13,2.13,0,0,0,41.25,33,2.09,2.09,0,0,0,42.75,33.62Z"/>
  </Icon>
);
