import React from 'react';
import { connect } from 'react-redux';
import { css } from 'emotion';
import styled from '@emotion/styled';
import FilterBar from '@components/Collections/FilterBar';
import {
  setFilter,
  clearFilter,
} from '@components/Collections/FilterBar.actions';
import Table from '@components/Collections/Table';
import { Wrapper } from '@components/shell/Shell';
import InfiniteScroll from 'react-infinite-scroller';
import { Tag } from '@components/Tags';
import { 
  format, 
  differenceInDays,
  min
} from 'date-fns';
import theme from '@/theme.js';
import WorkcentersMenu from './WorkcentersMenu';
import FloatingActionButton from '@components/FloatingActionButton';
import { Follower, FollowersWrapper } from '@pages/Workorder/Followers';
import { axios } from '@/api.js';
import OrderIcon from 'react-icons/lib/md/list';
import PrintIcon from '@material-ui/icons/Print';
import { Checkbox, Loading } from '@components/Controls';
import Modal from '@components/Modal';
import CheckStockOrders from '@components/Containers/stock/CheckStockOrders';

const enableInfiniteScroll = id => ['7', '6'].includes(id);

export const Jobname = styled('div')(({ theme, rush, must }) => ({
  color: rush ? theme.danger.color : must ? theme.orange.color : '#000',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
  '& span': {
    marginRight: 5,
    marginLeft: 0,
  },
}));

class WorkCenters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingStock: false,
      contextMenuOpen: false,
      contextMenuProps: {},
      documents: [],
      infinite: enableInfiniteScroll(props.match.params.workCenterId),
      fetching: false,
      orderList: [],
      lastOrder: null,
      loading: false,
      documentsCount: 1,
      workcenters: []
    };
  }

  componentDidMount() {
    this.props.setTitle(`Work Center - ${this.props.match.params.workCenterId}`);
    this.getWorkcenters();
    if(!this.state.infinite) {
      this.getDocumentsByWorkcenter();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const workcenterHasChanged = 
      this.props.match.params.workCenterId 
      !== prevProps.match.params.workCenterId;

    if (workcenterHasChanged) {
      this.props.dispatch({
        type: 'WCP.SET_ACTIVE_WORKCENTER',
        activeWorkcenter: this.props.match.params.workCenterId,
      });

      this.setState({ infinite: enableInfiniteScroll(this.props.match.params.workCenterId) }, () => {
        this.reloadDocuments();
      });

      this.props.setTitle(`Work Center - ${this.props.match.params.workCenterId}`);
    }
  }

  closeContextMenu = () => {
    this.setState({ contextMenuOpen: false });
  }

  createNewOrder = () => {
    axios({
      method: 'POST',
      url: '/document',
      crossDomain: true,
    }).then(response => {
      this.props.history.push(`/workorder/edit/${response.data.document_id}`);
    });
  };

  createOrderSheet = async () => {
    this.setState({ checkingStock: true });
    if (this.state.orderList.length > 0) {
      await this.downloadOrderSheet(this.state.orderList,
        (res) => {
          console.log(res.data);
        },
        (err) => {
          console.error(err);
        });
    }
  };

  downloadOrderSheet = async (documents, success, error) => {
    const onComplete = e => {
      console.log(e);
      const blobUrl = window.URL.createObjectURL(
        new Blob([e.data], {
          type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      );
      const a = document.createElement('a');
      a.download = 'order-sheet.xlsx';
      a.href = blobUrl;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      success(e);
    };
  
    const data = {documents: documents};

    var config = {
      responseType: 'blob'
    };

    axios.post('/ordersheet', data, config)
      .then(onComplete)
      .catch(error);
  };

  editWorkorder = ({ document_id }) => {
    this.props.history.push(`/workorder/edit/${document_id}`);
  };

  fetchMoreDocuments = () => {
    if(this.state.documents.length >= this.state.documentsCount || this.state.fetching) return;
    this.getDocumentsByWorkcenter();
  }

  getWorkcenters = () => {
    axios.get('/workcenters')
      .then((res) => {
        this.setState({ workcenters: res.data.result });
      });
  }

  getActiveWorkCenter = () => {
    return this.state.workcenters.length
      ? this.state.workcenters.find(
        wc => this.props.match.params.workCenterId === wc.workcenter_id.toString()
      )
      : {};
  };

  getDocumentsByWorkcenter = () => {
    if(this.state.infinite && (this.state.documents.length >= this.state.documentsCount || this.state.fetching)) return;
    this.setState({ fetching: true }, () => {
      let orderBy = localStorage.getItem('sort');
      orderBy = (orderBy !== 'followers' ? orderBy : undefined);
      let orderByDir;
      if(orderBy) {
        orderByDir = (localStorage.getItem('down') === 'true' ? 'DESC' : 'ASC');
      }

      axios.get('/workcenters/documents', {
        params: {
          workcenter: this.props.match.params.workCenterId,
          offset: this.state.documents.length,
          orderBy,
          sortDir: orderByDir,
          limit: this.state.infinite ? 50 : -1
        }
      })
        .then((res) => {
          const docs = [...this.state.documents, ...res.data.result];
          if(localStorage.getItem('sort') === 'followers') {
            docs.sort(this.sortByFollowers);
          }
          this.setState({ documents: docs, fetching: false });
        });
    });
  };

  getDocumentsCount = () => {
    axios.get('/workcenters/documents/count', {
      params: {
        workcenter: this.props.match.params.workCenterId
      }
    })
      .then((res) => {
        this.setState({ documentsCount: res.data.result, documents: [] });
      });
  };

  getDocumentContactBackground = (documents, workcenter) => {
    documents = this.getFilteredRows(documents);

    if(workcenter === '1' || workcenter === '10' || workcenter === '2') {
      documents = documents.map(document => {
        let background = '';
        const dayDiff = differenceInDays(Date.now(), document.history?.[0]?.timestamp);
        const creationDiff = differenceInDays(Date.now(), document.createdAt);

        if(creationDiff < 3) {
          return {...document, background};
        }

        if(dayDiff > 4) {
          background = '#ff7a45';
        } else if(dayDiff > 3) {
          background = '#ffd46f';
        } else if(dayDiff > 2) {
          background = '#eaba95';
        }
        return {...document, background};
      });
    }

    return documents;
  };

  getFilteredRows = rows => {
    const filter = this.props.filter.toLowerCase();
    if (filter !== '') {
      return rows.filter(
        row =>
          typeof row.jobname === 'string'
            ? row.jobname.toLowerCase().includes(filter)
            : false
        // row.contact.toLowerCase().includes(filter)
      );
    } else {
      return rows;
    }
  };

  openContextMenu = (row, contextMenuProps) => {
    this.setState({ contextMenuOpen: true, contextMenuProps });
  };

  openContextMenu = (row, contextMenuProps) => {
    this.setState({ contextMenuOpen: true, contextMenuProps });
  }

  onSelectChange = () => { 
    this.reloadDocuments(); 
  };

  printAllPdfs = async () => {
    if(this.state.orderList.length > 0) {
      this.setState({ loading: true });
      axios({
        method: 'post',
        data: {
          doc_ids: this.state.orderList
        },
        url: '/exportbulkproductionpdfs',
        crossDomain: true,
        responseType: 'blob',
      }).then(response => {
        this.setState({productionLoad: true});
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'bulk-full.pdf');
        document.body.appendChild(link);
        link.click();
        this.setState({ loading: false });
      });
    }
  };

  reloadDocuments = () => {
    this.setState({
      documents: [],
      documentsCount: 0,
      fetching: false
    }, () => {
      
      if(!this.state.infinite) {
        this.getDocumentsByWorkcenter();
      } else {
        this.getDocumentsCount();
      }
    });
  }

  rowDragged = () => {
    this.onSelectChange();
  };

  sortByFollowers = (a, b) => {
    if(a.followers_of_document.length < 1 && b.followers_of_document.length < 1) {
      return 0;
    }

    if(a.followers_of_document.length < 1) {
      return -1;
    }

    if(b.followers_of_document.length < 1) {
      return 1;
    }

    if(a.followers_of_document[0].id < b.followers_of_document[0].id) {
      return -1;
    } else if (a.followers_of_document[0].id > b.followers_of_document[0].id) {
      return 1;
    }

    return 0;
  };

  stopCheckingStock = () => {
    this.setState({ checkingStock: false });
  };

  toggleOrderSheet = (id) => {
    let newList = this.state.orderList;
    if (newList.indexOf(id) === -1) {
      newList.push(id);
    } else {
      newList = newList.filter(item => item !== id);
    }
    this.setState({ orderList: newList, lastOrder: id });
  };

  render() {
    const { match } = this.props;
    const { documents = [] } = this.state;

    const template = [
      {
        label: 'Job Name',
        id: 'jobname',
        checkbox: (match.params.workCenterId == 4 || match.params.workCenterId == 3) ? true : false,
        onCheckboxClick: (value) => {
          if(value) {
            this.setState({
              orderList: documents.map(document => {
                return document.document_id;
              })
            }, () => {
              this.forceUpdate();
            });
          } else {
            this.setState({
              orderList: []
            }, () => {
              this.forceUpdate();
            });
          }
        },
        checkboxChecked: this.state.orderList.length === documents.length,
        bold: true,
        upperCase: true,
        maxLength: 30,
        render: row => {
          return (
            <Jobname rush={row.priority === 'Rush'} must={row.priority === 'Must'}>
              {match.params.workCenterId == 1 && (
                <ClaimedIndicator details={row.document_detail} artist/>
              )}
              {match.params.workCenterId == 10 && (
                <ClaimedIndicator details={row.document_detail} approval/>
              )}
              {match.params.workCenterId == 3 && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    cssOverrides={{zIndex: 100}}
                    onChange={(e) => {
                      e.stopPropagation();
                      this.toggleOrderSheet(row.document_id);
                    }}
                    value={this.state.orderList.includes(row.document_id)}
                    onClick={(e) => {e.stopPropagation();}}
                  ></Checkbox>
                </div>
              )}
              {match.params.workCenterId == 4 && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    cssOverrides={{zIndex: 100}}
                    onChange={(e) => {
                      e.stopPropagation();
                      this.toggleOrderSheet(row.document_id);
                    }}
                    value={this.state.orderList.includes(row.document_id)}
                    onClick={(e) => {e.stopPropagation();}}
                  ></Checkbox>
                </div>
              )}
              {row.priority === 'Must' && (
                <Tag color={theme.orange.color}>MUST</Tag>
              )}
              {row.priority === 'Rush' && (
                <Tag color={theme.danger.color}>RUSH</Tag>
              )}
              {row.isreorder === 1 && (
                <Tag color={theme.blue.color}>RE-ORDER</Tag>
              )}
              {row.isweborder === 1 && (
                <Tag color={theme.vibrant.color}>WEB-ORDER</Tag>
              )}
              {row.ismodro === 1 && (
                <Tag color={theme.primary.lightMedium}>MODRO</Tag>
              )}
              {row.jobname}
            </Jobname>
          );
        },
      },
      {
        label: 'Contact',
        id: 'customer',
        color: '#b3b3b3',
        type: 'customerName',
      },
      { label: 'Tags', id: 'tags_of_document', type: 'tags' },
      {
        label: 'Invoice',
        id: 'invoiced',
        type: 'invoiced',
        render: row => {
          return (
            <>
              { row.invoiced && <>$</> }
            </>
          );
        }
      },
      {
        label: 'Followers',
        id: 'followers_of_document',
        type: 'followers',
        render: row => {
          return (<FollowersWrapper>
            {row.followers_of_document.map(({ id, user_profile }) => {
              const { first_name, last_name, profilepicture } = user_profile
                ? user_profile
                : {};
              return (
                <Follower
                  name={`${first_name} ${last_name}`}
                  profilepicture={profilepicture}
                  background
                  size="small"
                  tooltip
                  key={id}
                />
              );
            })}
          </FollowersWrapper>
          );
        },
      },
      {
        label: 'Due Date',
        id: 'duedate',
        type: 'date',
        render: row => {
          if(row.production_card) {
            return row.production_card?.date ? format(row.production_card?.date, 'MMM D, YYYY') : 'None';
          }

          if(row.designGroups?.filter((des) => des.prod_date !== null).length > 0) {
            return format(row.designGroups
              .filter((des) => des.prod_date !== null)
              .map(des => des.prod_date)
              .reduce((acc, cur) => acc > cur ? cur : acc), 'MMM D, YYYY');
          }

          return 'None';
        }
      },
      { id: 'document_id', hidden: true },
    ];
    return (
      <Wrapper
        key="PAGE_WRAPPER"
      >
        {/* {!loggedIn && <Redirect to='/login' />} */}
        <ConnectedFilterBar
          workCenter={this.getActiveWorkCenter()}
          key="FILTER_BAR"
          onSelectChange={this.onSelectChange}
        />
        {this.state.infinite ? (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => {
              this.fetchMoreDocuments();
            }}
            hasMore={this.state.documents.length < this.state.documentsCount && !this.state.fetching }
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
            className={css({
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            })}
          >
            <Table
              template={template}
              data={!documents ? [] : this.getDocumentContactBackground(documents, match.params.workCenterId)}
              onRowClick={this.editWorkorder}
              onContextMenu={this.openContextMenu}
              rowDragged={this.rowDragged}
            />
          </InfiniteScroll>
        ) : (
          <Table
            template={template}
            data={this.getDocumentContactBackground(documents, match.params.workCenterId)}
            onRowClick={this.editWorkorder}
            onContextMenu={this.openContextMenu}
            rowDragged={this.rowDragged}
          />
        )}
        <WorkcentersMenu
          {...this.state.contextMenuProps}
          isOpen={this.state.contextMenuOpen}
          onModalClose={this.closeContextMenu}
        />
        <div
          className={css({
            position: 'fixed',
            right: 30,
            bottom: 30,
            zIndex: 5,
          })}
        >
          <FloatingActionButton onClick={this.createNewOrder} />
        </div>
        {this.getActiveWorkCenter().name === 'Order' ?
          (<div
            className={css({
              position: 'fixed',
              right: 30,
              bottom: 120,
              zIndex: 5
            })}
          >
            <FloatingActionButton onClick={this.createOrderSheet}
              css={(theme) => ({
                background: '#ed5851',
                color: theme.vibrant.textOn,
                '&:hover': {
                  background: '#FF5E57'
                },
                '&:active': {
                  background: '#FF5E57'
                }
              })}
              icon={<OrderIcon/>}
            />
            <Modal
              isOpen={this.state.checkingStock}
              onModalClose={this.stopCheckingStock}
              orders={this.state.orderList}
              element={CheckStockOrders}
            />
          </div>
          ) : (<div></div>)}
        {this.getActiveWorkCenter().name === 'Separations' ?
          (<div
            className={css({
              position: 'fixed',
              right: 30,
              bottom: 120,
              zIndex: 5
            })}
          >
            <FloatingActionButton onClick={this.printAllPdfs}
              css={(theme) => ({
                background: '#ed5851',
                color: theme.vibrant.textOn,
                '&:hover': {
                  background: '#FF5E57'
                },
                '&:active': {
                  background: '#FF5E57'
                }
              })}
              icon={<PrintIcon/>}
            />
          </div>
          ) : (<div></div>)}
        <Loading
          isOpen={this.state.loading}
        />
      </Wrapper>
    );
  }
}
export default connect((state, props) => ({
  filter: state.workCentersPage.filter
}))(WorkCenters);

export class ConnectedFilterBar extends React.Component {
  render() {
    const { workCenter, value } = this.props;
    return (
      <FilterBar
        {...workCenter}
        color={workCenter.htmlcolor}
        title={workCenter.name}
        onClear={this.props.clearFilter}
        value={value}
        onChange={this.props.setFilter}
        onSelectChange={this.props.onSelectChange}
        allowMini
        unfilterable={this.props.unfilterable ? true : false}
        unsortable={this.props.unsortable ? true : false}
      />
    );
  }
}
ConnectedFilterBar = connect(
  state => ({
    value: state.workCentersPage.filter,
  }),
  { setFilter, clearFilter }
)(ConnectedFilterBar);

const ClaimedIndicator = ({ details, artist, approval }) => {
  if (artist) {
    const claimed = (details.find(d => d.attributename === 'artist') || {}).value;
    return (
      !claimed ?
        <div
          className={css({
            width: 15,
            height: 20,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&::before': {
              content: '""',
              width: 8,
              height: 8,
              borderRadius: '100%',
              background: 'rgb(237, 88, 81)'
            }
          })}
        />
        :
        <div
          className={css({
            width: 15,
            height: 20,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&::before': {
              content: '""',
              width: 8,
              height: 8,
              borderRadius: '100%',
            }
          })}
        />
    );
  } else if (approval) {
    const state = (details.find(d => d.attributename === 'approved') || {}).value;
    switch (state) {
    case 'Waiting':
      return (
        <div
          className={css({
            width: 15,
            height: 20,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&::before': {
              content: '""',
              width: 8,
              height: 8,
              borderRadius: '100%',
              background: 'rgb(126, 88, 194)'
            }
          })}
        />
      );
    case 'Approved':
      return (
        <div
          className={css({
            width: 15,
            height: 20,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&::before': {
              content: '""',
              width: 8,
              height: 8,
              borderRadius: '100%',
              background: 'rgb(156, 204, 100)'
            }
          })}
        />
      );
    case 'Rejected':
      return (
        <div
          className={css({
            width: 15,
            height: 20,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&::before': {
              content: '""',
              width: 8,
              height: 8,
              borderRadius: '100%',
              background: 'rgb(237, 88, 81)'
            }
          })}
        />
      );
    default:
      return (
        <div
          className={css({
            width: 15,
            height: 20,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&::before': {
              content: '""',
              width: 8,
              height: 8,
              borderRadius: '100%',
            }
          })}
        />
      );
    }
  }
};
