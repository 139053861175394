import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import ModalStateController from '@components/ModalStateController';
import { TextInput, Button, Select, ConfirmationModal } from '@components/Controls';
import DatePicker from '@components/DatePicker';
import { format } from 'date-fns';
import { history } from '@/index';
import theme from '@/theme';
import FilterableList from '@components/FilterableList';
import Drawings from '@components/Drawings';
import { Follower, RowWrapper } from '../Workorder/Followers';
import ConnectedFollower from './ConnectedFollower';
import AddIcon from 'react-icons/lib/md/add';
import { calendarOptions, timeOptions } from '@/data/calendarData.js';
import { axios } from '@/api.js';

const ModalWrapper = styled('div')({

  background: '#fff',
  overflowY: 'auto',
  maxHeight: '90vh',
  margin: 'auto',
  marginTop: '50px',
  width: 'min-content',
  zIndex: 88,
  padding: '8px',
  display: 'flex',
  flexDirection: 'column'
}, ({theme}) => ({
  boxShadow: theme.shadows.d2
}));

const HeaderRow = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

const HeaderCol = styled('div')({});

const HeaderTitleButton = styled('div')({
  padding: '5px',
  height: 'min-content',
  cursor: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'  width=\'41\' height=\'49\' viewport=\'0 0 100 100\' style=\'fill:black;font-size:25px;transform: rotate(230deg)\'><text y=\'50%\'>👌</text></svg>") 16 0,auto'
}, ({theme}) => ({
  '&:hover': {
    background: theme.blue.light,
    borderRadius: '5px',
    boxShadow: theme.shadows.d2
  }
}));

const HeaderTitleText = styled('h2')({
  margin: 'auto'
});

const HeaderDate = styled('h2')({
  margin: 0,
  padding: '5px',
  cursor: 'context-menu',
}, ({theme}) => ({
  '&:hover': {
    textShadow: theme.shadows.d2
  }
}));

const HeaderText = styled('p')({
  margin: '4px'
});

const ProductionRow = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  overflowX: 'scroll'
});

const ProductionPrep = styled('div')({
  width: '300px',
  marginLeft: '15px',
  marginRight: '5px'
});

const Designs = styled('div')({});

const DesignsWrapper = styled('div')({
  whiteSpace: 'nowrap',
  maxWidth: '630px',
});

const Design = styled('div')({
  display: 'inline-block',
  width: '300px',
  margin: '5px'
});

const SmallTitle = styled('h3')({
  margin: '3px',
  marginBottom: '8px',
  display: 'inline-flex'
});

const AddFollower = styled('button')(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: '100%',
  border: `3px solid ${theme.neutral.extraLight}`,
  margin: 3,
  color: theme.neutral.extraLight,
  padding: 0,
  cursor: 'pointer',
  opacity: 1,
  transition: 'opacity 200ms',
  '& svg': {
    width: '100%',
    height: '100%',
  },
  '&:hover': {
    color: theme.neutral.light,
    borderColor: theme.neutral.light,
  },
}));


export default class ProductionCardModal extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      datePickerOpen: false,
      userPickerOpen: false,
      confirmationModalOpen: false,
      date: '',
      calendar_event_id: '',
      type: '',
      size: 1,
      prepSteps: '',
      designIndex: null,
      stepIndex: null,
      userPickerJob: '',
      note: props?.editCard?.note ? props.editCard.note : '',
      designs: props?.editCard?.designs ? props.editCard.designs : [],
      timeToComplete: .25
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.editCard !== prevProps.editCard) {
      const card = this.props.editCard;
      this.setState({
        date: card.date,
        type: card.type,
        prepSteps: card.prep_steps,
        designs: card.designs,
        note: card.note,
        size: card.size ? card.size : 1,
        timeToComplete: card.timeToComplete
      });
    }
  }

  openDatePicker = () => {
    const dm = this.date.getBoundingClientRect();
    const page = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    const left = (dm.left - 80 + 288 < page.width) 
      ? dm.left - 80 : page.width - 288 - 10 ;
    const top = dm.bottom;
    this.setState({
      datePickerOpen: true,
      datePickerStyle: {
        left,
        top
      }
    });
  };

  closeDatePicker = () =>  {
    this.setState({ datePickerOpen: false });
  };

  selectDate = (date) => {
    this.setState({ date });
    axios.put('/calendar/card', {
      production_card_id: this.props.editCard.production_card_id,
      date: date
    });
  };

  onModalClose = () => {
    this.props.onModalClose();
  };

  updateNote = ({target: {value}}) => {
    this.setState({
      note: value
    });
  };

  saveNote = () => {
    axios.put('/calendar/card', {
      production_card_id: this.props.editCard.production_card_id,
      note: this.state.note
    });
  };

  updateSize = ({ target: {value}}) => {
    value = Number(value);
    if(!Number.isNaN(value)) {
      this.setState({
        size: value
      });
      axios.put('/calendar/card', {
        production_card_id: this.props.editCard.production_card_id,
        size: value
      });
    }
  };

  updateType = ({target: {value}}) => {
    this.setState({
      type: value
    });
    axios.put('/calendar/card', {
      production_card_id: this.props.editCard.production_card_id,
      type: value
    });
  };

  updateTimeToComplete = (hours) => {
    this.setState({
      timeToComplete: hours
    });
    axios.put('/calendar/card', {
      production_card_id: this.props.editCard.production_card_id,
      timeToComplete: hours
    });
  };

  userPickerClose = () => {
    this.setState({
      userPickerOpen: false
    });
  };

  openUserPicker = () => {
    this.setState({
      userPickerOpen: true
    });
  }

  setDesignChange = (designIndex, stepIndex) => {
    this.setState({
      userPickerJob: 'design',
      designIndex,
      stepIndex
    });
  }

  removeDesignTimestamp = (designIndex, stepIndex) => {
    this.setState({
      userPickerJob: 'design',
      designIndex,
      stepIndex,
      confirmationModalOpen: true,
    });
  }

  removePrepTimestamp = (stepIndex) => {
    this.setState({
      userPickerJob: 'prep',
      stepIndex,
      confirmationModalOpen: true,
    });
  }

  setAddUserToDesign = (designIndex) => {
    this.setState({
      userPickerJob: 'user',
      designIndex
    });
  }

  setPrepChange = (stepIndex) => {
    this.setState({
      userPickerJob: 'prep',
      stepIndex
    });
  }

  confirmationModalClose = () => {
    this.setState({confirmationModalOpen: false});
  }

  deleteTimestamp = () => {
    const { editCard } = this.props;
    const { userPickerJob, stepIndex, designIndex } = this.state;
    if (userPickerJob === 'design') {
      const designs = editCard.designs;
      designs[designIndex].progress[stepIndex].doneDate = null;
      designs[designIndex].progress[stepIndex].doneBy = null;
      axios.put('/calendar/card', {
        production_card_id: editCard.production_card_id,
        designs
      });
    } else if (userPickerJob === 'prep') {
      const prep = editCard.prep_steps;
      prep[stepIndex].doneDate = null;
      prep[stepIndex].doneBy = null;
      axios.put('/calendar/card', {
        production_card_id: editCard.production_card_id,
        prep_steps: prep
      });
    }
    this.setState({confirmationModalOpen: false});
  }

  deletePrinter = (designIndex, printerIndex) => {
    const designs = this.props.editCard.designs;
    designs[designIndex].printer.splice(printerIndex, 1);
    axios.put('/calendar/card', {
      production_card_id: this.props.editCard.production_card_id,
      designs
    }).then(() => {
      this.forceUpdate();
    });
  }

  updateWhere = (value, designIndex) => {
    const designs = this.state.designs;
    designs[designIndex].where = value;
    this.setState({designs});
  }

  saveWhere = () => {
    const { designs } = this.state;
    const { editCard } = this.props;
    axios.put('/calendar/card', {
      production_card_id: editCard.production_card_id,
      designs
    });
  }

  onChooseUser = (user) => {
    const { designIndex, stepIndex, userPickerJob } = this.state;
    const { editCard } = this.props;
    if (userPickerJob === 'design') {
      const designs = editCard.designs;
      designs[designIndex].progress[stepIndex].doneDate = Date.now();
      designs[designIndex].progress[stepIndex].doneBy = user.user_account_id;
      axios.put('/calendar/card', {
        production_card_id: editCard.production_card_id,
        designs
      });
    } else if (userPickerJob === 'prep') {
      const prep = editCard.prep_steps;
      prep[stepIndex].doneDate = Date.now();
      prep[stepIndex].doneBy = user.user_account_id;
      axios.put('/calendar/card', {
        production_card_id: editCard.production_card_id,
        prep_steps: prep
      });
    } else if (userPickerJob === 'user') {
      const designs = editCard.designs;
      designs[designIndex].printer.push(user.user_account_id);
      axios.put('/calendar/card', {
        production_card_id: editCard.production_card_id,
        designs
      });
    }
  }

  setTimedUser = (designIndex, stepIndex, user) => {
    const designs = this.props.editCard.designs;
    designs[designIndex].progress[stepIndex].doneBy = user.user_account_id;
    axios.put('/calendar/card', {
      production_card_id: this.props.editCard.production_card_id,
      designs
    });
  }

  setDesignStartTimestamp = (designIndex, stepIndex, value) => {
    const designs = this.props.editCard.designs;
    designs[designIndex].progress[stepIndex].startDate = value;
    axios.put('/calendar/card', {
      production_card_id: this.props.editCard.production_card_id,
      designs
    })
      .then(() => {
        this.forceUpdate();
      });
  }

  setDesignFinishTimestamp = (designIndex, stepIndex, value) => {
    const designs = this.props.editCard.designs;
    if(designs[designIndex].progress[stepIndex].startDate || value === null) {
      designs[designIndex].progress[stepIndex].doneDate = value;
      axios.put('/calendar/card', {
        production_card_id: this.props.editCard.production_card_id,
        designs
      })
        .then(() => {
          this.forceUpdate();
        });
    }
  }

  calculateDesignPrint = (card) => {
    if(!card.f && !card.b && !card.r && !card.l) {
      return card.print;
    } 
    let print = (card.f ? card.f : '0') + 'x';
    print += (card.b ? card.b : '0') + 'x';
    print += (card.r ? card.r : '0') + 'x';
    print += card.l ? card.l : '0';
    if(card.print) {
      print += 'x' + card.print;
    }

    return print;
  }

  render(){
    const { isOpen, editCard, users } = this.props;
    const {
      note,
      date,
      type,
      datePickerOpen,
      datePickerStyle,
      userPickerOpen,
      designs,
      confirmationModalOpen,
      size,
      timeToComplete
    } = this.state;

    return(
      <ModalStateController
        enterAnimationClassName="pop-in-down"
        exitAnimationClassName="pop-out-up"
        isOpen={isOpen}
        onModalClose={this.onModalClose}
      >
        {
          ({getProps}) => (
            <ModalWrapper {...getProps()}>
              <HeaderRow>
                <HeaderCol>
                  <HeaderTitleButton
                    onClick={() => {
                      history.push(`/workorder/edit/${editCard.document.document_id}`);
                    }}
                  >
                    <HeaderTitleText>
                      {editCard.document.jobname}
                    </HeaderTitleText>
                  </HeaderTitleButton>
                </HeaderCol>
                <HeaderCol>
                  <HeaderDate
                    ref={ref=>{this.date=ref;}}
                    onClick={this.openDatePicker}
                  >
                    <span>{format(date, 'YYYY-MM-DD')}</span>
                  </HeaderDate>
                  <DatePicker
                    isOpen={datePickerOpen}
                    onModalClose={this.closeDatePicker}
                    onDayClick={this.selectDate}
                    style={datePickerStyle}
                    selectedDays={date}
                  />
                </HeaderCol>
              </HeaderRow>
              <HeaderRow>
                <div>
                  <HeaderText>
                    Added by: {editCard.user.first_name + ' ' + editCard.user.last_name}
                  </HeaderText>
                  <Select
                    label="Type"
                    css={{ margin: 2 }}
                    options={calendarOptions}
                    onChange={this.updateType}
                    value={type}
                  />
                  <TextInput
                    label="Size"
                    value={size}
                    onChange={this.updateSize}
                    onBlur={this.saveSize}
                    css={{ margin: 2 }}
                  />
                  <TextInput
                    type="textarea"
                    label="Note"
                    value={note}
                    onChange={this.updateNote}
                    onBlur={this.saveNote}
                    css={{ margin: 2 }}
                  />
                  <Select
                    label="Time to Complete"
                    css={{ margin: 2 }}
                    options={timeOptions}
                    onChange={(e) => {
                      this.updateTimeToComplete(e.target.value);
                    }}
                    value={timeToComplete}
                  />
                </div>
                <ProductionPrep>
                  <SmallTitle>Prep Steps</SmallTitle>
                  { editCard &&
                    editCard.prep_steps.map((step, i) => (
                      <div key={step.step}>
                        <SingleStepButton
                          step={step}
                          onClick={() => {
                            this.openUserPicker();
                            this.setPrepChange(i);
                          }}
                          onRevert={() => {
                            this.removePrepTimestamp(i);
                          }}
                        />
                      </div>
                    ))
                  }
                </ProductionPrep>
              </HeaderRow>
              <ProductionRow>
                <Designs>
                  <SmallTitle>Production Steps</SmallTitle>
                  <DesignsWrapper>
                    { editCard &&
                      editCard.designs.map((design, i) => (
                        <Design key={i}>
                          <div style={{display: 'flex', justifyContent: 'center'}}>
                            <SmallTitle>{`D${i + 1} ${this.calculateDesignPrint(design)} (${design.qty})`}</SmallTitle>
                            {design.printer.map((printer, printerIndex) => (
                              <ConnectedFollower
                                key={printerIndex}
                                user_id={printer}
                                size="small"
                                deleteable
                                onRequestDelete={() => this.deletePrinter(i, printerIndex)}
                              />
                            ))}
                            <AddFollower
                              onClick={() => {
                                this.openUserPicker();
                                this.setAddUserToDesign(i);
                              }}
                              className="add"
                            >
                              <AddIcon style={{marginTop: '-4px'}}/>
                            </AddFollower>
                          </div>
                          <TextInput
                            label="Where"
                            value={designs[i]?.where}
                            onChange={({target: {value}}) => this.updateWhere(value, i)}
                            onBlur={() => this.saveWhere()}
                            css={{ margin: 2 }}
                          />
                          { 
                            design.progress.map((step, j) => {
                              if(!step.type || step.type === 'single') {
                                return (
                                  <div key={step.step}>
                                    <SingleStepButton
                                      step={step}
                                      onClick={() => {
                                        this.openUserPicker();
                                        this.setDesignChange(i, j);
                                      }}
                                      onRevert={() => {
                                        this.removeDesignTimestamp(i, j);
                                      }}
                                    />
                                  </div>
                                );
                              } else {
                                return (
                                  <div key={step.step}>
                                    <TimedStepButton
                                      step={step}
                                      setStartTimestamp={ (value) => {
                                        this.setDesignStartTimestamp(i, j, value);
                                      }}
                                      setFinishTimestamp={ (value) => {
                                        this.setDesignFinishTimestamp(i, j, value);
                                      }}
                                      setUser={ (user) => {
                                        this.setTimedUser(i, j, user);
                                      }}
                                      users={users}
                                    />
                                  </div>
                                );
                              }
                            })
                          }
                        </Design>
                      ))
                    }
                  </DesignsWrapper>
                </Designs>
              </ProductionRow>
              <UserPickerModal
                users={users}
                isOpen={userPickerOpen}
                onModalClose={this.userPickerClose}
                onChooseUser={this.onChooseUser}
              />
              <ConfirmationModal
                onConfirm={this.deleteTimestamp}
                onModalClose={this.confirmationModalClose}
                isOpen={confirmationModalOpen}
                text="Are you sure you want to remove this timestamp, this action cannot be undone."
              />
            </ModalWrapper>
          )
        }
      </ModalStateController>
    );
  }
}

export const Label = styled('label')({
  fontSize: 14,
  marginLeft: 4,
  lineHeight: 0,
  paddingTop: 2,
  whiteSpace: 'nowrap',
});

export class SingleStepButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  uselessButton = () => {
    console.log(
      Drawings[Math.floor(Math.random() * Drawings.length)],
      'color: green; font-weight: bold; font-size: 1rem; font: ui-monospace,"Cascadia Mono","Segoe UI Mono","Liberation Mono",Menlo,Monaco,Consolas,monospace;'
    );

    this.props.onRevert();
  }

  render () {
    const { step, onClick } = this.props;
    return (
      <>
        { step.doneDate ?
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '46px'}}>
            <div style={{display: 'flex', justifyContent: 'center', width: '140px'}}>
              <Button
                styles={{
                  backgroundColor: theme.green.color,
                  marginBottom: '3px',
                  padding: '5px',
                  borderRadius: '7px',
                  width: '100%',
                  margin: 'auto'
                }} 
                onClick={this.uselessButton}
              >
                {step.step}
              </Button>
            </div>
            <ConnectedFollower
              user_id={step.doneBy}
            />
            <p
              style={{
                display: 'inline-block',
                marginTop: 'auto',
                marginBottom: 'auto'
              }}>
              {format(step.doneDate, 'MMM Do')}
              <br />
              {format(step.doneDate, 'h:mm A')}
            </p>
          </div>
          :
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '46px'}}>
            <Button styles={{
              backgroundColor: step.color ? step.color : theme.orange.light,
              marginBottom: '3px',
              padding: '5px',
              borderRadius: '7px',
              width: '100%'
            }}
            onClick={onClick}
            ref={button => {
              this.button = button;
            }}
            >
              {step.step}
            </Button>
          </div>
        }
      </>
    );
  }
}


export class TimedStepButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  uselessButtonFinish = () => {
    console.log(
      Drawings[Math.floor(Math.random() * Drawings.length)],
      'color: green; font-weight: bold; font-size: 1rem; font: ui-monospace,"Cascadia Mono","Segoe UI Mono","Liberation Mono",Menlo,Monaco,Consolas,monospace;'
    );

    this.props.setFinishTimestamp(null);
    this.setState({ finishConfirmationModalOpen: false });
  }

  uselessButtonStart = () => {
    console.log(
      Drawings[Math.floor(Math.random() * Drawings.length)],
      'color: green; font-weight: bold; font-size: 1rem; font: ui-monospace,"Cascadia Mono","Segoe UI Mono","Liberation Mono",Menlo,Monaco,Consolas,monospace;'
    );

    this.props.setStartTimestamp(null);
    this.setState({ startConfirmationModalOpen: false });
  }

  startConfirmationModalClose = () => {
    this.setState({ startConfirmationModalOpen: false });
  }
  
  finishConfirmationModalClose = () => {
    this.setState({ finishConfirmationModalOpen: false });
  }

  openUserPicker = () => {
    this.setState({ userPickerOpen: true });
  }

  closeUserPicker = () => {
    this.setState({ userPickerOpen: false });
  }

  render () {
    const {step, users} = this.props;
    const { userPickerOpen, startConfirmationModalOpen, finishConfirmationModalOpen } = this.state;
    return (
      <>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '46px'}}>
          { step.startDate ?
            <>
              <div style={{display: 'flex', justifyContent: 'center', width: '140px'}}>
                <Button
                  styles={{
                    backgroundColor: theme.green.color,
                    marginBottom: '3px',
                    padding: '5px',
                    borderRadius: '7px',
                    width: '100%',
                    margin: 'auto'
                  }} 
                  onClick={() => {
                    this.setState({ startConfirmationModalOpen: true });
                  }}
                >
                  {step.step}
                </Button>
              </div>
              <p
                style={{
                  display: 'inline-block',
                  marginTop: 'auto',
                  marginBottom: 'auto'
                }}>
                {format(step.startDate, 'MMM Do')}
                <br />
                {format(step.startDate, 'h:mm A')}
              </p>
            </>
            :
            <>
              <Button styles={{
                backgroundColor: step.color ? step.color : theme.orange.light,
                marginBottom: '3px',
                padding: '5px',
                borderRadius: '7px',
                width: '75%'
              }}
              onClick={() => { this.props.setStartTimestamp(Date.now()); }}
              ref={button => {
                this.button = button;
              }}
              >
                {step.step + ' Start'} 
              </Button>
            </>
          }
          <Button
            styles={{
              width: '24%',
              height: '70px',
              paddingLeft: '16px',
              marginTop: '11px'
            }}
            onClick={this.openUserPicker}
          >{step.doneBy ?  
              <ConnectedFollower
                tooltip={false}
                size="medium"
                user_id={step.doneBy}
              /> : 
              'User' }
          </Button>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'start', height: '46px'}}>
          { step.doneDate ?
            <>
              <div style={{display: 'flex', justifyContent: 'center', width: '140px'}}>
                <Button
                  styles={{
                    backgroundColor: theme.green.color,
                    marginBottom: '3px',
                    padding: '5px',
                    borderRadius: '7px',
                    width: '100%',
                    margin: 'auto'
                  }} 
                  onClick={() => {
                    this.setState({ finishConfirmationModalOpen: true });
                  }}
                >
                  {step.step}
                </Button>
              </div>
              <p
                style={{
                  display: 'inline-block',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: '10px'
                }}>
                {format(step.doneDate, 'MMM Do')}
                <br />
                {format(step.doneDate, 'h:mm A')}
              </p>
            </>
            :
            <>
              <Button styles={{
                backgroundColor: step.color ? step.color : theme.orange.light,
                marginBottom: '3px',
                padding: '5px',
                borderRadius: '7px',
                width: '75%'
              }}
              onClick={() => { this.props.setFinishTimestamp(Date.now()); }}
              ref={button => {
                this.button = button;
              }}
              >
                {step.step + ' Finish'} 
              </Button>
            </>
          }
        </div>
        <UserPickerModal
          users={users}
          isOpen={userPickerOpen}
          onModalClose={this.closeUserPicker}
          onChooseUser={this.props.setUser}
        />
        <ConfirmationModal
          onConfirm={() => {this.uselessButtonStart();}}
          onModalClose={this.startConfirmationModalClose}
          isOpen={startConfirmationModalOpen}
          text="Are you sure you want to remove this timestamp, this action cannot be undone."
        />
        <ConfirmationModal
          onConfirm={() => {this.uselessButtonFinish();}}
          onModalClose={this.finishConfirmationModalClose}
          isOpen={finishConfirmationModalOpen}
          text="Are you sure you want to remove this timestamp, this action cannot be undone."
        />
      </>
    );
  }
}


const UserModalWrapper = styled('div')({
  position: 'fixed',
  zIndex: 101,
  top: '15%',
  left: '45%',
  display: 'flex',
  flexDirection: 'column'
}, ({theme}) => ({
  boxShadow: theme.shadows.d2
}));

export class UserPickerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userPickerOpen: false
    };
  }

  render () {
    const {users, isOpen, style} = this.props;
    return (
      <ModalStateController
        enterAnimationClassName="pop-in-down"
        exitAnimationClassName="pop-out-up"
        isOpen={isOpen}
        onModalClose={this.props.onModalClose}
      >
        {({getProps, closeModal}) => (
          <UserModalWrapper 
            {...getProps()}
            style={style}
          >
            <FilterableList
              items={users}
              onSubmit={() => {
                this.onChooseUser('123');
                closeModal();
              }}
              maxHeight={600}
              filter={(fol, filter) =>
                fol.username.toLowerCase().includes(filter)
              }
              ref={list => {
                this.list = list;
              }}
              renderItem={({
                item: follower,
                selected,
                deselect,
              }) => (
                <RowWrapper
                  selected={selected}
                  onClick={() => {
                    this.props.onChooseUser(follower);
                    closeModal();
                  }}
                  key={follower.user_account_id}
                  onMouseEnter={deselect}
                >
                  <Follower
                    profilepicture={follower.user_profile.profilepicture}
                    background
                    user_id={follower.user_account_id}
                  />
                  <Label>{follower.user_profile.first_name + ' ' + follower.user_profile.last_name}</Label>
                </RowWrapper>
              )}
            />
          </UserModalWrapper>
        )}
      </ModalStateController>
    );
  }
}
