import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import ModalStateController from './ModalStateController';

const ScrollWrapper = styled('div')({
  zIndex: 3,
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  overflowY: 'auto'
});

export default class GalleryModal extends React.Component {
  state = { loaded: false }
  componentDidMount = () => {
    this.setScrollTop(this.props.targetIndex);
  }
  componentDidUpdate = prevProps => {
    if (prevProps.isOpen === true && this.props.isOpen === false) {
      // this.setScrollTop(this.props.targetIndex)
      this.setState({loaded: false});
    }
  }
  setScrollTop = i => {
    if (this.gallery && this.wrapper) {
      const y = this.gallery.getImageTopByIndex(i);
      this.wrapper.scrollTop = y - 50;
    }
  }
  fadeInAndScroll = () => {
    this.setScrollTop(this.props.targetIndex);
    this.setState({ loaded: true });
  }
  render() {
    const { isOpen, onModalClose, images, targetIndex } = this.props;
    return (
      <ModalStateController
        enterAnimationClassName="fade-in"
        exitAnimationClassName="fade-out"
        isOpen={isOpen}
        onModalClose={onModalClose}
        shadeStyle={{ background: 'rgba(0,0,0,.6)' }}
      >
        {({ getProps, closeModal }) => (
          <ScrollWrapper
            {...getProps()}
            ref={ref => {
              this.wrapper = ref;
            }}
            style={{opacity: this.state.loaded ? 1 : 0}}
          >
            <Gallery
              ref={ref => {
                this.gallery = ref;
              }}
              images={images}
              targetIndex={targetIndex}
              onAllImagesLoaded={this.fadeInAndScroll}
              onRequestModalClose={closeModal}
            />
            <div
              onClick={closeModal}
              className={css({
                background: 'transparent',
                position: 'fixed',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                zIndex: -1
              })}
            />
          </ScrollWrapper>
        )}
      </ModalStateController>
    );
  }
}

class Gallery extends React.Component {
  state = { numLoaded: 0, allLoaded: false }
  imageRefs = []
  componentDidUpdate = prevProps => {
    if (this.props.images !== prevProps.images) {
      this.setState({ numLoaded: 0 });
    }
  }
  getImageTopByIndex = i => {
    if (this.imageRefs[i]) {
      const { y } = this.imageRefs[
        this.props.targetIndex
      ].getBoundingClientRect();
      return y;
    } else {
      return 0;
    }
  }
  reportLoad = () => {
    this.setState(
      state => ({
        numLoaded: state.numLoaded + 1
      }),
      () => {
        if (this.state.numLoaded === this.props.images.length) {
          this.props.onAllImagesLoaded();
          this.setState({allLoaded: true});
        }
      }
    );
  }
  render() {
    const { images } = this.props;
    return (
      <Wrapper
        style={{
          opacity: this.state.allLoaded ? 1 : 0
        }}
      >
        {images.map((img, i) => (
          <Image
            ref={ref => {
              this.imageRefs[i] = ref;
            }}
            onLoad={this.reportLoad}
            onError={this.reportLoad}
            src={img}
            key={i}
          />
        ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 50,
  zIndex: 10,
  transition: 'opacity 200ms',
  pointerEvents: 'none'
});

const Image = styled('img')({
  maxWidth: 'calc(100vw - 100px)',
  maxHeight: 'calc(100vh - 100px)',
  margin: '10px 0px',
  pointerEvents: 'auto',
  background: '#ffffff',

  '&:first-of-type': {
    marginTop: 0
  },

  '&:last-child': {
    marginBottom: 0
  }
});
