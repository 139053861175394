import React from 'react';
import styled from '@emotion/styled';
import {
  SimpleCheckbox,
  Button,
  TextInput
} from './Controls';
import AddIcon from 'react-icons/lib/md/add';
import DeleteIcon from 'react-icons/lib/md/delete';
import shortid from 'shortid';

const RecipientGrid = styled('div')({
  flex: 'auto',
  flexDirection: 'column',
  display: 'flex'
});

const RecipientRow = styled('div')({
  flex: '1',
  display: 'flex',
  alignItems: 'start',
});

const HeaderText = styled('div')({
  flex: 1,
  textAlign: 'center'
});

const RecipientTitle = styled('div')({
  flex: 8
});

export default class EmailPicker extends React.Component {
  state = {
    customEmails: []
  };

  addCustomEmail = () => {
    const customEmails = this.state.customEmails;
    customEmails.push({
      sendField: [false, false],
      email: '',
      id: shortid.generate()
    });
    this.setState({
      customEmails
    });
  }

  changeCustomText = (val, i) => {
    if(this.props.to === this.state.customEmails[i].email && this.props.to) {
      this.props.changeTo(val, true);
    } else if(this.props.cc === this.state.customEmails[i].email && this.props.cc) {
      this.props.changeCC(val, true);
    }

    const customEmails = this.state.customEmails;
    customEmails[i].email = val;

    this.setState({customEmails});
  }

  removeEmail = () => {
    const i = this.state.customEmails.length - 1;

    if(this.props.to === this.state.customEmails[i].email && this.props.to) {
      this.props.changeTo(this.props.to, false);
    } else if(this.props.cc === this.state.customEmails[i].email && this.props.cc) {
      this.props.changeCC(this.props.cc, false);
    }

    const customEmails = this.state.customEmails;
    customEmails.splice(customEmails.length - 1, 1);
    this.setState({customEmails});
  }

  render() {
    const {
      billto,
      careof,
      to,
      cc
    } = this.props;

    return (
      <>
        <RecipientGrid>
          <RecipientRow>
            <HeaderText>To:</HeaderText>
            <HeaderText>CC:</HeaderText>
            <RecipientTitle></RecipientTitle>
          </RecipientRow>
          { careof === billto ?
            (
              <RecipientRow>
                <SimpleCheckbox
                  css={{flex: 1}}
                  onChange={(e) => {this.props.changeTo(careof, e);}}
                  value={to === careof}
                />
                <SimpleCheckbox
                  css={{flex: 1}}
                  onChange={(e) => {this.props.changeCC(careof, e);}}
                  value={cc === careof}
                />
                <RecipientTitle>
                  Email: {careof || <span style={{color: 'red'}}>none</span>}
                </RecipientTitle>
              </RecipientRow>
            ) : (
              <>
                <RecipientRow>
                  <SimpleCheckbox
                    css={{flex: 1}}
                    onChange={(e) => {this.props.changeTo(careof, e);}}
                    value={to === careof}
                  />
                  <SimpleCheckbox
                    css={{flex: 1}}
                    onChange={(e) => {this.props.changeCC(careof, e);}}
                    value={cc === careof}
                  />
                  <RecipientTitle>
                    Care of: {careof || <span style={{color: 'red'}}>none</span>}
                  </RecipientTitle>
                </RecipientRow>
                <RecipientRow>
                  <SimpleCheckbox
                    css={{flex: 1}}
                    onChange={(e) => {this.props.changeTo(billto, e);}}
                    value={to === billto}
                  />
                  <SimpleCheckbox
                    css={{flex: 1}}
                    onChange={(e) => {this.props.changeCC(billto, e);}}
                    value={cc === billto}
                  />
                  <RecipientTitle>
                    Bill to: {billto || <span style={{color:'red'}}>none</span>}
                  </RecipientTitle>
                </RecipientRow>
              </>
            )
          }
          {this.props.defaultEmails &&
           <>
             <RecipientRow>
               <SimpleCheckbox
                 css={{flex: 1}}
                 onChange={(e) => {this.props.changeTo('art@badgerprints.com', e);}}
                 value={this.props.to == 'art@badgerprints.com'}
               />
               <SimpleCheckbox
                 css={{flex: 1}}
                 onChange={(e) => {this.props.changeCC('art@badgerprints.com', e);}}
                 value={this.props.cc == 'art@badgerprints.com'}
               />
               <RecipientTitle>
                art@badgerprints.com
               </RecipientTitle>
             </RecipientRow>
             <RecipientRow>
               <SimpleCheckbox
                 css={{flex: 1}}
                 onChange={(e) => {this.props.changeTo('invoices@badgerprints.com', e);}}
                 value={this.props.to == 'invoices@badgerprints.com'}
               />
               <SimpleCheckbox
                 css={{flex: 1}}
                 onChange={(e) => {this.props.changeCC('invoices@badgerprints.com', e);}}
                 value={this.props.cc == 'invoices@badgerprints.com'}
               />
               <RecipientTitle>
                invoices@badgerprints.com
               </RecipientTitle>
             </RecipientRow>
           </>
          }
          {this.state.customEmails.map((email, i) => (
            <RecipientRow style={{marginTop: '10px'}} key={email.id}>
              <SimpleCheckbox
                css={{flex: 1}}
                onChange={(e) => {this.props.changeTo(this.state.customEmails[i].email, e);}}
                value={to === this.state.customEmails[i].email && to}
              />
              <SimpleCheckbox
                css={{flex: 1}}
                onChange={(e) => {this.props.changeCC(this.state.customEmails[i].email, e);}}
                value={cc === this.state.customEmails[i].email && cc}
              />
              <RecipientTitle>
                <TextInput
                  label="Custom Email"
                  onChange={({target: {value}}) => {this.changeCustomText(value, i);}}
                  value={email.email}
                />
              </RecipientTitle>
            </RecipientRow>
          ))}
          <RecipientRow style={{margintop: '10px'}}>
            <div style={{flex: 2}}></div>
            <RecipientTitle>
              <Button
                onClick={this.addCustomEmail}
                styles={{height: '10px', paddingTop: '6px', paddingBottom: '20px'}}
              > <AddIcon /> </Button>
              { this.state.customEmails.length > 0 &&
                <Button
                  onClick={this.removeEmail}
                  styles={{height: '10px', backgroundColor: 'red', marginLeft: '5px', paddingTop: '6px', paddingBottom: '20px'}}
                > <DeleteIcon /> </Button>
              }
            </RecipientTitle>
          </RecipientRow>
        </RecipientGrid>
      </>
    );
  }
}
