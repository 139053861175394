import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from 'emotion';
import Grid from './Collections/Grid';
import { TextInput, Checkbox, SimpleCheckbox } from './Controls';
import compose from '../utils/compose';
import { colemanTemplate } from '../data/linesTemplates';
import Toolbar from '../pages/Workorder/Toolbar';
import { axios, createHistory } from '../api.js';
import _ from 'lodash';

class ColemanOrder extends React.Component {
  state = {
    colemanLines: {
      school: '',
      size: '',
      specialLengthSleeve: '',
      specialLengthBody: '',
      sleeveColor: '',
      bodyColor: '',
      monogramming: '',
      monogrammingLine: 'Straight',
      name: '',
      nameFont: 'Hailey',
      year: '',
      additional_emblems: []
    },
    coleman_on: false,
    isreorder: 0
  };

  componentDidMount() {
    this.getColemanLines();
  }

  componentDidUpdate() {
    const coleman_lines = this.state.colemanLines ?? {};

    if(!_.isEqual(coleman_lines, this.state.colemanLines)) {
      this.setState({colemanLines: coleman_lines});
    }
  }

  getColemanLines = () => {
    axios.get('/document', {
      params: {
        id: this.props.document_id,
        attributes: [
          'coleman_on',
          'coleman_lines',
          'isreorder'
        ]
      }
    })
      .then((res) => {
        const colemanLines = this.state.colemanLines;
        Object.assign(colemanLines, res.data.document.coleman_lines ?? {});
        this.setState({
          colemanLines,
          coleman_on: res.data.document.coleman_on,
          isreorder: res.data.document.isreorder
        });
      });
  };

  toggleColemanOrder = active => {
    axios.put('/document/singleField', {
      id: this.props.document_id,
      field: 'coleman_on',
      fieldValue: active
    })
      .then(() => {
        createHistory({
          document_id: this.props.document_id,
          json_previous: this.state.isreorder,
          user_id: this.props.user_account_id,
          json: active,
          type: 'toggle',
          key: 'colemanon',
        });
        this.setState({ coleman_on: active });
      });

  };

  updateColemanHeaders = (header, value) => {
    const colemanLines = this.state.colemanLines;
    colemanLines[header] = value;
    this.setState({ colemanLines });
  };

  updateColeman = () => {
    const coleman_lines = this.state.colemanLines;
    axios.put('/document/singleField', {
      id: this.props.document_id,
      field: 'coleman_lines',
      fieldValue: coleman_lines
    })
      .then(() => {
        createHistory({
          document_id: this.props.document_id,
          json: coleman_lines,
          type: 'coleman',
          key: 'colemanLines',
          user_id: this.props.user_account_id
        });
      });
  };

  updateColemanLines = additional_emblems => {
    const colemanLines = this.state.colemanLines;
    colemanLines.additional_emblems = additional_emblems;
    this.setState({colemanLines});
    this.updateColeman();
  };

  render() {
    const {
      colemanLines
    } = this.state;

    return (
      <>
        <FlexRow
          className={css({
            marginTop: 15,
            paddingLeft: 15,
            paddingRight: 15,
          })}
        >
          <Toolbar
            label="Coleman Order"
            buttons={
              <SimpleCheckbox
                boxCSS={{
                  borderColor:'white',
                  marginRight: '1px'
                }}
                value={this.state.coleman_on}
                onChange={active => {
                  this.toggleColemanOrder(active);
                }}
              />
            }
          />
        </FlexRow>
        {this.state.coleman_on &&
          <>
            <ColemanInputWrapper>
              <TextInput
                label="School"
                value={colemanLines.school}
                onChange={(e) => {this.updateColemanHeaders('school', e.target.value);}}
                onBlur={this.updateColeman}
              />
              <TextInput
                label="Size"
                value={colemanLines.size}
                onChange={(e) => {this.updateColemanHeaders('size', e.target.value);}}
                onBlur={this.updateColeman}
              />
              <TextInput
                label="Year"
                value={colemanLines.year}
                onChange={(e) => {this.updateColemanHeaders('year', e.target.value);}}
                onBlur={this.updateColeman}
              />
            </ColemanInputWrapper>
            <ColemanInputWrapper>
              <TextInput
                label="Special Length Body"
                value={colemanLines.specialLengthBody}
                onChange={(e) => {this.updateColemanHeaders('specialLengthBody', e.target.value);}}
                onBlur={this.updateColeman}
              />
              <TextInput
                label="Special Length Sleeve"
                value={colemanLines.specialLengthSleeve}
                onChange={(e) => {this.updateColemanHeaders('specialLengthSleeve', e.target.value);}}
                onBlur={this.updateColeman}
              />
              <TextInput
                label="Sleeve Color"
                value={colemanLines.sleeveColor}
                onChange={(e) => {this.updateColemanHeaders('sleeveColor', e.target.value);}}
                onBlur={this.updateColeman}
              />
            </ColemanInputWrapper>
            <ColemanInputWrapper>
              <TextInput
                label="Body Color"
                value={colemanLines.bodyColor}
                onChange={(e) => {this.updateColemanHeaders('bodyColor', e.target.value);}}
                onBlur={this.updateColeman}
              />
              <TextInput
                label="Monogramming"
                value={colemanLines.monogramming}
                onChange={(e) => {this.updateColemanHeaders('monogramming', e.target.value);}}
                onBlur={this.updateColeman}
              />
              <Checkbox
                onChange={() => {
                  this.updateColemanHeaders('monogrammingLine', 'Straight');
                  this.updateColeman();
                }}
                value={colemanLines.monogrammingLine === 'Straight'}
                label="Straight"
              />
              <Checkbox
                onChange={() => {
                  this.updateColemanHeaders('monogrammingLine', 'Curved');
                  this.updateColeman();
                }}
                value={colemanLines.monogrammingLine === 'Curved'}
                label="Curved"
              />

            </ColemanInputWrapper>
            <ColemanInputWrapper>
              <TextInput
                label="Name"
                value={colemanLines.name}
                onChange={(e) => {this.updateColemanHeaders('name', e.target.value);}}
                onBlur={this.updateColeman}
              />
              <Checkbox
                onChange={() => {
                  this.updateColemanHeaders('nameFont', 'Hailey');
                  this.updateColeman();
                }}
                value={colemanLines.nameFont === 'Hailey'}
                label="Hailey"
              />
              <Checkbox
                onChange={() => {
                  this.updateColemanHeaders('nameFont', 'Jared');
                  this.updateColeman();
                }}
                value={colemanLines.nameFont === 'Jared'}
                label="Jared"
              />
              <Checkbox
                onChange={() => {
                  this.updateColemanHeaders('nameFont', 'Chris');
                  this.updateColeman();
                }}
                value={colemanLines.nameFont === 'Chris'}
                label="Chris"
              />
              <Checkbox
                onChange={() => {
                  this.updateColemanHeaders('nameFont', 'Block');
                  this.updateColeman();
                }}
                value={colemanLines.nameFont === 'Block'}
                label="Block"
              />
            </ColemanInputWrapper>
            <GridWrapper>
              <Grid
                template={colemanTemplate}
                newRowTemplate={colemanTemplate}
                hideableColumns={[]}
                activeColumns={colemanTemplate}
                lines={colemanLines?.additional_emblems ? colemanLines.additional_emblems : []}
                updateLines={this.updateColemanLines}
                disableOnClickOutside={false}
                hideEditInventory={true}
                hideAutoSort={true}
                ref={grid => {
                  this.grid = grid;
                }}
              />
            </GridWrapper>
          </>
        }
      </>
    );
  }
}

export default compose(
  connect((state) => ({
    user_account_id: state.app.user_account_id,
    user: state.app.user
  })),
)(ColemanOrder);

const GridWrapper = styled('div')({
  padding: '0px 15px',
});

const ColemanInputWrapper = styled('div')({
  paddingTop: 10,
  paddingLeft: 18,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  marginTop: 0,
  paddingBottom: 0,
  marginBottom: 0,
  '& > *': { //So that the TextInputs in this don't sit right next to each other
    margin: 0,
    marginLeft: 5,
    padding: 0
  }
});

const FlexRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});
