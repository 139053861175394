import React from 'react';
import styled from '@emotion/styled';

const BoxLabel = styled('label')(({theme}) => ({
  fontSize: 13,
  color: theme.neutral.color,
  display: 'flex',
  alignItems: 'center',
  flex: '0 0 auto',
  whiteSpace: 'nowrap'
}));

const IconWrapper = styled('div')({
  width: 17,
  marginRight: 5,
  whiteSpace: 'nowrap',

  '& svg': {
    width: '100%',
    height: '100%'
  }
});

export const SmallLabel = ({icon=null, label=''}) => (
  <BoxLabel>
    {
      icon &&
      <IconWrapper>
        {icon}
      </IconWrapper>
    }
    <span>
      {label}
    </span>
  </BoxLabel>
);
