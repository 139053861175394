import React from 'react';
import styled from '@emotion/styled';
import format from 'date-fns/format';
import { connect } from 'react-redux';

import MdVoicemail from 'react-icons/lib/md/voicemail';
import MdLocalPhone from 'react-icons/lib/md/local-phone';
import MdEmail from 'react-icons/lib/md/email';
import MdMessage from 'react-icons/lib/md/message';
import MdPerson from 'react-icons/lib/md/person';

import { TypedHistory } from './History';
import { Button, TextInput } from './Controls';
import DatePicker from './DatePicker';
import ModalStateController from './ModalStateController';
import { css } from 'emotion';
import { axios, createHistory } from '../api';

export default class CustomerLogModal extends React.Component {
  render() {
    const { isOpen, onModalClose, document_id } = this.props;
    return (
      <ModalStateController
        enterAnimationClassName="fade-in"
        exitAnimationClassName="fade-out"
        isOpen={isOpen}
        onModalClose={onModalClose}
      >
        {({getProps, closeModal}) =>
          <ScrollWrapper
            {...getProps()}
            onClick={() =>
              closeModal()
            }
          >
            <CustomerLog
              onModalClose={closeModal}
              document_id={document_id}
            >
            </CustomerLog>
          </ScrollWrapper>
        } 
      </ModalStateController>

    );
  }
}

const ScrollWrapper = styled('div')({
  overflowY: 'auto',
  width: '100%',
  padding: 30,
  zIndex: 99,
  maxHeight: '100vh',
});

const ModalWrapper = styled('div')(({ theme }) => ({
  background: '#fff',
  borderRadius: 15,
  width: '100%',
  maxWidth: 1200,
  padding: 30,
  margin: '0 auto',
  boxShadow: theme.shadows.d3,
}));

const iconButtonStyles = {
  padding: 7,
  margin: 5
};

const HeaderWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const ButtonWrapper = styled('div')(() => ({
  display: 'flex'
}));

const Text = styled('p')(() => ({
  display: 'flex',
  margin: '10px'
}));

const Title = styled('h3')(() => ({
  margin: 0
}));

class CustomerLog extends React.Component {

  constructor(props) {
    super(props);
    this.dateButton = React.createRef();
  }
  
  state = {
    contactType: '',
    datePickerOpen: false,
    date: new Date(),
    note: '',
    error: ''
  };

  setContactType = (type) => {
    this.setState({ contactType: type });
  };
  
  openDatePicker = () => {
    const dm = this.dateButton.current.getBoundingClientRect();
    const left = dm.left - 140;

    this.setState({
      datePickerOpen: true,
      datePickerStyle: {
        left,
        top: dm.bottom + 10
      }
    });
  };

  closeDatePicker = () => {
    this.setState({datePickerOpen: false});
  };

  datePick = (date) => {
    this.setState({date: date});
  };

  changeNote = (note) => {
    this.setState({note: note});
  };

  submitContact = () => {
    const json = {
      contactType: this.state.contactType,
      note: this.state.note,
      date: format(this.state.date, 'MM/DD/YY')
    };

    const event = {
      document_id: this.props.document_id,
      user_id: this.props.user_account_id,
      json: json,
      type: 'contact',
      key: this.state.contactType
    };

    if(this.state.note && this.state.contactType) {
      createHistory(event);

      this.setState({error: ''});
      this.props.onModalClose();

      return;
    }

    this.setState({error: 'A note must be added and a contact type must be selected'});
  };
  

  render() {

    const { date, note, error } = this.state;
    const formattedDate = format(date, 'MM/DD/YY');

    return (
      <ModalWrapper
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Title>Contact Log</Title>
        <hr />
        <HeaderWrapper>
          <ButtonWrapper>
            <Text>Type:</Text>
            <Button
              styles={{
                ...iconButtonStyles,
                backgroundColor:(this.state.contactType == 'emailContact') ? 'green' : ''
              }}
              onClick={() => { this.setContactType('emailContact'); }}
            >
              <MdEmail />
            </Button>
            <Button
              styles={{
                ...iconButtonStyles,
                backgroundColor:(this.state.contactType == 'phoneContact') ? 'green' : ''
              }}
              onClick={() => { this.setContactType('phoneContact'); }}
            >
              <MdLocalPhone />
            </Button>
            <Button
              styles={{
                ...iconButtonStyles,
                backgroundColor:(this.state.contactType == 'textContact') ? 'green' : ''
              }}
              onClick={() => { this.setContactType('textContact'); }}
            >
              <MdMessage />
            </Button>
            <Button
              styles={{
                ...iconButtonStyles,
                backgroundColor:(this.state.contactType == 'inPersonContact') ? 'green' : ''
              }}
              onClick={() => { this.setContactType('inPersonContact'); }}
            >
              <MdPerson />
            </Button>
            <Button
              styles={{
                ...iconButtonStyles,
                backgroundColor:(this.state.contactType == 'voicemailContact') ? 'green' : ''
              }}
              onClick={() => { this.setContactType('voicemailContact'); }}
            >
              <MdVoicemail />
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Text>{formattedDate}</Text>
            <Button 
              ref={this.dateButton}
              onClick={() => { this.openDatePicker(); }}
            >Select Date</Button>
          </ButtonWrapper>
          <DatePicker
            isOpen={this.state.datePickerOpen}
            style={this.state.datePickerStyle}
            onModalClose={this.closeDatePicker}
            onDayClick={this.datePick}
          />
        </HeaderWrapper>
        <TextInput
          width="100%"
          type="textarea"
          label="Note"
          css={{height: '150px'}}
          onChange={(e) => { this.changeNote(e.target.value);}}
          value={note}
        />
        <Button
          onClick={this.props.submitContact || this.submitContact}
        >
          Submit
        </Button>
        <p
          style={{color: 'red'}}
        >{error}</p>

        <hr />
        <Title>History</Title>
        <hr />
        <TypedHistory document_id={this.props.document_id} type="contact"/>
      </ModalWrapper>
    );
  }
}
CustomerLog = connect((state) => ({
  user_account_id: state.app.user_account_id,
}))(CustomerLog);

export class SidebarLog extends React.Component {
  state = {
    historys: []
  };

  componentDidMount() {
    axios.get('/history', {
      params: {
        document_id: this.props.document_id,
        type: 'contact'
      }
    })
      .then((res) => {
        this.setState({ historys: res.data.result });
      });
  }

  render() {
    const historys = this.state.historys;
    return (
      <>
        <p
          style={{
            color:'white', 
            margin:'2px', 
            padding:'5px', 
            fontSize: '12px',
            fontWeight: 400
          }}
        >
          Contact Log:
        </p>

        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            padding: '1px 10px 1px 10px',
            overflowY: 'auto',
            maxHeight: '155px',
          })}
        >

          {historys.length && historys.map((history, index) => {
            return <MiniContact type={history.key} key={index} json={history.json} />;
          }) || <p style={{
            color:'white', 
            margin:'2px', 
            padding:'5px', 
            fontSize: '12px',
            fontWeight: 400
          }}>No Contacts Yet!</p>}
        
        </div>
      </>
    );
  }
} 

class MiniContact extends React.Component {

  getIconByType = () => {

    switch (this.props.type) {
    case 'phoneContact':
      return <MdLocalPhone />;
    case 'emailContact':
      return <MdEmail />;
    case 'textContact':
      return <MdMessage />;
    case 'voicemailContact':
      return <MdVoicemail />;
    case 'inPersonContact':
      return <MdPerson />;
    }
    
  }

  render() {
    const { json } = this.props;
  
    return (
      <p
        style={{
          color:'white', 
          margin:'2px', 
          padding:'5px', 
          background: 'rgba(255,255,255,.05)',
          fontSize: '12px',
          fontWeight: 400
        }}
      >
        {this.getIconByType()} | {json.date} | {json.note}
      </p>

    );
  }
}
