import React, { useState, useEffect } from 'react';
import { axios, serverSentEventSub } from '@/api.js';
import { TextInput, Button } from '@components/Controls';
import styled from '@emotion/styled';
import Lines from '@components/Collections/StaticLines';
import DataGrid from '@components/Reusable/data/DataGrid/DataGrid.js';
import { getFilteredTemplate } from '@/data/linesTemplates';
import columns from '@components/Reusable/data/DataGrid/template.js';

export default function Stock(props) {
  const [inStock, setInStock] = useState({}); // Currently In Stock
  const [toUse, setToUse] = useState({}); // Holds the input values for how many to use up
  const [lines, setLines] = useState([]);
  const [linesConfig, setLinesConfig] = useState({}); // just for V2 lines
  const [version, setVersion] = useState();

  useEffect(() => {
    console.log('use');
    //serverSentEventSub.addEventListener('updateStock', handleStockUpdate);
    getDocument();
  }, []);

  useEffect(() => {
    if(!version) return;

    if(version >= 3) {
      getLines();
    }
  }, [version]);

  useEffect(() => {
    if(lines.length < 1) return;
    getItems();
  }, [lines]);

  useEffect(() => (() => {
    console.log('de-use');
    serverSentEventSub.removeEventListener('updateStock', handleStockUpdate);
  }), []);

  function getDocument() {
    axios.get('/document', {
      params: {
        id: props.document_id,
        attributes: [
          'document_id',
          'jobname',
          'lines',
          'linesConfig',
          'version'
        ]
      }
    })
      .then((res) =>{
        setVersion(res.data.document.version);
        if(res.data.document.version < 3) {
          setLines(res.data.document.lines);
          setLinesConfig(res.data.document.linesConfig);
        }
      });
  }

  function getItems() {
    if(version < 3) {
      for(const lineIndex in lines) { // Get stock for each document line
        const line = lines[lineIndex];
        const stylenumber = line.stylenumber.split('|');

        if(!stylenumber[0]) {
          continue;
        }

        getItemSetFromDB(stylenumber[0], stylenumber[1], line.quantity);
      }
    } else {
      for(const designIndex in lines) {
        const design = lines[designIndex];
        for(const lineIndex in design.orderLines) {
          const line = design.orderLines[lineIndex];

          const stylenumber = line.stylenumber.split('|');

          if(!stylenumber[0]) {
            continue;
          }

          getItemSetFromDB(stylenumber[0], stylenumber[1], line.quantity);
        }
      }
    }
  }

  function getItemSetFromDB(stylenumber, color) {
    axios.get('/inventory/stock/itemset', {
      params: {
        stylenumber,
        color
      }
    })
      .then((res) => {
        for(const row of res.data.results) {
          setToUse((prev) => ({
            ...prev,
            [row.id]: row.number
          }));
        }

        setInStock((prev) => ({
          ...prev,
          [stylenumber + '|' + color]: res.data.results
        }));
      })
      .catch(() => {
        // This is to eat the request because it fails if the inventory item doesn't exist
      });
  }

  function getLines() {
    axios.get('/lines', {
      params: {
        document_id: props.document_id
      }
    })
      .then((res) => {
        setLines(res.data.results);
      });
  }

  function handleStockUpdate(e) {
    console.log('yarg');
    console.log(e);
  }

  function sortItems(itemsArray) {

    itemsArray.sort((a, b) => {
      const [ stylenumbera, colora ] = a[0].split('|');
      const [ stylenumberb, colorb ] = b[0].split('|');

      if(stylenumbera == stylenumberb) {
        if(colora == colorb) {
          return 0;
        }

        return (colora > colorb) ? 1 : -1;
      }

      return (stylenumbera > stylenumberb) ? 1 : -1;
    });
    return itemsArray;
  }

  function useItems(item) {
    const numberToUse = toUse[item.id];
    const numberLeft = item.number - numberToUse;
    if(numberLeft < 0) {
      return;
    }

    axios.put('/inventory/stock/item', {
      id: item.id,
      number: numberLeft
    })
      .then(() => {
        getItems();
      });

  }

  const filteredTemplate = getFilteredTemplate(
    linesConfig || {},
    version
  ).filter(t => t.id !== 'customprice');
  return (
    <>
      { props.noTitle !== true &&
        <h2
          style={{
            marginTop: '0',
            padding: '16px',
            borderBottom: '2px solid black'
          }}
        >In Stock</h2>
      }
      { version < 3 ?
        <Lines
          lines={lines}
          template={[
            ...filteredTemplate.slice(0, 4),
            { label: 'Description', id: 'description' },
            ...filteredTemplate.slice(4),
          ]}
          separatorLines={true}
        />
        :
        lines.map(design =>
          <DataGrid
            key={design.id}
            rows={design.orderLines}
            hiddenColumns={design.hiddenColumns}
            columnsHideable={false}
            readOnly={true}
            columns={columns}
          />
        )
      }
      {
        (Object.entries(inStock).filter(stock => stock[1].length).length === 0 &&
          <h4>None of the items in this workorder are currently in stock.</h4>)
      }
      { // This bit of magic code iterates through every non-empty item in the state.inStock object after sorting it
        sortItems(Object.entries(inStock)).filter(stock => stock[1].length).map((stock, i) => {
          const items = stock[1];
          return(
            <React.Fragment key={i}>
              <h3>{stock[0]}</h3>
              <TableRow
                style={{
                  border: '1px solid black',
                  borderRadius: '6px'
                }}
              >
                <TableData>Color</TableData>
                <TableData>Location</TableData>
                <TableData>Size</TableData>
                <TableData>Number</TableData>
                <TableData>Use</TableData>
              </TableRow>
              {items.map(item => {
                return(
                  <TableRow key={item.id}>
                    <TableData>{item.size.toUpperCase()}</TableData>
                    <TableData>{item.color}</TableData>
                    <TableData>{item.location}</TableData>
                    <TableData>{item.number}</TableData>
                    <TableData>
                      <TextInput
                        value={toUse[item.id]}
                        onChange={(e) => {setToUse(prev => ({...prev, [item.id]: e.target.value}));}}
                        css={{
                          display: 'inline-block',
                          marginRight: '8px',
                          width: '60%'
                        }}
                        inputCSS={{width: '100%'}}
                      />
                      <Button onClick={() => {useItems(item);}}>Use</Button>
                    </TableData>
                  </TableRow>
                );
              })}
            </React.Fragment>
          );
        })
      }
    </>
  );
}

const TableRow = styled('div')({
  padding: '16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid black',
});

const TableData = styled('div')({
  width: '33%'
});
