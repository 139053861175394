import React, { useState, useEffect } from 'react';
import { Button, TextInput } from '@components/Controls.js';
import { axios } from '@/api.js';

export default function Inventory() {
  const [inventoryManufacturers, setInventoryManufacturers] = useState([]);
  const [inventorySuppliers, setInventorySuppliers] = useState([]);

  useEffect(() => {
    axios.get('/settings', {
      params: {
        type: 'inventory',
        sub_type: 'providers',
        sub_sub_type: 'manufacturers'
      }
    })
      .then((res) => {
        setInventoryManufacturers(res.data.result.data);
      });

    axios.get('/settings', {
      params: {
        type: 'inventory',
        sub_type:'providers',
        sub_sub_type: 'suppliers'
      }
    })
      .then((res) => {
        setInventorySuppliers(res.data.result.data);
      });
  }, []);

  function addInventoryManufacturer() {
    const tempInventoryManufacturers = inventoryManufacturers;
    tempInventoryManufacturers.push('');
    setInventoryManufacturers([ ...inventoryManufacturers ]);
  }

  function removeInventoryManufacturer(index) {
    const tempInventoryManufacturers = inventoryManufacturers;
    tempInventoryManufacturers.splice(index, 1);
    setInventoryManufacturers([ ...inventoryManufacturers ]);
  }

  function changeInventoryManufacturer(index, value) {
    const tempInventoryManufacturers = inventoryManufacturers;
    tempInventoryManufacturers[index] = value;
    setInventoryManufacturers([ ...inventoryManufacturers ]);
  }

  function submitInventoryManufacturers() {
    axios.put('/settings', {
      type: 'inventory',
      sub_type: 'providers',
      sub_sub_type: 'manufacturers',
      data: inventoryManufacturers
    });
  }

  function addInventorySupplier() {
    const tempInventorySuppliers = inventorySuppliers;
    tempInventorySuppliers.push('');
    setInventorySuppliers([ ...inventorySuppliers ]);
  }

  function removeInventorySupplier(index) {
    inventorySuppliers.splice(index, 1);
    setInventorySuppliers([ ...inventorySuppliers ]);
  }

  function changeInventorySupplier(index, value) {
    const tempInventorySuppliers = inventorySuppliers;
    tempInventorySuppliers[index] = value;
    setInventorySuppliers([ ...inventorySuppliers ]);
  }

  function submitInventorySuppliers() {
    axios.put('/settings', {
      type: 'inventory',
      sub_type:'providers',
      sub_sub_type: 'suppliers',
      data: inventorySuppliers
    });
  }

  return (
    <>
      <h2>Inventory Manufacturers</h2>
      { inventoryManufacturers.map((man, index) => {
        return(
          <div key={index}>
            <TextInput
              value={man}
              css={{ display: 'inline-block' }}
              label="Manufacturer"
              onChange={(e) => {
                changeInventoryManufacturer(index, e.target.value);
              }}
            />
            <Button
              styles={{ display: 'inline-block', margin: '6px' }}
              onClick={() => { removeInventoryManufacturer(index); }}
            >Remove</Button>
          </div>
        );
      })}
      <Button
        onClick={() => { addInventoryManufacturer(); }}
        styles={{margin: '6px'}}
      >Add</Button>
      <Button
        onClick={() => { submitInventoryManufacturers(); }}
      >Submit</Button>
      <h2>Inventory Suppliers</h2>
      {inventorySuppliers.map((man, index) => {
        return(
          <div key={index}>
            <TextInput
              value={man}
              css={{ display: 'inline-block' }}
              label="Supplier"
              onChange={(e) => {
                changeInventorySupplier(index, e.target.value);
              }}
            />
            <Button
              styles={{ display: 'inline-block', margin: '6px' }}
              onClick={() => { removeInventorySupplier(index); }}
            >Remove</Button>
          </div>
        );
      })}
      <Button
        onClick={() => { addInventorySupplier(); }}
        styles={{margin: '6px'}}
      >Add</Button>
      <Button
        onClick={() => { submitInventorySuppliers(); }}
      >Submit</Button>
    </>
  );
}
