import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { axios, createHistory } from '@/api.js';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import { differenceInSeconds } from 'date-fns';

import Timer from '@components/Reusable/data/Timer.js';
import UserIcon from '@components/Reusable/user/UserIcon.js';
import Checkbox from '@mui/material/Checkbox';
import { Paper, Typography } from '@mui/material';

function JobTimer(props) {
  const [timeDiff, setTimeDiff] = useState(0);
  const [timer, setTimer] = useState();
  const [timing, setTiming] = useState({
    beginTimes: [],
    endTimes: [],
    finished: false,
    assistant_present: false
  });
  const [stoppingError, setStoppingError] = useState();
  const [stopping, setStopping] = useState(false);
  const [qtyFinished, setQtyFinished] = useState(props.quantity || 0);
  const [colors, setColors] = useState([]);
  const [note, setNote] = useState(props?.task?.note || '');
  const [pickedColors, setPickedColors] = useState();

  useEffect(() => {
    return () => { stopTimer(); };
  }, []);

  useEffect(() => {
    const currentTiming = props.task.task_timings.find((timing) => !timing.finished);
    if(currentTiming) {
      setTiming(currentTiming);
    }     
    return () => { stopTimer(); };
  }, [props.task]);

  useEffect(() => {
    checkTimerStart();
    return () => { stopTimer(); };
  }, [timing]);

  function calcTimeDiff() {
    if(!timing) return 0;
    return timing.beginTimes.reduce((accumulator, begTime, i) => {
      if(!timing.endTimes[i]) {
        return new Date().getTime() - new Date(begTime).getTime() + accumulator;
      }
      return new Date(timing.endTimes[i]).getTime() - new Date(begTime).getTime() + accumulator;
    }, 0);
  }

  function checkTimerStart() {
    if(isCurrentlyTiming()) {
      startTimer();
    } else {
      setTimeDiff(calcTimeDiff());
      stopTimer();
    }
  }

  function createTiming() {
    if(timing.beginTimes.length > 0) return;
    axios.post('/calendar/design/task/timing', {
      task_id: props.task.id,
      user_id: props.user_account_id,
      timing_start: new Date().getTime()
    })
      .then((res) => {
        props.reloadTimings();
        setTiming(res.data.result);
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error creating the timing. Please reload the page.'
        });
      });
  }

  function isCurrentlyTiming(curTiming = timing) {
    return (curTiming?.beginTimes.length > curTiming?.endTimes.length);
  }

  function pauseTiming() {
    if(!isCurrentlyTiming()) return;
    const newTiming = timing;
    if(isCurrentlyTiming()) {
      newTiming.endTimes = [...(newTiming.endTimes || []), new Date()];
    }
    setTiming({...newTiming});
    axios.put('/calendar/design/task/timing', {
      timing_id: timing.id,
      beginTimes: newTiming.beginTimes,
      endTimes: newTiming.endTimes,
      assistant_present: newTiming.assistant_present
    })
      .then(() => {
        props.reloadTimings();
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error trying to pause the timing. Please try reloading the page.'
        });
      });

    stopTimer();
  }

  function openStopping() {
    setStopping(true);
    setColors([...props.designColors]);
  }

  function updateAssistantPresent(value) {
    const newTiming = {...timing};
    newTiming.assistant_present = value;
    setTiming({...newTiming});
    axios.put('/calendar/design/task/timing', {
      timing_id: newTiming.id,
      beginTimes: newTiming.beginTimes,
      endTimes: newTiming.endTimes,
      assistant_present: newTiming.assistant_present
    })
      .then(() => {
        props.reloadTimings();
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error updating the assistant status. Please reload.'
        });
      });
  }

  function startTiming() {
    if(isCurrentlyTiming() || timing?.finished) return;
    if(timing?.id) {
      const newTiming = timing;
      newTiming.beginTimes = [...(newTiming.beginTimes || []), new Date()];
      setTiming({...newTiming});
      axios.put('/calendar/design/task/timing', {
        timing_id: newTiming.id,
        beginTimes: newTiming.beginTimes,
        endTimes: newTiming.endTimes,
        assistant_present: newTiming.assistant_present
      })
        .then(() => {
          props.reloadTimings();
        })
        .catch(() => {
          this.props.dispatch({
            type: 'ERROR/SET',
            error: 'There was an error starting the timing.'
          });
        });
      startTimer();
    } else {
      createTiming();
    }
  }

  function stopTiming() {
    if(timing.finished) return;
    const newTiming = timing;
    newTiming.finished = true;
    if(isCurrentlyTiming()) {
      newTiming.endTimes = [...(newTiming.endTimes || []), new Date()];
    }
    setTiming(newTiming);
    axios.put('/calendar/design/task/timing/stop', {
      timing_id: timing.id,
      endTimes: newTiming.endTimes,
      qty_finished: qtyFinished,
      qty_screens: colors[pickedColors]
    })
      .then((res) => {
        setTiming(res.data.result);
        stopTimer();
        props.reloadTimings();
        createHistory({
          document_id: props.document_id,
          user_id: props.user_account_id,
          type: 'printing',
          json: {
            design_id: props.design_id,
            timing,
            task: props.task,
          },
          key: 'finished_print_task'
        });
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error stopping the timing. Reload to ensure that it actually stopped.'
        });
      });
  }

  function startTimer() {
    setTimer(setInterval(() => {
      setTimeDiff(calcTimeDiff());
    }, 500));
  }

  function stopTimer() {
    if(!timer) return;
    clearInterval(timer);
  }

  return (
    <>
      <Stack
        direction="row"
        sx={{
          width: '100%',
          pr: 2,
          pt: 1,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
        >
          <IconButton 
            onClick={props.leave} 
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '140%'
            }}
          >{props.task.name}</Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <FormControlLabel 
            disabled={timeDiff === 0}
            control={
              <Checkbox 
                checked={timing?.assistant_present}
                onChange={(e) => { updateAssistantPresent(e.target.checked); }}
              />
            } 
            label={'Assistant' + (timeDiff === 0 ? ' (Enabled After Start)' : '')}
          />
          <UserIcon
            userId={props.user_account_id}
          />
        </Stack>
      </Stack>
      { props.task.timed ? 
        <>
          <Timer 
            timeDiff={timeDiff}
          />
          <Box
            sx={{
              width: '100%',
              height: '20%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Stack
              direction="row"
              spacing={2}
            >

              <IconButton
                color="primary"
                onClick={pauseTiming}
                disabled={!isCurrentlyTiming() || timing?.finished}
              >
                <PauseCircleIcon 
                  sx={{
                    transform: 'scale(2)'
                  }}
                />
              </IconButton>
              <IconButton
                color="primary"
                onClick={startTiming}
                disabled={isCurrentlyTiming() || timing?.finished}
              >
                <PlayCircleIcon 
                  sx={{
                    transform: 'scale(2)'
                  }}
                />
              </IconButton>
              <IconButton
                color="primary"
                disabled={timing.beginTimes.length < 1 || timing?.finished}
                onClick={() => { openStopping(); }}
              >
                <StopCircleIcon 
                  sx={{
                    transform: 'scale(2)'
                  }}
                />
              </IconButton>

              <Modal
                open={stopping}
                onClose={() => { setStopping(false); }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: 'white',
                    width: '33%',
                    borderRadius: '2px'
                  }}
                >
                  <Stack
                    direction="column" 
                    spacing={1}
                  >
                    <FormControl>
                      <FormLabel># of Screens:</FormLabel>
                      <RadioGroup
                        value={pickedColors}
                        onChange={(e) => {
                          setPickedColors(e.target.value);
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={0}
                          sx={{
                            flexWrap: 'wrap'
                          }}
                        >
                          {colors.map((color, i) => {
                            return (
                              <FormControlLabel 
                                key={i}
                                value={i}
                                control={<Radio />}
                                label={
                                  <TextField 
                                    size="small"
                                    value={color}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const newColors = [...colors];
                                      newColors[i] = value;
                                      setColors([...newColors]);
                                    }}
                                    sx={{
                                      width: '38px'
                                    }}
                                  />
                                }
                                sx={{ 
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: '0px'

                                }}
                              />
                            );
                          })}
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                    <TextField 
                      value={qtyFinished}
                      onChange={(e) => { setQtyFinished(e.target.value); }}
                    />
                    <Button 
                      onClick={() => {
                        if(!qtyFinished || !pickedColors || !(Number(qtyFinished) >= 0) || !(Number(pickedColors) >= 0)) {
                          setStoppingError('Make sure that the screens and qty finished are both positive numbers.');
                          return;
                        }
                        setStoppingError();
                        stopTiming();
                        setStopping(false);
                      }}
                    >Finish Task</Button>
                    <Typography
                      sx={{ color: 'danger.main' }}
                    >{stoppingError}</Typography>
                  </Stack>
                </Box>
              </Modal>
            </Stack>
          </Box>
        </>
        : 
        <Box 
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            variant="contained"
            disabled={props.task.task_timings.length && props.task.task_timings.every(timing => timing.finished) && !props.task.timed}
            onClick={() => {
              axios.post('/calendar/design/task/timing/notiming', {
                task_id: props.task.id,
                user_id: props.user_account_id
              })
                .then(() => {
                  props.reloadTimings();
                });
            }} 
          >
            Mark Finished
          </Button>
        </Box>
      }
      <TextField 
        multiline
        label="Note"
        value={note}
        sx={{
          m: 2,
          width: '96%',
        }}
        onChange={(e) => { setNote(e.target.value); }}
        onBlur={() => { 
          axios.put('/calendar/design/task/note', {
            task_id: props.task.id,
            note
          })
            .then(() => {
              props.reloadTimings();
            })
            .catch(() => {
              this.props.dispatch({
                type: 'ERROR/SET',
                error: 'There was an error updating the note. Please reload and try again.'
              });
            });
        }}
      />
      <Stack
        spacing={.5}
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          sx={{ width: '100%', pl: 4}}
        >
          Timings:
        </Box>
        {props.task.task_timings
          .filter(timing => timing.finished)
          .sort((a, b) => a.id - b.id)
          .map((time, i) => (
            <Paper
              key={i}
              sx={{
                border: '1px grey solid',
                width: '96%',
                height: '42px',
                p: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mx: 1
              }}
            >
              <UserIcon
                userId={time.userProfileUserProfileId}
                size="28px"
              />
              <Typography>
                Total Time: 
                {time.endTimes.reduce((acc, _, index) => {
                  return acc + differenceInSeconds(time.endTimes[index], time.beginTimes[index]) / 3600;
                }, 0).toFixed(3)}
              </Typography>
              <Typography>
                Screens: {time.qty_screens}
              </Typography>
              <Typography>
                Total Finished: {time.qty_finished}
              </Typography>

            </Paper>
          ))
        }
      </Stack>
    </>
  ); 
}

export default connect((state) => {
  return { user_account_id: state.app.user_account_id };
})(JobTimer);
