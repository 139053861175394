/*
 * column stuff
 * key: String; The name of the key on the column object
 * headerName: String; The header to display
 * editable: Bool = true (the entire table can be uneditable, in which case this will do nothing)
 * hideable: Bool = true (again, override on the whole table)
 * default: The value of upon new row creation. Left undefined if not set
 * type: Defaults to string
 */

export default [
  {
    key: 'colorgroup',
    headerName: 'C',
    editable: true,
    type: 'string',
    hideable: false,
    maxWidth: 50
  },
  {
    key: 'quantity',
    headerName: 'Qty',
    maxWidth: 70,
    minWidth: 50,
    editable: false,
    hideable: false
  },
  {
    key: 'stylenumber',
    headerName: 'Style',
    editable: true,
    hideable: false,
    minWidth: 180,
    type: 'stylenumber'
  },
  {
    editable: true,
    key: 'color',
    headerName: 'Color',
    maxWidth: 150,
    minWidth: 120,
    hideable: false
  },
  {
    key: 'desc',
    headerName: 'Desc',
    minWidth: 300,
    hideable: false,
    editable: false
  },
  {
    editable: true,
    key: 'nb',
    headerName: 'NB',
    minWidth: 30,
    maxWidth: 70
  },
  {
    key: 'm3',
    headerName: '3m',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 'm6',
    headerName: '6m',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 'm12',
    headerName: '12m',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 'm18',
    headerName: '18m',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 'm24',
    headerName: '24m',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 't2',
    headerName: '2t',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 't3',
    headerName: '3t',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 't4',
    headerName: '4t',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 't56',
    headerName: '5/6',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 'xs',
    headerName: 'xs',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 's',
    headerName: 's',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 'm',
    headerName: 'm',
    minWidth: 30,
    editable: true,
    maxWidth: 70
  },
  {
    key: 'l',
    headerName: 'l',
    editable: true,
    minWidth: 30,
    maxWidth: 70
  },
  {
    key: 'xl',
    headerName: 'xl',
    editable: true,
    minWidth: 30,
    maxWidth: 70
  },
  {
    key: 'xxl',
    headerName: 'xxl',
    editable: true,
    minWidth: 30,
    maxWidth: 70
  },
  {
    key: 'xl3',
    headerName: '3xl',
    editable: true,
    minWidth: 30,
    maxWidth: 70
  },
  {
    key: 'xl4',
    headerName: '4xl',
    editable: true,
    minWidth: 30,
    maxWidth: 70
  },
  {
    key: 'xl5',
    headerName: '5xl',
    editable: true,
    minWidth: 30,
    maxWidth: 70
  },
  {
    key: 'xl6',
    headerName: '6xl',
    editable: true,
    minWidth: 30,
    maxWidth: 70
  },
  {
    key: 'misc',
    headerName: 'Misc',
    editable: true,
    minWidth: 30,
    maxWidth: 70
  },
  {
    key: 'itemPrice',
    headerName: 'Item Price',
    maxWidth: 90,
    hideable: false,
    editableFunction: (row) => {
      return row.customPriceOn ?? false;
    }
  },
  {
    key: 'customPriceOn',
    headerName: 'Custom',
    hideable: false,
    type: 'checkbox',
    maxWidth: 30
  },
  {
    key: 'lineTotal',
    headerName: 'Total',
    maxWidth: 110,
    minWidth: 60,
    hideable: false,
    editable: false
  }
];
