import React from 'react';
import styled from '@emotion/styled';

const MenuWrapper = styled('div')(({theme}) => ({
  boxShadow: theme.shadows.d3
}));

export const MenuButton = styled('div')(() => ({
  background: '#fff',
  padding: 5
}));

export class ContextMenu extends React.Component{
  render(){
    const { children } = this.props;
    return(
      <MenuWrapper>
        {children}
      </MenuWrapper>
    );
  }
}
