import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from '../../utils/compose';
import { SidebarHeader, BackButton, CalendarButton } from '../../components/shell/Sidebar';
import { withLastLocation } from 'react-router-last-location';
import { startOfWeek, format } from 'date-fns';
import { axios } from '@/api.js';

class WorkorderEditSidebarHeader extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      prod_card: undefined
    };
  }

  componentDidMount() {
    axios.get('/calendar/card', {
      params: {
        document_id: this.props.document_id
      }
    })
      .then((res) => {
        if(res.data.result) {
          this.setState({ prod_card: res.data.result });
        }
      });
  }

  navigateToCalendar = () => {
    if(!this.state.prod_card) {
      this.props.history.push('/calendar');
    } else {
      this.props.history.push('/calendar/' + format(startOfWeek(this.state.prod_card.date)), 'YYYY-MM-DD');
    }
  }

  navigateToWorkcenters = () => {
    if(this.props.lastLocation) {
      this.props.history.push(this.props.lastLocation);
    } else {
      this.props.history.push('/workcenters/myorders');
    }
  }

  render() {
    return (
      <SidebarHeader>
        <BackButton onClick={this.navigateToWorkcenters}/>
        <CalendarButton onClick={this.navigateToCalendar} />
      </SidebarHeader>
    );
  }
}
export default compose(
  connect(
    state => ({
      activeWorkcenter: state.workCentersPage.activeWorkcenter
    })
  ),
  withLastLocation,
  withRouter,
)(WorkorderEditSidebarHeader);
