import React from 'react';
import { css } from 'emotion';
import { Wrapper } from '../../components/shell/Shell';
import { ConnectedFilterBar } from '../WorkCenters/WorkCenters';
import theme from '../../theme';
import { Button, Loading } from '../../components/Controls';
import { axios } from '../../api';

export default class surveyResults extends React.Component {

  state = {
    loading: false
  }

  componentDidMount() {
    const { setTitle = () => {}, pageTitle } = this.props;
    setTitle(pageTitle);
  }
  
  retrieveSurveyResults = () => {
    this.setState({ loading: true });
    axios.get(`/survey/reports/surveyresults`, { timeout: 3600000 })
      .then(res => {
        const blobUrl = window.URL.createObjectURL(
          new Blob([res.data], {
            type:
              'text/csv',
          })
        );
        const a = document.createElement('a');
        a.download = 'survey-results.csv';
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <Wrapper
        className={css({
          paddingTop: 60,
        })}
      >
        <ConnectedFilterBar
          workCenter={{
            name: 'Survey Results',
            htmlcolor: theme.primary.light,
          }}
          value={this.props.filter}
        />
        <Button
          onClick={ this.retrieveSurveyResults }
        >Get Results</Button>
        <Loading
          isOpen={this.state.loading}
        />
      </Wrapper>
    );
  }
}
