import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import Toolbar from './Toolbar';
import UploadIcon from 'react-icons/lib/md/cloud-upload';
import DownloadIcon from 'react-icons/lib/md/cloud-download';
import { uploadDocumentImages } from '../../queries';
import { axios, createHistory } from '../../api.js';

const Button = styled('button')(
  {
    background: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    borderRadius: 3,
    margin: '0px 2px',
    appearance: 'none',
    paddingLeft: 7,
    paddingRight: 7,
    textDecoration: 'none',
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  ({ theme }) => ({
    '&:hover': {
      background: theme.neutral.light,
    },
  })
);

const LabelAsButton = Button.withComponent('label');

const StyledLink = Button.withComponent('a');

const Label = styled('span')({
  fontSize: 12,
  textTransform: 'uppercase',
  fontWeight: 600,
  paddingTop: 2,
});

const HiddenFileInput = styled('input')({
  position: 'fixed',
  left: -1,
  top: -1,
  width: 1,
  height: 1,
  opacity: 0,
});

export default class GalleryToolbar extends React.Component {

  state = {
    uploadProgress: null,
    uploadStatus: null,
  };

  downloadFile = (downloadURL, filename, backupURL = undefined) => {
    console.log(downloadURL);
    console.log(backupURL);
    axios({
      method:'GET',
      url: downloadURL,
      responseType: 'blob'
    })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if(res.data.type === 'text/html') {
          throw new Error('404');
        }
        const blobUrl = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/illustrator'
          })
        );
        const a = document.createElement('a');
        a.download = filename;
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch(() => {
        if(backupURL){
          axios({
            method: 'GET',
            url: backupURL,
            responseType: 'blob'
          })
            .then((res) => {
              const blobUrl = window.URL.createObjectURL(
                new Blob([res.data], {
                  type: 'application/illustrator'
                })
              );
              const a = document.createElement('a');
              a.download = filename;
              a.href = blobUrl;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);

            });
        }
      });
  };

  uploadFile = e => {
    this.setState({
      uploadStatus: 'uploading',
    });
    uploadDocumentImages({
      file: e.target.files[0],
      document_id: this.props.document_id,
      onProgress: this.onUploadProgress,
      onComplete: this.onUploadComplete,
      onError: this.onUploadError,
    });
  };

  onUploadProgress = e => {
    this.setState({
      uploadProgress: Math.round((e.loaded * 100) / e.total),
    });
  };

  onUploadComplete = () => {
    this.props.refetch
      ? window.setTimeout( () => {
        createHistory({
          document_id: this.props.document_id.toString(),
          type: 'art',
          key: 'art',
          user_id: this.props.user_account_id,
        });
        this.props.refetch();
        this.setState({
          uploadStatus: 'success',
        });
      }, 4000)
      : console.log('Document refetch isn\'t defined.');
  };

  onUploadError = () => {
    this.setState({
      uploadStatus: 'failed',
    });
    window.setTimeout(this.resetUploadState, 3000);
  };

  resetUploadState = () => {
    this.setState({
      uploadStatus: null,
      uploadProgress: null,
    });
  };
  
  render() {
    const {
      downloadPath = '',
      backupDownloadPath = ''
    } = this.props;
    return (
      <div
        className={css({
          perspective: 1000,
          width: '100%',
        })}
      >
        <div
          className={css({
            width: '100%',
            transformStyle: 'preserve-3d',
            position: 'relative',
            transition: 'transform 300ms',
          })}
          style={{
            transform:
              this.state.uploadStatus === 'uploading' ||
              this.state.uploadStatus === 'failed'
                ? 'rotateX(180deg)'
                : '',
          }}
        >
          <Toolbar
            label="Artwork"
            className={css({
              backfaceVisibility: 'hidden',
            })}
            buttons={
              <Fragment>
                <StyledLink
                  onClick={() => {
                    this.downloadFile(downloadPath, (this.props.document_id + '-' + this.props.jobname + '.ai').replace(/ /g, '_'), backupDownloadPath);
                  }}
                  //href={downloadPath}
                  //download
                  //download={(this.props.document_id + " " + this.props.jobname + ".ai").replace(/ /g, '_')}
                >
                  <div className={css({ paddingBottom: 2, marginRight: 5 })}>
                    <DownloadIcon />
                  </div>
                  <Label> Download</Label>
                </StyledLink>
                <LabelAsButton htmlFor="art-upload-input">
                  <div className={css({ paddingBottom: 2, marginRight: 5 })}>
                    <UploadIcon />
                  </div>
                  <Label> Upload</Label>
                </LabelAsButton>
                <HiddenFileInput
                  type="file"
                  onChange={this.uploadFile}
                  id="art-upload-input"
                  name="art-upload-input"
                  accept=".pdf,.ai"
                />
              </Fragment>
            }
          />
          <UploadFace failed={this.state.uploadStatus === 'failed'}>
            {this.state.uploadStatus === 'failed'
              ? 'Something went wrong'
              : 'Uploading...'}
          </UploadFace>
        </div>
      </div>
    );
  }
}

const UploadFace = styled('div')(({ theme, failed }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: 4,
  background: failed ? theme.danger.color : theme.vibrant.color,
  transition: 'background 150ms',
  transform: 'rotateX(180deg)',
  backfaceVisibility: 'hidden',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  padding: 10,
}));
