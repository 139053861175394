
import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import ColumnsIcon from 'react-icons/lib/md/view-column';
import { ColumnSelectorModal } from './DataGrid.ColumnSelector';
import AddIcon from 'react-icons/lib/md/add-circle';
import FilterList from 'react-icons/lib/md/filter-list';

export default class Toolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectorCoordinates: { x: 0, y: 0 },
      columnSelectorOpen: false,
      sliderOpen: false
    };
  }

  closeColumnSelector = () => {
    this.setState({columnSelectorOpen: false});
  };

  getHideableColumns = () => this.props.columns.filter((column) => !(column.hideable === false));

  getToolbarPosition = () => {
    const toolbar = this.toolbar.getBoundingClientRect();
    return { x: toolbar.x, y: toolbar.y };
  };

  openColumnSelector = () => {
    this.setState({columnSelectorOpen: true, selectorCoordinates: this.getToolbarPosition()});
  };

  render() {
    const {
      updateHiddenColumns,
      columnsHideable,
      hiddenColumns,
      addRow,
      autoSort
    } = this.props;

    return (
      <Wrapper
        ref={toolbar => {
          this.toolbar = toolbar;
        }}
      >

        {columnsHideable === true &&
         <>
           <ColumnSelectorModal
             hiddenColumns={hiddenColumns}
             onChange={updateHiddenColumns}
             hideableColumns={this.getHideableColumns()}

             isOpen={this.state.columnSelectorOpen}
             onModalClose={this.closeColumnSelector}
             coordinates={this.state.selectorCoordinates}
           />
           <ToolbarButton
             onClick={this.openColumnSelector}
           >
             <IconWrapper>
               <ColumnsIcon />
             </IconWrapper>
             <Label>Manage Columns</Label>
           </ToolbarButton>
         </>
        }

        {!this.props.readOnly && <ToolbarButton
          onClick={addRow}
        >
          <IconWrapper className={css({width: 18})}>
            <AddIcon />
          </IconWrapper>
          <Label>Add Row</Label>
        </ToolbarButton>}

        { !this.props.hideAutoSort &&
          <ToolbarButton
            onClick={autoSort}
          >
            <IconWrapper className={css({width: 18})}>
              <FilterList />
            </IconWrapper>
            <Label>Auto Sort</Label>
          </ToolbarButton>
        }

        { this.props.drawerContent && <ToolbarButton
          onClick={this.props.toggleDrawer}
        >
          <IconWrapper className={css({width: 18})}>
            <AddIcon />
          </IconWrapper>
          <Label>{this.props.drawerText}</Label>
        </ToolbarButton>}
      </Wrapper>
    );
  }
}

const ToolbarButton = styled('button')(({ theme }) => ({
  border: 'none',
  borderRadius: 3,
  background: 'transparent',
  padding: '5px 8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.primary.color,
  fontSize: 11,
  fontFamily: theme.primary.fontFamily,
  cursor: 'pointer',
  '&:hover': {
    background: 'rgb(238, 238, 238)'
  },
  '&:active': {
    background: 'rgb(228, 227, 227)'
  },
  '&:focus': {
    outline: 'none'
  }
}));

const Wrapper = styled('div')({
  width: '100%',
  padding: '5px 0px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row'
});

const IconWrapper = styled('div')(({ theme }) => ({
  margin: -3,
  marginRight: 3,
  width: 25,
  flex: '0 0 auto',
  color: theme.neutral.light,
  '& svg': {
    width: '100%',
    height: '100%'
  }
}));

const Label = styled('span')({
  paddingTop: 2
});
