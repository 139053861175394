
import React, { useState, ReactNode } from 'react';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

interface PopoverSelectProps {
  open: boolean,
  onClose?: () => void,
  anchorEl?: Element,
  mapItems: (arg0: string) => ReactNode[]
}

export default function PopoverSelect(props: PopoverSelectProps) {
  const [filter, setFilter] = useState<string>('');

  return(
    <Popover
      open={props.open}
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      PaperProps={{
        style: {
          maxHeight: '400px'
        }
      }}
    >
      <TextField
        variant="filled"
        label="Filter"
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      ></TextField>
      <Stack
        direction="column"
      >
        {props.mapItems(filter)}
      </Stack>
    </Popover>
  );
}
