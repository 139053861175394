import React from 'react';
import { connect } from 'react-redux';
import {
  nonSizeColumnsArray,
  resolveLinesConfig,
  defaultColumnsNull,
  template
} from '../../data/linesTemplates.js';
import {
  axios,
  createHistory
} from '../../api.js';
import Grid from './Grid.js';

class V2EditGrid extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lines: props.lines,
      linesConfig: props.linesConfig,
      version: 2
    };
  }

  componentDidMount() {
    this.getLines();
  }

  getLines = (addHistory=false, event={}) => {
    axios.get('/document', {
      params: {
        id: this.props.document_id,
        attributes: [
          'lines',
          'linesConfig',
          'customer_billto',
          'version',
          'total'
        ]
      },
    })
      .then((res) => {
        console.log(res);
        const lines = res.data.document.lines;
        const linesConfig = res.data.document.linesConfig;
        const total = res.data.document.total;
        const version = res.data.document.version;

        this.props.updateTotal(total);

        if(addHistory) {
          const historyEvent = event;
          historyEvent.json = document.lines;
          createHistory(historyEvent);
        }

        this.setState({
          lines,
          linesConfig,
          version
        });
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error trying to update the lines.'
        });
      });
  };

  setActiveColumns = activeColumns => {
    const { billto } = this.props.billto;

    const filteredColumns = Object.entries(activeColumns).reduce((obj, col) => {
      if (col[1] && !nonSizeColumnsArray.includes(col[0])) {
        return { ...obj, [col[0]]: true };
      } else {
        return obj;
      }
    }, {});

    const linesConfig = this.state.linesConfig || {};
    const newActiveColumns = {
      ...linesConfig,
      activeColumns: filteredColumns
    };
    this.setState({ linesConfig: newActiveColumns });
    axios.put('/document/lines', {
      id: this.props.document_id,
      lines: this.state.lines,
      linesConfig: newActiveColumns,
      billto,
      user_account_id: this.props.user_account_id
    })
      .then(() => {
        createHistory({
          document_id: this.props.document_id,
          json_previous: JSON.stringify(this.state.linesConfig),
          json: JSON.stringify(activeColumns),
          type: 'columns',
          key: 'linesConfig',
          user_id: this.props.user_account_id,
        });
      });

  };


  updateLines = lines => {
    const linesConfig = this.props.linesConfig ? this.props.linesConfig : { activeColumns: defaultColumnsNull };

    // Need to have the event here because the lines change between now and the promise fulfillment
    const event = {
      document_id: this.props.document_id,
      json_previous: this.state.lines,
      json: lines,
      type: 'lines',
      key: 'lines',
      user_id: this.props.user_account_id,
    };

    axios.put('/document/lines', {
      id: this.props.document_id,
      lines: lines ? lines : this.state.lines,
      linesConfig,
      user_account_id: this.props.user_account_id
    })
      .then(() => {
        if(JSON.stringify(this.state.lines) !== JSON.stringify(lines)) {
          //createHistory(event);
        }
        this.getLines(true, event);
      })
      .catch((e) => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error trying to update the lines.'
        });
        console.log(e);
      });
  };

  render() {
    const {
      lines,
      linesConfig,
      version
    } = this.state;

    const activeColumns = resolveLinesConfig(linesConfig, version);
    return (
      <Grid
        separatorLines={false}
        template={template.filter(t => activeColumns[t.id])}
        newRowTemplate={template}
        hideableColumns={template.filter(t => t.type === 'size')}
        activeColumns={activeColumns}
        setActiveColumns={this.setActiveColumns}
        lines={lines}
        updateLines={this.updateLines}
        disableOnClickOutside={false}
        ref={grid => {
          this.grid = grid;
        }}
        editLockable={true}
      />
    );
  }

}

export default connect((state) => ({
  user_account_id: state.app.user_account_id,
}))(V2EditGrid);
