import React, { useState, useEffect } from 'react';
import Modal from '../../Reusable/layout/Modal.tsx';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { axios } from '../../../api.js';
import designGroup from '../../../types/models/designGroup.ts';
import DataGrid from '../../Reusable/data/DataGrid/DataGrid.js';
import columns from '../../Reusable/data/DataGrid/template.js';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ComissionsProps {
  document_id: number,
  open: boolean,
  onClose: () => void
}

export default function Comissions(props:ComissionsProps) {

  const [markup, setMarkup] = useState<string>('0');
  const [markupLines, setMarkupLines] = useState<designGroup[]>([]);
  const [nonMarkupLines, setNonMarkupLines] = useState<designGroup[]>([]);
  const [taxedTotal, setTaxedTotal] = useState<number>(0);

  useEffect(() => {

    if(props.open === false) return;

    axios.get('/document', {
      params: {
        id: props.document_id,
        attributes: [
          'commission_markup_percent',
          'document_id'
        ]
      }
    })
      .then((res) => {
        setMarkup(String(res.data.document.commission_markup_percent) || '0');
      });

    getLines();
    getNonMarkupLines();

  }, [props.open]);

  function getLines(): void {
    axios.get('/lines', {
      params:{ document_id: props.document_id }
    })
      .then((res) => {
        setTaxedTotal(res.data.total);
        setMarkupLines(res.data.results);
      });
  }

  function getNonMarkupLines(): void {
    axios.get('/lines', {
      params: {
        document_id: props.document_id,
        without_commission: true
      }
    })
      .then((res) => {
        setNonMarkupLines(res.data.results);
      });
  }

  function getTotalFromLines(lines: designGroup[]): number {
    return lines.reduce((total, design) => {
      return design.orderLines.reduce((designTotal, line) => {
        return Number((line.lineTotal ?? 0)) + designTotal;
      }, 0) + total;
    }, 0);
  }

  function updateMarkup(): void {
    axios.put('/document/singleField', { 
      id: props.document_id,
      field: 'commission_markup_percent',
      fieldValue: Number(markup),
      updateLines: true
    })
      .then(() => {
        getNonMarkupLines();
        getLines();
      });
  }

  const markupTotal : number = Math.round(getTotalFromLines(markupLines) * 100) / 100;
  const nonMarkupTotal : number = Math.round(getTotalFromLines(nonMarkupLines) * 100) / 100;
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      title="Commissions"
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'center'}}
      >
        <Stack
          direction="column"
          alignItems="center"
          width="90%"
          spacing={2}
        >
          <TextField
            variant="outlined"
            label="Markup"
            size="small"
            sx={{
              width: '30%',
            }}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            onChange={(e) => { 
              setMarkup(e.target.value); 
            }}
            onBlur={updateMarkup}
            value={markup}
            error={isNaN(Number(markup))}
          ></TextField>
          <Typography
            fontWeight={800}
            variant="h6"
          >New (Taxed) Total: {taxedTotal}</Typography>
          <Typography
            fontWeight={800}
            variant="h6"
          >Total Difference (Untaxed): {markupTotal} - {nonMarkupTotal} = {(Math.round((markupTotal - nonMarkupTotal) * 100) / 100).toFixed(2)}</Typography>
          <Stack
            spacing={1}
          >
            <Typography
              fontWeight={800}
              variant="h6"
            >
              WITH COMMISSION - {markupTotal}
            </Typography>
            {markupLines.map(design => {
              return (
                <div
                  key={design.id}
                >
                  <Typography
                    fontWeight={800}
                  >
                    D{design.designNumber} - {design.fPrint}x{design.bPrint}x{design.lPrint}x{design.rPrint}{design.otherPrints?.map((print) => 'x' + print)}
                  </Typography>
                  <DataGrid
                    key={design.id}
                    rows={design.orderLines}
                    hiddenColumns={design.hiddenColumns}
                    columnsHideable={false}
                    readOnly={true}
                    columns={columns}
                  />
                </div>
              );
            })}
          </Stack>
          <Stack
            spacing={1}
          >
            <Typography
              fontWeight={800}
              variant="h6"
            >
              WITHOUT COMMISSION - {nonMarkupTotal}
            </Typography>
            {nonMarkupLines.map(design => {
              return (
                <div
                  key={design.id}
                >
                  <Typography
                    fontWeight={800}
                  >
                    D{design.designNumber} - {design.fPrint}x{design.bPrint}x{design.lPrint}x{design.rPrint}{design.otherPrints?.map((print) => 'x' + print)}
                  </Typography>
                  <DataGrid
                    rows={design.orderLines}
                    hiddenColumns={design.hiddenColumns}
                    columnsHideable={false}
                    readOnly={true}
                    columns={columns}
                  />
                </div>
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );

}
