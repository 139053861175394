import React, { useState, useEffect } from 'react';
import { axios } from '@/api.js';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';

export default function Calendar() {

  const [printingTasks, setPrintingTasks] = useState([]);

  const [prepSteps, setPrepSteps] = useState({
    steps: [{
      step: ''
    }]
  });

  const [designSteps, setDesignSteps] = useState({
    steps: [{
      step: ''
    }]
  });

  const [pickers, setPickers] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    getPrintingTasks();
    fetchPrepSteps();
    fetchDesignSteps();
    axios.get('/settings', {
      params: {
        type: 'calendar',
        sub_type: 'event',
        sub_sub_type: 'tasks'
      }
    })
      .then((res) => {
        setEventTypes(res.data.result.data);
      });
  }, []);

  function addTaskToPrinting() {
    setSubmitted(false);
    setPrintingTasks([...printingTasks, {
      name: '',
      default: false,
      report: false,
      timing: true,
      reportSymbol: ''
    }]);
  }

  function deletePrintingTask(index) {
    const newPrintingTasks = printingTasks;
    newPrintingTasks.splice(index, 1);
    setSubmitted(false);
    setPrintingTasks([ ...newPrintingTasks ]);
  }

  function fetchPrepSteps() {
    axios.get('/settings', {
      params: {
        type: 'calendar',
        sub_type: 'steps',
        sub_sub_type: 'prep'
      }
    })
      .then((res) => {
        setPrepSteps(res.data.result.data);
      });
  }

  function fetchDesignSteps() {
    axios.get('/settings', {
      params: {
        type: 'calendar',
        sub_type: 'steps',
        sub_sub_type: 'design'
      }
    })
      .then((res) => {
        setDesignSteps(res.data.result.data);
      });
  }

  function getPrintingTasks() {
    axios.get('/settings', {
      params: {
        type: 'calendar',
        sub_type: 'printing',
        sub_sub_type: 'tasks'
      }
    })
      .then((res) => {
        setPrintingTasks(res.data.result.data);
      });
  }

  function submitPrintingTasks() {
    axios.put('/settings', {
      type: 'calendar',
      sub_type: 'printing',
      sub_sub_type: 'tasks',
      data: printingTasks
    })
      .then(() => {
        setSubmitted(true);
      });
  }

  function updatePrintingTask(value, index, key) {
    setSubmitted(false);
    printingTasks[index][key] = value;
    setPrintingTasks([...printingTasks]);
  }

  return (
    <>
      <h2>Printing Tasks</h2>
      <Stack 
        direction="column"
        spacing={1}
      >
        { printingTasks.map((task, i) => {
          return(
            <Stack 
              direction="row"
              key={i}
              alignItems="center"
              spacing={1}
            >
              <TextField
                label="Name"
                value={task.name}
                onChange={(e) => { updatePrintingTask(e.target.value, i, 'name'); }}
              ></TextField>
              <FormControlLabel
                label="Default"
                control={
                  <Checkbox
                    checked={task.default}
                    onChange={(e) => { updatePrintingTask(e.target.checked, i, 'default'); }} />
                }
              />
              <FormControlLabel
                label="Report"
                control={
                  <Checkbox
                    checked={task.report}
                    onChange={(e) => { updatePrintingTask(e.target.checked, i, 'report'); }} />
                }
              />
              <TextField
                label="Report Symbol"
                value={task.reportSymbol}
                onChange={(e) => { updatePrintingTask(e.target.value, i, 'reportSymbol'); }}
                disable={!task.report}
              ></TextField>
              <FormControlLabel
                label="Timing"
                control={
                  <Checkbox
                    checked={task.timing}
                    onChange={(e) => { updatePrintingTask(e.target.checked, i, 'timing'); }} />
                }
              />
              <FormControlLabel 
                label="Hide User Profile"
                control={
                  <Checkbox
                    checked={task.hideUser}
                    onChange={(e) => { updatePrintingTask(e.target.checked, i, 'hideUser'); }}
                  />
                }
              />
              <IconButton
                onClick={() => { deletePrintingTask(i); }}
              ><DeleteIcon /></IconButton>
            </Stack>
          );
        })}
        <Button
          variant="contained"
          onClick={() => { addTaskToPrinting(); }}
          sx={{ width: '200px' }}
        >Add Printing Task</Button>
        <Button
          variant="contained"
          onClick={() => { submitPrintingTasks(); }}
          sx={{ width: '200px' }}
        >Submit</Button>
        {submitted && <p>Submission Succeeded!</p>}
      </Stack>
    </>
  );
}
