import React from 'react';
import { css } from 'emotion';
import { axios } from '@/api.js';
import Logo from '@/common/img/logo-pdf.svg';
import styled from '@emotion/styled';
import { getFilteredTemplate, getFilteredNames, colemanTemplate } from '@/data/linesTemplates';
import { namesTemplate } from '@/data/linesTemplates';
import stripes from '@/common/img/shipping-stripes.svg';
import { BASE_URL } from '@/api';
import format from 'date-fns/format';
import Tags from './Tags';
import LabelIcon from 'react-icons/lib/md/label';
import { SmallLabel } from '@components/Typography';
import './Workorder.FullPDF.css';
import Barcode from 'react-barcode';
import Lines from '@components/Collections/StaticLines';
import DataGrid from '@components/Reusable/data/DataGrid/DataGrid.js';
import columns from '@components/Reusable/data/DataGrid/pdfTemplate.js';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DesignsDueDate from '../../components/Reusable/lines/DesignsDueDate';
import theme from '../../theme';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

export default class FullWorkorderPDF extends React.Component {

  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      document_id: props.match.params.document_id,
      designs: [],
      document: undefined
    };
  }

  componentDidMount = () => {
    this.getLines();
    this.getDocument();
  };

  defaultColumns = () => {
    const activeColumns = {};
    for (const key in namesTemplate) {
      const id = namesTemplate[key].id;
      activeColumns[id] = true;
    }

    return activeColumns;
  };

  getDocument = () => {
    axios.get('/document', {
      params: {
        id: this.state.document_id,
        includeQBAccount: true
      }
    })
      .then((res) => { this.setState({ document: res.data.document }); });
  }

  // Gets V3 Lines
  getLines = () => {
    axios.get('/lines', {
      params: {
        document_id: this.state.document_id
      }
    })
      .then((res) => {
        this.setState({ designs: res.data.results });
      });
  };

  // Gets V3 Lines
  getLines = () => {
    axios.get('/lines', {
      params: {
        document_id: this.state.document_id
      }
    })
      .then((res) => {
        this.setState({ designs: res.data.results });
      });
  };

  order_details = (details) => {
    const ret = this.placeholder();
    details.map(detail => {
      ret[detail.routeorder] = details[details.map(function(e) { return e.attributename; }).indexOf(detail.attributename)];
    });
    return ret;
  };

  placeholder = () => {
    const array = [];
    for (let i = 0; i < 12; i++) {
      array[i] = { id: i };
    }
    return array;
  };

  render() {
    const { hideProductionNotes = false } = this.props;
    const { document } = this.state;
    
    const filteredTemplate = document
      ? getFilteredTemplate(
        document.linesConfig || {},
        document.version
      ).filter(t => t.id !== 'customprice')
      : [];
    const filteredNames = document
      ? getFilteredNames(
        document.namesNumsLinesConfig || this.defaultColumns(),
      )
      : [];

    return document ? (
      <PageWrapper
        hasBorder={
          document.priority === 'Rush' ||
          document.priority === 'Must'
        }
        hasshipping={document.hasshipping}
      >
        {document.priority === 'Rush' && <Outline />}
        {document.priority === 'Must' && <Outline must />}
        {document.hasshipping ? (
          <ShippingStripes src={stripes} />
        ) : null}
        <Header
          total={Number(document.total)}
          duedate={document.duedate}
          prodDate={document.production_card?.date}
          id={document.document_id}
          version={document.version}
        />
        {hideProductionNotes && <Disclaimer date={document.createdAt} />}
        <Content
          document={document}
          details={this.order_details(document.document_detail)}
          followers={document.followers_documents_link}
          hideProductionNotes={hideProductionNotes}
          tags={document.tags_of_document}
        />
        <Instructions instructions={document.specialinstructions} />
        <div
          className={css({
            marginTop: 30,
          })}
        >
          {
            document.version === 3 ?
              <div>
                { this.state.designs.map((design, i) => {
                  return (
                    <Box
                      key={i}
                      mb={2}
                      sx={{
                        pageBreakInside: 'avoid'
                      }}
                    >
                      <Typography>DESIGN {design.designNumber}</Typography>
                      <Typography>Prints:
                        <Box fontWeight="fontWeightHeavy" display="inline"> {design.fPrint} x {design.bPrint} x {design.lPrint} x {design.rPrint}
                          { (design.otherPrints ?? []).map((print) => {
                            return ' x ' + print;
                          }) }
                        </Box>
                      </Typography>
                      {!hideProductionNotes && design.completion_time_hours && <Typography>
                        Production Time Estimate: 
                        <Box fontWeight="fontWeightHeavy" display="inline">
                          {design.completion_time_hours} hrs
                        </Box>
                      </Typography>}
                      <Typography>Total Items: {design.itemQuantity}</Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        flexWrap="wrap"
                        sx={{ mb: 1 }}
                      >
                        <Typography>Customizations: </Typography>
                        {design.design_customizations.map((customization) => {
                          return (
                            <Chip
                              key={customization.id}
                              sx={{ mb: 1}}
                              label={`${customization.name} - $${customization.price.toFixed(2)}`}
                              color="primary"
                            />
                          );
                        })}
                      </Stack>
                      <DataGrid
                        rootId="lines"
                        rows={design.orderLines}
                        hiddenColumns={design.hiddenColumns}
                        columnsHideable={false}
                        columns={columns.filter(column => column.key !== 'customPriceOn')}
                        readOnly={true}
                      />
                    </Box>
                  );
                })}
              </div> :
              <Lines
                lines={document?.lines || []}
                template={[
                  ...filteredTemplate.slice(0, 4),
                  { label: 'Description', id: 'description' },
                  ...filteredTemplate.slice(4),
                ]}
                separatorLines={true}
              />
          }

        </div>
        { document.names_and_nums_on &&
          <div
            className={css({
              marginTop: 30,
              marginBottom: 25
            })}
          >
            <Lines
              lines={document?.names_and_nums || []}
              template={filteredNames}
            />
          </div> 
        }
        { document.coleman_on && document.coleman_lines &&
          <div
            className={css({
              marginTop: 30,
              marginBottom: 25
            })}
          >
            <p>Coleman Order:</p>
            <ColemanRow>
              <ColemanCol>School: {document.coleman_lines.school}</ColemanCol>
              <ColemanCol>Size: {document.coleman_lines.size} </ColemanCol>
              <ColemanCol>Year: {document.coleman_lines.year}</ColemanCol>
            </ColemanRow>
            <ColemanRow>
              <ColemanCol>Special Body Length: {document.coleman_lines.specialLengthBody}</ColemanCol>
              <ColemanCol>Special Sleeve Length: {document.coleman_lines.specialLengthSleeve}</ColemanCol>
              <ColemanCol>Sleeve Color: {document.coleman_lines.sleeveColor}</ColemanCol>
            </ColemanRow>
            <ColemanRow>
              <ColemanCol>Body Color: {document.coleman_lines.bodyColor}</ColemanCol>
              <ColemanCol>Monogramming: {document.coleman_lines.monogramming}</ColemanCol>
              <ColemanCol>Monogramming Line: {document.coleman_lines.monogrammingLine}</ColemanCol>
            </ColemanRow>
            <ColemanRow>
              <ColemanCol>Name: {document.coleman_lines.name}</ColemanCol>
              <ColemanCol>Name Font: {document.coleman_lines.nameFont}</ColemanCol>
            </ColemanRow>
            { document.coleman_lines.additional_emblems &&
              <Lines
                lines={document?.coleman_lines?.additional_emblems || []}
                template={colemanTemplate}
              />
            }
          </div>
        }
        {document.customerartobject && (
          <ArtFiles art_object={document.customerartobject} />
        )}
      </PageWrapper>
    ) : null;
  }
}

const PageWrapper = styled('div')(
  ({ hasBorder }) =>
    hasBorder && {
      padding: 18,
    },
  ({ hasshipping }) =>
    hasshipping && {
      paddingTop: 18,
    },
);

const Outline = styled('div')(
  {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
  ({ must }) => ({
    border: `6px solid ${must ? '#ffa22a' : 'rgb(227,61,61)'}`,
  })
);

const ColemanRow = styled('div')({
  marginBottom: '8px'
});

const ColemanCol = styled('span')({
  margin: '10px',
  fontWeight: 400
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Header = ({ total, duedate, prodDate, id, version }) => (
  <StyledHeader>
    <Branding />
    <Barcode value={'~01' + id} height="35" fontSize="10"/><br/>
    {version < 3 ? <div className={css({ marginLeft: 'auto' })}>
      <ProdDate date={prodDate} />
    </div> : 
      <div className={css({ marginLeft: 'auto'})}>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          })}
        >
          <Label>Production</Label>
          <DesignsDueDate 
            document_id={id}
            style={{
              color: theme.blue.color,
              padding: 0,
              margin: 0,
              fontSize: 28,
              height: 28,
              flex: 0
            }}
            placeholder="None"
          />
        </div>
      </div>
    }
    <div className={css({ marginLeft: 50 })}>
      <DueDate duedate={duedate} />
    </div>
    <div className={css({ marginLeft: 50 })}>
      <Total total={total} />
    </div>
  </StyledHeader>
);

const StyledHeader = styled('header')({
  width: '100%',
  height: 70,
  display: 'flex',
  flexDirection: 'row',
  borderBottom: '1px solid rgb(159, 159, 159)',
});

const Branding = () => (
  <Row>
    <img
      id="branding"
      alt="branding"
      className={css({
        maxHeight: '100%',
        padding: 0,
        width: 300,
      })}
      src={Logo}
    />
  </Row>
);

const Price = styled('span')(
  {
    fontSize: 28,
  },
  ({ theme, blue }) => ({
    color: blue ? theme.blue.color : theme.success.color,
  })
);

const Label = styled('label')({
  fontSize: 13,
  color: 'rgb(161, 161, 161)',
});

const Total = ({ total }) => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    })}
  >
    <Label>Total</Label>
    <Price>${total.toFixed(2)}</Price>
  </div>
);

const DueDate = ({ duedate }) => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    })}
  >
    <Label>In Hands</Label>
    <Price blue>{duedate ? format(duedate, 'MMM D, YYYY') : 'None'}</Price>
  </div>
);

const ProdDate = ({ date }) => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    })}
  >
    <Label>Production</Label>
    <Price blue>{date ? format(date, 'MMM D, YYYY') : 'None'}</Price>
  </div>
);

const Content = ({
  document: {
    customQuickbooksBillTo,
    document_id,
    jobname,
    priority,
    shipNote,
    customer,
    customer_billto,
    isreorder,
    isweborder,
    ismodro,
    lastorder,
    hasshipping,
    followers_documents_link: followers,
    tags_of_document: tag,
    quickbooksAccount
  },
  hideProductionNotes,
  details
}) => (
  <React.Fragment>
    <Row
      className={css({
        marginTop: 30,
      })}
    >
      <Column>
        <TitleBar
          document_id={document_id}
          jobname={jobname}
          priority={priority}
          isreorder={isreorder}
          isweborder={isweborder}
          ismodro={ismodro}
        />
      </Column>
    </Row>
    <Row style={{ marginTop: 5 }}>
      <Column>
        <Customers
          customer={customer}
          billTo={customer_billto}
          qbAccount={quickbooksAccount}
          shipNote={(hasshipping) ? shipNote : null}
          customBillTo={customQuickbooksBillTo}
        />
        {isreorder ? (
          <Row style={{ marginTop: 20 }}>
            <span style={{ fontWeight: 400, marginRight: 10 }}>
              Last Ordered:
            </span>{' '}
            {(lastorder) ? format(lastorder, 'M/D/YYYY') : 'Not Available'}
          </Row>
        ) : null}
      </Column>
      {!hideProductionNotes && (
        <Column  className={css({ marginLeft: 'auto' })}>
          Followers:
          <ul>
            {followers.map(follower => {
              return (
                <li key={follower.id}>{`${follower.user_profile.first_name} ${follower.user_profile.last_name}`}</li>
              );
            })}
          </ul>
        </Column>
      )}
      {!hideProductionNotes && (
        <Column className={css({ marginLeft: 'auto'})}>
          <SmallLabel icon={<LabelIcon />} label="Tags" />
          <Tags
            tags={tag}
            readOnly
            size={'large'}
          />
        </Column>
      )}
      {!hideProductionNotes && (
        <Column className={css({ marginLeft: 'auto' })}>
          <Details details={details} />
        </Column>
      )}
    </Row>
  </React.Fragment>
);

const Value = styled('span')(
  {
    fontSize: 32,
  },
  ({ neutral, theme }) => ({
    color: neutral ? theme.neutral.color : '#000',
  })
);

const TitleBar = ({ document_id, jobname, priority, isreorder, isweborder, ismodro }) => (
  <Row>
    <Column className={css({ marginRight: 30 })}>
      <Label>Order ID</Label>
      <Value neutral>{document_id}</Value>
    </Column>
    <Column>
      <Label>Job Name</Label>
      <Value>
        {jobname}
        {' ' /* For spacing purposes */}
        {priority === 'Rush' ? <Tag>RUSH</Tag> : null}
        {priority === 'Must' ? <Tag must>MUST</Tag> : null}
        {isreorder ? <Tag reorder>Re-Order</Tag> : null}
        {isweborder ? <Tag weborder>WEB-ORDER</Tag> : null}
        {ismodro ? <Tag modro>MODRO</Tag> : null}
      </Value>
    </Column>
  </Row>
);

const Disclaimer = props =>
{
  const createdDate = new Date(props.date);
  createdDate.setDate(createdDate.getDate() + 30);

  const todayDate = new Date();


  if(createdDate < todayDate) {
  
    return (
      <Row>
        <Column style={{alignItems: 'center', justifyContent: 'center', width: '100%'}}>
          <h3 style={{marginBottom: 0}}>
            ************************* THIS IS NOT AN INVOICE. FOR REFERENCE ONLY.
            *************************
          </h3>
          <p>
            THESE ITEM PRICES ARE OLD AND WILL NO LONGER BE HONORED.
            TO GET UPDATED PRICES PLEASE TALK TO SOMEONE AT BADGER.
          </p>
        </Column>
      </Row>
    );

  } else {

    return (
      <Row>
        <Column style={{alignItems: 'center', justifyContent: 'center', width: '100%'}}>
          <h3 style={{marginBottom: 0}}>
            ************************* THIS IS NOT AN INVOICE. FOR REFERENCE ONLY.
            *************************
          </h3>
          <p>
            ITEM PRICES WILL BE HONORED UNTIL {createdDate.toLocaleDateString()}. SIZES XXL
            ARE $2 MORE AND EVERY ADDITIONAL X IS AN ADDED $0.50.
          </p>
        </Column>
      </Row>
    );

  }

};


const Details = ({ details }) => (
  <Column>
    {details.map(det => (
      (det) ? <Detail {...det} key={det.id} /> : ''
    ))}
    <Row
      className={css({
        borderTop: '1px solid #333',
        '&:last-child': {
          borderBottom: '1px solid #333',
        },
        borderLeft: '1px solid #333'
      })}
    >
      <DetailValue
        style={{
          width: '37px'
        }}
      ></DetailValue>
      <DetailLabel
        style={{ width: '100px' }}
      >Picked up</DetailLabel>
      <DetailValue
        style={{
          width: '130px'
        }}
      ></DetailValue>
    </Row>
  </Column>
);

const Detail = ({ value, attributename }) => (
  <Row
    className={css({
      borderTop: '1px solid #333',
      '&:last-child': {
        borderBottom: '1px solid #333',
      },
    })}
  >
    <Checkbox checked={value} />
    <DetailLabel>{attributename}</DetailLabel>
    <DetailValue>{value}</DetailValue>
  </Row>
);

const Tag = styled('div')(
  {
    fontSize: 19,
    display: 'inline-block',
    padding: '3px 6px',
    marginLeft: '6px',
    borderRadius: 5,
    verticalAlign: 'top',
    marginTop: 4,
  },
  ({ theme, must, reorder, weborder, modro }) => ({
    background: must ? theme.orange.color : (
      reorder ? theme.blue.color : (
        weborder ? theme.vibrant.color : (
          modro ? theme.primary.lightMedium : theme.danger.color
        )
      )
    ),
    color: theme.danger.textOn,
  })
);

const DetailLabel = styled('div')({
  height: 16,
  background: 'rgb(78, 78, 78)',
  color: '#fff',
  fontSize: 9,
  width: 120,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 6,
});

const DetailValue = styled('div')({
  height: 16,
  width: 130,
  display: 'flex',
  alignItems: 'center',
  borderRight: '1px solid #333',
  fontSize: 12,
  paddingLeft: 4,
});

const CheckboxWrapper = styled('div')({}, ({ checked, theme }) => ({
  background: checked ? theme.success.color : 'none',
  width: 18,
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderLeft: '1px solid #333',
}));

const Checkbox = ({ checked }) => (
  <CheckboxWrapper checked={checked}>{checked ? 'x' : ''}</CheckboxWrapper>
);

const Instructions = ({ instructions }) =>
  instructions ? (
    <div
      className={css({
        padding: 15,
        borderRadius: 3,
        background: 'rgb(228, 228, 228)',
        fontSize: 18,
        fontWeight: 400,
        margin: '30px 0px 0px 0px',
      })}
    >
      {instructions}
    </div>
  ) : null;


const Count = styled('p')({
  display: 'block',
  pageBreakInside: 'avoid',
  height: '12px'
});

const Image = styled('img')({
  display: 'block',
  breakInside: 'avoid',
  pageBreakInside: 'avoid',
  height: '100%',
});

const ImagesWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  breakInside: 'avoid'
});

const ImageWrapper = styled('div')({
  display: 'block',
  pageBreakInside: 'avoid',
  maxWidth: '100%',
  maxHeight: '7.5in',
  height: '100%',
});

const PageBreakDisabler = styled('div')({
  display: 'flex',
  width: '10.5in',
  height: '7.5in',
  justifyContent: 'center',
  alignItems: 'center',
  pageBreakBefore: 'always',
  breakBefore: 'page',
  pageBreakInside: 'avoid',
  breakInside: 'avoid',
});

const ShippingStripes = styled('img')({
  position: 'fixed',
  width: '100%',
  height: 18,
  top: 0,
  left: 0,
});

// TODO: Page Breaks for the images are odd in firefox :(
const ArtFiles = ({ art_object = {} }) => (
  <ImagesWrapper>
    {(art_object.idArray || []).map((i, index) => (
      <PageBreakDisabler key={i}>
        <ImageWrapper>
          <Image
            src={`${BASE_URL}/api/documentimages/${art_object.cache_guid}${art_object.previewPath}art_lg_${i}.jpg`}
          />
        </ImageWrapper>
        <Count>{(index + 1) + '/' + (art_object.idArray?.length || [])}</Count>
      </PageBreakDisabler>
    ))}
  </ImagesWrapper>
);

const Customers = ({ customer, billTo, shipNote, qbAccount, customBillTo }) => {
  return (
    <CustomersWrapper>
      {customer ? <Customer {...customer} label="Customer" /> : null}
      {qbAccount ? (qbAccount.BillAddr?.Line1 ? <Customer 
        firstname={qbAccount?.DisplayName}
        address={qbAccount?.BillAddr?.Line1}
        city={qbAccount?.BillAddr?.City}
        state={qbAccount?.BillAddr?.CountrySubDivisionCode}
        zipcode={qbAccount?.BillAddr?.PostalCode}
        email={qbAccount?.PrimaryEmailAddr?.Address}
        primaryphone={qbAccount?.PrimaryPhone?.FreeFormNumber}
        label="Account"
      /> : <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          flex: '0 0 auto',
          marginRight: 15,
          '&:last-child': {
            marginRight: 0,
          },
        })}
      >
        <h3>Account</h3>
        <span
          style={{
            width: '200px',
            overflowWrap: 'break-word',
            whiteSpace: 'pre-line'
          }}
        >{customBillTo}</span>
      </div>
      ) :  
        billTo ? <Customer {...billTo} label="Bill To" /> : null
      }
      {shipNote ? (
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            flex: '0 0 auto',
            marginRight: 15,
            '&:last-child': {
              marginRight: 0,
            },
          })}
        > 
          <h3>Shipping Info</h3>
          <span style={{ whiteSpace: 'pre-line' }}>{shipNote}</span>
        </div>) 
        : null}
    </CustomersWrapper>
  );
};

const CustomersWrapper = styled('div')({
  display: 'flex',
  span: {
    fontWeight: 400,
    fontSize: 13,
    marginBottom: 2,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  h3: {
    fontWeight: 600,
    fontSize: 14,
  },
});

const Customer = ({
  firstname = '',
  lastname = '',
  isorganization = false,
  organization = '',
  address,
  city,
  state,
  zipcode,
  email,
  primaryphone,
  label,
}) => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
      flex: '0 0 auto',
      marginRight: 15,
      '&:last-child': {
        marginRight: 0,
      },
    })}
  >
    <h3>{label}</h3>
    {
      isorganization ?
        <React.Fragment>
          <span>
            {organization}
          </span>
          <span className={{fontStyle: 'italic'}}>
            {firstname} {lastname}
          </span>
        </React.Fragment>
        :
        <span>
          {firstname} {lastname}
        </span>
    }
    {address ? (
      <span>
        {address}
        <br />
      </span>
    ) : null}
    <span>
      {city ? `${city}, ` : null}
      {state ? `${state} ` : null}
      {zipcode ? zipcode : null}
    </span>
    {primaryphone ? <span>{primaryphone}</span> : null}
    {email ? <span>{email}</span> : null}
  </div>
);
