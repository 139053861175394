import React from 'react';
import { css } from 'emotion';
import { Wrapper } from '../../components/shell/Shell';
import FilterBar from '../../components/Collections/FilterBar';
import InfiniteScroll from 'react-infinite-scroller';
import Table from '../../components/Collections/Table';
import SearchBox from './SearchBox';
import format from 'date-fns/format';
import { Jobname } from './WorkCenters';
import { Tag } from '../../components/Tags';
import { withTheme } from 'emotion-theming';
import { Follower, FollowersWrapper } from '../../pages/Workorder/Followers';
import axios from 'axios';
import { BASE_URL } from '../../api';

let searchTermsCache = {};

class AllOrders extends React.Component {

  state = {
    documents: [],
    documentCount: 0,
    searchTerms: {},
    error: null,
    filter: '',
    hasMoreToFetch: false,
    searchTermsTemp: {
      jobname: undefined,
      document_id: undefined,
      customer_id: undefined,
      officenotes: '',
      specialinstructions: '',
      ...searchTermsCache,
    },
    jobname: undefined,
    document_id: undefined,
    customer_id: undefined,
    officenotes: undefined,
    specialinstructions: undefined,
    paymentLower: undefined,
    paymentUpper: undefined,
    sort: ''
  };

  componentDidMount() {
    this.search();
  }

  changeSort = (sort) => {
    this.setState({ sort: sort }, () => {
      this.search();
    });
  }

  editWorkorder = ({ document_id }) => {
    this.props.history.push(`/workorder/edit/${document_id}`);
  };

  search = () => {
    const { searchTermsTemp } = this.state;
    const {
      jobname,
      document_id,
      customer_id,
      officenotes,
      specialinstructions,
      paymentLower,
      paymentUpper
    } = searchTermsTemp;

    console.log(this.state.sort);

    this.setState({
      searchTerms: {
        ...(jobname ? { jobname: { iLike: `%${jobname}%` } } : {}),
        ...(document_id ? { document_id } : {}),
        ...(customer_id && customer_id != 0 ? { customer_id } : {}),
        ...(officenotes ? { officenotes: { iLike: `%${officenotes}%` } } : {}),
        ...(specialinstructions
          ? { specialinstructions: { iLike: `%${specialinstructions}%` } }
          : {}),
      },
      jobname,
      document_id,
      customer_id,
      officenotes,
      specialinstructions,
      paymentLower,
      paymentUpper,
      hasMoreToFetch: true
    }, () => {
      if(jobname ||
         document_id ||
         customer_id ||
         officenotes ||
         specialinstructions ||
         (paymentLower && paymentUpper)
      ) {
        axios.get(`${BASE_URL}/api/workcenters/allDocumentsCount`, {
          params: {
            jobname,
            document_id,
            customer_id: (customer_id && customer_id != 0) ? customer_id : undefined,
            officenotes,
            specialinstructions,
            paymentCiel: paymentUpper,
            paymentFloor: paymentLower
          }
        })
          .then((res) => {
            this.setState({ documentCount: res.data.result[0][0].count });
          });

        const sortArray = this.state.sort.split(':');
        console.log(sortArray);

        let sortDirection = 'DESC';
        let sort = 'document_id';
        if(sortArray.length === 1) {
          sort = sortArray[0];
        } else {
          sortDirection = (sortArray[0] === 'reverse' ? 'ASC' : 'DESC');
          sort = sortArray[1];
        }

        axios.get(`${BASE_URL}/api/workcenters/allDocumentsSearch`, {
          params: {
            jobname,
            document_id,
            customer_id,
            officenotes,
            specialinstructions,
            paymentCiel: paymentUpper,
            paymentFloor: paymentLower,
            offset: 0,
            limit: 100,
            sort,
            sortDirection
          }
        })
          .then((res) => {
            this.setState({
              documents: res.data.result,
              hasMoreToFetch: false
            });
          });
      }
    });

  };

  setSearchTermsTemp = searchTermsTemp => {
    searchTermsCache = searchTermsTemp;
    this.setState({ searchTermsTemp });
  };

  render() {
    const { theme } = this.props;

    const template = [
      {
        label: 'Job Name',
        id: 'jobname',
        sortable: true,
        bold: true,
        upperCase: true,
        maxLength: 30,
        render: passedRow => {
          const row = passedRow ? passedRow : {};
          if (row.priority === 'Rush') {
            return (
              <Jobname rush>
                <Tag color={theme.danger.color}>RUSH</Tag> {row.jobname}
              </Jobname>
            );
          }
          if (row.priority === 'Must') {
            return (
              <Jobname must>
                <Tag color={theme.orange.color}>MUST</Tag> {row.jobname}
              </Jobname>
            );
          }
          return row.jobname;
        },
      },
      {
        label: 'Contact',
        id: 'customer',
        color: '#b3b3b3',
        type: 'customerName',
      },
      { label: 'Tags', id: 'tags_of_document', type: 'tags' },
      {
        label: 'Followers',
        id: 'followers_documents_link',
        type: 'followers',
        render: row => {
          return (
            <FollowersWrapper>
              {row &&
              row.followers_documents_link &&
              row.followers_documents_link.map(f => {
                const { id, user_profile } = f ? f : { user_profile: {} };
                const { first_name, last_name, profilepicture } = user_profile
                  ? user_profile
                  : {};
                return (
                  <Follower
                    name={`${first_name} ${last_name}`}
                    profilepicture={profilepicture}
                    background
                    size="small"
                    tooltip
                    key={id}
                  />
                );
              })}
            </FollowersWrapper>
          );},
      },
      {
        label: 'Due Date',
        id: 'duedate',
        type: 'date',
        sortable: true,
        render: row =>
          row.duedate ? format(row.duedate, 'MMM D, YYYY') : 'None',
      },
      { id: 'document_id', hidden: true },
    ];

    return (
      <Wrapper
        className={css({
          paddingTop: 60,
        })}
      >
        <FilterBar
          title="All Orders"
          unfilterable
          unsortable
        />
        <SearchBox
          values={this.state.searchTermsTemp}
          onChange={this.setSearchTermsTemp}
          onClickSearch={this.search}
        />
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if(!this.state.hasMoreToFetch) {
              this.setState({hasMoreToFetch: true});
              axios.get(`${BASE_URL}/api/workcenters/allDocumentsSearch`, {
                params: {
                  jobname: this.state.jobname,
                  document_id: this.state.document_id,
                  customer_id: this.state.customer_id,
                  officenotes: this.state.officenotes,
                  specialinstructions: this.state.specialinstructions,
                  paymentCiel: this.state.paymentUpper,
                  paymentFloor: this.state.paymentLower,
                  offset: this.state.documents.length,
                  limit: 100
                }
              })
                .then((res) => {
                  const documents = this.state.documents;
                  documents.push(...res.data.result);
                  this.setState({
                    documents: documents,
                    hasMoreToFetch: false
                  });
                });
            }
          }}
          hasMore={this.state.documents.length < this.state.documentCount ? true : false}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
          className={css({
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          })}
        >
          <Table
            template={template}
            data={this.state.documents}
            onRowClick={this.editWorkorder}
            onContextMenu={this.openContextMenu}
            sortColumns
            onSortChange={this.changeSort}
          />
        </InfiniteScroll>
      </Wrapper>
    );
  }
}

export default withTheme(AllOrders);
