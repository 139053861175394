import React from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { testWorkCenters } from '../../testData';
import{ AllIcon } from '../../common/img/icons/workCenters';
import { axios } from '../../api';

const ActiveButton = styled('button')(
  ({active}) => ({
    flex: '0 0 auto',
    height: 50,
    width: 50,
    border: 'none',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 250ms, width 200ms, padding-left 200ms, border-radius 200ms',
    '& svg': {
      width: 36
    },
    '&:hover': {
      width: active ? 50 : 70,
      paddingLeft: active ? '' : 24,
      borderTopRightRadius: active ? 0 : 25,
      borderBottomRightRadius: active ? 0 : 25,
      '& .tooltip': {
        opacity: 1
      }
    }
  }),
  ({ loadingStyle, theme }) =>
    loadingStyle
      ? {
        background: theme.neutral.extraLight
      }
      : {
        background: theme.primary.color
      }
);

const Tooltip = styled('span')(
  {
    position: 'absolute',
    left: 85,
    top: 12,
    padding: '4px 10px',
    borderRadius: 5,
    fontSize: 14,
    opacity: 0,
    transition: 'opacity 100ms'
  },
  ({ theme }) => ({
    background: theme.primary.color,
    color: theme.primary.textOn
  }),
  ({ theme, background }) => ({ // eslint-disable-line
    '&:before': {
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '8px 10px 8px 0',
      borderColor: `transparent ${background} transparent transparent`,
      position: 'absolute',
      left: -9,
      top: 4,
      zIndex: -1
    }
  })
);

export class WorkCenterPicker extends React.Component {
  state = { expanded: false };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  togglePicker = () => {
    this.setState(
      state => ({ expanded: !state.expanded }),
      () => {
        if (this.state.expanded) {
          document.addEventListener('click', this.handleOutsideClick);
        } else {
          document.removeEventListener('click', this.handleOutsideClick);
        }
      }
    );
  };

  handleOutsideClick = e => {
    if (!this.wrapper.contains(e.target)) {
      this.togglePicker();
    }
  };

  selectWorkcenter = wcid => {
    this.togglePicker();
    this.props.onChange(wcid);
  };

  render() {

    const { activeWorkcenter } = this.props;
    const workcenters = this.props.workcenters;

    const currentWorkcenter =
      activeWorkcenter &&
      workcenters.find(wc => wc.workcenter_id === activeWorkcenter);

    const filteredWorkcenters =
      activeWorkcenter &&
      workcenters.filter(
        wc => wc.workcenter_id !== activeWorkcenter && wc.wcord !== 100 && wc.active
      );

    const { icon: Icon = AllIcon } = testWorkCenters[activeWorkcenter] || {};

    return (
      <div
        style={{ position: 'relative', display: 'flex', zIndex: 15 }}
        ref={r => {
          this.wrapper = r;
        }}
      >
        {!activeWorkcenter ? (
          <ActiveButton active loadingStyle />
        ) : (
          <ActiveButton
            style={{
              background: currentWorkcenter
                ? currentWorkcenter.htmlcolor
                : '#CCC',
              zIndex: workcenters.length
            }}
            active
            onClick={this.togglePicker}
          >
            {Icon && <Icon />}
          </ActiveButton>
        )}
        { activeWorkcenter &&
          filteredWorkcenters.map((wc, i) => {
            const { icon: Icon = AllIcon } = testWorkCenters[wc.workcenter_id] || {};
            return (
              <ActiveButton
                key={wc.workcenter_id}
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  background: wc.htmlcolor,
                  transform: this.state.expanded
                    ? `translateY(${(i + 1) * 49}px)`
                    : '',
                  zIndex: filteredWorkcenters.length - i,
                  transitionDelay: this.state.open
                    ? `${i * 8}ms`
                    : `${(filteredWorkcenters.length - i) * 8}ms`
                }}
                onClick={() => {
                  this.selectWorkcenter(wc.workcenter_id);
                }}
              >
                {this.state.expanded && (
                  <Tooltip
                    className="tooltip"
                    background={wc.htmlcolor}
                    style={{
                      background: wc.htmlcolor
                    }}
                  >
                    {wc.name}
                  </Tooltip>
                )}
                <Icon />
              </ActiveButton>
            );
          })}
      </div>
    );
  }
}

const ConnectedWorkCenterPicker = connect(state => ({
  workcenters: state.workCenters.workCenters
}))(WorkCenterPicker);
export default ConnectedWorkCenterPicker;
