import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  padding: 4,
  paddingLeft: 7,
  borderRadius: 4,
  position: 'relative'
}, ({theme}) => ({
  background: theme.neutral.extraLight
}));

const Label = styled('div')(({theme}) => ({
  fontSize: 16,
  color: theme.palette.primary.main
}));

const RightAlign = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'row'
});

const Toolbar = ({label, buttons, children, className=''}) => (
  <Wrapper className={className}>
    <Label>{label}</Label>
    {children}
    <RightAlign>{buttons}</RightAlign>
  </Wrapper>
);

export default Toolbar;
