import React from 'react';
import Box from '@mui/material/Box';

import AuthRoute from '@components/Reusable/auth/AuthRoute.js';
import SystemAdminSidebar from '@pages/SystemAdmin/SystemAdmin.sidebar';
import WorkorderEditSidebar from '@pages/Workorder/Workorder.Edit.Sidebar';
import WorkCentersSidebar from '@pages/WorkCenters/WorkCentersSidebar';

export default function SidebarRoutes() {
  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.primary.color
      }}
    >
      <AuthRoute
        path="/workcenters"
        component={props => {
          return <WorkCentersSidebar {...props} />;
        }}
        exact
      />
      <AuthRoute
        path="/workcenters/:workCenterId"
        component={props => {
          return <WorkCentersSidebar {...props} />;
        }}
        exact
      />
      <AuthRoute
        path="/stock"
        component={props => {
          return <WorkCentersSidebar {...props} />;
        }}
        exact
      />
      <AuthRoute
        path="/sales"
        component={props => {
          return <WorkCentersSidebar {...props} />;
        }}
        exact
      />
      <AuthRoute
        path="/calendar"
        component={props => {
          return <WorkCentersSidebar {...props} />;
        }}
        exact
      />
      <AuthRoute
        path="/tasks"
        component={props => {
          return <WorkCentersSidebar {...props} />;
        }}
        exact
      />
      <AuthRoute
        path="/calendar/:date"
        component={props => {
          return <WorkCentersSidebar {...props} />;
        }}
        exact
      />
      <AuthRoute
        path="/workorder/edit/:document_id"
        component={WorkorderEditSidebar}
        exact
      />
      <AuthRoute
        path="/admin"
        component={SystemAdminSidebar}
      />
    </Box>
  );
}
