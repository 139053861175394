import React from 'react';
import Board from '../../components/Board';
import { Wrapper } from '../../components/shell/Shell';
import { css } from 'emotion';
import FilterBar from '../../components/Collections/FilterBar';

export default class Tasks extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { setTitle = () => {}, pageTitle } = this.props;
    setTitle(pageTitle);
  }

  render() {
    return (
      <Wrapper
        style={{
          display: 'flex',
          alignItems: 'start'
        }}
        className={css({
          width: '100%',
          height: '100vh',
        })}
        key="PAGE_WRAPPER"
      >
        <FilterBar
          unsortable={true}
          unfilterable={true}
          title="Tasks"
          color="#EF5350"
        >
        </FilterBar>
        <Board
          name="tasks"
        />
      </Wrapper>
    );
  }

}
