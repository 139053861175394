import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { axios } from '../../api';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { Loading } from '../../components/Controls';
import TaxCode from '../../types/quickbooks/taxCode';

export default function ManageQuickbooks() {
  const [accounts, setAccounts] = useState<any>([]);
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [pushingItems, setPushingItems] = useState<boolean>(false);
  const [availableTaxCodes, setAvailableTaxCodes] = useState<TaxCode[]>([]);
  const [selectedTaxCode, setSelectedTaxCode] = useState<string|''>('');

  useEffect(() => {
    getExpenseAccounts();
    getAccountSetting();
    getTaxCodeSetting();
    getTaxCodes();
  }, []);

  function getExpenseAccounts() {
    axios.get('/quickbooks/quickbooksExpenseAccounts')
      .then((res) => {
        setAccounts(res.data.result);
      });
  }

  function getAccountSetting() {
    axios.get('/settings', {
      params: {
        type: 'quickbooks',
        sub_type: 'accounts',
        sub_sub_type: 'inventory_expense'
      }
    })
      .then((res) => {
        setSelectedAccount(res.data.result.data);
      });
  }

  function getTaxCodes() {
    axios.get('/quickbooks/taxCodes')
      .then((res) => {
        setAvailableTaxCodes(res.data.result);
      });
  }

  function getTaxCodeSetting() {
    axios.get('/settings', {
      params: {
        type: 'quickbooks',
        sub_type: 'taxes',
        sub_sub_type: 'code'
      }
    })
      .then((res) => {
        console.log(res.data.result.data);
        setSelectedTaxCode(res.data.result.data);
      });
  }

  function updateTaxCode(code: string) {
    setSelectedTaxCode(code);
    axios.put('/settings', {
      type: 'quickbooks',
      sub_type: 'taxes',
      sub_sub_type: 'code',
      data: code
    });
  }

  function updateAccountSetting(Id: string) {
    setSelectedAccount(Id);
    axios.put('/settings', {
      type: 'quickbooks',
      sub_type: 'accounts',
      sub_sub_type: 'inventory_expense',
      data: Id
    });
  }

  return (
    <Stack>
      <Button
        onClick={() => {
          axios.post('/quickbooks/disconnect');
        }}
        sx={{ margin: '16px' }}
        variant="contained"
      >
      Disconnect Quickbooks
      </Button>
      <FormControl 
        sx={{ mx: 2, mb: 1 }}
        variant="outlined"
      >
        <InputLabel id="accountSelectLabel">Account Associated with *PRINTING* typed Inventory Items</InputLabel>
        <Select
          value={selectedAccount}
          onChange={(e:any) => { console.log(e.target.value); updateAccountSetting(e.target.value); }}
        >
          {accounts.map((account:any) => {
            return (
              <MenuItem
                key={account.Id}
                value={account.Id}
              >{account.Name}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        color="error"
        sx={{ mx: 2 }}
        onClick={() => {
          setPushingItems(true);
          axios.post('/quickbooks/pushAllItems')
            .then(() => {
              setPushingItems(false);
            })
            .catch(() => {
              setPushingItems(false);
            });
        }}
      >Push ALL Items To Quickbooks</Button>
      <Loading isOpen={pushingItems} />
      <FormControl 
        sx={{ mx: 2, mt: 2 }}
        variant="outlined"
      >
        <InputLabel id="taxSelectLabel">Select a Tax Code that will be applied to work orders.</InputLabel>
        <Select
          value={selectedTaxCode}
          onChange={(e:any) => { updateTaxCode(e.target.value); }}
        >
          {availableTaxCodes.map((taxCode:any) => {
            return (
              <MenuItem
                key={taxCode.Id}
                value={taxCode.Id}
              >{taxCode.Name}</MenuItem>
            );
          })}
          <MenuItem
            key={undefined}
            value={undefined}
          >None</MenuItem>
        </Select>
      </FormControl>

    </Stack>
  );
}

