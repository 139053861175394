import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Button } from '@components/Controls.js';
import { axios } from '@/api.js';

export default function OldPricing() {

  const [ inventoryMarkup, setInventoryMarkup ] = useState({});
  const [ printing, setPrinting ] = useState({});

  useEffect(() => {

    axios.get('/settings', {
      params: {
        type: 'pricing',
        sub_type: 'inventory_markup',
        sub_sub_type: 'inventory_markup'
      }
    })
      .then((res) => {
        setInventoryMarkup(res.data.result);
      });

    axios.get('/settings', {
      params: {
        type: 'pricing',
        sub_type: 'labor',
        sub_sub_type: 'Printing'
      }
    })
      .then((res) => {
        setPrinting(res.data.result);
      });
  }, []);

  function handleLineChange(event, line, col) {
    const inv = inventoryMarkup;
    inv.data[line][col] = event.target.value;

    setInventoryMarkup(inv);
  }

  function handlePrintingChange(event, line, col) {
    const inv = printing;
    inv.data[line][col] = event.target.value;

    setPrinting(inv);
  }

  function onPrintingSubmit() {
    const data = printing.data;
    axios.put('/settings', {
      type: 'pricing',
      sub_type: 'labor',
      sub_sub_type: 'Printing',
      data
    });
  }

  function onMarkupSubmit() {
    const data = inventoryMarkup.data;
    axios.put('/settings', {
      type: 'pricing',
      sub_type: 'inventory_markup',
      sub_sub_type: 'inventory_markup',
      data
    });
  }

  if(!inventoryMarkup.data || !printing.data) {
    return (<div></div>);
  }
  return (
    <>
      <div>
        <Table>
          <Thead>
            <TR>
              <td>Breaks</td>
              <td><Input value={inventoryMarkup.data[0].col1} onChange={event => handleLineChange(event, '0', 'col1')}/></td>
              <td><Input value={inventoryMarkup.data[0].col2} onChange={event => handleLineChange(event, '0', 'col2')}/></td>
              <td><Input value={inventoryMarkup.data[0].col3} onChange={event => handleLineChange(event, '0', 'col3')}/></td>
              <td><Input value={inventoryMarkup.data[0].col4} onChange={event => handleLineChange(event, '0', 'col4')}/></td>
              <td><Input value={inventoryMarkup.data[0].col5} onChange={event => handleLineChange(event, '0', 'col5')}/></td>
              <td><Input value={inventoryMarkup.data[0].col6} onChange={event => handleLineChange(event, '0', 'col6')}/></td>
              <td><Input value={inventoryMarkup.data[0].col7} onChange={event => handleLineChange(event, '0', 'col7')}/></td>
              <td><Input value={inventoryMarkup.data[0].col8} onChange={event => handleLineChange(event, '0', 'col8')}/></td>
              <td><Input value={inventoryMarkup.data[0].col9} onChange={event => handleLineChange(event, '0', 'col9')}/></td>
            </TR>
          </Thead>
          <Tbody>
            <TR>
              <td>1-5</td>
              <td><Input value={inventoryMarkup.data[1].col1} onChange={event => handleLineChange(event, '1', 'col1')}/></td>
              <td><Input value={inventoryMarkup.data[1].col2} onChange={event => handleLineChange(event, '1', 'col2')}/></td>
              <td><Input value={inventoryMarkup.data[1].col3} onChange={event => handleLineChange(event, '1', 'col3')}/></td>
              <td><Input value={inventoryMarkup.data[1].col4} onChange={event => handleLineChange(event, '1', 'col4')}/></td>
              <td><Input value={inventoryMarkup.data[1].col5} onChange={event => handleLineChange(event, '1', 'col5')}/></td>
              <td><Input value={inventoryMarkup.data[1].col6} onChange={event => handleLineChange(event, '1', 'col6')}/></td>
              <td><Input value={inventoryMarkup.data[1].col7} onChange={event => handleLineChange(event, '1', 'col7')}/></td>
              <td><Input value={inventoryMarkup.data[1].col8} onChange={event => handleLineChange(event, '1', 'col8')}/></td>
              <td><Input value={inventoryMarkup.data[1].col9} onChange={event => handleLineChange(event, '1', 'col9')}/></td>
            </TR>
            <TR>
              <td>6-11</td>
              <td><Input value={inventoryMarkup.data[2].col1} onChange={event => handleLineChange(event, '2', 'col1')}/></td>
              <td><Input value={inventoryMarkup.data[2].col2} onChange={event => handleLineChange(event, '2', 'col2')}/></td>
              <td><Input value={inventoryMarkup.data[2].col3} onChange={event => handleLineChange(event, '2', 'col3')}/></td>
              <td><Input value={inventoryMarkup.data[2].col4} onChange={event => handleLineChange(event, '2', 'col4')}/></td>
              <td><Input value={inventoryMarkup.data[2].col5} onChange={event => handleLineChange(event, '2', 'col5')}/></td>
              <td><Input value={inventoryMarkup.data[2].col6} onChange={event => handleLineChange(event, '2', 'col6')}/></td>
              <td><Input value={inventoryMarkup.data[2].col7} onChange={event => handleLineChange(event, '2', 'col7')}/></td>
              <td><Input value={inventoryMarkup.data[2].col8} onChange={event => handleLineChange(event, '2', 'col8')}/></td>
              <td><Input value={inventoryMarkup.data[2].col9} onChange={event => handleLineChange(event, '2', 'col9')}/></td>
            </TR>
            <TR>
              <td>12-29</td>
              <td><Input value={inventoryMarkup.data[3].col1} onChange={event => handleLineChange(event, '3', 'col1')}/></td>
              <td><Input value={inventoryMarkup.data[3].col2} onChange={event => handleLineChange(event, '3', 'col2')}/></td>
              <td><Input value={inventoryMarkup.data[3].col3} onChange={event => handleLineChange(event, '3', 'col3')}/></td>
              <td><Input value={inventoryMarkup.data[3].col4} onChange={event => handleLineChange(event, '3', 'col4')}/></td>
              <td><Input value={inventoryMarkup.data[3].col5} onChange={event => handleLineChange(event, '3', 'col5')}/></td>
              <td><Input value={inventoryMarkup.data[3].col6} onChange={event => handleLineChange(event, '3', 'col6')}/></td>
              <td><Input value={inventoryMarkup.data[3].col7} onChange={event => handleLineChange(event, '3', 'col7')}/></td>
              <td><Input value={inventoryMarkup.data[3].col8} onChange={event => handleLineChange(event, '3', 'col8')}/></td>
              <td><Input value={inventoryMarkup.data[3].col9} onChange={event => handleLineChange(event, '3', 'col9')}/></td>
            </TR>
            <TR>
              <td>30-59</td>
              <td><Input value={inventoryMarkup.data[4].col1} onChange={event => handleLineChange(event, '4', 'col1')}/></td>
              <td><Input value={inventoryMarkup.data[4].col2} onChange={event => handleLineChange(event, '4', 'col2')}/></td>
              <td><Input value={inventoryMarkup.data[4].col3} onChange={event => handleLineChange(event, '4', 'col3')}/></td>
              <td><Input value={inventoryMarkup.data[4].col4} onChange={event => handleLineChange(event, '4', 'col4')}/></td>
              <td><Input value={inventoryMarkup.data[4].col5} onChange={event => handleLineChange(event, '4', 'col5')}/></td>
              <td><Input value={inventoryMarkup.data[4].col6} onChange={event => handleLineChange(event, '4', 'col6')}/></td>
              <td><Input value={inventoryMarkup.data[4].col7} onChange={event => handleLineChange(event, '4', 'col7')}/></td>
              <td><Input value={inventoryMarkup.data[4].col8} onChange={event => handleLineChange(event, '4', 'col8')}/></td>
              <td><Input value={inventoryMarkup.data[4].col9} onChange={event => handleLineChange(event, '4', 'col9')}/></td>
            </TR>
            <TR>
              <td>60-99</td>
              <td><Input value={inventoryMarkup.data[5].col1} onChange={event => handleLineChange(event, '5', 'col1')}/></td>
              <td><Input value={inventoryMarkup.data[5].col2} onChange={event => handleLineChange(event, '5', 'col2')}/></td>
              <td><Input value={inventoryMarkup.data[5].col3} onChange={event => handleLineChange(event, '5', 'col3')}/></td>
              <td><Input value={inventoryMarkup.data[5].col4} onChange={event => handleLineChange(event, '5', 'col4')}/></td>
              <td><Input value={inventoryMarkup.data[5].col5} onChange={event => handleLineChange(event, '5', 'col5')}/></td>
              <td><Input value={inventoryMarkup.data[5].col6} onChange={event => handleLineChange(event, '5', 'col6')}/></td>
              <td><Input value={inventoryMarkup.data[5].col7} onChange={event => handleLineChange(event, '5', 'col7')}/></td>
              <td><Input value={inventoryMarkup.data[5].col8} onChange={event => handleLineChange(event, '5', 'col8')}/></td>
              <td><Input value={inventoryMarkup.data[5].col9} onChange={event => handleLineChange(event, '5', 'col9')}/></td>
            </TR>
            <TR>
              <td>100-149</td>
              <td><Input value={inventoryMarkup.data[6].col1} onChange={event => handleLineChange(event, '6', 'col1')}/></td>
              <td><Input value={inventoryMarkup.data[6].col2} onChange={event => handleLineChange(event, '6', 'col2')}/></td>
              <td><Input value={inventoryMarkup.data[6].col3} onChange={event => handleLineChange(event, '6', 'col3')}/></td>
              <td><Input value={inventoryMarkup.data[6].col4} onChange={event => handleLineChange(event, '6', 'col4')}/></td>
              <td><Input value={inventoryMarkup.data[6].col5} onChange={event => handleLineChange(event, '6', 'col5')}/></td>
              <td><Input value={inventoryMarkup.data[6].col6} onChange={event => handleLineChange(event, '6', 'col6')}/></td>
              <td><Input value={inventoryMarkup.data[6].col7} onChange={event => handleLineChange(event, '6', 'col7')}/></td>
              <td><Input value={inventoryMarkup.data[6].col8} onChange={event => handleLineChange(event, '6', 'col8')}/></td>
              <td><Input value={inventoryMarkup.data[6].col9} onChange={event => handleLineChange(event, '6', 'col9')}/></td>
            </TR>
            <TR>
              <td>150-249</td>
              <td><Input value={inventoryMarkup.data[7].col1} onChange={event => handleLineChange(event, '7', 'col1')}/></td>
              <td><Input value={inventoryMarkup.data[7].col2} onChange={event => handleLineChange(event, '7', 'col2')}/></td>
              <td><Input value={inventoryMarkup.data[7].col3} onChange={event => handleLineChange(event, '7', 'col3')}/></td>
              <td><Input value={inventoryMarkup.data[7].col4} onChange={event => handleLineChange(event, '7', 'col4')}/></td>
              <td><Input value={inventoryMarkup.data[7].col5} onChange={event => handleLineChange(event, '7', 'col5')}/></td>
              <td><Input value={inventoryMarkup.data[7].col6} onChange={event => handleLineChange(event, '7', 'col6')}/></td>
              <td><Input value={inventoryMarkup.data[7].col7} onChange={event => handleLineChange(event, '7', 'col7')}/></td>
              <td><Input value={inventoryMarkup.data[7].col8} onChange={event => handleLineChange(event, '7', 'col8')}/></td>
              <td><Input value={inventoryMarkup.data[7].col9} onChange={event => handleLineChange(event, '7', 'col9')}/></td>
            </TR>
            <TR>
              <td>250-499</td>
              <td><Input value={inventoryMarkup.data[8].col1} onChange={event => handleLineChange(event, '8', 'col1')}/></td>
              <td><Input value={inventoryMarkup.data[8].col2} onChange={event => handleLineChange(event, '8', 'col2')}/></td>
              <td><Input value={inventoryMarkup.data[8].col3} onChange={event => handleLineChange(event, '8', 'col3')}/></td>
              <td><Input value={inventoryMarkup.data[8].col4} onChange={event => handleLineChange(event, '8', 'col4')}/></td>
              <td><Input value={inventoryMarkup.data[8].col5} onChange={event => handleLineChange(event, '8', 'col5')}/></td>
              <td><Input value={inventoryMarkup.data[8].col6} onChange={event => handleLineChange(event, '8', 'col6')}/></td>
              <td><Input value={inventoryMarkup.data[8].col7} onChange={event => handleLineChange(event, '8', 'col7')}/></td>
              <td><Input value={inventoryMarkup.data[8].col8} onChange={event => handleLineChange(event, '8', 'col8')}/></td>
              <td><Input value={inventoryMarkup.data[8].col9} onChange={event => handleLineChange(event, '8', 'col9')}/></td>
            </TR>
            <TR>
              <td>500-999</td>
              <td><Input value={inventoryMarkup.data[9].col1} onChange={event => handleLineChange(event, '9', 'col1')}/></td>
              <td><Input value={inventoryMarkup.data[9].col2} onChange={event => handleLineChange(event, '9', 'col2')}/></td>
              <td><Input value={inventoryMarkup.data[9].col3} onChange={event => handleLineChange(event, '9', 'col3')}/></td>
              <td><Input value={inventoryMarkup.data[9].col4} onChange={event => handleLineChange(event, '9', 'col4')}/></td>
              <td><Input value={inventoryMarkup.data[9].col5} onChange={event => handleLineChange(event, '9', 'col5')}/></td>
              <td><Input value={inventoryMarkup.data[9].col6} onChange={event => handleLineChange(event, '9', 'col6')}/></td>
              <td><Input value={inventoryMarkup.data[9].col7} onChange={event => handleLineChange(event, '9', 'col7')}/></td>
              <td><Input value={inventoryMarkup.data[9].col8} onChange={event => handleLineChange(event, '9', 'col8')}/></td>
              <td><Input value={inventoryMarkup.data[9].col9} onChange={event => handleLineChange(event, '9', 'col9')}/></td>
            </TR>
            <TR>
              <td>1000+</td>
              <td><Input value={inventoryMarkup.data[10].col1} onChange={event => handleLineChange(event, '10', 'col1')}/></td>
              <td><Input value={inventoryMarkup.data[10].col2} onChange={event => handleLineChange(event, '10', 'col2')}/></td>
              <td><Input value={inventoryMarkup.data[10].col3} onChange={event => handleLineChange(event, '10', 'col3')}/></td>
              <td><Input value={inventoryMarkup.data[10].col4} onChange={event => handleLineChange(event, '10', 'col4')}/></td>
              <td><Input value={inventoryMarkup.data[10].col5} onChange={event => handleLineChange(event, '10', 'col5')}/></td>
              <td><Input value={inventoryMarkup.data[10].col6} onChange={event => handleLineChange(event, '10', 'col6')}/></td>
              <td><Input value={inventoryMarkup.data[10].col7} onChange={event => handleLineChange(event, '10', 'col7')}/></td>
              <td><Input value={inventoryMarkup.data[10].col8} onChange={event => handleLineChange(event, '10', 'col8')}/></td>
              <td><Input value={inventoryMarkup.data[10].col9} onChange={event => handleLineChange(event, '10', 'col9')}/></td>
            </TR>
          </Tbody>
        </Table>
        <Button onClick={onMarkupSubmit}>Save Markup</Button>
      </div>

      <div>
        <Table>
          <Thead>
            <TR>
              <td>Breaks</td>
              <td><Input value={printing.data[1].qty} onChange={event => handlePrintingChange(event, '1', 'qty')}/></td>
              <td><Input value={printing.data[2].qty} onChange={event => handlePrintingChange(event, '2', 'qty')}/></td>
              <td><Input value={printing.data[3].qty} onChange={event => handlePrintingChange(event, '3', 'qty')}/></td>
              <td><Input value={printing.data[4].qty} onChange={event => handlePrintingChange(event, '4', 'qty')}/></td>
              <td><Input value={printing.data[5].qty} onChange={event => handlePrintingChange(event, '5', 'qty')}/></td>
              <td><Input value={printing.data[6].qty} onChange={event => handlePrintingChange(event, '6', 'qty')}/></td>
              <td><Input value={printing.data[7].qty} onChange={event => handlePrintingChange(event, '7', 'qty')}/></td>
              <td><Input value={printing.data[8].qty} onChange={event => handlePrintingChange(event, '8', 'qty')}/></td>
              <td><Input value={printing.data[9].qty} onChange={event => handlePrintingChange(event, '9', 'qty')}/></td>
              <td><Input value={printing.data[10].qty} onChange={event => handlePrintingChange(event, '10', 'qty')}/></td>
            </TR>
          </Thead>
          <Tbody>
            <TR>
              <td>1</td>
              <td><Input value={printing.data[1].col1} onChange={event => handlePrintingChange(event, '1', 'col1')}/></td>
              <td><Input value={printing.data[2].col1} onChange={event => handlePrintingChange(event, '2', 'col1')}/></td>
              <td><Input value={printing.data[3].col1} onChange={event => handlePrintingChange(event, '3', 'col1')}/></td>
              <td><Input value={printing.data[4].col1} onChange={event => handlePrintingChange(event, '4', 'col1')}/></td>
              <td><Input value={printing.data[5].col1} onChange={event => handlePrintingChange(event, '5', 'col1')}/></td>
              <td><Input value={printing.data[6].col1} onChange={event => handlePrintingChange(event, '6', 'col1')}/></td>
              <td><Input value={printing.data[7].col1} onChange={event => handlePrintingChange(event, '7', 'col1')}/></td>
              <td><Input value={printing.data[8].col1} onChange={event => handlePrintingChange(event, '8', 'col1')}/></td>
              <td><Input value={printing.data[9].col1} onChange={event => handlePrintingChange(event, '9', 'col1')}/></td>
              <td><Input value={printing.data[10].col1} onChange={event => handlePrintingChange(event, '10', 'col1')}/></td>
            </TR>
            <TR>
              <td>2</td>
              <td><Input value={printing.data[1].col2} onChange={event => handlePrintingChange(event, '1', 'col2')}/></td>
              <td><Input value={printing.data[2].col2} onChange={event => handlePrintingChange(event, '2', 'col2')}/></td>
              <td><Input value={printing.data[3].col2} onChange={event => handlePrintingChange(event, '3', 'col2')}/></td>
              <td><Input value={printing.data[4].col2} onChange={event => handlePrintingChange(event, '4', 'col2')}/></td>
              <td><Input value={printing.data[5].col2} onChange={event => handlePrintingChange(event, '5', 'col2')}/></td>
              <td><Input value={printing.data[6].col2} onChange={event => handlePrintingChange(event, '6', 'col2')}/></td>
              <td><Input value={printing.data[7].col2} onChange={event => handlePrintingChange(event, '7', 'col2')}/></td>
              <td><Input value={printing.data[8].col2} onChange={event => handlePrintingChange(event, '8', 'col2')}/></td>
              <td><Input value={printing.data[9].col2} onChange={event => handlePrintingChange(event, '9', 'col2')}/></td>
              <td><Input value={printing.data[10].col2} onChange={event => handlePrintingChange(event, '10', 'col2')}/></td>
            </TR>
            <TR>
              <td>3</td>
              <td><Input value={printing.data[1].col3} onChange={event => handlePrintingChange(event, '1', 'col3')}/></td>
              <td><Input value={printing.data[2].col3} onChange={event => handlePrintingChange(event, '2', 'col3')}/></td>
              <td><Input value={printing.data[3].col3} onChange={event => handlePrintingChange(event, '3', 'col3')}/></td>
              <td><Input value={printing.data[4].col3} onChange={event => handlePrintingChange(event, '4', 'col3')}/></td>
              <td><Input value={printing.data[5].col3} onChange={event => handlePrintingChange(event, '5', 'col3')}/></td>
              <td><Input value={printing.data[6].col3} onChange={event => handlePrintingChange(event, '6', 'col3')}/></td>
              <td><Input value={printing.data[7].col3} onChange={event => handlePrintingChange(event, '7', 'col3')}/></td>
              <td><Input value={printing.data[8].col3} onChange={event => handlePrintingChange(event, '8', 'col3')}/></td>
              <td><Input value={printing.data[9].col3} onChange={event => handlePrintingChange(event, '9', 'col3')}/></td>
              <td><Input value={printing.data[10].col3} onChange={event => handlePrintingChange(event, '10', 'col3')}/></td>
            </TR>
            <TR>
              <td>4</td>
              <td><Input value={printing.data[1].col4} onChange={event => handlePrintingChange(event, '1', 'col4')}/></td>
              <td><Input value={printing.data[2].col4} onChange={event => handlePrintingChange(event, '2', 'col4')}/></td>
              <td><Input value={printing.data[3].col4} onChange={event => handlePrintingChange(event, '3', 'col4')}/></td>
              <td><Input value={printing.data[4].col4} onChange={event => handlePrintingChange(event, '4', 'col4')}/></td>
              <td><Input value={printing.data[5].col4} onChange={event => handlePrintingChange(event, '5', 'col4')}/></td>
              <td><Input value={printing.data[6].col4} onChange={event => handlePrintingChange(event, '6', 'col4')}/></td>
              <td><Input value={printing.data[7].col4} onChange={event => handlePrintingChange(event, '7', 'col4')}/></td>
              <td><Input value={printing.data[8].col4} onChange={event => handlePrintingChange(event, '8', 'col4')}/></td>
              <td><Input value={printing.data[9].col4} onChange={event => handlePrintingChange(event, '9', 'col4')}/></td>
              <td><Input value={printing.data[10].col4} onChange={event => handlePrintingChange(event, '10', 'col4')}/></td>
            </TR>
            <TR>
              <td>5</td>
              <td><Input value={printing.data[1].col5} onChange={event => handlePrintingChange(event, '1', 'col5')}/></td>
              <td><Input value={printing.data[2].col5} onChange={event => handlePrintingChange(event, '2', 'col5')}/></td>
              <td><Input value={printing.data[3].col5} onChange={event => handlePrintingChange(event, '3', 'col5')}/></td>
              <td><Input value={printing.data[4].col5} onChange={event => handlePrintingChange(event, '4', 'col5')}/></td>
              <td><Input value={printing.data[5].col5} onChange={event => handlePrintingChange(event, '5', 'col5')}/></td>
              <td><Input value={printing.data[6].col5} onChange={event => handlePrintingChange(event, '6', 'col5')}/></td>
              <td><Input value={printing.data[7].col5} onChange={event => handlePrintingChange(event, '7', 'col5')}/></td>
              <td><Input value={printing.data[8].col5} onChange={event => handlePrintingChange(event, '8', 'col5')}/></td>
              <td><Input value={printing.data[9].col5} onChange={event => handlePrintingChange(event, '9', 'col5')}/></td>
              <td><Input value={printing.data[10].col5} onChange={event => handlePrintingChange(event, '10', 'col5')}/></td>
            </TR>
            <TR>
              <td>6</td>
              <td><Input value={printing.data[1].col6} onChange={event => handlePrintingChange(event, '1', 'col6')}/></td>
              <td><Input value={printing.data[2].col6} onChange={event => handlePrintingChange(event, '2', 'col6')}/></td>
              <td><Input value={printing.data[3].col6} onChange={event => handlePrintingChange(event, '3', 'col6')}/></td>
              <td><Input value={printing.data[4].col6} onChange={event => handlePrintingChange(event, '4', 'col6')}/></td>
              <td><Input value={printing.data[5].col6} onChange={event => handlePrintingChange(event, '5', 'col6')}/></td>
              <td><Input value={printing.data[6].col6} onChange={event => handlePrintingChange(event, '6', 'col6')}/></td>
              <td><Input value={printing.data[7].col6} onChange={event => handlePrintingChange(event, '7', 'col6')}/></td>
              <td><Input value={printing.data[8].col6} onChange={event => handlePrintingChange(event, '8', 'col6')}/></td>
              <td><Input value={printing.data[9].col6} onChange={event => handlePrintingChange(event, '9', 'col6')}/></td>
              <td><Input value={printing.data[10].col6} onChange={event => handlePrintingChange(event, '10', 'col6')}/></td>
            </TR>
          </Tbody>
        </Table>
        <Button onClick={onPrintingSubmit}>Save Printing</Button>
      </div>
    </>
  );
}

const Table = styled('table')({
  border: '1px solid black',
  margin: '1em'
  // width: '100%',
});

const TR = styled('tr')({
  border: '1px solid black',
  borderBottom: '1px solid black'
  // width: '100%',
});

const Tbody = styled('tbody')({

});

const Thead = styled('thead')({
  border: '1px solid black'
});

const Input = styled('input')({
  width: '60px'
});

const taxSchema = {
  type: 'object',
  title: 'Settings',
  properties: {
    tax: {
      type: 'string',
    },
  },
  required: ['tax'],
};
