import React from 'react';
import styled from '@emotion/styled';
import { Draggable, Droppable } from 'react-dragtastic';
import ModalStateController from '../ModalStateController';

export default class ColumnSelector extends React.Component{

  static defaultProps = {
    activeColumns: {},
    onChange: () => {}
  };

  constructor(props){
    super(props);
    this.state = {activeColumns: props.activeColumns, dragType: null, dragStart: null};
  }

  componentDidUpdate = prevProps => {
    if(this.props.activeColumns !== prevProps.activeColumns){
      this.setState({activeColumns: this.props.activeColumns});
    }
  }

  toggleColumn = colId => {
    this.setState(state => ({
      activeColumns: {
        ...state.activeColumns,
        [colId]: !state.activeColumns[colId]
      }
    }), () => {
      this.commitChange();
    });
  }

  setColumn = endIndex => {
    this.setState(state => ({
      activeColumns: {
        ...state.activeColumns,
        ...(this.props.hideableColumns.map(t=>t.id))
          .slice(
            (state.dragStart > endIndex ? endIndex : state.dragStart),
            (state.dragStart > endIndex ? state.dragStart : endIndex) + 1
          )
          .reduce((obj, col) => ({ ...obj, [col]: state.dragType }), {})
      }
    }));
  }
  
  startColumnDrag = (col, active, startIndex) => {
    this.setState(state => ({
      activeColumns: { ...state.activeColumns, [col]: active },
      dragType: active,
      dragStart: startIndex,
    }));
  }

  commitChange = () => {
    this.props.onChange(this.state.activeColumns);
  }

  render(){
    const { activeColumns } = this.state;
    const { hideableColumns } = this.props;
    return(
      <Wrapper>
        {hideableColumns.map((col, i) => (
          <Droppable
            id={col.id}
            onDragEnter={() => {
              this.setColumn(i);
            }}
            key={col.id}
          >
            {({ events: dropEvents }) => (
              <Draggable
                id={col.id}
                onDragStart={() => {
                  this.startColumnDrag(col.id, activeColumns[col.id] ? false : true, i);
                }}
                onDragEnd={this.commitChange}
              >
                {({ events: dragEvents }) => (
                  <ColumnButton
                    {...dragEvents}
                    {...dropEvents}
                    active={activeColumns[col.id] ? true : false}
                    noRadiusLeft={
                      !activeColumns[col.id] ? false : activeColumns[(hideableColumns[i - 1] || {}).id]
                    }
                    noRadiusRight={
                      !activeColumns[col.id] ? false : activeColumns[(hideableColumns[i + 1] || {}).id]
                    }
                    onClick={() => {
                      this.toggleColumn(col.id);
                    }}
                  >
                    {col.label}
                  </ColumnButton>
                )}
              </Draggable>
            )}
          </Droppable>
        ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled('div')(({theme}) => ({
  height: 40,
  boxShadow: theme.shadows.d2,
  borderRadius: 30,
  padding: 3,
  background: '#fff'
}));

const ColumnButton = styled('button')({
  border: 'none'
},
({theme, active, noRadiusLeft, noRadiusRight}) => ({
  height: 34,
  minWidth: 34,
  borderRadius: 40,
  background: active ? theme.blue.color : 'none',
  color: active ? theme.primary.textOn : theme.primary,
  border: 'none',
  fontFamily: theme.primary.fontFamily,
  fontSize: 13,
  padding: 3,
  cursor: 'pointer',
  borderTopRightRadius: noRadiusRight ? 0 : '',
  borderBottomRightRadius: noRadiusRight ? 0 : '',
  borderTopLeftRadius: noRadiusLeft ? 0 : '',
  borderBottomLeftRadius: noRadiusLeft ? 0 : '',
  transition: 'border-radius 200ms, background 200ms, color 200ms',
  willChange: 'border-radius, background, color',
  userSelect: 'none',
  '&:focus': {
    outline: 'none',
  },
  '&:hover': {
    background: active ? theme.blue.light : theme.blue.alpha2
  }
}));

const SelectorWrapper = styled('div')({
  position: 'fixed',
  left: -10,
  top: 0,
  zIndex: 10
});

export class ColumnSelectorModal extends React.Component{
  render(){
    const { activeColumns, onChange, isOpen, onModalClose, coordinates={}, hideableColumns } = this.props;
    return(
      <ModalStateController
        enterAnimationClassName="pop-in-left"
        exitAnimationClassName="pop-out-left"
        isOpen={isOpen}
        onModalClose={onModalClose}
      >
        {
          ({getProps}) => (
            <SelectorWrapper style={{
              left: coordinates.x,
              top: coordinates.y
            }} {...getProps()}>
              <ColumnSelector activeColumns={activeColumns} onChange={onChange} hideableColumns={hideableColumns} />
            </SelectorWrapper>
          )
        }
      </ModalStateController>
    );
  }
}
