import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import BackArrowIcon from 'react-icons/lib/md/arrow-back';
import { DateRange } from '@material-ui/icons';

import logo from '@/common/img/logo.svg';
import NetworkMonitor from '@components/NetworkMonitor';
import ProfileButton from '@components/ProfileButton';

const SidebarWrapper = styled('aside')({
  position: 'fixed',
  width: 250,
  // height: 'calc(100% - 60px)',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 3
}, ({theme}) => ({
  background: theme.primary.color,
  borderRight: `1px solid ${theme.primary.dark}`,
  
}), ({allowMini, theme}) => ({
  transition: 'width 300ms',
  [`${theme.breakpoints.down('lg')}`]: allowMini ? {
    display: 'none'
  } : {}
}));


const ScrollWrapper = styled('div')({
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    background: 'none',
    width: 13
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255,255,255,.3)',
    width: 13,
    borderRadius: 7
  }
}, ({hasFooter}) => ({
  height: hasFooter ? 'calc(100% - 60px)' : '100%'
}));

export default class Sidebar extends React.Component{
  render(){
    const { children, footer, allowMini } = this.props;
    return(
      <SidebarWrapper allowMini={allowMini}>
        <ScrollWrapper hasFooter={footer ? true : false}>
          {children}
        </ScrollWrapper>
        {
          footer &&
          <SidebarFooter>
            {footer}
          </SidebarFooter>
        }
      </SidebarWrapper>
    );
  }
}

export const SidebarFooter = styled('div')({
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  height: 60,
  boxShadow: '0 -3px 6px rgba(0,0,0,0.16), 0 -3px 6px rgba(0,0,0,0.23)'
}, ({theme}) => ({
  background: theme.primary.color,
  color: theme.primary.textOn,
  borderTop: `1px solid ${theme.primary.dark}`
}));

const SidebarHeaderWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  height: 60,
  alignItems: 'center',
  padding: '0px 5px'
}, ({theme}) => ({
  borderBottom: `1px solid ${theme.primary.dark}`
}));

const AlignRight = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  height: '100%'
});

const Branding = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 250,
  paddingLeft: 5
});

const Logo = styled('img')({
  flex: '0 0 auto',
  width: '100%',
  maxWidth: 150
});

const BackButtonWrapper = styled('button')({
  height: '100%',
  padding: 0,
  paddingLeft: 10,
  paddingRight: 20,
  fontSize: 28,
  background: 'none',
  border: 'none',
  outline: 'none'
}, ({theme}) => ({
  color: theme.primary.textOn,
  opacity: .5,
  '&:hover': {
    opacity: 1
  }
}));

export const BackButton = ({onClick}) => (
  <BackButtonWrapper onClick={onClick}>
    <BackArrowIcon />
  </BackButtonWrapper>
);

export const CalendarButton = ({onClick}) => (
  <BackButtonWrapper style={{ paddingTop: 8 }} onClick={onClick}>
    <DateRange />
  </BackButtonWrapper>
);

export class SidebarHeader extends React.Component{
  render(){
    const { children, branding, allowMini } = this.props;
    return(
      <SidebarHeaderWrapper>
        {branding &&
          <Branding>
            <Logo src={logo} />
          </Branding>
        }
        {children}
        <AlignRight>
          <div className={css(allowMini ? {
            height: '100%',
            '@media (max-width: 1080px)': {
              display: 'none'
            }
          } : {height: '100%'})}>
            <NetworkMonitor/>
          </div>
          <ProfileButton/>
        </AlignRight>
      </SidebarHeaderWrapper>
    );
  }
}
