import React, { useState, useEffect } from 'react';

import styled from '@emotion/styled';
import { connect } from 'react-redux';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import Button from '@mui/material/Button';
import { axios } from '@/api.js';
import { history } from '@/index';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { TypeColorMap } from '../../data/calendarData.js';

import UserList from '@components/Reusable/user/UserList.js';
import UserIcon from '@components/Reusable/user/UserIcon.js';
import { calendarDesignTypes } from '@/data/calendarData.js';
import JobTimer from './DesignModal.Timer.js';
import DataGrid from '../../components/Reusable/data/DataGrid/DataGrid.js';
import template from '../../components/Reusable/data/DataGrid/template.js';
import Tooltip from '@mui/material/Tooltip';

function LeftSide(props) {
  const [subTypeMenuOpen, setSubTypeMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  console.log(props.design);
  return (            
    <Stack
      width="33%"
      direction="column"
      justifyContent="flex-start"
      alignItems="start"
      spacing={1}
    >

      <StyledInput 
        size="small"
        fullWidth 
        label="Time Estimate (Hours)"
        value={props.design.completion_time_hours}
        onChange={(e) => { props.updateDesignKeyDontSend(e.target.value, 'completion_time_hours'); }}
        onBlur={(e) => { props.updateDesignKey(e.target.value, 'completion_time_hours'); }}
      />
      <StyledInput 
        size="small"
        fullWidth
        label="Note"
        height="400px"
        multiline
        rows={6}
        value={props.design.calendar_note}
        onChange={(e) => { props.updateDesignKeyDontSend(e.target.value, 'calendar_note'); }}
        onBlur={(e) => { props.updateDesignKey(e.target.value, 'calendar_note'); }}
      />
      <FormControlLabel
        label="Finished"
        control={ 
          <Checkbox 
            checked={props.finished} 
            onChange={(e) => { 
              props.updateDesignKey(e.target.checked, 'finished'); 
            }}
          /> 
        }
      />

      <Stack
        direction="row"
        alignItems="center"
      >
        <Button
          onClick={(e) => { 
            setAnchorEl(e.currentTarget);
            setSubTypeMenuOpen(true); 
          }}
          variant='contained'
          endIcon={ <AddCircleIcon /> }
        >Add A Sub-Type
        </Button>
        {props.design.sub_types?.map((sub_type, i) => {
          return (
            <Tooltip
              key={sub_type}
              title={sub_type}
              arrow
            >
              <CircleIcon
                sx={{
                  color: TypeColorMap[sub_type].bg,
                  '& :hover': { color: 'grey' }
                }}
                onClick={() => {
                  const sub_types = props.design.sub_types;
                  sub_types.splice(i, 1);
                  props.updateDesignKey([...sub_types], 'sub_types');
                }}
              >
              </CircleIcon>
            </Tooltip>
          );

        })}
        <Menu
          anchorEl={anchorEl}
          open={subTypeMenuOpen}
          onClose={() => { setSubTypeMenuOpen(false); }}
        >
          {calendarDesignTypes.map((type) => {
            if(type.value === 'Finished' || type.label === 'None' || type.value === props.design.type) return null;
            if(props.design.sub_types?.length && props.design.sub_types.findIndex(arr_type => arr_type === type.value) != -1) return null;
            return (
              <MenuItem 
                key={type.value}
                value={type.value}
                onClick={() => {
                  props.updateDesignKey([...props.design.sub_types, type.value], 'sub_types');
                  setSubTypeMenuOpen(false);
                }}
              >{type.label}</MenuItem>
            );
          })}
        </Menu>
      </Stack>

      <DataGrid
        columns={template}
        rows={props.design.orderLines}
        columnsHideable={false}
        hiddenColumns={[
          'nb', 'm3', 'm6', 'm12', 'm18', 'm24',
          't2', 't3', 't4', 't56', 'xs', 's', 'm',
          'l', 'xl', 'xxl', 'xl3', 'xl4', 'xl5', 'xl6', 'misc',
          'itemPrice', 'customPriceOn', 'lineTotal'
        ]}
        readOnly={true}
      />
    </Stack>
  );
}

function DesignModal(props) {
  const [viewing, setViewing] = useState('tasks'); // Other option is 'timer'
  const [workingTask, setWorkingTask] = useState();
  const [design, setDesign] = useState({
    design: '',
    finished: false,
    calendar_note: '',
    completion_time_hours: 1,
    calendar_print_tasks: []
  });

  const [addingTask, setAddingTask] = useState(false);
  const [printingTasks, setPrintingTasks] = useState([]);
  const [taskMenuAnchor, setTaskMenuAnchor] = useState();
  const [selectingUser, setSelectingUser] = useState(false);
  const [selectingUserEl, setSelectingUserEl] = useState();
  const [selectedTaskId, setSelectedTaskId] = useState(0);

  useEffect(() => {
    getPrintingTasks();
  }, [props.open]);

  useEffect(() => {
    loadDesign();
  }, [props.designId]);

  function addPrintingTask(task) {
    const newTask = {
      name: task.name,
      quantity: design.itemQuantity,
      reportSymbol: task.reportSymbol,
      timed: task.timing,
      hideUser: task.hideUser ? true : false
    };

    const newDesign = design;
    newDesign.calendar_print_tasks.push(newTask);
    setDesign({ ...newDesign });

    axios.post('/calendar/design/task', {
      designId: design.id,
      name: newTask.name,
      quantity: newTask.quantity,
      reportSymbol: newTask.reportSymbol,
      timing: newTask.timed,
      hideUser: newTask.hideUser
    })
      .then(() => {
        loadDesign();
      });
  }

  function addUserToTask(user) {
    const newDesign = design;
    const taskId = newDesign.calendar_print_tasks.findIndex((task) => task.id === selectedTaskId);
    newDesign.calendar_print_tasks[taskId].user_profiles.push(user);
    setDesign({...newDesign});

    axios.post('/calendar/design/task/assignedUser', {
      user_account_id: user.user_account_id,
      printTaskId: selectedTaskId
    });
  }

  function calcQtyFinished(task) {
    return (task.task_timings || []).reduce((total, timing) => {
      if(timing.finished && timing.qty_finished) return Number(total) + Number(timing.qty_finished);
      return total;
    }, 0);
  }

  function deletePrintingTask(index) {
    axios.delete('/calendar/design/task', {
      params: {
        id: design.calendar_print_tasks[index].id
      }
    });

    const newDesign = design;
    newDesign.calendar_print_tasks.splice(index, 1);
    setDesign({ ...newDesign });
  }

  function deleteUserFromTask(user, task) {
    const newDesign = design;
    const taskIndex = newDesign.calendar_print_tasks.findIndex((taskToFind) => task.id === taskToFind.id);
    const userIndex = newDesign.calendar_print_tasks[taskIndex].user_profiles.findIndex((userToFind) => user.user_profile_id === userToFind.user_profile_id);
    newDesign.calendar_print_tasks[taskIndex].user_profiles.splice(userIndex, 1);
    setDesign({...newDesign});

    axios.delete('/calendar/design/task/assignedUser', {
      params: {
        user_account_id: user.user_account_id,
        printTaskId: task.id
      }
    });
  }

  function getDesignPrintColors() {
    const colors = design.otherPrints || [];
    colors.unshift(design.rPrint);
    colors.unshift(design.lPrint);
    colors.unshift(design.bPrint);
    colors.unshift(design.fPrint);
    return colors;
  }

  function getPrintingTasks() {
    axios.get('/settings', {
      params: {
        type: 'calendar',
        sub_type: 'printing',
        sub_sub_type: 'tasks'
      }
    })
      .then((res) => {
        setPrintingTasks([...res.data?.result?.data]);
      });
  }

  function loadDesign() {
    if(props.designId === 0) return;
    axios.get('/calendar/design', {
      params: {
        id: props.designId
      }
    })
      .then((res) => {
        if(workingTask) setWorkingTask(res.data.result.calendar_print_tasks.find(task => task.id === workingTask.id));
        setDesign(res.data.result);
      });
  }

  function updateDesign(design) {
    axios.put('/calendar/design', {
      id: props.designId,
      type: design.type,
      finished: design.finished,
      note: design.calendar_note,
      completion_time_hours: design.completion_time_hours,
      sub_types: design.sub_types
    });
  }

  function updateDesignKey(value, key) {
    const newDesign = design;
    newDesign[key] = value;
    setDesign({...newDesign});
    updateDesign(newDesign);
    console.log(value);
  }

  function updateDesignKeyDontSend(value, key) {
    const newDesign = design;
    newDesign[key] = value;
    setDesign({...newDesign});
  }

  const finished = design.finished;
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
    >
      <SurroundingBox>
        <AppBar 
          style={{ 
            backgroundColor:'white', 
            borderBottom: '1px', 
            borderBottomColor: 'grey', 
            borderBottomStyle: 'solid' 
          }}
          position="static"
          elevation={0}
        >
          <Toolbar
            variant="dense"
            elevation={0}
            sx={{
              display:'flex',
              justifyContent:'space-between'
            }}
          >
            <TitleButton 
              variant="text"
              onClick={() => {
                history.push(`/workorder/edit/${design.document?.document_id}`);
              }}
            >
              {design?.document?.jobname + ' - D' + design.designNumber}
            </TitleButton>
            <span>
              <FormControl
                size="small"
              >
                <InputLabel 
                  id="type-label"
                  shrink={true}
                >Type</InputLabel>
                <StyledSelect
                  labelId="type-label"
                  label="Type"
                  displayEmpty
                  notched={true}
                  value={design.type ?? ''}
                  onChange={(e) => { updateDesignKey(e.target.value, 'type'); }}
                >
                  <MenuItem 
                    disabled 
                    value={null} 
                  >
                    <em>Type</em>
                  </MenuItem>
                  {calendarDesignTypes.map((type) => {
                    if(type.value === 'Finished') return null; 
                    return (
                      <MenuItem
                        key={type.value}
                        value={type.value}
                      >
                        {type.label}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              </FormControl>
              <IconButton
                onClick={props.onClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </Toolbar>
        </AppBar>

        { viewing === 'tasks' ? 
          <Stack
            direction="row"
            spacing={0}
            justifyContent="space-around"
            alignItems="start"
            width="100%"
            sx={{ p: 1, pt: 2 }}
          >
            <LeftSide 
              design={design}
              updateDesignKeyDontSend={updateDesignKeyDontSend}
              updateDesignKey={updateDesignKey}
              finished={finished}
            />
            <Stack
              width="65%"
              direction="column"
              justifyContent="flex-start"
              alignItems="start"
              spacing={.5}
            >
              <Button
                fullWidth
                variant="contained"
                onClick={(e) => { 
                  setAddingTask(true); 
                  setTaskMenuAnchor(e.currentTarget);
                }}
              >Add Task</Button>
              <Menu
                open={addingTask}
                anchorEl={taskMenuAnchor}
                onClose={() => { setAddingTask(false); }}
              >
                { printingTasks.map((task, i) => {
                  return (
                    <MenuItem key={i} onClick={() => {
                      addPrintingTask(task);
                      setAddingTask(false);
                    }}>
                      {task.name}
                    </MenuItem>
                  );
                })}
              </Menu>
              {design.calendar_print_tasks.map((task, i) => {
                return (
                  <Paper 
                    key={task.id}
                    sx={{ 
                      backgroundColor: () => {
                        if(calcQtyFinished(task) < task.quantity && calcQtyFinished(task) > 0 && task.timed) return 'red.light';
                        if(task?.task_timings?.length && task.task_timings?.every(timing => timing.finished)) return 'success.dark';
                        if(task.task_timings?.some(timing => timing?.beginTimes?.length > timing?.endTimes?.length)) return 'orange.light';
                        return 'primary.extraLight';
                      },
                      color: 'primary.textOn',
                      width: '100%',
                      height: '42px',
                      p: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <span
                      style={{
                        width: '30%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        paddingRight: '8px'
                      }}
                    >
                      {task.name}
                    </span>
                    <span
                      style={{
                        width: '25%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {task.note}
                    </span>
                    <span>
                      {(task.timed ? ' Done: ' + calcQtyFinished(task) + '/' + task.quantity : '')}
                    </span>
                    {!task.timed && !task?.task_timings?.some((timing) => timing.finished) && <Button
                      sx={{
                        color: 'primary.textOn',
                        whiteSpace: 'nowrap'
                      }}
                      onClick={() => {
                        axios.post('/calendar/design/task/timing/notiming', {
                          task_id: task.id,
                          user_id: props.user_account_id
                        })
                          .then(() => {
                            loadDesign();
                          });
                      }}
                    >Mark Finished</Button>}
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={.3}
                    >
                      {task.user_profiles?.map((user) => {
                        return (
                          <UserIcon 
                            key={user.user_profile_id}
                            userId
                            size={30}
                            profilepic={user.profilepicture}
                            userName={user.first_name + ' ' + user.last_name}
                            onUserClear={() => { deleteUserFromTask(user, task); }}
                          />
                        );
                      })}
                      { task.user_profiles?.length > 0 ?
                        <IconButton
                          onClick={(e) => { 
                            setSelectingUser(true);
                            setSelectedTaskId(task.id);
                            setSelectingUserEl(e.currentTarget);
                          }}
                          size="small"
                          sx={{
                            color: 'primary.textOn',
                          }}
                        >
                          <AddCircleIcon />
                        </IconButton>
                        : <Button
                          sx={{
                            color: 'primary.textOn',
                            whiteSpace: 'nowrap'
                          }}
                          onClick={(e) => { 
                            setSelectingUser(true);
                            setSelectedTaskId(task.id);
                            setSelectingUserEl(e.currentTarget);
                          }}
                        >Assign User</Button>
                      }
                      <Button
                        sx={{
                          color: 'primary.textOn',
                          whiteSpace: 'nowrap'
                        }}
                        onClick={() => {
                          setViewing('timer');
                          setWorkingTask(task);
                        }}
                      >View Task</Button>
                      { !task?.task_timings?.some(timing => timing.finished) && <IconButton
                        onClick={() => { deletePrintingTask(i); }}
                        sx={{ color: 'primary.textOn' }}
                      >
                        <DeleteForeverIcon /> 
                      </IconButton> }
                    </Stack>
                  </Paper>
                );
              })}
              <UserList 
                open={selectingUser}
                anchorEl={selectingUserEl}
                onClose={() => { setSelectingUser(false); }}
                onUserSelect={(user) => { addUserToTask(user); }}
                type="production"
              />
            </Stack>
          </Stack> : 
          <JobTimer 
            document_id={design.document_id}
            design_id={design.id}
            designColors={getDesignPrintColors()}
            task={workingTask}
            reloadTimings={loadDesign}
            leave={() => { setViewing('tasks'); }}
            quantity={design.itemQuantity}
          />
        }
      </SurroundingBox>
    </Modal>
  );
}
export default connect((state) => {
  return { user_account_id: state.app.user_account_id };
})(DesignModal);

const SurroundingBox = styled(Box)({
  width: '80%',
  height: '80%',
  backgroundColor: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'scroll'
}, ({ theme }) => ({
  [`${theme.breakpoints.down('lg')}`]: {
    width: '100%',
    height: '100%'
  }
}));

const TitleButton = styled(Button)({
  fontWeight: 600,
  fontSize: '23px',
  color: 'black',
}, ({ theme }) => ({
  fontFamily: theme.primary.fontFamily
}));

const StyledSelect = styled(Select)(() => ({
  borderRadius: '2px',
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  font: theme.typography.heavy,
  '& .MuiOutlinedInput-root': {
    borderRadius: '2px',
    borderWidth: '2px',
    '& fieldset': {
      borderColor: theme.palette.neutral.main,
      borderWidth: '2px',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.neutral.dark,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.extraLight,
    }
  },
}));

const ColoredCircle = styled.div(({ backgroundColor }) => ({
  backgroundColor: backgroundColor,
  width: 30,
  height: 30,
  borderRadius: 15,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& :hover': {
    backGroundColor: 'black'
  }
}));
