import React from 'react';
import styled from '@emotion/styled';
import { history } from '@/index';
import CalendarBar from './CalendarBar';
import CalendarGrid from './CalendarGrid';
import { startOfWeek, endOfWeek, subWeeks, addWeeks, addDays, format } from 'date-fns';
import { axios } from '@/api';

const Wrapper = styled('div')({
  padding: 15,
  position: 'relative',
  paddingTop: 45,
});

export default class Calendar extends React.Component {
  constructor(props) {
    super();
    this.state = {
      dateRange: {
        startOfWeek: props.match.params.date ? props.match.params.date : startOfWeek(Date.now()),
        endOfWeek: props.match.params.date ? endOfWeek(props.match.params.date) : endOfWeek(Date.now()),
        ...this.weekDays(props.match.params.date ? props.match.params.date : startOfWeek(Date.now())),
      },
      events: [],
      filterByUser: false,
      sortBy: 'type',
      user_accounts: []
    };
  }

  componentDidMount() {
    const { setTitle = () => {}, pageTitle } = this.props;
    setTitle(pageTitle);
    this.getEvents();
    this.getProductionUsers();
  }

  getProductionUsers = () => {
    axios.get('/users/production')
      .then(res => {
        this.setState({ user_accounts: res.data.results });
      });
  }

  backward = async () => {
    let { startOfWeek, endOfWeek } = this.state.dateRange;
    startOfWeek = format(subWeeks(startOfWeek, 1), 'YYYY-MM-DD');
    endOfWeek = format(subWeeks(endOfWeek, 1), 'YYYY-MM-DD');
    await this.reloadWithNewDates(startOfWeek, endOfWeek);
    this.setState({
      dateRange: {
        startOfWeek,
        endOfWeek,
        ...this.weekDays(startOfWeek),
      }
    }, () => {
      this.reloadCalendar();
    });
  };

  datesArray = (start) => {
    return [
      start,
      addDays(start, 1),
      addDays(start, 2),
      addDays(start, 3),
      addDays(start, 4),
      addDays(start, 5),
      addDays(start, 6),
    ];
  };

  dragDateChange = (e, date) => {
    axios.put('/calendar/card', {
      production_card_id: e.dataTransfer.getData('production_card_id'),
      date: date
    })
      .then(() => {
        this.reloadCalendar();
      });
  };

  forward = async () => {
    let { startOfWeek, endOfWeek } = this.state.dateRange;
    startOfWeek = format(addWeeks(startOfWeek, 1), 'YYYY-MM-DD');
    endOfWeek = format(addWeeks(endOfWeek, 1), 'YYYY-MM-DD');
    await this.reloadWithNewDates(startOfWeek, endOfWeek);
    this.setState({
      dateRange: {
        startOfWeek,
        endOfWeek,
        ...this.weekDays(startOfWeek),
      },
    }, () => {
      this.reloadCalendar();
    });
  };

  getStartAndEndDate = () => {
    let start = '';
    let end = '';
    if (this.props.match.params.date) {
      start = this.props.match.params.date;
      end = format(endOfWeek(this.props.match.params.date), 'YYYY-MM-DD');
    } else {
      start = format(startOfWeek(Date.now()), 'YYYY-MM-DD');
      end = format(endOfWeek(Date.now()), 'YYYY-MM-DD');
    }

    return [start, end];
  };

  getEvents = () => {
    const [start, end] = this.getStartAndEndDate();
    axios.get('/calendar/events', {
      params: {
        start,
        end
      }
    })
      .then((res) => {
        this.setState({ events: res.data.results });
      });
  };

  reloadWithNewDates = (start) => {
    history.push(`/calendar/${start}`);
    return new Promise((res) => {
      res();
    });
  };

  reloadCalendar = () => {
    this.getEvents();
  };

  toggleFilterByUser = () => {
    console.log('yarg');
    this.setState({ filterByUser: !this.state.filterByUser });
  }

  weekDays = (start) => {
    return {
      sunday: start,
      monday: addDays(start, 1),
      tuesday: addDays(start, 2),
      wednesday: addDays(start, 3),
      thursday: addDays(start, 4),
      friday: addDays(start, 5),
      saturday: addDays(start, 6)
    };
  };

  render () {
    const { dateRange, filterByUser } = this.state;
    const { user_accounts = [] } = this.state;
    return (
      <Wrapper>
        <CalendarBar
          color={'black'}
          forward={this.forward}
          backward={this.backward}
          dateRange={dateRange}
          reloadEvents={this.reloadCalendar}
          users={user_accounts}
          toggleTasks={this.toggleTasks}
          togglePauses={this.togglePauses}
          filterByUser={filterByUser}
          sortBy={this.state.sortBy}
          updateSortBy={(value) => { this.setState({ sortBy: value });}}
          toggleFilterByUser={this.toggleFilterByUser}
        />
        <CalendarGrid
          match={this.props.match}
          dateRange={dateRange}
          events={this.state.events}
          reloadEvents={this.reloadCalendar}
          dragDateChange={this.dragDateChange}
          users={user_accounts}
          tasks={false}
          pauses={false}
          filterByUser={filterByUser}
          sortBy={this.state.sortBy}
        ></CalendarGrid>
      </Wrapper>
    );
  }
}
