const initialState = {
  workCenters: []
};

const WorkCentersReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'WORKCENTERS/SET':
    return {
      ...state,
      workCenters: action.workCenters
    };
  default:
    return state;
  }
};

export default WorkCentersReducer;
