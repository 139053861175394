import React from 'react';
import styled from '@emotion/styled';
import { AllIcon } from '@/common/img/icons/workCenters';
import { testWorkCenters } from '@/testData.js';

const IconWrapper = styled('div')({
  '& svg': {
    maxWidth: 30,
  },
  width: 44,
  padding: 5,
  textAlign: 'center',
  verticalAlign: 'middle'
});

export default class WorkcenterIndicator extends React.Component {
  constructor(props) {
    super(props);
  }

  getWorkcenterIcon = (workcenter_id) => {
    let Icon = AllIcon;
    if(testWorkCenters?.[workcenter_id]?.icon) {
      Icon = testWorkCenters[workcenter_id].icon;
    }
    return Icon;
  };

  render() {
    const Icon = this.getWorkcenterIcon(this.props.workcenter.workcenter_id);
    return (
      <IconWrapper
        style={{
          backgroundColor: this.props.workcenter.htmlcolor,
          ...this.props.style
        }}
      >
        <Icon />
      </IconWrapper>
    );
  }
}
