import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import ColumnsIcon from 'react-icons/lib/md/view-column';
import { ColumnSelectorModal } from './Grid.ColumnSelector';
import AddIcon from 'react-icons/lib/md/add-circle';
import FilterList from 'react-icons/lib/md/filter-list';
import EditInventory from 'react-icons/lib/md/view-list';
import InventorySlider from '../../pages/Workorder/InventorySlider';
import { Button } from '../Controls.js';

export default class Toolbar extends React.Component {
  state = { 
    selectorCoordinates: { x: 0, y: 0 },
    columnSelectorOpen: false,
    sliderOpen: false
  };
  closeColumnSelector = () => {
    this.setState({columnSelectorOpen: false});
  };
  closeSlider = () => {
    this.setState({ sliderOpen: false });
  };
  getToolbarPosition = () => {
    const toolbar = this.toolbar.getBoundingClientRect();
    return { x: toolbar.x, y: toolbar.y };
  };
  openColumnSelector = () => {
    this.setState({columnSelectorOpen: true, selectorCoordinates: this.getToolbarPosition()});
  };
  openSlider = () => {
    this.setState({ sliderOpen: true });
  };
  render() {
    const { activeColumns, setActiveColumns, addRow, hideableColumns, autoSort, editLock, editLockable } = this.props;
    return (
      <Wrapper
        ref={toolbar => {
          this.toolbar = toolbar;
        }}
      >
        <ColumnSelectorModal
          activeColumns={activeColumns}
          onChange={setActiveColumns}
          isOpen={this.state.columnSelectorOpen}
          onModalClose={this.closeColumnSelector}
          coordinates={this.state.selectorCoordinates}
          hideableColumns={hideableColumns}
        />
        {this.props.hideableColumns.length !== 0 &&
          <ToolbarButton
            disabled={editLockable && editLock}
            onClick={this.openColumnSelector}
          >
            <IconWrapper>
              <ColumnsIcon />
            </IconWrapper>
            <Label>Manage Columns</Label>
          </ToolbarButton>
        }
        <ToolbarButton
          disabled={editLockable && editLock}
          onClick={addRow}
        >
          <IconWrapper className={css({width: 18})}>
            <AddIcon />
          </IconWrapper>
          <Label>Add Row</Label>
        </ToolbarButton>
        { !this.props.hideAutoSort &&
          <ToolbarButton
            disabled={editLockable && editLock}
            onClick={() => {
              autoSort();
            }}
          >
            <IconWrapper className={css({width: 18})}>
              <FilterList />
            </IconWrapper>
            <Label>Auto Sort</Label>
          </ToolbarButton>
        }
        {this.props.hideEditInventory || 
        <>
          <ToolbarButton
            onClick={this.openSlider}
          >
            <IconWrapper className={css({width: 18})}>
              <EditInventory />
            </IconWrapper>
            <Label>Edit Inventory</Label>
          </ToolbarButton>
          <InventorySlider
            isOpen={this.state.sliderOpen}
            onModalClose={this.closeSlider}
          />
        </>
        }
        { this.props.editLockable &&
          <div>
            { this.props.editLock ?
              <Button
                onClick={() => {
                  this.props.toggleEditing();
                }}
                styles={{ marginLeft: '8px'}}
              >
                Edit Lines
              </Button>
              :
              <>
                <Button
                  onClick={() => {
                    this.props.updateLines();
                    this.props.toggleEditing();
                  }}
                  styles={{ marginLeft: '8px'}}
                >
                  Save Lines
                </Button>
                <Button
                  onClick={() => {
                    this.props.revertChanges();
                    this.props.toggleEditing();
                  }}
                  styles={{ marginLeft: '8px'}}
                >
                  Cancel Edit
                </Button>
              </>
            }
          </div>
        }
      </Wrapper>
    );
  }
}

const ToolbarButton = styled('button')(({ theme }) => ({
  border: 'none',
  borderRadius: 3,
  background: 'transparent',
  padding: '5px 8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.primary.color,
  fontSize: 11,
  fontFamily: theme.primary.fontFamily,
  cursor: 'pointer',
  '&:hover': {
    background: 'rgb(238, 238, 238)'
  },
  '&:active': {
    background: 'rgb(228, 227, 227)'
  },
  '&:focus': {
    outline: 'none'
  }
}));

const Wrapper = styled('div')({
  width: '100%',
  padding: '5px 0px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row'
});

const IconWrapper = styled('div')(({ theme }) => ({
  margin: -3,
  marginRight: 3,
  width: 25,
  flex: '0 0 auto',
  color: theme.neutral.light,
  '& svg': {
    width: '100%',
    height: '100%'
  }
}));

const Label = styled('span')({
  paddingTop: 2
});
