import React, { useEffect, useRef, useState } from 'react';
import compose from '../../utils/compose';
import { connect } from 'react-redux';
import { Wrapper } from '../../components/shell/Shell';
import FilterBar from '../../components/Collections/FilterBar';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { css } from 'emotion';
import debounce from 'lodash/debounce';
import ModalStateController from '../../components/ModalStateController';
import { getTagColorById } from '../../theme';
import { Button } from '../../components/Controls';
import { axios } from '../../api';

const TagsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

function TagAdmin(props) {
  const [filter, setFilter] = useState('');
  const [activeTag, setActiveTag] = useState(null);
  const [pickerDimensions, setPickerDimensions] = useState(null);
  const [tags, setTags] = useState([]);
  const prevTagsRef = useRef([]);
  let addButton = null;

  useEffect(() => {
    const { setTitle = () => {}, pageTitle } = props;
    setTitle(pageTitle);
    getTags();
  }, []);

  useEffect(() => {
    if(activeTag &&
      tags &&
      tags.length === prevTagsRef.current.length
    ) {
      console.log(activeTag);
      const index = tags.findIndex(x => x.tag_id === activeTag.tag_id);
      if(index !== -1) {
        setActiveTag(tags[index]);
      }
    }
    prevTagsRef.current = tags;
  }, [activeTag, tags]);

  function getTags() {
    axios.get('/tags')
      .then((res) => {
        setTags(res.data.result);
      });
  }
  
  /*
  componentDidUpdate = prevProps => {
    if (
      activeTag &&
      tags &&
      tags.length === prevProps.tags.tags.length
    ) {
      const index = tags.findIndex(
        x => x.tag_id === activeTag.tag_id
      );
      setActiveTag(tags[index]);
    }
  }
    */

  function clearFilter() {
    setFilter('');
  }

  function getFilteredTags() {
    if (!tags) {
      return [];
    } else if (!filter) {
      return tags;
    } else {
      const filter = filter.toLowerCase();
      return tags.filter(t =>
        t.title.toLowerCase().includes(filter)
      );
    }
  }
  function openPicker(activeTag, pickerDimensions) {
    setActiveTag(activeTag);
    setPickerDimensions(pickerDimensions);
  }
  function closePicker() { setActiveTag(null); }
  function openCreatePicker() {
    const { x, y, width, height } = addButton.getBoundingClientRect();
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;
    setActiveTag({ title: '', tag_id: 0, color: '' });
    setPickerDimensions({ x, y, width, height, pageWidth, pageHeight });
  }
  function selectTag(tag) { 
    setActiveTag(tag); 
    getTags();
  }
  const { theme } = props;
  return (
    <Wrapper>
      <FilterBar
        title="Tags"
        color={theme.primary.light}
        value={filter}
        onChange={setFilter}
        onClear={clearFilter}
        allowMini
      />
      <TagsWrapper>
        {getFilteredTags().map(tag => (
          <EditableTag
            active={
              activeTag &&
              tag.tag_id === activeTag.tag_id
            }
            notActive={
              activeTag &&
              tag.tag_id !== activeTag.tag_id
            }
            archived={tag.active}
            onTagClick={openPicker}
            tag={tag}
            key={tag.tag_id}
          />
        ))}
        <TagWrapper
          ref={(thing) => {
            addButton = thing;
          }}
          onClick={openCreatePicker}
          className={`${css({
            background: 'none',
            border: '3px dashed',
            padding: '7px 12px',
            cursor: 'default',
            color: theme.neutral.extraLight,
            '&:hover': {
              transform: 'none',
              boxShadow: 'none',
              color: theme.neutral.light,
            },
          })} __tag-wrapper`}
        >
          Add New Tag
        </TagWrapper>
      </TagsWrapper>
      <TagEditorModal
        isOpen={activeTag ? true : false}
        activeTag={activeTag}
        pickerDimensions={pickerDimensions}
        onRequestModalClose={closePicker}
        selectTag={selectTag}
        getTags={getTags}
      />
    </Wrapper>
  );
}
export default (withTheme)(TagAdmin);

const TagWrapper = styled('div')(
  {
    padding: '10px 15px',
    borderRadius: 5,
    margin: 5,
    userSelect: 'none',
    cursor: 'pointer',
    transition: 'transform 200ms, opacity 200ms, box-shadow 200ms',
    '&:hover': {
      transform: 'scale(1.05)',
      opacity: 0.8,
    },
    // flex: '1 1 auto',
    color: '#fff',
  },
  ({ theme }) => ({
    background: theme.primary.color,
    '&:hover': {
      boxShadow: theme.shadows.d2,
    },
  }),
  ({ active, archived, theme }) =>
    active
      ? {
        transform: 'scale(1.05)',
        opacity: archived ? 0.8 : 1,
        boxShadow: theme.shadows.d2,
        textDecoration: archived ? 'line-through' : '',
      }
      : {
        opacity: archived ? 0.5 : '',
        textDecoration: archived ? 'line-through' : '',
      },
  ({ notActive }) =>
    notActive
      ? {
        opacity: 0.3,
      }
      : null
);

class EditableTag extends React.Component {
  selectTag = () => {
    const { x, y, width, height } = this.tag.getBoundingClientRect();
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;
    this.props.onTagClick(this.props.tag, {
      x,
      y,
      width,
      height,
      pageWidth,
      pageHeight,
    });
  }
  render() {
    const { tag, active, notActive } = this.props;
    const tagColor = getTagColorById(tag.colorId);
    return (
      <React.Fragment>
        <TagWrapper
          className="__tag-wrapper"
          ref={r => {
            this.tag = r;
          }}
          style={{
            background: tagColor.color,
            color: tagColor.text,
          }}
          active={active}
          notActive={notActive}
          archived={!tag.active}
          onClick={this.selectTag}
        >
          {tag.title}
        </TagWrapper>
      </React.Fragment>
    );
  }
}

const PICKER_WIDTH = 252;

export class TagEditorModal extends React.Component {
  getPosition = () => {
    const {
      pickerDimensions: { x, y, width, height, pageWidth },
    } = this.props;
    const centeredX = x + width / 2 - PICKER_WIDTH / 2;
    return {
      left:
        centeredX + PICKER_WIDTH + 10 > pageWidth
          ? pageWidth - PICKER_WIDTH - 10
          : centeredX,
      top: y + height + 5,
    };
  }
  render() {
    const { isOpen, onRequestModalClose, activeTag, selectTag } = this.props;
    return (
      <ModalStateController
        isOpen={isOpen}
        shade={false}
        wrapperStyle={{ pointerEvents: 'none' }}
        onModalClose={onRequestModalClose}
      >
        {({ getProps, closeModal }) => (
          <div
            style={{
              position: 'fixed',
              transition: 'left 150ms, top 150ms',
              ...this.getPosition(),
            }}
          >
            <TagEditor
              wrapperProps={getProps()}
              color={activeTag.colorId}
              active={activeTag.active}
              title={activeTag.title}
              tag_id={activeTag.tag_id}
              id={activeTag.id}
              closeModal={closeModal}
              selectTag={selectTag}
              getTags={this.props.getTags}
            />
          </div>
        )}
      </ModalStateController>
    );
  }
}

const EditorWrapper = styled('div')(
  {
    width: PICKER_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    borderRadius: 5,
    pointerEvents: 'all',
    animationDuration: '150ms',
  },
  ({ theme }) => ({
    boxShadow: theme.shadows.d3,
    border: `1px solid ${theme.neutral.extraExtraLight}`,
  })
);

const InputWrapper = styled('div')(
  {
    padding: 10,
    marginBottom: -10,
    width: '100%',
  },
  ({ isNew }) =>
    isNew
      ? {
        marginBottom: 0,
      }
      : null
);

const TitleInput = styled('input')(
  {
    width: '100%',
    borderRadius: 4,
    height: 35,
    padding: 5,
  },
  ({ theme }) => ({
    border: `2px solid ${theme.neutral.extraExtraLight}`,
    '&:focus': {
      borderColor: theme.vibrant.color,
      outline: 'none',
    },
  })
);

const ColorsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: 5,
});

const ColorButton = styled('button')(
  {
    width: 30,
    height: 30,
    borderRadius: 4,
    border: 'none',
    margin: 5,
  },
  ({ theme }) => ({
    boxShadow: theme.shadows.d1,
  })
);

function TagEditor(props) {
  const [title, setTitle] = useState(props.title);
  const [color, setColor] = useState(props.color);
  const [tagChanged, setTagChanged] = useState(false);
  const [sentCreateRequest, setSentCreateRequest] = useState(false);
  const [cachedCreateTitle, setCachedCreateTitle] = useState('');
  const [input, setInput] = useState(null);
  const [wrapper, setWrapper] = useState(null);

  useEffect(() => {
    if(input) {
      input.focus();
      document.addEventListener('click', handleOutsideClick);
      return () => { document.removeEventListener('click', handleOutsideClick); }
    }
  }, [input]);

  useEffect(() => {
    if(tagChanged) {
      input.focus();
      setTagChanged(false);
    }
  }, [tagChanged]);

  useEffect(() => {
    setTitle(props.title);
    setColor(props.color);
    setTagChanged(true);
    setSentCreateRequest(props.tag_id === 0 ? false : true);
  }, [props.tag_id])

  function handleOutsideClick(e) {
    if (
      !wrapper.contains(e.target) &&
      !e.target.className.includes('__tag-wrapper')
    ) {
      props.closeModal();
    }
  }
  function setTitleName(e) {
    const title = e.target.value;
    if (props.tag_id === 0) {
      if (!sentCreateRequest) {
        setTitle(title);
        setSentCreateRequest(true);
        setCachedCreateTitle(title);
        createTag(title);
      } else {
        setTitle(title);
        setCachedCreateTitle(title);
      }
    } else {
      setTitle(title);
      debouncedPushTitle(title, props.tag_id);
    }
  }
  function pushTitle(title, tag_id) {
    axios.put('/tags', {
      title, 
      tag_id
    })
      .then(() => {
        props.getTags();
      });
  }
  const debouncedPushTitle = debounce(pushTitle, 50);
  function pushCachedTitle(title, tag_id) {
    pushTitle(title, tag_id);
  }
  function createTag(title) {
    const { user_account_id: user_created_id} = props;
    axios.post('/tags', {
      title,
      user_created_id
    })
      .then((res) => {
        const tag = res.data.result;
        if (tag.title !== cachedCreateTitle) {
          const inputTitle = input.value;
          pushCachedTitle(
            inputTitle.length > cachedCreateTitle
              ? inputTitle
              : cachedCreateTitle,
            tag.tag_id,
          );
        }
        props.selectTag(tag);
      });
  }
  function setColorName(colorId) {
    axios.put('/tags', {
      colorId,
      tag_id: props.tag_id,
    })
      .then(() => {
        props.getTags();
      });
  }
  function toggleArchiveState() {
    const newArchiveState = !props.active;
    axios.put('/tags', {
      tag_id: props.tag_id,
      active: newArchiveState
    })
      .then(() => {
        props.getTags();
        props.closeModal();
      });
  }

  const { wrapperProps, theme, tag_id, active } = props;
  const { orderedTagColors } = theme;
  return (
    <EditorWrapper
      {...wrapperProps}
      ref={r => {
        setWrapper(r);
      }}
    >
      <InputWrapper isNew={props.tag_id === 0}>
        <TitleInput
          ref={r => (setInput(r))}
          value={title}
          onChange={setTitleName}
        />
      </InputWrapper>
      {tag_id !== 0 && (
        <ColorsWrapper>
          {orderedTagColors.map(c => (
            <ColorButton
              style={{
                background: c.color,
              }}
              onClick={() => {
                setColorName(c.colorId);
              }}
              key={c.colorId}
            />
          ))}
        </ColorsWrapper>
      )}
      {tag_id !== 0 && (
        <Button
          css={{
            margin: 10,
            marginTop: 0,
            // borderRadius: 5
          }}
          //requireHold
          //holdDuration={1500}
          //onHoldComplete={toggleArchiveState}
          onClick={toggleArchiveState}
          danger
        >
          {active ? 'Archive' : 'Unarchive'} Tag
        </Button>
      )}
    </EditorWrapper>
  );
}
TagEditor = compose(
  connect((state) => ({
    user_account_id: state.app.user_account_id
  })),
  withTheme
)(TagEditor);
