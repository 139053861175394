import React, { useEffect, useState } from 'react';
import { axios } from '../../api';
import Grid from '../../components/Collections/Grid';
import { namesTemplate, resolveNamesConfig } from '../../data/linesTemplates';

export default function NamesNumsPdf(props) {
  console.log(props);
  const [namesAndNums, setNamesAndNums] = useState([]);
  const [namesConfig, setNamesConfig] = useState({});
  useEffect(() => {
    getItems();
  }, []);

  function getItems() {
    axios.get('/document', {
      params: {
        id: props.match.params.document_id,
        attributes: [
          'names_and_nums',
          'namesNumsLinesConfig'
        ]
      }
    })
      .then((res) => {
        setNamesAndNums(res.data.document.names_and_nums);
        setNamesConfig(res.data.document.namesNumsLinesConfig);
      });
  }

  const namesNumsActiveColumns = namesConfig || resolveNamesConfig(namesConfig);
  return (
    <div>
      <Grid
        template={namesTemplate.filter(t => namesNumsActiveColumns[t.id])}
        newRowTemplate={namesTemplate}
        hideableColumns={namesTemplate.filter(t => t.hideable === true)}
        activeColumns={namesNumsActiveColumns}
        lines={namesAndNums.sort((a,b) => a.name < b.name ? -1 : 1)}
        disableOnClickOutside={false}
        hideEditInventory={true}
        sort={['printoption', 'stylenumber', 'color']}
        orderBy={['asc', 'asc']}
        //ref={grid => { this.grid = grid; }}
      />
    </div>
  );
}
