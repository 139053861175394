function numberToDollar(value) {
  return (Math.round(Number(value) * 100) / 100).toFixed(2);
}

/*
 * column stuff
 * key: String; The name of the key on the column object
 * headerName: String; The header to display
 * editable: Bool = true (the entire table can be uneditable, in which case this will do nothing)
 * hideable: Bool = true (again, override on the whole table)
 * default: The value of upon new row creation. Left undefined if not set
 * type: Defaults to string
 *    - string (default)
 *    - stylenumber
 *    - checkbox: accepts a boolean
 *    - number
 * valueFunction: (row) => return string; This allows you to create custom cell values.
 *    If the valueFunction returns an element, it will render that element in the cell
 */
export default [
  {
    key: 'colorgroup',
    headerName: 'C',
    editable: true,
    type: 'string',
    hideable: false,
    maxWidth: 50
  },
  {
    key: 'quantity',
    headerName: 'Qty',
    maxWidth: 40,
    minWidth: 30,
    editable: false,
    hideable: false
  },
  {
    key: 'stylenumber',
    headerName: 'Style',
    editable: true,
    hideable: false,
    minWidth: 90,
    maxWidth: 200,
    type: 'stylenumber'
  },
  {
    editable: true,
    key: 'color',
    headerName: 'Color',
    maxWidth: 120,
    minWidth: 80,
    hideable: false
  },
  {
    key: 'desc',
    headerName: 'Desc',
    maxWidth: 70,
    hideable: false,
    editable: false
  },
  {
    key: 'nb',
    headerName: 'NB',
    editable: true,
    minWidth: 15,
  },
  {
    key: 'm3',
    headerName: '3m',
    minWidth: 15,
    editable: true,
  },
  {
    key: 'm6',
    headerName: '6m',
    minWidth: 15,
    editable: true,
  },
  {
    key: 'm12',
    headerName: '12m',
    minWidth: 15,
    editable: true,
  },
  {
    key: 'm18',
    headerName: '18m',
    minWidth: 15,
    editable: true,
  },
  {
    key: 'm24',
    headerName: '24m',
    minWidth: 15,
    editable: true,
  },
  {
    key: 't2',
    headerName: '2t',
    minWidth: 15,
    editable: true,
  },
  {
    key: 't3',
    headerName: '3t',
    minWidth: 15,
    editable: true,
  },
  {
    key: 't4',
    headerName: '4t',
    minWidth: 15,
    editable: true,
  },
  {
    key: 't56',
    headerName: '5/6',
    minWidth: 15,
    editable: true,
  },
  {
    key: 'xs',
    headerName: 'xs',
    minWidth: 15,
    editable: true,
  },
  {
    key: 's',
    headerName: 's',
    minWidth: 15,
    editable: true,
  },
  {
    key: 'm',
    headerName: 'm',
    minWidth: 15,
    editable: true,
  },
  {
    key: 'l',
    headerName: 'l',
    editable: true,
    minWidth: 15,
  },
  {
    key: 'xl',
    headerName: 'xl',
    editable: true,
    minWidth: 15,
  },
  {
    key: 'xxl',
    headerName: 'xxl',
    editable: true,
    minWidth: 15,
  },
  {
    key: 'xl3',
    headerName: '3xl',
    editable: true,
    minWidth: 15,
  },
  {
    key: 'xl4',
    headerName: '4xl',
    editable: true,
    minWidth: 15,
  },
  {
    key: 'xl5',
    headerName: '5xl',
    editable: true,
    minWidth: 15,
  },
  {
    key: 'xl6',
    headerName: '6xl',
    editable: true,
    minWidth: 15,
  },
  {
    key: 'misc',
    headerName: 'Misc',
    editable: true,
    minWidth: 15,
  },
  {
    key: 'itemPrice',
    headerName: 'Item Price',
    maxWidth: 75,
    hideable: false,
    editableFunction: (row) => {
      return row.customPriceOn ?? false;
    },
    editDisplayValue: numberToDollar
  },
  {
    key: 'customPriceOn',
    headerName: 'Custom',
    hideable: false,
    type: 'checkbox',
    maxWidth: 30
  },
  {
    key: 'lineTotal',
    headerName: 'Total',
    maxWidth: 80,
    minWidth: 60,
    hideable: false,
    editable: false,
    editDisplayValue: numberToDollar
  }
];
