import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import DatePicker from '../../components/DatePicker';
import { format } from 'date-fns';
import { axios, createHistory } from '../../api';

export default function DetailsList(props)  {
  const [documentDetails, setDocumentDetails] = useState([]);

  useEffect(() => {
    axios.get('/document/detail/all', {
      params: { document_id: props.document_id }
    }) 
      .then((res) => { setDocumentDetails(res.data.result); });
  }, []);

  function placeholder() {
    const array = [];
    for (let i = 0; i < 12; i++) {
      array[i] = { id: i };
    }
    return array;
  }

  function order_details(details) {
    if (details.length == 1) {
      return details;
    }
    const ret = placeholder();

    details.map(detail => {
      ret[detail.routeorder] = details[details.map(function(e) { return e.attributename; }).indexOf(detail.attributename)];
    });

    return ret;
  }
  
  const { document_id, disablePaid } = props;
  const computedDetails = documentDetails.length === 0
    ? placeholder()
    // : details.document_details
    : order_details(documentDetails);
  return (
    <div>
      {computedDetails
        ? computedDetails.map((detail, i) => (
          (detail) ? <ConnectedDetail
            {...detail}
            document_id={document_id}
            disabled={disablePaid && detail.attributename === 'paid'}
            key={i}
          /> : ''
        ))
        : null}
    </div>
  );
}

const DetailRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: 30,
  color: theme.primary.textOn,
  background: theme.primary.medium,
  '&:nth-of-type(even)': {
    background: theme.primary.lightMedium,
  },
}));

const Toggle = styled('div')({
  width: 40,
  height: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '0 0 40px',
});

const InnerToggle = styled('button')(({ theme, active }) => ({
  width: 20,
  height: 20,
  background: active ? theme.blue.color : 'none',
  border: active ? 'none' : `2px solid ${theme.primary.textOn}`,
  margin: active ? 0 : 5,
  borderRadius: '100%',
  '&:focus': {
    borderColor: theme.blue.color,
    outline: 'none',
  },
  '&:disabled': {
    opacity: 0.5,
  },
}));

const Label = styled('label')(({ theme }) => ({
  fontSize: 11,
  fontWeight: 400,
  color: theme.neutral.light,
  height: 30,
  display: 'flex',
  alignItems: 'center',
}));

const Input = styled('input')(({ theme }) => ({
  flex: '1 1 auto',
  width: '100%',
  height: '100%',
  border: 'none',
  background: 'transparent',
  paddingLeft: 10,
  textAlign: 'right',
  color: theme.primary.textOn,
  fontFamily: theme.primary.fontFamily,
  // paddingTop: 3,
  paddingRight: 10,
  '&:focus': {
    outline: 'none',
  },
  '&:disabled': {
    color: theme.primary.textOnByAlpha(0.6),
  },
  minWidth: 0
}));

const FauxInput = styled('button')(({ theme }) => ({
  flex: '1 1 auto',
  width: '100%',
  height: '100%',
  border: 'none',
  background: 'transparent',
  paddingLeft: 10,
  textAlign: 'right',
  color: theme.primary.textOn,
  fontFamily: theme.primary.fontFamily,
  // paddingTop: 3,
  paddingRight: 10,
  '&:focus': {
    outline: 'none',
  },
  '&:disabled': {
    color: theme.primary.textOnByAlpha(0.6),
  },
}));

class ConnectedDetail extends React.Component {
  constructor(props) {
    super();
    this.state = { value: props.value };
    this.input = React.createRef();
  }
  componentDidUpdate = prevProps => {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }
  commitChange = value => {
    if (this.props.value !== this.state.value || value !== undefined) {
      value = value !== undefined ? value : this.state.value;
      const event = {
        document_id: this.props.document_id,
        json_previous: JSON.stringify(this.props.value),
        user_id: this.props.user_account_id,
        json: JSON.stringify(value),
        type: 'detail',
        key: 'document_detail',
      };
      axios.put('/document/detail', {
        attributename: this.props.attributename,
        document_id: this.props.document_id,
        value
      })
        .then(() => {
          this.setState({ value: value });
          createHistory({
            ...event,
          });
        });
    }
  }
  checkEnter = ({ charCode }) => {
    if (charCode === 13) {
      this.input.current.blur();
      this.commitChange();
    }
  }
  setValue = e => {
    const value = e.target.value;
    this.setState({ value });
  }
  toggleDetail = () => {
    if (!this.state.value) {
      this.commitChange(format(Date.now(), 'MM/DD/YYYY'));
    } else {
      this.commitChange('');
    }
  }
  render() {
    const { attributename, disabled } = this.props;
    const value = this.state.value ? this.state.value : '';
    return (
      <Detail
        disabled={disabled}
        value={value}
        attributename={attributename}
        toggleDetail={this.toggleDetail}
        setValue={this.setValue}
        commitChange={this.commitChange}
        checkEnter={this.checkEnter}
        ref={this.input}
      />
    );
  }
}
ConnectedDetail = connect((state) => ({
  user_account_id: state.app.user_account_id,
}))(ConnectedDetail);

export const Detail = React.forwardRef(
  (
    {
      disabled,
      value,
      attributename,
      toggleDetail,
      setValue,
      commitChange,
      checkEnter,
      datePicker,
    },
    inputRef
  ) => {
    const [datePickerOpen, setDatePickerOpen] = React.useState(false);
    const [datePickerStyle, setDatePickerStyle] = React.useState({});
    const dueDateRef = React.useRef();
    const openDatePicker = () => {
      const dm = dueDateRef.current.getBoundingClientRect();
      setDatePickerOpen(true);
      setDatePickerStyle({
        left: 100,
        top: dm.bottom,
      });
    };

    return (
      <DetailRow>
        <Toggle>
          <InnerToggle
            disabled={disabled}
            onClick={toggleDetail}
            active={value !== ''}
          />
        </Toggle>
        <Label>{attributename}</Label>
        {datePicker ? (
          <React.Fragment>
            <FauxInput
              ref={dueDateRef}
              disabled={disabled}
              onClick={openDatePicker}
            >
              {value}
            </FauxInput>
            <DatePicker
              isOpen={datePickerOpen}
              onModalClose={() => setDatePickerOpen(false)}
              onDayClick={setValue}
              style={datePickerStyle}
            />
          </React.Fragment>
        ) : (
          <Input
            ref={inputRef}
            disabled={disabled}
            value={value}
            onChange={setValue}
            onBlur={() => {
              commitChange();
            }}
            onKeyPress={checkEnter}
          />
        )}
      </DetailRow>
    );
  }
);
Detail.displayName = 'Detail';
