import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { Wrapper } from '../../components/shell/Shell';
import TextField from '@mui/material/TextField';
import BiotechIcon from '@mui/icons-material/Biotech';
import FloatingActionButton from '../../components/FloatingActionButton';
import { css } from 'emotion';
import { axios } from '../../api.js';
import AppBar from '../../components/Reusable/navigation/AppBar.tsx';
import DataGrid from '../../components/Reusable/data/DataGrid/DataGrid.js';
import UserIcon from '../../components/Reusable/user/UserIcon.js';
import UserModal from './UserModal.tsx';

const userColumns = [
  { 
    key: 'profilepicture',
    headerName: '',
    editable: false,
    hideable: false,
    valueFunction: (val) => {
      return (
        <div
          style={{ margin: 2}}
        >
          <UserIcon
            size={30}
            profilepic={val.user_profile.profilepicture}
            key={val.user_account_id}
          />
        </div>
      );
    },
    maxWidth: 34
  },
  {
    key: 'name',
    headerName: 'Name',
    editable: false,
    hideable: false,
    valueFunction: (val) => {
      return val.user_profile.first_name + ' ' + val.user_profile.last_name;
    }
  },
  {
    key: 'email',
    headerName: 'Email',
    editable: false,
    hideable: false
  },
  {
    key: 'phone',
    headerName: 'Phone',
    editable: false,
    hideable: false,
    valueFunction: (val) => {
      return val.user_profile.phone
    }
  },
  {
    key: 'active',
    headerName: 'Active',
    editable: false,
    hideable: false,
    type: 'checkbox',
    maxWidth: 50
  }
];

function Users(props) {
  const [selectedUserId, setSelectedUserId] = useState(-1);
  const [showInactive, setShowInactive] = useState(false);
  const [filter, setFilter] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const { setTitle = () => {}, pageTitle } = props;
    setTitle(pageTitle);
    getUsers();
  }, []);

  function getUsers() {
    axios.get('/users', {
      params: {
        active: false
      }
    })
      .then((res) => { setUsers(res.data.results); });
    
  }

  function getFilteredRows() {
    let user_accounts = users.map(user => ({
      ...user.user_profile_user_account, 
      user_profile: {
        profilepicture: user.profilepicture,
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone
      }
    }));
    if (!showInactive) {
      user_accounts = user_accounts.filter(u => u.active);
    }
    if (!filter) {
      return user_accounts;
    } else {
      const tmpFilter = filter.toLowerCase();
      return user_accounts.filter(u =>
        `${u.user_profile.first_name} ${u.user_profile.last_name}`
          .toLowerCase()
          .includes(tmpFilter)
      );
    }
  }

  function selectUser(row) {
    setSelectedUserId(row.user_account_id);
  }

  function clearSelected() {
    selectUser({ user_account_id: null, isNewUser: false });
  }
  function refetchAndClearSelected() {
    clearSelected();
    props.users.refetch();
  }

  function toggleShowInactive() { setShowInactive(!showInactive); }

  const { theme } = props;
  const template = [
    {
      id: 'profilepicture',
      label: '',
      renderCell: (row, CellWrapper) => {
        const { profilepicture } =
          row && row.user_profile ? row.user_profile : '';
        return (
          <CellWrapper
            className={css({
              padding: 3,
            })}
            key={row.id}
          >
          </CellWrapper>
        );
      },
    },
    {
      id: 'name',
      label: 'Name',
      bold: true,
      render: row =>
        `${row.user_profile &&
          row.user_profile.first_name} ${row.user_profile &&
          row.user_profile.last_name}`,
    },
    {
      id: 'email',
      label: 'Email',
    },
    {
      id: 'phone',
      label: 'Phone',
      render: row => row.user_profile && row.user_profile.phone,
    },
    {
      id: 'active',
      label: 'Status',
      render: row => (row.active ? 'Active' : 'Inactive'),
    },
  ];

  return (
    <>
      <AppBar
        title="Employees"
      >
        <BiotechIcon 
          sx={{ color: showInactive ? 'white' : 'grey', mx: 2 }} 
          onClick={() => { setShowInactive(!showInactive); }}
        />
        <TextField 
          label="Filter" 
          variant="outlined" 
          size="small"
          sx={{ backgroundColor: 'white', borderRadius: 1 }}
          onChange={(e) => { setFilter(e.target.value); }}
        />
      </AppBar>
      <Wrapper>
        <DataGrid 
          columns={userColumns}
          columnsHideable={false}
          rows={getFilteredRows()}
          readOnly={true}
          readOnlyRowClick={(value) => { 
            setSelectedUserId(value.user_account_id); 
          }}
        />
        <UserModal 
          viewingUser={selectedUserId}
          onClose={() => {
            setSelectedUserId(-1);
            getUsers();
          }}
        />
        <FOBWrapper>
          <FloatingActionButton
            css={theme => ({
              background: theme.blue.color,
              alignSelf: 'flex-end',
              '&:hover': {
                background: theme.blue.light,
              },
            })}
            onClick={() => setSelectedUserId(0)}
          />
        </FOBWrapper>
      </Wrapper>
    </>
  );
}
export default (withTheme)(Users);

const FOBWrapper = styled('div')({
  position: 'fixed',
  right: 30,
  bottom: 30,
  zIndex: 5,
});
