import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import UploadIcon from 'react-icons/lib/md/cloud-upload';
import ImageFallback from './ImageFallback';
import { Button } from './Controls';
import userPlaceholder from '../common/img/user_placeholder_white.svg';

export default class UploadCropper extends React.Component {
  state = {
    cropping: false,
    dataUrl: null,
    uploading: false,
  }
  upload = e => {
    const file = e.target.files[0];
    this.setState({
      uploading: true,
      error: false
    });
    this.props.onUpload({file, onComplete: this.onComplete, onError: this.onError});
  }
  onComplete = () => {
    this.setState({ uploading: false });
  }
  onError = () => {
    this.setState({ uploading: false, error: true });
  }
  render() {
    const { image } = this.props;
    return (
      <Wrapper>
        <UploadWrapper>
          <ImageFallback
            fallback={
              <ImageWrapper>
                <LabelAsButton htmlFor="profile-uploader">
                  <UploadIcon />
                </LabelAsButton>
                <Image src={userPlaceholder} />
              </ImageWrapper>
            }
          >
            {props => (
              <ImageWrapper>
                <LabelAsButton htmlFor="profile-uploader">
                  <UploadIcon />
                </LabelAsButton>
                <Image {...props} src={image} />
              </ImageWrapper>
            )}
          </ImageFallback>
          <HiddenFileInput
            type="file"
            onChange={this.upload}
            id="profile-uploader"
            name="profile-uploader"
            accept=".jpg,.png"
          />
          {this.state.uploading && (
            <div className={css({ marginTop: 15 })}>
              <Button disabled onClick={this.crop}>
                Uploading
              </Button>
            </div>
          )}
          {this.state.error && (
            <ErrorWrapper>
              Oops! Something went wrong, use a different image or try again
              later.
            </ErrorWrapper>
          )}
        </UploadWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled('div')({});

const LabelAsButton = styled('label')({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
});

const HiddenFileInput = styled('input')({
  position: 'fixed',
  left: -1,
  top: -1,
  width: 1,
  height: 1,
  opacity: 0,
});

const UploadWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const ImageWrapper = styled('div')(
  {
    width: 150,
    height: 150,
    borderRadius: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  ({ theme }) => ({
    background: theme.neutral.extraLight,
    border: `10px solid ${theme.neutral.extraLight}`,
  })
);

const Image = styled('img')({
  width: '100%',
  height: '100%'
});

const ErrorWrapper = styled('p')(({ theme }) => ({
  color: theme.danger.dark,
  textAlign: 'center',
}));
