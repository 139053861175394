export const colemanTemplate = [
  {
    label: 'Additional Emblems',
    id: 'emblems',
  }
];

export const namesTemplate = [
  {
    label: 'Design',
    id: 'printoption',
    maxWidth: 110 
  },
  {
    label: 'Qty',
    id: 'quantity',
    maxWidth: 40,
    mask: 'numbers',
    defaultValue: 0,
    hideable: true
  },
  {
    label: 'Style',
    id: 'stylenumber',
    hideable: true
  },
  {
    label: 'Color',
    id: 'stylecolor',
    hideable: true
  },
  {
    label: 'Size',
    id: 'size',
    hideable: true
  },
  {
    label: 'Name',
    id: 'name',
    hideable: true
  },
  {
    label: 'Number',
    id: 'number',
    mask: 'numbers',
    hideable: true
  }
];

export const template = [
  {
    label: 'Design',
    id: 'printoption',
    maxWidth: 60,
  },
  {
    label: 'Print',
    id: 'printing',
    maxWidth: 50,
  },
  {
    label: 'Qty',
    id: 'quantity',
    maxWidth: 40,
    mask: 'numbers',
    defaultValue: 0,
  },
  {
    label: 'Style',
    id: 'stylenumber',
    type: 'stylenumber',
  },
  {
    label: 'Color',
    id: 'stylecolor',
  },
  {
    label: 'NB',
    id: 'NB',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '3m',
    id: '3m',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '6m',
    id: '6m',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '12m',
    id: '12m',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '18m',
    id: '18m',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '24m',
    id: '24m',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '2t',
    id: '2t',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '3t',
    id: '3t',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '4t',
    id: '4t',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '5/6',
    id: '6t',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: 'xs',
    id: 'xs',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: 's',
    id: 'sm',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: 'm',
    id: 'mdm',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: 'l',
    id: 'lg',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: 'xl',
    id: 'xl',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: 'xxl',
    id: 'xxl',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: 'xxxl',
    id: 'xxxl',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '4xl',
    id: 'xxxxl',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '5xl',
    id: 'xxxxxl',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: '6xl',
    id: 'xxxxxxl',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size',
  },
  {
    label: 'Misc',
    id: 'misc',
    maxWidth: 35,
    mask: 'numbers',
    type: 'size'
  },
  {
    label: 'Other',
    id: 'other',
    maxWidth: 35
  },
  {
    label: 'Desc',
    id: 'otherdesc',
  },
  {
    label: 'Price',
    id: 'itemprice',
    maxWidth: 70,
    format: 'currency',
    getIsDisabled: row => row.customprice !== true,
  },
  {
    label: 'Custom',
    id: 'customprice',
    maxWidth: 40,
    type: 'checkbox',
  },
];

/*
 * The following deal with the layout of the pricing lines
 * found in the main part of the edit page
 */

const nonSizeColumns = {
  printoption: true,
  printing: true,
  quantity: true,
  stylenumber: true,
  stylecolor: true,
  other: true,
  otherdesc: true,
  itemprice: true,
  customprice: true,
};

const defaultColumns = {
  xs: true,
  sm: true,
  mdm: true,
  lg: true,
  xl: true,
  misc: true
};

const allOriginalColumns = {
  printoption: true,
  printing: true,
  quantity: true,
  stylenumber: true,
  stylecolor: true,
  other: true,
  otherdesc: true,
  itemprice: true,
  customprice: true,
  xs: true,
  sm: true,
  mdm: true,
  lg: true,
  xl: true,
  xxl: true,
  xxxl: true,
  xxxxl: true,
  xxxxxl: true,
  xxxxxxl: true,
  misc: true
};

export const defaultColumnsNull = {
  xs: true,
  sm: true,
  mdm: true,
  lg: true,
  xl: true,
  xxl: true,
  xxxl: true,
  xxxxl: true,
  xxxxxl: true,
  xxxxxxl: true,
  misc: true
};

export const readableSizeColumns = {
  XS: true,
  SM: true,
  M: true,
  LG: true,
  XL: true,
  XXL: true,
  XXXL: true,
  '4XL': true,
  '5XL': true,
  '6XL': true,
  MISC: true
};

export const getFilteredTemplate = (linesConfig = {}, version) => {
  const allowed = resolveLinesConfig(linesConfig || {}, version);
  return template.filter(t => allowed[t.id]);
};

export const nonSizeColumnsArray = Object.entries(nonSizeColumns).map(c => c[0]);

export const resolveLinesConfig = (linesConfig = {}, version) => {

  return linesConfig && linesConfig.activeColumns
    ? { ...linesConfig.activeColumns, ...nonSizeColumns }
    : version === 1
      ? allOriginalColumns
      : { ...nonSizeColumns, ...defaultColumns };

};

/*
 * These two functions deal with the names and numbers grid
 * found on the edit page
 */

export const resolveNamesConfig = (linesConfig) => {
  if(linesConfig?.activeColumns) {
    return { ...linesConfig.activeColumns };
  } else {
    const activeColumns = {};
    for (const key in namesTemplate) {
      const id = namesTemplate[key].id;
      activeColumns[id] = true;
    }

    return activeColumns;
  }
};

export const getFilteredNames = (linesConfig = {}) => {
  return namesTemplate.filter(t => linesConfig[t.id]);
};
