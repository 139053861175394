import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from 'emotion';
import Grid from './Collections/Grid';
import { SimpleCheckbox } from './Controls';
import compose from '../utils/compose';
import { axios, createHistory, serverSentEventSub } from '../api.js';

import Toolbar from '../pages/Workorder/Toolbar';

import { resolveNamesConfig, namesTemplate } from '../data/linesTemplates';
import { Button, Checkbox } from '@mui/material';

class NamesAndNumbers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      names_and_nums: [],
      names_and_nums_on: false,
      namesNumsLinesConfig: {},
      isreorder: 0
    };
  }

  componentDidMount() {
    this.getNamesAndNums();
    serverSentEventSub.addEventListener('names_and_numbers', this.handleServerSentEvent);
  }

  componentWillUnmount() {
    serverSentEventSub.removeEventListener('names_and_numbers', this.handleServerSentEvent);
  }

  handleServerSentEvent = () => {
    console.log('names and numbers');
    const eventData = JSON.parse(event.data);
    console.log(eventData);
    console.log(this.props.document_id);
    if(Number(eventData.document_id) !== Number(this.props.document_id)) {
      return;
    }
    console.log(eventData.document_id);
    this.setState({names_and_nums: eventData.names_and_nums});
  };

  getNamesAndNums = () => {
    axios.get('/document', {
      params: {
        id: this.props.document_id,
        attributes: [
          'names_and_nums_on',
          'names_and_nums',
          'isreorder',
          'namesNumsLinesConfig'
        ]
      }
    })
      .then((res) => {
        this.setState({
          names_and_nums: res.data.document.names_and_nums,
          names_and_nums_on: res.data.document.names_and_nums_on,
          namesNumsLinesConfig: res.data.document.namesNumsLinesConfig,
          isreorder: res.data.document.isreorder
        });
      });
  };

  getSheet = () => {
    axios.get('/document/namesAndNumbers/sheet', {
      params: {
        document_id: this.props.document_id
      }
    })
      .then((res) => {
        const blobUrl = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'text/csv',
          })
        );
        const a = document.createElement('a');
        a.download = 'names_and_numbers_sheet.csv';
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  toggleNamesAndNums = active => {
    axios.put('/document/singleField', {
      id: this.props.document_id,
      field: 'names_and_nums_on',
      fieldValue: active
    })
      .then(() => {
        this.setState({ names_and_nums_on: active });
        createHistory({
          document_id: this.props.document_id,
          json_previous: this.state.isreorder,
          user_id: this.props.user_account_id,
          json: active,
          type: 'toggle',
          key: 'namesandnumson',
        });
      });
  };

  updateNamesAndNums = names_and_nums => {
    axios.put('/document/singleField', {
      id: this.props.document_id,
      field: 'names_and_nums',
      fieldValue: names_and_nums
    })
      .then(() => {
        this.setState({ names_and_nums: names_and_nums });
        createHistory({
          document_id: this.props.document_id,
          json_previous: this.state.names_and_nums,
          json: names_and_nums,
          type: 'names_and_nums',
          key: 'lines',
          user_id: this.props.user_account_id,
        });
      });
  };

  uploadSheet = e => {
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);
    fileData.append('document_id', this.props.document_id);
    axios({
      method: 'PUT',
      url: '/document/namesAndNumbers/sheet',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: fileData
    })
      .catch(e => { console.log(e); });
  }

  setNamesActiveColumns = activeColumns => {
    axios.put('/document/singleField', {
      id: this.props.document_id,
      field: 'namesNumsLinesConfig',
      fieldValue: activeColumns
    })
      .then(() => {
        this.setState({ namesNumsLinesConfig: activeColumns });
      });
  };

  render() {

    const {
      namesNumsLinesConfig: namesConfig = {},
      names_and_nums_on = false,
    } = this.state;

    const namesNumsActiveColumns = namesConfig || resolveNamesConfig(namesConfig);

    return (
      <>
        <FlexRow
          className={css({
            marginTop: 15,
            paddingLeft: 15,
            paddingRight: 15,
          })}
        >
          <Toolbar
            label="Names and Numbers"
            buttons={
              <>
                <Button
                  size="small"
                  variant="outlined"
                  href={'/workorder/namesnumspdf/' + this.props.document_id}
                  target="_blank"
                  sx={{ mr: 1 }}
                >Get Names Nums Pdf</Button>
                <Button
                  component="label"
                  size="small"
                  variant="outlined"
                  sx={{ mr: 1 }}
                >
                  Upload Sheet
                  <VisuallyHiddenInput 
                    onChange={this.uploadSheet}
                    type="file" />
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={this.getSheet}
                >Get Sheet</Button>
                <Checkbox
                  size="small"
                  checked={names_and_nums_on}
                  onChange={e => { this.toggleNamesAndNums(e.target.checked); }}
                ></Checkbox>
              </>
            }
          />
        </FlexRow>
        {names_and_nums_on &&
          <GridWrapper>
            <Grid
              template={namesTemplate.filter(t => namesNumsActiveColumns[t.id])}
              newRowTemplate={namesTemplate}
              hideableColumns={namesTemplate.filter(t => t.hideable === true)}
              activeColumns={namesNumsActiveColumns}
              setActiveColumns={this.setNamesActiveColumns}
              lines={this.state.names_and_nums}
              updateLines={this.updateNamesAndNums}
              disableOnClickOutside={false}
              hideEditInventory={true}
              sort={['printoption', 'stylenumber', 'color']}
              orderBy={['asc', 'asc']}
              ref={grid => {
                this.grid = grid;
              }}
            />
          </GridWrapper>
        }
      </>
    );

  }
}

export default compose(
  connect((state) => ({
    user_account_id: state.app.user_account_id,
  }))
)(NamesAndNumbers);

const FlexRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const GridWrapper = styled('div')({
  padding: '0px 15px',
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
