import React from 'react';
import axios from 'axios';
import { BASE_URL } from '../../api';


export default class WorkcenterIndicator extends React.Component {
  constructor() {
    super();
    this.state = {
      workcenters: {}
    };
  }

  componentDidMount() {
    axios.get(`${BASE_URL}/api/workcenters`)
      .then((res) => {
        const workcenters = res.data.result.reduce((previousValue, currentValue) => {
          previousValue[currentValue.workcenter_id] = currentValue;
          return previousValue;
        }, {});
        this.setState({ workcenters });
      });
  }

  render() {
    const {
      workcenter_id
    } = this.props;

    const {
      workcenters
    } = this.state;
    return (
      <>
        <p>{workcenters?.[workcenter_id]?.name}</p>
      </>
    );
  }
}
