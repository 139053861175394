export const calendarOptions = [
  { value: 'Finished', label: 'Finished' },
  { value: 'Manual', label: 'Manual' },
  { value: 'Large Manual', label: 'Large Manual' },
  { value: 'Auto/Manual', label: 'Auto/Manual' },
  { value: 'Auto', label: 'Auto' },
  { value: 'Junior', label: 'Junior' },
  { value: 'Heat Press', label: 'Heat Press' },
  { value: 'DTG', label: 'DTG' },
  { value: 'Specialty', label: 'Specialty' },
  { value: 'Trouble Ticket', label: 'Trouble Ticket' },
  { value: 'Panels', label: 'Panels' },
  { value: 'X-Type', label: 'X-Type' },
  { value: '', label: 'None' },
];

export const calendarDesignTypes = [
  { value: 'Finished', label: 'Finished' },
  { value: 'Manual', label: 'Manual' },
  { value: 'Large Manual', label: 'Large Manual' },
  { value: 'Auto/Manual', label: 'Auto/Manual' },
  { value: 'Auto', label: 'Auto' },
  { value: 'Junior', label: 'Junior' },
  { value: 'Heat Press', label: 'Heat Press' },
  { value: 'DTG', label: 'DTG' },
  { value: 'Specialty', label: 'Specialty' },
  { value: 'Trouble Ticket', label: 'Trouble Ticket' },
  { value: 'Panels', label: 'Panels' },
  { value: 'X-Type', label: 'X-Type' },
  { value: '', label: 'None' },
];

export const timeOptions = [
  { value: 0, label: 'No time' },
  { value: .25, label: '15 minutes' },
  { value: .5, label: '30 minutes' },
  { value: .75, label: '45 minutes' },
  { value: 1, label: '1 hour' },
  { value: 1.5, label: '1.5 hours' },
  { value: 2, label: '2 hours' },
  { value: 2.5, label: '2.5 hours' },
  { value: 3, label: '3 hours' },
  { value: 3.5, label: '3.5 hours' },
  { value: 4, label: '4 hours' },
  { value: 4.5, label: '4.5 hours' },
  { value: 5, label: '5 hours' },
  { value: 5.5, label: '5.5 hours' },
  { value: 6, label: '6 hours' },
  { value: 7, label: '7 hours' },
  { value: 8, label: '8 hours' },
  { value: 9, label: '9 hours' },
  { value: 10, label: '10 hours' },
  { value: undefined, label: '' },
];

export const TypeColorMap = {
  'Finished': { bg: '#C3C7C2', text: '#020202' },
  'Manual': { bg: '#F3B891', text: '#020202' },
  'Large Manual': { bg: '#F48B48', text: '#020202' },
  'Auto/Manual': { bg: '#7DC89A', text: '#020202' },
  'Auto': { bg: '#20B358', text: '#020202' },
  'Junior': { bg: '#F06292', text: '#020202' },
  'Heat Press': { bg: '#F69A9C', text: '#020202' },
  'DTG': { bg: '#F27165', text: '#020202' },
  'Panels': { bg: '#EBDC4F', text: '#020202' },
  'Specialty': { bg: '#90BAE3', text: '#020202' },
  'Trouble Ticket': { bg: '#957DA8', text: '#020202' },
  'X-Type': { bg: '#0082b5', text: '#020202' }
};

export const Days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

export const StatusColorMap = {
  'Rush': '#ED4E51',
  'Must': '#FFCF1A',
  'Standard': '#333333',
};

export const StatusSortOrder = [
  'Rush',
  'Must',
  'Standard',
  '',
  null
];

export const TypeSortOrder = [
  'Finished',
  'Manual',
  'Large Manual',
  'Auto/Manual',
  'X-Type',
  'Auto',
  'Junior',
  'Heat Press',
  'DTG',
  'Panels',
  'Specialty',
  'Trouble Ticket',
  '',
  null
];
