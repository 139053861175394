import React from 'react';
import { css } from 'emotion';
import { Wrapper } from '../../components/shell/Shell';
import { ConnectedFilterBar } from '../WorkCenters/WorkCenters';
import theme from '../../theme';
import { Button, Loading } from '../../components/Controls';
import axios from 'axios';
import { BASE_URL } from '../../api';

export default class ProductSheets extends React.Component {

  state = {
    loading: false
  }

  componentDidMount() {
    const { setTitle = () => {}, pageTitle } = this.props;
    setTitle(pageTitle);
  }

  retrieveSnSData = () => {
    this.setState({ loading: true });

    axios.get(`${BASE_URL}/api/productsheets/sns`)
      .then(res => {
        const blobUrl = window.URL.createObjectURL(
          new Blob([res.data], {
            type:
              'text/csv',
          })
        );
        const a = document.createElement('a');
        a.download = 'sns-sheet.csv';
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  retrieveSanMarData = () => {
    this.setState({ loading: true });
    axios.get(`${BASE_URL}/api/productsheets/sanmar`, { timeout: 3600000 })
      .then(res => {
        const blobUrl = window.URL.createObjectURL(
          new Blob([res.data], {
            type:
              'text/csv',
          })
        );
        const a = document.createElement('a');
        a.download = 'sanmar-sheet.csv';
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <Wrapper
        className={css({
          paddingTop: 60,
        })}
      >
        <ConnectedFilterBar
          workCenter={{
            name: 'Product Sheets',
            htmlcolor: theme.primary.light,
          }}
          value={this.props.filter}
        />
        <Button
          onClick={ this.retrieveSnSData }
        >S&S (~5 minutes)</Button>
        <Button
          styles={{
            marginTop: '10px'
          }}
          onClick={ this.retrieveSanMarData }
        >SanMar (~35 minutes)</Button>
        <Loading
          isOpen={this.state.loading}
        />
      </Wrapper>
    );
  }
}
