import React, { useState, useEffect } from 'react';
import {
  Button,
  TextInput,
  ConfirmationModal
} from './Controls';
import { axios, createHistory }  from '../api.js';
import { connect } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { TypedHistory } from './History';
import styled from '@emotion/styled';
import { css } from 'emotion';
import EmailPicker from './EmailPicker.js';

const ApprovalsTitle = styled('h3')({
  marginTop: 0
});

const Title = styled('h2')({
  marginTop: 0
});

const NewPaymentWrapper = styled('div')({});

const TextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

const Text = styled('p')({
  display: 'flex',
  marginTop: 5,
  marginBottom: 5
});

export default function Approvals(props) {
  const { doc } = props;
  const [approvalEmails, setApprovalEmails] = useState([]);

  useEffect(() => {
    getCurrentApprovalEmails();
  }, []);

  function getCurrentApprovalEmails() {
    axios.get('/approvals/email', {
      params: {
        document_id: doc.document_id
      }
    })
      .then((res) => {
        setApprovalEmails(res.data.result);
      });
  }

  return (
    <>
      <Title>Approvals</Title>
      <hr />
      <EmailApproval doc={doc} onModalClose={props.onModalClose} />
      <ApprovalsTitle>Sent Approval Emails</ApprovalsTitle>
      {(approvalEmails && approvalEmails.length > 0) 
        ? approvalEmails.map( (approval, index) => { 
          return (<ActiveApprovalEmail 
            approvalEmail={approval} 
            key={index} 
            onDelete={() => { getCurrentApprovalEmails(); }}>
          </ActiveApprovalEmail>);
        }) 
        : <>No approval emails are currently active. <hr /></>}

      <ApprovalsTitle>History</ApprovalsTitle>
      <TypedHistory document_id={props.doc.document_id} type="approval" />
    </>
  );
}

function ActiveApprovalEmail(props) {
  const [confirm, setConfirm] = useState(false);

  function openConfModal() {
    setConfirm(true);
  }

  function closeConfModal() {
    setConfirm(false);
  }

  function deleteEmail() {
    axios.delete('/approvals/email', {
      params: {
        uuid: props.approvalEmail.uuid
      }
    }).then(() => {
      props.onDelete();
    });

    const event = {
      document_id: props.approvalEmail.document_id,
      user_id: props.user_account_id,
      type: 'approval',
      key: 'deletedEmail'
    };

    createHistory(event);

    closeConfModal();
  }

  const email = props.approvalEmail;
  return (
    <>
      <NewPaymentWrapper
        className={css({
          padding: '15px'
        })}
      >
        <TextContainer>
          <Text>
            To: {email.to}
          </Text>
          <Text>
            {new Date(email.createdAt).toDateString()}
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>
            CC: {email.cc}
          </Text>
        </TextContainer>
        <TextContainer>
          <Text
            css={{
              flex: '29'
            }}
          >Note: {email.note}</Text>
          <TextContainer>
            <Button
              styles={{
                height: '10px',
                width: '25px',
                alignSelf: 'flex-end',
                backgroundColor: 'red',
                flex: '1',
                paddingTop: '3px',
                paddingRight: '29px',
                paddingBottom: '19px',
                paddingLeft: '15px'
              }}
              onClick={openConfModal}
            >
              <DeleteIcon/>
            </Button>
          </TextContainer>
        </TextContainer>
        <ConfirmationModal
          isOpen={confirm}
          onModalClose={closeConfModal}
          onConfirm={deleteEmail}
          text="Are you sure you want to delete this email?"
        ></ConfirmationModal>
      </NewPaymentWrapper>
      <hr />
    </>
  ); 
}
ActiveApprovalEmail = connect((state) => ({
  user_account_id: state.app.user_account_id,
}))(ActiveApprovalEmail);

function EmailApproval(props) {

  const [cc, setCc] = useState('');
  const [to, setTo] = useState('');
  const [note, setNote] = useState('');
  const [error, setError] = useState('');
  const [documentDetails, setDocumentDetails] = useState([]);

  useEffect(() => {
    axios.get('/document/detail/all', {
      params: { document_id: props.doc.document_id }
    })
      .then((res) => {
        setDocumentDetails(res.data.result);
      });
  }, []);

  function changeTo(val, e) {
    if(e == true) {
      if(cc != val){
        setTo(val);
      } else {
        setTo(val);
        setCc('');
      }
    } else {
      setTo('');
    }
  }

  function changeCC(val, e) {
    if(e == true) {
      if(to != val) {
        setCc(val);
      } else {
        setCc(val);
        setTo('');
      }
    } else {
      setCc('');
    }
  }

  function changeNote(note) {
    setNote(note);
  }

  function submitApproval() {
    const event = {
      document_id: props.doc.document_id,
      user_id: props.user_account_id,
      json: {
        cc: cc,
        note: note,
        to: to,
      },
      type: 'approval',
      key: 'sentEmailApproval'
    };


    axios({
      method: 'POST',
      url: '/approval',
      data: {
        to: to,
        cc: cc,
        note: note,
        document_id: props.doc.document_id
      },
      crossDomain: true,
    }).then(() => {
      changeNote('');
      props.onModalClose();

      const detail = documentDetails.find(e => e.attributename === 'approved');

      axios.put('/document/detail', {
        attributename: 'approved',
        document_id: props.doc.document_id,
        value: 'Waiting'
      })
        .then(() => {
          createHistory(event);
          createHistory({
            document_id: props.doc.document_id,
            json_previous: JSON.stringify(detail.value),
            user_id: props.user_account_id,
            json: JSON.stringify('Waiting'),
            type: 'detail',
            key: 'document_detail',
          });
        });
    })
      .catch(error => {
        console.log(error);
        setError('Unable to send email.');
      });
  }

  const { doc } = props;
  return (
    <>
      <NewPaymentWrapper>
        <ApprovalsTitle>New Email Approval</ApprovalsTitle>
        <EmailPicker
          to={to}
          cc={cc}
          changeTo={changeTo}
          changeCC={changeCC}
          careof={doc.customer.email}
          billto={doc.customer_billto.email}
          defaultEmails={true}
        />
        <TextInput
          width="100%"
          type="textarea"
          label="Note"
          css={{height: '150px'}}
          onChange={({target: {value}}) => {changeNote(value);}}
          value={note}
        ></TextInput>
        <Button
          onClick={() => submitApproval()}
          styles={{marginLeft: '5px'}}
        >Send</Button>
        { error !== '' && <h4 style={{color: '#ff5050'}}>{error}</h4>}
      </NewPaymentWrapper>
      <hr />
    </>
  );
}
EmailApproval = connect((state) => ({
  user_account_id: state.app.user_account_id,
}))(EmailApproval);
