import React from 'react';
import styled from '@emotion/styled';
import CloseIcon from 'react-icons/lib/md/close';

const TagWrapper = styled('span')(({size, clickable}) => ({
  position: 'relative',
  padding: size === 'large' ? '6px 8px' : '2px 5px',
  borderRadius: 3,
  fontSize: size === 'large' ? 13 : 10,
  // textShadow: size === 'large' ? '0px 1px 2px rgba(0,0,0,.3)' : '',
  margin: 2,
  fontWeight: 600,
  background: '#000000',
  color: '#ffffff',
  cursor: clickable ? 'pointer' : 'default',
  '&:hover': {
    opacity: clickable ? .8 : 1,
    '& .DeleteButton': {
      opacity: 1
    }
  }
}));

export const Tag = ({
  children,
  color,
  textColor='#ffffff',
  size = 'small',
  onClick = () => {},
  clickable,
  deleteable,
  onRequestDelete,
  css={}
}) => (
  <TagWrapper
    onClick={onClick}
    clickable={clickable}
    size={size}
    style={{
      background: color,
      color: textColor
    }}
    css={css}
  >
    {
      deleteable &&
      <DeleteButton className="DeleteButton" style={{background: color}} onClick={onRequestDelete}>
        <CloseIcon/>
      </DeleteButton>
    }
    {children}
  </TagWrapper>
);


export const formatTags = (value, alltags) => {
  const tagValue = value ? value.split(',') : [];
  const tags = tagValue.map(tagId => alltags.find(tag => tag.tag_id.toString() === tagId)).filter(tag => tag);
  return tags;
};

const DeleteButton = styled('button')({
  opacity: 0,
  position: 'absolute',
  border: 'none',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  color: '#fff',
  padding: 0,
  appearance: 'none',
  borderRadius: 3,
  outline: 'none'
});
