import React, { useEffect } from 'react';
import { Route, withRouter} from 'react-router-dom';
import Login from '@pages/Login/Login';
import compose from '../../../utils/compose';
import { connect } from 'react-redux';

function AuthRoute(props) {
  const loggedIn = props.user_account_id !== null;
  const { pageTitle = 'Badger Admin Tool', setTitle = () => console.log('undefined setTitle.'), ...moreProps } = props;

  useEffect(() => {
    if (pageTitle !== 'Badger Admin Tool') setTitle(pageTitle);
  }, [pageTitle]);

  return loggedIn ? (
    <Route {...moreProps} setTitle={setTitle} />
  ) : (
    <Login {...props} />
  );
}

export default compose(
  withRouter,
  connect((state, props) => {
    return {
      user_account_id: state.app.user_account_id,
      user_group_id: state.app.user_group_id,
      location: props.location,
    };
  })
)(AuthRoute);
