import React from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import compose from '../utils/compose';
import { withRouter } from 'react-router-dom';
import { Follower } from '../pages/Workorder/Followers';
import { MenuWithButtons } from './FloatingMenu';
import { credentialsHeader, axios } from '../api';
import UserModal from '../pages/Users/UserModal';

class ProfileButton extends React.Component {
  state = { 
    menuOpen: false, 
    x: 10, 
    y: 10,
    accountEditorOpen: false,
    user: undefined
  };

  componentDidMount = () => {
    this.getUser();
  }

  getUser = () => {
    axios.get('/users/account/' + this.props.user_account_id)
      .then((res) => {
        this.setState({ user: res.data.result });
      });
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.checkForClickOutside);
  };

  openMenu = () => {
    const { top, left } = this.wrapper.getBoundingClientRect();
    document.addEventListener('click', this.checkForClickOutside);
    this.setState({ menuOpen: true, x: left, y: top });
  };

  closeMenu = () => {
    document.removeEventListener('click', this.checkForClickOutside);
    this.setState({ menuOpen: false, x: 10, y: 10 });
  }

  deleteCookie = () => {
    // console.log("post it!")
    const headers = {
      ...credentialsHeader(),
    };
    if (headers.credentials === 'include') axios.defaults.withCredentials = true;
    return axios({
      method: 'POST',
      url: '/auth/logout',
      headers,
      data: { },
    });
  }

  logout = async () => {
    await this.deleteCookie();
    localStorage.removeItem('token');
    localStorage.removeItem('user_account_id');
    this.props.history.push('/login');
    this.props.dispatch({
      type: '@@APP/SET_USER_ACCOUNT_ID',
      user_account_id: null,
    });
  }

  checkForClickOutside = e => {
    if (!this.wrapper.contains(e.target)) {
      this.closeMenu();
    }
  }

  openAccountEditor = () => { this.setState({ accountEditorOpen: true }); }
  closeAccountEditor = () => { this.setState({ accountEditorOpen: false }); }

  render() {
    const buttons = [
      { label: 'Manage Account', onClick: this.openAccountEditor },
      { label: 'Logout', onClick: this.logout },
    ];
    const user = this.state.user;
    return (
      <div
        ref={r => {
          this.wrapper = r;
        }}
      >
        {user && (
          <Follower
            background
            profilepicture={
              user?.user_profile?.profilepicture
            }
            onClick={this.state.menuOpen ? this.closeMenu : this.openMenu}
            onModalClose={this.toggleMenu}
          />
        )}
        {this.state.menuOpen &&
          user && (
          <MenuWithButtons
            onRequestModalClose={this.closeMenu}
            renderHeader={
              <HeaderWrapper>
                <Follower
                  background
                  profilepicture={
                    user?.user_profile?.profilepicture
                  }
                  onClick={
                    this.state.menuOpen ? this.closeMenu : this.openMenu
                  }
                  onModalClose={this.toggleMenu}
                />
                <NameWrapper>
                  <span>
                    {user?.user_profile?.first_name}{' '}
                    {user?.user_profile?.last_name}
                  </span>
                  <Username>{user?.username}</Username>
                </NameWrapper>
              </HeaderWrapper>
            }
            buttons={buttons}
            style={{
              left: this.state.x - 2,
              top: this.state.y - 2,
              position: 'fixed',
              zIndex: 999,
            }}
          />
        )}
        <UserModal
          viewingUser={user?.user_account_id}
          isOpen={this.state.accountEditorOpen && user}
          onClose={this.closeAccountEditor}
        />
      </div>
    );
  }
}
export default compose(
  connect(state => ({
    user_account_id: state.app.user_account_id,
  })),
  withRouter
)(ProfileButton);

const HeaderWrapper = styled('div')({
  background: '#fff',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 2,
  paddingRight: 10,
  borderBottom: '1px solid #ddd',
});

const NameWrapper = styled('span')(
  {
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  () => ({
    // color: theme.neutral.color
  })
);

const Username = styled('div')(({ theme }) => ({
  fontSize: 11,
  color: theme.neutral.color,
}));
