import React from 'react';
import { connect } from 'react-redux';
import DetailsList, { Detail } from './DetailsList';
import styled from '@emotion/styled';
import compose from '../../utils/compose';
import format from 'date-fns/format';
import {
  Button
} from '@components/Controls';
import {
  SidebarTextArea,
  SidebarSelect,
  SidebarSwitch
} from '@components/SidebarControls';
import { css } from 'emotion';
import TimeIcon from 'react-icons/lib/md/watch-later';
import Payments from '@components/Payments';
import Approvals from '@components/Approvals';
import Stock from '@components/Stock';
import Modal from '@components/Modal';
import Commissions from '../../components/Containers/commissions/Commissions.tsx';
import DownloadIcon from 'react-icons/lib/md/file-download';
import AssignmentIcon from 'react-icons/lib/md/assignment';
import theme from '@/theme';
import WorkorderEditSidebarHeader from './Workorder.Edit.Sidebar.Header';
import { axios, createHistory, serverSentEventSub } from '@/api';
import { SidebarLog } from '@components/CustomerLog';
import { Loading } from '../../components/Controls.js';
import Invoices from '../../components/Containers/invoices/Invoices.tsx';

const TogglesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

const sidebarButtonStyles = {
  width: '100%',
  background: 'rgba(255,255,255,.05)',
  '&:hover': {
    background: 'rgba(255,255,255,.1)',
  },
};

const sidebarHalfButtonStyles = {
  height: '40px',
  background: 'rgba(255,255,255,.05)',
  '&:hover': {
    background: 'rgba(255,255,255,.1)',
  },
};

const buttonLoading = {
  background: '#5b99ff',
  '&:hover': {
    background: '#5b99ffbb',
  },
  animation: 'loading-rotation 20s linear infinite',
};

class WorkorderEditSidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      commissions: false,
      invoices: false,
      customerLoad: false,
      loading: false,
      productionLoad: false,
      paymentsModalOpen: false,
      approvalsModalOpen: false,
      overstockDialogueOpen: false,
      contactLogOpen: false,
      document_id: props.match.params.document_id,
      document: {},
    };

    this.sseEventName = 'updateSidebar';
  }

  componentDidMount() {
    serverSentEventSub.addEventListener(this.sseEventName, this.handleServerSentEvent);
    this.getDocument();
  }

  componentWillUnmount() {
    serverSentEventSub.removeEventListener(this.sseEventName, this.handleServerSentEvent);
  }

  downloadCustomer = () => {
    this.setState((state) => {
      const currentState = state;
      currentState.customerLoad = true;
      return currentState;
    });
    this.setState({ loading: true });
    axios({
      method: 'get',
      url: `/exportcustomer/${this.state.document_id}`,
      crossDomain: true,
      responseType: 'blob',
    }).then(response => {
      this.setState({customerLoad: false});
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.state.document_id}-${this.state.document.jobname}.pdf`);
      document.body.appendChild(link);
      link.click();
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  downloadProduction = () => {

    this.setState((state) => {
      const currentState = state;
      currentState.productionLoad = true;
      return currentState;
    });
    this.setState({ loading: true });

    axios({
      method: 'get',
      url: `/exportproduction/${this.state.document.document_id}`,
      crossDomain: true,
      responseType: 'blob',
    }).then(response => {
      this.setState({productionLoad: true});
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.state.document.document_id}-${this.state.document.jobname}-full.pdf`);
      document.body.appendChild(link);
      link.click();
    }).finally(() => {
      this.setState({ loading: false });
    });
  };

  handleServerSentEvent = (event) => {
    const document = this.state.document;
    const eventData = JSON.parse(event.data);
    if(eventData.document_id !== this.state.document_id) {
      return;
    }
    Object.assign(document, eventData);
    this.setState({document});
  };

  getDocument = () => {
    axios.get('/document', {
      params: {
        id: this.state.document_id,
      }
    })
      .then((res) => {
        this.setState({ document: res.data.document });
      });
  }

  openPaymentsDialogue = () => {
    this.setState(state => ({paymentsModalOpen: !state.paymentsModalOpen}));
  };
  closePaymentsDialogue = () => {
    this.setState({paymentsModalOpen: false});
  };
  
  openApprovalsDialogue = () => {
    this.setState(state => ({approvalsModalOpen: !state.approvalsModalOpen}));
  };
  closeApprovalsDialogue = () => {
    this.setState({approvalsModalOpen: false});
  };

  openInvoices = () => {
    this.setState({ invoices: true });
  };

  closeInvoices = () => {
    this.setState({ invoices: false });
  };

  openCommissions = () => {
    this.setState({ commissions: true });
  };

  closeCommissions = () => {
    this.setState({ commissions: false });
  };

  openOverstockDialogue = () => {
    this.setState({ overstockDialogueOpen: true });
  };
  closeOverstockDialogue = () => {
    this.setState({ overstockDialogueOpen: false });
  };

  updateIsreorder = active => {
    const isreorder = active ? 1 : 0;
    this.updateSingleField(
      'isreorder',
      isreorder,
      {
        document_id: this.state.document_id,
        json_previous: this.state.document.isreorder,
        user_id: this.props.user_account_id,
        json: active,
        type: 'toggle',
        key: 'isreorder',
      }
    );
  };

  updateIsWebOrder = active => {
    const isweborder = active ? 1 : 0;
    this.updateSingleField(
      'isweborder',
      isweborder,
      {
        document_id: this.state.document_id,
        json_previous: this.state.document.isweborder,
        user_id: this.props.user_account_id,
        json: active,
        type: 'toggle',
        key: 'isweborder',
      }
    );
  };

  updateIsModro = active => {
    const ismodro = active ? 1 : 0;
    this.updateSingleField(
      'ismodro',
      ismodro,
      {
        document_id: this.state.document_id,
        json_previous: this.state.document.ismodro,
        user_id: this.props.user_account_id,
        json: active,
        type: 'toggle',
        key: 'ismodro',
      }
    );
  };

  updateHasshipping = active => {
    const hasshipping = active ? 1 : 0;
    this.updateSingleField(
      'hasshipping',
      hasshipping,
      {
        document_id: this.state.document_id,
        json_previous: this.state.document.hasshipping,
        user_id: this.props.user_account_id,
        json: active,
        type: 'toggle',
        key: 'hasshipping',
      }
    );
  };

  updatePriority = priority => {
    this.updateSingleField(
      'priority',
      priority,
      {
        document_id: this.state.document_id,
        json_previous: JSON.stringify(this.state.document.priority),
        user_id: this.props.user_account_id,
        json: JSON.stringify(priority),
        type: 'select',
        key: 'priority',
      }
    );
  };

  updateOrdertype = ordertype => {
    this.updateSingleField(
      'ordertype',
      ordertype,
      {
        document_id: this.state.document_id,
        json_previous: JSON.stringify(this.state.document.ordertype),
        user_id: this.props.user_account_id,
        json: JSON.stringify(ordertype),
        type: 'select',
        key: 'ordertype',
      }
    );
  };

  setProductionNotes = specialinstructions => {
    if (specialinstructions !== this.state.document.specialinstructions) {
      this.updateSingleField(
        'specialinstructions',
        specialinstructions,
        {
          document_id: this.state.document.document_id,
          user_id: this.props.user_account_id,
          json_previous: JSON.stringify(
            this.state.document.specialinstructions
          ),
          json: JSON.stringify(specialinstructions),
          type: 'toggle',
          key: 'specialinstructions',
        }
      );
    }
  };

  setOfficeNotes = officenotes => {
    if (officenotes !== this.state.document.officenotes) {
      this.updateSingleField(
        'officenotes',
        officenotes,
        {
          document_id: this.state.document.document_id,
          json_previous: JSON.stringify(this.state.document.officenotes),
          json: JSON.stringify(officenotes),
          user_id: this.props.user_account_id,
          type: 'toggle',
          key: 'officenotes',
        }
      );
    }
  };

  createReOrder = () => {
    axios.post('/document/reorder', {
      ...this.state.document
    }).then(response => {
      this.props.history.push(`/workorder/edit/${response.data.document_id}`);
      window.location.reload();
    });
  };

  toggleArchivedState = () => {
    const isarchived = this.state.document.isarchived === 1 ? 0 : 1;
    this.updateSingleField(
      'isarchived',
      isarchived,
      {
        document_id: this.state.document_id,
        json_previous: JSON.stringify(this.state.document.isarchived),
        json: JSON.stringify(isarchived),
        user_id: this.props.user_account_id,
        type: 'toggle',
        key: 'archive',
      }
    );
  };

  updateLastOrder = lastorder => {
    this.updateSingleField(
      'lastorder',
      lastorder.toISOString().split('T')[0],
      {
        document_id: this.state.document.document_id,
        json_previous: JSON.stringify(this.state.document.lastorder),
        json: JSON.stringify(lastorder),
        user_id: this.props.user_account_id,
        type: 'toggle',
        key: 'lastorder',
      }
    );
  };

  updateSingleField = (field, value, historyEvent) => {
    axios.put('/document/singleField', {
      id: this.state.document_id,
      field: field,
      fieldValue: value,
      eventType: this.sseEventName
    })
      .then(() => {
        // Create the history before the state change because the state has the old set of values
        createHistory(historyEvent);
        const document = this.state.document;
        document[field] = value;
        this.setState({ document });
      });
  }

  render() {
    const {
      hasshipping,
      isarchived,
      ismodro,
      isreorder,
      isweborder,
      lastorder,
      officenotes,
      ordertype,
      priority,
      specialinstructions
    } = this.state.document;
    return (
      <div>
        <WorkorderEditSidebarHeader
          document_id={this.state.document_id}
        />
        <TogglesWrapper>
          <SidebarSelect
            value={priority}
            options={[
              { value: 'Standard', label: 'Standard' },
              {
                value: 'Must',
                label: 'Must',
                color: theme.orange.color,
                hoverColor: theme.orange.light,
              },
              {
                value: 'Rush',
                label: 'Rush',
                color: theme.red.color,
                hoverColor: theme.red.light,
              },
            ]}
            icon={<TimeIcon />}
            onChange={this.updatePriority}
          />
          <SidebarSelect
            value={ordertype}
            options={[
              { value: 'Standard', label: 'Standard' },
              { value: 'DTG', label: 'DTG' },
              { value: 'Embroidery', label: 'Embroidery' },
              { value: 'Employee', label: 'Employee' },
            ]}
            icon={<AssignmentIcon />}
            onChange={this.updateOrdertype}
          />
        </TogglesWrapper>
        <TogglesWrapper>
          <SidebarSwitch
            onLabel="Reorder"
            offLabel="Reorder"
            active={isreorder}
            onChange={this.updateIsreorder}
          />
          <SidebarSwitch
            onLabel="Shipping"
            offLabel="Shipping"
            active={hasshipping}
            onChange={this.updateHasshipping}
          />
        </TogglesWrapper>
        <TogglesWrapper>
          <SidebarSwitch
            onLabel="Modro"
            offLabel="Modro"
            active={ismodro}
            onChange={this.updateIsModro}
          />
          <SidebarSwitch
            onLabel="Weborder"
            offLabel="Weborder"
            active={isweborder}
            onChange={this.updateIsWebOrder}
          />
        </TogglesWrapper>
        {isreorder ? (
          <Detail
            attributename="Last Ordered"
            value={
              lastorder && typeof lastorder === 'string'
                ? format(
                  new Date(lastorder.split('-').join('/')),
                  'M/D/YY'
                )
                : lastorder
            }
            setValue={this.updateLastOrder}
            datePicker
          />
        ) : null}
        <DetailsList
          document_id={this.state.document_id}
          disablePaid={ordertype === 'Employee'}
        />
        <SidebarTextArea
          placeholder="Production Notes"
          value={specialinstructions}
          onBlur={this.setProductionNotes}
        />
        <SidebarTextArea
          light
          placeholder="Office Notes"
          value={officenotes}
          onBlur={this.setOfficeNotes}
        />

        <SidebarLog doc={this.state.document} document_id={this.props.match.params.document_id} />

        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 10,
          })}
        >
          <div className={css({width: '100%'})}>
            <a
              className={css({ width: '100%', marginBottom: 10 })}
              href={`/workorder/customerpdf/${
                this.state.document_id
              }`}
              target="blank"
            >
              <Button css={{...sidebarHalfButtonStyles, width: '70%'}} sidebar>
                Customer
              </Button>
            </a>
            <Button
              css={{
                ...sidebarHalfButtonStyles,
                marginBottom: 10,
                paddingRight: 6,
                width: '30%',
                ...(this.state.customerLoad ? buttonLoading : {})}}
              sidebar
              onClick={this.downloadCustomer}
            >
              <DownloadIcon />
            </Button>
          </div>
          <div className={css({width: '100%'})}>
            <a
              className={css({ width: '100%', marginBottom: 10 })}
              href={`/workorder/fullpdf/${this.state.document_id}`}
              target="blank"
            >
              <Button css={{...sidebarHalfButtonStyles, width: '70%'}} sidebar>
                Production
              </Button>
            </a>
            <Button
              css={{
                ...sidebarHalfButtonStyles,
                marginBottom: 10,
                paddingRight: 6,
                width: '30%',
                ...(this.state.productionLoad ? buttonLoading : {})
              }}
              sidebar
              onClick={this.downloadProduction}
            >
              <DownloadIcon />
            </Button>
          </div>
          <Button
            css={{ ...sidebarButtonStyles, marginBottom: 10}}
            onClick={this.openPaymentsDialogue}
            sidebar
          >
            Payments
          </Button>
          <Modal
            isOpen={this.state.paymentsModalOpen}
            onModalClose={this.closePaymentsDialogue}
            doc={this.state.document}
            element={Payments}
          />
          <Button
            css={{ ...sidebarButtonStyles, marginBottom: 10}}
            sidebar
            onClick={this.openApprovalsDialogue}
          >
            Approvals
          </Button>
          <Modal
            isOpen={this.state.approvalsModalOpen}
            onModalClose={this.closeApprovalsDialogue}
            doc={this.state.document}
            element={Approvals}
          />
          <Button
            css={{ ...sidebarButtonStyles, marginBottom: 10 }}
            sidebar
            onClick={this.openOverstockDialogue}
          >
            Check Stock
          </Button>
          <Modal
            isOpen={this.state.overstockDialogueOpen}
            onModalClose={this.closeOverstockDialogue}
            document_id={this.state.document.document_id}
            element={Stock}
          />
          <Button
            css={{ ...sidebarButtonStyles, marginBottom: '10px' }}
            sidebar
            onClick={this.openInvoices}
          >
            Invoices
          </Button>
          <Invoices 
            document_id={Number(this.state.document_id)}
            open={this.state.invoices}
            onClose={this.closeInvoices}
          />
          <Button
            css={{ ...sidebarButtonStyles, marginBottom: '10px' }}
            sidebar
            onClick={this.openCommissions}
          >
            Commissions
          </Button>
          <Commissions 
            document_id={Number(this.state.document_id)}
            open={this.state.commissions}
            onClose={this.closeCommissions}
          />
          <Button
            sidebar
            css={{ ...sidebarButtonStyles, marginBottom: 10 }}
            holdDuration={700}
            requireHold
            holdComplete={this.createReOrder}
          >
            Create Reorder
          </Button>
          <Button
            css={sidebarButtonStyles}
            sidebar
            requireHold
            holdComplete={this.toggleArchivedState}
          >
            {isarchived
              ? 'Unarchive Order'
              : 'Archive Order'}
          </Button>
        </div>
        <Loading 
          isOpen={this.state.loading}
        />
      </div>
    );
  }
}
export default compose(
  connect((state, props) => ({
    user_account_id: state.app.user_account_id,
    user: state.app.user,
    user_group_id: state.app.user_group_id,
    location: props.location,
  })),
)(WorkorderEditSidebar);
