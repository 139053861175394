import React from 'react';
import ModalStateController from '../../components/ModalStateController';
import { ContextMenu, MenuButton } from '../../components/ContextMenu';
import styled from '@emotion/styled';

const MenuWrapper = styled('div')({
  position: 'fixed',
  left: 30,
  top: 30
});

export default class WorkcentersMenu extends React.Component{
  render(){
    const { isOpen, onModalClose, coordinates } = this.props;
    return(
      <ModalStateController
        enterAnimationClassName="fade-in"
        exitAnimationClassName="fade-out"
        isOpen={isOpen}
        onModalClose={onModalClose}
      >
        {
          ({getProps}) => (
            <MenuWrapper
              {...getProps()}
              style={{
                left: coordinates.x,
                top: coordinates.y
              }}
            >
              <ContextMenu>
                <MenuButton>
                  Archive Order
                </MenuButton>
              </ContextMenu>
            </MenuWrapper>
          )
        }
      </ModalStateController>
    );
  }
}
