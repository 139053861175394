const security_questions = [
  {
    label: '',
    value: 0,
  },
  {
    label: 'What High School did you attend?',
    value: 1,
  },
  {
    label: 'What was the name of your first pet?',
    value: 2,
  },
  {
    label: 'What was the color of your first car?',
    value: 3,
  },
];

export default security_questions;
