import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import { axios, BASE_URL } from '@/api.js';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';

export default function UserIcon(props) {
  const [userName, setUserName] = useState(props.userName);
  const [profilePicture, setProfilePicture] = useState(props.profilepic);

  useEffect(() => {
    if(!props.profilepic && props.userId) {
      axios.get('/users/one/' + props.userId)
        .then((res) => {
          setUserName(res.data.result.first_name + ' ' + res.data.result.last_name);
          setProfilePicture(res.data.result.profilepicture);
        });
    }
  }, []);

  return (
    <Tooltip
      disableFocusListener={!userName}
      disableHoverListener={!userName}
      title={userName}
      enterDelay={10}
      placement="top"
      arrow
    >
      <Avatar
        sx={{
          borderWidth: '2px',
          borderColor: 'black',
          width: props.size,
          height: props.size,
          '&:hover': {
            '& #xbox': {
              display: 'flex'
            }
          }
        }}
      >
        {props.onUserClear && <Box
          id="xbox"
          sx={{
            display: 'none',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, .25)',
            zIndex: 10,
            minHeight: '100%',
            minWidth: '100%',
            position: 'absolute',
          }}
          onClick={props.onUserClear}
        ><ClearIcon /></Box> }
        <Avatar
          sx={{
            borderWidth: '2px',
            width: props.size,
            height: props.size,
            zIndex: 1
          }}
          imgProps={{
            loading:'lazy',
            decoding: 'async'
          }}
          src={profilePicture ? BASE_URL + '/api/documentimages/userimage' + profilePicture : undefined}
        >
        </Avatar>
      </Avatar>
    </Tooltip>
  );
}
