import React from 'react';
import { css } from 'emotion';
import styled from '@emotion/styled';
import { getTagColorById } from '../../theme.ts';
import { Tag } from '../Tags';
import DownArrow from 'react-icons/lib/md/arrow-downward';
import UpArrow from 'react-icons/lib/md/arrow-upward';
import { Checkbox } from '../Controls';

const TableWrapper = styled('div')({
  display: 'table',
  width: '100%',
  maxWidth: 1050,
});

const THead = styled('div')({
  display: 'table-header-group',
  fontSize: 13,
  fontWeight: 600,
  padding: '10px 0px',
});

const TBody = styled('div')({
  display: 'table-row-group',
});

const Cell = styled('div')({
  display: 'table-cell',
  padding: '10px 7px',
  fontSize: 13,
  userSelect: 'none',
  verticalAlign: 'middle',
  // whiteSpace: 'nowrap'
});

const Th = styled('div')({
  display: 'table-cell',
  padding: '10px 7px',
  fontWeight: 800,
});

const Row = styled('div')({
  display: 'table-row',
  cursor: 'pointer',

  '&:first-of-type': {
    '& .cell:first-of-type': {
      borderTopLeftRadius: 4,
    },
    '& .cell:last-child': {
      borderTopRightRadius: 4,
    },
  },

  '&:last-child': {
    '& .cell:first-of-type': {
      borderBottomLeftRadius: 4,
    },
    '& .cell:last-child': {
      borderBottomRightRadius: 4,
    },
  },

  '&:nth-of-type(odd)': {
    '& div.cell': {
      background: '#f9f9f9',
    },
  },

  '&:hover': {
    background: '#cde8f4',
    '&:nth-of-type(odd)': {
      '& div.cell': {
        background: '#cde8f4',
      },
    },
  },
});

const TagsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: -6,
  marginBottom: -6,
});

export default class Table extends React.Component {
  static defaultProps = {
    template: [],
    data: [],
  };

  state = {
    sortColumn: '',
    sortDirection: true
  };

  render() {
    const {
      template,
      data,
      onRowClick,
      onContextMenu,
      sortColumns,
      onSortChange,
      rowDragged
    } = this.props;
    return (
      <TableWrapper>
        <THead>
          {template.map(t => !t.hidden &&
            <Th
              key={t.id}
              onClick={() => {
                if (sortColumns && t.sortable) {
                  let direction = this.state.sortDirection;
                  if (this.state.sortColumn === t.id) {
                    direction = !direction;
                  } else {
                    direction = true;
                  }
                  this.setState({ sortColumn: t.id, sortDirection: direction }, () => {
                    onSortChange(`${this.state.sortDirection ? 'reverse:' : ''}${this.state.sortColumn}`);
                  });
                }
              }}
            >
              <div
                style={{
                  display:'flex',
                  flexDirection: 'row'
                }}
              >
                {t.checkbox &&
                 <Checkbox
                   cssOverrides={{
                     display: 'inline'
                   }}
                   value={t.checkboxChecked}
                   onChange={() => {
                     t.onCheckboxClick(!t.checkboxChecked);
                   }}

                 />
                }
                {t.label}
              </div>
              {t.id === this.state.sortColumn &&
                this.state.sortDirection && <DownArrow />
              }
              {t.id === this.state.sortColumn && 
                !this.state.sortDirection && <UpArrow />
              }
            </Th>
          )}
        </THead>
        <TBody>
          {data.map((row, i) => {
            return (
              <TableRow
                row={row}
                template={template}
                onRowClick={onRowClick}
                onContextMenu={onContextMenu}
                rowDragged={rowDragged}
                key={i}
              />
            );
          })}
        </TBody>
      </TableWrapper>
    );
  }
}

class TableRow extends React.Component {

  dragStart = (e) => {
    e.dataTransfer.setData('customer', String(this.props.row.customer.firstname));
    e.dataTransfer.setData('jobname', String(this.props.row.jobname));
    e.dataTransfer.setData('customerEmail', String(this.props.row.customer.email));
    e.dataTransfer.setData('document_id', String(this.props.row.document_id));
    e.dataTransfer.setData('prev_workcenter', String(this.props.row.workcenter));
    e.dataTransfer.setData('id', String(this.props.row.id));
    e.dataTransfer.setDragImage(new Image(), 0, 0);
  };

  dragDrop = (e) => {
    e.preventDefault();
  };

  dragOver = (e) => {
    e.preventDefault();
  };

  onRowClick = () => {
    if (this.props.onRowClick) {
      this.props.onRowClick(this.props.row);
    }
  };

  onContextMenu = e => {
    if (this.props.onContextMenu) {
      e.preventDefault();
      const coordinates = { x: e.clientX, y: e.clientY };
      this.props.onContextMenu(this.props.row, { coordinates });
    }
  };

  truncate = (value, length) => {
    if (value.length <= length) {
      return value;
    } else {
      return value.slice(0, length) + '...';
    }
  };

  getCellByType = (type, value, template, isarchived, background) => {
    switch (type) {
    case 'tags':
      return (
        <TableCell
          {...template}
          key={template.id}
          style={{ background: background ? '#0000' : isarchived === 1 ? 'rgba(200, 0, 0, 0.08)' : ''}}
        >
          <TagsWrapper>
            {value.map(tag => {
              const tagColor = getTagColorById(tag.colorId);
              return (
                <Tag
                  color={tagColor.color}
                  textColor={tagColor.text}
                  key={tag.id}
                >
                  {tag.title}
                </Tag>
              );
            })}
          </TagsWrapper>
        </TableCell>
      );
    case 'customerName':
      return (
        <TableCell
          {...template}
          key={template.id} 
          style={{ background: background ? '#0000' : isarchived === 1 ? 'rgba(200, 0, 0, 0.08)' : ''}}
        >
          {value && `${value.firstname || ''} ${value.lastname || ''}`}
        </TableCell>
      );
    default:
      return (
        <TableCell
          {...template}
          key={template.id}
          style={{ background: background ? '#0000' : isarchived === 1 ? 'rgba(200, 0, 0, 0.08)' : ''}}
        >
          {template.maxLength && typeof value === 'string'
            ? this.truncate(value, template.maxLength)
            : value}
        </TableCell>
      );
    }
  }

  calculateBackgroundStyles = (background) => {

    let styles = {};

    if(background) {
      styles = {
        background: background, // For alternating add a + 'dd' to this line for an alpha channel
        '&:hover': {
          background: background + '11',
          '&:nth-of-type(odd)': {
            '& div.cell': {
              background: background + '11',
            },
          },
        },
        '&:nth-of-type(odd)': {
          background: background,
          'backgroundColor': background,
          '& div.cell': {
            backgroundColor: '',
            background: ''
          },
        },
        '& div.cell': {
          backgroundColor: '',
          background: ''
        }
      };
    }

    return css(styles);
  };

  render() {
    const { row, template, rowDragged } = this.props;
    return (
      <Row
        onClick={this.onRowClick}
        onContextMenu={this.onContextMenu}
        draggable={true}
        onDragStart={this.dragStart}
        onDragEnd={() => {
          if (rowDragged) {
            rowDragged();
          }
        }}
        className={this.calculateBackgroundStyles(row.background)}
      >
        {template.map(t => {
          if (t.render) {
            return (
              <TableCell {...t} key={t.id + '_' + row.document_id} style={{ background: row.background ? '#0000' : row.isarchived === 1 ? 'rgba(200, 0, 0, 0.08)' : ''}}>
                {t.render(row)}
              </TableCell>
            );
          }
          if (t.renderCell) {
            return t.renderCell(row, TableCell);
          }
          return !t.hidden && this.getCellByType(t.type, row && row[t.id], t, row.isarchived, row.background);
        })}
      </Row>
    );
  }
}

const TableCell = ({
  bold,
  upperCase,
  color,
  children,
  background,
  style = {},
  className = '',
}) => (
  <Cell
    className={`cell ${className}`}
    style={{
      background: background ? background : '',
      fontWeight: bold ? 700 : '',
      textTransform: upperCase ? 'uppercase' : '',
      color: color ? color : '',
      ...style,
    }}
  >
    {children}
  </Cell>
);
