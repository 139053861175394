import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import Toolbar from './Grid.Toolbar.js';
import FloatingMenu from '../FloatingMenu';
import shortid from 'shortid';

import MenuIcon from 'react-icons/lib/md/more-vert';
import InsertBelowIcon from 'react-icons/lib/md/subdirectory-arrow-right';
import InsertAboveIcon from 'react-icons/lib/md/subdirectory-arrow-left';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from 'react-icons/lib/md/delete';

import copyToClipboard from 'clipboard-copy';
import StyleSelector from '../../pages/Workorder/StyleSelector';
import orderBy from 'lodash/orderBy';

let SheetClip = require('sheetclip');
SheetClip = new SheetClip();

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  fontFamily: theme.primary.fontFamily
}));

const TableWrapper = styled('table')({
  borderCollapse: 'collapse',
  width: '100%'
});

// TODO: Improve grid performance with large grids

export default class Grid extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      menuOpen: false,
      menuPosition: { x: 20, y: 20 },
      activeMenuIndex: null,
      redraw: false,
      selectedCell: null,
      endSelectedCell: null,
      blurringSelectedCell: false,
      dragging: false,
      lines: (props.lines ? props.lines : [])
    };

    if(props.editLockable) {
      this.state.editLock = true;
    } else {
      this.state.editLock = false;
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.state.redraw) {
      this.setState({ redraw: false });
      this.forceRedraw();
    }

    if(JSON.stringify(this.props.lines) !== JSON.stringify(prevProps.lines)) {
      this.setState({ lines: this.props.lines });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('copy', this.copyCells);
  };

  addRow = () => {
    const objectPlusNewLine = [...(this.state.lines || []), this.deriveRowFromTemplate()];
    this.updateLines(objectPlusNewLine);
  };

  autoSort = () => {
    const lines = orderBy([...(this.state.lines || [])], this.props.sort || ['printoption', 'quantity'], this.props.orderBy || ['asc', 'desc']);
    this.updateLines(lines);
  };

  toggleEditing = () => {
    this.setState({ editLock: !this.state.editLock });
  };

  checkForClickOutside = e => {
    if(!this.container.contains(e.target)){
      this.deselectCell();
    }
  };

  closeRowMenu = () => {
    this.setState({ menuOpen: false, activeMenuIndex: null });
  };

  commitChange = ({ rowIndex, cellIndex, value }) => {
    const lines = this.state.lines;
    const rows = [
      ...lines.slice(0, rowIndex),
      {
        ...lines[rowIndex],
        [this.props.template[cellIndex].id]: value
      },
      ...lines.slice(rowIndex + 1)
    ];
    this.updateLines(rows);
  };

  copyCells = (e) => {
    if(!e.key === 'c' || !e.ctrlKey) return;
    if(!this.getSelectedRange()) return;
    const { origin, target } = this.getSelectedRange();
    const lines = this.state.lines;
    const template = this.props.template;
    const rows = [];
    for (let i = origin.rowIndex; i <= target.rowIndex; i++) {
      const row = lines[i];
      const rowArray = [];
      for (let c = origin.cellIndex; c <= target.cellIndex; c++) {
        const cell = row[template[c].id] || '';
        rowArray.push(cell);
      }
      rows.push(rowArray);
    }
    const csv = SheetClip.stringify(rows);
    copyToClipboard(csv);
  };

  deleteCells = e => {
    if(e.key === 'Delete') {
      const { origin, target } = this.getSelectedRange();
      const lines = this.state.lines;
      const template = this.props.template;

      for (let rowIndex = origin.rowIndex; rowIndex <= target.rowIndex; rowIndex++) {
        for(let column = origin.cellIndex; column <= target.cellIndex; column++) {
          lines[rowIndex][template[column].id] = '';
        }
      }

      this.updateLines(lines);
    }
  };

  deleteRow = () => {
    const activeMenuIndex = this.state.activeMenuIndex;
    const lines = [...this.state.lines.slice(0, activeMenuIndex), ...this.state.lines.slice(activeMenuIndex + 1)];
    this.updateLines(lines);
  };

  deriveRowFromTemplate = () => {
    return {
      ...this.props.newRowTemplate.reduce((obj, cell) => ({
        ...obj,
        [cell.id]: cell.defaultValue || ''
      })),
      id: shortid.generate()
    };
  };

  deselectCell = () => {
    this.setState({ selectedCell: null, endSelectedCell: null });
    window.removeEventListener('copy', this.copyCells);
    window.removeEventListener('keyup', this.deleteCells);
    document.removeEventListener('mousedown', this.checkForClickOutside);
  };

  endDrag = () => {
    this.toggleDragging(false);
  };

  extendSelection = endSelectedCell => {
    this.setState({ endSelectedCell, blurringSelectedCell: true }, () => {
      document.activeElement.blur();
      this.setState({ blurringSelectedCell: false });
    });
    window.addEventListener('copy', this.copyCells);
    window.addEventListener('keyup', this.deleteCells);
    document.addEventListener('mousedown', this.checkForClickOutside);
  };

  forceRedraw = () => {
    this.table.style.display = 'none';
    const temp = this.table.offsetHeight; // eslint-disable-line
    this.table.style.display = '';
  };

  getLesserOf = (a, b) => {
    if (a < b) {
      return { lesser: a, greater: b };
    } else {
      return { lesser: b, greater: a };
    }
  };

  getSelectedRange = () => {
    const cellIndices = this.getLesserOf(
      this.state.selectedCell.cellIndex,
      this.state.endSelectedCell.cellIndex
    );
    const rowIndicies = this.getLesserOf(
      this.state.selectedCell.rowIndex,
      this.state.endSelectedCell.rowIndex
    );
    return {
      origin: { cellIndex: cellIndices.lesser, rowIndex: rowIndicies.lesser },
      target: { cellIndex: cellIndices.greater, rowIndex: rowIndicies.greater }
    };
  };

  insertAbove = () => {
    const activeMenuIndex = this.state.activeMenuIndex;
    this.setState({ redraw: true }, () => {
      this.updateLines([
        ...this.state.lines.slice(0, activeMenuIndex),
        this.deriveRowFromTemplate(),
        ...this.state.lines.slice(activeMenuIndex)
      ]);
    });
  };

  insertBelow = () => {
    const activeMenuIndex = this.state.activeMenuIndex;
    this.updateLines([
      ...this.state.lines.slice(0, activeMenuIndex + 1),
      this.deriveRowFromTemplate(),
      ...this.state.lines.slice(activeMenuIndex + 1)
    ]);
  };

  moveDown = () => {
    const activeMenuIndex = this.state.activeMenuIndex;
    const newLines = this.state.lines;
    [newLines[activeMenuIndex], newLines[activeMenuIndex + 1]] = [newLines[activeMenuIndex + 1], newLines[activeMenuIndex]];
    this.setState({ redraw: true }, () => {
      this.updateLines(newLines);
    });
  };

  moveUp = () => {
    const activeMenuIndex = this.state.activeMenuIndex;
    const newLines = this.state.lines;
    [newLines[activeMenuIndex], newLines[activeMenuIndex - 1]] = [newLines[activeMenuIndex - 1], newLines[activeMenuIndex]];
    this.setState({ redraw: true }, () => {
      this.updateLines(newLines);
    });
  };

  openRowMenu = ({ menuPosition, activeMenuIndex }) => {
    this.setState({ menuOpen: true, menuPosition, activeMenuIndex });
  };

  pasteCells = ({ rowIndex, cellIndex, value }) => {
    const paste = SheetClip.parse(value);
    let lines = [...this.state.lines];
    paste.forEach((row, i) => {
      const ri = rowIndex + i;
      if (lines[ri]) {
        row.forEach((cell, c) => {
          const ci = cellIndex + c;
          if (this.props.template[ci]) {
            lines = [
              ...lines.slice(0, ri),
              { ...lines[ri], [this.props.template[ci].id]: cell },
              ...lines.slice(ri + 1)
            ];
          }
        });
      }
    });
    this.updateLines(lines);
  };

  selectCell = selectedCell => {
    this.setState({ selectedCell, endSelectedCell: null });
  };

  toggleDragging = dragging => {
    this.setState({ dragging });
    if (dragging === true) {
      window.addEventListener('mouseup', this.endDrag);
    } else {
      window.removeEventListener('mouseup', this.endDrag);
    }
  };

  updateLines = (lines=this.state.lines, override=false) => {
    this.setState({ lines });
    if(this.props.editLockable && !override) {
      return;
    }
    this.props.updateLines(lines);
  };

  render() {
    const {
      template,
      activeColumns,
      setActiveColumns,
      hideableColumns,
      editLockable
    } = this.props;
    const {
      lines = [],
      editLock
    } = this.state;
    return (
      <Container ref={r=>{this.container = r;}}>
        <div
          id="__hidden-drag-image"
          className={css({
            opacity: 0,
            width: 1,
            height: 1,
            position: 'fixed',
            left: -1,
            top: -1
          })}
        />
        <Toolbar
          activeColumns={activeColumns}
          setActiveColumns={setActiveColumns}
          hideableColumns={hideableColumns}
          hideEditInventory={this.props.hideEditInventory}
          hideAutoSort={this.props.hideAutoSort}
          autoSort={this.autoSort}
          addRow={this.addRow}
          editLockable={editLockable}
          editLock={editLock}
          toggleEditing={this.toggleEditing}
          revertChanges={() => {
            this.setState({ lines: this.props.lines }); }}
          updateLines={() => {
            this.updateLines(this.state.lines, true);
          }}
        />
        <TableWrapper
          ref={r => {
            this.table = r;
          }}
        >
          <Headers
            template={template}
            ref={headers => {
              this.headers = headers;
            }}
          />
          <Rows
            disabled={editLockable && editLock}
            template={template}
            rows={lines}
            commitChange={this.commitChange}
            updateLines={this.updateLines}
            openRowMenu={this.openRowMenu}
            selectCell={this.selectCell}
            deselectCell={this.deselectCell}
            extendSelection={this.extendSelection}
            blurringSelectedCell={this.state.blurringSelectedCell}
            pasteCells={this.pasteCells}
            toggleDragging={this.toggleDragging}
            dragging={this.state.dragging}
            separatorLines={this.props.separatorLines}
            selectedRange={
              this.state.selectedCell && this.state.endSelectedCell
                ? this.getSelectedRange()
                : null
            }
          />
        </TableWrapper>
        <FloatingMenu
          isOpen={this.state.menuOpen}
          style={{
            left: this.state.menuPosition.x,
            top: this.state.menuPosition.y,
            position: 'fixed',
            zIndex: 9
          }}
          onModalClose={this.closeRowMenu}
          buttons={[
            (this.state.activeMenuIndex != 0) && {
              label: (
                <LabelWrapper
                  className={css({
                    '& svg': {
                      display: 'inline-block'
                    }
                  })}
                >
                  <ArrowUpwardIcon />
                  Move Up
                </LabelWrapper>
              ),
              onClick: this.moveUp
            },
            (lines && this.state.activeMenuIndex != lines.length - 1) && {
              label: (
                <LabelWrapper
                  className={css({
                    '& svg': {
                      transform: 'rotate(180deg)',
                      display: 'inline-block'
                    }
                  })}
                >
                  <ArrowDownwardIcon />
                  Move Down
                </LabelWrapper>
              ),
              onClick: this.moveDown
            },
            {
              label: (
                <LabelWrapper
                  className={css({
                    '& svg': {
                      transform: 'rotate(180deg)',
                      display: 'inline-block'
                    }
                  })}
                >
                  <InsertAboveIcon />
                  Insert Above
                </LabelWrapper>
              ),
              onClick: this.insertAbove
            },
            {
              label: (
                <LabelWrapper>
                  <InsertBelowIcon /> Insert Below
                </LabelWrapper>
              ),
              onClick: this.insertBelow
            },
            {
              label: (
                <LabelWrapper danger>
                  <span className={css({ paddingBottom: 2 })}>
                    <DeleteIcon />
                  </span>{' '}
                  Delete Row
                </LabelWrapper>
              ),
              onClick: this.deleteRow
            }
          ]}
        />
      </Container>
    );
  }
}

const LabelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}, ({ theme, danger }) => ({
  color: danger ? theme.danger.dark : ''
}));

const Header = styled('thead')({
  position: 'sticky',
  top: '0px',
  zIndex: 10
});

const HeadRow = styled('tr')({});

const HCell = styled('th')(({ theme }) => ({
  fontSize: 12,
  background: theme.primary.color,
  color: theme.primary.textOn,
  padding: '7px 5px',
  flexGrow: 1,
  textAlign: 'left',
  fontWeight: 600,
  '&:first-of-type': {
    borderTopLeftRadius: 4
  },
  '&:last-child': {
    borderTopRightRadius: 4
  }
}));

class Headers extends React.Component {
  render() {
    return (
      <Header>
        <HeadRow>
          {this.props.template.map(hc => (
            <HeaderCell
              ref={cell => {
                this[hc.id] = cell;
              }}
              setWidth={this.props.setWidth}
              {...hc}
              key={hc.id}
            />
          ))}
        </HeadRow>
      </Header>
    );
  }
}

class HeaderCell extends React.Component {
  static defaultProps = {
    background: '',
    maxWidth: ''
  };
  render() {
    const { maxWidth, background, id, label } = this.props;
    return (
      <HCell
        style={{
          width: maxWidth,
          background
        }}
        ref={cell => {
          this.cell = cell;
        }}
        key={id}
      >
        {label}
      </HCell>
    );
  }
}

const RowsWrapper = styled('tbody')({});

const RowWrapper = styled('tr')({
  // display: 'flex',
  // flexDirection: 'row'
  '&:hover .row-menu-button': {
    opacity: 1
  }
});

class Rows extends React.Component {
  moveFocus = (rowIndex, cellIndex, direction) => {
    if (direction === 'up') {
      this['row' + (rowIndex - 1)].focusCell(cellIndex);
    } else {
      this['row' + (rowIndex + 1)].focusCell(cellIndex);
    }
  }
  render() {
    const {
      widths,
      rows = [],
      template,
      selectedRange,
      blurringSelectedCell,
      dragging,
      separatorLines,
      disabled
    } = this.props;
    return (
      <RowsWrapper>
        {(rows || []).map((row, i) => {
          let lineAbove = null;
          if(separatorLines) {
            let compareIndex = i - 1;
            const design = row.printoption;
            if(i !== 0 && row.printoption !== undefined) {

              while(rows[compareIndex].printoption === undefined) {
                compareIndex--;
              }

              if(rows[compareIndex].printoption.substring(0, 2) !== design.substring(0, 2)) {
                lineAbove = css({
                  borderTop: '2px solid black'
                });
              } else if(rows[compareIndex].printoption !== design) {
                lineAbove = css({
                  borderTop: '2px dashed black'
                });
              }
            }
          }

          return (
            <Row
              disabled={disabled}
              lineAboveStyle={lineAbove}
              row={row}
              widths={widths}
              index={i}
              isLast={i === rows.length - 1}
              moveFocus={this.moveFocus}
              commitChange={this.props.commitChange}
              openRowMenu={this.props.openRowMenu}
              selectCell={this.props.selectCell}
              deselectCell={this.props.deselectCell}
              extendSelection={this.props.extendSelection}
              blurringSelectedCell={blurringSelectedCell}
              toggleDragging={this.props.toggleDragging}
              pasteCells={this.props.pasteCells}
              dragging={dragging}
              selectedRange={selectedRange}
              ref={row => {
                this['row' + i] = row;
              }}
              template={template}
              key={row.id}
            />
          );
        })}
      </RowsWrapper>
    );
  }
}

class Row extends React.Component {
  focusCell = cellIndex => {
    this['cell' + cellIndex].focus();
  };
  getRow = () => this.props.row;
  render() {
    const {
      disabled,
      row,
      index,
      isLast,
      moveFocus,
      commitChange,
      template,
      selectedRange,
      blurringSelectedCell,
      dragging,
      lineAboveStyle
    } = this.props;
    return (
      <RowWrapper
        className={lineAboveStyle}
      >
        {template.map((t, i) => (
          <Cell
            disabled={disabled}
            columnTemplate={t}
            value={row[t.id]}
            isFirst={i === 0}
            getRow={this.getRow}
            isLast={isLast}
            rowIndex={index}
            cellIndex={i}
            moveFocus={moveFocus}
            commitChange={commitChange}
            openRowMenu={this.props.openRowMenu}
            selectCell={this.props.selectCell}
            deselectCell={this.props.deselectCell}
            extendSelection={this.props.extendSelection}
            blurringSelectedCell={blurringSelectedCell}
            toggleDragging={this.props.toggleDragging}
            pasteCells={this.props.pasteCells}
            dragging={dragging}
            titleText={row.description}
            selected={
              selectedRange
                ? index >= selectedRange.origin.rowIndex &&
                  index <= selectedRange.target.rowIndex &&
                  i >= selectedRange.origin.cellIndex &&
                  i <= selectedRange.target.cellIndex
                : false
            }
            ref={cell => {
              this['cell' + i] = cell;
            }}
            key={t.id}
          />
        ))}
      </RowWrapper>
    );
  }
}

const RowMenuButton = styled('div')({
  position: 'absolute',
  left: -20,
  top: 0,
  background: 'none',
  border: 'none',
  height: '100%',
  width: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  opacity: 0,

  '& svg': {
    width: '100%',
    height: '100%'
  },

  cursor: 'default'
}, ({ theme }) => ({
  color: theme.neutral.color,
  '&:hover': {
    color: theme.neutral.light
  }
}));

const CellWrapper = styled('td')({
  fontSize: 13,

  // overflow: 'hidden',
  borderRight: '1px solid rgb(201, 201, 201)',

  borderBottom: '1px solid rgb(201, 201, 201)',
  willChange: 'width',
  position: 'relative',
  cursor: 'cell',
  padding: 0,

  '&:first-of-type': {
    borderLeft: '1px solid rgb(201, 201, 201)'
  }
}, ({ theme, selected }) => ({
  background: selected ? theme.vibrant.alpha3 : 'none'
}));

const InputWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row'
});

const Input = styled('input')(({ theme }) => ({
  flex: '1 0 auto',
  border: 'none',
  width: '100%',
  height: '100%',
  padding: 5,
  cursor: 'cell',
  fontFamily: theme.primary.fontFamily,
  fontSize: 12,
  background: 'none',
  '&:focus': {
    outline: `2px solid ${theme.vibrant.color}`,
    background: theme.blue.alpha1,
    cursor: 'text'
  }
}));

export class Cell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? props.value : '',
      dragDistance: { x: 0, y: 0 },
      skipCommit: false
    };
  }
  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  };
  focus = () => {
    this.props.selectCell({
      rowIndex: this.props.rowIndex,
      cellIndex: this.props.cellIndex
    });
    this.input.focus();
  };
  setValue = e => {
    const value = this.getMaskedValue(e.target.value);
    this.setState({ value });
  };
  getMaskedValue = value => {
    switch (this.props.columnTemplate.mask) {
    case 'numbers':
      return value.replace(/[^0-9]/g, '');
    default:
      return value;
    }
  };
  checkEnter = e => {
    if (e.charCode === 13) {
      const direction = e.getModifierState('Shift') ? 'up' : 'down';
      if (direction === 'up') {
        if (this.props.rowIndex !== 0) {
          this.props.moveFocus(
            this.props.rowIndex,
            this.props.cellIndex,
            direction
          );
        } else {
          this.input.blur();
        }
      } else {
        if (!this.props.isLast) {
          this.props.moveFocus(this.props.rowIndex, this.props.cellIndex);
        } else {
          this.input.blur();
        }
      }
    }
  }
  commitChange = value => {
    if (!this.state.skipCommit) {
      const computedValue = value !== undefined ? value :  this.state.value;
      const previousValue = this.props.value || this.props.value === false ? this.props.value : '';
      const valueHasChanged = previousValue !== computedValue;

      if (!this.props.blurringSelectedCell) {
        this.props.deselectCell();
      }
      if (valueHasChanged) {
        this.props.commitChange({
          rowIndex: this.props.rowIndex,
          cellIndex: this.props.cellIndex,
          value: computedValue,
          columnId: this.props.columnTemplate.id
        });
      }
    } else {
      this.props.deselectCell();
    }
  };
  select = () => {
    this.props.selectCell({
      rowIndex: this.props.rowIndex,
      cellIndex: this.props.cellIndex
    });
    this.input.select();
  };
  openRowMenu = () => {
    const { x, y } = this.menuButton.getBoundingClientRect();

    if(y + 250 > window.innerHeight) {
      this.props.openRowMenu({
        menuPosition: { x, y: window.innerHeight - 250 },
        activeMenuIndex: this.props.rowIndex
      });
    } else {
      this.props.openRowMenu({
        menuPosition: { x, y },
        activeMenuIndex: this.props.rowIndex
      });
    }
  };
  checkForSelection = e => {
    if (e.shiftKey) {
      e.nativeEvent.stopImmediatePropagation();
      this.props.extendSelection({
        rowIndex: this.props.rowIndex,
        cellIndex: this.props.cellIndex
      });
      e.preventDefault();
    }
  };
  startDrag = e => {
    e.preventDefault();
    document.activeElement.blur();
    this.props.selectCell({
      rowIndex: this.props.rowIndex,
      cellIndex: this.props.cellIndex
    });
    this.props.toggleDragging(true);
    e.dataTransfer.setDragImage(
      document.getElementById('__hidden-drag-image'),
      0,
      0
    );
  };
  extendSelection = () => {
    this.props.extendSelection({
      rowIndex: this.props.rowIndex,
      cellIndex: this.props.cellIndex
    });
  };
  handlePaste = e => {
    const value = e.clipboardData.getData('text/plain');
    if (value.includes('\t') || value.includes('\n')) {
      this.setState(
        {
          skipCommit: true
        },
        () => {
          this.input.blur();
          this.setState({
            skipCommit: false
          });
        }
      );
      this.props.pasteCells({
        cellIndex: this.props.cellIndex,
        rowIndex: this.props.rowIndex,
        value
      });
    }
  };
  getByType = () => {
    switch (this.props.columnTemplate.type) {
    case 'stylenumber':
      return (
        <StyleSelector
          defaultInputValue={this.state.value}
          onChange={value => {this.commitChange(value);}}
        >
          {
            ({getInputProps}) => (
              <Input
                disabled={this.props.disabled}
                title={`${this.props.titleText}`}
                {...getInputProps({
                  // value,
                  onPaste: this.handlePaste,
                  // onChange: this.setValue,
                  onBlur: e =>
                  {
                    e.preventDefault();
                    this.commitChange(e.target.value);
                  },
                  onFocus: this.select,
                  onMouseDown: this.checkForSelection,
                  onKeyPress: this.checkEnter,
                  onDragStart: e => {
                    e.preventDefault();
                    document.activeElement.blur();
                  },
                  draggable: false,
                  ref: input => {
                    this.input = input;
                  }
                })}
              />
            )
          }
        </StyleSelector>
      );
    case 'checkbox':
      return (
        <GridCheckbox
          value={this.state.value}
          disabled={this.props.disabled}
          onChange={p => {
            this.setValue({target:{value:p}});
            this.commitChange(p);
          }}
          ref={input => {
            this.input = input;
          }}
        />
      );
    default:
      return (
        <Input
          value={this.state.value || ''}
          onPaste={this.handlePaste}
          onChange={this.setValue}
          onBlur={()=>{this.commitChange();}}
          onFocus={this.select}
          onMouseDown={this.checkForSelection}
          onKeyPress={this.checkEnter}
          disabled={(this.props.columnTemplate.getIsDisabled ? this.props.columnTemplate.getIsDisabled(this.props.getRow()) : false) || this.props.disabled}
          onDragStart={e => {
            e.preventDefault();
            document.activeElement.blur();
          }}
          draggable={false}
          ref={input => {
            this.input = input;
          }}
        />
      );
    }
  }
  render() {
    const { isFirst, selected, dragging } = this.props;
    return (
      <CellWrapper
        selected={selected}
        draggable
        onDragStart={this.startDrag}
        onMouseEnter={dragging ? this.extendSelection : undefined}
      >
        {isFirst && (
          <RowMenuButton
            className="row-menu-button"
            onClick={() => {
              if(this.props.disabled) return;
              this.openRowMenu();
            }}
            ref={r => {
              this.menuButton = r;
            }}
          >
            <MenuIcon />
          </RowMenuButton>
        )}
        <InputWrapper>
          {this.getByType()}
        </InputWrapper>
      </CellWrapper>
    );
  }
}

class GridCheckbox extends React.Component {
  id = shortid.generate();
  render() {
    const { value, onChange, disabled } = this.props;
    return (
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        })}
      >
        <input
          disabled={disabled}
          type="checkbox"
          id={this.id}
          name={this.id}
          checked={value}
          value={value}
          onChange={e => onChange(e.target.checked)}
          className={css({
            opacity: .01,
            width: .1,
            height: .1,
            position: 'fixed',
            left: -1,
            top: -1,
            visible: 'hidden'
          })}
        />
        <StyledBox
          checked={value}
          htmlFor={this.id}
        />
      </div>
    );
  }
}

const StyledBox = styled('label')({
  position: 'relative',
  border: '2px solid rgb(189, 189, 189)',
  borderRadius: 3,
  width: 17,
  height: 17
}, ({theme, checked}) => (
  checked ? {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 2,
      top: 2,
      width: 9,
      height: 9,
      borderRadius: 1,
      background: theme.vibrant.color
    }
  } : null
));
