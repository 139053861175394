import React from 'react';
import styled from '@emotion/styled';

const Icon = styled('svg')({
  width: '100%'
});

export const CustomerIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0"
    y="0"
    viewBox="0 0 73.05 73.05"
    enableBackground="new 0 0 73.053 73.053"
    style={{ fill: color }}
  >
    <path d="M36.55,7A29.55,29.55,0,1,1,7,36.55,29.59,29.59,0,0,1,36.55,7m0-7A36.55,36.55,0,1,0,73.1,36.55,36.54,36.54,0,0,0,36.55,0Z"/>
    <ellipse cx="36.55" cy="25.06" rx="9.16" ry="10.3"/>
    <path d="M36.55,37.4c-9.17,0-16.69,0-17.35,15.13a2.17,2.17,0,0,0,2.18,2.27H51.72a2.17,2.17,0,0,0,2.18-2.27C53.24,37.4,45.72,37.4,36.55,37.4Z"/>
  </Icon>
);
