import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import styled from '@emotion/styled';
import FilterBar from '@components/Collections/FilterBar';
import { Wrapper } from '@components/shell/Shell';
import theme from '@/theme';
import { format } from 'date-fns';
import Button from '@mui/material/Button';
import InfiniteScroll from 'react-infinite-scroller';
import { axios } from '@/api.js';
import WorkcenterIndicator from '@components/Reusable/display/WorkcenterIndicator.js';
import IconButton from '@mui/material/IconButton';
import PaidIcon from '@mui/icons-material/Paid';

export default class EmployeeOrders extends React.Component {
  state = {
    documentCount: 0,
    documents: [],
    filter: '',
    getting: false,
    hasMoreToFetch: true,
    filterByPaid: false
  };

  componentDidMount(){
    const { setTitle = () => {}, pageTitle } = this.props;
    setTitle(pageTitle);
    this.loadPage();
    this.getDocumentCount();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(this.state.documentCount);
    console.log(this.state.documents.length);
    if(prevState.documentCount !== this.state.documentCount) {
      this.getDocuments(true);
    }
  }

  loadPage = () => {
    this.getDocuments(true);
    this.getDocumentCount();
  }

  getDocumentCount = () => {
    console.log('arseitonioarstn');
    axios.get('/document/bulk/count', {
      params: {
        document_type: 'workorder',
        ordertype: 'Employee',
        jobName: (this.state.filter !== '' ? this.state.filter : undefined),
        includedDetails: this.state.filterByPaid ? ['paid'] : [],
      }
    })
      .then((res) => {
        this.setState({ documentCount: res.data.result });
      });
  };

  getDocuments = (refresh = false) => {
    if(this.state.getting === true) return;
    if(this.state.documentCount <= this.state.documents?.length) return;
    this.setState({getting: true});
    axios.get('/document/bulk', {
      params: {
        document_type: 'workorder',
        limit: 30,
        offset: this.state.documents.length,
        ordertype: 'Employee',
        jobName: (this.state.filter !== '' ? this.state.filter : undefined),
        attributes: [
          'duedate',
          'jobname',
          'customer',
          'total',
          'document_detail',
          'workcenter'
        ],
        includedDetails: this.state.filterByPaid ? ['paid'] : [],
        orderby: JSON.stringify([['updated', 'DESC']])
      }
    })
      .then((res) => {
        const documents = refresh ? [] : this.state.documents;
        documents.push(...res.data.results);
        this.setState({ documents, getting: false });
      });
  };

  getFilteredRows = () => {
    if (!this.state.filter) {
      return this.state.documents;
    } else {
      const filter = this.state.filter.toLowerCase();
      return this.state.documents.filter(
        d =>
          (d.jobname || '').toLowerCase().includes(filter) ||
          (d.customer.firstname || '').toLowerCase().includes(filter) ||
          (d.customer.lastname  || '').toLowerCase().includes(filter)
      );
    }
  };

  render() {

    const {
      documents,
      documentCount,
      filter
    } = this.state;

    return (
      <Wrapper
        className={css({
          paddingTop: 60,
        })}
      >
        <FilterBar
          title="Employee Orders"
          color={theme.primary.light}
          custom={
            <IconButton
              sx={{ color: this.state.filterByPaid ? 'white' : 'neutral.light'}}
              onClick={() => { 
                this.setState({ 
                  filterByPaid: !this.state.filterByPaid,
                  documents: [],
                  documentCount: 1
                }, () => {
                  this.loadPage();
                }); 
              }}
            >
              <PaidIcon />
            </IconButton>
          }
          value={filter}
          unsortable={true}
          onChange={(value) => {
            this.setState({ documents: [], filter: value }, () => {
              this.loadPage();
            });
          }}
          onClear={() => {
            this.setState({ documents: [], filter: '' }, () => {
              this.loadPage();
            });
          }}
        />
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            this.getDocuments();
          }}
          hasMore={
            documents.length < documentCount && !this.state.getting
          }
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
          className={css({
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          })}
        >
          <FlexWrapper>
            {this.state.documents.map(doc => {
              const paidAttribute = doc.document_detail.find(dd => dd.attributename === 'paid') || {};
              return (
                <ConnectedRow
                  document_id={doc.document_id}
                  duedate={doc.duedate}
                  jobname={doc.jobname}
                  customer={doc.customer}
                  paid={
                    paidAttribute ? (paidAttribute.value !== null && paidAttribute.value !== '' ? true : false) : false
                  }
                  pending={paidAttribute.value === 'Pending'}
                  workcenter={doc.workcenterData}
                  paidDetail={paidAttribute}
                  total={doc.total}
                  key={doc.document_id}
                />
              );
            })}
          </FlexWrapper>
        </InfiniteScroll>
      </Wrapper>
    );
  }
}

class ConnectedRow extends React.Component {
  state = {
    paid: this.props.paid,
    pending: this.props.pending
  };

  setPending = () => {
    const value = 'Pending';
    this.setState({ pending: true });
    axios.put('/document/detail', {
      document_id: this.props.document_id,
      attributename: 'paid',
      value
    });
  };

  togglePaid = paid => {
    const value = paid ? format(Date.now(), 'M/D/YYYY') : '';
    this.setState({ paid: paid, pending: false });
    axios.put('/document/detail', {
      document_id: this.props.document_id,
      attributename: 'paid',
      value
    });
  };

  render() {
    return (
      <FlexRow
        {...this.props}
        pending={this.state.pending}
        actuallyPaid={this.state.paid}
        togglePaid={this.togglePaid}
        canPay={this.props.paidDetail.document_detail_id ? true : false}
        setPending={this.setPending}
      />
    );
  }
}

const FlexRow = ({
  jobname,
  customer,
  duedate,
  total,
  document_id,
  togglePaid,
  setPending,
  canPay,
  actuallyPaid,
  workcenter,
  pending
}) => (
  <FlexRowWrapper success={actuallyPaid && !pending} danger={!actuallyPaid && !pending} pending={pending}>
    <FlexCell
      style={{
        flexBasis: '5%'
      }}
    >
      <WorkcenterIndicator
        workcenter={workcenter}
        style={{ borderRadius: '3px' }}
      />
    </FlexCell>
    <FlexCell
      style={{
        flexBasis: '10%'
      }}
    >
      {duedate ? format(duedate, 'MMM D, YYYY') : '-'}
    </FlexCell>
    <FlexCell
      style={{
        flexBasis: 'auto',
        flexGrow: 2,
        padding: 0,
      }}
    >
      <Link
        className={css({
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          textDecoration: 'none',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#444444',
          padding: 10,
          '&:hover': {
            background: 'rgba(255,255,255,.3)',
          },
        })}
        target="_blank"
        to={`/workorder/edit/${document_id}`}
      >
        <Jobname>{jobname}</Jobname>
        <Customer>{`${customer.firstname} ${customer.lastname}`}</Customer>
      </Link>
    </FlexCell>
    <FlexCell
      style={{
        flexBasis: '10%'
      }}
    >
      {`$${total}`}
    </FlexCell>
    <FlexCell>
      {!actuallyPaid && !pending &&
          <Button
            variant="contained"
            fullWidth
            size="small"
            sx={{ 
              mb: '4px',
              backgroundColor: 'warning.dark',
              '&:hover': { backgroundColor: 'warning.main' }
            }}
            onClick={setPending}
          >Mark Pending</Button>
      }
      <Button
        sx={{
          backgroundColor: actuallyPaid && !pending ? 'success.main' : 'danger.main',
          '&:hover': { backgroundColor: actuallyPaid ? 'success.light' : 'danger.light' }
        }}
        variant="contained"
        disabled={!canPay}
        fullWidth
        size="small"
        onClick={() => {
          actuallyPaid = !actuallyPaid;
          togglePaid(actuallyPaid);
        }}
      >
        {actuallyPaid && !pending ? 'Unmark as Paid' : 'Mark as Paid'}
      </Button>
    </FlexCell>
  </FlexRowWrapper>
);

const FlexWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const FlexRowWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
    borderRadius: 5,
    overflow: 'hidden',
  },
  ({ success, theme }) =>
    success
      ? {
        background: theme.success.colorByAlpha(0.3),
      }
      : {},
  ({ danger, theme }) =>
    danger
      ? {
        background: theme.danger.colorByAlpha(0.3),
      }
      : {},
  ({ pending }) => 
    pending ? {
      background: '#ECCE83'
    } : {}
);

const FlexCell = styled('div')({
  flex: '0 0 25%',
  width: '25%',
  display: 'flex',
  flexDirection: 'column',
  padding: 10,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  '&:nth-of-type(odd)': {
    background: 'rgba(255,255,255,.3)',
  },
});

const Customer = styled('span')(
  {
    fontSize: 13,
    fontStyle: 'italic',
    fontWeight: 600,
  },
  ({ theme }) => ({
    color: theme.neutral.dark,
  })
);

const Jobname = styled('span')({
  fontWeight: 700,
});
