import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { TextInput } from '../../components/Controls';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import CustomerSlider from '../Workorder/CustomerSlider';
import CustomerList from '../../components/Reusable/data/CustomerList';
import DeleteIcon from '@mui/icons-material/Delete';

export default class SearchBox extends React.Component {
  state = {
    sliderOpen: false,
    selectingCustomer: false,
    customerListAnchor: undefined,
    customer: undefined
  };

  onChange = payload => {
    this.props.onChange({ ...this.props.values, ...payload });
  };

  openNewCustomerSlider = () => {
    this.setState({ sliderOpen: true });
  };

  closeSlider = () => {
    this.setState({ sliderOpen: false });
  };

  selectCustomer = customer => {
    this.onChange({ customer_id: customer.customer_id });
  };

  checkEnter = code => {
    if (code === 13) {
      typeof this.props.onClickSearch === 'function'
        ? this.props.onClickSearch()
        : [];
    }
  };

  render() {
    const { values } = this.props;
    return (
      <Wrapper>
        <div
          className={css({ display: 'flex', marginBottom: 15, width: '100%' })}
        >
          <TextInput
            label="Order ID"
            value={values.document_id}
            onChange={e => this.onChange({ document_id: e.target.value })}
            onKeyPress={e => this.checkEnter(e.charCode)}
            width="100%"
            css={{ margin: 0, flex: '1 1 24%' }}
          />
          <TextInput
            label="Job Name"
            value={values.jobname}
            onChange={e => this.onChange({ jobname: e.target.value })}
            onKeyPress={e => this.checkEnter(e.charCode)}
            width="100%"
            css={{ margin: 0, flex: '1 1 38%', marginLeft: 15 }}
          />
          <Stack
            direction="row"
            alignItems="start"
            sx={{ ml: 2, mt: 1 }}
          >
            <Button
              sx={{ height: 30}}
              size="small"
              variant="contained"
              onClick={(e) => { 
                this.setState({ 
                  selectingCustomer: true, 
                  customerListAnchor: e.currentTarget 
                }); 
              }}
            >Select {!this.state.customer && 'Customer'}</Button>
            <IconButton
              sx={{ height: 30 }}
              onClick={() => { 
                this.setState({ customer: undefined });
                this.selectCustomer({ customer_id: 0 }); 
              }}
            ><DeleteIcon /></IconButton>
            {this.state.customer && 
              <Stack
                sx={{
                  border: 'gray 1px solid',
                  borderRadius: '3px',
                  p: 1
                }}
              >
                <Typography
                  variant="body2"
                >{this.state.customer.firstname} {this.state.customer.lastname}</Typography>
                <Typography
                  variant="caption"
                >{this.state.customer.organization}</Typography>
              </Stack>
            }
          </Stack>
          <CustomerList 
            anchorEl={this.state.customerListAnchor}
            onSelect={(customer) => {
              this.setState({ customer, selectingCustomer: false });
              this.selectCustomer(customer);
            }}
            onClose={() => { this.setState({ selectingCustomer: false }); }}
            open={this.state.selectingCustomer}
          />
          <CustomerSlider
            isOpen={this.state.sliderOpen}
            onModalClose={this.closeSlider}
            onSubmit={this.createCustomer}
          />
        </div>
        <div className={css({ display: 'flex', width: '100%', marginBottom: '14px' })}>
          <TextInput
            label="Payment Lower Bound"
            onChange={e => this.onChange({ paymentLower: e.target.value })}
            value={values.paymentLower}
            css={{ margin: 0, flex: '1 1 50%' }}
          />
          <TextInput
            label="Payment Upper Bound"
            value={values.paymentUpper}
            onChange={e => this.onChange({ paymentUpper: e.target.value })}
            css={{ margin: 0, flex: '1 1 50%' }}
          />
        </div>
        <div className={css({ display: 'flex', width: '100%' })}>
          <TextInput
            label="Office Notes"
            value={values.officenotes}
            onChange={e => this.onChange({ officenotes: e.target.value })}
            width="100%"
            css={{ margin: 0, flex: '1 1 40%' }}
          />
          <TextInput
            label="Production Notes"
            value={values.specialinstructions}
            onChange={e =>
              this.onChange({ specialinstructions: e.target.value })
            }
            width="100%"
            css={{
              margin: 0,
              flex: '1 1 40%',
              marginLeft: 15,
              marginRight: 15,
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              typeof this.props.onClickSearch === 'function'
                ? this.props.onClickSearch()
                : [];
            }}
          >
            Search
          </Button>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled('div')(
  {
    width: '100%',
    maxWidth: 700,
    borderRadius: 4,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  ({ theme }) => ({
    border: `2px solid ${theme.neutral.extraLight}`,
  })
);

const InnerButton = styled('div')({
  fontSize: 16,
  fontWeight: 500
});

const buttonStyleOverride = {
  height: 50,
  flex: '1 1 auto'
};

const buttonWrapperStyleOverride = {
  margin: 0,
  marginLeft: 15,
  '&:last-child': {
    marginRight: 0
  },
  '& .__selected-customer': {
    padding: 0,
    paddingTop: 3,
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: 0
  }
};
