import React, { PropsWithChildren } from 'react';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

interface RoundSelectProps {
  label: string,
  value: any,
  nullValue?: any,
  items: { value: any, text: string }[],
  onSelect: (value: { value: any, text: string}) => undefined
}

export default function RoundSelect(props:PropsWithChildren<RoundSelectProps>) {
  return(
    <FormControl>
      {props.value === props.nullValue && <InputLabel 
        id={'round-select-label' + props.label}
        sx={{
          top: '-10px'
        }}
        shrink={false}
      >{props.label}</InputLabel>}
      <Select 
        labelId={'round-select-label' + props.label}
        sx={{
          width: '200px',
          backgroundColor: 'rgba(255, 255, 255, .6)',
          borderRadius: 1,
          paddingLeft: 1,
          fontWeight: 600,
        }}
        placeholder="Filter By Folder"
        input={
          <InputBase 
            sx={{
              borderRadius: 5,
              height: 28,
              border: 'none',
              backgroundColor: 'rgba(255, 255, 255, .6)',
            }}
          />
        }
      >
        {props.items.map((item, i) => {
          return (
            <MenuItem 
              key={i} 
              value={item.value}
              dense
              onClick={() => {props.onSelect(item);}}
            >{item.text}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

