import React, { useState, useEffect } from 'react';
import { axios } from '../../../api.js';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import EditIcon from '@mui/icons-material/Edit';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import EmailIcon from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import CustomerList from '../../Reusable/data/CustomerList';
import Customer, { defaultCustomer } from '../../../types/models/customer';
import QBCustomer from '../../../types/quickbooks/customer';
import CustomerModal from '../../../pages/Customers/CustomerModal.tsx';
import { Stack } from '@mui/system';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';

interface CustomerAccountBarProps {
  document_id?: number,
  customer_id?: number,
  disable_account?: boolean,
  account_id?: number,
  setCustomer?: (customer_id:number) => void,
  width?: string,
  customQuickbooksBillTo?: string
}

export default function CustomerAccountBar(props: CustomerAccountBarProps) {
  const [customerListOpen, setCustomerListOpen] = useState<boolean>(false);
  const [customerListAnchor, setCustomerListAnchor] = useState<PopoverProps['anchorEl']>(null);
  const [customer, setCustomer] = useState<Customer>(defaultCustomer);
  const [editingCustomer, setEditingCustomer] = useState<boolean>(false);
  const [account, setAccount] = useState<QBCustomer>();
  const [changingAccount, setChangingAccount] = useState<boolean>(false);
  const [changingAccountAnchor, setChangingAccountAnchor] = useState<PopoverProps['anchorEl']>(null);
  const [customBillTo, setCustomBillTo] = useState<string>(props.customQuickbooksBillTo || '');

  useEffect(() => {
    setCustomerListOpen(false);
    if(!props.customer_id || Number(props.customer_id) === 0) return;
    getCustomer();
  }, [props.customer_id]);

  useEffect(() => {
    if(editingCustomer === false) {
      getCustomer();
    }
  }, [editingCustomer]);

  useEffect(() => {
    setCustomBillTo(props.customQuickbooksBillTo || '');
  }, [props.customQuickbooksBillTo]);

  useEffect(() => {
    if(props.account_id == 0) return;
    getAccount();
  }, [props.account_id]);

  function accountHasAddress(): boolean {
    return !!account && !!account.BillAddr?.City && !!account.BillAddr.Line1 && !!account.BillAddr.PostalCode && !!account.BillAddr.CountrySubDivisionCode;
  }

  function getAccount() : void {
    if(!props.account_id) return;
    axios.get('/customer/quickbooks', {
      params: {
        Id: props.account_id
      }
    })
      .then((res) => {
        setAccount(res.data.result);
      });
  }

  function getCustomer() : void{
    if(!props.customer_id) {
      return;
    }

    axios.get('/customer', {
      params: {
        id: props.customer_id
      }
    })
      .then((res) => {
        setCustomer(res.data.result ?? defaultCustomer);
      });
  }

  function setDocumentAccount(account_id: number, taxexempt: boolean = false) :void {
    axios.put('/document/singleField', {
      id: props.document_id,
      field: 'quickbooksAccount',
      fieldValue: account_id
    });

    axios.put('/document/singleField', {
      id: props.document_id,
      field: 'taxexempt',
      fieldValue: taxexempt
    });
  }

  function setDocumentCustomer(customer_id: number) : void {
    axios.put('/document/singleField', {
      id: props.document_id,
      field: 'customer_id',
      fieldValue: customer_id
    });
  }

  function setDocumentOrdertype(ordertype: string = 'Standard') : void {
    axios.put('/document/singleField', {
      id: props.document_id,
      field: 'ordertype',
      fieldValue: ordertype,
      eventType: 'updateSidebar'
    });
  }

  function setCustomQuickbooksBillTo(): void {
    axios.put('/document/singleField', {
      id: props.document_id,
      field: 'customQuickbooksBillTo',
      fieldValue: customBillTo
    });
  }

  return props.customer_id != 0 ? ( 
    <Card
      raised
      sx={{ 
        width: props.width || '96%',
      }}
    >
      <Grid container>
        <Grid 
          item 
          xs={6}
        >
          <CardContent
            sx={{ paddingBottom: 0 }}
          >
            <Typography 
              color="rgba(0,0,0,.5)"
              sx={{ fontSize: '12px' }}
            >Customer</Typography>
            <Typography
              sx={{ 
                fontWeight: 700,
                fontSize: '16px'
              }}
            >{(customer.firstname + ' ' + customer.lastname)}</Typography>
            {customer.isorganization && <Typography
              sx={{
                overflowWrap: 'break-word'
              }}
            >{customer.organization}</Typography>}
            {customer.email && <Typography
              sx={{
                fontSize: '14px',
                overflowWrap: 'break-word'
              }}
            >{customer.email}</Typography>}
            {customer.primaryphone && <Typography
            >{customer.primaryphone}</Typography>}
            { (props.disable_account || !accountHasAddress()) &&
              <>
                <Typography
                  sx={{
                    fontSize: '14px',
                    overflowWrap: 'break-word'
                  }}
                >{customer.address}</Typography>
                <Typography
                  sx={{
                    fontSize: '14px'
                  }}
                >{customer.city + ', ' + customer.state + ' ' + customer.zipcode}</Typography>
              </>
            }
          </CardContent>
          <CardActions
            sx={{ 
              paddingTop: '4px',
              width: '50%'
            }}
          >
            <Tooltip
              title="Remove Customer"
            >
              <IconButton 
                color="error"
                onClick={() => { 
                  if(props.setCustomer) {
                    props.setCustomer(0);
                  } else {
                    setDocumentCustomer(0); 
                    setDocumentAccount(0, false);
                    setAccount(undefined);
                  }
                }}
              >
                <PersonRemoveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Edit Customer"
            >
              <IconButton
                onClick={() => { setEditingCustomer(true); }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Email Customer"
            >
              <IconButton
                onClick={() => {
                  window.open(
                    `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${customer.email}&tf=1&su=| Badger Screen Printing`,
                    '_blank'
                  );
                }}
              >
                <EmailIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Search Customer Emails"
            >
              <IconButton
                onClick={() => {
                  window.open(
                    `https://mail.google.com/mail/u/0/#search/${customer.email}`,
                    '_blank'
                  );
                }}
              >
                <ContactMailIcon />
              </IconButton>
            </Tooltip>
          </CardActions>
        </Grid>
        { !props.disable_account &&
          <>
            <Divider 
              orientation="vertical"
              sx={{ borderColor: 'rgba(0,0,0,.3)'}} 
              flexItem
              variant="middle"
            ></Divider>
            <Grid 
              item 
              xs={5}
            >
              { !account?.Id ? 
                <FormControl 
                  fullWidth
                  sx={{ pl: 1 }}
                >
                  <InputLabel
                    size="small"
                  >Account</InputLabel>
                  <Select
                    size="small"
                    fullWidth
                    variant="standard"
                  >
                    <MenuItem
                      disabled
                    >
                      <em>Select An Account</em>
                    </MenuItem>
                    {customer.quickbooksAccounts?.map((account: QBCustomer) => {
                      return (
                        <MenuItem
                          key={account.Id}
                          onClick={() => { setDocumentAccount(Number(account.Id), !account.Taxable); }}
                        >{account.DisplayName}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> 
                : <Stack
                  direction="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <CardContent
                    sx={{
                      paddingBottom: '0px'
                    }}
                  >
                    <Typography 
                      color="rgba(0,0,0,.5)"
                      sx={{ fontSize: '12px' }}
                    >Account</Typography>
                    <Typography
                      sx={{ 
                        fontWeight: 700,
                        fontSize: '16px'
                      }}
                    >{account.DisplayName}</Typography>
                    {accountHasAddress() ?
                      <>
                        <Typography
                          sx={{
                            fontSize: '14px'
                          }}
                        >{account.BillAddr?.Line1}</Typography>
                        <Typography
                          sx={{
                            fontSize: '14px'
                          }}
                        >{account.BillAddr?.City + ', ' + account.BillAddr?.CountrySubDivisionCode + ' ' + account.BillAddr?.PostalCode}</Typography>
                      </>
                      : <TextField
                        multiline
                        maxRows={4}
                        value={customBillTo}
                        label="Custom Bill To"
                        size="small"
                        onChange={(e) => { setCustomBillTo(e.target.value); }}
                        onBlur={() => { setCustomQuickbooksBillTo(); }}
                      ></TextField>
                    }
                    <Typography
                      sx={{ fontWeight: 700, fontSize: '14px'}}
                    >{account.Taxable ? 'NOT TAXEXEMPT' : 'TAXEXEMPT'}</Typography>
                  </CardContent>
                  <CardActions
                    sx={{ paddingTop: '4px' }}
                  >
                    <Tooltip
                      title="Change Account"
                    >
                      <IconButton 
                        color="error"
                        onClick={(e) => { 
                          setChangingAccount(true); 
                          setChangingAccountAnchor(e.currentTarget);
                        }}
                      >
                        <ChangeCircleIcon />
                      </IconButton>
                    </Tooltip>
                    <Popover
                      open={changingAccount}
                      anchorEl={changingAccountAnchor}
                      anchorOrigin={{
                        vertical:'bottom',
                        horizontal: 'left'
                      }}
                      onClose={() => { setChangingAccount(false); }}
                    >
                      <MenuItem
                        disabled
                      >
                        <em>Select An Account</em>
                      </MenuItem>
                      {customer.quickbooksAccounts?.map((account: QBCustomer) => {
                        return (
                          <MenuItem
                            key={account.Id}
                            onClick={() => { 
                              setDocumentAccount(Number(account.Id), !account.Taxable); 
                              setChangingAccount(false);
                            }}
                          >{account.DisplayName}</MenuItem>
                        );
                      })}
                    </Popover>
                  </CardActions>
                </Stack>
              }
            </Grid>
          </>
        }
      </Grid>
      <CustomerModal 
        viewingCustomer={editingCustomer ? customer.customer_id || 0 : 0}
        onClose={() => { 
          setEditingCustomer(false); 
          getCustomer();
        }}
      />
    </Card>
  ) : (
    <>
      <Button
        variant="outlined"
        sx={{ width: '96%', height: '96%' }}
        onClick={(e) => { 
          setCustomerListAnchor(e.currentTarget); 
          setCustomerListOpen(true);
        }}
      >
      Add Customer
      </Button>
      <CustomerList
        open={customerListOpen}
        onClose={() => { setCustomerListOpen(false); }}
          //@ts-ignore
        anchorEl={customerListAnchor}
        onSelect={(customer: Customer) => { 
          if(props.setCustomer) {
            return props.setCustomer(customer.customer_id || 0);
          }
          setDocumentCustomer(customer.customer_id || 0); 
          if(customer.firstname === 'Badger' && customer.lastname === 'Screen Printing') {
            setDocumentOrdertype('Employee');
          }
        }}
      ></CustomerList>
    </>
  );
}
