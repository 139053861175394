import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import { Modal, Stack, Typography, Box, Grid, TextField, Select, FormControl, InputLabel, MenuItem, Checkbox, FormControlLabel, Button } from '@mui/material';
import { InventoryItem as InventoryItemType, defaultItem } from '../../types/models/inventoryItem';
import { axios } from '../../api';
import { pricingtypeOptions } from '../../data/options';
import Item from '../../types/quickbooks/item';
import PopoverSelect from '../../components/Reusable/controls/PopoverSelect';
import { Loading } from '../../components/Controls';

interface InventoryItemModalProps {
  viewingItem: number,
  onClose: () => void
}

export default function InventoryItemModal(props:InventoryItemModalProps) {
  const [item, setItem] = useState<InventoryItemType>(defaultItem);
  const [manufacturers, setManufacturers] = useState<string[]>([]);
  const [suppliers, setSuppliers] = useState<string[]>([]);
  const [quickbooksItems, setQuickbooksItems] = useState<Item[]>([]);
  const [anchorEl, setAnchorEl] = useState<Element>();
  const [settingQBItem, setSettingQBItem] = useState(false);
  const [loadingQBItems, setLoadingQBItems] = useState(true);

  useEffect(() => {
    getQuickbooksItems();
  }, []);

  useEffect(() => {
    getManufacturers();
    getSuppliers();
    setItem(defaultItem);
    if(props.viewingItem <= 0) return;
    getItem();
  }, [props.viewingItem]);

  function createItem() {
    axios.post('/inventory', {
      pricingtype: item.pricingtype,
      stylenumber: item.stylenumber,
      custprice: item.custprice,
      description: item.description,
      color: item.color,
      taxexempt: item.taxexempt,
      supplier: item.supplier,
      manufacturer: item.manufacturer,
      QuickbooksNonCOGS: item.QuickbooksNonCOGS,
      QuickbooksItemID: item.QuickbooksItemID
    })
      .then(() => { props.onClose(); });
  }

  function getQuickbooksItems() {
    axios.get('/quickbooks/getItems')
      .then((res) => {
        setQuickbooksItems(res.data.result);
        setLoadingQBItems(false);
      });
  }

  function updateItem() {
    axios.put('/inventory', {
      inventory_item_id: props.viewingItem,
      pricingtype: item.pricingtype,
      stylenumber: item.stylenumber,
      custprice: item.custprice,
      description: item.description,
      color: item.color,
      taxexempt: item.taxexempt,
      supplier: item.supplier,
      manufacturer: item.manufacturer,
      QuickbooksNonCOGS: item.QuickbooksNonCOGS,
      QuickbooksItemID: item.QuickbooksItemID
    })
      .then(() => { props.onClose(); });
  }

  function getManufacturers() {
    axios.get('/settings', {
      params: {
        type: 'inventory',
        sub_type: 'providers',
        sub_sub_type: 'manufacturers'
      }
    })
      .then((res) => {
        setManufacturers(res.data.result.data);
      });
  }

  function getSuppliers() {
    axios.get('/settings', {
      params: {
        type: 'inventory',
        sub_type: 'providers',
        sub_sub_type: 'suppliers'
      }
    })
      .then((res) => {
        setSuppliers(res.data.result.data);
      });
  }


  function getItem() {
    if(props.viewingItem <= 0) return;
    axios.get('/inventory/individual/' + props.viewingItem)
      .then((res) => {
        setItem(res.data.result);
      });
  }

  return (
    <Modal
      open={(props.viewingItem !== 0)}
      onClose={props.onClose}
    >
      <SurroundingBox>
        <Stack
          width={'100%'}
          spacing={.5}
          direction={'column'}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: 20
            }}
          >
            {'View Item'.toUpperCase()}
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Stack
                direction="column"
                spacing={1}
                sx={{mr: 2}}
              >
                <TextField 
                  variant="standard"
                  size="small"
                  label="Stylenumber"
                  value={item.stylenumber}
                  onChange={(e) => { setItem({...item, stylenumber: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                  error={item.stylenumber === ''}
                  helperText={item.stylenumber === '' ? 'Item Must have a stylenumber' : ''}
                />
                <TextField 
                  variant="standard"
                  size="small"
                  label="Color"
                  value={item.color}
                  onChange={(e) => { setItem({...item, color: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField 
                  variant="standard"
                  size="small"
                  label="Price"
                  value={item.custprice}
                  onChange={(e) => { setItem({...item, custprice: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                  error={!/^(?=.*\d)\d{0,6}(\.\d{1,2})?$/.test(item.custprice) || !item.custprice}
                />
                <FormControl fullWidth>
                  <InputLabel id="pricingtype-label">Pricing Type</InputLabel>
                  <Select
                    labelId="pricingtype-label"
                    value={item.pricingtype}
                    label="Pricing Type"
                    onChange={(e) => { setItem({...item, pricingtype: e.target.value}); }}
                  >
                    {pricingtypeOptions.map(option => {
                      return (<MenuItem 
                        key={option.value}
                        value={option.value}
                      >{option.label}</MenuItem>);
                    })}
                  </Select>
                </FormControl>
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                >
                  <FormControlLabel 
                    control={
                      <Checkbox
                        value={item.taxexempt}
                        checked={item.taxexempt}
                        onChange={(e) => { setItem({...item, taxexempt: e.target.checked}); }}
                      />
                    }
                    label="Tax-Exempt"
                  />
                  <FormControlLabel 
                    control={
                      <Checkbox
                        value={item.QuickbooksNonCOGS}
                        checked={item.QuickbooksNonCOGS}
                        onChange={(e) => { setItem({...item, QuickbooksNonCOGS: e.target.checked}); }}
                      />
                    }
                    label="Non-COGS"
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction="column"
                spacing={1}
                sx={{mr: 2}}
              >
                <Box 
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Typography>Quickbooks Item:&nbsp;
                    <strong>
                      {quickbooksItems.find(
                        (qb) => qb.Id === item.QuickbooksItemID
                      )?.Name || 'NO ASSOCIATED ITEM WAS FOUND'}
                    </strong>
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={(e) => { 
                      setSettingQBItem(true);
                      setAnchorEl(e.currentTarget); 
                    }}
                  >Set Quickbooks Item</Button>
                  <PopoverSelect
                    anchorEl={anchorEl}
                    open={settingQBItem}
                    onClose={() => { setSettingQBItem(false); }}
                    mapItems={(filter) => {
                      return quickbooksItems
                        .filter(qbItem => { 
                          return qbItem.Name.includes(filter);
                        })
                        .map((qbItem) => {
                          return (
                            <Stack
                              key={qbItem.Id}
                              sx={{
                                paddingLeft: 1,
                                paddingTop: .5,
                                paddingBottom: .5,
                                '&:hover': {
                                  backgroundColor: 'lightslategray'
                                },
                                cursor: 'pointer' 
                              }}
                              onClick={() => { 
                                setItem({ ...item, QuickbooksItemID: qbItem.Id })
                                setSettingQBItem(false);
                              }}
                            >
                              <Typography>{qbItem.Name}</Typography>
                            </Stack>
                          );
                        });
                    }}
                  />
                </Box>
                <TextField 
                  variant="standard"
                  size="small"
                  multiline
                  rows="6"
                  label="Description"
                  value={item.description}
                  onChange={(e) => { setItem({...item, description: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                />
                <FormControl fullWidth>
                  <InputLabel id="manufacturer-label">Manufacturer</InputLabel>
                  <Select
                    labelId="manufacturer-label"
                    value={item.pricingtype}
                    label="Manufacturer"
                  >
                    {manufacturers.map(man => {
                      return (<MenuItem 
                        key={man}
                        value={man}
                      >{man}</MenuItem>);
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="supplier-label">Supplier</InputLabel>
                  <Select
                    labelId="supplier-label"
                    value={item.pricingtype}
                    label="Supplier"
                  >
                    {suppliers.map(sup => {
                      return (<MenuItem 
                        key={sup}
                        value={sup}
                      >{sup}</MenuItem>);
                    })}
                  </Select>
                </FormControl>

              </Stack>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 1 }}
            onClick={() => {
              //setEditingCustomer(false);
              props.onClose();
              props.viewingItem === -1 ? createItem() : updateItem();
            }}
          >{props.viewingItem === -1 ? 'Create Item' : 'Update Item'}</Button>
        </Stack>
        <Loading isOpen={loadingQBItems}/>
      </SurroundingBox>
    </Modal>
  );
}

const SurroundingBox = styled(Box)({
  width: '80%',
  height: '80%',
  backgroundColor: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'scroll',
  padding: '2%',
  display: 'flex',
});
