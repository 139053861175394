import { createStore, combineReducers } from 'redux';
import WorkCentersReducer from './store/WorkCenters.reducer';
import WorkorderReducer from './data/Workorder.reducer';
import WorkCentersPageReducer from './pages/WorkCenters/WorkCentersPageReducer';
import UsersPageReducer from './pages/Users/Users.page.reducer.js';
import SystemAdminPageReducer from './pages/SystemAdmin/SystemAdmin.reducer';

const initialAppState = {
  queryStatus: null,
  user_account_id: null,
  user_group_id: null,
  error: ''
};

const AppReducer = (state = initialAppState, action) => {
  switch (action.type) {
  case '@@APP/SET_QUERY_STATUS':
    return { ...state, queryStatus: action.queryStatus };
  case '@@APP/SET_USER_ACCOUNT_ID':
    return {
      ...state,
      user_account_id: action.user_account_id,
      user_group_id: action.user_group_id,
    };
  default:
    return state;
  }
};

const ErrorReducer = (state = initialAppState, action) => {
  switch (action.type) {
  case 'ERROR/SET':
    return {
      ...state,
      error: action.error
    };
  case 'ERROR/UNSET':
    return {
      ...state,
      error: ''
    };
  default:
    return state;
  }
};

const store = createStore(
  combineReducers({
    error: ErrorReducer,
    app: AppReducer,
    workCenters: WorkCentersReducer,
    workCentersPage: WorkCentersPageReducer,
    workOrder: WorkorderReducer,
    usersPage: UsersPageReducer,
    systemAdminPage: SystemAdminPageReducer,
  })
);

export default store;
