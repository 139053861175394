
import React from 'react';
import styled from '@emotion/styled';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { IconButton } from '@mui/material';

export default function Headers(props) {
  return (
    <Header>
      <HeadRow>
        {(props.columns || []).map(hc => (
          <HeaderCell
            setWidth={props.setWidth}
            {...hc}
            key={hc.key}
            headerKey={hc.key}
            sortableByHeader={props.sortableByHeader}
            onSortByHeader={props.onSortByHeader}
            sortDirection={props.sortDirection}
            sortingBy={props.sortingBy}
            defaultKey={props.defaultKey}
          />
        ))}
      </HeadRow>
    </Header>
  );
}

function HeaderCell(props) {
  const { maxWidth, background, sortKey = null, defaultKey = undefined, headerKey, headerName, sortableByHeader, sortingBy, sortDirection } = props;

  function isSortedByThis() {
    return sortingBy === headerKey || sortingBy === sortKey;
  }

  return (
    <HCell
      style={{
        width: maxWidth,
        background
      }}
      key={headerKey}
    >
      {headerName}
      {sortableByHeader && 
        <IconButton
          size="small"
          sx={{ 
            p: 0,
            color: isSortedByThis() ? 
              'primary.contrastText' : 'primary.contrastTextDark'
          }}
          onClick={() => {
            props.onSortByHeader(
              sortDirection === 'DESC' && isSortedByThis() ? 
                defaultKey : (sortKey ?? headerKey), 
              isSortedByThis() && sortDirection === 'ASC' ? 'DESC' : 'ASC'
            );
          }}
        >
          { sortDirection === 'DESC' && isSortedByThis() ? 
            <ArrowDropUp /> : <ArrowDropDown />}
        </IconButton>
      }
    </HCell>
  );
}

const Header = styled('thead')({
  position: 'sticky',
  top: '0px',
  zIndex: 10
});

const HeadRow = styled('tr')({});

const HCell = styled('th')(({ theme }) => ({
  fontSize: 12,
  background: theme.primary.color,
  color: theme.primary.textOn,
  padding: '7px 5px',
  flexGrow: 1,
  textAlign: 'left',
  fontWeight: 600,
  '&:first-of-type': {
    borderTopLeftRadius: 4
  },
  '&:last-child': {
    borderTopRightRadius: 4
  }
}));
