function numberToDollar(value : String | Number) {
  return (Math.round(Number(value) * 100) / 100).toFixed(2);
}

export interface estimateRowType {
  stylenumber: string,
  desc: string,
  lItemTotal: number,
  hItemTotal: number,
  lItemPrice: number | string,
  hItemPrice: number | string
}

export const estimateTemplate = [
  {
    key: 'stylenumber',
    headerName: 'Style',
    type: 'stylenumber',
    editable: true,
  },
  {
    key: 'desc',
    headerName: 'Desc',
    editable: false
  },
  {
    key: 'lItemTotal',
    headerName: 'Low Item Estimate',
    editable: true
  },
  {
    key: 'hItemTotal',
    headerName: 'High Item Estimate',
    editable: true
  },
  {
    key: 'lItemPrice',
    headerName: 'Low Item Price',
    maxWidth: 75,
    hideable: false,
    editableFunction: (row: any) => {
      return row.customPriceOn ?? false;
    },
    editDisplayValue: numberToDollar
  },
  {
    key: 'hItemPrice',
    headerName: 'High Item Price',
    maxWidth: 75,
    hideable: false,
    editableFunction: (row: any) => {
      return row.customPriceOn ?? false;
    },
    editDisplayValue: numberToDollar
  }
];
