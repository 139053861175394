const initialState = {
  filter: '',
  activeWorkcenter: 1
};

const WorkCentersPageReducer = (state=initialState, action) => {
  switch (action.type) {
  case 'WCP.SET_FILTER':
    return {...state, filter: action.filter};
  case 'WCP.CLEAR_FILTER':
    return {...state, filter: ''};
  case 'WCP.SET_ACTIVE_WORKCENTER':
    return {...state, activeWorkcenter: action.activeWorkcenter};
  default:
    return state;
  }
};

export default WorkCentersPageReducer;
