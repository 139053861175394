import axios from 'axios';
import { BASE_URL } from './api';

const noop = () => {};

export const uploadDocumentImages = ({
  file,
  document_id,
  onProgress = noop,
  onComplete = noop,
  onError = noop,
}) => {
  const data = new FormData();
  data.append('file', file);
  var config = {
    onUploadProgress: onProgress,
  };
  axios
    .post(`${BASE_URL}/api/documentimages/${document_id}`, data, config)
    .then(onComplete)
    .catch(onError);
};

export const uploadProfileImage = ({
  file,
  user_account_id,
  onProgress = noop,
  onComplete = noop,
  onError = noop,
}) => {
  const data = new FormData();
  data.append('file', file);
  var config = {
    onUploadProgress: onProgress,
  };
  axios
    .post(`${BASE_URL}/api/profilepicture/${user_account_id}`, data, config)
    .then(onComplete)
    .catch(onError);
};
