import React from 'react';
import Modal from './Modal';
import { Button } from './Controls';
import axios from 'axios';
import { BASE_URL, createHistory } from '../api';
import { connect } from 'react-redux';

export default class Wrapper extends React.Component {

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        element={EmailConfModal}
        {...this.props}
      />
    );
  }
}

class EmailConfModal extends React.Component {
  constructor() {
    super();
  }

  sendPickupEmail = () => {
    axios.post(`${BASE_URL}/api/pickupEmail`, {
      jobname: this.props.document.jobname.trim(),
      to: this.props.document.customerEmail.trim(),
      customerName: this.props.document.firstname.trim(),
    })
      .then(() => {
        this.props.onModalClose();
        const event = {
          document_id: this.props.document.document_id,
          user_id: this.props.user_account_id,
          type: 'pickup',
          key: 'pickupSent'
        };

        createHistory(event);
      });
  };

  render() {
    console.log(this.props);
    return (
      <>
        <p>
          Would you like to send a pickup email
          to {this.props.document.firstname.trim() + ' '} at
          {' ' + this.props.document.customerEmail.trim()} for the
          workorder {this.props.document.jobname.trim()}?
        </p>
        <Button
          styles={{ backgroundColor: 'red', alignSelf: 'flex-end', margin: '4px' }}
          onClick={this.props.onModalClose}
        >Close</Button>
        <Button
          onClick={this.sendPickupEmail}
        > Send</Button>
      </>
    );
  }
}

EmailConfModal = connect((state, props) => ({
    user_account_id: state.app.user_account_id,
    user: state.app.user,
    user_group_id: state.app.user_group_id,
    location: props.location,
}))(EmailConfModal);
