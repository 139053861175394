import React, { Fragment } from 'react';
import { PortalWithState } from 'react-portal';
import styled from '@emotion/styled';
import AddIcon from 'react-icons/lib/md/add';
import { Shade } from '../shell/Shell';
import axios from 'axios';
import { BASE_URL } from '../../api';
import InfiniteScrollList from '../Collections/InfiniteScrollList';

/**
 * Workorder Selector
 *
 * This allows you to select a workorder from the set of all workorders
 *
 * props:
    * onSelect - what to do with the workorder_id that is selected
    * reference - the ref of the component that the customer selector is associated with
 * */

export default class WorkcenterSelector extends React.Component {
  constructor() {
    super();
    this.state = {
      workorders: [],
      workordersCount: 0,
      filter: '',
      coordinates: {
        x: 0,
        y: 0,
        maxHeight: 300
      }
    };
  }

  componentDidMount() {
    this.countWorkorders();
    this.getWorkorders();
  }

  changeFilter = (filter) => {
    this.setState({ filter: filter, workorders: [], workordersCount: 0 }, () => {
      this.countWorkorders();
      this.getWorkorders();
    });
  };

  countWorkorders = () => {
    const filter = this.state.filter;

    axios.get(`${BASE_URL}/api/workcenters/allDocumentsCount`, {
      params: {
        jobname: filter
      }
    })
      .then((res) => {
        this.setState({ workordersCount: res.data.result });
      });
  };

  getWorkorders = () => {
    const filter = this.state.filter;

    axios.get(`${BASE_URL}/api/workcenters/allDocumentsSearch`, {
      params: {
        limit: 100,
        offset: this.state.workorders.length,
        jobname: filter
      }
    })
      .then((res) => {
        const workorders = this.state.workorders;
        workorders.push(...res.data.result);
        this.setState({ workorders });
      });
  };

  newWorkorder = () => {
    if(this.props.newWorkorder) {
      this.props.newWorkorder();
    } else {
      axios.post(`${BASE_URL}/api/document`)
        .then((res) => {
          console.log(res.data.document_id);
        });
    }
  };

  setSelectorCoordinates = () => {
    this.list.focus();
    const reference = this.reference.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const x = reference.x + 220 > screenWidth ? screenWidth - 220 : reference.x - 20;
    this.setState({
      coordinates: {
        x,
        y: reference.y,
        maxHeight: `calc(100vh - ${reference.y + 10}px)`
      }
    });
  };

  render() {
    const CustomButton = this.props.CustomButton;
    const {
      workorders
    } = this.state;

    return (
      <PortalWithState
        onOpen={this.setSelectorCoordinates}
        closeOnEsc
        closeOnOutsideClick
      >
        {({ portal, openPortal, closePortal, isOpen }) => (
          <Fragment>
            {CustomButton ?
              React.cloneElement(CustomButton, {
                ref:ref => {
                  this.reference = ref;
                },
                onClick:openPortal
              }) :
              <AddButton
                ref={ref => {
                  this.reference = ref;
                }}
                onClick={openPortal}
              >
                <AddIcon />
              </AddButton>
            }
            {portal(
              <InfiniteScrollList
                left={this.state.coordinates.x}
                top={this.state.coordinates.y}
                hasMore={this.state.workorders.length < this.state.workordersCount }
                loadMore={() => {this.getWorkorders();}}
                ref={list => {this.list = list;}}
                items={workorders}
                maxHeight={this.state.coordinates.maxHeight}
                onSubmit={this.props.onSelect}
                renderItem={({
                  item: workorder,
                  index: i,
                  selected,
                }) => (
                  <WorkorderCard
                    {...workorder}
                    onClick={() => {
                      closePortal();
                      this.props.onSelect(workorder);
                    }}
                    selected={selected}
                    key={i}
                  />
                )}
                renderAction={
                  <NewWorkorderButton
                    onClick={() => {
                      closePortal();
                      this.newWorkorder();
                    }}
                  >
                    New
                  </NewWorkorderButton>
                }
                onChange={(_, filter) => {this.changeFilter(filter);}}
                filter={() => true}
              />
            )}
            {isOpen && <Shade className="fade-in" />}
          </Fragment>
        )}
      </PortalWithState>
    );
  }
}

const AddButton = styled('button')(
  {
    width: '100%',
    background: 'none',
    border: 'none',
    fontSize: 40,
    borderRadius: 6,
    marginTop: 8,
    transition: 'border-color 200ms, color 200ms',
  },
  ({ theme }) => ({
    color: theme.neutral.extraExtraLight,
    border: `3px dashed ${theme.neutral.extraExtraLight}`,
    '&:hover': {
      borderColor: theme.neutral.light,
      color: theme.neutral.light,
    },
  }),
  ({noLabel}) => noLabel ? ({
    margin: 0
  }) : null
);

const NewWorkorderButton = styled('button')(
  {
    width: 41,
    height: 30,
    border: 'none',
    padding: 0,
    borderRadius: 3,
    marginLeft: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px'
  },
  ({ theme }) => ({
    background: theme.vibrant.color,
    color: theme.vibrant.textOn,
    boxShadow: theme.shadows.d1,
  })
);

export const WorkorderCard = ({
  jobname,
  document_id,
  selected,
  onClick
}) => (
  <WorkorderCardWrapper selected={selected} onClick={onClick}>
    <span>
      {jobname} | {document_id}
    </span>
  </WorkorderCardWrapper>
);

export const WorkorderCardWrapper = styled('div')(
  {
    fontSize: 16,
    padding: '10px 5px',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
  },
  ({ theme, selected }) => ({
    background: selected ? theme.blue.extraLight : 'none',
    '&:nth-of-type(even)': {
      background: selected ? theme.blue.extraLight : 'rgba(0,0,0,.07)',
    },
    '&:hover': {
      background: theme.blue.extraLight,
      '&:nth-of-type(even)': {
        background: theme.blue.extraLight,
      },
    },
  })
);
