import React from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import { TextInput, Button } from '@components/Controls';
import Logo from '@/common/img/logo-head.svg';
import { axios, credentialsHeader } from '@/api';
import loggedInSuccess from '@utils/auth/loggedInSuccess.js';
import { Box } from '@mui/system';

export const modes = {
  update: 'update',
  reset: 'reset',
  login: 'login'
};

const Page = styled('div')(
  {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99,
    transition: 'opacity 500ms',
  },
  ({ theme }) => ({
    background: theme.primary.color,
  })
);

const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    padding: 40,
    paddingTop: 60,
    background: '#fff',
    position: 'relative',
    borderRadius: 5,
    transition: 'opacity 700ms',
  },
  ({ theme }) => ({
    boxShadow: theme.shadows.d3,
  })
);

const LogoWrapper = styled('div')(
  {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    width: 120,
    height: 120,
    padding: 12,
    paddingTop: 20,
    top: -60,
    left: 'calc(50% - 60px)',

    // transitionTimingFunction: 'cubic-bezier(.72,.01,.36,1)'
    transition: 'top 1300ms',
  },
  ({ theme }) => ({
    background: theme.vibrant.color,
    border: `6px solid ${theme.primary.color}`,
    // boxShadow: theme.shadows.d2
  })
);

const PasswordReset = styled('div')({
  display: 'flex',
  fontSize: '.8rem',
  margin: '12px 0',
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoDimensions: {},
      hasError: false,

    };
  }

  componentDidMount = () => {
    //this?.username?.focus();
    //const { x, y } = this.logoWrapper.getBoundingClientRect();
    //this.setState({ logoDimensions: { left: x, top: y } });
  }

  checkForEnter = e => {
    if (e.charCode === 13) {
      this.submit();
    }
  }

  requestPasswordReset = () => {
    const username = this.username.value;
    // console.log('post it!')
    const headers = {
      ...credentialsHeader(),
    };

    if (headers.credentials === 'include') {
      axios.defaults.withCredentials = true;
    }

    axios({
      method: 'POST',
      url: '/auth/reset',
      headers,
      data: { username },
    })
      .then(response => {
        console.log('password reset sent:', response);
        this.setState({message: 'Password reset request sent'});
      })
      .catch(error => {
        console.log(error.message ? error.message : 'failed to login.');
        this.setState({message: 'User doesn\'t exist'});
      });
  };

  updatePassword = () => {
    const username = this.username.value;
    const password = this.password.value;
    const answer = this.answer.value;
    const token = this.props.match.params.reset_token;

    const headers = {
      ...credentialsHeader(),
    };
    if (headers.credentials === 'include') axios.defaults.withCredentials = true;
    axios.post('/auth/update_pw', {
      headers,
      data: { 
        username, 
        password, 
        token, 
        answer 
      }
    })
      .then(response => {
        loggedInSuccess(response);
      })
      .catch(error => {
        console.log(error.message ? error.message : 'failed to login.');
      });
  };

  login = () => {
    this.setState({hasError: false});
    const username = this.username.value;
    const password = this.password.value;
    const headers = {
      ...credentialsHeader(),
    };
    if (headers.credentials === 'include') axios.defaults.withCredentials = true;
    axios({
      method: 'POST',
      url: '/auth',
      headers,
      data: { username, password },
    })
      .then(response => {
        loggedInSuccess(response);
      })
      .catch(error => {
        console.log(error.message ? error.message : 'failed to login.');
        this.setState({hasError: true});
      });
  };

  submit = () => {
    switch (this.props.mode) {
    case modes.reset:
      this.requestPasswordReset();
      break;
    case modes.update:
      this.updatePassword();
      break;
    default:
      this.login();
      break;
    }
  };

  render() {
    const { mode = modes.login } = this.props;

    return (
      <Page>
        <Wrapper>
          <LogoBadge
            wrapperRef={r => {
              this.logoWrapper = r;
            }}
          />
          <TextInput
            innerRef={ref => {
              this.username = ref;
            }}
            label="Username"
            className={css({ marginBottom: 10 })}
            empty={false}
            onKeyPress={this.checkForEnter}
            uncontrolled
            id="username"
          />
          {mode !== modes.reset && (
            <>
              <TextInput
                innerRef={ref => {
                  this.password = ref;
                }}
                label="Password"
                className={css({ marginBottom: 10 })}
                type="password"
                empty={false}
                onKeyPress={this.checkForEnter}
                uncontrolled
                id="password"
              />
            </>
          )}
          {mode === modes.update && (
            <TextInput
              innerRef={ref => {
                this.answer = ref;
              }}
              label="Answer"
              className={css({ marginBottom: 10 })}
              empty={false}
              onKeyPress={this.checkForEnter}
              uncontrolled
              id="answer"
            />
          )}
          {mode === modes.login && (
            <PasswordReset>
              Forgot your&nbsp;<Link to="/password/reset">Password</Link>?
            </PasswordReset>
          )}
          <Button
            className={css({ marginTop: 10, textTransform: 'capitalize' })}
            onClick={this.submit}
            id="login-button"
          >
            {mode}
          </Button>
          {
            this.state.hasError &&
            <LoginError>Your username or password was incorrect.</LoginError>
          }
          {
            this.state.message &&
            <LoginMessage>{this.state.message}</LoginMessage>
          }
          <Box
            sx={{ 
              mt: 1,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Link to="/privacypolicy" replace>Privacy Policy</Link>
            &nbsp;-&nbsp;
            <Link to="/eula" replace>Terms of Service</Link>
          </Box>
        </Wrapper>
      </Page>
    );
  }
}
export default connect(state => ({
  user_group_id: state.app.user_group_id,
}))(Login);

const LogoBadge = ({ style, wrapperRef }) => (
  <LogoWrapper style={style} ref={wrapperRef}>
    <img className={css({ width: '100%' })} src={Logo} alt="logo" />
  </LogoWrapper>
);

const LoginError = styled('p')({
  color: 'rgb(231, 17, 17)',
  fontStyle: 'italic',
  margin: 0,
  marginTop: 8,
  fontSize: 13,
  fontWeight: 600,
  textAlign: 'center'
});

const LoginMessage = styled('p')({
  color: '#48cbfc',
  fontStyle: 'italic',
  margin: 0,
  marginTop: 8,
  fontSize: 13,
  fontWeight: 600,
  textAlign: 'center'
});
