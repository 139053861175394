export default function compose() {
  const funcs = [];
  for (let i = 0; i < arguments.length; i++) {
    funcs[i] = arguments[i];
  }

  const functions = funcs.reverse();

  return function () {
    const args = [];
    for(let i = 0; i < arguments.length; i++) {
      args[i] = arguments[i];
    }

    const firstFunction = functions[0], restFunctions = functions.slice(1);
    let result = firstFunction.apply(null, args);
    restFunctions.forEach(function (fnc) {
      result = fnc.call(null, result);
    });
    return result;
  };
}
