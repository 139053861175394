import React from 'react';
import { connect } from 'react-redux';
import Board from '../../components/Board';
import { Wrapper } from '../../components/shell/Shell';
import { css } from 'emotion';
import FilterBar from '../../components/Collections/FilterBar';
import { TextInput, Select, Button } from '../../components/Controls';
import DatePicker from '../../components/DatePicker';
import styled from '@emotion/styled';
import format from 'date-fns/format';
import compareAsc from 'date-fns/compareAsc';
import parse from 'date-fns/parse';
import CustomerSelector from '../../components/static/CustomerSelector';
import WorkorderSelector from '../../components/static/WorkorderSelector';
import WorkcenterIndicator from '../../components/static/WorkcenterIndicator';
import Description from 'react-icons/lib/md/description';
import theme from '../../theme';
import { history } from '../../index';
import { contactTypes, contactTypesObject } from '../../data/options';
import { axios } from '../../api';
import { TypedHistory } from '../../components/History';

export default class Sales extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { setTitle = () => {}, pageTitle } = this.props;
    setTitle(pageTitle);
  }

  render() {
    return (
      <Wrapper
        style={{
          display: 'flex',
          alignItems: 'start'
        }}
        className={css({
          width: '100%',
          height: '100vh',
        })}
        key="PAGE_WRAPPER"
      >
        <FilterBar
          unsortable={true}
          unfilterable={true}
          title="Sales"
          color="#FBC02D"
        >
        </FilterBar>
        <Board
          name="sales"
          addModal={AddModal}
          viewModal={ViewModal}
          modalWidth="1050px"
        />
      </Wrapper>
    );
  }
}

class AddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      customer: {},
      data: {
        contacts: [],
        customer_id: undefined,
        workorder_id: undefined
      },
      datePickerOpen: false,
      datePickerStyle: {},
      error: '',
      selectingCustomer: false,
      title: '',
      workorder: {}
    };

  }

  addContact = (contactInfo) => {
    if(!contactInfo.contactNote) {
      return;
    }

    if(!this.state.data.workorder_id) {
      const contacts = this.state.data.contacts;
      const json = {
        contactType: contactInfo.contactType,
        note: contactInfo.contactNote,
        date: format(contactInfo.contactDate, 'MM/DD/YY'),
        user_id: this.props.user_account_id
      };

      contacts.push(json);

      if(contactInfo.contactNote && contactInfo.contactType) {
        this.setState({
          contacts,
          contactNote: '',
        });
      }
    } else {
      axios.post('/history', {
        document_id: this.state.data.workorder_id,
        json: {
          contactType: contactInfo.contactType,
          note: contactInfo.contactNote,
          date: format(contactInfo.contactDate, 'MM/DD/YY')
        },
        type: 'contact',
        key: contactInfo.contactType,
        user_id: this.props.user_account_id
      })
        .then(() => {
          this.forceUpdate();
        });

    }

  };

  checkForEnter = (e) => {
    if(e.key === 'Enter') {
      this.submit();
    }
  };

  newWorkorder = () => {
    axios.post('/document', {
      document: {
        customer_id: this.state.data.customer_id,
        jobname: this.state.title
      }
    })
      .then((res) => {
        const data = this.state.data;
        data.workorder_id = res.data.document_id;

        const histories = this.state.data.contacts.sort((a, b) => { //Hopefully this gets them to order correctly
          return compareAsc(parse(a.date), parse(b.date));
        }).map((contact) => {
          return axios.post('/history', {
            document_id: data.workorder_id,
            user_id: contact.user_id,
            json: contact,
            type: 'contact',
            key: contact.contactType
          });
        });

        Promise.all(histories)
          .then(() => {
            this.setState({ data, workorder: res.data });
          });

      });
  };

  openDatePicker = () => {
    const dm = this.dateButton.current.getBoundingClientRect();
    const left = dm.left - 140;

    this.setState({
      datePickerOpen: true,
      datePickerStyle: {
        left,
        top: dm.bottom + 10
      }
    });
  };

  submit = () => {
    this.setState({ error: '' });

    if(!this.state.title) {
      this.setState({ error: 'Need a name for this item' });
      return;
    }

    this.props.onModalClose();
    this.props.submit(this.state.title, this.state.data);
  }

  render() {
    return (
      <>
        <Title
          style={{ marginBottom: '8px' }}
        >Add a New Prospect</Title>
        <TextInput
          label="Add Prospective customer"
          value={this.state.title}
          onChange={(e) => {
            this.setState({ title: e.target.value });
          }}
          onKeyPress={this.checkForEnter}
        />
        <Row
          style={{ justifyContent: 'space-evenly' }}
        >
          {Object.entries(this.state.customer).length > 0 ?
            <Customer
              clearCustomer={() => {
                const data = this.state.data;
                data.customer_id = undefined;
                this.setState({data, customer: {}});
              }}
              customer={this.state.customer}
            /> :
            <>
              <CustomerSelector
                CustomButton={<Button styles={{ height: '100%' }}>Add Customer</Button>}
                onSelect={(customer) => {
                  const data = this.state.data;
                  data.customer_id = customer.customer_id;
                  this.setState({ data, customer });
                }}
              />
            </>}
          {Object.entries(this.state.workorder).length > 0 ?
            <Workorder
              clearWorkorder={() => {
                const data = this.state.data;
                data.workorder_id = undefined;
                this.setState({ data, workorder: {} });
              }}
              workorder={this.state.workorder}
            /> :
            <WorkorderSelector
              CustomButton={
                <Button
                  styles={{ height: '100%' }}
                >Add Workorder</Button>
              }
              onSelect={(workorder) => {
                const data = this.state.data;
                if(workorder.customer_id) {
                  data.customer_id = workorder.customer_id;
                  this.setState({ data, customer: workorder.customer });
                }
                data.workorder_id = workorder.document_id;
                this.setState({ workorder, data });
              }}
              newWorkorder={this.newWorkorder}
            />
          }
        </Row>
        <Contact addContact={this.addContact}/>
        {(this.state.data.workorder_id) ?
          <TypedHistory document_id={this.state.data.workorder_id} type="contact" />
          : this.state.data.contacts.sort((a, b) => {
            return compareAsc(parse(a.date), parse(b.date));
          }).map((contact, i) => {
            return (
              <Row key={i}>{ contactTypesObject[contact.contactType] } - { contact.date } - { contact.note }</Row>
            );
          })}
        <hr />
        <Row
          style={{ flexDirection: 'row-reverse' }}
        >
          <Button
            onClick={this.submit}
          >Add Item</Button>
          <Error>{this.state.error}</Error>
        </Row>
      </>
    );
  }
}
AddModal = connect((state) => {
  return { user_account_id: state.app.user_account_id };
})(AddModal);

class ViewModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      customer: {},
      data: Object.assign({
        contacts: [],
        customer_id: undefined,
        workorder_id: undefined
      }, props.item.data),
      title: props.item.name,
      workorder: {}
    };
  }

  componentDidMount() {
    if(this.props.item.data.workorder_id) {
      axios.get('/document', {
        params: {
          id: this.props.item.data.workorder_id,
          attributes: ['customer', 'jobname', 'total', 'document_id', 'workcenter']
        }
      })
        .then((res) => {
          this.setState({
            customer: res.data.document?.customer || {},
            workorder: res.data.document || {}
          });
        });
    } else if(this.props.item.data.customer_id) {
      axios.get('/customer', {
        params: {
          id: this.props.item.data.customer_id
        }
      })
        .then((res) => {
          this.setState({
            customer: res.data.result
          });
        });
    }
  }

  addContact = (contactInfo) => {
    if(!contactInfo.contactNote) {
      return;
    }

    if(!this.state.data.workorder_id) {
      const contacts = this.state.data.contacts;
      const json = {
        contactType: contactInfo.contactType,
        note: contactInfo.contactNote,
        date: format(contactInfo.contactDate, 'MM/DD/YY'),
        user_id: this.props.user_account_id
      };

      contacts.push(json);

      if(contactInfo.contactNote && contactInfo.contactType) {
        this.setState({
          contacts,
          contactNote: '',
        });
      }
    } else {
      axios.post('/history', {
        document_id: this.state.data.workorder_id,
        json: {
          contactType: contactInfo.contactType,
          note: contactInfo.contactNote,
          date: format(contactInfo.contactDate, 'MM/DD/YY')
        },
        type: 'contact',
        key: contactInfo.contactType,
        user_id: this.props.user_account_id
      })
        .then(() => {
          this.forceUpdate();
        });
    }
  };

  newWorkorder = () => {
    axios.post('/document', {
      document: {
        customer_id: this.state.data.customer_id,
        jobname: this.state.title
      }
    })
      .then((res) => {
        const data = this.state.data;
        data.workorder_id = res.data.document_id;

        const histories = this.state.data.contacts.sort((a, b) => { //Hopefully this gets them to order correctly
          return compareAsc(parse(a.date), parse(b.date));
        }).map((contact) => {
          return axios.post('/history', {
            document_id: data.workorder_id,
            user_id: contact.user_id,
            json: contact,
            type: 'contact',
            key: contact.contactType
          });
        });

        Promise.all(histories)
          .then(() => {
            this.setState({ data, workorder: res.data });
          });

      });
  };

  submit = () => {
    axios.put('/board/item', {
      itemId: this.props.item.id,
      name: this.state.title,
      data: this.state.data
    })
      .then(() => {
        this.props.reloadBoard();
      });
  };

  render() {
    return (
      <>
        <Title style={{ marginBottom: '8px' }}>{this.state.title}</Title>
        <Row
          style={{ justifyContent: 'space-around' }}
        >
          {Object.entries(this.state.customer).length ?
            <Customer
              customer={this.state.customer}
              clearCustomer={() => {
                const data = this.state.data;
                data.customer_id = undefined;
                this.setState({data, customer: {}});
              }}
              disableClear={!!this.state.data.workorder_id}
            /> :
            !this.state.data.workorder_id ?
              <CustomerSelector
                CustomButton={<Button styles={{ height: '100%' }}>Add Customer</Button>}
                onSelect={(customer) => {
                  const data = this.state.data;
                  data.customer_id = customer.customer_id;
                  this.setState({ customer, data });
                }}
              /> :
              <p>No customer on the workorer yet</p>
          }
          {Object.entries(this.state.workorder).length ?
            <Workorder
              workorder={this.state.workorder}
              clearWorkorder={() => {
                const data = this.state.data;
                data.workorder_id = undefined;
                this.setState({ data, workorder: {} });
              }}
            /> :
            <WorkorderSelector
              CustomButton={
                <Button
                  styles={{ height: '100%' }}
                >Add Workorder</Button>
              }
              onSelect={(workorder) => {
                const data = this.state.data;
                if(workorder.customer_id) {
                  data.customer_id = workorder.customer_id;
                  this.setState({
                    customer: workorder.customer,
                    data
                  });
                }
                data.workorder_id = workorder.document_id;
                this.setState({ workorder, data });
              }}
              newWorkorder={this.newWorkorder}
            />
          }
        </Row>
        <Contact addContact={this.addContact}/>
        {(this.state.data.workorder_id) ?
          <TypedHistory document_id={this.state.data.workorder_id} type="contact" />
          : this.state.data.contacts.sort((a, b) => {
            return compareAsc(parse(a.date), parse(b.date));
          }).map((contact, i) => {
            return (
              <Row key={i}>{ contactTypesObject[contact.contactType] } - { contact.date } - { contact.note }</Row>
            );
          })}
        <hr />
        <Row
          style={{flexDirection: 'row-reverse'}}
        ><Button onClick={this.submit}>Save</Button></Row>
      </>
    );
  }
}

ViewModal = connect((state) => {
  return { user_account_id: state.app.user_account_id };
})(ViewModal);

const Title = styled('h3')(() => ({
  margin: 0
}));

const Error = styled('p')(() => ({
  color: 'red'
}));

const Row = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: '8px'
}));

class Customer extends React.Component {
  constructor() {
    super();
    this.state = {
      icons: false
    };
  }

  render() {
    const {
      email,
      firstname,
      lastname,
      primaryphone,
      address,
      city,
      zipcode,
      state,
      organization
    } = this.props.customer;
    return (
      <Box
        onMouseEnter={() => {
          this.setState({ icons:true });
        }}
        onMouseLeave={() => {
          this.setState({ icons:false });
        }}
      >
        {this.state.icons && this.props.clearCustomer && !this.props.disableClear &&
         <IconButton
           style={{
             left: '96%',
             top: '-14px'
           }}
           onClick={this.props.clearCustomer}
         >X</IconButton>
        }
        {organization && <Row>{'Organization: ' + organization}</Row>}
        <Row>{'Name: ' + firstname + ' ' + lastname}</Row>
        <Row>{'Phone: ' + primaryphone}</Row>
        <Row>{'Email: ' + email}</Row>
        <Row>{'Address: ' + address}</Row>
        <Row>{city + ' ' + state + ' ' + zipcode}</Row>
      </Box>
    );
  }
}

class Workorder extends React.Component {
  constructor() {
    super();
    this.state = {
      icons: false
    };
  }

  render() {
    const {
      document_id,
      jobname,
      total
    } = this.props.workorder;
    return (
      <Box
        onMouseEnter={() => {
          this.setState({ icons:true });
        }}
        onMouseLeave={() => {
          this.setState({ icons:false });
        }}
      >
        {this.state.icons &&
         <>
           <IconButton
             style={{
               left: '89%',
               top: '-14px',
               background: theme.vibrant.color
             }}
             onClick={() => { history.push('/workorder/edit/' + document_id); }}
           >
             <Description/>
           </IconButton>
           <IconButton
             style={{
               left: '96%',
               top: '-14px'
             }}
             onClick={this.props.clearWorkorder}
           >X</IconButton>
         </>
        }
        <Row>{'Document ID: ' + document_id}</Row>
        <Row>{'Jobname: ' + jobname}</Row>
        <Row>{'Total: ' + (total || '0.00')}</Row>
        <Row><p>Workcenter: </p><WorkcenterIndicator workcenter_id={this.props.workorder.workcenter} /></Row>
      </Box>
    );
  }
}

const IconButton = styled('button')(
  {
    position: 'absolute',
    width: 26,
    height: 26,
    borderRadius: '100%',
    padding: 0,
    fontSize: 20,
    lineHeight: 0,
    border: 'none',
    opacity: 1,
  },
  ({ theme }) => ({
    background: theme.danger.color,
    color: theme.danger.textOn,
    '&:hover': {
      background: theme.danger.light,
    },
  })
);

const Box = styled('div')(() => ({
  position: 'relative',
  width: '45%',
  border: '2px solid lightgrey',
  borderRadius: '4px',
  padding: '8px'
}));

class Contact extends React.Component {

  constructor() {
    super();
    this.state = {
      contactType: 'emailContact',
      contactDate: new Date(),
      contactNote: '',
      datePickerOpen: false,
      datePickerStyle: {}
    };
    this.dateButton = React.createRef();
  }

  openDatePicker = () => {
    const dm = this.dateButton.current.getBoundingClientRect();
    const left = dm.left - 140;

    this.setState({
      datePickerOpen: true,
      datePickerStyle: {
        left,
        top: dm.bottom + 10
      }
    });
  };

  addContact = () => {
    const state = this.state;
    this.props.addContact({
      contactType: state.contactType,
      contactDate: state.contactDate,
      contactNote: state.contactNote
    });
  }

  render() {
    return (
      <>
        <hr/>
        <Title
          style={{ marginBottom: '8px' }}
        >Add Contacts</Title>
        <Row>
          <Select
            value={this.state.contactType}
            options={contactTypes}
            onChange={(e) => {
              this.setState({ contactType: e.target.value });
            }}
          />
          <Row>
            <p
              style={{ marginRight: '12px' }}
            >
              {format(this.state.contactDate, 'MM/DD/YY')}
            </p>
            <Button
              ref={this.dateButton}
              onClick={() => { this.openDatePicker(); }}
            >Select Date</Button>
          </Row>
        </Row>
        <DatePicker
          isOpen={this.state.datePickerOpen}
          style={this.state.datePickerStyle}
          onModalClose={() => { this.setState({ datePickerOpen: false }); }}
          onDayClick={(date) => { this.setState({contactDate: date}); }}
        />
        <TextInput
          width="100%"
          type="textarea"
          label="Note"
          css={{height: '150px'}}
          onChange={(e) => { this.setState({ contactNote: e.target.value }); }}
          value={this.state.contactNote}
        />
        <Row style={{ flexDirection: 'row-reverse' }}>
          <Button onClick={() => {this.addContact({});}}>Add Contact</Button>
        </Row>
      </>
    );
  }
}
