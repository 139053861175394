import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import WorkCenterButton from './WorkCenterButton';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const WorkCentersList = (props) => {
  const { activeWorkCenterId = '' } = props;

  return (
    <Wrapper>
      { props.workcenters
        .filter((center) => center.active && center.isworkcenter)
        .map(workcenter => (
          <WorkCenterButton
            dropHandler={props.dropHandler}
            {...workcenter}
            active={
              activeWorkCenterId.toString() ===
                (workcenter.workcenter_id || '').toString()
            }
            key={workcenter.workcenter_id || workcenter.id}
          />
        ))}
    </Wrapper>
  );
};

export default connect((state) => {
  return {
    workcenters: state.workCenters.workCenters
  };
})(WorkCentersList);
