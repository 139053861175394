import React from 'react';
import styled from '@emotion/styled';

const Icon = styled('svg')({
  width: '100%'
});

export const AllIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0"
    y="0"
    viewBox="0 0 73.05 73.05"
    enableBackground="new 0 0 73.053 73.053"
    style={{ fill: color }}
  >
    <path d="M36.97 6.71c16.44 0 29.82 13.38 29.82 29.82S53.41 66.34 36.97 66.34 7.15 52.97 7.15 36.53 20.53 6.71 36.97 6.71M36.97 0.75c-19.76 0-35.78 16.02-35.78 35.78s16.02 35.78 35.78 35.78c19.76 0 35.78-16.02 35.78-35.78S56.73 0.75 36.97 0.75L36.97 0.75z" />
    <path d="M36.53 73.05C16.39 73.05 0 56.67 0 36.53S16.39 0 36.53 0c20.14 0 36.53 16.39 36.53 36.53S56.67 73.05 36.53 73.05zM36.53 1.49C17.21 1.49 1.49 17.21 1.49 36.53s15.72 35.03 35.03 35.03c19.32 0 35.04-15.72 35.04-35.03S55.85 1.49 36.53 1.49z" />
    <path d="M31.07 16.52l-14.14 4.81c-2.06 0.71-3.18 2.96-2.47 5.02l9.42 27.72c0.7 2.07 2.96 3.18 5.02 2.47l19.3-6.56c2.06-0.7 3.18-2.95 2.47-5.02l-7.68-22.6L31.07 16.52zM47.41 47.69l-19.3 6.56c-0.8 0.27-1.67-0.16-1.94-0.96l-9.42-27.72c-0.27-0.8 0.16-1.67 0.96-1.94l13.28-4.52 1.67 4.91c0.48 1.43 2.04 2.2 3.48 1.71l4.8-1.64 7.43 21.67C48.64 46.55 48.21 47.42 47.41 47.69L47.41 47.69zM47.41 47.69" />
    <path d="M22.29 32.55l5.8-1.97c0.49-0.17 0.75-0.69 0.59-1.18l-1.97-5.8c-0.17-0.49-0.7-0.76-1.19-0.59l-5.8 1.97c-0.49 0.17-0.75 0.7-0.58 1.19l1.97 5.8C21.27 32.46 21.8 32.72 22.29 32.55L22.29 32.55zM21.2 26.45l4.04-1.37 1.37 4.04 -4.04 1.37L21.2 26.45zM21.2 26.45" />
    <path d="M25.59 42.25l5.8-1.97c0.49-0.17 0.75-0.7 0.59-1.19l-1.97-5.8c-0.16-0.49-0.7-0.75-1.19-0.58l-5.8 1.97c-0.49 0.17-0.75 0.7-0.59 1.19l1.97 5.8C24.57 42.15 25.1 42.41 25.59 42.25L25.59 42.25zM24.5 36.15l4.04-1.37 1.37 4.04 -4.04 1.37L24.5 36.15zM24.5 36.15" />
    <path d="M31.1 49.22l-1.93 0.66 -1.37-4.04 4.04-1.37 0.39 1.14 0.72 0.36 0.81-1.65 -0.45-1.33c-0.17-0.49-0.7-0.75-1.19-0.58l-5.8 1.97c-0.49 0.17-0.75 0.69-0.58 1.18l1.97 5.81c0.17 0.49 0.7 0.75 1.19 0.58l4.78-1.62c-0.24-0.01-0.48-0.07-0.7-0.18L31.1 49.22zM31.1 49.22" />
    <path d="M35.62 42.62l-2.26 4.59 -1.82-0.9c-0.46-0.23-1.02-0.04-1.25 0.42 -0.23 0.47-0.04 1.02 0.43 1.25l2.65 1.31c0.23 0.12 0.49 0.13 0.72 0.05 0.23-0.08 0.42-0.24 0.54-0.47l2.67-5.43c0.23-0.46 0.04-1.02-0.42-1.25C36.41 41.97 35.85 42.16 35.62 42.62L35.62 42.62zM35.62 42.62" />
    <path d="M54.38 20.96L40.48 15.49c-2.03-0.8-4.33 0.2-5.13 2.23l-1.11 2.82c0.55-0.17 1.15-0.15 1.66 0.15 0.24 0.14 0.49 0.27 0.73 0.4l0.98-2.49c0.31-0.79 1.2-1.17 1.99-0.86l13.06 5.13 -1.9 4.83c-0.55 1.4 0.14 3 1.55 3.56l4.73 1.85 -8.33 21.34c-0.31 0.79-1.2 1.18-1.99 0.87l-7.83-3.08c-1.28 0.61-2.67 0.91-4.11 0.99l11.06 4.35c2.02 0.8 4.33-0.2 5.13-2.23l8.74-22.21L54.38 20.96z" />
    <path d="M46.71 55.31" />
    <path d="M45.95 22.32l-5.7-2.24c-0.48-0.19-1.02 0.05-1.21 0.53l-0.57 1.44c0.55 0.28 1.1 0.56 1.63 0.86 0.01 0 0.02 0 0.03 0.01l0.3-0.76 3.97 1.56 -1.09 2.76c0.35 0.92 0.66 1.84 0.9 2.79 0-0.01 0.01-0.02 0.02-0.03l2.24-5.7C46.67 23.06 46.43 22.52 45.95 22.32z" />
  </Icon>
);

export const BillingIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0"
    y="0"
    viewBox="0 0 73.05 73.05"
    enableBackground="new 0 0 73.054 73.053"
    style={{ fill: color }}
  >
    <path d="M36.97 6.71c16.44 0 29.82 13.38 29.82 29.82S53.41 66.34 36.97 66.34 7.15 52.97 7.15 36.53 20.53 6.71 36.97 6.71M36.97 0.75c-19.76 0-35.78 16.02-35.78 35.78s16.02 35.78 35.78 35.78c19.76 0 35.78-16.02 35.78-35.78S56.73 0.75 36.97 0.75L36.97 0.75z" />
    <path d="M36.53 73.05C16.39 73.05 0 56.67 0 36.53S16.39 0 36.53 0C56.67 0 73.06 16.39 73.06 36.53S56.67 73.05 36.53 73.05zM36.53 1.49c-19.32 0-35.03 15.72-35.03 35.03s15.72 35.03 35.03 35.03 35.03-15.72 35.03-35.03S55.85 1.49 36.53 1.49z" />
    <path d="M40.49 15.89l-4.08 2.16 -4.1-2.16c-0.79-0.42-1.69 0.32-1.43 1.17l2.22 7.42c1.09-0.34 2.2-0.51 3.31-0.51 1.1 0 2.19 0.17 3.27 0.5l2.25-7.39C42.18 16.21 41.28 15.47 40.49 15.89L40.49 15.89zM40.49 15.89" />
    <path d="M52.35 46.54c0-8.81-7.14-21.27-15.94-21.27 -8.8 0-15.94 12.46-15.94 21.27S27.6 57.3 36.4 57.3C45.21 57.3 52.35 55.35 52.35 46.54L52.35 46.54zM37.78 48.92v1.56c0 0.5-0.42 0.91-0.92 0.91h-0.79c-0.51 0-0.91-0.41-0.91-0.91v-1.42c-1.09-0.14-2.4-0.54-3.48-1.25 -0.39-0.26-0.51-0.8-0.25-1.2l0.82-1.27c0.26-0.4 0.8-0.53 1.2-0.28 0.94 0.58 1.73 0.82 2.52 0.82 1.17 0 1.64-0.4 1.64-1.31 0-1.87-6.03-2.13-6.03-6.22 0-2.36 1.33-4 3.61-4.49v-1.55c0-0.49 0.39-0.88 0.87-0.88H36.9c0.48 0 0.88 0.39 0.88 0.88v1.51c1.15 0.18 2.08 0.68 2.88 1.35 0.37 0.31 0.42 0.88 0.1 1.25l-0.88 1.02c-0.3 0.34-0.8 0.4-1.17 0.15 -0.6-0.42-1.17-0.63-1.82-0.63 -1.01 0-1.5 0.28-1.5 1.22 0 1.78 6.03 1.88 6.03 6.13C41.43 46.55 40.24 48.33 37.78 48.92L37.78 48.92zM37.78 48.92" />
  </Icon>
);

export const DesignIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 73.053 73.053"
    enableBackground="new 0 0 73.053 73.053"
    style={{ fill: color }}
  >
    <g>
      <g>
        <path d="M36.97,6.709c16.441,0,29.816,13.377,29.816,29.817S53.411,66.344,36.97,66.344
        c-16.442,0-29.817-13.377-29.817-29.817S20.527,6.709,36.97,6.709 M36.97,0.746c-19.764,0-35.781,16.019-35.781,35.78
        s16.018,35.78,35.781,35.78c19.76,0,35.78-16.019,35.78-35.78S56.729,0.746,36.97,0.746L36.97,0.746z" />
      </g>
      <g>
        <path d="M36.527,73.053C16.386,73.053,0,56.668,0,36.526S16.386,0,36.527,0c20.141,0,36.525,16.385,36.525,36.526
        S56.668,73.053,36.527,73.053z M36.527,1.491c-19.319,0-35.036,15.717-35.036,35.035s15.717,35.035,35.036,35.035
        c19.318,0,35.034-15.717,35.034-35.035S55.846,1.491,36.527,1.491z" />
      </g>
      <g>
        <g>
          <g>
            <g>
              <rect x="20.714" y="26.641" width="10.8" height="7.795" />
            </g>
          </g>
          <g>
            <g>
              <path d="M31.514,18.225c0-0.663-0.539-1.202-1.199-1.202h-8.396c-0.663,0-1.204,0.539-1.204,1.202v6.112h10.8
              V18.225z M31.514,18.225" />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path d="M31.514,52.946v-6.115h-10.8v6.115c0,0.661,0.541,1.205,1.204,1.205h8.396
            C30.975,54.151,31.514,53.607,31.514,52.946L31.514,52.946z M26.116,49.385c0.609,0,1.105,0.498,1.105,1.106
            c0,0.612-0.496,1.11-1.105,1.11c-0.613,0-1.111-0.498-1.111-1.11C25.005,49.883,25.503,49.385,26.116,49.385L26.116,49.385z
             M26.116,49.385" />
          </g>
        </g>
        <g>
          <g>
            <rect x="20.714" y="36.739" width="10.8" height="7.789" />
          </g>
        </g>
        <g>
          <g>
            <path d="M43.355,31.379l-8.938-5.021l-1.53,2.725v4.898l6.847,3.844L43.355,31.379z M43.355,31.379" />
          </g>
        </g>
        <g>
          <g>
            <path d="M47.254,24.428c0.15-0.269,0.185-0.58,0.104-0.874c-0.081-0.291-0.276-0.538-0.538-0.688l-6.941-3.896
            c-0.171-0.096-0.365-0.146-0.561-0.146c-0.414,0-0.798,0.225-0.998,0.588l-2.843,5.056l8.939,5.014L47.254,24.428z
             M47.254,24.428" />
          </g>
        </g>
        <g>
          <g>
            <path d="M32.888,47.44v2.579l1.1-1.965L32.888,47.44z M32.888,47.44" />
          </g>
        </g>
        <g>
          <g>
            <path d="M38.673,39.719l-5.785-3.251v8.482l2.164,1.217L38.673,39.719z M38.673,39.719" />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path d="M44.923,32.467l-1.353,1.089l-2.988,5.323l4.976,6.212l5.775-4.625l-6.325-7.885L44.923,32.467z
               M44.923,32.467" />
            </g>
          </g>
          <g>
            <g>
              <path d="M57.728,33.871l-4.975-6.208c-0.219-0.271-0.549-0.432-0.895-0.432c-0.261,0-0.515,0.091-0.719,0.253
              l-4.521,3.629l6.405,7.999l4.526-3.631C58.041,35.085,58.122,34.363,57.728,33.871L57.728,33.871z M57.728,33.871" />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path d="M33.206,52.015v0.932c0,1.112-0.904,2.015-2.019,2.015h-1.656l0.737,0.921
            c0.218,0.271,0.543,0.428,0.893,0.428c0.261,0,0.517-0.087,0.717-0.251l4.527-3.628l-2.021-2.519L33.206,52.015z M33.206,52.015
            " />
          </g>
        </g>
        <g>
          <g>
            <path d="M35.533,47.873l2.564,3.201l5.771-4.627l-4.432-5.533L35.533,47.873z M35.533,47.873" />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

export const FileIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="498.146 212.503 303.703 399.14"
    enableBackground="new 498.146 212.503 303.703 399.14"
    style={{ fill: color }}
  >
    <g>
      <g>
        <g>
          <path d="M700.959,212.503H540.637c-23.424,0-42.49,19.077-42.49,42.479v314.194c0,23.402,19.066,42.468,42.49,42.468h218.745
          c23.358,0,42.468-19.065,42.468-42.468V313.055L700.959,212.503z M759.382,585.635H540.637c-9.067,0-16.448-7.37-16.448-16.448
          V254.982c0-9.056,7.381-16.448,16.448-16.448l150.554-0.131v55.686c0,16.186,13.195,29.425,29.502,29.425l54.48-0.131
          l0.646,245.794C775.819,578.265,768.438,585.635,759.382,585.635L759.382,585.635z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M556.406,345.075h65.771c5.541,0,10.03-4.457,10.03-9.976v-65.75c0-5.585-4.489-10.086-10.03-10.086h-65.771
          c-5.542,0-10.031,4.501-10.031,10.086v65.75C546.375,340.618,550.865,345.075,556.406,345.075L556.406,345.075z M566.415,279.315
          h45.742v45.743h-45.742V279.315z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M556.406,454.99h65.771c5.541,0,10.03-4.468,10.03-10.021v-65.749c0-5.553-4.489-10.042-10.03-10.042h-65.771
          c-5.542,0-10.031,4.489-10.031,10.042v65.749C546.375,450.522,550.865,454.99,556.406,454.99L556.406,454.99z M566.415,389.24
          h45.742v45.731h-45.742V389.24z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M588.295,544.876h-21.88v-45.753h45.742v12.878l6.089,6.133l13.962-13.996v-15.014c0-5.585-4.489-10.02-10.03-10.02
          h-65.771c-5.541,0-10.031,4.435-10.031,10.02v65.782c0,5.52,4.49,9.977,10.031,9.977h54.13c-2.354-0.952-4.577-2.354-6.461-4.271
          L588.295,544.876z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M657.034,493.417l-38.799,38.865l-15.397-15.397c-3.92-3.92-10.271-3.92-14.159,0c-3.921,3.921-3.921,10.239,0,14.16
          l22.449,22.471c1.982,1.961,4.533,2.946,7.107,2.946c2.573,0,5.136-0.985,7.074-2.946l45.917-45.938
          c3.898-3.888,3.898-10.239,0-14.16C667.307,489.529,660.955,489.529,657.034,493.417L657.034,493.417z" />
        </g>
      </g>
    </g>
  </Icon>
);

export const FinishedIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 73.053 73.053"
    enableBackground="new 0 0 73.053 73.053"
    style={{ fill: color }}
  >
    <g>
      <g>
        <g>
          <path d="M36.969,6.709c16.441,0,29.816,13.376,29.816,29.817S53.41,66.344,36.969,66.344
          S7.152,52.968,7.152,36.526S20.527,6.709,36.969,6.709 M36.969,0.746c-19.764,0-35.779,16.019-35.779,35.78
          s16.016,35.78,35.779,35.78c19.76,0,35.781-16.019,35.781-35.78S56.729,0.746,36.969,0.746L36.969,0.746z" />
        </g>
      </g>
      <g>
        <path d="M36.525,73.053C16.385,73.053,0,56.668,0,36.526S16.385,0,36.525,0c20.143,0,36.527,16.385,36.527,36.526
        S56.668,73.053,36.525,73.053z M36.525,1.491C17.207,1.491,1.49,17.208,1.49,36.526s15.717,35.035,35.035,35.035
        c19.32,0,35.037-15.717,35.037-35.035S55.846,1.491,36.525,1.491z" />
      </g>
      <g>
        <g>
          <g>
            <g>
              <path d="M23.033,23.097c-0.186-0.729-0.928-1.168-1.658-0.986c-0.73,0.186-1.172,0.926-0.986,1.659
              l8.904,35.132c0.16,0.623,0.713,1.033,1.32,1.033c0.113,0,0.225-0.015,0.34-0.044c0.727-0.186,1.174-0.929,0.982-1.653
              L23.033,23.097z M23.033,23.097" />
            </g>
          </g>
          <g>
            <g>
              <path d="M52.676,32.995c-1.453-5.358-2.906-10.72-4.359-16.077c-0.156-0.583-0.43-1.2-1.469-1.267
              c-8.162-0.588-13.52,7.539-21.682,6.945c-0.646-0.076-1.061,0.256-0.904,0.838c1.455,5.357,2.895,10.718,4.355,16.076
              c0.234,0.858,0.83,1.186,1.473,1.267c8.162,0.592,13.521-7.535,21.684-6.947C52.418,33.908,52.834,33.573,52.676,32.995
              L52.676,32.995z M46.27,17.676c0.4,1.479,0.801,2.961,1.201,4.437c-1.719,0.056-3.232,0.402-4.84,1.002
              c-0.4-1.477-0.799-2.955-1.199-4.435C43.035,18.079,44.555,17.736,46.27,17.676L46.27,17.676z M29.453,34.253
              c-0.459-1.678-0.916-3.36-1.367-5.042c1.803-0.052,3.4-0.441,5.078-1.104c-0.398-1.479-0.801-2.957-1.201-4.435
              c1.639-0.733,3.182-1.609,4.73-2.513c0.402,1.479,0.801,2.956,1.201,4.435c-1.547,0.906-3.094,1.779-4.73,2.513
              c0.459,1.678,0.912,3.361,1.367,5.041C32.855,33.807,31.256,34.203,29.453,34.253L29.453,34.253z M35.75,37.647
              c-0.408-1.502-0.812-3-1.219-4.499c1.625-0.728,3.16-1.596,4.695-2.495c0.404,1.499,0.812,3.002,1.217,4.499
              C38.908,36.052,37.373,36.923,35.75,37.647L35.75,37.647z M39.26,30.633c-0.455-1.68-0.908-3.357-1.365-5.038
              c1.547-0.905,3.092-1.77,4.736-2.48c0.457,1.684,0.91,3.363,1.369,5.043C42.352,28.865,40.809,29.73,39.26,30.633L39.26,30.633
              z M45.246,32.646c-0.406-1.503-0.814-3.003-1.221-4.5c1.6-0.597,3.105-0.937,4.812-0.993c0.406,1.5,0.811,3,1.217,4.5
              C48.35,31.708,46.842,32.051,45.246,32.646L45.246,32.646z M45.246,32.646" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

export const OrderIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 73.053 73.053"
    enableBackground="new 0 0 73.053 73.053"
    style={{ fill: color }}
  >
    <g>
      <g>
        <path d="M36.969,6.709c16.441,0,29.817,13.377,29.817,29.817S53.41,66.344,36.969,66.344
        S7.151,52.967,7.151,36.526S20.527,6.709,36.969,6.709 M36.969,0.746c-19.762,0-35.781,16.019-35.781,35.78
        c0,19.76,16.02,35.78,35.781,35.78c19.761,0,35.781-16.021,35.781-35.78C72.75,16.765,56.729,0.746,36.969,0.746L36.969,0.746z" />
      </g>
      <g>
        <path d="M36.526,73.053C16.385,73.053,0,56.668,0,36.526S16.385,0,36.526,0s36.526,16.385,36.526,36.526
        S56.668,73.053,36.526,73.053z M36.526,1.491c-19.318,0-35.034,15.717-35.034,35.035s15.716,35.035,35.034,35.035
        s35.035-15.717,35.035-35.035S55.845,1.491,36.526,1.491z" />
      </g>
      <g>
        <g>
          <g>
            <path d="M59.877,39.237c0-0.009,0-0.018,0-0.026c-0.014-0.585-0.484-1.048-1.076-1.048h-0.283l-4.358-8.428
            c-0.183-0.358-0.554-0.581-0.955-0.581H45.76l0.286-2.251c0.08-0.9-0.196-1.748-0.785-2.395
            c-0.585-0.637-1.401-0.992-2.301-0.992H19.861c-0.58,0-1.071,0.45-1.127,1.028L18.5,26.399h13.162
            c0.855,0,1.529,0.692,1.508,1.549c-0.021,0.85-0.732,1.546-1.589,1.546h-4.392c0,0.003,0.007,0.006,0.007,0.009H14.302
            c-0.603,0-1.106,0.489-1.121,1.092c-0.016,0.603,0.46,1.096,1.067,1.096h17.064c0.793,0.121,1.386,0.806,1.363,1.636
            c-0.026,0.911-0.78,1.648-1.688,1.657H19.221c-0.609,0-1.117,0.494-1.134,1.104c-0.018,0.611,0.466,1.105,1.078,1.105h11.623
            c0.805,0.111,1.41,0.805,1.39,1.641c-0.023,0.917-0.786,1.659-1.704,1.659H17.135l0,0h-1.121c-0.608,0-1.115,0.495-1.133,1.106
            c-0.018,0.609,0.466,1.104,1.076,1.104h0.969l-0.379,3.959c-0.081,0.9,0.195,1.747,0.783,2.391
            c0.586,0.641,1.4,0.996,2.297,0.996h0.836c0.399,2.272,2.366,3.989,4.773,3.989c2.401,0,4.464-1.717,4.979-3.989h10.413
            c0.842,0,1.639-0.323,2.263-0.851c0.573,0.548,1.33,0.851,2.158,0.851h0.17c0.398,2.272,2.366,3.989,4.771,3.989
            c2.409,0,4.463-1.717,4.98-3.989h0.688c1.791,0,3.378-1.456,3.54-3.243l0.674-7.463c0-0.012,0-0.024,0-0.029
            c0-0.016,0.004-0.032,0.004-0.047C59.877,39.258,59.877,39.249,59.877,39.237L59.877,39.237z M25.295,51.828
            c-1.525,0-2.732-1.237-2.691-2.764c0.041-1.522,1.309-2.761,2.834-2.761c1.521,0,2.732,1.238,2.691,2.761
            C28.09,50.591,26.814,51.828,25.295,51.828L25.295,51.828z M50.051,51.828c-1.526,0-2.73-1.237-2.695-2.764
            c0.041-1.522,1.318-2.761,2.84-2.761c1.524,0,2.729,1.238,2.689,2.761C52.843,50.591,51.571,51.828,50.051,51.828L50.051,51.828
            z M56.995,46.662c-0.056,0.638-0.644,1.174-1.278,1.174h-0.742c-0.495-2.148-2.404-3.745-4.725-3.745
            c-2.319,0-4.311,1.597-4.916,3.745h-0.229c-0.277,0-0.529-0.104-0.704-0.294c-0.175-0.191-0.256-0.454-0.234-0.736l1.395-15.442
            h3.203l-0.508,5.62c-0.082,0.899,0.195,1.751,0.781,2.394c0.588,0.641,1.404,0.992,2.3,0.992h6.229L56.995,46.662z
             M56.995,46.662" />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

export const ProductionIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 73.053 73.053"
    enableBackground="new 0 0 73.053 73.053"
    style={{ fill: color }}
  >
    <g>
      <g>
        <g>
          <path d="M36.969,6.709c16.443,0,29.818,13.376,29.818,29.817S53.412,66.344,36.969,66.344
          c-16.441,0-29.816-13.376-29.816-29.817S20.527,6.709,36.969,6.709 M36.969,0.746c-19.762,0-35.779,16.019-35.779,35.78
          s16.018,35.78,35.779,35.78S72.75,56.288,72.75,36.526S56.73,0.746,36.969,0.746L36.969,0.746z" />
        </g>
      </g>
      <g>
        <path d="M36.527,73.053C16.385,73.053,0,56.668,0,36.526S16.385,0,36.527,0c20.141,0,36.525,16.385,36.525,36.526
        S56.668,73.053,36.527,73.053z M36.527,1.491c-19.32,0-35.035,15.717-35.035,35.035s15.715,35.035,35.035,35.035
        c19.318,0,35.035-15.717,35.035-35.035S55.846,1.491,36.527,1.491z" />
      </g>
      <g>
        <g>
          <g>
            <g>
              <path d="M38.41,20.572c-0.154-0.238-0.205-0.401-0.205-0.401l0.072-0.07c0,0,0.15,0.062,0.396,0.166
              c0.121,0.046,0.264,0.104,0.43,0.172c0.168,0.058,0.346,0.131,0.535,0.175c0.096,0.028,0.191,0.058,0.291,0.069
              c0.049,0.004,0.1,0.018,0.148,0.029c0.049,0,0.096,0.006,0.145,0.015c0.199,0.023,0.383,0,0.58-0.009
              c0.094-0.035,0.195-0.044,0.297-0.087c0.094-0.056,0.213-0.088,0.301-0.155c0.045-0.034,0.088-0.061,0.143-0.099
              c0.049-0.026,0.105-0.049,0.166-0.11l0.35-0.297c0-0.003-0.021,0.028-0.01,0.015l0.01-0.009l0.01-0.009l0.021-0.021l0.051-0.04
              l0.09-0.082l0.174-0.166c0.111-0.113,0.248-0.204,0.361-0.312c0.105-0.11,0.256-0.181,0.371-0.288
              c0.121-0.099,0.271-0.175,0.406-0.269c0.297-0.14,0.645-0.244,0.955-0.197c0.072,0.005,0.148,0.018,0.225,0.034
              c0.068,0.018,0.139,0.041,0.201,0.066c0.137,0.045,0.26,0.111,0.367,0.173c0.225,0.128,0.398,0.273,0.535,0.419
              c0.141,0.144,0.244,0.282,0.32,0.404c0.078,0.123,0.127,0.226,0.156,0.298c0.031,0.073,0.041,0.113,0.041,0.113l-0.07,0.07
              c0,0-0.162-0.056-0.406-0.154c-0.117-0.05-0.258-0.11-0.426-0.178c-0.16-0.064-0.338-0.137-0.521-0.199
              c-0.092-0.034-0.191-0.072-0.283-0.092c-0.049-0.012-0.098-0.032-0.145-0.047c-0.051-0.007-0.104-0.018-0.152-0.038
              c-0.195-0.063-0.4-0.128-0.656-0.154c-0.117,0.015-0.256,0.009-0.379,0.062c-0.117,0.059-0.248,0.104-0.346,0.209
              c-0.094,0.105-0.201,0.192-0.268,0.323l-0.1,0.189l-0.043,0.1l-0.021,0.046l-0.01,0.026l-0.004,0.009v0.006l-0.006,0.005
              c0.01-0.014-0.016,0.027-0.016,0.021l-0.188,0.422c-0.061,0.131-0.207,0.285-0.322,0.417c-0.119,0.148-0.273,0.233-0.416,0.346
              c-0.152,0.105-0.33,0.166-0.498,0.239c-0.348,0.102-0.719,0.119-1.023,0.038c-0.162-0.035-0.309-0.082-0.438-0.149
              c-0.135-0.055-0.248-0.125-0.357-0.195c-0.215-0.142-0.389-0.293-0.523-0.441C38.588,20.837,38.488,20.691,38.41,20.572
              L38.41,20.572z M53.596,44.342v8.63H17.398v-8.63h2.465v-9.696l6.951-4.872v4.872l6.945-4.872v4.872l6.949-4.872v9.587h2.393
              l1.307-16.954h3.973l1.141,16.954h2.67v4.981H53.596z M28.744,40.614h-5.461v5.044h5.461V40.614z M38.158,40.614h-5.461v5.044
              h5.461V40.614z M47.689,40.614h-5.467v5.044h5.467V40.614z M47.689,40.614" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

export const QuotesIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 73.053 73.053"
    enableBackground="new 0 0 73.053 73.053"
    style={{ fill: color }}
  >
    <g>
      <g>
        <path d="M36.969,6.709c16.442,0,29.818,13.376,29.818,29.817S53.411,66.344,36.969,66.344
        c-16.441,0-29.817-13.376-29.817-29.817S20.527,6.709,36.969,6.709 M36.969,0.746c-19.762,0-35.78,16.019-35.78,35.78
        s16.019,35.78,35.78,35.78c19.761,0,35.781-16.019,35.781-35.78S56.729,0.746,36.969,0.746L36.969,0.746z" />
      </g>
      <g>
        <path d="M36.526,73.053C16.385,73.053,0,56.668,0,36.526S16.385,0,36.526,0c20.141,0,36.526,16.385,36.526,36.526
        S56.667,73.053,36.526,73.053z M36.526,1.491c-19.318,0-35.035,15.717-35.035,35.035s15.717,35.035,35.035,35.035
        s35.036-15.717,35.036-35.035S55.845,1.491,36.526,1.491z" />
      </g>
      <g>
        <g>
          <g>
            <path d="M42.093,19.405h-14.64c-2.139,0-3.88,1.742-3.88,3.879v28.691c0,2.137,1.741,3.878,3.88,3.878h19.975
            c2.133,0,3.878-1.741,3.878-3.878V28.587L42.093,19.405z M47.428,53.478H27.453c-0.828,0-1.502-0.673-1.502-1.502V23.284
            c0-0.827,0.674-1.502,1.502-1.502l13.748-0.012v5.085c0,1.478,1.205,2.687,2.694,2.687l4.975-0.012l0.059,22.445
            C48.929,52.805,48.255,53.478,47.428,53.478L47.428,53.478z M47.428,53.478" />
          </g>
        </g>
        <g>
          <g>
            <path d="M28.893,31.511h6.006c0.506,0,0.916-0.407,0.916-0.911v-6.004c0-0.51-0.41-0.921-0.916-0.921h-6.006
            c-0.506,0-0.916,0.411-0.916,0.921V30.6C27.977,31.104,28.387,31.511,28.893,31.511L28.893,31.511z M29.807,25.506h4.177v4.177
            h-4.177V25.506z M29.807,25.506" />
          </g>
        </g>
        <g>
          <g>
            <path d="M28.893,41.548h6.006c0.506,0,0.916-0.408,0.916-0.915v-6.004c0-0.507-0.41-0.917-0.916-0.917h-6.006
            c-0.506,0-0.916,0.41-0.916,0.917v6.004C27.977,41.14,28.387,41.548,28.893,41.548L28.893,41.548z M29.807,35.544h4.177v4.176
            h-4.177V35.544z M29.807,35.544" />
          </g>
        </g>
        <g>
          <g>
            <path d="M31.805,49.756h-1.998v-4.178h4.177v1.176l0.556,0.56l1.275-1.278v-1.371c0-0.51-0.41-0.915-0.916-0.915
            h-6.006c-0.506,0-0.916,0.405-0.916,0.915v6.007c0,0.504,0.41,0.911,0.916,0.911h4.943c-0.215-0.087-0.418-0.215-0.59-0.39
            L31.805,49.756z M31.805,49.756" />
          </g>
        </g>
        <g>
          <g>
            <path d="M38.082,45.057l-3.543,3.549L33.133,47.2c-0.358-0.358-0.938-0.358-1.293,0
            c-0.358,0.358-0.358,0.935,0,1.293l2.05,2.052c0.181,0.179,0.414,0.269,0.649,0.269s0.469-0.09,0.646-0.269l4.193-4.195
            c0.356-0.355,0.356-0.935,0-1.293C39.02,44.702,38.44,44.702,38.082,45.057L38.082,45.057z M38.082,45.057" />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

export const SeparationsIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0"
    y="0"
    viewBox="0 0 73.05 73.05"
    enableBackground="new 0 0 73.054 73.053"
    style={{ fill: color }}
  >
    <g>
      <g>
        <path d="M36.969,6.709c16.441,0,29.818,13.376,29.818,29.817S53.41,66.344,36.969,66.344
        S7.152,52.968,7.152,36.526S20.527,6.709,36.969,6.709 M36.969,0.746c-19.764,0-35.781,16.019-35.781,35.78
        s16.018,35.78,35.781,35.78c19.76,0,35.781-16.019,35.781-35.78S56.729,0.746,36.969,0.746L36.969,0.746z" />
      </g>
      <g>
        <path d="M36.525,73.053C16.385,73.053,0,56.668,0,36.526S16.385,0,36.525,0s36.527,16.385,36.527,36.526
        S56.666,73.053,36.525,73.053z M36.525,1.491C17.207,1.491,1.49,17.208,1.49,36.526s15.717,35.035,35.035,35.035
        c19.32,0,35.037-15.717,35.037-35.035S55.846,1.491,36.525,1.491z" />
      </g>
      <g>
        <g>
          <g>
            <g>
              <path d="M54.793,24.08c-0.068-0.184-0.236-0.312-0.432-0.335c-0.195-0.021-0.383,0.061-0.494,0.221
              c-0.672,0.941-1.555,1.253-2.576,1.619c-0.824,0.295-1.674,0.594-2.596,1.258c-1.301,0.932-1.893,2.207-1.596,3.413
              l-0.031,0.041c-0.547-0.793-1.135-1.521-1.762-2.17c-2.914-3.043-6.996-4.874-11.188-5.022
              c-0.184-0.007-0.371-0.009-0.561-0.009c-4.68,0-9.162,2.09-12.293,5.742c-3.115,3.631-4.498,8.354-3.785,12.963
              c0.059,0.385,0.133,0.771,0.219,1.156c1.152,5.067,4.092,8.922,8.068,10.579c0.979,0.407,1.971,0.614,2.936,0.614
              c2.41,0,4.514-1.29,5.771-3.538c0.48-0.853,0.789-1.738,0.918-2.626c0.078-0.524,0.023-1.045-0.027-1.556
              c-0.053-0.524-0.1-1.013-0.02-1.514c0.178-1.142,0.975-1.823,2.129-1.823c0.328,0,0.664,0.057,0.996,0.164
              c-0.393,0.718-0.746,1.403-1.057,2.041c-0.66,1.345-1.115,2.461-1.352,3.315c-0.146,0.524-0.539,1.923,0.414,2.58
              c0.301,0.211,0.682,0.289,1.08,0.211c0.363-0.064,1.449-0.271,4.553-3.798c0.832-0.949,1.73-2.047,2.668-3.262
              c2.404-0.81,4.277-2.823,4.775-5.146c0.123-0.576,0.176-1.199,0.154-1.843c0.9-1.395,1.689-2.693,2.488-4.092
              c0.014-0.022,0.027-0.052,0.041-0.081c1.154-0.316,2.148-1.217,2.734-2.473C55.822,28.878,55.758,26.523,54.793,24.08
              L54.793,24.08z M41.781,32.761c-0.418,0.66-1.137,1.059-1.922,1.059c-0.424,0-0.842-0.122-1.203-0.35
              c-0.516-0.316-0.867-0.826-1.002-1.411c-0.133-0.592-0.031-1.203,0.289-1.713c0.422-0.663,1.137-1.06,1.924-1.06
              c0.422,0,0.844,0.119,1.207,0.347c0.51,0.323,0.863,0.826,1,1.418C42.207,31.64,42.104,32.247,41.781,32.761L41.781,32.761z
               M44.725,36.866c1.145-1.687,2.355-3.363,3.51-4.858c0.182,0.166,0.391,0.336,0.619,0.493c0.375,0.26,0.766,0.46,1.164,0.594
              C49.049,34.761,48,36.402,46.74,38.27c-2.082,3.061-4.242,5.907-6.086,8.007c-1.031,1.177-1.936,2.085-2.629,2.645
              c0.268-0.856,0.779-2.042,1.494-3.434C40.793,42.991,42.646,39.929,44.725,36.866L44.725,36.866z M24.607,36.851
              c-0.428,0-0.846-0.121-1.207-0.35c-0.512-0.319-0.869-0.823-1.002-1.415c-0.133-0.59-0.029-1.195,0.289-1.709
              c0.422-0.663,1.139-1.06,1.922-1.06c0.428,0,0.844,0.123,1.207,0.352c1.059,0.664,1.373,2.071,0.711,3.125
              C26.111,36.458,25.391,36.851,24.607,36.851L24.607,36.851z M22.23,41.846c0.416-0.664,1.137-1.061,1.922-1.061
              c0.426,0,0.84,0.116,1.205,0.346c0.508,0.327,0.867,0.827,1.002,1.419c0.133,0.588,0.027,1.199-0.295,1.706
              c-0.416,0.664-1.133,1.061-1.918,1.061c-0.426,0-0.842-0.123-1.205-0.348c-0.514-0.323-0.869-0.827-1.004-1.415
              C21.805,42.963,21.908,42.354,22.23,41.846L22.23,41.846z M29.49,28.383c0.416-0.66,1.133-1.059,1.92-1.059
              c0.424,0,0.842,0.121,1.203,0.35c1.057,0.663,1.375,2.066,0.709,3.126c-0.418,0.658-1.133,1.058-1.92,1.058
              c-0.426,0-0.84-0.119-1.205-0.35c-0.514-0.32-0.867-0.823-1.002-1.415C29.062,29.501,29.166,28.896,29.49,28.383L29.49,28.383z
               M29.49,28.383" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

export const FrontDeskIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0"
    y="0"
    viewBox="0 0 73.05 73.05"
    enableBackground="new 0 0 73.054 73.053"
    style={{ fill: color }}
  >
    <g><g><g><path d="M37,6.7c16.4,0,29.8,13.4,29.8,29.8S53.4,66.3,37,66.3C20.5,66.3,7.2,53,7.2,36.5S20.5,6.7,37,6.7 M37,0.7 c-19.8,0-35.8,16-35.8,35.8s16,35.8,35.8,35.8s35.8-16,35.8-35.8S56.7,0.7,37,0.7L37,0.7z"/></g><g><path d="M36.5,73.1C16.4,73.1,0,56.7,0,36.5S16.4,0,36.5,0S73,16.4,73,36.5S56.7,73.1,36.5,73.1z M36.5,1.5 c-19.3,0-35,15.7-35,35s15.7,35,35,35s35-15.7,35-35S55.8,1.5,36.5,1.5z"/></g><g><path d="M55.7,35.4h-2.3v-1.1c0-3-1.7-5.7-4.2-7c1.2-1,2-2.6,2-4.3c0-3.1-2.5-5.6-5.6-5.6S40,19.9,40,23 c0,1.7,0.8,3.2,2,4.3c-2.5,1.3-4.2,4-4.2,7v1.1h-7.9v-2.3h5.6V19.6h-18v13.5H23v2.3h-5.6v6.8h2.3v13.5h33.8V42.2h2.3L55.7,35.4 L55.7,35.4z M42.2,23c0-1.9,1.5-3.4,3.4-3.4S49,21.2,49,23s-1.5,3.4-3.4,3.4S42.2,24.9,42.2,23z M39.9,34.3 c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6v1.1H39.9V34.3z M19.6,21.9h13.5v9H19.6V21.9z M25.3,33.2h2.3v2.3h-2.3V33.2z M51.2,53.5 H21.9V42.2h29.3C51.2,42.2,51.2,53.5,51.2,53.5z M53.5,39.9H19.6v-2.3h33.8L53.5,39.9L53.5,39.9z"/><path d="M24.1,44.4H49v2.3H24.1V44.4z"/><path d="M24.1,49H49v2.3H24.1V49z"/></g></g></g>
  </Icon>
);

export const ManagerCheckIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0"
    y="0"
    viewBox="0 0 73.05 73.05"
    enableBackground="new 0 0 73.054 73.053"
    style={{ fill: color }}
  >
    <g>
      <g>
        <g>
          <path d="M37,6.7c16.4,0,29.8,13.4,29.8,29.8S53.4,66.3,37,66.3C20.5,66.3,7.2,53,7.2,36.5S20.5,6.7,37,6.7 M37,0.7
            c-19.8,0-35.8,16-35.8,35.8s16,35.8,35.8,35.8c19.8,0,35.8-16,35.8-35.8S56.7,0.7,37,0.7L37,0.7z"/>
        </g>
        <g>
          <path d="M36.5,73.1C16.4,73.1,0,56.7,0,36.5S16.4,0,36.5,0s36.5,16.4,36.5,36.5S56.7,73.1,36.5,73.1z M36.5,1.5
            c-19.3,0-35,15.7-35,35s15.7,35,35,35c19.3,0,35-15.7,35-35S55.8,1.5,36.5,1.5z"/>
        </g>
        <path d="M18,19.7h13.5v13.5H18V19.7 M22.5,24.2v4.5H27v-4.5H22.5 M36,24.2h22.5v4.5H36V24.2 M36,42.1h22.5v4.5H36
          V42.1 M22.5,53.4l-7.9-7.9l3.2-3.2l4.7,4.7l10.3-10.3l3.2,3.2L22.5,53.4z"/>
      </g>
    </g>
  </Icon>
);
