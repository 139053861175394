const initialState = {
  id: 47,
  name: 'Test Order',
  due: '10/23/18',
  total: 0,
};

const WorkorderReducer = (state=initialState, action) => {
  switch (action.type) {
  case 'WO.setName':
    return {...state, name: action.name};
  default:
    return state;
  }
};

export default WorkorderReducer;
