import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div>
      <a href={window.location.origin + '/'}>Back to Home</a>
      <h1>Privacy Policy</h1>
      <h4>Analytics</h4> 
      <p>
        Only the employees of Badger Screen Printing Co. use this app, so
        we don&apost collect any analytics on traffic or personal information.
      </p> 
      <h4>Personal Information</h4> 
      <p>
        We don&apost track personal information when using this application
        beyond that needed to create a user account for employees.
      </p>
      <h4>Cookies</h4> 
      <p>
        Our use of cookies is limited to that of keeping some login tokens
        stored for user authentication.
      </p> 
      <h4>Security</h4>
      <p>
        We implement modern privacy and data protection practices on our side 
        to assure that any information we do store is protected. 
      </p>
      <h4>Contact</h4>
      <p>
        If you have any concerns please feel 
        free to reach out to our IT team at <a>tech@badgerprints.com</a>. 
        We look forward to working with you.
      </p>

    </div>
  );
}
