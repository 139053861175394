import React from 'react';
import FilterBar from '../../components/Collections/FilterBar';
import FilterableList from '../../components/FilterableList';
import { Wrapper } from '../../components/shell/Shell';
import InfiniteScroll from 'react-infinite-scroller';
import { css } from 'emotion';
import styled from '@emotion/styled';
import ModalStateController from '../../components/ModalStateController';
import FloatingActionButton from '../../components/FloatingActionButton';
import Table from '../../components/Collections/Table';
import axios from 'axios';
import { BASE_URL } from '../../api';
import { RowWrapper } from '../Workorder/Followers';
import { readableSizeColumns } from '../../data/linesTemplates';
import { Select, TextInput, Button } from '../../components/Controls';
import OrderIcon from 'react-icons/lib/md/list';

const ItemTemplate = [
  {
    label: 'Stylenumber',
    id: 'stylenumber',
    bold: true,
    render: row => {
      return <p>{row.inventory_item.stylenumber}</p>;
    }
  },
  {
    label: 'Price Color',
    id: 'color',
    render: row => {
      return <p>{row.inventory_item.color}</p>;
    }
  },
  {
    label: 'Shirt Color',
    id: 'shirtcolor',
    render: row => {
      return <p>{row.color}</p>;
    }
  },
  {
    label: 'Size',
    id: 'size',
    render: row => {
      return <p>{row.size}</p>;
    }
  },
  {
    label: 'In Stock',
    id: 'number',
    render: row => {
      return <p>{row.number}</p>;
    }
  }
];

export default class Stock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
      filter: '',
      modifying: false,
      rowToModify: {},
      items: [],
      itemCount: 0
    };
  }

  componentDidMount() {
    axios.get(`${BASE_URL}/api/inventory/stock/itemsCount`, {
      params: {
        filter: this.state.filter
      }
    })
      .then((res) => {
        this.setState({ itemCount: res.data.result });
      });
  }

  downloadSheet = () => {
    axios.get(`${BASE_URL}/api/inventory/stock/spreadsheet`)
      .then((res) => {
        const blobUrl = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'text/csv'
          })
        );
        const a = document.createElement('a');
        a.download = 'stock-sheet.csv';
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });

  };

  getMoreItems = () => {
    axios.get(`${BASE_URL}/api/inventory/stock/items`, {
      params: {
        limit: 5,
        offset: this.state.items.length,
        filter: this.state.filter
      }
    })
      .then((res) => {
        const items = this.state.items;
        console.log(items);
        items.push(...res.data.results);
        this.setState({ items });
      });
  };

  modifyItem = (row) => {
    this.setState({
      modifying: true,
      rowToModify: row
    });
  };

  refetchItems = () => {
    axios.get(`${BASE_URL}/api/inventory/stock/itemsCount`, {
      params: {
        filter: this.state.filter
      }
    })
      .then((res) => {
        this.setState(
          { itemCount: res.data.result },
          () => {
            axios.get(`${BASE_URL}/api/inventory/stock/items`, {
              params: {
                limit: (this.state.items.length < this.state.itemCount) ? this.state.items.length : this.state.itemCount,
                offset: 0,
                filter: this.state.filter
              }
            })
              .then((res) => {
                this.setState({ items: res.data.results });
              });
          }
        );
      });
  };

  render() {
    return (
      <Wrapper
        className={css({
          width: '100%',
          paddingTop: 60,
        })}
        key="PAGE_WRAPPER"
      >
        <FilterBar
          unsortable={true}
          value={this.state.filter}
          onChange={(value) => {
            this.setState(
              {filter: value},
              () => {this.refetchItems();}
            );
          }}
          onClear={() => {
            this.setState(
              { filter: '' },
              () => {
                this.refetchItems();
              }
            );

          }}
          title="Stock"
          color="#1B5E20"
          workCenter={{
            name: 'Stock',
          }}
          key="FILTER_BAR"
        />
        <InfiniteScroll
          pageStart={0}
          className={css({
            width: '60%'
          })}
          loadMore={() => {
            this.getMoreItems();
          }}
          hasMore={this.state.items.length < this.state.itemCount }
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        >
          <Table
            template={ItemTemplate}
            data={this.state.items || []}
            onRowClick={this.modifyItem}
          >
          </Table>
        </InfiniteScroll>
        <div
          style={{
            position: 'fixed',
            right: 30,
            bottom: 100,
            zIndex: 5,
          }}
        >
          <FloatingActionButton
            css={() => ({ background: '#ed5851' })}
            icon={<OrderIcon/>}
            onClick={this.downloadSheet}
          />
        </div>
        <div
          style={{
            position: 'fixed',
            right: 30,
            bottom: 30,
            zIndex: 5
          }}
        >
          <FloatingActionButton
            onClick={() => {
              this.setState({ adding: true });
            }}
          />
        </div>
        <ModalStateController
          enterAnimationClassName="pop-in-down"
          exitAnimationClassName="pop-out-up"
          isOpen={this.state.adding}
          onModalClose={() => {
            this.setState({adding: false});
          }}
        >
          {
            ({getProps, closeModal}) => (
              <Adding
                {...getProps()}
                closeModal={() => {
                  this.refetchItems();
                  closeModal();
                }}>
              </Adding>
            )
          }
        </ModalStateController>
        <ModalStateController
          enterAnimationClassName="pop-in-down"
          exitAnimationClassName="pop-out-up"
          isOpen={this.state.modifying}
          onModalClose={() => {
            this.setState({modifying: false});
          }}
        >
          {
            ({getProps, closeModal}) => (
              <Modifying
                {...getProps()}
                closeModal={() => {
                  this.refetchItems();
                  closeModal();
                }}
                row={this.state.rowToModify}
              >
              </Modifying>
            )
          }
        </ModalStateController>
      </Wrapper>
    );
  }
}

class Adding extends React.Component {
  constructor() {
    super();

    const sizes = Object.entries(readableSizeColumns).map((thing) => {
      return { value: thing[0], label: thing[0] };
    });

    this.state = {
      items: [],
      item_id: '',
      stylenumber: '',
      color: '',
      shirtColor: '',
      location: '',
      number: 0,
      size: 'xs',
      sizes: sizes
    };
  }

  componentDidMount() {
    axios.get(`${BASE_URL}/api/inventory`)
      .then((res) => {
        this.setState({ items: res.data });
      });
  }

  addItem = () => {
    if(!this.state.size || !this.state.item_id || !this.state.number) {
      return;
    }

    axios.post(`${BASE_URL}/api/inventory/stock/item`, {
      item_id: this.state.item_id,
      number: this.state.number,
      size: this.state.size,
      color: this.state.shirtColor,
      location: this.state.location
    })
      .then(() => {
        this.props.closeModal();
      });
  };

  render() {
    const {
      items
    } = this.state;
    return(
      <ModalWrapper {...this.props}>
        <h2
          style={{ margin: '8px' }}
        >Add Overstock Item</h2>
        <FlexWrapper>
          <Column>
            <DataHeader>Item:</DataHeader>
            {!this.state.item_id ?
              <FilterableList
                items={items}
                onSubmit={() => {}}
                filter={(item, filter) =>
                  item.stylenumber.toLowerCase().includes(filter)
                }
                maxHeight={400}
                ref={list => {
                  this.list=list;
                }}
                renderItem={({
                  item,
                  index,
                }) => (
                  <RowWrapper
                    key={index}
                    onClick={() => {
                      this.setState({
                        stylenumber: item.stylenumber,
                        color: item.color,
                        item_id: item.inventory_item_id
                      });
                    }}
                  >
                    <p style={{ margin: '6px' }}>{item.stylenumber}|{item.color}</p>
                  </RowWrapper>
                )}
              /> :
              <>
                <p>{this.state.stylenumber}|{this.state.color}</p>
                <Button
                  cssOverrides={{ color: 'red' }}
                  onClick={() => { this.setState({ item_id: '' }); }}
                >x</Button>
              </>
            }
          </Column>
          <Column>
            <Select
              label="Size"
              options={this.state.sizes}
              onChange={(e) => {
                this.setState({ size: e.target.value });
              }}
              value={this.state.size}
            />
            <TextInput
              label="Color"
              value={this.state.shirtColor}
              onChange={(e) => {
                this.setState({ shirtColor: e.target.value });
              }}
            />
            <TextInput
              label="Location"
              value={this.state.location}
              onChange={(e) => {
                this.setState({ location: e.target.value });
              }}
            />
            <TextInput
              label="# of Items"
              value={this.state.number}
              onChange={(e) => {
                this.setState({ number: e.target.value });
              }}
              number={true}
            />
            <Button
              styles={{ alignSelf: 'flex-end' }}
              onClick={this.addItem}
            >Submit</Button>
          </Column>
        </FlexWrapper>
      </ModalWrapper>
    );
  }
}

class Modifying extends React.Component {
  constructor(props) {
    super();
    this.state = {
      number: props.row.number,
      location: props.row.location
    };
  }

  deleteItem = () => {
    axios.delete(`${BASE_URL}/api/inventory/stock/item`, {
      params: {
        id: this.props.row.id
      }
    })
      .then(() => {
        this.props.closeModal();
      });

  }

  modifyItem = () => {
    axios.put(`${BASE_URL}/api/inventory/stock/item`, {
      id: this.props.row.id,
      number: this.state.number,
      location: this.state.location
    })
      .then(() => {
        this.props.closeModal();
      });

  }
  render() {
    return(
      <ModalWrapper {...this.props}>
        <h2>Modify Stock</h2>
        <FlexWrapper>
          <Column>
            <DataHeader>Item:</DataHeader>
            <p>{this.props.row.inventory_item.stylenumber}|{this.props.row.inventory_item.color}</p>
            <DataHeader>Size:</DataHeader>
            <p>{this.props.row.size}</p>
          </Column>
          <Column>
            <TextInput
              label="In Stock:"
              number={true}
              onChange={(e) => { this.setState({ number: e.target.value }); }}
              value={this.state.number}
            />
            <TextInput
              label="Location:"
              onChange={(e) => { this.setState({ location: e.target.value }); }}
              value={this.state.location}
            />
            <Button
              styles={{ backgroundColor: 'red', alignSelf: 'flex-end', margin: '4px' }}
              onClick={this.deleteItem}
            >Delete</Button>
            <Button
              styles={{ alignSelf: 'flex-end' }}
              onClick={this.modifyItem}
            >Submit</Button>
          </Column>
        </FlexWrapper>
      </ModalWrapper>
    );
  }
}

const DataHeader = styled('h4')({
  margin: '0px',
  marginBott: '4px'
});

const ModalWrapper = styled('div')({
  position: 'fixed',
  top: 50,
  background: '#fff',
  zIndex: 88,
  width: '50%',
  left: '25%',
  borderRadius: '4px',
  padding: '20px'
}, ({theme}) => ({
  boxShadow: theme.shadows.d2
}));

const FlexWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px'
});
