import store from './store';
import { history } from './index';
import Axios from 'axios';

import parseJwt from '@utils/auth/jwt/parseJwt.js';

const getUrl = window.location;
export const BASE_URL =
  getUrl.protocol + '//' + getUrl.host + '/' === 'http://localhost:3000/'
    ? 'http://localhost:3001'
    : getUrl.protocol + '//' + getUrl.host;
// BASE_URL = 'https://stage.badgerscreenprinting.com'
// BASE_URL = 'https://admin.badgerscreenprinting.com'

export const credentialsHeader = () => {
  const token = localStorage.getItem('token');
  return {
    credentials:
      getUrl.protocol + '//' + getUrl.host !== BASE_URL
        ? 'include'
        : 'same-origin',
    'x-access-token': token ? `${token}` : '',
    crossDomain: true,
  };
};

const axios = Axios.create({
  baseURL: `${BASE_URL}/api`,
});

axios.defaults.headers.common['x-access-token'] = credentialsHeader()['x-access-token'];
export { axios };

export const createHistory = (event) => {
  axios.post('history', {
    ...event
  })
    .catch(() => {
      if(!this) return;
      this.props.dispatch({
        type: 'ERROR/SET',
        error: 'There was an error trying to add to the history.'
      });
    });
};

export const serverSentEventSub = new EventSource(`${BASE_URL}/updates`);

export const refreshAuth = () => {
  return new Promise((res) => {
    Axios({
      method: 'GET',
      url: `${BASE_URL}/api/auth/refresh`,
      crossDomain: true,
      ...credentialsHeader, // include, same-origin, *omit
    })
      .then(response => {
        if (response.data.auth) {
          const token = response.data.token
            ? response.data.token
            : window.localStorage.getItem('token');
          const { user_account_id, user_group_id } =
            response.data && response.data.user
              ? response.data.user
              : parseJwt(token);

          console.log('good request:', user_account_id, user_group_id);
          if (token) window.localStorage.setItem('token', token);
          store.dispatch({
            type: '@@APP/SET_USER_ACCOUNT_ID',
            user_account_id: user_account_id,
            user_group_id: user_group_id,
          });
        }
        res(response.token);
        // setTimeout(() => {
        //   history.push('/login')
        // }, 30)
      })
      .catch(error => {
        localStorage.setItem('token', '');
        res('failed to refresh.');
        setTimeout(() => {
          history.push('/login');
        }, 30);
        console.log(error.message ? error.message : 'failed to refresh.');
      });
  });
};

