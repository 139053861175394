import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import format from 'date-fns/format';
import { Button } from './Controls';
import ModalStateController from './ModalStateController';
import Lines from './Collections/StaticLines';
import { getFilteredTemplate } from '../data/linesTemplates';
import v3Template from '@components/Reusable/data/DataGrid/template.js';
import DataGrid from '@components/Reusable/data/DataGrid/DataGrid.js';
import { testWorkCenters } from '../testData';
import ImageFallback from './ImageFallback';
import { BASE_URL } from '../api';
import { axios } from '../api.js';
import userPlaceholder from '../common/img/user_placeholder_white.svg';

export class HistoryModal extends React.Component {
  render() {
    const { isOpen, onModalClose, document_id } = this.props;
    return (
      <ModalStateController
        enterAnimationClassName="fade-in"
        exitAnimationClassName="fade-out"
        isOpen={isOpen}
        onModalClose={onModalClose}
        shadeStyle={{ background: 'rgba(0,0,0,.5)' }}
      >
        {({ getProps, closeModal }) => (
          <ScrollWrapper
            {...getProps()}
            onClick={() => {
              closeModal();
            }}
          >
            <ModalWrapper
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <ConnectedHistory document_id={document_id} />
            </ModalWrapper>
          </ScrollWrapper>
        )}
      </ModalStateController>
    );
  }
}

const ScrollWrapper = styled('div')({
  overflowY: 'auto',
  width: '100%',
  padding: 30,
  zIndex: 99,
  maxHeight: '100vh',
});

const ModalWrapper = styled('div')(({ theme }) => ({
  background: '#fff',
  borderRadius: 15,
  width: '100%',
  maxWidth: 1200,
  padding: 30,
  margin: '0 auto',
  boxShadow: theme.shadows.d3,
}));

export class TypedHistory extends React.Component {
  constructor() {
    super();
    this.state = {
      histories: []
    };
  }

  componentDidMount() {
    this.getHistory();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.document_id !== this.props.document_id) {
      this.getHistory();
    }
  }

  getHistory = () => {
    axios.get('/history', {
      params: {
        document_id: this.props.document_id,
        type: this.props.type
      }
    })
      .then((res) => {
        this.setState({ histories: res.data.result }, () => {
          this.forceUpdate();
        });
      });
  };

  render() {
    return (
      (this.state.histories.length) ?
        <History events={this.state.histories} /> :
        <p>No History</p>
    );
  }
}

export class ConnectedHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: []
    };
  }

  componentDidMount() {
    axios.get('/history', {
      params: {
        document_id: this.props.document_id
      }
    })
      .then((res) => {
        this.setState({ history: res.data.result });
      });
  }

  render() {
    return <History events={this.state.history} />;
  }
}

const HistoryWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

class History extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }

  componentDidMount() {
    axios.get('/users', {
      params: {
        active: false
      }
    })
      .then((res) => {
        this.setState({ users: res.data.results });
      });
  }


  render() {
    const { events = [] } = this.props;
    return (
      <HistoryWrapper>
        {events.map((e, i) => {
          return (<Event {...e} docKey={e.key} key={i} users={this.state.users} />);
        })}
      </HistoryWrapper>
    );
  }
}

const EventWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  paddingBottom: 34,
  position: 'relative',
  '&:before': {
    content: '" "',
    position: 'absolute',
    width: 6,
    height: '100%',
    background: theme.blue.color,
    left: 25,
    top: 10,
    zIndex: 0,
  },
  '&:last-child': {
    paddingBottom: 0,
    '&:before': {
      display: 'none',
    },
  },
}));

const LabelValue = styled('span')(({ theme }) => ({
  color: theme.blue.color,
  fontWeight: 700,
}));

class Event extends React.Component {

  getChildrenByKey = () => {
    let value;
    switch (this.props.docKey) {
    case 'document_detail':
    case 'officenotes':
    case 'specialinstructions':
      return (
        <React.Fragment>
          {this.props.json_previous && (
            <React.Fragment>
              <GenericEvent value={this.props.json_previous} before />
              <br />
            </React.Fragment>
          )}
          {this.props.json && <GenericEvent value={this.props.json} after />}
        </React.Fragment>
      );
    case 'lines':
      if (
        (this.props.json || []).length >
        (this.props.json_previous || []).length
      ) {
        return null;
      }
      value = getLinesDiff(this.props.json_previous, this.props.json);
      return <EventTable diff={value} full={this.props.json} version={value?.some((line) => line.designId) ? 3 : 2} />;
    case 'updateRow':
      value = getLinesDiff(this.props?.json_previous?.lines, this.props?.json?.lines);
      return <EventTable diff={value} full={this.props?.json?.lines} version={value?.some((line) => line.designId) ? 3 : 2} />;
    case 'manualPayment':
      return <Lines
        allowNewLine
        lines={[
          {
            type: this.props.json.type,
            amount: '$' + this.props.json.amount,
            previousTotal: `$${this.props.json.previousTotal}`,
            newTotal: `$${this.props.json.newTotal}`,
            note: this.props.json.note
          }
        ]}

        template={[{
          id: 'type',
          label: 'Type',
          maxWidth: '15%'
        },
        {
          id: 'amount',
          label: 'Amount',
          maxWidth: '10%'
        },
        {
          id: 'note',
          label: 'Note',
        },
        {
          id: 'previousTotal',
          label: 'Previous Total',
          maxWidth: '10%'
        },
        {
          id: 'newTotal',
          label: 'New Total',
          maxWidth: '10%'
        }
        ]}
      />;
    case 'emailPayment':
      return <Lines
        lines={[
          {
            name: this.props.json.firstName + ' ' + this.props.json.lastName,
            amount: Number(this.props.json.amount).toFixed(2),
            balance: Number(this.props.json.newTotal).toFixed(2)
          }
        ]}

        template = {
          [
            {
              id: 'name',
              label: 'Name:',
              maxWidth: '33%'
            },
            {
              id: 'amount',
              label: 'Amount:',
              maxWidth: '33%'
            },
            {
              id: 'balance',
              label: 'New Balance:',
              maxWidth: '34%'
            }

          ]
        }
      />;
    case 'sentEmailPayment':
      return <>
        <Lines
          lines={[
            {
              to: this.props.json.to,
              cc: this.props.json.cc,
              amount: this.props.json.amount,
              note: this.props.json.note
            }
          ]}

          template={
            [
              {
                id: 'to',
                label: 'To:',
                maxWidth: '25%'
              },
              {
                id: 'cc',
                label: 'CC:',
                maxWidth: '20%'
              },
              {
                id: 'amount',
                label: 'Amount',
                maxWidth: '10%',
              },
              {
                id: 'note',
                label: 'Note',
              }
            ]
          }

        />
        { this.props.json.snapshot_path &&
          <a rel="noreferrer" href={BASE_URL + '/api/documentimages/rando_guid/' + this.props.json.snapshot_path} target="_blank" download>
            <Button css={{margin: '10px'}}>Download Snapshot</Button>
          </a>
        }
      </>;

    case 'sentEmailInvoice':
      return <>
        <Lines
          lines={[
            {
              to: this.props.json.to,
              cc: this.props.json.cc,
              note: this.props.json.note
            }
          ]}
          template={
            [
              {
                id: 'to',
                label: 'To:',
                maxWidth: '25%'
              },
              {
                id: 'cc',
                label: 'CC:',
                maxWidth: '25%'
              },
              {
                id: 'note',
                label: 'Note'
              }
            ]
          }
        />
        { this.props.json.snapshot_path &&
          <a rel="noreferrer" href={BASE_URL + '/api/documentimages/rando_guid/' + this.props.json.snapshot_path} target="_blank" download>
            <Button css={{margin: '10px'}}>Download Snapshot</Button>
          </a>
        }
      </>;

    case 'textContact':
    case 'inPersonContact':
    case 'voicemailContact':
    case 'emailContact':
    case 'phoneContact':
      return <Lines
        lines={[
          {
            date: this.props.json.date,
            note: this.props.json.note
          }
        ]}

        template={
          [
            {
              id: 'date',
              label: 'Date:',
              maxWidth: '15%',
            },
            {
              id: 'note',
              label: 'Note:'
            }
          ]
        }

      />;

    case 'sentEmailApproval':
      return <Lines
        allowNewLine
        lines={[
          {
            to: this.props.json.to,
            cc: this.props.json.cc,
            amount: this.props.json.amount,
            note: this.props.json.note
          }
        ]} 

        template={
          [
            {
              id: 'to',
              label: 'To:',
              maxWidth: '25%'
            },
            {
              id: 'cc',
              label: 'CC:',
              maxWidth: '20%'
            },
            {
              id: 'note',
              label: 'Note',
            }
          ]
        }
        
      />;
    case 'rejected':
    case 'approved':
      return <>
        <Lines
          allowNewLine
          lines={[
            {
              response: this.props.json.response,
              userNote: this.props.json.userNote
            }
          ]}

          template={
            [
              {
                id: 'response',
                label: 'Response',
                maxWidth: '20%'
              },
              {
                id: 'userNote',
                label: 'Response Note',
              }
            ]
          }
        />
        {this.props.json.snapshot_path &&
         <a rel="noreferrer" href={BASE_URL + '/api/documentimages/rando_guid/' + this.props.json.snapshot_path} target="_blank" download>
           <Button css={{margin: '10px'}}>Download Snapshot</Button>
         </a>
        }
      </>;

    default:
      return null;
    }
  }

  getValueLabelByValue = value => {
    switch (value) {
    case 1:
    case true:
      return 'on';
    case 0:
    case false:
      return 'off';
    default:
      return value;
    }
  }

  getLabelByKey = (key, value) => {
    value = this.getValueLabelByValue(value);
    switch (key) {
    case 'hasshipping':
      return (
        <React.Fragment>
            toggled shipping <LabelValue>{value}</LabelValue>
        </React.Fragment>
      );
    case 'ordertype':
      return (
        <React.Fragment>
            changed the order type to <LabelValue>{value}</LabelValue>
        </React.Fragment>
      );
    case 'priority':
      return (
        <React.Fragment>
            changed the priority to <LabelValue>{value}</LabelValue>
        </React.Fragment>
      );
    case 'isreorder':
      return (
        <React.Fragment>
            toggled reorder <LabelValue>{value}</LabelValue>
        </React.Fragment>
      );
    case 'isweborder':
      return (
        <React.Fragment>
            toggled weborder <LabelValue>{value}</LabelValue>
        </React.Fragment>
      );
    case 'ismodro':
      return (
        <React.Fragment>
            toggled modro <LabelValue>{value}</LabelValue>
        </React.Fragment>
      );
    case 'officenotes':
      return 'edited the office notes';
    case 'specialinstructions':
      return 'edited the production notes';
    case 'jobname':
      return (
        <React.Fragment>
            changed the job name to <LabelValue>{value}</LabelValue>
        </React.Fragment>
      );
    case 'duedate':
      if (this.props.json_previous) {
        return (
          <React.Fragment>
              changed the in hands date from{' '}
            <LabelValue>
              {format(this.props.json_previous, 'MM/DD/YYYY')}
            </LabelValue>{' '}
              to <LabelValue>{format(value, 'MM/DD/YYYY')}</LabelValue>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
              set the in hands date to{' '}
            <LabelValue>{format(value, 'MM/DD/YYYY')}</LabelValue>
          </React.Fragment>
        );
      }
    case 'lines':
      if (
        (this.props.json || []).length >
          (this.props.json_previous || []).length
      ) {
        return 'added a product line';
      }
      if (
        (this.props.json || []).length <
          (this.props.json_previous || []).length
      ) {
        return 'deleted a product line';
      }
      return 'edited the product lines';
    case 'document_detail':
      return (
        <React.Fragment>
            toggled a detail{' '}
          <LabelValue>{this.props.json ? 'on' : 'off'}</LabelValue>
        </React.Fragment>
      );
    case 'workcenter': {
      const prev = testWorkCenters[this.props.json_previous] || {};
      const next = testWorkCenters[this.props.json] || {};
      return (
        <React.Fragment>
            moved the order from&nbsp;
          <LabelValue style={{ color: prev.color }}>{prev.title}</LabelValue>
            &nbsp;to&nbsp;
          <LabelValue style={{ color: next.color }}>{next.title}</LabelValue>
        </React.Fragment>
      );
    }
    case 'createTag': {
      return (
        <React.Fragment>
            added the tag {this.props.json.title}
        </React.Fragment>
      );
    }
    case 'deleteTag': {
      return (
        <React.Fragment>
            deleted the tag {this.props.json.title}
        </React.Fragment>
      );
    }
    case 'archive': {
      return (
        <React.Fragment>
          <LabelValue>
            {this.props.json === 1 ? 'archived' : 'unarchived'}
          </LabelValue>{' '}
            this order
        </React.Fragment>
      );
    }
    case 'art': {
      return 'uploaded new art';
    }
    case 'manualPayment':
      return 'received a payment';
    case 'sentEmailPayment':
      return 'sent an online payment request';
    case 'sentEmailInvoice':
      return 'sent an email invoice';
    case 'deletedActiveEmail':
      return 'deleted a payment request';
    case 'emailPayment':
      return 'fulfilled the recent payment request';

    case 'phoneContact':
      return 'called the customer';
    case 'emailContact':
      return 'emailed the customer';
    case 'textContact':
      return 'texted the customer';
    case 'voicemailContact':
      return 'left a voicemail for the customer';
    case 'inPersonContact':
      return 'talked to the customer in person';


    case 'sentEmailApproval':
      return 'sent an approval email';
    case 'approved':
      return 'approved the order';
    case 'rejected':
      return 'rejected the order';
    case 'deletedEmail':
      return 'deleted an approval email';

    case 'createProductionCard':
      return 'added the workorder to the calendar with a due date of ' + format(this.props.json.date, 'MM/DD/YYYY');
    case 'updateProductionCard':
      return 'updated this workorder\'s due date to ' + format(this.props.json.date, 'MM/DD/YYYY');

    case 'colemanon':
      return 'toggled the Coleman order';
    case 'colemanLines':
      return 'edited the Coleman order';

    case 'pickupSent':
      return 'sent a pickup notification';

    case 'addRow':
      return 'added a line to Design' + this.props?.json?.id;
    case 'deleteRow':
      return 'deleted a row in Design ' + this.props?.json?.id;
    case 'updateRow':
      return 'updated some rows in Design ' + this.props?.json?.id;
    case 'addedDesign':
      return 'added a design';
    case 'deleteDesign':
      return 'deleted Design ' + this.props?.json?.id;

    default:
      return 'edited the order';
    }
  }

  render() {
    const { users, user_id, docKey, json, timestamp } = this.props;
    const user = users.find((thing) => {
      return thing.user_account_id == user_id?.toString();
    });

    const userFullName = user ? 
      user?.first_name + ' ' + user?.last_name
      : 'An anonymous user';

    const children = this.getChildrenByKey();
    return (
      <EventWrapper>
        <UserRow
          user_account_id={user_id}
          profilepicture={
            user
              ? user.profilepicture
              : null
          }
          name={userFullName}
          label={this.getLabelByKey(docKey, json)}
          timestamp={timestamp}
        />
        {children && (
          <div
            className={css({
              paddingLeft: 60,
            })}
          >
            {children}
          </div>
        )}
      </EventWrapper>
    );
  }
}

const ProfileImg = styled('img')(({ theme }) => ({
  width: 55,
  height: 55,
  borderRadius: '100%',
  border: `5px solid ${theme.blue.color}`,
  background: theme.blue.color,
}));

const EventName = styled('span')(({ theme }) => ({
  fontWeight: 700,
  color: theme.neutral.extraDark,
}));

const EventLabel = styled('span')(({ theme }) => ({
  color: theme.neutral.color,
  fontWeight: 500,
}));

const EventDate = styled('span')(() => ({
  fontSize: 13,
  fontStyle: 'italic',
  fontWeight: 600,
  marginTop: 2,
}));

const EventTime = styled('span')(({ theme }) => ({
  color: theme.neutral.color,
}));

const UserRow = ({ name, label, timestamp, profilepicture }) => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'row',
      fontSize: 20,
      position: 'relative',
      zIndex: 9,
    })}
  >
    <ImageFallback fallback={<ProfileImg src={userPlaceholder} />}>
      {props => (
        <ProfileImg
          {...props}
          src={
            profilepicture
              ? `${BASE_URL}/api/documentimages/moneymaker${profilepicture}`
              : userPlaceholder
          }
          alt="Profile Picture"
        />
      )}
    </ImageFallback>
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: 7,
      })}
    >
      <div className={css({})}>
        <EventName>{name || 'An anonymous user'}&nbsp;</EventName>
        <EventLabel>{label}</EventLabel>
      </div>
      <EventDate>
        {format(new Date(timestamp), 'dddd, MMMM D, YYYY')}&nbsp;
        <EventTime>at {format(new Date(timestamp), 'h:mm a')}</EventTime>
      </EventDate>
    </div>
  </div>
);

const GenericEventWrapper = styled('div')(({ theme, before, after }) => ({
  background: before
    ? 'rgb(255, 170, 175)'
    : after
      ? 'rgb(176, 255, 170)'
      : theme.vibrant.alpha1,
  borderRadius: 8,
  padding: 5,
  fontSize: 13,
  display: 'inline-block',
  textDecoration: before ? 'line-through' : 'none',
  marginBottom: 5,
  '&:last-child': {
    marginBottom: 'none',
  },
}));

const GenericEvent = ({ value, before, after }) => (
  <GenericEventWrapper before={before} after={after}>
    {value}
  </GenericEventWrapper>
);

const getLinesDiff = (prev, current) => {
  prev = prev || [];
  current = current || [];
  const addedRows = [];
  const deletedRows = [];
  prev.forEach(row => {
    if (!current.find(pr => pr.id === row.id)) {
      deletedRows.push(
        Object.keys(row).reduce((cells, cellId) => ({
          ...cells,
          [cellId]: <Diff before={row[cellId]} after="" />,
        }))
      );
    }
  });
  if (deletedRows.length > 0) {
    return deletedRows;
  }
  current.forEach(row => {
    if (!prev.find(pr => pr.id === row.id)) {
      addedRows.push(row);
    }
  });
  const editedRows = current.reduce((rows, row) => {
    const prevRow = prev.find(pr => pr.id === row.id);
    if (!prevRow) {
      return rows;
    }
    const editedCells = Object.keys(row).reduce((cells, cellId) => {
      const cell = row[cellId];
      if (cell !== prevRow[cellId]) {
        return {
          ...cells,
          [cellId]: <Diff before={prevRow[cellId]} after={cell} />,
        };
      }
      return cells;
    }, {});
    if (Object.keys(editedCells).length > 0) {
      return [...rows, { ...prevRow, ...editedCells, id: row.id }];
    }
    return rows;
  }, []);
  return editedRows;
};

const Diff = ({ before, after }) => (
  <div>
    {before !== '' && before !== null && (
      <span
        className={css({
          background: 'rgb(255, 170, 175)',
          borderRadius: 3,
          textDecoration: 'line-through',
          padding: '0px 3px',
          marginLeft: 0,
          margin: 2,
          display: 'inline-block',
        })}
      >
        {before}
      </span>
    )}
    {after !== '' && after !== null && (
      <span
        className={css({
          background: 'rgb(176, 255, 170)',
          borderRadius: 3,
          margin: 2,
          marginRight: 0,
          padding: '0px 3px',
          display: 'inline-block',
        })}
      >
        {after}
      </span>
    )}
  </div>
);

class EventTable extends React.Component {
  state = {
    showContext: false,
  }
  toggleContext = () => {
    this.setState(state => ({ showContext: !state.showContext }));
  }
  render() {
    const { diff, full, linesConfig, version = 1 } = this.props;
    return (
      <React.Fragment>
        { version < 3 ? <Lines
          lines={!this.state.showContext ? diff : full}
          template={getFilteredTemplate(linesConfig || {}, version).filter(
            t => t.id !== 'customprice'
          )}
        /> : 
          <DataGrid 
            rows={!this.state.showContext ? diff : full}
            autoCalculateHiddenColumns
            columns={v3Template} 
            readOnly={true}
            columnsHideable={false}
          />
        }
        <div
          className={css({
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: 5,
          })}
        >
          <button
            className={css({
              background: 'none',
              border: 'none',
              fontSize: 12,
              padding: 5,
              '&:hover': {
                background: 'rgba(3, 3, 3, 0.1)',
              },
            })}
            onClick={this.toggleContext}
          >
            {this.state.showContext ? 'Show diff' : 'Show Context'}
          </button>
        </div>
      </React.Fragment>
    );
  }
}
