import React, { useState, useEffect } from 'react';
import { axios } from '@/api.js';
import { history } from '@/index';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import TodayIcon from '@mui/icons-material/Today';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { startOfWeek, format } from 'date-fns';

import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';

export default function AddToCalendar(props) {
  const [ open, setOpen ] = useState(false);
  const [ anchor, setAnchor ] = useState(null);
  const [ date, setDate ] = useState(new Date(props.design.prod_date + ' 00:00:00')); //Add time to it otherwise it changes the date
  const [ printingTasks, setPrintingTasks] = useState([]);

  useEffect(() => {
    getPrintingTasks();
  }, []);

  function addToCalendar() {
    axios.put('/calendar/design/date', {
      id: props.design.id,
      date
    })
      .then(() => {
        if(!props.design.prod_date) {
          printingTasks.filter((task) => task.default).map((task) => {
            return axios.post('/calendar/design/task', {
              designId: props.design.id,
              name: task.name,
              quantity: props.design.itemQuantity,
              timing: task.timing,
              reportSymbol: task.reportSymbol
            });
          });
        }
        props.sideEffects();
        setOpen(false);
      });
  }

  function getPrintingTasks() {
    axios.get('/settings', {
      params: {
        type: 'calendar',
        sub_type: 'printing',
        sub_sub_type: 'tasks'
      }
    })
      .then((res) => {
        setPrintingTasks([...res.data?.result?.data]);
      });
  }

  function gotoCalendar() { 
    history.push('/calendar/' + format(startOfWeek(props.design.prod_date)) + '#' + props.design.id);

  }

  function removeFromCalendar() {
    axios.delete('/calendar/design/date', {
      params: {
        designId: props.design.id
      }
    })
      .then(() => {
        setDate(undefined);
      });
  }

  return (
    <>
      <Button
        onClick={(e) => {
          setOpen(true);
          setAnchor(e.currentTarget);
        }}
        variant={ !isNaN(date) ? 'contained' : 'outlined' }
      >{ !isNaN(date) ? props.design.prod_date : 'Add To Calendar'}</Button>
      { !isNaN(date?.valueOf()) && 
        <>
          <IconButton
            onClick={gotoCalendar}
          >
            <TodayIcon />
          </IconButton>
          <IconButton
            onClick={removeFromCalendar}
          >
            <EventBusyIcon />
          </IconButton>
        </>
      }
      <Popover
        open={open}
        anchorEl={anchor}
        onClose={() => { setOpen(false); }}
        PaperProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      >
        <DayPicker
          onDayClick={newDate => {
            setDate(newDate);
          }}
          selectedDays={date}
        />
        <Button
          onClick={addToCalendar}
          variant="contained"
        >Add To Calendar</Button>
      </Popover>
    </>
  );
}
