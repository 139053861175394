import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';

import Toolbar from './DataGrid.Toolbar.js';
import Headers from './DataGrid.Headers.js';
import Rows from './DataGrid.Rows.js';
import FloatingMenu from '../../../FloatingMenu';

import InsertBelowIcon from 'react-icons/lib/md/subdirectory-arrow-right';
import InsertAboveIcon from 'react-icons/lib/md/subdirectory-arrow-left';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from 'react-icons/lib/md/delete';

import copyToClipboard from 'clipboard-copy';

import  SheetClipModule from 'sheetclip';
const SheetClip = new SheetClipModule();

/**
 * Props:
 *   - drawerContent: ReactNode, will show in the drawer when it is expanded
 *   - columns: Array[], Template for the grid columns.
 *   - columnsHideable: boolean, If false, none of the columns will be hideable,
 *   - hiddenColumns: string[], an array of hidden column keys,
 *   - updateHiddenColumnsSideEffects: function(updatedHiddenColumns), a function that allows you to perform actions with the hidden columns are changed
 *   - onAddRow: function(newRow, newRowIndex), a function called when a row is added
 *   - onDeleteRow: function(deletedRowIndex, updatedRows), a function called when a row is deleted,
 *   - onChangeRow: funciton(updatedRowIndex, updatedRows), a functino called when a row is edited,
 *   - onChangeRowSideEffects: functino(oldRows, updatedRows), a function called when a row is edited TODO: FIND DIFFERENCE,
 *   - onChangeRowOrder: function(ids, sortOrders), a function called when a the order of the rows changed
 *   - autoSortFunction: function(row1, row2), a custom sort function for the autosort
 *   - updateMultipleRows: function(updatedRows), a function called when several rows are updated at the same time
 *   - rows: Array[], The actual array of data representing the rows
 *   - readOnly: boolean, If true, the entire grid will be uneditable
 *   - readOnlyRowClick: function(row), If the grid is readonly, you may click on it to 
 *   - sortableByHeader: boolean, Whether or not the headers can be clicked on to sort the data
 *   - onSortByHeader: function(header, direction), called when a header is clicked on and sortable by header is true
 *   - sortingBy: string, header id that the grid is being sorted by
 *   - sortDirection: 'DESC' | 'ASC', direction that the column is being sorted by
 *   - defaultKey: string, the default sort key that sorting should be returned to when not sorted
 *   - drawerInitial: boolean (optional), the initial state of the drawer. Defaults to closed (false).
 */

export default class DataGrid extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      hiddenColumns: props.hiddenColumns ?? [],
      menuOpen: false,
      menuPosition: { x: 20, y: 20 },
      activeMenuIndex: null,
      redraw: false,
      selectedCell: null,
      endSelectedCell: null,
      blurringSelectedCell: false,
      dragging: false,
      rows: (props.rows ? props.rows : []),
      columns: props.columns,
      drawerOpen: props.drawerInitial ?? false
    };

  }

  componentDidMount = () => {
    if(this.props.readOnly === true) {
      const readOnlyColumns = this.state.columns.map(column => {
        column.editable = false;
        return column;
      });

      this.setState({ columns: readOnlyColumns });
    }
  }

  componentDidUpdate = (prevState, prevProps) => {

    if (this.state.redraw) {
      this.setState({ redraw: false });
      this.forceRedraw();
    }

    const replacer = (key, value) => {
      return typeof value?.type === 'function' ? null : value;
    };

    if(JSON.stringify(this.props.rows, replacer) 
      !== JSON.stringify(prevProps.rows, replacer) 
      && this.props.rows !== undefined) {
      this.setState({ rows: this.props.rows });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('copy', this.copyCells);
  };

  addRow = () => {
    const newRow = this.getDefaultRow();
    newRow.sortOrder = this.getLargestSortOrder();

    const newRows = [
      ...this.state.rows,
      newRow
    ];

    this.setState({ rows: newRows });
    if(this.props.onAddRow) {
      this.props.onAddRow(newRow, newRows.length);
    } else if(this.props.onUpdate) {
      this.props.onUpdate(newRows);
    }
  };

  autoCalcHiddenColumns = () => {
    const set = new Set();
    this.state.rows.map(row => {
      Object.keys(row).map(key => {
        if(row[key]) set.add(key);
      });
    });
    return Array.from(set);
  }

  autoSort = () => {

    const rows = this.state.rows.sort(this.props.autoSortFunction);
    this.setState({ rows });

    let sortAccumulator = 0;
    if(this.props.onChangeRowOrder) {
      this.props.onChangeRowOrder(
        rows.map(row => row.id),
        rows.map(() => {
          sortAccumulator += 100;
          return sortAccumulator;
        })
      );
    } else if(this.props.onUpdate) {
      // TODO: Need to be able to complete auto sort when only provided an onUpdate function
      // Basically, the rows don't update their own sortorder yet
      console.error('Auto Sort like this is not Implemented');
    }
  };

  checkForClickOutside = (e) => {
    if(!this.container.contains(e.target)){
      this.deselectCell();
    }
  };

  closeRowMenu = () => {
    this.setState({ menuOpen: false, activeMenuIndex: null });
  };

  commitChange = ({ rowIndex, value, columnKey }) => {
    const oldRows = structuredClone(this.state.rows);
    const rows = this.state.rows;
    rows[rowIndex][columnKey] = value;

    if(this.props.onChangeRowSideEffects) {
      this.props.onChangeRowSideEffects(oldRows, rows);
    }

    this.setState({rows});
    if(this.props.onChangeRow) {
      this.props.onChangeRow(rowIndex, rows);
    } else if(this.props.onUpdate) {
      this.props.onUpdate(rows);
    }
  };

  copyCells = () => {
    if(!this.getSelectedRange()) return;
    const { origin, target } = this.getSelectedRange();
    const rows = this.state.rows;
    const copiedRows = [];
    const visibleColumns = this.getVisibleColumns();
    for (let i = origin.rowIndex; i <= target.rowIndex; i++) {
      const row = rows[i];
      const rowArray = [];
      for (let c = origin.cellIndex; c <= target.cellIndex; c++) {
        const cell = row[visibleColumns[c].key] || '';
        rowArray.push(cell);
      }
      copiedRows.push(rowArray);
    }
    const csv = SheetClip.stringify(copiedRows);
    copyToClipboard(csv);
  };

  copyOneCell = () => {
    /*
    const { rowIndex, cellIndex } = this.state.selectedCell;
    const row = this.state.rows[rowIndex];
    const value = row[this.getVisibleColumns()[cellIndex].key];
    */
    const value = window.getSelection().toString();
    window.removeEventListener('copy', this.copyOneCell);

    copyToClipboard(value);
  }

  deleteCells = e => {
    if(e.key === 'Delete') {
      const { origin, target } = this.getSelectedRange();
      const rows = this.state.rows;
      const visibleColumns = this.getVisibleColumns();

      const updatedRows = [];
      for (let rowIndex = origin.rowIndex; rowIndex <= target.rowIndex; rowIndex++) {
        for(let column = origin.cellIndex; column <= target.cellIndex; column++) {
          rows[rowIndex][visibleColumns[column].key] = '';
        }
        updatedRows.push(rows[rowIndex]);
      }

      this.setState({ rows });
      if(this.props.updateMultipleRows) {
        this.props.updateMultipleRows(rows);
      } else if(this.props.onUpdate) {
        this.props.onUpdate(rows);
      }
    }
  };

  deleteRow = () => {
    const activeMenuIndex = this.state.activeMenuIndex;
    const rows = [...this.state.rows.slice(0, activeMenuIndex), ...this.state.rows.slice(activeMenuIndex + 1)];
    this.setState({rows});
    if(this.props.onDeleteRow) {
      this.props.onDeleteRow(activeMenuIndex, rows);
    } else if(this.props.onUpdate) {
      this.props.onUpdate(rows);
    }
  };


  deselectCell = () => {
    this.setState({ selectedCell: null, endSelectedCell: null });
    window.removeEventListener('copy', this.copyOneCell);
    window.removeEventListener('copy', this.copyCells);
    window.removeEventListener('keyup', this.deleteCells);
    document.removeEventListener('mousedown', this.checkForClickOutside);
  };

  endDrag = () => {
    this.toggleDragging(false);
  };

  extendSelection = endSelectedCell => {
    this.setState({ endSelectedCell, blurringSelectedCell: true }, () => {
      document.activeElement.blur();
      this.setState({ blurringSelectedCell: false });
    });
    window.removeEventListener('copy', this.copyOneCell);
    window.addEventListener('copy', this.copyCells);
    window.addEventListener('keyup', this.deleteCells);
    document.addEventListener('mousedown', this.checkForClickOutside);
  };

  forceRedraw = () => {
    this.table.style.display = 'none';
    const temp = this.table.offsetHeight; // eslint-disable-line
    this.table.style.display = '';
  };

  getLesserOf = (a, b) => {
    if (a < b) {
      return { lesser: a, greater: b };
    } else {
      return { lesser: b, greater: a };
    }
  };

  getDefaultRow = () => {
    return this.state.columns.reduce((line, col) => {
      line[col.key] = col?.default;
      return line;
    }, {});
  };

  getLargestSortOrder = () => {
    return this.state.rows.reduce((sort, row) => {
      if(row.sortOrder >= sort) {
        sort = row.sortOrder + 100;
      }

      return sort;
    }, 0);
  }

  getSelectedRange = () => {
    if(!this.state.selectedCell || !this.state.endSelectedCell) return null;

    const cellIndices = this.getLesserOf(
      this.state.selectedCell.cellIndex,
      this.state.endSelectedCell.cellIndex
    );

    const rowIndicies = this.getLesserOf(
      this.state.selectedCell.rowIndex,
      this.state.endSelectedCell.rowIndex
    );

    return {
      origin: { cellIndex: cellIndices.lesser, rowIndex: rowIndicies.lesser },
      target: { cellIndex: cellIndices.greater, rowIndex: rowIndicies.greater }
    };
  };

  insertAbove = () => {
    const activeMenuIndex = this.state.activeMenuIndex;
    const newRow = this.getDefaultRow();
    let sortValue;
    if(activeMenuIndex > 0) {
      sortValue =
        this.state.rows[activeMenuIndex].sortOrder -
        Math.floor(
          ((this.state.rows[activeMenuIndex].sortOrder) -
           (this.state.rows[activeMenuIndex - 1].sortOrder)) /
            2
        );
    } else {
      sortValue = this.state.rows[0].sortOrder - 100;
    }

    newRow.sortOrder = sortValue;
    const rows = [
      ...this.state.rows.slice(0, activeMenuIndex),
      newRow,
      ...this.state.rows.slice(activeMenuIndex)
    ];

    this.setState({
      redraw: true,
      rows
    });

    if(this.props.onAddRow) {
      this.props.onAddRow(newRow, activeMenuIndex);
    } else if(this.props.onUpdate) {
      this.props.onUpdate(rows);
    }
  };

  insertBelow = () => {
    const activeMenuIndex = this.state.activeMenuIndex;
    const newRow = this.getDefaultRow();

    let sortValue;
    if(activeMenuIndex < this.state.rows.length - 1) {
      sortValue =
        this.state.rows[activeMenuIndex].sortOrder +
        Math.floor(
          ((this.state.rows[activeMenuIndex + 1].sortOrder) -
           (this.state.rows[activeMenuIndex].sortOrder)) /
            2
        );
    } else {
      sortValue = this.getLargestSortOrder();
    }

    newRow.sortOrder = sortValue;
    const rows = [
      ...this.state.rows.slice(0, activeMenuIndex + 1),
      newRow,
      ...this.state.rows.slice(activeMenuIndex + 1)
    ];

    this.setState({
      rows
    });

    if(this.props.onAddRow) {
      this.props.onAddRow(newRow, activeMenuIndex + 1);
    } else if(this.props.onUpdate) {
      this.props.onUpdate(rows);
    }
  };

  moveDown = () => {
    const activeMenuIndex = this.state.activeMenuIndex;

    let sortValue;
    if(activeMenuIndex < this.state.rows.length - 2) {
      sortValue =
        this.state.rows[activeMenuIndex + 1].sortOrder +
        Math.floor(
          ((this.state.rows[activeMenuIndex + 2].sortOrder) -
           (this.state.rows[activeMenuIndex + 1].sortOrder)) /
            2
        );
    } else {
      sortValue = this.getLargestSortOrder();
    }

    const newLines = this.state.rows;
    const movedLine = newLines[activeMenuIndex];
    movedLine.sortOrder = sortValue;
    [newLines[activeMenuIndex], newLines[activeMenuIndex + 1]] = [newLines[activeMenuIndex + 1], movedLine];

    this.setState({
      redraw: true,
      rows: newLines
    });

    if(this.props.onChangeRowOrder) {
      this.props.onChangeRowOrder([movedLine.id], [sortValue], newLines);
    } else if(this.props.onUpdate) {
      this.props.onUpdate(newLines);
    }
  };

  moveUp = () => {
    const activeMenuIndex = this.state.activeMenuIndex;

    let sortValue;
    if(activeMenuIndex > 1) {
      sortValue =
        this.state.rows[activeMenuIndex - 1].sortOrder -
        Math.floor(
          ((this.state.rows[activeMenuIndex - 1].sortOrder) -
           (this.state.rows[activeMenuIndex - 2].sortOrder)) /
            2
        );
    } else {
      sortValue = this.state.rows[0].sortOrder - 100;
    }

    const newLines = this.state.rows;
    const movedLine = newLines[activeMenuIndex];
    movedLine.sortOrder = sortValue;
    [newLines[activeMenuIndex], newLines[activeMenuIndex - 1]] = [newLines[activeMenuIndex - 1], movedLine];

    this.setState({
      redraw: true,
      rows: newLines
    });

    if(this.props.onChangeRowOrder) {
      this.props.onChangeRowOrder([movedLine.id], [sortValue], newLines);
    } else if(this.props.onUpdate) {
      this.props.onUpdate(newLines);
    }
  };

  openRowMenu = ({ menuPosition, activeMenuIndex }) => {
    this.setState({ menuOpen: true, menuPosition, activeMenuIndex });
  };

  pasteCells = ({ rowIndex, cellIndex, value }) => {
    let rows = [...this.state.rows];

    const paste = SheetClip.parse(value);
    const updatedRows = [];
    paste.forEach((row, i) => {
      const ri = rowIndex + i;
      if (rows[ri]) {
        let rowWasUpdated = false;
        row.forEach((cell, c) => {
          const ci = cellIndex + c;
          if (this.getVisibleColumns()[ci]) {
            const updatedRow = { ...rows[ri], [this.getVisibleColumns()[ci].key]: cell };
            rows = [
              ...rows.slice(0, ri),
              updatedRow,
              ...rows.slice(ri + 1)
            ];
            rowWasUpdated = true;
          }
        });
        if(rowWasUpdated) { updatedRows.push(rows[ri]); }
      }
    });

    this.setState({ rows });
    if(this.props.updateMultipleRows) this.props.updateMultipleRows(rows);
    // TODO: The paste doesn't work at all
  };

  selectCell = selectedCell => {
    window.addEventListener('copy', this.copyOneCell);
    this.setState({ selectedCell, endSelectedCell: null });
  };

  toggleDragging = dragging => {
    this.setState({ dragging });
    if (dragging === true) {
      window.addEventListener('mouseup', this.endDrag);
    } else {
      window.removeEventListener('mouseup', this.endDrag);
    }
  };

  updateHiddenColumns = (hiddenColumns) => {

    const rows = this.state.rows.map(row => {
      const newRow = {...row};
      hiddenColumns.map(hidden => {
        newRow[hidden] = '';
      });
      return newRow;
    });

    this.setState({ hiddenColumns });
    this.setState({rows});

    if(this.props.updateMultipleRows) {
      this.props.updateMultipleRows(rows);
    } else if(this.props.onUpdate) {
      this.props.onUpdate(rows);
    }

    if(this.props.updateHiddenColumnsSideEffects) {
      this.props.updateHiddenColumnsSideEffects(hiddenColumns);
    }
  };

  getVisibleColumns = () => {
    let hiddenColumns = this.state.hiddenColumns;
    if(this.props.autoCalculateHiddenColumns && this.props.readOnly && !this.props.columnsHideable) {
      hiddenColumns = this.autoCalcHiddenColumns();
      return this.state.columns.filter(col => hiddenColumns.includes(col.key));
    }

    return this.state.columns.filter(col => !hiddenColumns.includes(col.key));
  }

  render() {
    const {
      columnsHideable = true,
    } = this.props;
    const {
      hiddenColumns = [],
      rows = [],
      columns
    } = this.state;
    const visibleColumns = this.getVisibleColumns();

    return (
      <Container
        id={this.props.rootId}
        ref={r=>{
          this.container = r;
        }}
      >
        <div
          id="__hidden-drag-image"
          className={css({
            opacity: 0,
            width: 1,
            height: 1,
            position: 'fixed',
            left: -1,
            top: -1
          })}
        />
        <Toolbar
          columns={columns}
          hiddenColumns={hiddenColumns}
          updateHiddenColumns={this.updateHiddenColumns}
          columnsHideable={columnsHideable}
          hideAutoSort={!this.props.autoSortFunction}
          autoSort={this.autoSort}
          addRow={this.addRow}
          toggleDrawer={() => { this.setState({ drawerOpen: !this.state.drawerOpen });}}
          drawerText="Customizations"
          drawerContent={this.props.drawerContent}
          readOnly={this.props.readOnly}
        />
        <TableWrapper
          ref={r => {
            this.table = r;
          }}
        >
          <Headers
            columns={visibleColumns}
            sortableByHeader={this.props.sortableByHeader}
            onSortByHeader={this.props.onSortByHeader}
            sortingBy={this.props.sortingBy}
            sortDirection={this.props.sortDirection}
            defaultKey={this.props.defaultKey}
          />
          <tbody>
            <tr>
              <td
                colSpan="100%"
                style={{ padding: 0 }}
              >
                <ExpandingDrawer 
                  expanded={this.state.drawerOpen}
                >{this.props.drawerContent}</ExpandingDrawer>
              </td>
            </tr>
          </tbody>
          <Rows
            columns={visibleColumns}
            rows={rows}
            commitChange={this.commitChange}
            openRowMenu={this.openRowMenu}
            selectCell={this.selectCell}
            deselectCell={this.deselectCell}
            extendSelection={this.extendSelection}
            blurringSelectedCell={this.state.blurringSelectedCell}
            pasteCells={this.pasteCells}
            toggleDragging={this.toggleDragging}
            dragging={this.state.dragging}
            selectedRange={
              this.state.selectedCell && this.state.endSelectedCell
                ? this.getSelectedRange()
                : null
            }
            readOnly={this.props.readOnly}
            readOnlyRowClick={this.props.readOnlyRowClick}
          />
        </TableWrapper>
        <FloatingMenu
          isOpen={this.state.menuOpen}
          style={{
            left: this.state.menuPosition.x,
            top: this.state.menuPosition.y,
            position: 'fixed',
            zIndex: 9
          }}
          onModalClose={this.closeRowMenu}
          buttons={[
            (this.state.activeMenuIndex != 0) && {
              label: (
                <LabelWrapper
                  className={css({
                    '& svg': {
                      display: 'inline-block'
                    }
                  })}
                >
                  <ArrowUpwardIcon />
                  Move Up
                </LabelWrapper>
              ),
              onClick: this.moveUp
            },
            (rows && this.state.activeMenuIndex != rows.length - 1) && {
              label: (
                <LabelWrapper
                  className={css({
                    '& svg': {
                      transform: 'rotate(180deg)',
                      display: 'inline-block'
                    }
                  })}
                >
                  <ArrowDownwardIcon />
                  Move Down
                </LabelWrapper>
              ),
              onClick: this.moveDown
            },
            {
              label: (
                <LabelWrapper
                  className={css({
                    '& svg': {
                      transform: 'rotate(180deg)',
                      display: 'inline-block'
                    }
                  })}
                >
                  <InsertAboveIcon />
                  Insert Above
                </LabelWrapper>
              ),
              onClick: this.insertAbove
            },
            {
              label: (
                <LabelWrapper>
                  <InsertBelowIcon /> Insert Below
                </LabelWrapper>
              ),
              onClick: this.insertBelow
            },
            {
              label: (
                <LabelWrapper danger>
                  <span className={css({ paddingBottom: 2 })}>
                    <DeleteIcon />
                  </span>{' '}
                  Delete Row
                </LabelWrapper>
              ),
              onClick: this.deleteRow
            }
          ]}
        />
      </Container>
    );
  }
}

const LabelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}, ({ theme, danger }) => ({
  color: danger ? theme.danger.dark : ''
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  fontFamily: theme.primary.fontFamily
}));

const TableWrapper = styled('table')({
  borderCollapse: 'collapse',
  width: '100%'
});

const ExpandingDrawer = styled('div')(({ theme, expanded }) => ({
  maxHeight: expanded ? '1000px' : 0,
  overflow: 'hidden',
  width: '100%',
  transition: 'max-height .5s, border-width .2s',
  border: 'solid rgb(189, 189, 189)',
  borderWidth: expanded ? '1px' : 0,
  columnSpan: '100%'
}));
