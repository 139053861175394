import React from 'react';
import axios from 'axios';
import { BASE_URL } from '../api';
import { Button, TextInput, ConfirmationModal } from './Controls';
import styled from '@emotion/styled';
import Modal from './Modal';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteIcon from '@material-ui/icons/Delete';
/**
 * Board
 *
 * This is a board with a set of lists similar to the gitlab issue boards.
 *
 * props:
  * name - Used to retrieve the board lists, and their list items. Once the name has been set,
    * the same table can be retrieved by using the same table name elsewhere.
  * addingModal - Used to change the way that items are added. It's essentially for adding an
    * object to the data field in the database. It has access to a "submit" method through the props
    * which accept an itemName String and an itemData Object
  * dataModal - Used to display and edit the data in the data object in the database
 * */
export default class Board extends React.Component {
  constructor() {
    super();
    this.state = {
      board_id: 0,
      dragging: false,
      lists: [],
      listOrder: [],
      addingList: false,
      newList: ''
    };
  }

  componentDidMount() {
    this.getBoard();
  }

  addList = (listName) => {
    console.log(this.state);
    axios.post(`${BASE_URL}/api/board/list`, {
      boardId: this.state.board_id,
      listName,
      oldListOrder: this.state.listOrder
    })
      .then(() => {
        this.getBoard();
      });
  };

  dropOnTrash = (e) => {
    const dragged_type = e.dataTransfer.getData('type');
    if(dragged_type === 'list') {
      const dragged_list_id = e.dataTransfer.getData('list_id');

      axios.delete(`${BASE_URL}/api/board/list`, {
        params: {
          listId: dragged_list_id
        }
      })
        .then(() => {
          this.getBoard();
        });
    } else if(dragged_type === 'item'){
      const dragged_item_id = e.dataTransfer.getData('item_id');
      const listItemOrder = JSON.parse(e.dataTransfer.getData('og_list_item_order'));
      const oldListId = e.dataTransfer.getData('og_list_id');

      axios.delete(`${BASE_URL}/api/board/item`, {
        params: {
          itemId: dragged_item_id,
          oldListItemOrder: listItemOrder,
          oldListId
        }
      })
        .then(() => {
          this.getBoard();
        });
    }

    this.stopDrag();

  };

  getBoard = () => {
    axios.get(`${BASE_URL}/api/board`, {
      params: {
        name: this.props.name
      }
    })
      .then((res) => {
        this.setState({
          board_id: res.data.result.id,
          lists: res.data.result.lists || [],
          listOrder: res.data.result.list_order || []
        });
      });
  };

  orderLists = (lists) => {
    const listOrder = this.state.listOrder;
    return lists.sort((a, b) => {
      return listOrder.indexOf(a.id) - listOrder.indexOf(b.id);
    });
  };

  startDrag = () => {
    this.setState({ dragging: true });
  };

  stopDrag = () => {
    this.setState({ dragging: false});
  };

  render() {

    const {
      lists,
      addingList,
    } = this.state;

    return (
      <>
        { (lists.length) ? (
          <Row
            style={{
              height: '60px',
              justifyContent: 'space-between'
            }}
          >
            <Button
              onClick={() => {
                this.setState({ addingList: true });
              }}
            >Add List</Button>
            {this.state.dragging ?
              <DeleteForeverIcon
                color="error"
                fontSize="large"
                onDragOver={(e) => {e.preventDefault();}}
                onDrop={(e) => { this.dropOnTrash(e); }}
              /> :
              <DeleteIcon
                color="error"
                fontSize="large"
                onDragOver={(e) => {e.preventDefault();}}
                onDrop={(e) => { this.dropOnTrash(e); }}
              />}
          </Row>
        ) : (
          <Row
            style={{
              height: '60px',
            }}
          >
            <Button
              onClick={() => {
                this.setState({ addingList: true });
              }}
            >Add List</Button>
          </Row>
        )}
        <Row
          style={{
            height: '100%',
            width: 'auto'
          }}
        >
          {this.orderLists(lists).map((list, i) => {
            return (
              <List
                key={i}
                list={lists[i]}
                getBoard={this.getBoard}
                listOrder={this.state.listOrder}
                startDrag={this.startDrag}
                stopDrag={this.stopDrag}
                addModal={this.props.addModal}
                addModalName={this.props.addModalName}
                modalWidth={this.props.modalWidth}
                viewModal={this.props.viewModal}
              />
            );
          })}
        </Row>
        <Modal
          label="List Name"
          maxWidth="350px"
          isOpen={addingList}
          onModalClose={() => {
            this.setState({ addingList: false });
          }}
          element={AddModal}
          submit={(listName) => {
            this.addList(listName);
          }}
        />
      </>
    );
  }
}

class List extends React.Component {

  constructor() {
    super();
    this.state = {
      addingItem: false,
      deletingList: false
    };
  }

  addItem = (itemName, itemData) => {
    axios.post(`${BASE_URL}/api/board/item`, {
      listId: this.props.list.id,
      itemName,
      oldItemOrder: this.props.list.item_order ||  [],
      data: itemData
    })
      .then(() => {
        this.props.getBoard();
      });
  };

  delete = () => {
    axios.delete(`${BASE_URL}/api/board/list`, {
      params: {
        listId: this.props.list.id
      }
    })
      .finally(() => {
        this.setState({ deletingList: false });
        this.props.getBoard();
      });
  };

  drop = (e) => {
    const list = this.props.list;
    const dragged_type = e.dataTransfer.getData('type');
    if(dragged_type === 'list') {
      const dragged_list_id = e.dataTransfer.getData('list_id');
      const list_order = this.props.listOrder;
      // This inserts the dragged list in the place of the list that it was dropped on
      list_order.splice(list_order.indexOf(list.id), 0, list_order.splice(list_order.indexOf(dragged_list_id), 1)[0]);

      axios.put(`${BASE_URL}/api/board/listLocation`, {
        boardId: list.board_id,
        newListOrder: list_order
      })
        .then(() => {
          this.props.getBoard();
        });
    } else if(dragged_type === 'item') {
      const dragged_item_id = e.dataTransfer.getData('item_id');
      const oldListId = e.dataTransfer.getData('og_list_id');
      const oldListItemOrder = JSON.parse(e.dataTransfer.getData('og_list_item_order'));
      const item_order = list.item_order;

      item_order.push(dragged_item_id);

      if(oldListId != list.id) {
        oldListItemOrder.splice(oldListItemOrder.indexOf(dragged_item_id), 1);
      } else {
        // If an item has been moved to the end of it's list we need to remove it's duplicate further up the list
        item_order.splice(item_order.indexOf(dragged_item_id), 1);
      }

      axios.put(`${BASE_URL}/api/board/itemLocation`, {
        itemId: dragged_item_id,
        newListItemOrder: item_order,
        newListId: list.id,
        oldListId: (oldListId == list.id) ? undefined : oldListId,
        oldListItemOrder
      })
        .then(() => {
          this.props.getBoard();
        });
    }

  };

  listDragStart = (e) => {
    const list = this.props.list;

    if(e.dataTransfer.getData('type')) {
      return;
    }

    e.dataTransfer.setData('type', 'list');
    e.dataTransfer.setData('list_id', String(list.id));
  };

  orderItems = (items) => {
    const itemOrder = this.props.list.item_order || [];
    return items.sort((a, b) => {
      return itemOrder.indexOf(a.id) - itemOrder.indexOf(b.id);
    });
  };

  updateItem = (itemId, itemName, itemData) => {
    axios.put(`${BASE_URL}/api/board/item`, {
      itemId: itemId,
      name: itemName,
      data: itemData
    })
      .then(() => {
        this.props.getBoard();
      });
  }

  render() {
    const {
      items,
      name,
      item_order
    } = this.props.list;

    const {
      addingItem
    } = this.state;

    return (
      <ListWrapper
        draggable="true"
        onDragStart={(e) => {
          this.listDragStart(e);
          this.props.startDrag();
        }}
        onDragOver={(e) => {e.preventDefault();}}
        onDrop={(e) => { this.drop(e); }}
        onDragEnd={() => { this.props.stopDrag(); }}
      >
        <Row
          style={{
            justifyContent: 'space-between',
          }}
        >
          {name}
          <span>
            <Button
              styles={{
                padding: '2px 6px'
              }}
              onClick={() => {
                this.setState({ addingItem: true });
              }}
            >+</Button>
          </span>
        </Row>
        <div style={{
          overflow: 'scroll',
          height: '97%'
        }}>
          {this.orderItems(items).map((item, i) => {
            return(
              <Item
                key={i}
                item={item}
                itemOrder={item_order}
                getBoard={this.props.getBoard}
                startDrag={this.props.startDrag}
                stopDrag={this.props.stopDrag}
                modalWidth={this.props.modalWidth}
                viewModal={this.props.viewModal || ViewModal}
              />
            );
          })}
        </div>
        <Modal
          label="Item Name"
          maxWidth={this.props.modalWidth || '350px'}
          isOpen={addingItem}
          onModalClose={() => {
            this.setState({ addingItem: false });
          }}
          element={this.props.addModal || AddModal}
          submit={(itemName, itemData = {}) => {
            this.addItem(itemName, itemData);
          }}
          reloadBoard={this.props.getBoard}
        />
        <ConfirmationModal
          isOpen={this.state.deletingList}
          onModalClose={() => { this.setState({ deletingList: false }); }}
          onConfirm={this.delete}
          text="Are you sure you want to delete this list and every item associated with it?"
        />
      </ListWrapper>
    );
  }
}

const ListWrapper = styled('div')({
  width: '250px',
  midWidth: '350px',
  height: '100%',
  border: '4px solid #CFD8DC',
  borderRadius: '8px',
  padding: '12px',
  backgroundColor: '#F5F5F5',
  marginRight: '8px',
  //overflow: 'scroll'
});


class Item extends React.Component {

  constructor() {
    super();
    this.state = {
      itemOpen: false
    };
  }

  itemDragStart = (e) => {
    const item = this.props.item;
    e.dataTransfer.setData('type', 'item');
    e.dataTransfer.setData('item_id', String(item.id));
    e.dataTransfer.setData('og_list_id', String(item.board_list_id));
    e.dataTransfer.setData('og_list_item_order', JSON.stringify(this.props.itemOrder));
  };

  drop = (e) => {
    e.stopPropagation();
    if(e.dataTransfer.getData('type') === 'item') {
      const item = this.props.item;
      const dragged_item_id = e.dataTransfer.getData('item_id');
      const oldListId = e.dataTransfer.getData('og_list_id');
      const oldListItemOrder = JSON.parse(e.dataTransfer.getData('og_list_item_order'));
      const item_order = this.props.itemOrder;

      if(oldListId == item.board_list_id) {
        item_order.splice(item_order.indexOf(item.id), 0, item_order.splice(item_order.indexOf(dragged_item_id), 1)[0]);
      } else {
        oldListItemOrder.splice(oldListItemOrder.indexOf(dragged_item_id), 1);
        item_order.splice(item_order.indexOf(item.id), 0, dragged_item_id);
      }

      axios.put(`${BASE_URL}/api/board/itemLocation`, {
        itemId: dragged_item_id,
        newListItemOrder: item_order,
        newListId: item.board_list_id,
        oldListId: (oldListId == item.board_list_id) ? undefined : oldListId,
        oldListItemOrder
      })
        .then(() => {
          this.props.getBoard();
        });
    }

  };

  render() {
    return (
      <>
        <ItemWrapper
          draggable="true"
          onDragStart={(e) => {
            this.itemDragStart(e);
            this.props.startDrag();
          }}
          onDragOver={(e) => {e.preventDefault();}}
          onDrop={(e) => { this.drop(e); }}
          onDragEnd={() => { this.props.stopDrag(); }}
          onClick={() => {if(this.props.viewModal) this.setState({ itemOpen: true });}}
        >{this.props.item.name}</ItemWrapper>
        {this.props.viewModal &&
         <Modal
           label="Item"
           maxWidth={this.props.modalWidth || '350px'}
           isOpen={this.state.itemOpen}
           onModalClose={() => { this.setState({ itemOpen: false }); }}
           element={this.props.viewModal}
           reloadBoard={this.props.getBoard}
           item={this.props.item}
         />
        }
      </>
    );
  }
}

const ItemWrapper = styled('div')({
  width: '100%',
  minHeight: '70px',
  backgroundColor: 'white',
  marginBottom: '10px',
  padding: '4px',
  border: '1px solid #CCC'
});

class AddModal extends React.Component {

  constructor() {
    super();
    this.state = {
      list: ''
    };
  }

  render() {
    return (
      <TextInput
        label={this.props.label}
        value={this.state.list}
        onChange={(e) => {
          this.setState({ list: e.target.value });
        }}
        onKeyPress={(e) => {
          if(e.key === 'Enter') {
            this.props.onModalClose();
            this.props.submit(this.state.list);
          }
        }}
      />
    );
  }
}

class ViewModal extends React.Component {
  constructor(props) {
    super();
    console.log(props.item);
    this.state = {
      name: props.item.name
    };
  }

  update = () => {
    axios.put(`${BASE_URL}/api/board/item`, {
      itemId: this.props.item.id,
      name: this.state.name,
      data: {}
    })
      .then(() => {
        this.props.onModalClose();
        this.props.reloadBoard();
      });
  };

  render() {
    return (
      <TextInput
        inputCSS={{height: '300px'}}
        type="textarea"
        label={this.props.label || 'Item Name'}
        value={this.state.name}
        onChange={(e) => {
          this.setState({ name: e.target.value });
        }}
        onKeyPress={(e) => {
          if(e.key === 'Enter') {
            this.update();
          }
        }}
      />
    );
  }
}

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '100%',
  padding: '6px',
  overflowY: 'auto',
  alignItems: 'flex-start',
  alignContent: 'flex-start'
});
