import React from 'react';
import styled from '@emotion/styled';
import AddIcon from 'react-icons/lib/md/add';

const ButtonWrapper = styled('button')({
  width: 55,
  height: 55,
  borderRadius: '100%',
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 10,
  fontSize: 36,
  outline: 'none',
  cursor: 'pointer'
}, ({theme}) => ({
  background: theme.vibrant.color,
  boxShadow: theme.shadows.d4,
  color: theme.vibrant.textOn,
  '&:hover': {
    background: theme.vibrant.light
  },
  '&:active': {
    background: theme.vibrant.dark
  }
}), ({css, theme}) => {
  if(css){
    return css(theme) || {};
  }
});

export default class FloatingActionButton extends React.Component{
  render(){
    const { css, icon, onClick } = this.props;
    return(
      <ButtonWrapper css={css} onClick={onClick}>
        {
          icon ?
            icon
            :
            <AddIcon/>
        }
      </ButtonWrapper>
    );
  }
}
