import React, { useState, useEffect } from 'react';

import { axios } from '@/api.js';
import { TextInput, Button } from '@components/Controls.js';

export default function Email() {
  const [emailNote, setEmailNote] = useState('');
  const [invoiceNote, setInvoiceNote] = useState('');

  useEffect(() => {
    axios.get('/settings', {
      params: {
        type: 'general',
        sub_type: 'email',
        sub_sub_type: 'note'
      }
    })
      .then((res) => {
        setEmailNote(res.data.result.data.text);
      });
    axios.get('/settings', {
      params: {
        type: 'general',
        sub_type: 'email',
        sub_sub_type: 'invoice_note'
      }
    })
      .then((res) => {
        setInvoiceNote(res.data.result.data.text);
      });
  }, []);

  function onEmailNoteSubmit() {
    const data = {
      text: emailNote
    };
    axios.put('/settings', {
      type: 'general',
      sub_type: 'email',
      sub_sub_type: 'note',
      data
    });
  }

  function onInvoiceNoteSubmit() {
    const data = {text: invoiceNote};
    axios.put('/settings', {
      type: 'general',
      sub_type: 'email',
      sub_sub_type: 'invoice_note',
      data
    });
  }

  return (
    <>
      <div style={{margin: 5}}>
        <TextInput
          width="100%"
          type="textarea"
          label="Payment Email Note"
          css={{height: '150px'}}
          onChange={({target: {value}}) => {setEmailNote(value);}}
          value={emailNote}
        />
        <Button onClick={onEmailNoteSubmit}>Save Payment Note</Button>
      </div>
      <div style={{margin: 5}}>
        <TextInput
          width="100%"
          type="textarea"
          label="Invoice Email Note"
          css={{height: '150px'}}
          onChange={({target: {value}}) => {setInvoiceNote(value);}}
          value={invoiceNote}
        />
        <Button onClick={onInvoiceNoteSubmit}>Save Invoice Note</Button>
      </div>
    </>
  );
}
