import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { testWorkCenters } from '../../testData';
import theme from '../../theme';

const ButtonWrapper = styled('button')({
  display: 'flex',
  flexDirection: 'row',
  border: 'none',
  background: 'none',
  height: 56,
  position: 'relative',
  alignItems: 'stretch',
  padding: 0,
  cursor: 'pointer',
  fontSize: 13,
  textTransform: 'uppercase',
  transition: 'background 300ms',
  width: '100%',
  margin: 0,
  fontWeight: 600,

  '&:hover': {
    '& .color-bar': {
      width: 25
    }
  },

  '&:focus': {
    outline: 'none'
  }
}, ({theme}) => ({
  color: theme.primary.textOn,
  borderBottom: `1px solid ${theme.primary.dark}`
}));

const IconWrapper = styled('div')({
  width: 56,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 11,
  flex: '0 0 auto'
});

const ColorBar = styled('div')({
  flex: '0 0 auto',
  width: 10,
  height: 55,
  transition: 'width 300ms'
});

const Title = styled('span')({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap'
});

const linkWrapperStyle = css({
  width: '100%',
  textDecoration: 'none',
  margin: 0
});

export default class WorkCenterButton extends React.Component{
  render(){
    const {
      name,
      htmlcolor,
      textColor,
      active,
      workcenter_id,
      href,
      Icon,
      dropHandler
    } = this.props;
    const workcenter = testWorkCenters[workcenter_id] || {};
    return (
      <Link
        to={href || `/workcenters/${workcenter_id}`}
        replace={!href?.startsWith('http')}
        className={linkWrapperStyle}
      >
        <ButtonWrapper
          style={{
            background: active ? htmlcolor : '',
            color: active ? textColor : ''
          }}
          onDragOver={(e) => {e.preventDefault();}}
          onDrop={(e) => {
            if (dropHandler) {
              dropHandler(e, workcenter_id);
            }
          }}
        >
          <ColorBar className="color-bar" style={{
            background: htmlcolor
          }} />
          <IconWrapper>
            {
              workcenter.icon &&
              <workcenter.icon color={active ? '#fff' : theme.primary.dark} />
            }
            {
              Icon &&
              <Icon color={active ? 'currentColor' : theme.primary.dark} />
            }
          </IconWrapper>
          <Title>
            {name}
          </Title>
        </ButtonWrapper>
      </Link>
    );
  }
}
