import React from 'react';
import styled from '@emotion/styled';

import { format } from 'date-fns';
import NewEventModal from './NewEventModal';
import NewProductionCardModal from './NewProductionCardModal';

import LeftArrow from '@mui/icons-material/ChevronLeft';
import RightArrow from '@mui/icons-material/ChevronRight';
import AddCircle from '@mui/icons-material/AddCircle';
import NewEvent from '@mui/icons-material/Event';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
 
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import SidebarRoutes from '@components/Containers/auth/SidebarRoutes';


export default class CalendarBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      newEventModalOpen: false,
      newCardModalOpen: false,
      newEventModalStyle: {}
    };
  }

  openNewProductionCardModal = () => {
    const dm = this.newCard.getBoundingClientRect();
    const page = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    const left = ((dm.left - page.width) < (288 - 30)) ? (page.width - 288 - 30) : dm.left;
    this.setState({
      newCardModalOpen: true,
      newCardModalStyle: {
        left: left - 150,
        top: dm.bottom
      }
    });
  };

  closeCardModal = () => {
    this.props.reloadEvents();
    this.setState({
      newCardModalOpen: false
    });
  };

  openNewEventModal = () => {
    const dm = this.newEvent.getBoundingClientRect();
    const page = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    const left = ((dm.left - page.width) < (288 - 30)) ? (page.width - 288 - 30) : dm.left;
    this.setState({
      newEventModalOpen: true,
      newEventModalStyle: {
        left: left - 150,
        top: dm.bottom
      }
    });
  }

  closeEventModal = () => {
    this.setState({
      newEventModalOpen: false
    });
  }

  render () {
    const {
      color = '',
      fullWidth,
      forward,
      backward,
      dateRange,
      reloadEvents,
      filterByUser = false,
      toggleFilterByUser
    } = this.props;

    return (
      <Wrapper
        style={{
          background: color,
          position: 'fixed'
        }}
        hasSidebar={fullWidth ? false : true}
        allowMini
      >
        {/* Mobile Nav Drawer */}
        <IconButton
          color="inherit"
          edge="start"
          sx={{ color: 'white', display: { lg: 'none' } }}
          onClick={() => { this.setState({ drawerOpen: !this.state.drawerOpen }); }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          open={this.state.drawerOpen}
          variant="temporary"
          sx={{
            display: { lg: 'none' },
          }}
          onClose={() => { this.setState({ drawerOpen: false }); }}
        >
          <SidebarRoutes/>
        </Drawer>

        <Title>Calendar</Title>
        <DateWrapper>
          <FormControl size="small">
            <InputLabel
              color="blue"
            >SortBy</InputLabel>
            <Select
              value={this.props.sortBy}
              label="SortBy"
              onChange={(e) => { this.props.updateSortBy(e.target.value); }}
              sx={{ backgroundColor: 'white'}}
            >
              <MenuItem value="design">Design</MenuItem>
              <MenuItem value="type">Type</MenuItem>
            </Select>
          </FormControl>

          <IconButton
            sx={{ color: filterByUser ? 'white' : 'gray' }}
            onClick={toggleFilterByUser}
          >
            <PersonSearchIcon />
          </IconButton>
          <IconButton
            onClick={this.openNewEventModal}
            ref={ref=>{this.newEvent=ref;}}
            sx={{ color: 'white' }}
          >
            <NewEvent />
          </IconButton>
          <NewEventModal
            isOpen={this.state.newEventModalOpen}
            onModalClose={this.closeEventModal}
            reloadEvents={reloadEvents}
            style={this.state.newEventModalStyle}
            users={this.props.users}
          />
          <IconButton
            onClick={this.openNewProductionCardModal}
            ref={ref=>{this.newCard=ref;}}
            sx={{ color: 'white' }}
          >
            <AddCircle />
            <NewProductionCardModal
              isOpen={this.state.newCardModalOpen}
              onModalClose={this.closeCardModal}
              style={this.state.newCardModalStyle}
            />
          </IconButton>
          <IconButton
            onClick={backward}
            sx={{ color: 'white'}}
          >
            <LeftArrow />
          </IconButton>
          <Date>
            {format(dateRange.startOfWeek, 'MMM Do')}
            {' - '}
            {format(dateRange.endOfWeek, 'MMM Do')}
          </Date>
          <IconButton
            onClick={forward}
            sx={{ color: 'white'}}
          >
            <RightArrow />
          </IconButton>
        </DateWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled('div')(({theme}) =>(
  {
    height: 45,
    position: 'fixed',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 10px',
    zIndex: 2,
    transition: 'left 300ms, width 300ms, background 300ms',
    width: 'calc(100% - 250px)',
    left: 250,
    [`${theme.breakpoints.down('lg')}`]: {
      left: 0,
      width: '100%',
    },
    background: theme.primary.color,
  }));

const Title = styled('span')(
  {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 20,
    textTransform: 'uppercase',
  },
  ({ theme }) => ({
    color: theme.primary.textOn,
  })
);

const DateWrapper = styled('div')({
  marginLeft: 'auto',
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'row'
},
({ theme }) => ({
  color: theme.primary.textOn,
})
);

const Date = styled('div')({
  padding: 4,
  height: '100%',
  width: '200px',
  margin: 'auto',
  textAlign: 'center'
});
