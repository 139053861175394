import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

class ErrorToast extends React.Component {
  constructor(props, context) { super(props, context); }

  render() {
    if(!this.props.error) {
      return null;
    }

    return (
      <ToastDiv
        className="slide-in-bottom"
      >
        <p
          style={{ paddingRight: '16px' }}
        >{this.props.error}</p>
        <p
          onClick={() => {
            this.props.dispatch({
              type: 'ERROR/UNSET'
            });
          }}
        >CLOSE</p>
      </ToastDiv>
    );
  }
}
export default connect(state => ({
  error: state.error.error
}))(ErrorToast);

const ToastDiv = styled('div')({
  padding: '4px',
  paddingLeft: '8px',
  paddingRight: '8px',
  position: 'absolute',
  bottom: '8px',
  left: '35%',
  width: '30%',
  zIndex: 10000000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f44336',
  borderRadius: '4px',
  color: '#fafafa'
});
