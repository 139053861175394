import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Wrapper } from '../../components/shell/Shell';
import Table from '../../components/Collections/Table';
import theme from '../../theme';
import { Jobname } from './WorkCenters';
import { Tag } from '../../components/Tags';
import { Follower, FollowersWrapper } from '../../pages/Workorder/Followers';
import { testWorkCenters } from '../../testData';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { AllIcon } from '../../common/img/icons/workCenters';
import AppBar from '../../components/Reusable/navigation/AppBar.tsx';
import WhiteRoundSelect from '../../components/Reusable/controls/WhiteRoundSelect.tsx';
import { axios } from '../../api.js';

const IconWrapper = styled('div')({
  marginTop: 2,
  '& svg': {
    maxWidth: 30,
  },
});

function MyOrders(props) {
  const [workcenters, setWorkcenters] = useState([]);
  const [filterBy, setFilterBy] = useState('');
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    getWorkcenters();
    getDocumentsByUser();
  }, []);

  useEffect(() => {
    getDocumentsByUser();
  }, [filterBy]);


  function editWorkorder({ document_id }) {
    props.history.push(`/workorder/edit/${document_id}`);
  }

  function getDocumentsByUser() {
    axios.get('/document/bulk/byUser', {
      params: {
        userId: props.user_account_id,
        workcenter: filterBy
      }
    })
      .then((res) => {
        setDocuments(res.data.result);
      });
  }

  function getWorkcenter(id) {
    if (workcenters) {
      return workcenters?.find(({workcenter_id}) => workcenter_id === id );
    } else {
      return null;
    }
  }

  function getWorkcenters() {
    axios.get('/workcenters')
      .then((res) => {
        setWorkcenters(res.data.result.sort((a, b) => {
          return a.wcord - b.wcord;
        }));
      });
  }

  return (
    <>
      <AppBar 
        title="My Orders"
        htmlcolor={theme.primary.light}
      >
        <WhiteRoundSelect 
          label="Filter By Folder"
          items={[{ text: 'None', value: '' }, ...workcenters.map((workcenter) => {
            return {
              text: workcenter.name,
              value: workcenter.workcenter_id
            };
          })]}
          nullValue={''}
          value={filterBy}
          onSelect={(value) => { setFilterBy(value.value); }}
        />
      </AppBar>
      <Wrapper
        style={{ paddingTop: 0 }}
      >
        <Table
          template={[
            {
              label: '',
              id: 'workcenter',
              
              renderCell: (row, TableCell) => {
                const workcenter = 
                  getWorkcenter(row.workcenter)
                  || testWorkCenters[row.workcenter] 
                  || {};
                let Icon = AllIcon;
                if(testWorkCenters?.[row?.workcenter]?.icon) {
                  Icon = testWorkCenters[row.workcenter].icon;
                }
                return (
                  <TableCell
                    key="workcenter"
                    background={workcenter.htmlcolor || theme.neutral.extraLight}
                    style={{
                      width: 44,
                      padding: 5,
                      textAlign: 'center',
                      verticalAlign: 'middle',
                    }}
                    id={row.document_id}
                  >
                    <IconWrapper>
                      <Icon />
                    </IconWrapper>
                  </TableCell>
                );
              },
            },
            {
              label: 'Job Name',
              id: 'jobname',
              bold: true,
              upperCase: true,
              maxLength: 30,
              render: row => {
                if (row.priority === 'Rush') {
                  return (
                    <Jobname rush>
                      <Tag color={theme.danger.color}>RUSH</Tag> {row.jobname}
                    </Jobname>
                  );
                }
                if (row.priority === 'Must') {
                  return (
                    <Jobname must>
                      <Tag color={theme.orange.color}>MUST</Tag> {row.jobname}
                    </Jobname>
                  );
                }
                return row.jobname;
              },
            },
            {
              label: 'Contact',
              id: 'customer',
              color: '#b3b3b3',
              type: 'customerName',
            },
            { label: 'Tags', id: 'tags_of_document', type: 'tags' },
            {
              label: 'Followers',
              id: 'followers_of_document',
              type: 'followers',
              render: row => (
                <FollowersWrapper>
                  {row.followers_of_document.map(({ id, user_profile = {} }) => {
                    const {
                      first_name,
                      last_name,
                      profilepicture,
                    } = user_profile ? user_profile : {};
                    return (
                      <Follower
                        name={`${first_name} ${last_name}`}
                        profilepicture={profilepicture}
                        background
                        size="small"
                        tooltip
                        key={id}
                      />
                    );
                  })}
                </FollowersWrapper>
              ),
            },
            {
              label: 'Due Date',
              id: 'duedate',
              type: 'date',
              render: row => row.duedate && format(row.duedate, 'MMM, D, YYYY'),
            },
            { id: 'document_id', hidden: true },
          ]}
          data={documents}
          onRowClick={editWorkorder}
        />
      </Wrapper>
    </>
  );
}
export default connect(state => ({
  user_account_id: state.app.user_account_id,
}))(MyOrders);
