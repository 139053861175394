import React from 'react';
import styled from '@emotion/styled';
import Slider from '../../components/Slider';
import { TextInput, Select, Checkbox, Button } from '../../components/Controls';
import { stateOptions, billingTypes } from '../../data/options';
import axios from 'axios';
import { BASE_URL } from '../../api';

export class ConnectedCustomerSlider extends React.Component {
  createCustomer = customer => {
    axios.post(`${BASE_URL}/api/customer`, {
      customer
    })
      .then((res) => {
        this.props.onModalClose();
        if(this.props.onCustomerCreated){
          this.props.onCustomerCreated(res.data.result);
        }
      });
  };

  render() {
    return <CustomerSlider {...this.props} onSubmit={this.createCustomer} />;
  }

}

export default class CustomerSlider extends React.Component {
  render() {
    const {
      isOpen,
      onModalClose,
      customer,
      submitLabel = 'Create Customer',
      title = 'Add New Customer',
      onSubmit
    } = this.props;
    return (
      <Slider title={title} isOpen={isOpen} onModalClose={onModalClose}>
        <CustomerForm
          onSubmit={onSubmit}
          customer={customer}
          submitLabel={submitLabel}
        />
      </Slider>
    );
  }
}

export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.slider.padding
}));

export const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '20px 0px',

  '&:first-of-type': {
    marginTop: 0
  },

  '&:last-child': {
    marginBottom: 0
  }
});

const initialCustomer = {
  isorganization: false,
  firstname: '',
  lastname: '',
  organization: '',
  email: '',
  primaryphone: '',
  secondaryphone: '',
  billingtype: 'Due On Receipt',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  taxexempt: false,
  taxnumber: '',
  requirespo: false,
  notes: ''
};

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    const initialState = props.customer ? props.customer : {};
    this.state = { ...initialCustomer, ...initialState };
  }
  setValue = (key, value) => {
    this.setState({ [key]: value });
  };
  submit = () => {
    const name = {
      organization: this.state.isorganization ? this.state.organization : ''
    };
    const taxnumber = {
      taxnumber: this.state.taxexempt ? this.state.taxnumber : ''
    };
    this.props.onSubmit({
      isorganization: this.state.isorganization,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      primaryphone: this.state.primaryphone,
      secondaryphone: this.state.secondaryphone,
      billingtype: this.state.billingtype,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zipcode: this.state.zipcode,
      requirespo: this.state.requirespo,
      taxexempt: this.state.taxexempt,
      notes: this.state.notes,
      ...name,
      ...taxnumber
    });
  };
  render() {
    const canSubmit =
      (this.state.isorganization
        ? this.state.organization !== ''
        : this.state.firstname !== '' && this.state.lastname !== '') &&
      this.state.email !== '' &&
      this.state.primaryphone !== '' &&
      this.state.billingtype !== '' &&
      (this.state.taxexempt ? this.state.taxnumber !== '' : true);
    const { submitLabel } = this.props;
    return (
      <Wrapper>
        <Row>
          <Checkbox
            value={this.state.isorganization}
            label="Organization"
            onChange={e => {
              this.setValue('isorganization', e.target.checked);
            }}
          />
        </Row>
        {this.state.isorganization && (
          <Row>
            <TextInput
              css={{
                flex: '1 1 auto',
                width: 'auto'
              }}
              inputCSS={{
                width: '100%'
              }}
              label="Organization"
              value={this.state.organization}
              onChange={e => {
                this.setValue('organization', e.target.value);
              }}
            />
          </Row>
        )}
        <Row>
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto',
              margin: 0,
              marginRight: 7
            }}
            inputCSS={{
              width: '100%'
            }}
            label="First Name"
            value={this.state.firstname}
            onChange={e => {
              this.setValue('firstname', e.target.value);
            }}
          />
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto',
              margin: 0,
              marginLeft: 7
            }}
            inputCSS={{
              width: '100%'
            }}
            label="Last Name"
            value={this.state.lastname}
            onChange={e => {
              this.setValue('lastname', e.target.value);
            }}
          />
        </Row>
        <Row>
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto'
            }}
            inputCSS={{
              width: '100%'
            }}
            label="Email"
            value={this.state.email}
            onChange={e => {
              this.setValue('email', e.target.value);
            }}
          />
        </Row>
        <Row>
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto'
            }}
            inputCSS={{
              width: '100%'
            }}
            label="Primary Phone Number"
            value={this.state.primaryphone}
            onChange={e => {
              this.setValue('primaryphone', e.target.value);
            }}
          />
        </Row>
        <Row>
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto'
            }}
            inputCSS={{
              width: '100%'
            }}
            label="Secondary Phone Number"
            value={this.state.secondaryphone}
            onChange={e => {
              this.setValue('secondaryphone', e.target.value);
            }}
          />
        </Row>
        <Row>
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto'
            }}
            inputCSS={{
              width: '100%'
            }}
            label="Street Address"
            value={this.state.address}
            onChange={e => {
              this.setValue('address', e.target.value);
            }}
          />
        </Row>
        <Row>
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto'
            }}
            inputCSS={{
              width: '100%'
            }}
            label="City"
            value={this.state.city}
            onChange={e => {
              this.setValue('city', e.target.value);
            }}
          />
        </Row>
        <Row>
          <Select
            css={{
              flex: '1 1 auto',
              width: '30%',
              margin: 0,
              marginRight: 7
            }}
            inputCSS={{
              width: '100%'
            }}
            options={stateOptions}
            label="State"
            value={this.state.state}
            onChange={e => {
              this.setValue('state', e.target.value);
            }}
          />
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto',
              margin: 0,
              marginLeft: 7
            }}
            inputCSS={{
              width: '100%'
            }}
            label="Zipcode"
            value={this.state.zipcode}
            onChange={e => {
              this.setValue('zipcode', e.target.value);
            }}
          />
        </Row>
        <Row>
          <Select
            css={{
              flex: '1 1 auto',
              width: '30%',
              margin: 0,
              marginRight: 7
            }}
            inputCSS={{
              width: '100%'
            }}
            options={billingTypes}
            label="Billing Type"
            value={this.state.billingtype || 'Due on Receipt'}
            onChange={e => {
              this.setValue('billingtype', e.target.value);
            }}
          />
        </Row>
        <Row>
          <Checkbox
            value={this.state.taxexempt}
            label="Tax Exempt"
            onChange={e => {
              this.setValue('taxexempt', e.target.checked);
            }}
          />
        </Row>
        {this.state.taxexempt && (
          <Row>
            <TextInput
              css={{
                flex: '1 1 auto',
                width: 'auto'
              }}
              inputCSS={{
                width: '100%'
              }}
              label="Tax Number"
              value={this.state.taxnumber}
              onChange={e => {
                this.setValue('taxnumber', e.target.value);
              }}
            />
          </Row>
        )}
        <Row>
          <Checkbox
            value={this.state.requirespo}
            label="Requires PO"
            onChange={e => {
              this.setValue('requirespo', e.target.checked);
            }}
          />
        </Row>
        <Row>
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto',
              height: 'auto',
              padding: '3px',
            }}
            inputCSS={{
              width: '100%',
              height: '20vh',
              resize: 'vertical'
            }}
            label="Notes"
            value={this.state.notes}
            type="textarea"
            onChange={e => {
              this.setValue('notes', e.target.value);
            }}
          />
        </Row>
        <Row>
          <Button
            disabled={!canSubmit}
            css={{ flexGrow: 1 }}
            onClick={this.submit}
          >
            {submitLabel}
          </Button>
        </Row>
      </Wrapper>
    );
  }
}
