import React from 'react';
import ModalStateController from './ModalStateController';
import { ScrollWrapper, ModalWrapper } from './Controls';

export default class Modal extends React.Component {
  render() {
    const { element, isOpen, onModalClose, maxWidth, ...other } = this.props;
    const Element = element;
    return (
      <ModalStateController
        enterAnimationClassName="fade-in"
        exitAnimationClassName="fade-out"
        isOpen={isOpen}
        onModalClose={onModalClose}
        shadeStyle={{ background: 'rgba(0,0,0,.5)' }}
      >
        {({getProps, closeModal}) => (
          <ScrollWrapper
            {...getProps()}
            onClick={() => {
              closeModal();
            }}
          >
            <ModalWrapper
              style={{
                marginTop: '20px',
                maxWidth: maxWidth ? maxWidth : ''
              }}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Element {...other} onModalClose={onModalClose} />
            </ModalWrapper>
          </ScrollWrapper>
        )}
      </ModalStateController>
    );
  }
}
