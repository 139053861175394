import React, { useEffect, useState } from 'react';
import ImageFallback from '../../components/ImageFallback';
import { BASE_URL, axios } from '../../api';
import styled from '@emotion/styled';
import userPlaceholder from '../../common/img/user_placeholder_white.svg';
import CloseIcon from 'react-icons/lib/md/close';


const UserImage = styled('img')({
  width: '100%',
  height: '100%'
});

const ImageWrapper = styled('div')(({ theme, size, background }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  width: size === 'small' ?  28 :
    size === 'x-small' ?  12 : 
      size === 'x-large' ? 80 : 40,
  height: size === 'small' ? 28 :
    size === 'x-small' ? 12 : 
      size === 'x-large' ? 80 : 40,
  overflow: 'hidden',
  border: `${size === 'small' ? 2 : size === 'x-small' ? 0 : 3}px solid ${theme.neutral.light}`,
  background: background ? theme.neutral.light : '',
  position: 'relative',
  '&:first-of-type': {
    marginLeft: 0,
  },
  '&:last-child': {
    marginRight: 0,
  },
}));


const FollowerWrapper = styled('div')(({ size }) => ({
  margin: size === 'small' ? 1 : size === 'x-small' ? 0 : size === 'medium' ? 0 : 3,
  display: 'inline-block',
  position: 'relative',
  '&:hover': {
    '& .DeleteButton': {
      display: 'flex',
    },
    '& .tooltip': {
      display: 'flex',
    },
  },
}));

const Tooltip = styled('div')({
  background: '#000',
  borderRadius: 3,
  padding: '4px 5px',
  color: '#fff',
  fontSize: 11,
  transform: 'translateX(-50%)',
  position: 'relative',
  whiteSpace: 'nowrap',
  '&:before': {
    position: 'absolute',
    bottom: -4,
    left: 'calc(50% - 3px)',
    content: '" "',
    width: 8,
    height: 8,
    background: '#000',
    transform: 'rotate(45deg)',
  },
});

const TooltipWrapper = styled('div')({
  position: 'absolute',
  width: 2,
  left: '50%',
  top: -30,
  flexDirection: 'row',
  pointerEvents: 'none',
  display: 'none',
});

const DeleteButton = styled('button')({
  appearance: 'none',
  border: 'none',
  background: 'rgba(0,0,0,.7)',
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: '100%',
  color: '#fff',
  fontSize: 20,
  padding: 0,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'none',
});

export const Follower = ({
  user_id,
  size = '',
  background = true,
  tooltip = true,
  deleteable,
  onRequestDelete,
  onClick = () => ({}),
  tooltipPosition = 'top',
  className = '',
  align = ''
}) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    getUser();
  }, [user_id]);

  function getUser() {
    axios.get('/users/account/' + user_id)
      .then((res) => {
        console.log(res.data.result);
        setUser(res.data.result);
      });
  }

  return (
    <FollowerWrapper size={size} style={{ float: align }} className={className} onClick={onClick}>
      {user?.user_profile?.first_name !== '' && tooltip && (
        <TooltipWrapper position={tooltipPosition} className="tooltip">
          <Tooltip position={tooltipPosition}>{user?.user_profile?.first_name}</Tooltip>
        </TooltipWrapper>
      )}
      <ImageWrapper size={size} background={background}>
        {deleteable && (
          <DeleteButton
            className="DeleteButton"
            onClick={() => {
              onRequestDelete(user_id);
            }}
          >
            <CloseIcon />
          </DeleteButton>
        )}
        <ImageFallback
          fallback={<UserImage src={userPlaceholder} alt="follower" />}
        >
          {props => (
            <UserImage
              {...props}
              src={
                user?.user_profile?.profilepicture
                  ? `${BASE_URL}/api/documentimages/userimage${user.user_profile.profilepicture}`
                  : userPlaceholder
              }
              alt="follower"
            />
          )}
        </ImageFallback>
      </ImageWrapper>
    </FollowerWrapper>
  );
};

export default Follower;
