import React from 'react';
import DataGrid from '../../../components/Reusable/data/DataGrid/DataGrid.js';
import { axios } from '../../../api.js';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

export default class PricingSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tax: 0,
      imprintMarkup: [],
      shirtMarkup: [],
      imprintStatusText: '',
      shirtStatusText: '',
      orderCustomizations: [],
      custoModal: false,
      custoName: '',
      custoPrice: 0,
      imprintPercentIncrease: 0,
      shirtPercentIncrease: 0
    };
  }

  componentDidMount = () => {
    this.getImprintMarkup();
    this.getShirtMarkup();
    this.getTax();
    this.getOrderCustomizations();
  };

  addCustomization = () => {
    axios.post('/lines/design/customization', {
      name: this.state.custoName,
      price: this.state.custoPrice
    })
      .then(() => {
        this.getOrderCustomizations();
      });
    this.setState({ orderCustomizations: [...this.state.orderCustomizations, {
      name: this.state.custoName,
      price: this.state.custoPrice,
      applyTo: 'item',
      archived: 'false'
    }], custoModal: false });
  };

  archiveCustomization = (id) => {
    axios.delete('/lines/design/customization', {
      params: {
        id
      }
    })
      .then(() => {
        this.getOrderCustomizations();
      });
  }

  unarchiveCustomization = (id) => {
    axios.put('/lines/design/customization/unarchive', {
      id
    })
      .then(() => {
        this.getOrderCustomizations();
      });
  }

  changeName = (value, i) => {
    const orderCustos = this.state.orderCustomizations;
    orderCustos[i].name = value;
    this.setState({ orderCustomizations: orderCustos });
  };

  changePrice = (value, i) => {
    const orderCustos = this.state.orderCustomizations;
    orderCustos[i].price = value;
    this.setState({ orderCustomizations: orderCustos });
  };

  deleteCusto = (i) => {
    const orderCustos = this.state.orderCustomizations;
    orderCustos.splice(i, 1);
    this.setState({ orderCustomizations: orderCustos });
  };

  getImprintMarkup = () => {
    axios.get('/settings', {
      params: {
        type: 'pricing',
        sub_type: 'markup',
        sub_sub_type: 'imprint'
      }
    })
      .then((res) => {
        this.setState({ imprintMarkup: res.data.result.data });
      });
  };

  getOrderCustomizations = () => {
    axios.get('/lines/design/customization')
      .then((res) => {
        this.setState({ orderCustomizations: res.data.result });
      });
  };

  getShirtMarkup = () => {
    axios.get('/settings', {
      params: {
        type: 'pricing',
        sub_type: 'markup',
        sub_sub_type: 'shirt'
      }
    })
      .then((res) => {
        this.setState({ shirtMarkup: res.data.result.data });
      });
  };

  getTax = () => {
    axios.get('/settings', {
      params: {
        type: 'general',
        sub_type: 'settings',
        sub_sub_type: 'values'
      }
    })
      .then((res) => {
        this.setState({tax: res.data.result.data.tax});
      });
  }

  increaseImprint = () => {
    console.log(this.state.imprintMarkup);
    let newImprints = this.state.imprintMarkup;
    newImprints = newImprints.map((row) => {
      return {
        ...row,
        print1: (Number(row.print1) * (this.state.imprintPercentIncrease / 100 + 1)).toFixed(2),
        print2: (Number(row.print2) * (this.state.imprintPercentIncrease / 100 + 1)).toFixed(2),
        print3: (Number(row.print3) * (this.state.imprintPercentIncrease / 100 + 1)).toFixed(2),
        print4: (Number(row.print4) * (this.state.imprintPercentIncrease / 100 + 1)).toFixed(2),
        print5: (Number(row.print5) * (this.state.imprintPercentIncrease / 100 + 1)).toFixed(2),
        print6: (Number(row.print6) * (this.state.imprintPercentIncrease / 100 + 1)).toFixed(2),
      };
    });

    this.setState({ imprintMarkup: newImprints });
  }

  increaseShirt = () => {
    console.log(this.state.shirtMarkup);
    let newShirts = this.state.shirtMarkup;
    newShirts = newShirts.map((row) => {
      return {
        ...row,
        'p0t2.60': (Number(row['p0t2.60']) * (this.state.shirtPercentIncrease / 100 + 1)).toFixed(2),
        'p2.61t4.99': (Number(row['p2.61t4.99']) * (this.state.shirtPercentIncrease / 100 + 1)).toFixed(2),
        'p5.00t6.99': (Number(row['p5.00t6.99']) * (this.state.shirtPercentIncrease / 100 + 1)).toFixed(2),
        'p7.00t9.60': (Number(row['p7.00t9.60']) * (this.state.shirtPercentIncrease / 100 + 1)).toFixed(2),
        'p9.61t14.99': (Number(row['p9.61t14.99']) * (this.state.shirtPercentIncrease / 100 + 1)).toFixed(2),
        'p15.00t24.99': (Number(row['p15.00t24.99']) * (this.state.shirtPercentIncrease / 100 + 1)).toFixed(2),
        'p25.00t44.99': (Number(row['p25.00t44.99']) * (this.state.shirtPercentIncrease / 100 + 1)).toFixed(2),
        'p45.00t84.99': (Number(row['p45.00t84.99']) * (this.state.shirtPercentIncrease / 100 + 1)).toFixed(2),
        'p85+': (Number(row['p85+']) * (this.state.shirtPercentIncrease / 100 + 1)).toFixed(2),
      };
    });

    this.setState({ shirtMarkup: newShirts });

    //this.setState({ imprintMarkup: newShirts });
  }

  onTaxSubmit = () => {
    const data = {
      tax: this.state.tax
    };
    axios.put('/settings', {
      type: 'general',
      sub_type: 'settings',
      sub_sub_type: 'values',
      data
    });
  }

  saveOrderCustomizations = () => {
    axios.put('/settings', {
      type: 'pricing',
      sub_type: 'order',
      sub_sub_type: 'customizations',
      data: this.state.orderCustomizations
    });
  }

  saveImprintPriceBreaks = () => {

    const imprint = this.state.imprintMarkup;
    const imprintValid = this.validatePricingGrid(imprint);

    if(imprintValid) {
      axios.put('/settings', {
        type: 'pricing',
        sub_type: 'markup',
        sub_sub_type: 'imprint',
        data: imprint
      }).then(() => {
        this.setState({
          imprintStatusText: 'Success!'
        });
      }).catch(() => {
        this.setState({
          imprintStatusText: 'Error!'
        });
      });
    } else {
      this.setState({
        imprintStatusText: 'Every value in the Imprint grid should be a number'
      });
    }
  }

  saveShirtPriceBreaks = () => {

    const shirt = this.state.shirtMarkup;
    const shirtValid = this.validatePricingGrid(shirt);

    if(shirtValid) {
      axios.put('/settings', {
        type: 'pricing',
        sub_type: 'markup',
        sub_sub_type: 'shirt',
        data: shirt
      }).then(() => {
        this.setState({
          shirtStatusText: 'Success!'
        });
      }).catch(() => {
        this.setState({
          shirtStatusText: 'Error!'
        });
      });
    } else {
      this.setState({
        shirtStatusText: 'Every value in the Shirt grid should be a number'
      });
    }

  };

  updateImprintPriceBreaks = (rows) => {
    this.setState({ imprintMarkup: rows });
  };

  updateShirtPriceBreaks = (rows) => {
    this.setState({ shirtMarkup: rows });
  };

  validatePricingGrid = (grid) => {
    return grid.map((row) => {
      const {
        inflection_point,
        print1,
        print2,
        print3,
        print4,
        print5,
        print6
      } = row;

      return {
        inflection_point: Number.parseInt(inflection_point),
        print1: Number.parseFloat(print1),
        print2: Number.parseFloat(print2),
        print3: Number.parseFloat(print3),
        print4: Number.parseFloat(print4),
        print5: Number.parseFloat(print5),
        print6: Number.parseFloat(print6)
      };
    }).every((priceBreak) => {
      const {
        inflection_point,
        print1,
        print2,
        print3,
        print4,
        print5,
        print6
      } = priceBreak;
      return typeof inflection_point === 'number' &&
        typeof print1 === 'number' &&
        typeof print2 === 'number' &&
        typeof print3 === 'number' &&
        typeof print4 === 'number' &&
        typeof print5 === 'number' &&
        typeof print6 === 'number';
    });
  };

  render() {
    return (
      <div>
        <h3>Order Customizations</h3>
        <Stack
          spacing={1}
        >
          { this.state.orderCustomizations.sort((custo1, custo2) => {
            if(custo1.archived && !custo2.archived || !custo1.archived && custo2.archived) {
              return custo1.archived ? 1 : -1;
            }
            return 0;
          }).map((custo, i) => {
            return(
              <Stack 
                direction="row"
                key={i}
                sx={{
                  alignItems: 'center'
                }}
                spacing={1}
              >
                <span><strong>Name:</strong> {custo.name}</span>
                <span><strong>Price:</strong> ${custo.price}</span>
                {custo.archived ? 
                  <IconButton
                    onClick={() => { this.unarchiveCustomization(custo.id); }}
                  >
                    <UnarchiveIcon />
                  </IconButton>
                  :
                  <IconButton
                    onClick={() => { this.archiveCustomization(custo.id); }}
                  >
                    <ArchiveIcon />
                  </IconButton>
                }
                {custo.archived && <span style={{ color: 'red' }}>Archived</span>}
              </Stack>
            );
          })}
          <Modal
            open={this.state.custoModal}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClose={() => { this.setState({ custoModal: false }); }}
          >
            <Box
              sx={{
                backgroundColor: 'white',
                p: 2,
                borderRadius: 1
              }}
            >
              <Stack
                spacing={1}
              >
                <Typography>Add Customization</Typography>
                <TextField 
                  label="Name"
                  value={this.state.custoName}
                  onChange={(e) => { this.setState({ custoName: e.target.value }); }}
                />
                <TextField 
                  label="Price"
                  value={this.state.custoPrice}
                  onChange={(e) => { this.setState({ custoPrice: e.target.value }); }}
                />
                <Button
                  variant="contained"
                  onClick={this.addCustomization}
                >Submit</Button>
              </Stack>
            </Box>
          </Modal>
          <Button
            onClick={() => { this.setState({ custoModal: true });}}
            variant="contained"
            startIcon={<AddCircleIcon />}
          >Add Customization</Button>
        </Stack>
        <h3>Imprint Price Breaks</h3>
        <TextField
          size="small"
          label="% Increase"
          value={this.state.imprintPercentIncrease}
          onChange={(e) => { this.setState({ imprintPercentIncrease: e.target.value }); }}
        ></TextField>
        <Button
          sx={{
            ml: 1
          }}
          variant="contained"
          onClick={this.increaseImprint}
        >Increase</Button>
        <DataGrid
          columnsHideable={false}
          columns={[{
            key: 'inflection_point',
            headerName: 'Break Num',
            type: 'number',
            width: 24
          }, {
            key: 'print1',
            headerName: '1 Print',
          }, {
            key: 'print2',
            headerName: '2 Print',
          }, {
            key: 'print3',
            headerName: '3 Print',
          }, {
            key: 'print4',
            headerName: '4 Print',
          }, {
            key: 'print5',
            headerName: '5 Print',
          }, {
            key: 'print6',
            headerName: '6 Print',
          }]}
          onUpdate={this.updateImprintPriceBreaks}
          rows={this.state.imprintMarkup}
        >
        </DataGrid>
        <Stack
          mt={1}
          mb={1}
          ml={1}
          spacing={2}
          direction="row"
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={this.saveImprintPriceBreaks}
          >Save Imprints</Button>
          <p>{ this.state.imprintStatusText }</p>
        </Stack>
        <h3>Shirt Price Breaks</h3>
        <TextField
          size="small"
          label="% Increase"
          value={this.state.shirtPercentIncrease}
          onChange={(e) => { this.setState({ shirtPercentIncrease: e.target.value }); }}
        ></TextField>
        <Button
          sx={{
            ml: 1
          }}
          variant="contained"
          onClick={this.increaseShirt}
        >Increase</Button>
        <DataGrid
          columnsHideable={false}
          columns={[{
            key: 'inflection_point',
            headerName: 'Break Num',
            type: 'number',
            width: 24
          }, {
            key: 'p0t2.60',
            headerName: '0-2.60',
          }, {
            key: 'p2.61t4.99',
            headerName: '2.61-4.99',
          }, {
            key: 'p5.00t6.99',
            headerName: '5.00-6.99',
          }, {
            key: 'p7.00t9.60',
            headerName: '7.00-9.60',
          }, {
            key: 'p9.61t14.99',
            headerName: '9.61-14.99',
          }, {
            key: 'p15.00t24.99',
            headerName: '15.00-24.99',
          }, {
            key: 'p25.00t44.99',
            headerName: '25.00-44.99',
          }, {
            key: 'p45.00t84.99',
            headerName: '45.00-84.99',
          }, {
            key: 'p85+',
            headerName: '85+',
            type: 'number',
          }]}
          onUpdate={this.updateShirtPriceBreaks}
          rows={this.state.shirtMarkup}
        >
        </DataGrid>
        <Stack
          mt={1}
          mb={1}
          ml={1}
          spacing={2}
          direction="row"
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={this.saveShirtPriceBreaks}
          >Save Imprints</Button>
          <p>{ this.state.shirtStatusText }</p>
        </Stack>
        <TextField
          value={this.state.tax}
          onChange={(e) => {
            this.setState({tax: e.target.value});
          }}
        />
        <Button
          onClick={this.onTaxSubmit}
          styles={{
            margin: '9px'
          }}
          size="small"
          variant="contained"
        >Save Tax</Button>
      </div>
    );
  }
}

