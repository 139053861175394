import { Breakpoint } from "@mui/system";
import { TagEditorModal } from "./pages/TagAdmin/TagAdmin";

interface TagColor {
  colorId: number,
  color: string,
  text: string
}

const orderedTagColors : TagColor[] = [
  {colorId: 1, color: 'rgb(237, 88, 81)', text: '#fff'},
  {colorId: 4, color: 'rgb(255, 122, 69)', text: '#fff'},
  {colorId: 6, color: 'rgb(255, 235, 59)', text: 'rgb(98, 89, 12)'},
  {colorId: 2, color: 'rgb(156, 204, 100)', text: '#fff'},
  {colorId: 10, color: 'rgb(91, 238, 220)', text: 'rgb(7, 88, 78)'},
  {colorId: 3, color: 'rgb(51, 122, 236)', text: '#fff'},
  {colorId: 5, color: 'rgb(126, 88, 194)', text: '#fff'},
  {colorId: 12, color: 'rgb(228, 106, 216)', text: '#fff'},
  {colorId: 7, color: 'rgb(120, 144, 156)', text: '#fff'},
  {colorId: 8, color: 'rgb(243, 243, 243)', text: 'rgb(47, 47, 47)'},
  {colorId: 9, color: 'rgb(49, 77, 102)', text: '#fff'},
  {colorId: 11, color: 'rgb(20, 30, 40)', text: '#fff'}
];

const defaultTagId : number = 9;

type TagColors = { [key: string]: TagColor }
const TAG_COLORS : TagColors = orderedTagColors.reduce((obj, c) => ({...obj, [c.colorId]: c}), {});

export const getTagColorById = (colorId:string) : TagColor => (TAG_COLORS[colorId] || TAG_COLORS[defaultTagId]);

interface SubTheme {
  color?: string,
  main?: string,
  colorRGB?: string,
  dark?: string,
  medium?: string,
  lightMedium?: string,
  light?:string,
  extraLight?: string,
  extraExtraLight?: string,
  textOn?: string,
  fontFamily?: string,
  alpha1?: string,
  alpha2?: string,
  alpha3?: string,
  alpha4?: string,
  alpha5?: string,
  colorByAlpha?: (a: string) => string,
  textOnByAlpha?: (a: string) => string,
}

interface Breakpoints { 
  xs: number, 
  sm: number, 
  md: number, 
  lg: number, 
  xl: number 
}

export interface Theme {
  palette: {
    primary: {
      main: string,
      colorRGB: string,
      dark: string,
      medium: string,
      lightMedium: string,
      light:string,
      extraLight: string,
      extraExtraLight: string,
      textOn: string,
      fontFamily: string,
      colorByAlpha: (a: string) => string,
      textOnByAlpha: (a: string) => string,
    },
    vibrant: SubTheme,
    neutral: SubTheme,
    success: SubTheme,
    danger: SubTheme,
    blue: SubTheme,
    green: SubTheme,
    red: SubTheme,
    orange: SubTheme,
    warning: SubTheme
  },
  primary: SubTheme,
  vibrant: SubTheme,
  neutral: SubTheme,
  success: SubTheme,
  danger: SubTheme,
  blue: SubTheme,
  green: SubTheme,
  red: SubTheme,
  orange: SubTheme,
  white: string,
  slider: {
    padding: number
  },
  breakpoints: {
    values: Breakpoints
  },
  grid: {
    grid_breakpoints: Breakpoints,
    max_container_width: Breakpoints,
    column_gutter: Breakpoints,
    outer_gutter: Breakpoints
  },
  shadows: {
    [key: string | number]: string
  },
  orderedTagColors: TagColor[],
  tagColors: { [key: string]: TagColor },
  typography: {
    fontFamily: string,
    heavy: string
  }
}

const theme : Theme = {
  palette: {
    primary: {
      main: '#213a4b',
      colorRGB: 'rgb(33, 58, 75)',
      dark: '#0b202b',
      medium: '#314d66',
      lightMedium: '#3e637f',
      light: '#3e6380',
      extraLight: '#6589a6',
      extraExtraLight: '#8eb1cd',
      textOn: '#fff',
      fontFamily: '"Montserrat", sans-serif',
      colorByAlpha: (a: string) => `rgba(33, 58, 75, ${a})`,
      textOnByAlpha: (a: string) => `rgba(255,255,255, ${a})`
    },
    vibrant: {
      main: '#29c5ff',
      dark: '#13b7f4',
      light: '#48cbfc',
      alpha1: 'rgba(41, 197, 255, 0.1)',
      alpha2: 'rgba(41, 197, 255, 0.2)',
      alpha3: 'rgba(41, 197, 255, 0.3)',
      alpha4: 'rgba(41, 197, 255, 0.4)',
      alpha5: 'rgba(41, 197, 255, 0.5)',
      textOn: 'rgb(255, 255, 255)',
      colorByAlpha:  (a: string) => `rgba(41, 197, 255, ${a})`,
      textOnByAlpha: (a: string) => `rgba(255,255,255, ${a})`,
    },
    neutral: {
      main: '#9a9a9a',
      light: '#b5b5b5',
      extraLight: '#cbcbcb',
      extraExtraLight: '#e9e9e9',
      dark: '#747474',
      alpha1: 'rgba(0,0,0,.1)',
      colorByAlpha: (a: string) => `rgba(154, 154, 154, ${a})`,
      textOnByAlpha: (a: string) => `rgba(0, 0, 0, ${a})`
    },
    success: {
      main: '#42b851',
      light: '#5bc969',
      extraLight: '#7ede8b',
      colorByAlpha: a => `rgba(66, 184, 81, ${a})`,
      textOnByAlpha: a => `rgba(255, 255, 255, ${a})`
    },
    warning: {
      main: '#E5C061',
      light: '#ECCE83',
      extraLight: '#EED595',
      dark: '#E0B23E',
      colorByAlpha: a => `rgba(229, 192, 97, ${a})`,
      textOnByAlpha: a => `rgba(255, 255, 255, ${a})`
    },
    danger: {
      main: 'rgb(227, 61, 61)',
      dark: 'rgb(181, 37, 37)',
      light: 'rgb(238, 80, 80)',
      textOn: 'rgb(255, 255, 255)',
      colorByAlpha: a => `rgba(227, 61, 61, ${a})`,
      textOnByAlpha: a => `rgba(255, 255, 255, ${a})`
    },
    blue: {
      main: '#347bed',
      dark: '#266ee0',
      light: '#5b99ff',
      extraLight: '#8fb9ff',
      alpha1: 'rgba(52, 123, 237, 0.1)',
      alpha2: 'rgba(52, 123, 237, 0.2)',
      colorByAlpha: a =>  `rgba(52, 123, 237, ${a})`,
      textOnByAlpha: a => `rgba(255, 255, 255, ${a})`
    },
    green: {
      main: '#9ccc64',
      colorByAlpha: a =>  `rgba(156, 204, 100, ${a})`,
      textOnByAlpha: a => `rgba(255, 255, 255, ${a})`
    },
    red: {
      main: '#ED5851',
      light: '#f56962',
      colorByAlpha: a =>  `rgba(237, 88, 81, ${a})`,
      textOnByAlpha: a => `rgba(255, 255, 255, ${a})`
    },
    orange: {
      main: '#ffa22a',
      light: '#ffae46',
      colorByAlpha: a =>  `rgba(255, 162, 42, ${a})`,
      textOnByAlpha: a => `rgba(255, 255, 255, ${a})`
    },
  },
  primary: {
    color: '#213a4b',
    colorRGB: 'rgb(33, 58, 75)',
    dark: '#0b202b',
    medium: '#314d66',
    lightMedium: '#3e637f',
    light: '#3e6380',
    extraLight: '#6589a6',
    extraExtraLight: '#8eb1cd',
    textOn: '#fff',
    fontFamily: '"Montserrat", sans-serif',
    colorByAlpha: (a: string) => `rgba(33, 58, 75, ${a})`,
    textOnByAlpha: (a: string) => `rgba(255,255,255, ${a})`
  },
  vibrant: {
    color: '#29c5ff',
    dark: '#13b7f4',
    light: '#48cbfc',
    alpha1: 'rgba(41, 197, 255, 0.1)',
    alpha2: 'rgba(41, 197, 255, 0.2)',
    alpha3: 'rgba(41, 197, 255, 0.3)',
    alpha4: 'rgba(41, 197, 255, 0.4)',
    alpha5: 'rgba(41, 197, 255, 0.5)',
    textOn: 'rgb(255, 255, 255)'
  },
  neutral: {
    color: '#9a9a9a',
    light: '#b5b5b5',
    extraLight: '#cbcbcb',
    extraExtraLight: '#e9e9e9',
    dark: '#747474',
    alpha1: 'rgba(0,0,0,.1)'
  },
  success: {
    color: '#42b851',
    light: '#5bc969',
    extraLight: '#7ede8b',
    colorByAlpha: (a: string) => `rgba(66, 184, 81, ${a})`,
  },
  danger: {
    color: 'rgb(227, 61, 61)',
    dark: 'rgb(181, 37, 37)',
    light: 'rgb(238, 80, 80)',
    textOn: 'rgb(255, 255, 255)',
    colorByAlpha: (a: string) => `rgba(227, 61, 61, ${a})`,
  },
  blue: {
    color: '#347bed',
    dark: '#266ee0',
    light: '#5b99ff',
    extraLight: '#8fb9ff',
    alpha1: 'rgba(52, 123, 237, 0.1)',
    alpha2: 'rgba(52, 123, 237, 0.2)'
  },
  green: {
    color: '#9ccc64'
  },
  red: {
    color: '#ED5851',
    light: '#f56962'
  },
  orange: {
    color: '#ffa22a',
    light: '#ffae46'
  },
  white: "#ffffff",
  slider: {
    padding: 15
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536
    },
  },
  grid: {
    grid_breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1281,
      xl: 1600
    },
    max_container_width: {
      xs: 0,
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1140
    },
    column_gutter: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0
    },
    outer_gutter: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0
    }
  },
  shadows: {
    id5: 'inset 0 19px 38px rgba(0,0,0,0.30), inset 0 15px 12px rgba(0,0,0,0.22)',
    id4: 'inset 0 14px 28px rgba(0,0,0,0.25), inset 0 10px 10px rgba(0,0,0,0.22)',
    id3: 'inset 0 10px 20px rgba(0,0,0,0.19), inset 0 6px 6px rgba(0,0,0,0.23)',
    id2: 'inset 0 3px 6px rgba(0,0,0,0.16), inset 0 3px 6px rgba(0,0,0,0.23)',
    id1: 'inset 0 1px 3px rgba(0,0,0,0.12), inset 0 1px 2px rgba(0,0,0,0.24)',
    d1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    d2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    d3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    d4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    d5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
    0: 'none',
    1: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    2: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    3: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    4: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    5: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    6: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    7: '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    8: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    9: '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    10: '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    11: '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    12: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    13: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    14: '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    15: '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    16: '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    17: '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    18: '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    19: '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    20: '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    21: '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    22: '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    23: '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    24: '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
  },
  orderedTagColors,
  tagColors: TAG_COLORS,
  typography: {
    fontFamily: '"Montserrat", Arial, Roboto',
    heavy: '1.2em "Montserrat", Arial, Roboto'
  }
};

export default theme;
