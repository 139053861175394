import React, { PropsWithChildren, useState } from 'react';
import styled from '@emotion/styled';
import theme from '../../../theme.ts';
import MobileSidebar from './MobileSidebar.tsx';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';

interface Props {
  title: string,
  barColor?: string,
}

export default function AppBar(props:PropsWithChildren<Props>) {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  return (
    <div
      style={{ 
        transform: 'scale(1)',
        paddingTop: 60,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <BarWrapper 
        id="bar"
        backgroundColor={props.barColor ? props.barColor : theme.palette.primary.light}
      >
        <IconButton
          sx={{
            color: 'primary.textOn',
            display: { lg: 'none' },
          }}
          onClick={() => { setMobileDrawerOpen(true); }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: { xs: 'none', lg: 'block' }, width: '8px' }} ></Box>
        <Title>{props.title}</Title>
        <ChildrenWrapper>
          {props.children}
        </ChildrenWrapper>
        <MobileSidebar 
          drawerOpen={mobileDrawerOpen}
          closeDrawer={() => { setMobileDrawerOpen(false); }}
        />
      </BarWrapper>
    </div>
  );
}

interface BarWrapperProps {
  backgroundColor: string 
}

const ChildrenWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
`;

const BarWrapper = styled.div<BarWrapperProps>`
  height: 45px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  padding: 0px 0px;
  padding-right: 6px;
  transition: background 300ms;
  z-index: 2;
  margin: 0;
  background: ${props => props.backgroundColor};
  width: 100%;
  justify-content: space-between;
`;

const Title = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  color: ${(props) => { return props.theme.palette.primary.textOn; }}
`;

