import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';

const Wrapper = styled('div')(({ theme }) => ({
  width: 200,
  background: theme.neutral.extraExtraLight,
  boxShadow: theme.shadows.d2,
  borderRadius: 4,
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowY: 'auto'
}));

const Filter = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  borderRadius: 3,
  height: 30,
  boxShadow: theme.shadows.id1,
  padding: 8,
  '&:focus': {
    outline: 'none',
    border: `2px solid ${theme.blue.extraLight}`
  }
}));

const List = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    background: 'none',
    width: 6
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0,0,0,.2)',
    width: 4,
    borderRadius: 4
  }
});

export const SelectorWrapper = styled('div')({
  position: 'fixed',
  left: '45%',
  top: '45%',
  zIndex: 9
});

export default class FilterableList extends React.Component {
  static defaultProps = {
    renderItem: () => null,
    filter: () => true,
    onSubmit: () => {},
    items: []
  };
  state = { filter: '', selectedIndex: 0 };
  filter = e => {
    if(this.props.onChange){
      this.props.onChange(e.target.value.toLowerCase(), e.target.value);
    }
    this.setState({ filter: e.target.value.toLowerCase() });
  };
  focus = () => {
    this.input.focus();
  };
  handleKeyPress = ({ keyCode }) => {
    if (keyCode === 13) {
      const filteredItems = this.props.items.filter((tag, i) =>
        this.props.filter(tag, this.state.filter, i)
      );
      if (this.state.selectedIndex || this.state.selectedIndex === 0) {
        this.props.onSubmit(
          filteredItems[
            this.state.selectedIndex < filteredItems.length - 1
              ? this.state.selectedIndex
              : filteredItems.length - 1
          ],
          filteredItems
        );
      }
    }
    if (keyCode === 40){
      const numItems = this.props.items.filter((tag, i) => this.props.filter(tag, this.state.filter, i)).length;
      if(this.state.selectedIndex !== numItems - 1){
        this.setState(state => ({selectedIndex: state.selectedIndex + 1}));
      }
    }
    if (keyCode === 38){
      if(this.state.selectedIndex !== 0){
        this.setState(state => ({selectedIndex: state.selectedIndex - 1}));
      }
    }
  };
  deselect = () => {
    if(this.state.selectedIndex !== null){
      this.setState({selectedIndex: null});
    }
  };
  reselect = () => {this.setState({selectedIndex: 0});};
  render() {
    const { items, renderItem, renderAction, filter, maxHeight = 300 } = this.props;
    const filteredItems = items.filter((tag, i) => filter(tag, this.state.filter, i));
    return (
      <Wrapper
        style={{
          maxHeight
        }}
      >
        <div className={css({
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 5
        })}>
          <Filter
            type="text"
            onKeyDown={this.handleKeyPress}
            onChange={this.filter}
            onBlur={this.deselect}
            onFocus={this.reselect}
            ref={input => {
              this.input = input;
            }}
          />
          {
            typeof renderAction === 'function' ?
              renderAction()
              : renderAction
          }
        </div>
        <List style={{ maxHeight }}>
          {
            filteredItems.map((item, i) => renderItem({
              item: item,
              index: i,
              length: filteredItems.length,
              selected: this.state.selectedIndex < filteredItems.length - 1 ? this.state.selectedIndex === i : i === filteredItems.length - 1,
              deselect: this.deselect,
              reselect: this.reselect
            }))
          }
        </List>
      </Wrapper>
    );
  }
}
