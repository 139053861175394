import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Chip, Fab, IconButton, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import AppBar from '../../components/Reusable/navigation/AppBar';
import DataGrid from '../../components/Reusable/data/DataGrid/DataGrid';
import { estimateTemplate, estimateRowType } from './estimateTemplate';
import { axios } from '../../api';
import { history } from '../../index';

interface customization {
  name: string,
  id: string,
  price: number,
  archived: boolean,
  applyTo: string
}

export default function Estimate() {
  const [prints, setPrints] = useState<number[]>([0]);
  const prevPrints = useRef<number[]>([]);
  const [rows, setRows] = useState<estimateRowType[]>([]);
  const prevRows = useRef<estimateRowType[]>([]);
  const [highTotal, setHighTotal] = useState<number>(0);
  const [lowTotal, setLowTotal] = useState<number>(0);
  const [customizationsList, setCustomizationsList] = useState<customization[]>([]);
  const [customizations, setCustomizations] = useState<customization[]>([]);
  const prevCustomizations = useRef<customization[]>([]);
  const [custoToAdd, setCustoToAdd] = useState<string>('');

  useEffect(() => {
    axios.get('/lines/design/customization', {
      params: {
        archived: false
      }
    })
      .then((res) => {
        setCustomizationsList(res.data.result);
      });
  },[]);

  useEffect(() => {
    if(rows.length <= 0) {
      setHighTotal(0);
      setLowTotal(0);
    }

    if(
      rows.length == prevRows.current.length && 
      prints.length === prevPrints.current.length &&
      customizations.length == prevCustomizations.current.length &&
      rows.every((row, index) => (
        row.hItemTotal == prevRows.current[index].hItemTotal &&
          row.lItemTotal == prevRows.current[index].lItemTotal &&
          row.stylenumber == prevRows.current[index].stylenumber
      )) && 
      prints.every((print, index) => (print == prevPrints.current[index]) &&
      customizations.every((custo, index) => (
        custo.name == prevCustomizations.current[index].name &&
        custo.price == prevCustomizations.current[index].price
      )))
    ) {
      return;
    }

    prevPrints.current = structuredClone(prints);
    prevRows.current = structuredClone(rows);
    prevCustomizations.current = structuredClone(customizations);

    const temp = [...rows].filter((row) => row.stylenumber && row.lItemTotal && row.hItemTotal);
    const savedRows = [...rows].filter((row) => !(row.stylenumber && row.lItemTotal && row.hItemTotal));

    const customizationsTotal = customizations.reduce((acc, custo) => {
      return acc += Number(custo.price);
    }, 0.0);

    axios.get('/estimates', {
      params: {
        rows: temp,
        prints,
        customizationsTotal
      }
    })
      .then((res) => {
        if(res.data?.rows?.length) {
          setHighTotal(res.data.highTotal);
          setLowTotal(res.data.lowTotal);
          setRows([...res.data.rows, ...savedRows]);
        }
      });
  }, [rows, prints, customizations]);

  function addCustomization() {
    const custo = customizationsList.find((customization) => customization.id === custoToAdd);
    if(!custo) return;
    const tmp = structuredClone(customizations);
    tmp.push(custo);
    setCustomizations(tmp);
  }

  function createOrderFromEstimates() {
    axios.post('/estimates/create', {
      customizations,
      prints,
      stylenumbers: rows.map((row) => row.stylenumber)
    })
      .then((res) => {
        history.push(`/workorder/edit/${res.data.document_id}`);
      });
  }

  function deleteCustomization(index : number) {
    const tmp = structuredClone(customizations);
    tmp.splice(index, 1);
    setCustomizations(tmp);
  }

  function getLowItemsTotal() {
    const temp = [...rows].filter((row) => row.stylenumber && row.lItemTotal && row.hItemTotal);
    return temp.reduce((acc, row) => {
      return acc + Number(row.lItemTotal);
    }, 0);
  }

  function getHighItemsTotal() {
    const temp = [...rows].filter((row) => row.stylenumber && row.lItemTotal && row.hItemTotal);
    return temp.reduce((acc, row) => {
      return acc + Number(row.hItemTotal);
    }, 0);
  }

  return (
    <Box>
      <AppBar
        title="Estimates"
        barColor="#2AB6F6"
      ></AppBar>
      <Box
        sx={{ px: 2 }}
      >
        <Stack
          direction="row"
        >
          <p>Print</p>
          {prints.map((print, i) => {
            return (
              <TextField
                key={i}
                size="small"
                sx={{
                  width: 40,
                  margin: 1
                }}
                label=""
                value={print}
                onChange={(e) => { 
                  if(Number(e.target.value) > 6 || Number(e.target.value) < 0) return;
                  const newPrints = [...prints];
                  newPrints[i] = Number(e.target.value);
                  setPrints(newPrints);
                }}
                onBlur={() => {}}
              />
            );
          })}
          <IconButton
            onClick={() => {
              setPrints([...prints, 0]);
            }}
          >
            <AddCircleIcon/>
          </IconButton>
          { prints.length > 1 &&
            <IconButton
              onClick={() => {
                setPrints([...prints.slice(0, -1)]);
              }}
            >
              <RemoveCircleIcon/>
            </IconButton>
          }
        </Stack>
        <DataGrid
          drawerContent={
            <Box
              sx={{p: 2}}
            > 
              <Stack
                direction="row"
                flexWrap="wrap"
                spacing={1}
                sx={{ mb: 1 }}
              >
                { customizations.map((customization, index) => {
                  return(
                    <Chip
                      key={customization.id}
                      sx={{ mb: 1}}
                      label={`${customization.name} - $${customization.price.toFixed(2)}`}
                      color="primary"
                      onDelete={() => { deleteCustomization(index); }}
                      deleteIcon={<HighlightOffIcon/>}
                    />
                  );
                }) }
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center'
                }}
              >
                <Select
                  size="small"
                  value={custoToAdd}
                  onChange={(e) => { setCustoToAdd(e.target.value); }}
                >
                  <MenuItem
                    dense
                    value={0}
                  ><em>None</em></MenuItem>
                  {customizationsList.map((custo) => {
                    return (
                      <MenuItem
                        dense
                        key={custo.id}
                        value={custo.id}
                      >{custo.name + ' - '}<em style={{ marginLeft: '4px'}}>Price: (${custo.price.toFixed(2)})</em></MenuItem>
                    );
                  })}
                </Select>
                <Button
                  variant="contained"
                  onClick={() => { addCustomization(); }}
                >
                          Add Customization
                </Button>
              </Stack>
            </Box>
          }
          columns={estimateTemplate}
          rows={rows}
          sortableByHeader={false}
          columnsHideable={false}
          onAddRow={(newRow : estimateRowType) => {
            const newRows = structuredClone(rows);
            newRows.push(newRow);
            setRows(newRows);
          }}
          onChangeRow={(updatedRowIndex : number, updatedRows: estimateRowType[]) => {
            const newRows = structuredClone(rows);
            newRows[updatedRowIndex] = updatedRows[updatedRowIndex];
            setRows([...newRows]);
          }}
        >
        </DataGrid>
        <Stack
          width="100%"
          alignItems="center"
        >
          <Typography
            variant="h2"
          >
            {getLowItemsTotal()} Items-{getHighItemsTotal()} Items
          </Typography>
          <Typography 
            variant="h2"
          >${lowTotal}-${highTotal}</Typography>
        </Stack>
      </Box>
      <Fab
        sx={{ 
          position: 'absolute', 
          bottom: '4vh', 
          right: '4vh',
          // @ts-ignore
          background: (theme) => theme.palette.vibrant.main
        }}
        variant="extended"
        onClick={() => {
          createOrderFromEstimates();
        }}
      >
        <AddIcon 
          sx={{ mr:1 }}
        />
        Create Order From Estimate
      </Fab>
    </Box>
  );
}
