import parseJwt from './jwt/parseJwt.js';
import store from '@/store.js';

// loggedInSuccess
export default response => {
  if (response.data.auth) {
    const token = response.data.token ? response.data.token : window.localStorage.getItem('token');
    const { user_account_id, user_group_id } = response.data && response.data.user ? response.data.user : parseJwt(token);

    if (token) window.localStorage.setItem('token', token);
    setTimeout(() => {
      store.dispatch({
        type: '@@APP/SET_USER_ACCOUNT_ID',
        user_account_id: user_account_id,
        user_group_id: user_group_id,
      });
    }, 50);
  } else {
    console.log(response.data.message ? response.data.message : 'failed to login.');
  }
};
