import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { axios } from '../../api';
import { uploadProfileImage } from '../../queries';
import type userAccount from '../../types/models/userAccount';
import type userProfile from '../../types/models/userProfile';
import type userGroup from '../../types/models/userGroup';
import Modal from '../../components/Reusable/layout/Modal';
import { Box, Button, MenuItem, Select, Stack, TextField, Grid, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import UploadCropper from '../../components/UploadCropper';
import { BASE_URL } from '../../api';
import security_questions from '../../data/securityQuestions';

interface UserModalProps {
  viewingUser: number, // -1 is closed, 0 is new, any other number should be the account id
  onClose: () => void,
  isOpen?: boolean,
  dispatch: (thing: any) => void
}

type User = userAccount & { user_profile: userProfile };
const defaultUser: User = {
  email: '',
  active: true,
  username: '',
  menu: 0,
  user_group_id: 0,
  security_question: 0,
  security_answer: 'badger',
  user_profile: {
    profilepicture: '',
    first_name: '',
    last_name: '',
    phone: '',
  }
}

const showMeOptions = [
  {
    label: 'Office Only',
    value: 0,
  },
  {
    label: 'Production Only',
    value: 1,
  },
  {
    label: 'Both',
    value: 2,
  },
  {
    label: 'Neither',
    value: 3,
  }
];

function UserModal(props:UserModalProps) {

  const [newUser, setNewUser] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [groups, setGroups] = useState<userGroup[]>([]);

  useEffect(() => {
    getUserGroups();
  }, []);

  useEffect(() => {
    if(!props.viewingUser || props.viewingUser === -1) return;
    if(props.viewingUser === 0) {
      setUser(defaultUser);
      setNewUser(true);
    } else {
      getUser();
      setNewUser(false);
    }
  }, [props.viewingUser]);

  function getUser() {
    axios.get('/users/account/' + props.viewingUser)
      .then((res) => { 
        setUser(res.data.result); 
      });
  }

  function getUserGroups() {
    axios.get('/users/groups')
      .then((res) => {
        setGroups(res.data.results);
      });
  }

  function upload({ file, onComplete, onError }: { file: File, onComplete: () => void, onError: () => void }) {
    uploadProfileImage({
      file,
      user_account_id: user?.user_account_id,
      onComplete,
      onError,
    });
  }

  function createUser() {
    if(
      !user?.email ||
        !user?.username ||
        !user?.user_profile.first_name ||
        !user?.user_profile.last_name ||
        !user?.user_profile.phone
    ) {
      props.dispatch({
        type: 'ERROR/SET',
        error: 'Please fill in your email, username, first_name, last_name, and phone'
      });
      return;
    }
    axios.post('/users', {
      email: user?.email,
      user_group_id: user?.user_group_id,
      active: user?.active,
      username: user?.username,
      menu: user?.menu,
      security_question: user?.security_question,
      security_answer: user?.security_answer,
      first_name: user?.user_profile.first_name,
      last_name: user?.user_profile.last_name,
      phone: user?.user_profile.phone
    })
      .then(() => {
        props.onClose();
      });
  }

  function updateUser() {
    Promise.all([
      axios.put('/users/profile', {
        user_profile_id: user?.user_profile.user_profile_id,
        first_name: user?.user_profile.first_name,
        last_name: user?.user_profile.last_name,
        phone: user?.user_profile.phone
      }),
      axios.put('/users/account', {
        email: user?.email,
        user_account_id: user?.user_account_id,
        user_group_id: user?.user_group_id,
        active: user?.active,
        username: user?.username,
        menu: user?.menu,
        security_question: user?.security_question,
        security_answer: user?.security_answer
      })
    ])
      .then(() => {
        props.onClose();
      });
  }

  return (
    <Modal
      title="Edit User"
      open={
        (props.isOpen === undefined && props.viewingUser > -1) || 
        !!props.isOpen
      }
      onClose={props.onClose}
    >
      { !user ? 'There is no user!' : 
        <Stack
          width={'100%'}
          spacing={.5}
          direction={'column'}
        >
          <Box 
            display="flex"
          >
            <Grid container>
              <Grid item xs={6}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{m: 2}}
                >
                  <UploadCropper
                    image={`${BASE_URL}/api/documentimages/${'CACHE_BUST'}${user?.user_profile.profilepicture || ''}`}
                    onUpload={upload}
                  />
                  <TextField
                    label="First Name"
                    value={user?.user_profile.first_name}
                    onChange={(e) => {
                      const tmpUser: User = { ...user };
                      tmpUser.user_profile.first_name = e.target.value;
                      setUser(tmpUser);
                    }}
                    size="small"
                  />

                  <TextField
                    label="Last Name"
                    value={user?.user_profile.last_name}
                    onChange={(e) => {
                      const tmpUser: User = { ...user };
                      tmpUser.user_profile.last_name = e.target.value;
                      setUser(tmpUser);
                    }}
                    size="small"
                  />

                  <TextField
                    label="Email"
                    value={user?.email}
                    onChange={(e) => {
                      const tmpUser: User = { ...user };
                      tmpUser.email = e.target.value;
                      setUser(tmpUser);
                    }}
                    size="small"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{mr: 2, my: 2}}
                >

                  <TextField
                    label="Username"
                    value={user?.username}
                    onChange={(e) => {
                      const tmpUser: User = { ...user };
                      tmpUser.username = e.target.value;
                      setUser(tmpUser);
                    }}
                    size="small"
                  />

                  <Select
                    label="User Type"
                    value={user?.user_group_id}
                    onChange={(e) => {
                      const tmpUser: User = { ...user };
                      tmpUser.user_group_id = parseInt(e.target.value.toString());
                      setUser(tmpUser);
                    }}
                    size="small"
                  >
                    {groups.map((group) => {
                      return (
                        <MenuItem 
                          key={group.user_group_id}
                          value={group.user_group_id}
                        >{group.name}</MenuItem>
                      );
                    })}
                  </Select>

                  <TextField
                    label="Phone"
                    value={user?.user_profile.phone}
                    onChange={(e) => {
                      const tmpUser: User = { ...user };
                      tmpUser.user_profile.phone = e.target.value;
                      setUser(tmpUser);
                    }}
                    size="small"
                  />

                  <Select
                    label="Security Question"
                    value={user?.security_question}
                    onChange={(e) => {
                      const tmpUser: User = { ...user };
                      tmpUser.security_question = parseInt(e.target.value.toString());
                      setUser(tmpUser);
                    }}
                    size="small"
                  >
                    {security_questions.map((question) => {
                      return (
                        <MenuItem
                          key={question.value}
                          value={question.value}
                        >{question.label}</MenuItem>
                      );
                    })}
                  </Select>

                  <TextField
                    label="Security Answer"
                    value={user?.security_answer}
                    onChange={(e) => {
                      const tmpUser: User = { ...user };
                      tmpUser.security_answer = e.target.value;
                      setUser(tmpUser);
                    }}
                    size="small"
                  />

                  <FormGroup>
                    <FormControlLabel control={<Checkbox 
                      checked={!!user?.active}
                      onChange={(e) => {
                        const tmpUser: User = { ...user };
                        tmpUser.active = e.target.value === 'true';
                        setUser(tmpUser);
                      }}
                    />} label="Active"/>
                  </FormGroup>


                  <Select
                    label="Show Me On"
                    value={user?.menu}
                    onChange={(e) => {
                      const tmpUser: User = { ...user };
                      tmpUser.menu = parseInt(e.target.value.toString());
                      setUser(tmpUser);
                    }}
                    size="small"
                  >
                    {showMeOptions.map((showMe) => {
                      return(
                        <MenuItem
                          key={showMe.value}
                          value={showMe.value}
                        >{showMe.label}</MenuItem>
                      );
                    })}
                  </Select>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Button
            variant="contained"
            sx={{ m: 2 }}
            onClick={() => newUser ? createUser() : updateUser() }
          >Update User</Button>
        </Stack>
      }
    </Modal>
  );
}

export default connect(() => ({}))(UserModal);
