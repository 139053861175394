import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from 'emotion';
import TitleBar from './TitleBar';
import V2EditGrid from '../../components/Collections/V2EditGrid.js';
import WorkorderGrid from '@components/Containers/orderGrid/WorkorderGrid.js';
import ThumbnailGallery from '@components/ThumbnailGallery';
import GalleryToolbar from './GalleryToolbar';
import { DocumentFollowers } from './Followers';
import PeopleIcon from 'react-icons/lib/md/people';
import ColemanOrder from '@components/ColemanOrder';
import NamesAndNumbers from '@components/NameAndNumbers';
import { SmallLabel } from '@components/Typography';
import LabelIcon from 'react-icons/lib/md/label';
import Tags from './Tags';
import { BASE_URL, axios, createHistory, serverSentEventSub } from '@/api.js';
import { format } from 'date-fns';
import PickupModal from '@components/PickupModal';
import InventorySlider from './InventorySlider.js';
import Fab from '@mui/material/Fab';
import InventoryIcon from '@mui/icons-material/Inventory';
import CustomerAccountBar from '../../components/Containers/editPage/CustomerAccountBar.tsx';
import { Stack, TextField } from '@mui/material';

class Edit extends React.Component {
  constructor(props) {
    super();
    this.state = {
      emailConfirmation: false,
      document_id: props.match.params.document_id,
      document: {},
      followers: [],
      production_card: {},
      tags: [],
      sliderOpen: false,
    };
  }

  componentDidMount() {
    serverSentEventSub.addEventListener('updateDocument', this.handleServerSentEvent);
    serverSentEventSub.addEventListener('updateSidebar', this.handleServerSentSidebar);
    this.getDocument();
    this.getFollowers();
    this.getTags();
    this.getProductionCard();
  }

  componentWillUnmount() {
    serverSentEventSub.removeEventListener('updateDocument', this.handleServerSentEvent);
    serverSentEventSub.removeEventListener('updateSidebar', this.handleServerSentSidebar);
  }

  createDocumentTag = tag => {
    axios.post('/document/tags', {
      document_id: this.state.document_id,
      tag_id: tag.tag_id,
    })
      .then(() => {
        createHistory({
          document_id: this.state.document_id,
          json: { title: tag.title },
          type: 'tags',
          key: 'createTag',
          user_id: this.props.user_account_id
        });
        this.getTags();
      });
  };

  createFollower = follower => {
    axios.post('/document/followers', {
      id: this.state.document_id,
      user_account_id: follower.user_account_id
    })
      .then(() => {
        const followers = this.state.followers;
        followers.push(follower);
        this.setState({followers});
        createHistory({
          document_id: this.state.document_id,
          json: { user_account_id: follower.user_account_id },
          type: 'followers',
          key: 'createFollower',
          user_id: this.props.user_account_id
        });
        //this.getFollowers();
      });
  };

  deleteFollower = user_account_id => {
    axios.delete('/document/followers', {
      params: {
        id: this.state.document_id,
        user_account_id
      }
    })
      .then(() => {
        createHistory({
          document_id: this.state.document_id,
          json: { user_account_id },
          type: 'followers',
          key: 'deleteFollower',
          user_id: this.props.user_account_id
        });
        this.getFollowers();
      });
  };

  deleteTag = (tag_id, tag_title) => {
    axios.delete('/document/tags', {
      params: {
        document_id: this.state.document_id,
        tag_id,
      }
    })
      .then(() => {
        createHistory({
          document_id: this.state.document_id,
          json: { title: tag_title },
          type: 'tags',
          key: 'deleteTag',
          user_id: this.props.user_account_id
        });
        this.getTags();
      });
  };

  getDocument = () => {
    axios.get('/document', {
      params: {
        id: this.state.document_id
      }
    })
      .then((res) => {
        this.setState({document: res.data.document});
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error trying to get the document.'
        });
      });
  };

  getFollowers = () => {
    axios.get('/document/followers', {
      params: {
        id: this.state.document_id
      }
    })
      .then((res) => {
        this.setState({ followers: res.data.results });
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error trying to get the followers.'
        });
      });
  };

  getProductionCard = () => {
    axios.get('/calendar/card', {
      params: {
        document_id: this.state.document_id
      }
    })
      .then((res) => {
        this.setState({production_card: res.data.result});
      });
  };

  getTags = () => {
    axios.get('/document/tags', {
      params: {
        document_id: this.state.document_id
      }
    })
      .then((res) => {
        this.setState({ tags: res.data.results });
      })
      .catch(() => {
      });
  };

  handleServerSentEvent = (event) => {
    const document = this.state.document;
    const eventData = JSON.parse(event.data);
    if(eventData.document_id !== this.state.document_id) {
      return;
    }
    Object.assign(document, eventData);
    this.setState({document});
  };

  handleServerSentSidebar = (event) => {
    const document = this.state.document;
    const eventData = JSON.parse(event.data);
    if(eventData.document_id !== this.state.document_id) {
      return;
    }
    Object.assign(document, eventData);
    this.setState({document});
  };

  reloadTotal = () => {
    axios.get('/document', {
      params: {
        id: this.state.document_id,
        attributes: ['total']
      }
    })
      .then((res) => {
        const document = this.state.document;
        document.total = res.data.document.total;
        this.setState({document});
      });
  };

  setDueDate = duedate => {
    axios.put('/document/singleField', {
      id: this.state.document.document_id,
      field: 'duedate',
      fieldValue: duedate
    })
      .then(() => {
        createHistory({
          document_id: this.state.document_id,
          json_previous: JSON.stringify(this.state.document.duedate),
          json: JSON.stringify(duedate),
          type: 'date',
          key: 'duedate',
          user_id: this.props.user_account_id,
        });
      });
  };

  setJobName = jobname => {

    axios.put('/document/singleField', {
      id: this.state.document_id,
      field: 'jobname',
      fieldValue: jobname
    })
      .then(() => {
        createHistory({
          document_id: this.state.document_id,
          json_previous: JSON.stringify(this.state.document.jobname),
          json: JSON.stringify(jobname),
          type: 'string',
          key: 'jobname',
          user_id: this.props.user_account_id,
        });
      });
  };

  setShipNote = () => {
    axios.put('/document/singleField', {
      id: this.state.document_id,
      field: 'shipNote',
      fieldValue: this.state.document.shipNote
    });
  }

  setWorkcenter = workcenter => {
    const originalWorkcenter = this.state.document.workcenter;

    axios.put('/document/singleField', {
      id: this.state.document_id,
      field: 'workcenter',
      fieldValue: workcenter
    })
      .then(() => {
        if (workcenter == '6') {
          this.state.followers.forEach(follower => {
            this.deleteFollower(follower.user_account_id);
          });

          if(this.state.document.jobname != 'null' && this.state.document.customer.customerEmail != 'null' && this.props.document.customer.firstname != 'null') {
            this.setState({
              emailConfirmation: true
            });
          }
        }

        createHistory({
          document_id: this.state.document_id,
          json_previous: originalWorkcenter,
          json: workcenter,
          type: 'workcenter',
          key: 'workcenter',
          user_id: this.props.user_account_id,
        });
      });
  };


  updateProductionDate = () => {
    this.getProductionCard();
  };

  updateTotal = (total) => {
    const document = this.state.document;
    document.total = total;
    this.setState({ document });
  };

  render() {
    const {
      billto,
      customerartwork,
      customerartobject: art_object,
      customer_id,
      customer,
      document_id: id,
      document_id,
      duedate,
      hasshipping,
      isarchived,
      jobname,
      lines,
      shipNote,
      total,
      totalPaid,
      version,
      workcenter,
    } = this.state.document;

    // I want to eventually remove this
    const tags = this.state.tags.map((tag) => {
      return tag.tag;
    });
    const customerartworkWithWOnumber = (customerartwork ? customerartwork : '').split('/').reduce((previousValue, currentValue, currentIndex) => {
      if(currentIndex == customerartwork.split('/').length - 1) {
        return previousValue + '/' + document_id + '-' + currentValue;
      }

      return previousValue + '/' + currentValue;
    });

    const card = this.state.production_card ?? {};
    const production_date = card.date ? format(card.date, 'M/D/YY') : '';

    return (
      <Wrapper>
        <TitleBar
          name={jobname}
          due={duedate}
          document_id={this.state.document_id}
          production_date={production_date}
          onProductionDateChange={this.updateProductionDate}
          total={total}
          totalPaid={totalPaid}
          setJobName={this.setJobName}
          hasshipping={hasshipping}
          setDueDate={this.setDueDate}
          activeWorkcenter={workcenter}
          setWorkcenter={this.setWorkcenter}
          isarchived={isarchived}
          version={version}
        />
        <Stack
          direction="row"
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ 
              pb: 2,
              minWidth: '50%',
              maxWidth: '75%'
            }}
          >
            { !!document_id && !!billto && Number(billto) !== 0 ?
              <>
                <CustomerAccountBar
                  document_id={document_id}
                  customer_id={customer_id}
                  disable_account={true}
                  width={hasshipping ? '32%' : '50%'}
                />
                <CustomerAccountBar
                  document_id={document_id}
                  customer_id={billto}
                  disable_account={true}
                  setCustomer={(id) => {
                    axios.put('/document/singleField', {
                      id: document_id,
                      field: 'billto',
                      fieldValue: id
                    });
                  }}
                  width={hasshipping ? '32%' : '50%'}
                />
              </>
              : <CustomerAccountBar 
                document_id={document_id}
                customer_id={customer_id}
                account_id={this.state.document.quickbooksAccount}
                customQuickbooksBillTo={this.state.document.customQuickbooksBillTo}
              />
            }

            {hasshipping ? <TextField 
              sx={{ 
                width: '33%',
                height: '100%'
              }}
              size="small"
              multiline
              rows={8}
              label="Shipping Note"
              value={shipNote}
              onChange={(e) => { 
                this.setState({ 
                  document: { ...this.state.document, shipNote: e.target.value } 
                }); 
              }}
              onBlur={() => { this.setShipNote(); }}
            /> : null}
          </Stack>
          <Stack
            sx={{ 
              ml: 1,
              maxWidth: '25%',
              width: '15%'
            }}
          >


            <SmallLabel icon={<PeopleIcon />} label="Followers" />
            <DocumentFollowers
              followers={this.state.followers}
              onSelect={this.createFollower}
              deleteFollower={this.deleteFollower}
            />




          </Stack>
          <Stack
            sx={{ 
              ml: 1,
              maxWidth: '25%',
              width: '15%'
            }}
          >
            <SmallLabel icon={<LabelIcon />} label="Tags" />
            <Tags
              tags={tags}
              onTagSelect={this.createDocumentTag}
              deleteTag={this.deleteTag}
            />
          </Stack>
        </Stack>
        {version && <GridWrapper>
          {version === 3 ?
            <WorkorderGrid
              document_id={this.state.document_id}
              documentSideEffects={this.reloadTotal}
            />
            : <V2EditGrid
              document_id={this.state.document_id}
              lines={lines}
              linesConfig={this.state.document.linesConfig}
              billto={this.state.document.billto}
              user_account_id={this.props.user_account_id} // Need to eventually refactor this into the component, but that's fine
              updateTotal={this.updateTotal}
            />}
        </GridWrapper>}

        <NamesAndNumbers
          document_id={this.state.document_id}
        />

        <ColemanOrder
          document_id={this.state.document_id}
        />

        <FlexRow
          className={css({
            marginTop: 15,
            paddingLeft: 15,
            paddingRight: 15,
          })}
        >
          <GalleryToolbar
            document_id={document_id}
            refetch={this.getDocument}
            jobname={jobname}
            downloadPath={
              art_object &&
              `/documentimages/${
                art_object.cache_guid
              }${customerartworkWithWOnumber}`
            }
            backupDownloadPath={
              art_object &&
              `/documentimages/${
                art_object.cache_guid
              }${customerartwork}`
            }
            user_account_id={this.props.user_account_id}
          />
        </FlexRow>
        <FlexRow className={css({ marginTop: 15 })}>
          <ThumbnailGallery
            images={
              art_object &&
              art_object.idArray && {
                full: art_object.idArray.map(
                  i =>
                    `${BASE_URL}/api/documentimages/${art_object.cache_guid}${
                      art_object.previewPath
                    }art_lg_${i.toString() +
                      (art_object.document_id ? '.jpg' : '.png')}`
                ),
                thumbs: art_object.idArray.map(
                  i =>
                    `${BASE_URL}/api/documentimages/${art_object.cache_guid}${
                      art_object.previewPath
                    }art_sm_${i}.jpg?timestamp=${new Date().getTime()}`
                ),
              }
            }
          />
        </FlexRow>
        {this.state.document &&
         <PickupModal
           isOpen={this.state.emailConfirmation}
           document={{
             firstname: customer?.firstname,
             jobname: jobname,
             document_id: this.state.document_id,
             customerEmail: customer?.email
           }}
           onModalClose={() => {
             this.setState({ emailConfirmation: false });
           }}
         />
        }
        <InventorySlider
          isOpen={this.state.sliderOpen}
          onModalClose={() => {
            this.setState({ sliderOpen: false });
          }}
        />
        { !this.state.sliderOpen &&
          <Fab
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16
            }}
            size="medium"
            onClick={() => {
              this.setState({ sliderOpen: true });
            }}
          >
            <InventoryIcon />
          </Fab>
        }
      </Wrapper>
    );
  }
}

export default connect((state) => ({
  user_account_id: state.app.user_account_id,
}))(Edit);

const FlexRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const GridWrapper = styled('div')({
  padding: '0px 15px',
});

const Wrapper = styled('div')({
  padding: 15,
  position: 'relative',
  paddingTop: 70,
});
