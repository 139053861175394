import React from 'react';
import { connect } from 'react-redux';
import compose from '../../utils/compose';
import WorkCenterButton from '../WorkCenters/WorkCenterButton';
import { SidebarHeader, BackButton } from '@components/shell/Sidebar';
import { withLastLocation } from 'react-router-last-location';
import { withTheme } from 'emotion-theming';

class SystemAdminSidebar extends React.Component {
  componentDidMount = () => {
    if (
      (this.props.lastLocation
        ? this.props.lastLocation.pathname
        : ''
      ).includes('workcenters')
    ) {
      this.props.dispatch({
        type: 'SA.SET_CAME_FROM',
        cameFrom: this.props.lastLocation.pathname,
      });
    }
  }
  navigateToWorkcenters = () => {
    if (this.props.cameFrom) {
      this.props.history.push(this.props.cameFrom);
    } else {
      this.props.history.push('/workcenters/myorders');
    }
  }
  render() {
    const { theme } = this.props;
    return (
      <div>
        <SidebarHeader allowMini>
          <BackButton onClick={this.navigateToWorkcenters} />
        </SidebarHeader>
        <WorkCenterButton
          href="/admin/employees"
          name="Employees"
          active={this.props.location.pathname === '/admin/employees'}
          htmlcolor={theme.primary.light}
        />
        <WorkCenterButton
          href="/admin/employeeorders"
          name="Employee Orders"
          active={this.props.location.pathname === '/admin/employeeorders'}
          htmlcolor={theme.primary.light}
        />
        <WorkCenterButton
          href="/admin/inventory"
          name="Inventory"
          active={this.props.location.pathname === '/admin/inventory'}
          htmlcolor={theme.primary.light}
        />
        <WorkCenterButton
          href="/admin/manageQuickbooks"
          name="Manage Quickbooks"
          active={this.props.location.pathname === '/admin/manageQuickbooks'}
          htmlcolor={theme.primary.light}
        />
        <WorkCenterButton
          href="/admin/productsheets"
          name="Product Sheets"
          active={this.props.location.pathname === '/admin/productsheets'}
          htmlcolor={theme.primary.light}
        />
        <WorkCenterButton
          href="/admin/tags"
          name="Tags"
          active={this.props.location.pathname === '/admin/tags'}
          htmlcolor={theme.primary.light}
        />
        <WorkCenterButton
          href="/admin/metrics"
          name="Metrics"
          active={this.props.location.pathname === '/admin/metrics'}
          htmlcolor={theme.primary.light}
        />
        <WorkCenterButton
          href="/admin/surveyresults"
          name="Survey"
          active={this.props.location.pathname === 'admin/surveyresults'}
          htmlcolor={theme.primary.light}
        />
        <WorkCenterButton
          href="/admin/settings"
          name="Settings"
          active={this.props.location.pathname === '/admin/Settings'}
          htmlcolor={theme.primary.light}
        />
      </div>
    );
  }
}
export default compose(
  connect(state => ({
    activeWorkcenter: state.workCentersPage.activeWorkcenter,
    cameFrom: state.systemAdminPage.cameFrom,
  })),
  withLastLocation,
  withTheme
)(SystemAdminSidebar);
