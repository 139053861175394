import React from 'react';
import { connect } from 'react-redux';
import { css } from 'emotion';
import styled from '@emotion/styled';
import theme from '../theme';

const StatusMessage = styled('div')(({theme}) => ({
  position: 'absolute',
  height: '100%',
  zIndex: 9,
  width: 250,
  right: -50,
  background: theme.primary.color,
  opacity: 0,
  pointerEvents: 'none',
  userSelect: 'none',
  lineHeight: 1.3,
  transition: 'opacity 200ms',
  textAlign: 'center',
  // whiteSpace: 'nowrap',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12,
  fontStyle: 'italic',
  fontWeight: 600,
  paddingLeft: 15,
  color: theme.primary.textOn,
  '&:before': {
    content: '" "',
    position: 'absolute',
    left: -50,
    top: 0,
    width: 50,
    height: '100%',
    background: `linear-gradient(to right, ${theme.primary.colorByAlpha(0)}, ${theme.primary.colorByAlpha(1)})`
  }
}));

export class NetworkMonitor extends React.Component{
  getColor = () => {
    switch (this.props.queryStatus) {
    case 'mutating':
    case 'querying':
      return theme.vibrant.color;
    case 'error':
      return theme.danger.color;
    case 'mutated':
      return theme.success.color;
    default:
      return theme.neutral.color;
    }
  }
  getStatusMessage = () => {
    switch (this.props.queryStatus) {
    case 'mutating':
      return 'Saving changes...';
    case 'querying':
      return 'Getting data...';
    case 'error':
      return 'Something went wrong!';
    case 'mutated':
      return 'All changes synced successfully';
    default:
      return null;

    }
  }
  render(){
    const { queryStatus } = this.props;
    const mutating = queryStatus === 'mutating';
    const statusMessage = this.getStatusMessage();
    return(
      <div className={css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 10,
        paddingLeft: 10,
        position: 'relative',
        height: '100%',
        '&:hover': {
          '& .network-status-message': {
            opacity: 1
          }
        }
      })}>
        <div className={`${css({
          width: 10,
          height: 10,
          borderRadius: '100%',
          background: this.getColor()
        })} ${mutating ? 'pulse' : ''}`} />
        {
          statusMessage &&
          <StatusMessage className="network-status-message">
            {statusMessage}
          </StatusMessage>
        }
      </div>
    );
  }
}
export default connect(
  state => ({
    queryStatus: state.app.queryStatus
  })
)(NetworkMonitor);
