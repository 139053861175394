import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import InfiniteScroll from 'react-infinite-scroller';

/**
 * InfiniteScrollList
 *
 * A list that can handle VERY large amounts of data by refetching when you scroll to the bottom
 *
 * props:
    * items - an array of the items to be listed
    * hasMore - an boolean indicating whether or not there are more items to render
    * loadMore - a function that adds more items to the items array
    * onSubmit - a function that details what to do with the selected items
    * left - absolute position left
    * top - absolute position top
    * maxHeight - the tallest that this list should render as
    * renderItem - a function detailing how to render each list item
    * renderAction - Component which will be placed to the right of the filter (generally used for an action button)
    * onChange - a function that details what to do with the filter value when it is changed
  *
  * This component's data is meant to be handled outside of the component. The props allow for minute
  * control over fetching and organizing the data and should be sufficient to accomodate any
  * large datasets
 * */

export default class InfiniteScrollList extends React.Component {

  static defaultProps = {
    renderItem: () => null,
    filter: () => true,
    onSubmit: () => {},
    items: []
  };

  state = { filter: '', selectedIndex: 0 };

  deselect = () => {
    if(this.state.selectedIndex !== null){
      this.setState({selectedIndex: null});
    }
  };

  filter = e => {
    if(this.props.onChange){
      this.props.onChange(e.target.value.toLowerCase(), e.target.value);
    }
    this.setState({ filter: e.target.value.toLowerCase() });
  };

  focus = () => {
    this.input.focus();
  };

  handleKeyPress = ({ keyCode }) => {
    if (keyCode === 13) {
      const filteredItems = this.props.items.filter((tag, i) =>
        this.props.filter(tag, this.state.filter, i)
      );
      if (this.state.selectedIndex || this.state.selectedIndex === 0) {
        this.props.onSubmit(
          filteredItems[
            this.state.selectedIndex < filteredItems.length - 1
              ? this.state.selectedIndex
              : filteredItems.length - 1
          ],
          filteredItems
        );
      }
    }
    if (keyCode === 40){
      const numItems = this.props.items.filter((tag, i) => this.props.filter(tag, this.state.filter, i)).length;
      if(this.state.selectedIndex !== numItems - 1){
        this.setState(state => ({selectedIndex: state.selectedIndex + 1}));
      }
    }
    if (keyCode === 38){
      if(this.state.selectedIndex !== 0){
        this.setState(state => ({selectedIndex: state.selectedIndex - 1}));
      }
    }
  };

  reselect = () => {this.setState({selectedIndex: 0});};

  render() {
    const {
      items,
      renderItem,
      renderAction,
      filter,
      hasMore,
      loadMore,
      loader,
      maxHeight = 300,
      left,
      top,
    } = this.props;

    const filteredItems = items.filter((tag, i) => filter(tag, this.state.filter, i));
    return (
      <SelectorWrapper
        style={{
          left,
          top,
          maxHeight,
          zIndex: 1000000
        }}
      >
        <Wrapper
          style={{
            maxHeight
          }}
        >
          <div className={css({
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 5
          })}>
            <Filter
              type="text"
              onKeyDown={this.handleKeyPress}
              onChange={this.filter}
              onBlur={this.deselect}
              onFocus={this.reselect}
              ref={input => {
                this.input = input;
              }}
            />
            {typeof renderAction === 'function' ? renderAction() : renderAction}
          </div>
          <List
            style={{ maxHeight }}
            ref={ref => {this.scrollParentRef = ref;}}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={hasMore}
              loader={loader || <div className="loader" key={0}>Loading ...</div>}
              useWindow={false}
              getScrollParent={() => this.scrollParentRef}
            >
              {
                filteredItems.map((item, i) => renderItem({
                  item: item,
                  index: i,
                  length: filteredItems.length,
                  selected: this.state.selectedIndex < filteredItems.length - 1 ? this.state.selectedIndex === i : i === filteredItems.length - 1,
                  deselect: this.deselect,
                  reselect: this.reselect
                }))
              }
            </InfiniteScroll>
          </List>
        </Wrapper>
      </SelectorWrapper>
    );
  }
}

const Wrapper = styled('div')(({ theme }) => ({
  width: 200,
  background: theme.neutral.extraExtraLight,
  boxShadow: theme.shadows.d2,
  borderRadius: 4,
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowY: 'auto'
}));

const Filter = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  borderRadius: 3,
  height: 30,
  boxShadow: theme.shadows.id1,
  padding: 8,
  '&:focus': {
    outline: 'none',
    border: `2px solid ${theme.blue.extraLight}`
  }
}));

const List = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    background: 'none',
    width: 6
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0,0,0,.2)',
    width: 4,
    borderRadius: 4
  }
});

export const SelectorWrapper = styled('div')({
  position: 'fixed',
  left: '45%',
  top: '45%',
  zIndex: 9
});
