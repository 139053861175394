import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';

import { axios, createHistory, serverSentEventSub } from '@/api.js';
import DataGrid from '@components/Reusable/data/DataGrid/DataGrid.js';
import columns from '@components/Reusable/data/DataGrid/template.js';

import AddToCalendar from './WorkorderGrid.AddToCalendar.js';
import { Chip } from '@mui/material';
import { ConfirmationModal } from '../../Controls.js';

class WorkorderGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      document_id: props.document_id,
      designs: [],
      deleting: 0,
      datePicker: {
        open: false,
        design: 0
      },
      customizations: [],
      custoToAdd: 0
    };
  }

  componentDidMount() {
    this.getLines();
    this.getOrderCustomizations();
    serverSentEventSub.addEventListener('updateLines', this.handleEvent);
  }

  componentWillUnmount() {
    serverSentEventSub.addEventListener('updateLines', this.handleEvent);
  }

  handleEvent = (e) => {
    const eventData = JSON.parse(e.data);
    if(Number(eventData.document_id) !== Number(this.state.document_id)) {
      return;
    }
    this.getLines();
  };

  addCustomization = (design_id) => {
    const custo = this.state.customizations.find((customization) => customization.id === this.state.custoToAdd);
    if(!custo) return;
    axios.post('/lines/customization', {
      name: custo.name,
      price: custo.price,
      design_id
    })
      .then(() => {
        this.getLines();
      });
  }

  addDesign = () => {
    axios.post('/lines/design', {
      document_id: this.state.document_id,
      designNumber: this.state.designs.length + 1
    })
      .then(() => {
        this.getLines();
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error getting the designs from the document'
        });
      });
  };

  addLine = (designGroup) => {
    axios.post('/lines', {
      designGroup
    })
      .then(() => {
        this.getLines();
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error adding a line to the document. Please reload before continuing work'
        });
      });
  };

  addOtherPrint = (designIndex) => {
    const designs = this.state.designs;
    const design = designs[designIndex];
    if(design.otherPrints === null) {
      design.otherPrints = [];
    }

    design.otherPrints.push(0);
    designs[designIndex] = design;

    this.setState({ designs }, () => {
      this.updateDesign(designIndex);
    });
  };

  deleteCustomization = (id, designGroup) => {
    axios.delete('/lines/customization', {
      params: {
        id,
        designGroup
      }
    })
      .then(() => {
        this.getLines();
      });
  }

  deleteDesign = (design) => {
    createHistory({
      document_id: this.state.document_id,
      key: 'deleteDesign',
      type: 'v3Lines',
      json: { id: design.designNumber },
      user_id: this.props.user_account_id
    });
    axios.delete('/lines/design/' + design.id)
      .then(() => {
        this.getLines();
        this.props.documentSideEffects();
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error deleting the design. Please reload before continuing work'
        });
      });   
  }

  getLines = () => {
    axios.get('/lines', {
      params: {
        document_id: this.state.document_id
      }
    })
      .then((res) => {
        this.setState({ designs: res.data.results });
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error getting the lines for the document'
        });
      });
  };

  getOrderCustomizations = () => {
    axios.get('/lines/design/customization', {
      params: {
        archived: false
      }
    })
      .then((res) => {
        this.setState({ customizations: res.data.result });
      });
  };

  getSheet = () => {
    axios.get('/lines/sheet', {
      params: { document_id: this.state.document_id }
    })
      .then((res) => {
        const blobUrl = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'text/csv',
          })
        );
        const a = document.createElement('a');
        a.download = 'workorder-sheet.csv';
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  };

  removeOtherPrint = (designIndex) => {
    const designs = this.state.designs;
    const design = designs[designIndex];
    design.otherPrints.pop();
    designs[designIndex] = design;

    this.setState({ designs }, () => {
      this.updateDesign(designIndex);
    });
  };

  updateDesign = (index) => {
    axios.put('/lines/design', {
      id: this.state.designs[index].id,
      designNumber: this.state.designs[index].designNumber,
      fPrint: this.state.designs[index].fPrint,
      bPrint: this.state.designs[index].bPrint,
      lPrint: this.state.designs[index].lPrint,
      rPrint: this.state.designs[index].rPrint,
      otherPrints: this.state.designs[index].otherPrints
    })
      .then((res) => {
        this.updateDesignTotals(index, res.data.result.orderLines);
        this.props.documentSideEffects();
      })
      .catch(() => {
        this.props.dispatch({
          type: 'ERROR/SET',
          error: 'There was an error updating the design. Please suggest you reload before continuing work.'
        });
      });
  };

  updateDesignTotals = (designIndex, rows) => {
    const designs = this.state.designs;
    const design = designs[designIndex];


    design.orderLines = design.orderLines.map((row) => {
      const rowToUse = rows.find(({id}) => row.id === id);
      row.quantity = rowToUse.quantity;
      row.itemPrice = rowToUse.itemPrice;
      row.lineTotal = rowToUse.lineTotal;
      row.desc = rowToUse.desc;
      return row;
    });

    designs[designIndex] = design;

    this.setState({
      designs
    });
  };

  updateDesignNumber = (index, number) => {
    const designs = this.state.designs;
    designs[index].designNumber = number;
    this.setState({ designs });
  };

  updateDesignPrint = (index, number, printSide) => {
    if(number > 6 || number < 0) return;
    const designs = this.state.designs;
    designs[index][printSide + 'Print'] = number;
    this.setState({ designs });
  };

  uploadSheet = e => {
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);
    fileData.append('document_id', this.props.document_id);
    axios({
      method: 'PUT',
      url: '/lines/sheet',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: fileData
    })
      .catch(e => { console.log(e); });
  }

  render() {
    return (
      <>

        {this.state.designs.map((design, index) => {
          return (
            <Box key={index} sx={{ width: '100%'}} mb={1}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <p>D</p>
                  <TextField
                    size="small"
                    sx={{
                      width: 40,
                      margin: 1
                    }}
                    value={design.designNumber}
                    onChange={(e) => { this.updateDesignNumber(index, e.target.value); }}
                    onBlur={() => { this.updateDesign(index); }}
                  />
                  <p>Total Items:  </p>
                  <TextField
                    inputProps={{readOnly: true}}
                    size="small"
                    sx={{
                      width: 80,
                      margin: 1,
                      textAlign: 'right'
                    }}
                    value={design.itemQuantity}
                  />
                  <p>Print</p>
                  <TextField
                    size="small"
                    sx={{
                      width: 40,
                      margin: 1
                    }}
                    label=""
                    value={design.fPrint}
                    onChange={(e) => { this.updateDesignPrint(index, e.target.value, 'f'); }}
                    onBlur={() => { this.updateDesign(index); }}
                  />
                  <TextField
                    size="small"
                    sx={{
                      width: 40,
                      margin: 1
                    }}
                    label=""
                    value={design.bPrint}
                    onChange={(e) => { this.updateDesignPrint(index, e.target.value, 'b'); }}
                    onBlur={() => { this.updateDesign(index); }}
                  />
                  <TextField
                    size="small"
                    sx={{
                      width: 40,
                      margin: 1
                    }}
                    label=""
                    value={design.lPrint}
                    onChange={(e) => { this.updateDesignPrint(index, e.target.value, 'l'); }}
                    onBlur={() => { this.updateDesign(index); }}
                  />
                  <TextField
                    size="small"
                    sx={{
                      width: 40,
                      margin: 1
                    }}
                    label=""
                    value={design.rPrint}
                    onChange={(e) => { this.updateDesignPrint(index, e.target.value, 'r'); }}
                    onBlur={() => { this.updateDesign(index); }}
                  />
                  {
                    (design.otherPrints || []).map((print, i) => {
                      return (
                        <TextField
                          key={i}
                          size="small"
                          sx={{
                            width: 40,
                            margin: 1
                          }}
                          label="O"
                          value={print}
                          onChange={(e) => {
                            if(e.target.value < 0 || e.target.value > 6) return;
                            const designs = this.state.designs;
                            const temp_design = {...designs[index]};
                            temp_design.otherPrints[i] = e.target.value;
                            designs[index] = temp_design;
                            this.setState({ designs });
                          }}
                          onBlur={(e) => {
                            if(e.target.value < 0 || e.target.value > 6 || e.target.value === '') return;
                            this.updateDesign(index);
                          }}
                        />
                      );
                    })
                  }
                  <IconButton
                    onClick={() => {
                      this.addOtherPrint(index);
                    }}
                  >
                    <AddCircleIcon/>
                  </IconButton>
                  { (design.otherPrints || []).length > 0 &&
                    <IconButton
                      onClick={() => {
                        this.removeOtherPrint(index);
                      }}
                    >
                      <RemoveCircleIcon/>
                    </IconButton>
                  }

                  <AddToCalendar 
                    design={design} 
                    sideEffects={() => { this.getLines(); }}
                  />
                </Box>
                
                <Box
                  sx={{
                    justifyContent: 'flex-end'
                  }}
                >
                  <IconButton onClick={() => { this.setState({ deleting: design }); }}>
                    <DeleteIcon>
                      style={{
                        marginLeft: 'auto'
                      }}
                    </DeleteIcon>
                  </IconButton>
                </Box>

              </Box>
              {
                <DataGrid
                // Hidden columns and lines are just the initial state of the component
                  drawerInitial={design.design_customizations.length > 0}
                  drawerContent={
                    <Box
                      sx={{p: 2}}
                    > 
                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        spacing={1}
                        sx={{ mb: 1 }}
                      >
                        { design.design_customizations.map((customization) => {
                          return(
                            <Chip
                              key={customization.id}
                              sx={{ mb: 1}}
                              label={`${customization.name} - $${customization.price.toFixed(2)}`}
                              color="primary"
                              onDelete={() => { this.deleteCustomization(customization.id, design.id); }}
                              deleteIcon={<HighlightOffIcon/>}
                            />
                          );
                        }) }
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          alignItems: 'center'
                        }}
                      >
                        <Select
                          size="small"
                          value={this.state.custoToAdd}
                          onChange={(e) => { this.setState({ custoToAdd: e.target.value}); }}
                        >
                          <MenuItem
                            dense
                            value={0}
                          ><em>None</em></MenuItem>
                          {this.state.customizations.map((custo) => {
                            return (
                              <MenuItem
                                dense
                                key={custo.id}
                                value={custo.id}
                              >{custo.name + ' - '}<em style={{ marginLeft: '4px'}}>Price: (${custo.price.toFixed(2)})</em></MenuItem>
                            );
                          })}
                        </Select>
                        <Button
                          variant="contained"
                          onClick={() => { this.addCustomization(design.id); }}
                        >
                          Add Customization
                        </Button>
                      </Stack>
                    </Box>
                  }
                  columns={columns}
                  columnsHideable={true}
                  hiddenColumns={design.hiddenColumns}
                  updateHiddenColumnsSideEffects={(hiddenColumns) => {
                    axios.put('/lines/design/hiddenColumns', {
                      id: design.id,
                      hiddenColumns
                    })
                      .then(() => {
                        this.props.documentSideEffects();
                      })
                      .catch(() => {
                        this.props.dispatch({
                          type: 'ERROR/SET',
                          error: 'There was an error updating which columns are hidden. Please reload before continuing work'
                        });
                      });
                  }}
                  onAddRow={(newRow, rowIndex) => {
                    axios.post('/lines', {
                      designGroup: design.id,
                      newRow
                    })
                      .then((res) => {
                        const design = this.state.designs[index];
                        design.orderLines.splice(rowIndex, 0, res.data.result);
                        const designs = this.state.designs;
                        designs[index] = design;
                        createHistory({
                          document_id: this.state.document_id,
                          key: 'addRow',
                          type: 'v3Lines',
                          json: { id: design.designNumber },
                          user_id: this.props.user_account_id
                        });
                        this.setState({ designs });
                        this.props.documentSideEffects();
                      })
                      .catch(() => {
                        this.props.dispatch({
                          type: 'ERROR/SET',
                          error: 'There was an error adding a row. Please reload before continuing work'
                        });
                      });
                  }}
                  onDeleteRow={(rowIndex, rows) => {
                    const rowId = design.orderLines[rowIndex].id;
                    axios.delete('/lines', {
                      params: {
                        id: rowId
                      }
                    })
                      .then((res) => {
                        const design = this.state.designs[index];
                        const designs = this.state.designs;
                        design.orderLines = rows;
                        this.setState({ designs }, () => {
                          this.updateDesignTotals(index, res.data.result.orderLines);
                        });
                        createHistory({
                          document_id: this.state.document_id,
                          key: 'deleteRow',
                          type: 'v3Lines',
                          json: { id: design.designNumber },
                          user_id: this.props.user_account_id
                        });
                        this.props.documentSideEffects();
                      })
                      .catch(() => {
                        this.props.dispatch({
                          type: 'ERROR/SET',
                          error: 'There was an error deleting a row. Please reload before continuing work'
                        });
                      });
                  }}
                  onChangeRow={(rowIndex, rows) => {
                    const row = { ...rows[rowIndex] };
                    const rowId = row.id;
                    if(!rowId) return;
                    delete row.id;

                    axios.put('/lines/single/' + rowId, {
                      row
                    })
                      .then((res) => {
                        const design = this.state.designs[index];
                        const designs = this.state.designs;
                        design.orderLines = rows;
                        this.setState({ designs }, () => {
                          this.updateDesignTotals(index, res.data.result.orderLines);
                        });
                        this.props.documentSideEffects();
                      })
                      .catch(() => {
                        this.props.dispatch({
                          type: 'ERROR/SET',
                          error: 'There was an updating a row. Please reload before continuing work'
                        });
                      });
                  }}
                  onChangeRowSideEffects={(oldRows, updatedRows) => {
                    createHistory({
                      document_id: this.state.document_id,
                      key: 'updateRow',
                      type: 'v3Lines',
                      json: { id: design.designNumber, lines: updatedRows },
                      json_previous: { lines: oldRows },
                      user_id: this.props.user_account_id
                    });
                  }}
                  onChangeRowOrder={(ids, sortOrders) => {
                    axios.put('/lines/sortOrder', {
                      ids,
                      sortOrders
                    })
                      .then(() => {
                        this.getLines();
                      })
                      .catch(() => {
                        this.props.dispatch({
                          type: 'ERROR/SET',
                          error: 'There was an error updating the row order. Please reload before continuing work'
                        });
                      });
                  }}
                  autoSortFunction={(row1, row2) => {
                    if(row1.colorgroup > row2.colorgroup) {
                      return 1;
                    } else {
                      return 0;
                    }
                  }}
                  updateMultipleRows={(rows) => {
                    axios.put('/lines/many', {
                      rows
                    })
                      .then((res) => {
                        const design = this.state.designs[index];
                        const designs = this.state.designs;
                        createHistory({
                          document_id: this.state.document_id,
                          key: 'updateRow',
                          type: 'v3Lines',
                          json: { id: design.designNumber, lines: rows },
                          json_previous: { lines: design.orderLines },
                          user_id: this.props.user_account_id
                        });
                        design.orderLines = rows;
                        this.setState({ designs }, () => {
                          this.updateDesignTotals(index, res.data.result.orderLines);
                        });
                        this.props.documentSideEffects();
                      })
                      .catch(() => {
                        this.props.dispatch({
                          type: 'ERROR/SET',
                          error: 'There was an error updating multiple rows. Definitely reload before continuing work'
                        });
                      });
                  }}
                  rows={design.orderLines}
                />}
            </Box>
          );
        })}
        <Button
          variant="contained"
          fullWidth={true}
          startIcon={<AddCircleIcon/>}
          sx={{ mb:1 }}
          onClick={() => {
            createHistory({
              document_id: this.state.document_id,
              key: 'addedDesign',
              type: 'v3Lines',
              user_id: this.props.user_account_id
            });
            this.addDesign();
          }}
        >Add Design</Button>
        <Button
          fullWidth
          variant="outlined"
          sx={{ mb:1 }}
          onClick={this.getSheet}
        >Get Sheet</Button>
        
        <Button
          fullWidth
          variant="outlined"
          sx={{ mb:1 }}
          component="label"
        >
          Upload Sheet
          <VisuallyHiddenInput 
            onChange={this.uploadSheet}
            type="file" 
          />
        </Button>
        <ConfirmationModal 
          isOpen={this.state.deleting !== 0}
          onModalClose={() => {this.setState({deleting: 0});}}
          onConfirm={() => { 
            this.deleteDesign(this.state.deleting); 
            this.setState({ deleting: 0 });
          }}
          text="Are you sure you want to delete this design?"
        />
      </>
    );
  }
}

export default connect((state) => ({
  user_account_id: state.app.user_account_id,
}))(WorkorderGrid);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
