import React, { Fragment } from 'react';
import { PortalWithState } from 'react-portal';
import styled from '@emotion/styled';
import { css } from 'emotion';
import AddIcon from 'react-icons/lib/md/add';
import { Shade } from '../shell/Shell';
import axios from 'axios';
import { BASE_URL } from '../../api';
import InfiniteScrollList from '../Collections/InfiniteScrollList';
import { ConnectedCustomerSlider } from '../../pages/Workorder/CustomerSlider';

/**
 * Customer Selector
 *
 * This allows you to select a customer from a set of customers
 *
 * props:
    * onSelect - what to do with the customer_id that is selected
    * reference - the ref of the component that the customer selector is associated with
 * */

export default class CustomerSelector extends React.Component {
  constructor() {
    super();
    this.state = {
      coordinates: {
        x: 0,
        y: 0,
        maxHeight: 300
      },
      customerCount: 0,
      customers: [],
      filter: '',
      notesState: { open: false },
      sliderOpen: false
    };
  }

  componentDidMount() {
    this.countCustomers();
    this.getCustomers();
  }

  changeFilter = (filter) => {
    this.setState({ filter: filter, customers: [], customerCount: 0 }, () => {
      this.countCustomers();
      this.getCustomers();
    });
  };

  countCustomers = () => {
    axios.get(`${BASE_URL}/api/customer/count`, {
      params: {
        filter: this.state.filter
      }
    })
      .then((result) => {
        this.setState({ customerCount: result.data.result });
      });
  };

  getCustomers = () => {
    axios.get(`${BASE_URL}/api/customer/all`, {
      params: {
        limit: 100,
        offSet: this.state.customers.length,
        filter: this.state.filter
      }
    })
      .then((result) => {
        const customers = this.state.customers;
        customers.push(...result.data.result);
        this.setState({ customers });
      });
  };

  openNewCustomerSlider = () => {
    this.setState({ sliderOpen: true });
  };

  setSelectorCoordinates = () => {
    this.list.focus();
    const reference = this.reference.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const x = reference.x + 220 > screenWidth ? screenWidth - 220 : reference.x - 20;
    this.setState({
      coordinates: {
        x,
        y: reference.y,
        maxHeight: `calc(100vh - ${reference.y + 10}px)`
      }
    });
  };

  render() {
    const CustomButton = this.props.CustomButton;
    const {
      customers
    } = this.state;
    return (
      <>
        <ConnectedCustomerSlider
          isOpen={this.state.sliderOpen}
          onModalClose={() => {this.setState({ sliderOpen: false });}}
          onCustomerCreated={(customer) => {
            this.props.onSelect(customer);
            this.setState({ sliderOpen: false });
          }}
        />
        <PortalWithState
          onOpen={this.setSelectorCoordinates}
          closeOnEsc
          closeOnOutsideClick
        >
          {({ portal, openPortal, closePortal, isOpen }) => (
            <Fragment>
              {CustomButton ?
                React.cloneElement(CustomButton, {
                  ref:ref => {
                    this.reference = ref;
                  },
                  onClick:openPortal
                }) :
                <AddButton
                  ref={ref => {
                    this.reference = ref;
                  }}
                  onClick={openPortal}
                >
                  <AddIcon />
                </AddButton>
              }
              {portal(
                <InfiniteScrollList
                  left={this.state.coordinates.x}
                  top={this.state.coordinates.y}
                  hasMore={this.state.customers.length < this.state.customerCount }
                  loadMore={() => {this.getCustomers();}}
                  ref={list => {this.list = list;}}
                  items={customers}
                  maxHeight={this.state.coordinates.maxHeight}
                  onSubmit={this.props.onSelect}
                  renderItem={({
                    item: customer,
                    index: i,
                    selected,
                  }) => (
                    <CustomerCard
                      {...customer}
                      onClick={() => {
                        closePortal();
                        this.props.onSelect(customer);
                      }}
                      selected={selected}
                      key={i}
                    />
                  )}
                  renderAction={
                    <NewCustomerButton
                      onClick={() => {
                        closePortal();
                        this.setState({ sliderOpen: true });
                      }}
                    >
                      <AddIcon />
                    </NewCustomerButton>
                  }
                  onChange={(_, filter) => {this.changeFilter(filter);}}
                  filter={() => true}
                />
              )}
              {isOpen && <Shade className="fade-in" />}
            </Fragment>
          )}
        </PortalWithState>
      </>
    );
  }
}

const AddButton = styled('button')(
  {
    width: '100%',
    background: 'none',
    border: 'none',
    fontSize: 40,
    borderRadius: 6,
    marginTop: 8,
    transition: 'border-color 200ms, color 200ms',
  },
  ({ theme }) => ({
    color: theme.neutral.extraExtraLight,
    border: `3px dashed ${theme.neutral.extraExtraLight}`,
    '&:hover': {
      borderColor: theme.neutral.light,
      color: theme.neutral.light,
    },
  }),
  ({noLabel}) => noLabel ? ({
    margin: 0
  }) : null
);

const NewCustomerButton = styled('button')(
  {
    width: 41,
    height: 30,
    border: 'none',
    padding: 0,
    fontSize: 28,
    borderRadius: 3,
    marginLeft: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ theme }) => ({
    background: theme.vibrant.color,
    color: theme.vibrant.textOn,
    boxShadow: theme.shadows.d1,
  })
);

const orgStyles = css({
  fontStyle: 'italic',
  fontSize: 13,
  opacity: 0.6,
  paddingLEft: 10,
});

export const CustomerCard = ({
  firstname,
  lastname,
  organization,
  isorganization,
  selected,
  onClick,
}) => (
  <CustomerCardWrapper selected={selected} onClick={onClick}>
    {
      isorganization ?
        <React.Fragment>
          <span>
            {organization}
          </span>
          <span className={orgStyles}>
            {firstname} {lastname}
          </span>
        </React.Fragment>
        :
        <span>
          {firstname} {lastname}
        </span>
    }
  </CustomerCardWrapper>
);

export const CustomerCardWrapper = styled('div')(
  {
    fontSize: 16,
    padding: '10px 5px',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
  },
  ({ theme, selected }) => ({
    background: selected ? theme.blue.extraLight : 'none',
    '&:nth-of-type(even)': {
      background: selected ? theme.blue.extraLight : 'rgba(0,0,0,.07)',
    },
    '&:hover': {
      background: theme.blue.extraLight,
      '&:nth-of-type(even)': {
        background: theme.blue.extraLight,
      },
    },
  })
);
