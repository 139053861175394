import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { axios, serverSentEventSub } from '../../../api';
import designGroup from '../../../types/models/designGroup';
import format from 'date-fns/format';

interface Props {
  document_id: number,
  style?: any,
  placeholder?: string
}

export default function DesignsDueDate(props:Props) {
  const [designs, setDesigns] = useState<designGroup[]>([]);

  useEffect(() => {

    axios.get('/lines', {
      params: {
        document_id: props.document_id
      }
    })
      .then((res) => {
        setDesigns(res.data.results);
      });

    return () => {
      serverSentEventSub.removeEventListener('updateDesignDate', handleUpdatedDesignDate);
    };
  }, []);

  useEffect(() => {
    serverSentEventSub.addEventListener('updateDesignDate', handleUpdatedDesignDate);

    return () => {
      serverSentEventSub.removeEventListener('updateDesignDate', handleUpdatedDesignDate);
    };
  }, [designs]);

  function calculateDueDate(): string {
    if(designs.length <= 0) return '';
    const date = designs.reduce((lastValue, currentValue) => {
      if(currentValue.prod_date === null) return lastValue;
      return currentValue.prod_date < lastValue ? currentValue.prod_date : lastValue;
    }, designs[0].prod_date);
    if(date === null) return '';
    return format(date.toString(), 'M/D/YY');
  }

  function handleUpdatedDesignDate(e: any): void {
    const eventData: {document_id: number, designId: number, date: Date } = JSON.parse(e.data);
    if(props.document_id !== eventData.document_id) {
      return;
    }

    const designIndex: number = designs.findIndex((design) => design.id === eventData.designId);
    if(designIndex === -1) {
      return;
    }
    const tempDesigns = designs;
    const tempDesign = designs[designIndex];
    tempDesign.prod_date = eventData.date;
    tempDesigns[designIndex] = tempDesign;
    setDesigns([...tempDesigns]);
  }

  const date = calculateDueDate();
  return (
    <NonButtonDueDate
      style={props.style}
    >
      {date === '' && props.placeholder ? props.placeholder : date}
    </NonButtonDueDate>
  );
}

const NonButtonDueDate = styled('span')({
  width: '100%',
  height: '100%',
  maxHeight: 50,
  flex: 1,
  fontSize: 24,
  paddingTop: 18,
  paddingLeft: 5,
  boxSizing: 'border-box',
  border: 'none',
  cursor: 'text',
  background: 'none',
  '&:focus': {
    outline: 'none',
    background: 'rgb(243, 243, 243)'
  }
}, ({ theme }) => ({
  //@ts-ignore
  fontFamily: theme.palette.primary.fontFamily
}));
