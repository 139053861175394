import styled from '@emotion/styled';

export const Shade = styled('div')({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0,0,0,.2)',
  zIndex: 9
});

export const Wrapper = styled('div')({
  padding: 15,
  paddingTop: 60,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});
