import React from 'react';
import styled from '@emotion/styled';
import ModalStateController from './ModalStateController';
import ClearIcon from 'react-icons/lib/md/keyboard-arrow-right';

const SliderWrapper = styled('div')({
  width: 350,
  height: '100%',
  position: 'fixed',
  right: 0,
  top: 0,
  background: '#fff',
  zIndex: 3,
  boxShadow: '-8px 0px 28px rgba(0,0,0,0.25), -4px 0px 10px rgba(0,0,0,0.22)',
  display: 'flex',
  flexDirection: 'column'
});

const Content = styled('div')({
  flex: '1 1 100%',
  overflowY: 'auto'
});

export default class Slider extends React.Component{
  render(){
    const { title, isOpen, onModalClose, children } = this.props;
    return(
      <ModalStateController
        enterAnimationClassName="slide-in-right"
        exitAnimationClassName="slide-out-right"
        isOpen={isOpen}
        onModalClose={onModalClose}
      >
        {
          ({getProps, closeModal}) => (
            <SliderWrapper {...getProps()}>
              <Toolbar title={title} closeModal={closeModal}/>
              <Content>
                {children}
              </Content>
            </SliderWrapper>
          )
        }
      </ModalStateController>
    );
  }
}

const ToolbarWrapper = styled('div')({
  flex: '0 0 auto',
  width: '100%',
  height: 50,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}, ({theme}) => ({
  background: theme.primary.medium
}));

const CloseButton = styled('button')({
  width: 50,
  height: '100%',
  border: 'none',
  fontSize: 36
}, ({theme}) => ({
  background: 'none',
  color: theme.primary.textOn,
  '&:hover': {
    background: theme.primary.lightMedium
  }
}));

const ToolbarTitle = styled('span')({
  fontSize: 18,
  lineHeight: 0
}, ({theme}) => ({
  color: theme.primary.textOn
}));

const Toolbar = ({closeModal, title}) => (
  <ToolbarWrapper>
    <CloseButton onClick={closeModal}>
      <ClearIcon/>
    </CloseButton>
    <ToolbarTitle>
      {title}
    </ToolbarTitle>
  </ToolbarWrapper>
);
