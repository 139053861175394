const initialState = {
  filter: ''
};

const UsersPageReducer = (state=initialState, action) => {
  switch(action.type){
  case 'USP.SET_FILTER':
    return {...state, filter: action.filter};
  default:
    return state;
  }
};

export default UsersPageReducer;
