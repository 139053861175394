import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AddIcon from '@mui/icons-material/Add';
import MergeIcon from '@mui/icons-material/Merge';
import { axios } from '../../api.js';
//@ts-ignore
import InfiniteScroll from 'react-infinite-scroller';
import CustomerType from '../../types/models/customer';
import DataGrid from '../../components/Reusable/data/DataGrid/DataGrid.js';
import AppBar from '../../components/Reusable/navigation/AppBar.tsx';
import theme from '../../theme.ts';
import CustomerModal from './CustomerModal.tsx';
import MergeCustomerModal from '../../components/MergeCustomer';
import { Loading } from '../../components/Controls';
import TextField from '@mui/material/TextField';

export default function Customer() {
  const [viewingCustomer, setViewingCustomer] = useState<number>(0);
  const [customers, setCustomers] = useState<CustomerType[]>([]);
  const [customersCount, setCustomersCount] = useState<number>(0);
  const [filter, setFilter] = useState<string>('');
  const [sortKey, setSortKey] = useState<string>('updatedAt');
  const [sortDir, setSortDir] = useState<'DESC' | 'ASC'>('DESC');
  const [merging, setMerging] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getCustomersCount();
  }, []);

  useEffect(() => {
    setCustomers([]);
    getCustomersCount();
  }, [filter, sortKey, sortDir]);

  function getCustomersCount():void {
    axios.get('/customer/count', {
      params: {
        filter
      }
    })
      .then((res) => {
        setCustomersCount(res.data.result);
      });
  }

  function getCustomers():void {
    axios.get('/customer/all', {
      params: {
        filter,
        limit: 50,
        offset: customers.length,
        orderBy: sortKey,
        sortDir
      }
    })
      .then((res) => {
        setCustomers([ ...customers, ...res.data.result ]);
      });
  }

  function getCustomerSheet() {
    setLoading(true);
    axios.get('/customer/orderSheet', {})
      .then((res) => {
        const blobUrl = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'text/csv',
          })
        );
        const a = document.createElement('a');
        a.download = 'customer-sheet.csv';
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
      });
  }


  return (
    <>
      <AppBar
        title="Customers"
        barColor={theme.palette.blue.main}
      >
        <TextField 
          variant="filled"
          inputProps={{
            sx:{
              pt:1,
              height: 19,
              backgroundColor: 'white'
            }
          }}
          placeholder="Filter"
          onChange={(e) => { setFilter(e.target.value); }}
        />
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: 2
        }}
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={() => { getCustomers(); }}
          hasMore={customers.length < customersCount }
          loader={ <div className="loader" key={0}> Loading ... </div> }
          style={{ width: '80%' }}
        >
          <DataGrid
            columns={[
              {
                key: 'name',
                valueFunction: (row: CustomerType) => {
                  return row.firstname + ' ' + row.lastname;
                },
                headerName: 'Name',
                hideable: false,
                sortKey: 'lastname'
              },{
                key: 'organization',
                headerName: 'Org'
              }, {
                key: 'email',
                headerName: 'Email',
              }, {
                key: 'primaryphone',
                headerName: 'Primary Phone',
                minWidth: 130,
                maxWidth: 150
              }, {
                key: 'city',
                headerName: 'City',
                minWidth: 80,
                maxWidth: 120
              },{
                key: 'state',
                headerName: 'State',
                maxWidth: 70,
                minWidth: 70
              }, {
                key: 'zipcode',
                headerName: 'Zip',
                minWidth: 50,
                maxWidth: 80
              }
            ]}
            hiddenColumns={['city', 'zipcode', 'state']}
            rows={customers}
            readOnly={true}
            columnsHideable={true}
            readOnlyRowClick={(custo:CustomerType) => {
              setViewingCustomer(custo.customer_id || 0);
            }}
            sortableByHeader={true}
            onSortByHeader={(id: string, direction: 'ASC' | 'DESC') => { 
              setSortKey(id);
              setSortDir(direction);
              setCustomers([]);
              setLoading(false);
            }}
            defaultKey="updatedAt"
            sortingBy={sortKey}
            sortDirection={sortDir}
          />
        </InfiniteScroll>
        <CustomerModal 
          viewingCustomer={viewingCustomer}
          onClose={() => { 
            setViewingCustomer(0); 
          }}
        />
        <Stack
          sx={{
            position: 'fixed',
            bottom: '20px',
            right: '40px',
            color: 'white'
          }}
          spacing={2}
        >
          <Fab 
            size="large"
            sx={{ 
              color: 'white',
              // @ts-ignore
              backgroundColor: (theme) => theme.palette.blue.main
            }}
            onClick={() => { getCustomerSheet(); }}
          >
            <TextSnippetIcon />
          </Fab>
          <Fab 
            size="large"
            sx={{ 
              color: 'white',
              // @ts-ignore
              backgroundColor: (theme) => theme.palette.blue.main
            }}
            onClick={() => { setMerging(true); }}
          >
            <MergeIcon />
          </Fab>
          <Fab 
            size="large"
            sx={{ 
              color: 'white',
              // @ts-ignore
              backgroundColor: (theme) => theme.palette.blue.main
            }}
            onClick={() => { setViewingCustomer(-1); }}
          >
            <AddIcon />
          </Fab>
        </Stack>
        <MergeCustomerModal
          isOpen={merging}
          onModalClose={() => {
            setMerging(false);
          }}
        />
      </Box>
      <Loading 
        isOpen={loading}
      />
    </>
  );
}

