import React, { useEffect, useState } from 'react';
import { axios } from '../../api';
import { InventoryItem as InventoryItemType } from '../../types/models/inventoryItem';
import AppBar from '../../components/Reusable/navigation/AppBar';

import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/UploadFile';

import InfiniteScroll from 'react-infinite-scroller';
import Box from '@mui/material/Box';
import DataGrid from '../../components/Reusable/data/DataGrid/DataGrid';
import { Loading } from '../../components/Controls';
import InventoryItemModal from './InventoryItemModal';
import { Fab, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, styled } from '@mui/material';
import { pricingtypeOptions } from '../../data/options';

export default function Inventory() {
  const [loading, setLoading] = useState<boolean>(false);
  const [viewingItem, setViewingItem] = useState<number>(0);

  const [items, setItems] = useState<InventoryItemType[]>([]);
  const [itemsCount, setItemsCount] = useState<number>(0);
  const [manufacturers, setManufacturers] = useState<string[]>([]);
  const [suppliers, setSuppliers] = useState<string[]>([]);

  const [sortKey, setSortKey] = useState<string>('stylenumber');
  const [sortDir, setSortDir] = useState<'DESC' | 'ASC'>('ASC');

  const [stylenumber, setStylenumber] = useState<string>('');
  const [color, setColor] = useState<string>('');
  const [manufacturer, setManufacturer] = useState<string>('');
  const [supplier, setSupplier] = useState<string>('');
  const [taxexempt, setTaxexempt] = useState<boolean | undefined>(undefined);
  const [priceLower, setPriceLower] = useState<string>('');
  const [priceUpper, setPriceUpper] = useState<string>('');
  const [pricingType, setPricingType] = useState<string>('');

  useEffect(() => {
    getItemsCount();
    getManufacturers();
    getSuppliers();
  }, []);

  useEffect(() => {
    setItems([]);
    getItemsCount();
  }, [sortKey, sortDir, stylenumber, color, manufacturer, supplier, taxexempt, priceLower, priceUpper, pricingType]);

  function downloadSpreadsheet() {
    axios.get('/inventory/downloadSpreadsheet', {
      params: {
        stylenumber: stylenumber,
        color: color,
        manufacturer: manufacturer,
        supplier: supplier,
        taxexempt: taxexempt,
        priceLower: priceLower,
        priceUpper: priceUpper,
        pricingType: pricingType
      }
    })
      .then((res) => {
        const blobUrl = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'text/csv',
          })
        );
        const a = document.createElement('a');
        a.download = 'filtered-inventory-sheet.csv';
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  function getItemsCount() {
    axios.get('/inventory/count', {
      params: {
        stylenumber: stylenumber,
        color: color,
        manufacturer: manufacturer,
        supplier: supplier,
        taxexempt: taxexempt,
        priceLower: priceLower,
        priceUpper: priceUpper,
        pricingType: pricingType
      }
    })
      .then((res) => { setItemsCount(res.data.result); });
  }

  function getItems() {
    axios.get('/inventory', {
      params: {
        limit: 50,
        offset:  items.length,
        orderBy: sortKey,
        sortDir,
        stylenumber: stylenumber,
        color: color,
        manufacturer: manufacturer,
        supplier: supplier,
        taxexempt: taxexempt,
        priceLower: priceLower,
        priceUpper: priceUpper,
        pricingType: pricingType
      }
    })
      .then((res) => { 
        setItems([...items, ...res.data]); 
      });
  }

  function getManufacturers() {
    axios.get('/settings', {
      params: {
        type: 'inventory',
        sub_type: 'providers',
        sub_sub_type: 'manufacturers'
      }
    })
      .then((res) => {
        setManufacturers(res.data.result.data);
      });
  }

  function getSuppliers() {
    axios.get('/settings', {
      params: {
        type: 'inventory',
        sub_type: 'providers',
        sub_sub_type: 'suppliers'
      }
    })
      .then((res) => {
        setSuppliers(res.data.result.data);
      });
  }

  function uploadSpreadsheet(e:any) {
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);
    setLoading(true);
    axios({
      method: 'POST',
      url: '/inventory/uploadSheet',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: fileData
    })
      .then(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <AppBar
        title="Inventory"
      ></AppBar>
      <Stack
        direction="column"
        alignItems="center"
      >
        <Grid 
          container
          width="80%"
        >
          <Grid 
            item 
            xs={6}
            sx={{ px: 1 }}
          >
            <TextField 
              variant="standard"
              size="small"
              label="Stylenumber"
              value={stylenumber}
              onChange={(e) => { setStylenumber(e.target.value); }}
              fullWidth
            />
            <TextField 
              variant="standard"
              size="small"
              label="Minimum Price"
              value={priceLower}
              onChange={(e) => { setPriceLower(e.target.value); }}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel id="supplier-label">Manufacturers</InputLabel>
              <Select 
                variant="standard"
                size="small"
                value={manufacturer}
                onChange={(e) => { setManufacturer(e.target.value); }}
                fullWidth
                labelId="supplier-label"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {manufacturers.map((thisManufacturer) => {
                  return (
                    <MenuItem 
                      key={thisManufacturer}
                      value={thisManufacturer}
                    >{thisManufacturer}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="pricing-type-select-label">Pricing Type</InputLabel>
              <Select
                variant="standard"
                size="small"
                value={pricingType}
                onChange={(e) => { setPricingType(e.target.value); }}
                fullWidth
                labelId="pricing-type-select-label"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {pricingtypeOptions.map((type) => {
                  return (
                    <MenuItem 
                      key={type.value}
                      value={type.value}
                    >{type.label}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid 
            item 
            xs={6}
            sx={{ px: 1 }}
          >
            <TextField 
              variant="standard"
              size="small"
              label="Color"
              value={color}
              onChange={(e) => { setColor(e.target.value); }}
              fullWidth
            />
            <TextField 
              variant="standard"
              size="small"
              label="Maximum Price"
              value={priceUpper}
              onChange={(e) => { setPriceUpper(e.target.value); }}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel id="supplier-label">Supplier</InputLabel>
              <Select 
                variant="standard"
                size="small"
                value={supplier}
                onChange={(e) => { setSupplier(e.target.value); }}
                fullWidth
                labelId="supplier-label"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {suppliers.map((thisSupplier) => {
                  return (
                    <MenuItem 
                      key={thisSupplier}
                      value={thisSupplier}
                    >{thisSupplier}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="taxexempt-select-label">Taxexempt</InputLabel>
              <Select
                variant="standard"
                size="small"
                value={taxexempt}
                onChange={(e) => { 
                  console.log(e.target.value);
                  if(e.target.value === '') {
                    setTaxexempt(undefined);
                  } else {
                    setTaxexempt(e.target.value === 'true'); 
                  }
                }}
                fullWidth
                labelId="taxexempt-select-label"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: 2,
        }}
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={() => { getItems(); }}
          hasMore={items.length < itemsCount}
          loader={ <div className="loader" key={0}> Loading ... </div> }
          style={{ width: '80%' }}
        >
          <DataGrid
            columns={[
              {
                key: 'stylecolor',
                headerName: 'Stylenumber',
                valueFunction: (row: InventoryItemType) => {
                  return row.stylenumber + '|' + row.color;
                },
                hideable: false,
                sortKey: 'stylenumber'
              }, {
                key: 'description',
                headerName: 'Description'
              }, {
                key: 'custprice',
                headerName: 'Price',
                maxWidth: 80
              }, {
                key: 'supplier',
                headerName: 'Supplier'
              }, {
                key: 'manufacturer',
                headerName: 'Manufacturer'
              }, {
                key: 'taxexempt',
                headerName: 'Taxexempt'
              }
            ]}
            hiddenColumns={['taxexempt', 'manufacturer', 'supplier']}
            rows={items}
            readOnly={true}
            columnsHideable={true}
            sortableByHeader={true}
            defaultKey="stylenumber"
            sortingBy={sortKey}
            sortDirection={sortDir}
            onSortByHeader={(id: string, direction: 'ASC' | 'DESC') => { 
              setSortKey(id);
              setSortDir(direction);
              setItems([]);
              setLoading(false);
            }}
            readOnlyRowClick={(item:InventoryItemType) => {
              setViewingItem(item.inventory_item_id || 0);
            }}
          ></DataGrid>
        </InfiniteScroll>
      </Box>
      <InventoryItemModal
        viewingItem={viewingItem}
        onClose={() => { setViewingItem(0); }}
      />
      <Stack
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '40px',
          color: 'white'
        }}
        spacing={2}
      >
        <Fab
          size="large"
          sx={{ 
            color: 'white',
            // @ts-ignore
            backgroundColor: (theme) => theme.palette.blue.main
          }}
        >
          <FileUploadIcon />
          <VisuallyHiddenInput 
            onChange={(e) => { uploadSpreadsheet(e); }}
            type="file"
          />
        </Fab>
        <Fab
          size="large"
          sx={{ 
            color: 'white',
            // @ts-ignore
            backgroundColor: (theme) => theme.palette.blue.main
          }}
          onClick={() => { downloadSpreadsheet(); }}
        >
          <DownloadIcon />
        </Fab>
        <Fab 
          size="large"
          sx={{ 
            color: 'white',
            // @ts-ignore
            backgroundColor: (theme) => theme.palette.blue.main
          }}
          onClick={() => { setViewingItem(-1); }}
        >
          <AddIcon />
        </Fab>
      </Stack>
      <Loading isOpen={loading} />
    </>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
