import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import compose from '../utils/compose';
import { connect } from 'react-redux';
import DayPicker from 'react-day-picker';
import { TextInput, Button } from '@components/Controls';
import 'react-day-picker/lib/style.css';
import AddIcon from 'react-icons/lib/md/add';
import DeleteIcon from 'react-icons/lib/md/delete';
import {
  axios,
  createHistory
} from '@/api.js';

import Box from '@mui/material/Box';

const PickerWrapper = styled('div')({
  position: 'fixed',
  left: 0,
  top: 50,
  background: '#fff',
  padding: '8px',
  zIndex: 99,
  maxHeight: '90%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column'
}, ({theme}) => ({
  boxShadow: theme.shadows.d2
}));

export default function ProductionInfo(props) {
  const [date, setDate] = useState('');
  const [designSteps, setDesignSteps] = useState();
  const [designs, setDesigns] = useState([]);
  const [document_id] = useState(props.document_id);
  const [error, setError] = useState('');
  const [id, setId] = useState(0);
  const [note, setNote] = useState('');
  const [prepSteps, setPrepSteps] = useState();
  const [size, setSize] = useState(1);

  useEffect(() => {
    axios.get('/settings', {
      params: {
        type: 'calendar',
        sub_type: 'steps',
        sub_sub_type: 'design'
      }
    })
      .then(res => {
        setDesignSteps(res.data.result.data.steps);
      });
    axios.get('/settings', {
      params: {
        type: 'calendar',
        sub_type: 'steps',
        sub_sub_type: 'prep'
      }
    })
      .then((res) => {
        setPrepSteps(res.data.result.data.steps);
      });
    axios.get('/calendar/card', {
      params: {
        document_id: props.document_id
      }
    })
      .then((res) => {
        if(res.data.result === null) return;
        const card = res.data.result;
        const date = new Date(card.date + ' 00:00:00');
        setId(card.production_card_id);
        setDate(date);
        setNote(card.note);
        setDesigns(card.designs);
        setSize(card.size ? card.size : 1);
      });
  }, []);

  function addDesign() {
    const steps = [];

    for (const step of designSteps) {
      if(step.type !== 'single' || !step.type) {
        steps.push({step: step.step, doneDate: null, doneBy: null, color: step.color, type: step.type });
      } else {
        steps.push({step: step.step, startDate: null, doneDate: null, doneBy: null, color: step.color, type: step.type });
      }
    }

    const design = {
      f: '',
      b: '',
      r: '',
      l: '',
      print: '',
      qty: '',
      printer: [],
      where: '',
      progress: [ ...steps ]
    };

    const newDesigns = designs;
    newDesigns.push(design);
    setDesigns([...newDesigns]);
  }

  function removeDesign() {
    const newDesigns = designs;
    newDesigns.pop();
    setDesigns([...newDesigns]);
  }

  function setDesignField(i, field, value) {
    const newDesigns = designs;
    newDesigns[i][field] = value;
    setDesigns([...newDesigns]);
  }

  function submit() {
    setError('');

    if(!date) {
      setError('Make sure you select a date');
      return;
    }

    if(!id) {
      const steps = [];
      for (const step of prepSteps) {
        steps.push({...step, doneDate: null, doneBy: null });
      }

      axios.post('/calendar/design', {
        document_id,
        // added
      });

      axios.post('/calendar/card', {
        document_id,
        added_by: props.user_account_id,
        date,
        type: '',
        size,
        note,
        designs,
        prep_steps: [ ...steps ]
      })
        .then((res) => {
          const data = res.data.result;
          const date = new Date(data.date);
          setId(data.production_card_id);
          setNote(data.note);
          setDesigns([...data.designs]);
          setDate(date);

          const event = {
            document_id: document_id,
            json: {
              date,
              designSteps,
              designs,
              error,
              id,
              note,
              prepSteps
            },
            type: 'calendar',
            key: 'createProductionCard',
            user_id: props.user_account_id
          };

          createHistory(event);

          props.onProductionDateChange();
          props.onModalClose();
        });
    } else {
      axios.put('/calendar/card', {
        production_card_id: id,
        date,
        note,
        designs,
        size
      })
        .then((res) => {
          const data = res.data.result;
          const date = new Date(data.date);
          setId(data.production_card_id);
          setNote(data.note);
          setDesigns([...data.designs]);
          setDate(date);

          const event = {
            document_id: document_id,
            json: {
              date,
              designSteps,
              designs,
              error,
              id,
              note,
              prepSteps
            },
            type: 'calendar',
            key: 'updateProductionCard',
            user_id: props.user_account_id
          };

          createHistory({
            variables: event
          });

          props.onProductionDateChange();
          props.onModalClose();
        });

    }
  }

  return(
    <Box>
      <PickerWrapper style={{...props.style, width: '300px'}}>
        <DayPicker
          onDayClick={date => { setDate(date); }}
          selectedDays={date ? date : undefined }
        />

        <h3 style={{ margin: '5px' }}>Designs:</h3>

        { designs.map((design, i) => {
          return (
            <div key={i}>
              {i !== 0 && <hr />}
              <h4 style={{ margin: '4px' }}>D{i + 1}</h4>
              <div>
                <TextInput
                  css={{
                    width:'19%',
                    display: 'inline-block',
                    margin:'0px'
                  }}
                  label="F"
                  width="100%"
                  number={true}
                  value={design.f}
                  onChange={(e) => { setDesignField(i, 'f', e.target.value); } }
                />

                <p
                  style={{
                    display: 'inline-block',
                    margin: '4px'
                  }}
                >X</p>

                <TextInput
                  css={{
                    width:'19%',
                    display: 'inline-block',
                    margin:'0px'
                  }}
                  label="B"
                  width="100%"
                  value={design.b}
                  onChange={(e) => { setDesignField(i, 'b', e.target.value); } }
                />

                <p
                  style={{
                    display: 'inline-block',
                    margin: '4px'
                  }}
                >X</p>

                <TextInput
                  css={{
                    width:'19%',
                    display: 'inline-block',
                    margin:'0px'
                  }}
                  label="R"
                  width="100%"
                  value={design.r}
                  onChange={(e) => { setDesignField(i, 'r', e.target.value); } }
                />

                <p
                  style={{
                    display: 'inline-block',
                    margin: '4px'
                  }}
                >X</p>
                <TextInput
                  css={{
                    width:'19%',
                    display: 'inline-block',
                    margin:'0px'
                  }}
                  label="L"
                  width="100%"
                  value={design.l}
                  onChange={(e) => { setDesignField(i, 'l', e.target.value); } }
                />
              </div>

              <TextInput
                label="Other Print"
                width="100%"
                value={design.print}
                onChange={(e) => { setDesignField(i, 'print', e.target.value); } }
              />
              <TextInput
                label="Quantity"
                width="100%"
                number={true}
                value={design.qty}
                onChange={(e) => { setDesignField(i, 'qty', e.target.value); } }
              />
            </div>
          );

        })}

        <div
          style={{
            alignSelf: 'end'
          }}
        >

          <Button
            styles={{
              width: '60px',
              height: '10px',
              paddingTop: '6px',
              paddingBottom: '20px',
              margin: '5px',
              alignSelf: 'end',
              backgroundColor: 'red'
            }}
            onClick={removeDesign}
          >
            <DeleteIcon />
          </Button>

          <Button
            styles={{
              width: '60px',
              height: '10px',
              paddingTop: '6px',
              paddingBottom: '20px',
              margin: '5px',
              alignSelf: 'end'
            }}
            onClick={addDesign}
          >
            <AddIcon />
          </Button>
        </div>

        <TextInput
          width={'100%'}
          height={'200px'}
          type="textarea"
          label="Note"
          value={note}
          onChange={(e) => { setNote(e.target.value); }}
        />

        <TextInput
          width={'100%'}
          label="Size"
          value={size}
          onChange={(e) => { setSize(Number(e.target.value)); }}
        />

        <Button
          onClick={() => {
            submit();
          }}
          style={{
            overflow: 'visible'
          }}
        >
          Submit
        </Button>
        <p style={{ margin: '5px', color: 'red' }}>{error}</p>
      </PickerWrapper>
    </Box>
  );
}
ProductionInfo = compose(
  connect((state) => ({
    user_account_id: state.app.user_account_id,
    user: state.app.user,
    user_group_id: state.app.user_group_id,
  })),
)(ProductionInfo);
