import React from 'react';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import UpArrow from 'react-icons/lib/fa/chevron-up';
import DownArrow from 'react-icons/lib/fa/chevron-down';

import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import SidebarRoutes from '@components/Containers/auth/SidebarRoutes';

const FilterWrapper = styled('div')(
  {
    height: 45,
    position: 'fixed',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 10px',
    transition: 'background 300ms',
    zIndex: 2,
  },
  ({ theme, hasSidebar }) => ({
    background: theme.primary.color,
    width: hasSidebar ? 'calc(100% - 250px)' : '100%',
    left: hasSidebar ? 250 : 0,
  }),
  ({ allowMini, theme }) =>
    allowMini
      ? {
        transition: 'left 300ms, width 300ms, background 300ms',
        [`${theme.breakpoints.down('lg')}`]: {
          left: 0,
          width: '100%',
        },
      }
      : {}
);

const IconWrapper = styled('div')({
  width: 45,
  padding: 7,
});

const Title = styled('span')(
  {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 20,
    textTransform: 'uppercase',
  },
  ({ theme }) => ({
    color: theme.primary.textOn,
  })
);

const InputWrapper = styled('div')({
  marginLeft: 'auto',
  alignSelf: 'center',
});

const Input = styled('input')(
  {
    height: 29,
    width: 200,
    border: 'none',
    borderRadius: 15,
    background: 'rgba(255,255,255,.6)',
    transition: 'background 300ms, width 300ms',
    transitionTimingFunction: 'cubic-bezier(.5,.25,0,1.25)',
    padding: '0px 15px',

    '&:focus': {
      background: 'rgba(255,255,255,1)',
      outline: 'none',
      width: 230,
    },
  },
  ({ theme }) => ({
    fontFamily: theme.primary.fontFamily,
  })
);

const Select = styled('select')(
  {
    height: 29,
    width: 200,
    border: 'none',
    borderRadius: 15,
    background: 'rgba(255,255,255,.6)',
    transition: 'background 300ms, width 300ms',
    transitionTimingFunction: 'cubic-bezier(.5,.25,0,1.25)',
    padding: '0px 15px',
    margin: '0px 7px',

    '&:focus': {
      background: 'rgba(255,255,255,1)',
      outline: 'none',
      width: 230,
    },
  },
  ({ theme }) => ({
    fontFamily: theme.primary.fontFamily,
  })
);

const ClearButton = styled('button')(
  {
    position: 'absolute',
    width: 19,
    height: 19,
    right: 16,
    top: 'calc(50% - 10px)',
    padding: 0,
    paddingTop: 2,
    fontWeight: 800,
    fontSize: 11,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    background: 'none',
    cursor: 'pointer',
    transition: 'opacity 200ms',

    '&:focus': {
      outline: 'none',
    },
  },
  ({ theme }) => ({
    color: theme.primary.dark,
    border: `2px solid ${theme.primary.dark}`,
    '&:hover': {
      color: theme.primary.light,
      borderColor: theme.primary.light,
    },
  })
);

class FilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false
    };
  }
  dirChanged = () => {
    localStorage.setItem(
      'down',
      localStorage.getItem('down') == 'true' ? 'false' : 'true'
    );
    this.props.onSelectChange();
    this.forceUpdate();
  };
  onChange = (e) => {
    this.props.onChange(e.target.value);
  };
  onClear = () => {
    this.input.focus();
    this.props.onClear();
  };
  selectChanged = (e) => {
    localStorage.setItem('sort', e.target.value);
    this.props.onSelectChange();
  };

  render() {
    const {
      color = '',
      icon,
      title = '',
      value = '',
      fullWidth,
      allowMini,
      unfilterable,
      unsortable,
    } = this.props;
    const selectValue = localStorage.getItem('sort')
      ? localStorage.getItem('sort')
      : 'jobname';
    return (
      <FilterWrapper
        style={{
          background: color,
        }}
        hasSidebar={fullWidth ? false : true}
        allowMini={allowMini}
      >
        {icon && (
          <IconWrapper>
            <this.props.icon />
          </IconWrapper>
        )}

        {/* Mobile Nav Drawer */}
        <IconButton
          color="inherit"
          edge="start"
          sx={{ display: { lg: 'none' } }}
          onClick={() => { this.setState({ drawerOpen: !this.state.drawerOpen }); }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          open={this.state.drawerOpen}
          variant="temporary"
          sx={{
            display: { lg: 'none' },
          }}
          onClose={() => { this.setState({ drawerOpen: false }); }}
        >
          <SidebarRoutes/>
        </Drawer>

        <Title>{title}</Title>
        <InputWrapper>
          <span>{this.props.custom}</span>
          {!unsortable && (
            <span>
              {localStorage.getItem('down') == 'true' ? (
                <UpArrow onClick={this.dirChanged} />
              ) : (
                <DownArrow onClick={this.dirChanged} />
              )}
              <Select
                placeholder="Limit"
                type="select"
                onChange={this.selectChanged}
                name="sort"
                defaultValue={selectValue ? selectValue : 'jobname'}
              >
                <option
                  value="jobname"
                >
                  Job Name
                </option>
                <option
                  value="createdAt"
                >
                  Created At
                </option>
                <option
                  value="priority"
                >
                  Priority
                </option>
                <option
                  value="followers"
                >
                  Followers
                </option>
              </Select>
            </span>
          )}
          {!unfilterable && (
            <span>
              <Input
                placeholder="Filter Results"
                value={value}
                onChange={this.onChange}
                ref={(input) => {
                  this.input = input;
                }}
              />
              <ClearButton
                onClick={this.onClear}
                style={{
                  opacity: value.length > 0 ? 1 : 0,
                  pointerEvents: value.length > 0 ? '' : 'none',
                }}
              >
                ✕
              </ClearButton>
            </span>
          )}
        </InputWrapper>
      </FilterWrapper>
    );
  }
}
export default withRouter(FilterBar);
