import React from 'react';
import styled from '@emotion/styled';
import DayPicker from 'react-day-picker';
import ModalStateController from './ModalStateController';
import 'react-day-picker/lib/style.css';

const PickerWrapper = styled('div')({
  position: 'fixed',
  left: 0,
  top: 50,
  background: '#fff',
  zIndex: 99
}, ({theme}) => ({
  boxShadow: theme.shadows.d2
}));

export default class DatePicker extends React.Component{
  render(){
    const { isOpen, onModalClose, style, onDayClick } = this.props;
    return(
      <ModalStateController
        enterAnimationClassName="pop-in-down"
        exitAnimationClassName="pop-out-up"
        isOpen={isOpen}
        onModalClose={onModalClose}
      >
        {
          ({getProps, closeModal}) => (
            <PickerWrapper {...getProps()} style={style}>
              <DayPicker
                onDayClick={date => {
                  closeModal();
                  onDayClick(date);
                }}
              />
            </PickerWrapper>
          )
        }
      </ModalStateController>
    );
  }
}
