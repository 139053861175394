import React from 'react';

export const CommandBox = React.forwardRef((props, ref) => {
  class CommandBox extends React.Component {

    state = {
      command: ''
    };

    commandChanged = (value) => {
      this.setState({command: value});
    };

    handleKeyPress = (keyCode) => {
      if(keyCode !== 13) { //Enter
        return;
      }

      const command = this.state.command.slice(0, 2);
      const payload = this.state.command.slice(2);

      // Go to customer PDF
      if(command === '01') {
        window.open('/workorder/label/' + payload, '_blank');
      }

      // This is to close the command box after the command has been executed
      window.dispatchEvent(new KeyboardEvent('keydown', { 'key': '~' }));
    }

    render() {
      return (
        <input
          ref={ref}
          type="text"
          onChange={(e) => {
            this.commandChanged(e.target.value);
          }}
          onKeyPress={(e) => {
            this.handleKeyPress(e.charCode);
          }}
          value={this.state.command}
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex:100000
          }}
        />
      );
    }

  }

  return <CommandBox {...props}/>;
});

CommandBox.displayName = 'CommandBox';
