import React from 'react';
import styled from '@emotion/styled';
import ModalStateController from '@components/ModalStateController';
import DatePicker from '@components/DatePicker';
import { TextInput, Button, Select } from '@components/Controls';
import compose from '../../utils/compose';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { calendarOptions } from '@/data/calendarData.js';
import { axios } from '@/api.js';

const NewEventWrapper = styled('div')({
  position: 'fixed',
  left: 0,
  top: 50,
  background: '#fff',
  zIndex: 88,
  padding: '8px'
}, ({theme}) => ({
  boxShadow: theme.shadows.d2
}));

class NewProductionCardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datepicker: false,
      note: '',
      date: null,
      type: '',
      size: ''
    };
  }

  closeDatePicker = () => {
    this.setState({
      datepicker: false
    });
  };

  newProductionCard = () => {
    axios.post('/calendar/card', {
      added_by: this.props.user_account_id,
      date: this.state.date,
      note: this.state.note,
      type: this.state.type,
      size: this.state.size
    })
      .then(() => {
        this.onModalClose();
      });
  };

  onModalClose = () => {
    this.setState({
      datepicker: false,
      note: '',
      date: null,
      type: '',
      size: '',
    });
    this.props.onModalClose();
  };

  openDatePicker = () => {
    const dm = this.date.getBoundingClientRect();
    const page = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    const left = ((dm.left - page.width) < (288 - 30)) ? (page.width - 288 - 30) : dm.left;
    this.setState({
      datepicker: true,
      datePickerStyle: {
        left,
        top: dm.bottom
      }
    });
  };

  selectDate = (date) => {
    this.setState({ date });
  };

  updateType = ({target: {value}}) => {
    this.setState({ type: value });
  };

  updateNote = ({target: {value}}) => {
    this.setState({ note: value });
  };

  updateSize = ({target: {value}}) => {
    this.setState({ size: value });
  };

  render() {
    const { isOpen, style } = this.props;
    const {
      datepicker,
      type,
      date,
      datePickerStyle
    } = this.state;


    return(
      <ModalStateController
        enterAnimationClassName="pop-in-down"
        exitAnimationClassName="pop-out-up"
        isOpen={isOpen}
        onModalClose={this.onModalClose}
      >
        {
          ({getProps }) => (
            <NewEventWrapper {...getProps()} style={style}>
              <Select
                label="Type"
                css={{ margin: 2 }}
                options={calendarOptions}
                onChange={this.updateType}
                value={type}
              />
              <TextInput
                label="Start Date"
                value={date ? format(date, 'MM/DD/YY') : ''}
                onClick={this.openDatePicker}
                innerRef={ref=>{this.date=ref;}}
                readOnly
              />
              <TextInput
                label="Note"
                value={this.state.note}
                onChange={this.updateNote}
              />
              <TextInput
                label="Size"
                value={this.state.size}
                onChange={this.updateSize}
                number={true}
              />
              <DatePicker
                isOpen={datepicker}
                onModalClose={this.closeDatePicker}
                onDayClick={this.selectDate}
                style={datePickerStyle}
              />
              <Button
                styles={{
                  backgroundColor: '#29c5ff',
                  width: '100%',
                  margin: '3px'
                }}
                onClick={this.newProductionCard}
              >
                Create Job
              </Button>
            </NewEventWrapper>
          )
        }
      </ModalStateController>
    );
  }

}
export default compose(
  connect((state) => ({
    user_account_id: state.app.user_account_id
  }))
)(NewProductionCardModal);
