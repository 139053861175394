import React from 'react';
import styled from '@emotion/styled';
import ModalStateController from '../../components/ModalStateController';
import { TextInput, Button, Select } from '../../components/Controls';
import DatePicker from '../../components/DatePicker';
import { format, isBefore } from 'date-fns';
import { axios } from '../../api';

import ConnectedFollower from './ConnectedFollower';

import {
  UserPickerModal,
} from './ProductionCardModal';

const NewEventWrapper = styled('div')({
  position: 'fixed',
  left: 0,
  top: 50,
  background: '#fff',
  zIndex: 88,
  padding: '8px'
}, ({theme}) => ({
  boxShadow: theme.shadows.d2
}));

export default class NewEventModal extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      startDatePickerOpen: false,
      endDatePickerOpen: false,
      startDate: null,
      endDate: null,
      note: '',
      calendar_event_id: '',
      eventTypes: [],
      type: 'Event',
      qty: '',
      userPickerOpen: false,
      user_id: null,
      userPickerY: '',
      userPickerX: '',
      start_timestamp: null,
      finish_timestamp: null
    };
  }

  componentDidUpdate(_, prevState) {
    if (
      this.props.editEvent &&
      this.props.editEvent.calendar_event_id !==
      prevState.calendar_event_id
    ) {
      this.setState({
        startDate: this.props.editEvent.start_date,
        endDate: this.props.editEvent.end_date,
        note: this.props.editEvent.note,
        calendar_event_id: this.props.editEvent.calendar_event_id,
        type: this.props.editEvent.type || 'Event',
        qty: this.props.editEvent.qty,
        user_id: this.props.editEvent.user_id,
        start_timestamp: this.props.editEvent.start_timestamp,
        finish_timestamp: this.props.editEvent.finish_timestamp
      });
    }
  }

  componentDidMount() {
    this.getEventTypes();
  }

  getEventTypes = () => {
    axios.get('/settings', {
      params: {
        type: 'calendar',
        sub_type: 'event',
        sub_sub_type: 'tasks'
      }
    })
      .then((res) => {
        console.log(res.data.result);
        const eventTypes = res.data.result.data;
        const events = [];

        eventTypes.map( (event) => {
          events.push({ value: event, label: event });
        });

        this.setState({eventTypes: events});
      });
  }

  openStartDatePicker = () => {
    const dm = this.startDate.getBoundingClientRect();
    const page = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    const left = ((dm.left - page.width) < (288 - 30)) ? (page.width - 288 - 30) : dm.left;
    this.setState({
      startDatePickerOpen: true,
      startDatePickerStyle: {
        left,
        top: dm.bottom
      }
    });
  }

  openEndDatePicker = () => {
    const dm = this.endDate.getBoundingClientRect();
    const page = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    const left = ((dm.left - page.width) < (288 - 30)) ? (page.width - 288 - 30) : dm.left;
    this.setState({
      endDatePickerOpen: true,
      endDatePickerStyle: {
        left,
        top: dm.bottom
      }
    });
  }

  closeStartDatePicker = () =>  {
    this.setState({ startDatePickerOpen: false }); 
  }

  closeEndDatePicker = () =>  {
    this.setState({ endDatePickerOpen: false }); 
  }

  selectStartDate = (startDate) => {
    this.setState({ startDate });
  }

  selectEndDate = (endDate) => {
    this.setState({ endDate });
  }

  updateType = ({target: {value}}) => {
    this.setState({
      type: value
    });
  };

  onModalClose = () => {
    this.setState({
      startDate: null,
      endDate: null,
      note: '',
      calendar_event_id: '',
      user_id: null,
      type: 'Event',
      qty: '',
      start_timestamp: null,
      finish_timestamp: null
    });
    this.props.onModalClose();
  };

  updateNote = ({target: {value}}) => {
    this.setState({
      note: value
    });
  };

  newEvent = (closeModal) => {
    axios.post('/calendar/event', {
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      note: this.state.note,
      type: this.state.type,
      qty: Number(this.state.qty),
      user_id: this.state.user_id,
      start_timestamp: this.state.start_timestamp,
      finish_timestamp: this.state.finish_timestamp
    })
      .then(() => {
        this.props.reloadEvents();
      });

    closeModal();
  };

  deleteEvent = (closeModal) => {
    axios.delete('/calendar/event', {
      params: {
        id: this.state.calendar_event_id
      }
    })
      .then(() => {
        closeModal();
        this.props.reloadEvents();
      });
  };

  saveEventQuery = () => {
    axios.put('/calendar/event', {
      calendar_event_id: this.state.calendar_event_id,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      note: this.state.note,
      type: this.state.type,
      qty: Number(this.state.qty),
      user_id: this.state.user_id,
      start_timestamp: this.state.start_timestamp,
      finish_timestamp: this.state.finish_timestamp
    })
      .then(() => {
        this.props.reloadEvents();
      });
  };

  saveEvent = (closeModal) => {
    this.saveEventQuery();
    closeModal();
  }

  onChooseUser = (user) => {
    this.setState({ user_id: user.user_account_id });
  }

  openUserPicker = () => {
    const picker = this.chooseUser.getBoundingClientRect();
    const x = picker.left + 20;
    const y = picker.top - 80;
    this.setState({ userPickerX: x, userPickerY: y, userPickerOpen: true });
  }

  updateQty = ({target: {value}}) => {
    this.setState({ qty: value });
  }

  setStartTimestamp = async () => {
    await this.setState({ start_timestamp: Date.now() });
    if(this.props.editEvent) {
      this.saveEventQuery();
    }
  }

  setFinishTimestamp = async () => {
    if(this.state.start_timestamp) {
      await this.setState({ finish_timestamp: Date.now() });
      if(this.props.editEvent) {
        this.saveEventQuery();
      }
    }
  }

  render(){
    const { isOpen, style, editEvent, users } = this.props;
    const {
      startDate,
      endDate,
      note,
      type,
      qty,
      startDatePickerOpen,
      endDatePickerOpen,
      startDatePickerStyle,
      endDatePickerStyle,
      userPickerOpen,
      start_timestamp,
      finish_timestamp
    } = this.state;
    
    let start = 0;

    if(start_timestamp) {
      start = new Date(Number(start_timestamp));
      start = format(start, 'h:m a');
    }

    let finish = 0;
    if(finish_timestamp) {
      finish = new Date(Number(finish_timestamp));
      finish = format(finish, 'h:m a');
    }

    const selectOptions = [
      { value: 'Event', label: 'Event' },
      { value: 'Pause', label: 'Pause' },
      ...this.state.eventTypes
    ];

    if(!selectOptions.some(s => s.value === type)) {
      selectOptions.push({ value: type, label: type });
    }

    return(
      <ModalStateController
        enterAnimationClassName="pop-in-down"
        exitAnimationClassName="pop-out-up"
        isOpen={isOpen}
        onModalClose={this.onModalClose}
      >
        {
          ({getProps, closeModal}) => (
            <NewEventWrapper {...getProps()} style={style}>              
              <Select
                label="Type"
                css={{ margin: 2 }}
                options={selectOptions}
                onChange={this.updateType}
                value={type}
              />
              <TextInput
                label="Start Date"
                value={startDate ? format(startDate, 'MM/DD/YY') : ''}
                onClick={this.openStartDatePicker}
                innerRef={ref=>{this.startDate=ref;}}
                readOnly
              />
              <DatePicker
                isOpen={startDatePickerOpen}
                onModalClose={this.closeStartDatePicker}
                onDayClick={this.selectStartDate}
                style={startDatePickerStyle}
              />
              <TextInput
                label="End Date (Optional)"
                value={endDate ? format(endDate, 'MM/DD/YY') : ''}
                onClick={this.openEndDatePicker}
                innerRef={ref=>{this.endDate=ref;}}
                readOnly
              />
              <DatePicker
                isOpen={endDatePickerOpen}
                onModalClose={this.closeEndDatePicker}
                onDayClick={this.selectEndDate}
                style={endDatePickerStyle}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '3px'
                }}
              >
                <Button
                  onClick={this.openUserPicker}
                  styles={{width: this.state.user_id ? '80%' : '100%'}}
                  ref={chooseUser => {
                    this.chooseUser = chooseUser;
                  }}
                >Select User</Button>
                { this.state.user_id &&
                <ConnectedFollower
                  user_id={this.state.user_id}
                />
                }
              </div>
              { type !== 'Event' && <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    onClick={this.setStartTimestamp}
                    styles={{
                      backgroundColor: start !== 0 ? '#9ccc64' : '#ffae46',
                      width: '100%',
                      margin: '3px'
                    }}
                  >
                    { start !== 0 ? start : 'Start'}
                  </Button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    onClick={this.setFinishTimestamp}
                    styles={{
                      backgroundColor: finish !== 0 ? '#9ccc64' : '#ffae46',
                      width: '100%',
                      margin: '3px'
                    }}
                  >
                    { finish !== 0 ? finish : 'Finish'}
                  </Button>
                </div>
              </> }
              { type !== 'Event' && type !== 'Pause' &&
                <TextInput
                  label="Qty"
                  value={qty}
                  onChange={this.updateQty}
                  number={true}
                />
              }
              <TextInput
                label="Note"
                value={note}
                onChange={this.updateNote}
              />
              { endDate && !isBefore(startDate, endDate) &&
                <p
                  style={{
                    // paddingBottom: '5px'
                  }}
                >
                  End date must be after start date.
                </p>
              }
              { note !== '' &&
                startDate !== null &&
                !editEvent &&
                type && 
                (!endDate || isBefore(startDate, endDate)) &&
                  <Button
                    styles={{width: '100%'}}
                    onClick={() => this.newEvent(closeModal)}
                  >
                    Save
                  </Button>
              }
              { note !== '' &&
                startDate !== null &&
                editEvent &&
                (!endDate || isBefore(startDate, endDate)) &&
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                      styles={{width: '47%'}}
                      onClick={() => this.saveEvent(closeModal)}
                    >
                      Save
                    </Button>
                    <Button
                      styles={{
                        width: '47%',
                        backgroundColor: '#333',
                        '&:hover': {
                          backgroundColor: '#424242'
                        },
                      }}
                      onClick={() => this.deleteEvent(closeModal)}
                    >
                      Delete
                    </Button>
                  </div>
              }
              <UserPickerModal
                isOpen={userPickerOpen}
                onModalClose={() => {this.setState({userPickerOpen: false});}}
                users={users}
                style={{top: this.state.userPickerY, left: this.state.userPickerX }}
                onChooseUser={this.onChooseUser}
              />
            </NewEventWrapper>
          )
        }
      </ModalStateController>
    );
  }
}
