
import React from 'react';
import styled from '@emotion/styled';
import { Draggable, Droppable } from 'react-dragtastic';
import ModalStateController from '../../../ModalStateController';

export default class ColumnSelector extends React.Component{

  static defaultProps = {
    hiddenColumns: [],
    onChange: () => {}
  };

  constructor(props){
    super(props);
    this.state = {
      hiddenColumns: props.hiddenColumns,
      dragType: null,
      dragStart: null
    };
  }

  componentDidUpdate = prevProps => {
    if(this.props.hiddenColumns.length !== prevProps.hiddenColumns.length){
      this.setState({hiddenColumns: this.props.hiddenColumns});
    }
  };


  toggleColumn = colKey => {
    const hiddenColumns = this.state.hiddenColumns;
    if(hiddenColumns.includes(colKey)) {
      hiddenColumns.splice(hiddenColumns.indexOf(colKey), 1);
    } else {
      hiddenColumns.push(colKey);
    }

    this.commitChange(hiddenColumns);
  };

  setColumn = endIndex => {
    console.log(endIndex);
    console.log(this.state.dragStart);
    const hiddenColumns = this.state.hiddenColumns;
    const changedColumns = this.props.hideableColumns.map(t => t.key)
      .slice(
        (this.state.dragStart > endIndex ? endIndex : this.state.dragStart),
        (this.state.dragStart > endIndex ? this.state.dragStart : endIndex) + 1
      );

    let filteredColumns;
    if(this.state.dragType === false) {
      hiddenColumns.push(...changedColumns);
      // Gets only unique values
      filteredColumns = hiddenColumns.filter((column, i, array) => array.indexOf(column) === i);
    } else {
      filteredColumns = hiddenColumns.filter((column) => !changedColumns.includes(column));
    }

    this.setState({ hiddenColumns: filteredColumns });
  };

  startColumnDrag = (col, active, startIndex) => {
    const hiddenColumns = this.state.hiddenColumns;
    this.setState(() => ({
      hiddenColumns: hiddenColumns,
      dragType: active,
      dragStart: startIndex,
    }));
  };

  commitChange = () => {
    this.props.onChange(this.state.hiddenColumns);
  };

  render() {
    const { hiddenColumns = [] } = this.state;
    const { hideableColumns } = this.props;
    return(
      <Wrapper>
        {hideableColumns.map((col, i) => {
          return (
            <Droppable
              id={col.key}
              onDragEnter={() => {
                this.setColumn(i);
              }}
              key={col.key}
            >
              {({ events: dropEvents }) => (
                <Draggable
                  id={col.key}
                  onDragStart={() => {
                    this.startColumnDrag(col.key, hiddenColumns.includes(col.key), i);
                  }}
                  onDragEnd={this.commitChange}
                >
                  {({ events: dragEvents }) => (
                    <ColumnButton
                      {...dragEvents}
                      {...dropEvents}
                      active={!(hiddenColumns.includes(col.key) ? true : false)}
                      noRadiusLeft={
                      // If the column is selected then the radius should be removed if the column next to it is not selected
                        hiddenColumns.includes(col.key) || i === 0 ? true : hiddenColumns.includes(hideableColumns?.[i - 1]?.key || {})
                      }
                      noRadiusRight={
                        hiddenColumns.includes(col.key) || i === hideableColumns.length - 1 ? true : hiddenColumns.includes(hideableColumns?.[i + 1]?.key || {})
                      }
                      onClick={() => {
                        this.toggleColumn(col.key);
                      }}
                    >
                      {col.headerName}
                    </ColumnButton>
                  )}
                </Draggable>
              )}
            </Droppable>
          );})}
      </Wrapper>
    );
  }
}

const Wrapper = styled('div')(({theme}) => ({
  height: 40,
  boxShadow: theme.shadows.d2,
  borderRadius: 30,
  padding: 3,
  background: '#fff'
}));

const ColumnButton = styled('button')({
  border: 'none'
},
({theme, active, noRadiusLeft, noRadiusRight}) => ({
  height: 34,
  minWidth: 34,
  borderRadius: 40,
  background: active ? theme.blue.color : 'none',
  color: active ? theme.primary.textOn : theme.primary,
  border: 'none',
  fontFamily: theme.primary.fontFamily,
  fontSize: 13,
  padding: 3,
  cursor: 'pointer',
  borderTopRightRadius: !noRadiusRight ? 0 : '',
  borderBottomRightRadius: !noRadiusRight ? 0 : '',
  borderTopLeftRadius: !noRadiusLeft ? 0 : '',
  borderBottomLeftRadius: !noRadiusLeft ? 0 : '',
  transition: 'border-radius 200ms, background 200ms, color 200ms',
  willChange: 'border-radius, background, color',
  userSelect: 'none',
  '&:focus': {
    outline: 'none',
  },
  '&:hover': {
    background: active ? theme.blue.light : theme.blue.alpha2
  }
}));

const SelectorWrapper = styled('div')({
  position: 'fixed',
  left: -10,
  top: 0,
  zIndex: 10
});

export class ColumnSelectorModal extends React.Component{

  render(){
    const { hiddenColumns, onChange, isOpen, onModalClose, coordinates={}, hideableColumns } = this.props;
    return(
      <ModalStateController
        enterAnimationClassName="pop-in-left"
        exitAnimationClassName="pop-out-left"
        isOpen={isOpen}
        onModalClose={onModalClose}
      >
        {
          ({getProps}) => (
            <SelectorWrapper style={{
              left: coordinates.x,
              top: coordinates.y
            }} {...getProps()}>
              <ColumnSelector hiddenColumns={hiddenColumns} onChange={onChange} hideableColumns={hideableColumns} />
            </SelectorWrapper>
          )
        }
      </ModalStateController>
    );
  }
}
