import React from 'react';
import styled from '@emotion/styled';

const Icon = styled('svg')({
  width: '100%'
});

export const AdminIcon = ({ color = '#fff' }) => (
  <Icon
    version="1.1"
    id="Layer_1"
    x="0"
    y="0"
    viewBox="0 0 73.05 73.05"
    enableBackground="new 0 0 73.053 73.053"
    style={{ fill: color }}
  >
    <path d="M36.55,7A29.55,29.55,0,1,1,7,36.55,29.59,29.59,0,0,1,36.55,7m0-7A36.55,36.55,0,1,0,73.1,36.55,36.54,36.54,0,0,0,36.55,0Z"/>
    <path d="M57.54,33.17l-3.47-.44a17.66,17.66,0,0,0-2.44-5.86l2.15-2.77A.86.86,0,0,0,53.71,23l-3.57-3.57A.86.86,0,0,0,49,19.32l-2.77,2.15A17.66,17.66,0,0,0,40.37,19l-.44-3.47a.86.86,0,0,0-.86-.75H34a.86.86,0,0,0-.86.75L32.73,19a17.66,17.66,0,0,0-5.86,2.44L24.1,19.32a.86.86,0,0,0-1.14.07L19.39,23a.86.86,0,0,0-.07,1.14l2.15,2.77A17.66,17.66,0,0,0,19,32.73l-3.47.44a.86.86,0,0,0-.75.86v5a.86.86,0,0,0,.75.86l3.47.44a17.66,17.66,0,0,0,2.44,5.86L19.32,49a.86.86,0,0,0,.07,1.14L23,53.71a.86.86,0,0,0,1.14.07l2.77-2.15a17.66,17.66,0,0,0,5.86,2.44l.44,3.47a.86.86,0,0,0,.86.75h5a.86.86,0,0,0,.86-.75l.44-3.47a17.66,17.66,0,0,0,5.86-2.44L49,53.78a.86.86,0,0,0,1.14-.07l3.57-3.57A.86.86,0,0,0,53.78,49l-2.15-2.77a17.66,17.66,0,0,0,2.44-5.86l3.47-.44a.86.86,0,0,0,.75-.86V34A.86.86,0,0,0,57.54,33.17Zm-1,5.14-3.34.42a.88.88,0,0,0-.74.71,15.88,15.88,0,0,1-2.63,6.34.87.87,0,0,0,0,1L52,49.46,49.46,52,46.8,49.89a.87.87,0,0,0-1,0,15.88,15.88,0,0,1-6.34,2.63.88.88,0,0,0-.71.74l-.42,3.34H34.79l-.42-3.34a.88.88,0,0,0-.71-.74,15.88,15.88,0,0,1-6.34-2.63.87.87,0,0,0-1,0L23.64,52l-2.49-2.49,2.06-2.66a.87.87,0,0,0,0-1,15.88,15.88,0,0,1-2.63-6.34.88.88,0,0,0-.74-.71l-3.34-.42V34.79l3.34-.42a.88.88,0,0,0,.74-.71,15.88,15.88,0,0,1,2.63-6.34.87.87,0,0,0,0-1l-2.06-2.66,2.49-2.49,2.66,2.06a.87.87,0,0,0,1,0,15.88,15.88,0,0,1,6.34-2.63.88.88,0,0,0,.71-.74l.42-3.34h3.52l.42,3.34a.88.88,0,0,0,.71.74,15.88,15.88,0,0,1,6.34,2.63.87.87,0,0,0,1,0l2.66-2.06L52,23.64,49.89,26.3a.87.87,0,0,0,0,1,15.88,15.88,0,0,1,2.63,6.34.88.88,0,0,0,.74.71l3.34.42Z"/>
    <path d="M36.55,23.89a12.61,12.61,0,0,0-12.09,8.88A.88.88,0,0,0,25,33.86a.87.87,0,0,0,1.08-.57,10.93,10.93,0,1,1,16.94,12v-4.8l2-2a.83.83,0,0,0,.26-.61V34.87a.87.87,0,1,0-1.73,0V37.6l-2,2a.86.86,0,0,0-.25.61v6.21a10.77,10.77,0,0,1-3.92,1.07V35.62a3.54,3.54,0,1,0-1.72,0V47.45a10.87,10.87,0,0,1-3.92-1.06V40.17a.86.86,0,0,0-.25-.61l-2-2V34.87a.87.87,0,1,0-1.73,0V38a.83.83,0,0,0,.26.61l2,2v4.82a10.83,10.83,0,0,1-3.93-5.52.87.87,0,0,0-1.65.52A12.66,12.66,0,1,0,36.55,23.89Zm-1.81,8.3A1.81,1.81,0,1,1,36.55,34,1.81,1.81,0,0,1,34.74,32.19Z"/>
    <circle cx="24.73" cy="36.55" r="0.86"/>
  </Icon>
);
