export default [
  `%c
S U P E R                             \\| /
M A R I O           ....'''.           |/
 B R O S     .''''''        '.       \\ |
             '.     ..     ..''''.    \\| /
AMC           '...''  '..''     .'     |/
 2   .sSSs.             '..   ..'    \\ |
 0  .P'  \`Y.               '''        \\| /
 0  SS    SS                           |/
 0  SS    SS                           |
    SS  .sSSs.                       .===.
    SS .P'  \`Y.                      | ? |
    SS SS    SS                      \`==='
    SS ""    SS
    P.sSSs.  SS
    .P'  \`Y. SS
    SS    SS SS                 .===..===..===..===.
    SS    SS SS                 |   || ? ||   ||   |
    ""    SS SS            .===.\`==='\`==='\`==='\`==='
  .sSSs.  SS SS            |   |
 .P'  \`Y. SS SS       .===.\`==='
 SS    SS SS SS       |   |
 SS    SS SS SS       \`==='
SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS
`,

  `%c
ヽ༼ ຈل͜ຈ༼ ▀̿̿Ĺ̯̿̿▀̿ ̿༽Ɵ͆ل͜Ɵ͆ ༽ﾉ
`,

  `%c
                ___,,___
           _,-='=- =-  -\`"--.__,,.._
        ,-;// /  - -       -   -= - "=.
      ,'///    -     -   -   =  - ==-=\\\`.
     |/// /  =    \`. - =   == - =.=_,,._ \`=/|
    ///    -   -    \\  - - = ,ndDMHHMM/\b  \\\\
  ,' - / /        / /\\ =  - /MM(,,._\`YQMML  \`|
 <_,=^Kkm / / / / ///H|wnWWdMKKK#""-;. \`"0\\  |
        \`""QkmmmmmnWMMM\\""WHMKKMM\\   \`--. \\> \\
 hjm          \`""'  \`->>>    \`\`WHMb,.    \`-_<@)
                                \`"QMM\`.
                                   \`>>>
`,

  `%c
                   .------.
                   ||'||||'|
                  ,|| -.._ |
                  \\|\`.-. .-.
                   | \`-'A'-'
                   | ( --.\`|
                  _|\`.\`___,'__   ____ 
              _,-'_| _   _/   /';    \`.
           ,-/ / '     /     ; ;       )
          / (__()     /     (__()\\     |
         |  (__()    |   __(__() : \`--  \\
         \\ _'__()\`------'  (__() \\       :
         ;  |\\/|           |\\/;\\  \\      |
         ;  '__'           |__| \`. ;.__, (
         :   \\ /           ;\\;|   /  \\    ,--.
         \`._ \\__\\          ;__|  ;   |   /\`.,-\`.
         /   |\\/|          |\\;|  |   :   \\\`/\`.__\\ 
        ;    |__|___ __ ___|__;__|    \\   \`\\\`/\`._\`.
       ,     |\\;|   _\\/_   |\\/|\\/|     \\  _|\`,\`.\`.,--.
       ;    ;|__|___\\/\\/___|__|__|      \`( ,/ /.\`/\`.,-\`.
           ; |   ,   | |   \`.    |       \\\`.;//;\`\\\`/ ,. \\
       jrei                  \`-  |        \`::_;   \`\\ \`' /
                                                    \`--'
`,

  `%c
            ___
         __|___|__
          ('o_o')
          _\\~-~/_    ______.
         //\\__/\\ \\ ~(_]---'
        / )O  O( .\\/_)
        \\ \\    / \\_/
        )/_|  |_\\
       // /(\\/)\\ \\
       /_/      \\_\\
      (_||      ||_)
        \\| |__| |/
         | |  | |
         | |  | |
         |_|  |_|
    JRO  /_\\  /_\\
`,

  `%c

         /\`\\  ___  /\`\\
         \\d \`"\\:/"\` b/
        /\`.--. \` .--.\`\\
        |/ __ \\ / __ \\|
        ( ((o) V (o)) )
        |\\\`""\`/^\\\`""\`/|
        \\ \`--'\\ /'--\` /
        /\`-._  \`  _.-\`\\
       / /.:.:.:.:.:.\\ \\
      ; |.:.:.:.:.:.:.| ;
      | |:.:.:.:.:.:.:| |
      | |.:.:.:.:.:.:.| |
      | |:.:.:.:.:.:.:| |
      \\/\\.:.:.:.:.:.:./\\/
        _\`).-.-:-.-.(\`_
    ,=^\` |=  =| |=  =| \`^=,
   /jgs  \\=/\\=/ \\=/\\=/     \\
          \`  \`   \`  \`
`
];
