import React from 'react';
import { Link } from 'react-router-dom';
import WorkCentersList from '@pages/WorkCenters/WorkCentersList';
import { css } from 'emotion';
import styled from '@emotion/styled';
import theme from '@/theme';
import { SidebarHeader } from '@components/shell/Sidebar';
import { connect } from 'react-redux';
import { createHistory } from '../../api';
import userPlaceholder from '@/common/img/user_placeholder_white.svg';
import WorkCenterButton from '@pages/WorkCenters/WorkCenterButton';
import ImageFallback from '@components/ImageFallback';
import { BASE_URL, axios } from '@/api';
import { AllIcon } from '@/common/img/icons/workCenters';
import { CalendarIcon } from '@/common/img/icons/Calendar';
import { CustomerIcon } from '@/common/img/icons/Customer';
import { AdminIcon } from '@/common/img/icons/Admin';
import PickupModal from '@components/PickupModal';

const MyOrdersButtonStyles = css({
  height: 70,
  textDecoration: 'none',
  borderBottom: '1px solid',
  display: 'flex',
  flexDirection: 'row',
  fontWeight: 600,
  transition: 'padding-left 300ms, background 300ms',
  '&:hover': {
    paddingLeft: 15,
  },
});

const ProfilePic = styled('img')(
  {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
  },
  ({ theme, active }) => ({
    background: theme.neutral.color,
    border: `4px solid ${active ? theme.neutral.color : theme.primary.dark}`,
  })
);

const ProfileWrapper = styled('div')({
  width: 70,
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 10,
  flex: '0 0 auto',
});

const Title = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

class WorkCentersSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      document: {},
      emailConfirmation: false
    };
  }

  dragDrop = (e, workcenter) => {

    e.preventDefault();
    const document_id = e.dataTransfer.getData('document_id');
    const id = e.dataTransfer.getData('id');
    const customerEmail = e.dataTransfer.getData('customerEmail');
    const firstname = e.dataTransfer.getData('customer');
    const jobname = e.dataTransfer.getData('jobname');
    const prevWorkcenter = e.dataTransfer.getData('prev_workcenter');

    if(!document_id) {
      return;
    }

    const event = {
      document_id: document_id,
      json_previous: prevWorkcenter,
      json: workcenter,
      type: 'workcenter',
      key: 'workcenter',
      user_id: this.props.user_account_id,
    };

    axios.put('/document/singleField', {
      id: document_id,
      field: 'workcenter',
      fieldValue: workcenter
    }).then(() => {
      if (workcenter == '6') {
        axios.delete('/document/followers/all', {
          params: {
            document_id
          }
        });
        if(firstname != 'null' && jobname != 'null' && customerEmail != 'null') {
          this.setState({
            emailConfirmation: true,
            document: {
              firstname,
              jobname,
              document_id,
              customerEmail
            }
          });
        }
      }
      createHistory(event);
    });
  };

  render() {
    const { isSuperAdmin } = this.props;
    return (
      <div>
        <SidebarHeader branding allowMini />
        <MyOrdersButton
          active={
            this.props.location.pathname.toLowerCase() ===
            '/workcenters/myorders'
          }
        />
        <WorkCenterButton 
          href="/workcenters/estimate"
          name="Estimate"
          active={this.props.location.pathname === '/workcenters/estimate'}
          htmlcolor="#2ab6f6"
          textColor={theme.primary.color}
        />
        <WorkCentersList
          activeWorkCenterId={this.props.match.params.workCenterId}
          dropHandler={this.dragDrop}
        />
        <WorkCenterButton
          href="/workcenters/all"
          name="All Orders"
          active={this.props.location.pathname === '/workcenters/all'}
          htmlcolor="#ffffff"
          textColor={theme.primary.color}
          Icon={AllIcon}
        />
        <WorkCenterButton
          href="/calendar"
          name="Calendar"
          active={this.props.location.pathname.match(/calendar/)}
          htmlcolor={'black'}
          textColor={theme.primary.textOn}
          Icon={CalendarIcon}
        />
        <WorkCenterButton
          href="/stock"
          name="Stock"
          active={this.props.location.pathname.match(/stock/)}
          htmlcolor={'#1B5E20'}
          textColor={theme.primary.textOn}
        />
        <WorkCenterButton
          href="/sales"
          name="Sales"
          active={this.props.location.pathname.match(/sales/)}
          htmlcolor={'#FBC02D'}
          textColor={theme.primary.textOn}
        />
        <WorkCenterButton
          href="/workcenters/customers"
          name="Customers"
          active={this.props.location.pathname === '/workcenters/customers'}
          htmlcolor={theme.blue.color}
          Icon={CustomerIcon}
        />
        <WorkCenterButton
          href="/tasks"
          name="Tasks"
          active={this.props.location.pathname === '/tasks'}
          htmlcolor="#Ef5350"
        />
        <WorkCenterButton
          href="https://web.edapp.com/#login"
          name="Learn"
          htmlcolor="rgb(255, 122, 69)"
        />
        {isSuperAdmin && (
          <WorkCenterButton
            href="/admin/employees"
            name="Admin Tools"
            htmlcolor={theme.primary.light}
            Icon={AdminIcon}
          />
        )}
        <PickupModal
          isOpen={this.state.emailConfirmation}
          document={this.state.document}
          onModalClose={() => {
            this.setState({ emailConfirmation: false });
          }}
        />
      </div>
    );
  }
}

export default connect(state => ({
  isSuperAdmin:
  state.app.user_group_id === 3 || state.app.user_group_id === '3',
  user_account_id: state.app.user_account_id,
}))(WorkCentersSidebar);

export class MyOrdersButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user: {}
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = () => {
    axios.get('/users/account/' + this.props.user_account_id)
      .then((res) => { this.setState({ user: res.data.result }); });
  }

  render() {
    const { active } = this.props;
    const { user } = this.state;
    return (
      <Link
        to="/workcenters/myorders"
        className={MyOrdersButtonStyles}
        style={{
          borderBottomColor: theme.primary.dark,
          color: theme.primary.textOn,
          background: active ? theme.primary.light : '',
        }}
      >
        <ProfileWrapper>
          <ImageFallback
            fallback={<ProfilePic active={active} src={userPlaceholder} />}
          >
            {props => (
              <ProfilePic
                {...props}
                active={active}
                src={
                  user?.user_profile
                    ? user.user_profile.profilepicture
                      ? `${BASE_URL}/api/documentimages/yopic${
                        user.user_profile.profilepicture
                      }`
                      : userPlaceholder
                    : userPlaceholder
                }
              />
            )}
          </ImageFallback>
        </ProfileWrapper>
        <Title>MY ORDERS</Title>
      </Link>
    );
  }
}
MyOrdersButton = connect(state => ({
  user_account_id: state.app.user_account_id,
}))(MyOrdersButton);

