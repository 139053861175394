import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { axios } from '../../api.js';
import CustomerType, { defaultCustomer } from '../../types/models/customer';
import AccountType from '../../types/quickbooks/customer.js';
import { stateOptions } from '../../data/options.js';
import PopoverSelect from '../../components/Reusable/controls/PopoverSelect.tsx';

interface CustomerModalProps {
  viewingCustomer: number,
  onClose: () => void
}

export default function CustomerModal(props:CustomerModalProps) {
  const [customer, setCustomer] = useState<CustomerType>(defaultCustomer);
  const [anchorEl, setAnchorEl] = useState<Element>();
  const [accounts, setAccounts] = useState<AccountType[]>([]);
  const [addingAccount, setAddingAccount] = useState<boolean>(false);

  useEffect(() => {
    getQBAccounts();
  }, []);

  useEffect(() => {
    if(props.viewingCustomer <= 0) return;
    getCustomer();
  }, [props.viewingCustomer]);

  function getQBAccounts():void {
    axios.get('/customer/quickbooks/all', {
      params: {
        attributes: ['DisplayName']
      }
    }) 
      .then((res) => {
        setAccounts(res.data.result);
      });
  }

  function getCustomer():void {
    axios.get('/customer', {
      params: {
        id: props.viewingCustomer
      }
    })
      .then((res) => {
        const newCustomer:CustomerType = defaultCustomer;
        const resCust:CustomerType = res.data.result;
        for(const prop in resCust) {
          // ignore the type errors
          // @ts-ignore
          if(resCust[prop] !== null) {  
            // @ts-ignore
            newCustomer[prop] = resCust[prop];
          } 
        }

        setCustomer({...newCustomer});
      });
  }

  function createCustomer():void {
    const newCustomer:CustomerType = { ...customer };
    delete newCustomer.customer_id;

    axios.post('/customer', {
      customer: newCustomer
    });
  }

  function updateCustomer():void {
    axios.put('/customer', {
      ...customer
    });
  }

  return (
    <Modal
      open={(props.viewingCustomer !== 0)}
      onClose={props.onClose}
    >
      <SurroundingBox>
        <Stack
          width={'100%'}
          spacing={.5}
          direction={'column'}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: 20
            }}
          >
            {'View Customer'.toUpperCase()}
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Stack
                direction="column"
                spacing={1}
                sx={{mr: 2}}
              >
                <TextField 
                  variant="standard"
                  size="small"
                  label="First Name"
                  value={customer.firstname}
                  onChange={(e) => { setCustomer({...customer, firstname: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                  error={customer.firstname === ''}
                  helperText={customer.firstname === '' ? 'Customer must have a first name.' : ''}
                />
                <TextField 
                  variant="standard"
                  size="small"
                  label="Last Name"
                  value={customer.lastname}
                  onChange={(e) => { setCustomer({...customer, lastname: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                  error={customer.lastname === ''}
                  helperText={customer.lastname === '' ? 'Customer must have a last name.' : ''}
                />
                <Stack
                  direction="row"
                  alignContent="center"
                  justifyContent="center"
                  width="100%"
                >
                  <Checkbox 
                    checked={customer.isorganization}
                    style={{ padding: 0, paddingRight: 8 }}
                    onChange={(e) => { 
                      setCustomer({...customer, isorganization: e.target.checked, organization: '' });
                    }}
                  />
                  <TextField 
                    variant="standard"
                    size="small"
                    label="Organization"
                    disabled={!customer.isorganization}
                    value={customer.organization}
                    onChange={(e) => { setCustomer({...customer, organization: e.target.value}); }}
                    style={{
                      width:'100%'
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Stack>
                <TextField 
                  variant="standard"
                  size="small"
                  label="Primary Phone"
                  value={customer.primaryphone}
                  onChange={(e) => { setCustomer({...customer, primaryphone: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                  error={customer.primaryphone === '' && customer.email === ''}
                  helperText={customer.primaryphone === '' && customer.email === '' ? 'Must have a phone number or email' : ''}
                />
                <TextField 
                  variant="standard"
                  size="small"
                  label="Secondary Phone"
                  value={customer.secondaryphone}
                  onChange={(e) => { setCustomer({...customer, secondaryphone: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField 
                  variant="standard"
                  size="small"
                  label="Fax"
                  value={customer.fax}
                  onChange={(e) => { setCustomer({...customer, fax: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField 
                  variant="standard"
                  size="small"
                  label="Email"
                  value={customer.email}
                  onChange={(e) => { setCustomer({...customer, email: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                  error={customer.primaryphone === '' && customer.email === ''}
                  helperText={customer.primaryphone === '' && customer.email === '' ? 'Must have a phone number or email' : ''}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction="column"
                spacing={1}
                width="auto"
              >
                <Typography
                  fontWeight={700}
                >Quickbooks Accounts</Typography>
                <Stack
                  direction="row"
                  spacing={1}
                >
                  {customer.quickbooksAccounts?.map((account, i) => {
                    return (
                      <Chip 
                        color="primary"
                        label={account.DisplayName}
                        key={account.Id}
                        onDelete={() => { 
                          // @ts-ignore
                          const newAccounts: AccountType[] = [...customer.quickbooksAccounts];
                          newAccounts.splice(i, 1);
                          setCustomer({ ...customer, quickbooksAccounts: newAccounts});
                        }}
                      />
                    );
                  })}
                </Stack>
                <Button
                  variant="contained"
                  endIcon={<AddCircleIcon />}
                  fullWidth
                  onClick={(e) => { 
                    setAddingAccount(true); 
                    setAnchorEl(e.currentTarget);
                  }}
                >
                    Add Quickbooks Account to Customer
                </Button>
                <PopoverSelect
                  anchorEl={anchorEl}
                  open={addingAccount}
                  onClose={() => { setAddingAccount(false); }}
                  mapItems={(accountFilter) => {
                    return accounts.filter((account) => {
                      return account.DisplayName
                        .toLowerCase()
                        .includes(accountFilter.toLowerCase());
                    }).map((account, i) => {
                      return (
                        <Stack 
                          key={i}
                          sx={{
                            paddingLeft: 1,
                            paddingTop: .5,
                            paddingBottom: .5,
                            '&:hover': {
                              backgroundColor: 'lightslategray'
                            },
                            cursor: 'pointer' 
                          }}
                          onClick={() => { 
                            const qbAccounts  = customer.quickbooksAccounts || [];
                            qbAccounts.push(account);
                            setCustomer({ ...customer, quickbooksAccounts: qbAccounts }); 
                          }}
                        >
                          <Typography>{account.DisplayName}</Typography>
                        </Stack>
                      );
                    });
                  }}
                />
                <Divider 
                  sx={{ backgroundColor: 'grey' }}
                />
                <TextField 
                  variant="standard"
                  size="small"
                  label="Address"
                  value={customer.address}
                  onChange={(e) => { setCustomer({...customer, address: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField 
                  variant="standard"
                  size="small"
                  label="City"
                  value={customer.city}
                  onChange={(e) => { setCustomer({...customer, city: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                />
                <FormControl>
                  <InputLabel id="state-select-label">State</InputLabel>
                  <Select
                    variant="standard"
                    size="small"
                    labelId="state-select-label"
                    label="State"
                    value={customer.state}
                    onChange={(e) => { setCustomer({...customer, state: e.target.value}); }}
                  >
                    {stateOptions.map((state) => {
                      return (
                        <MenuItem
                          key={state.value}
                          value={state.value}
                        >{state.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField 
                  variant="standard"
                  size="small"
                  label="Zipcode"
                  value={customer.zipcode}
                  onChange={(e) => { setCustomer({...customer, zipcode: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                  error={customer.zipcode === ''}
                  helperText={customer.zipcode === '' ? 'Customer must have a zipcode' : ''}
                />
                <TextField 
                  variant="standard"
                  size="small"
                  label="Notes"
                  multiline
                  value={customer.notes}
                  onChange={(e) => { setCustomer({...customer, notes: e.target.value}); }}
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
            </Grid>
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 1 }}
              onClick={() => {
                //setEditingCustomer(false);
                props.onClose();
                props.viewingCustomer === -1 ? createCustomer() : updateCustomer() 
              }}
            >
              {props.viewingCustomer === -1 ? 'Create Customer' : 'Update Customer'}
            </Button>
          </Grid>
        </Stack>
      </SurroundingBox>
    </Modal>
  );
}

const SurroundingBox = styled(Box)({
  width: '80%',
  height: '80%',
  backgroundColor: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'scroll',
  padding: '2%',
  display: 'flex',
});
