import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import shortid from 'shortid';
import CheckIcon from 'react-icons/lib/md/check';
import Transition from 'react-prop-transition';
import ModalStateController from './ModalStateController';
import loading from '../common/img/loading/loading.gif';


export const IconWrapper = styled('div')({
  margin: -3,
  marginRight: 5,
  width: 18,
  flex: '0 0 auto',
  '& svg': {
    width: '100%',
    height: '100%'
  }
});

const InputWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'relative',
  margin: '10px 0px',
  '&:first-of-type': {
    marginTop: 0
  },
  '&:last-child': {
    marginBottom: 0
  }
});

const InputLabel = styled('label')(
  {
    position: 'absolute',
    left: 15,
    top: 15,
    fontSize: 18,
    fontWeight: 500,
    transition: 'font-size 150ms, color 150ms, top 150ms',
    willChange: 'font-size, color, top',
    background: '#fff',
    padding: '0px 3px',
    pointerEvents: 'none'
  },
  ({ theme, empty }) => ({
    color: theme.neutral.color,
    fontSize: empty ? 18 : 14,
    top: empty ? 15 : -5
  })
);

const inputStyles = {
  appearance: 'none',
  height: 50,
  background: 'none',
  borderRadius: 4,
  padding: '5px 15px',
  transition: 'border-color 150ms'
};

const getDynamicInputStyles = ({ width, theme }) => ({
  width: width || 300,
  border: `2px solid ${theme.neutral.extraLight}`,
  '&:focus': {
    outline: 'none',
    borderColor: theme.vibrant.color,
    '& + label': {
      color: theme.vibrant.color,
      top: -5,
      fontSize: 14
    }
  }
});

const OutlinedTextArea = styled('textarea')(
  {
    ...inputStyles,
    height: '100%',
    paddingTop: '10px'
  },
  ({ width, theme }) => ({
    ...getDynamicInputStyles({ width, theme })
  })
);

const OutlinedInput = styled('input')(
  {
    ...inputStyles
  },
  ({ width, theme }) => ({
    ...getDynamicInputStyles({ width, theme })
  })
);

const OutlinedSelect = styled('select')(
  {
    ...inputStyles
  },
  ({ width, theme }) => ({
    ...getDynamicInputStyles({ width, theme })
  })
);

export class TextInput extends React.Component {
  getValue = () => this.input.value;

  filterNumbers = (e) => {
    if(/^\d+$/.test(e.target.value) || e.target.value === '') {
      this.props.onChange(e);
    }
  };

  render() {
    const {
      label,
      value = '',
      uncontrolled,
      onChange,
      onBlur,
      onKeyPress,
      width,
      name = '',
      inputCSS = {},
      css: cssOverrides,
      type = 'text',
      innerRef,
      onClick,
      id,
      number = false,
      readOnly
    } = this.props;
    const empty =
      this.props.empty !== undefined ? this.props.empty : value === '';
    return (
      <InputWrapper style={cssOverrides} onClick={onClick}>
        {(type == 'textarea') ?  
          <OutlinedTextArea 
            onAnimationEnd={e => {
              onChange(e);
            }}
            name={name}
            width={width}
            style={inputCSS}
            ref={(r) => {
              this.input = r;
              if(typeof innerRef === 'function') {
                innerRef(r);
              }
            }}
            value={uncontrolled ? undefined : value || ''}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
            id={id}
            readOnly={readOnly}
          />
          : <OutlinedInput
            onAnimationEnd={e => {
              number ? this.filterNumbers(e) : onChange(e);
            }}
            name={name}
            width={width}
            style={inputCSS}
            ref={(r) => {
              this.input = r;
              if(typeof innerRef === 'function') {
                innerRef(r);
              }
            }}
            type={type}
            value={uncontrolled ? undefined : value || ''}
            onChange={number ? this.filterNumbers : onChange}
            onKeyPress={onKeyPress}
            id={id}
            onBlur={onBlur}
            readOnly={readOnly}
          />}
        <InputLabel empty={empty}>{label}</InputLabel>
      </InputWrapper>
    );
  }
}

export class Select extends React.Component {
  render() {
    const {
      css: cssOverrides,
      label,
      inputCSS = {},
      options = [],
      value = '',
      onChange,
      name = '',
      readOnly = false,
      nonNull = true
    } = this.props;
    const empty = value === '';
    const optionsComponent = options.map(opt => <option key={opt.label} value={opt.value}>{opt.label}</option>);
    if(!nonNull) {
      optionsComponent.push(<option key={'null'} value=""></option>);
    }
    return (
      <InputWrapper className={css(cssOverrides)}>
        <OutlinedSelect
          className={css(inputCSS)}
          value={value || undefined}
          onChange={onChange}
          name={name}
          disabled={readOnly}
        >
          {optionsComponent}
        </OutlinedSelect>
        <InputLabel empty={empty}>{label}</InputLabel>
      </InputWrapper>
    );
  }
}

const CheckboxInput = styled('input')(
  {
    ...inputStyles,
    width: 0,
    height: 0,
    opacity: 0
  },
  ({ theme }) => ({
    '&:focus': {
      '& + label': {
        borderColor: theme.vibrant.color,
        '&:before': {
          opacity: 1
        }
      }
    }
  })
);


const StyledCheckbox = styled('label')(
  {
    ...inputStyles,
    flex: '0 0 auto',
    width: 22,
    height: 22,
    borderRadius: 2,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    transition: 'background 150ms',
    position: 'relative',
    '&:before': {
      content: '""',
      width: 40,
      height: 40,
      position: 'absolute',
      left: 'calc(50% - 20px)',
      top: 'calc(50% - 20px)',
      borderRadius: '100%',
      zIndex: -1,
      transition: 'opacity 150ms, background 150ms',
      opacity: 0
    }
  },
  ({ theme, checked }) => ({
    border: `2px solid ${
      checked ? theme.vibrant.color : theme.neutral.extraLight
    }`,
    background: checked ? theme.vibrant.color : 'none',
    color: theme.primary.textOn,
    '&:before': {
      background: theme.vibrant.alpha2
    },
    '&:active:before': {
      transition: 'background 10ms',
      opacity: 1,
      background: theme.vibrant.alpha4
    }
  })
);

const CheckboxLabel = styled('label')({
  paddingLeft: 14,
  userSelect: 'none'
});

export class Checkbox extends React.Component {
  htmlId = shortid.generate()
  render() {
    const { css: cssOverrides = {}, name = '', label, value, onChange, readOnly } = this.props;
    return (
      <InputWrapper
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          ...cssOverrides
        }}
      >
        <CheckboxInput disabled={readOnly} type="checkbox" name={name} checked={value} id={this.htmlId} onChange={onChange} />
        <StyledCheckbox checked={value} htmlFor={this.htmlId}>
          <CheckIcon opacity={value ? 1 : 0} transition="opacity 150ms" />
        </StyledCheckbox>
        <CheckboxLabel htmlFor={this.htmlId}>{label}</CheckboxLabel>
      </InputWrapper>
    );
  }
}

const StyledBox = styled('label')({
  position: 'relative',
  border: '2px solid rgb(189, 189, 189)',
  borderRadius: 3,
  width: 17,
  height: 17
}, ({theme, checked}) => (
  checked ? {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 2,
      top: 2,
      width: 9,
      height: 9,
      borderRadius: 1,
      background: theme.vibrant.color
    }
  } : null
));

export class SimpleCheckbox extends React.Component {
  id = shortid.generate()
  render() {
    const { css: cssOverrides = {}, value, onChange, boxCSS = {} } = this.props;
    return (
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          ...cssOverrides
        })}
      >
        <input
          type="checkbox"
          id={this.id}
          name={this.id}
          checked={value}
          value={value}
          onChange={e => onChange(e.target.checked)}
          className={css({
            opacity: .01,
            width: .1,
            height: .1,
            position: 'fixed',
            left: -1,
            top: -1,
            visible: 'hidden'
          })}
        />
        <StyledBox
          checked={value}
          htmlFor={this.id}
          style={{...boxCSS}}
        />
      </div>
    );
  }
}

const StyledRadio = styled('label')({
  position: 'relative',
  border: '2px solid rgb(189, 189, 189)',
  borderRadius: 12,
  width: 17,
  height: 17
}, ({theme, checked}) => (
  checked ? {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 2,
      top: 2.4,
      width: 9,
      height: 9,
      borderRadius: 12, 
      background: theme.vibrant.color
    }
  } : null
));

export class SimpleRadio extends React.Component {
  id = shortid.generate()
  render() {
    const { css: cssOverrides = {}, value, name, onChange } = this.props;
    return (
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          margin: '5px',
          alignItems: 'center',
          height: '100%',
          ...cssOverrides
        })}
      >
        <input
          type="radio"
          id={this.id}
          name={name}
          checked={value}
          value={value}
          onChange={e => onChange(e.target)}
          className={css({
            opacity: .01,
            width: .1,
            height: .1,
            position: 'fixed',
            left: -1,
            top: -1,
            visible: 'hidden'
          })}
        />
        <StyledRadio
          checked={value}
          htmlFor={this.id}
        />
      </div>
    );
  }
}

const StyledButton = styled('button')(
  {
    border: 'none',
    padding: '13px 20px 12px 20px',
    textTransform: 'uppercase',
    borderRadius: 2,
    transition: 'box-shadow 100ms',
    fontSize: 14,
    outline: 'none',
    position: 'relative',
    overflow: 'hidden',
    fontWeight: 700,
    userSelect: 'none',
    '& svg': {
      width: 15,
      height: 15,
      marginBottom: 2
    }
  },
  ({ theme, elevation, secondary }) => ({
    background: secondary ? 'none' : theme.vibrant.color,
    color: secondary ? theme.neutral.dark : theme.primary.textOn,
    '&:hover': {
      background: secondary ? 'rgba(0,0,0,.05)' : theme.vibrant.light,
      boxShadow: theme.shadows[`d${elevation + 1}`] || ''
    },
    '&:disabled': {
      background: theme.neutral.light,
      color: theme.neutral.textOn,
      boxShadow: 'none'
    },
    boxShadow: theme.shadows[`d${elevation}`] || ''
  }),
  ({ sidebar }) => (
    sidebar ? {
      background: 'rgba(255,255,255,.05)',
      '&:hover': {
        background: 'rgba(255,255,255,.1)',
      },
    } : {}),
  ({ light, theme }) =>
    light
      ? {
        background: 'none',
        color: theme.primary.textOn,
        '&:hover': {
          background: 'rgba(255,255,255,.05)'
        }
      }
      : {},
  ({danger, theme, disabled}) => (
    danger && !disabled ? {
      background: theme.danger.color,
      '&:hover': {
        background: theme.danger.light
      }
    } : {}
  ),
  ({success, theme, disabled}) => (
    success && !disabled ? {
      background: theme.success.color,
      '&:hover': {
        background: theme.success.light
      }
    } : {}
  )
);

const Ripple = styled('div')(
  {
    position: 'absolute',
    borderRadius: '100%',
    left: '50%',
    top: '50%',
    padding: '60%',
    animation: 'ripple 500ms',
    animationFillMod: 'forwards'
  },
  ({ secondary }) => ({
    background: secondary ? 'rgba(0,0,0,.1)' : 'rgba(255,255,255,.3)'
  })
);

const ChildWrapper = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '13px 20px 12px 20px'
});

const ProgressBar = styled('div')({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255,2555,255,.4)'
});

export const Button = React.forwardRef((props, ref) => {
  class Button extends React.Component {

    constructor(props) {
      super(props);
    }

    state = {
      ripples: {},
      holding: false
    }

    onClick = (e) => {
      if(this.props.disabled) {
        return;
      }
      if (!this.props.requireHold) {
        this.addRipple();
        if (this.props.onClick) {
          this.props.onClick(e);
        }
      }
    }

    addRipple = () => {
      this.setState(state => ({
        ripples: { ...state.ripples, [shortid.generate()]: true }
      }));
    }

    removeRipple = deletedId => {
      this.setState(state => {
        /* eslint-disable */
        const { [deletedId]: _, ...ripples } = state.ripples;
        /* eslint-enable */
        return {
          ripples
        };
      });
    }

    startHold = () => {
      if (this.props.requireHold) {
        this.setState({ holding: true });
        document.addEventListener('mouseup', this.stopHold);
        const duration = this.props.holdDuration || 3000;
        this.timeout = window.setTimeout(this.props.holdComplete, duration);
      }
    }

    stopHold = () => {
      document.removeEventListener('mouseup', this.stopHold);
      window.clearTimeout(this.timeout);
      this.setState({ holding: false });
    }
    
    onHoldComplete = () => {
      if (this.state.holding) {
        this.stopHold();
        if (this.props.holdComplete) {
          this.props.holdComplete();
        }
      }
    }

    render() {
      const {
        children,
        css: cssOverrides,
        styles,
        requireHold,
        holdShake,
        danger,
        holdDuration = 3000,
        ...restProps
      } = this.props;

      return (
        <StyledButton
          {...restProps}
          className={css(cssOverrides)}
          style={styles}
          onClick={(e) => this.onClick(e)}
          onMouseDown={this.startHold}
          danger={danger}
          ref={ref}
        >
          <span style={requireHold ? { opacity: 0 } : {}}>{children}</span>
          {requireHold && (
            <Transition
              props={{
                width: this.state.holding ? 100 : 0,
                r: this.state.holding ? 3 : 0
              }}
              duration={this.state.holding ? holdDuration : holdDuration / 6}
              easing="polyOut"
            >
              {({ width, r }) => (
                <Fragment>
                  <ChildWrapper>
                    <span style={{
                      transform: holdShake ? `translate(${(Math.random() * (r + r)) - r}px, ${(Math.random() * (r + r)) - r}px)` : '',
                      // transition: 'transform 30ms'
                    }}>{children}</span>
                  </ChildWrapper>
                  <ProgressBar
                    style={{
                      width: `${width}%`
                    }}
                  />
                </Fragment>
              )}
            </Transition>
          )}
          {!this.props.requireHold &&
            Object.keys(this.state.ripples).map(ripId => (
              <Ripple
                secondary={this.props.secondary}
                light={this.props.light}
                onAnimationEnd={() => {
                  this.removeRipple(ripId);
                }}
                key={ripId}
              />
            ))}
        </StyledButton>
      );
    }
  }

  return <Button {...props}/>;
});
Button.displayName = 'Button';

const FileInput = styled('label')({
  border: 'none',
  padding: '13px 20px 12px 20px',
  textTransform: 'uppercase',
  borderRadius: 2,
  top: '5px',
  transition: 'box-shadow 100ms',
  fontSize: 14,
  outline: 'none',
  position: 'relative',
  overflow: 'hidden',
  fontWeight: 700,
  userSelect: 'none',
  '& svg': {
    width: 15,
    height: 15,
    marginBottom: 2
  }
},
({ theme, elevation, secondary }) => ({
  background: secondary ? 'none' : theme.vibrant.color,
  color: secondary ? theme.neutral.dark : theme.primary.textOn,
  '&:hover': {
    background: secondary ? 'rgba(0,0,0,.05)' : theme.vibrant.light,
    boxShadow: theme.shadows[`d${elevation + 1}`] || ''
  },
  '&:disabled': {
    background: theme.neutral.light,
    color: theme.neutral.textOn,
    boxShadow: 'none'
  },
  boxShadow: theme.shadows[`d${elevation}`] || ''
}),
({ light, theme }) =>
  light
    ? {
      background: 'none',
      color: theme.primary.textOn,
      '&:hover': {
        background: 'rgba(255,255,255,.05)'
      }
    }
    : {},
({danger, theme, disabled}) => (
  danger && !disabled ? {
    background: theme.danger.color,
    '&:hover': {
      background: theme.danger.light
    }
  } : {}
),
({success, theme, disabled}) => (
  success && !disabled ? {
    background: theme.success.color,
    '&:hover': {
      background: theme.success.light
    }
  } : {}
));

export class FileUploadButton extends React.Component {
  
  state = {
    label: 'Choose a file...'
  }

  onChange = (e) => {
    const files = e.target.files;

    if (files.length === 1) {
      this.setState({ label: files[0].name });
    } else {
      this.setState({label: `${files.length} files selected`});
    }

    this.props.onChange(e);
  }

  render () {
    return (
      <div style={{
        // position: 'relative',
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 5,
        height: 45,
        ...this.props.css
      }}>
        <FileInput htmlFor="upload">{this.state.label}</FileInput>
        <input
          id="upload"
          type="file"
          onChange={(e) => this.onChange(e)}
          style={{
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'relative',
            zIndex: '-1'
          }}
        >
        </input>
      </ div>
    );
  }

}
export const OutlinedCircleButton = styled('button')(({ theme }) => ({
  padding: '1px 4px',
  borderRadius: 3,
  fontSize: 10,
  margin: 2,
  background: 'none',
  border: `1px solid ${theme.neutral.extraLight}`,
  color: theme.neutral.extraLight,
  opacity: 0,
  transition: 'opacity 200ms',
  cursor: 'pointer',
  '& svg': {
    height: '100%',
    width: 14,
    margin: '-1px -1px',
    marginTop: -3
  },
  '&:hover': {
    color: theme.neutral.light,
    borderColor: theme.neutral.light
  }
}));


/* FYI the scroll wrapper is the grey background behind the modal */
export const ScrollWrapper = styled('div')({
  overflowY: 'auto',
  width: '100%',
  padding: '15px',
  zIndex: 99,
  maxHeight: '100vh',
});

/* This is the actual container for the modal */
export const ModalWrapper = styled('div')(({ theme }) => ({
  background: '#fff',
  borderRadius: 15,
  margin: 'auto',
  marginTop: '100px',
  maxWidth: 1200,
  padding: '20px',
  width: '100%',
  boxShadow: theme.shadows.d3,
}));

const ScreenLock = styled('div')({
  background: 'rgba(0,0,0,.5)',
  height: '100vh',
  width: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export class Loading extends React.Component {

  render() {
    const { isOpen } = this.props;
    return (
      <>
        { isOpen && 
        <ScreenLock>
          <img src={loading} alt="LOADING" width="20%" /> 
        </ScreenLock>
        }
      </>
    );
  }

}

export class ConfirmationModal extends React.Component {


  render() {
    const { isOpen, onModalClose, onConfirm, text } = this.props;
    return (
      <ModalStateController
        enterAnimationClassName="fade-in"
        exitAnimationClassName="fade-out"
        isOpen={isOpen}
        onModalClose={onModalClose}
        shadeStyle={{ background: 'rgba(0,0,0,.5)' }}
      >
        {({getProps, closeModal}) => (
          <ScrollWrapper
            {...getProps()}
            onClick={() => {
              closeModal();
            }}
          >
            <ModalWrapper
              style={{
                width:'300px'
              }}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <p
                style={{margin:'10px'}}
              >{text}</p>
              <Button 
                styles={{
                  padding: '10px',
                  marginRight: '10px',
                  marginLeft: '70px',
                  backgroundColor: '#42b851'
                }}
                onClick={() => {onConfirm();}}>
                Confirm
              </Button>
              <Button 
                styles={{
                  padding: '10px',
                  marginRight: '10px',
                  backgroundColor: 'red'
                }}
                onClick={() => {closeModal();}}>
                Cancel
              </Button>
            </ModalWrapper>
          </ScrollWrapper>
        )}
      </ModalStateController>
    );
  }
}
