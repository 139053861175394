import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { axios } from '@/api.js';
import ProductionCardModal from './ProductionCardModal.js';
import {
  format,
  startOfWeek,
  endOfWeek
} from 'date-fns';
import ConnectedFollower from './ConnectedFollower';
import { StatusSortOrder, TypeSortOrder, TypeColorMap, StatusColorMap } from '@/data/calendarData';
import Stack from '@mui/material/Stack';
import DesignModal from './DesignModal.js';
import UserIcon from '@components/Reusable/user/UserIcon.js';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from '@mui/material/Tooltip';

function Cards(props) {
  const [ cardModalOpen, setCardModalOpen ] = useState(false);
  const [ cardModalStyle, setCardModalStyle ] = useState();
  const [ editCard, setEditCard ] = useState();
  const [ cards, setCards ] = useState([]);
  const [ designs, setDesigns ] = useState([]);
  const [ sortedCards, setSortedCards ] = useState({});
  const [ designCardModalOpen, setDesignCardModalOpen ] = useState(false);
  const [ modalDesignId, setModalDesignId ] = useState(0);

  useEffect(() => {
    if(window.location.hash !== '') {
      setDesignCardModalOpen(true);
      setModalDesignId(window.location.hash.substring(1));
    }
  }, []);

  useEffect(() => {
    reloadCards();
  }, [props.dateRange.startOfWeek, props.dateRange.endOfWeek, props.filterByUser]);

  useEffect(() => {
    sortCards();
  }, [cards, designs, props.sortBy]);

  function calculateDesignPrint(card) {
    if(!card.f && !card.b && !card.r && !card.l) {
      return card.print;
    }
    let print = (card.f ? card.f : '0') + 'x';
    print += (card.b ? card.b : '0') + 'x';
    print += (card.r ? card.r : '0') + 'x';
    print += card.l ? card.l : '0';
    if(card.print) {
      print += 'x' + card.print;
    }

    return print;
  }

  function closeCardModal() {
    setCardModalOpen(false);
    reloadCards();
  }

  function dragStart(e, card) {
    e.dataTransfer.setData('production_card_id', String(card.production_card_id));
    e.dataTransfer.setData('prev_date', String(card.date));
    e.dataTransfer.setData('document_id', String(card.document.document_id));
    props.cardDragStart();
  }

  function getCards() {
    const [start, end] = getStartAndEndDate();
    setCards([]);
    axios.get('/calendar/cards', {
      params: {
        start,
        end
      }
    })
      .then((res) => {
        setCards(res.data.results);
      });
  }

  function getDesigns() {
    const [start, end] = getStartAndEndDate();
    setDesigns([]);
    axios.get('/calendar/design/range', {
      params: {
        start,
        end
      }
    })
      .then((res) => {
        let designs = res.data.results;
        if(props.filterByUser) {
          designs = designs.filter((design) => {
            return design.calendar_print_tasks.some((task) => {
              return task.user_profiles.some((profile) => profile.user_account_id === props.user_account_id);
            });
          });
        }
        setDesigns(designs);
      });
  }

  function getStartAndEndDate() {
    let start = '';
    let end = '';

    start = format(startOfWeek(props.dateRange.startOfWeek), 'YYYY-MM-DD');
    end = format(endOfWeek(props.dateRange.endOfWeek), 'YYYY-MM-DD');

    return [start, end];
  }

  function openCardModal(e, card) {
    const page = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    const left = ((page.width - e.clientX) < (646)) ? (e.clientX - 646) : e.clientX;
    const top = ((page.height - e.clientY) < 800) ? (150) : e.clientY;
    setCardModalOpen(true);
    setCardModalStyle({left, top});
    setEditCard(card);
  }

  function reloadCards() {
    setSortedCards({});
    getDesigns();
    getCards();
  }

  function sortCards() {
    const designSet = splitDesigns();
    const cardSet = splitCards();
    const dateSet = cardSet;

    Object.keys(designSet).forEach(key => {
      if(dateSet[key]) {
        dateSet[key].push(...designSet[key]);
      } else {
        dateSet[key] = designSet[key];
      }
    });

    Object.keys(cardSet).forEach((key) => {
      dateSet[key].sort((a, b) => {
        if(props.sortBy === 'design') {
          if(a.document_id < b.document_id) {
            return -1;
          } else if(a.document_id > b.document_id) {
            return 1;
          } else {
            if(a.designNumber && b.designNumber) {
              return a.designNumber < b.designNumber ? -1 : 1;
            }
          }
        }
        if(a.finished || b.finished || a.type == 'Finished' || b.type == 'Finished') {
          if((a.finished || a.type == 'Finished') && !b.finished && b.type != 'Finished') {
            return 1;
          } else if((b.finished || b.type == 'Finished') && !a.finished && a.type != 'Finished') {
            return -1;
          }
        }
        const ai = StatusSortOrder.indexOf(a.document.priority);
        const bi = StatusSortOrder.indexOf(b.document.priority);
        if (ai < bi) {
          return -1;
        }
        if (bi < ai) {
          return 1;
        }
        const at = TypeSortOrder.indexOf(a.type);
        const bt = TypeSortOrder.indexOf(b.type);
        if (at < bt) {
          return -1;
        }
        if (bt < at) {
          return 1;
        }

        if(props.sortBy === 'type') {
          if(a.document_id === b.document_id) {
            return a.designNumber < b.designNumber ? -1 : 1;
          }
        }
        return 0;
      });
    });

    setSortedCards({ ...dateSet });
  }

  // Splits the cards up by date and then sorts them
  function splitCards() {
    const dateSet = {};
    cards.forEach(card => {
      if (dateSet[card.date])
        dateSet[card.date].push(card);
      else
        dateSet[card.date] = [card];
      if(!card.document) {
        card.document = { priority: 'Standard' };
        card.prep_steps = [];
        card.designs = [];
      } else if (card.document.priority === null) {
        card.document.priority = 'Standard';
      }
    });

    return dateSet;
  }

  function splitDesigns() {
    const dateSet = {};
    designs.forEach(design => {
      if(dateSet[design.prod_date]) {
        dateSet[design.prod_date].push(design);
      } else {
        dateSet[design.prod_date] = [design];
      }

      if(!design.document.priority) {
        design.document.priority = 'Standard';
      }
    });

    return dateSet;
  }

  function taskUsersSet(design) {
    if(!design) return;
    return new Set(
      design.calendar_print_tasks
        .map((task) => {
          return task.hideUser ? [] : task.user_profiles;
        })
        .flat()
    );
  }

  const { overlappingEvents, cardDragEnd, users } = props;
  return (
    <>
      {sortedCards && Object.keys(sortedCards).map((key) => {
        const gridRow = overlappingEvents + 4;
        const gridColumn = `${Number(format(key, 'd')) + 1}`;
        return (
          <Stack 
            key={key}
            sx={{
              gridRow,
              gridColumn,
              position: 'relative',
              top: '50px',
              paddingX: '8px',
              display: 'block'
            }}
            direction="column"
            spacing={.5}
            justifyContent="flex-start"
            alignItems="center"
          >
            {sortedCards[key].map((card) => {
              return card.prod_date ? (
                <CardBase
                  id={card.id}
                  style={{
                    height: `${(card.completion_time_hours) ? ((card.completion_time_hours > 0.33) ? card.completion_time_hours * 68 : 0.33 * 68) : 68}px`,
                    borderLeftColor: card.document.priority ? StatusColorMap[card.document.priority] : '#333333',
                    borderRightColor: card.document.hasshipping ? '#44AFDE' : '',
                    borderRightWidth: card.document.hasshipping ? '8px' : '',
                    borderRightStyle: card.document.hasshipping ? 'solid' : '',
                    background: card.finished ? TypeColorMap['Finished'].bg : (card.type ? TypeColorMap[card.type].bg : '#333'),
                    color: card.type ? TypeColorMap[card.type].text : '#fff',
                  }}
                  draggable={true}
                  onDragStart={(e) => dragStart(e, card)}
                  onDragEnd={() => {
                    cardDragEnd();
                  }}
                  onClick={() => { 
                    setDesignCardModalOpen(true); 
                    setModalDesignId(card.id);
                  }}
                  key={card.id}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    <CardTitle>
                      <span
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis'
                        }}
                      >D{card.designNumber} - {card.document.jobname}</span>
                      <span
                        style={{
                          flexGrow: 1
                        }}
                      >
                        {' ' + card.calendar_print_tasks.map(task => {
                          if(!task.reportSymbol) return '';
                          if(task.task_timings.length < 1) return '';
                          if(task.task_timings.every(timing => timing.finished)) return task.reportSymbol;
                          return '';
                        }).join('')}
                      </span>
                    </CardTitle>
                  </div>
                  <div style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginBottom: '4px'
                  }}>
                    { /* prints - qty */}
                    <Stack
                      direction="row"
                      alignItems="center"
                    >
                      {
                        card.fPrint + 'x' + 
                        card.bPrint + 'x' + 
                        card.lPrint + 'x' + 
                        card.rPrint + 
                        (card?.otherPrints ? (card?.otherPrints?.map((print) => { return 'x' + print; }).join('')) : '') + 
                        ' - ' + card.itemQuantity + ' '
                      }{card.sub_types.map((sub_type) => {
                        return(
                          <Tooltip 
                            key={card.designNumber + ' ' + sub_type}
                            title={sub_type}
                            arrow
                          >
                            <div 
                              style={{
                                marginLeft: '2px',
                                display: 'block',
                                backgroundColor: TypeColorMap[sub_type].bg,
                                width: '15px',
                                height: '15px',
                                borderRadius: 15
                              }}
                            >
                            </div>
                          </Tooltip>
                        );
                      })}
                    </Stack>
                  </div>
                  <AvatarGroup>
                    {[...taskUsersSet(card)]?.map((user_profile) => {
                      console.log(user_profile.profilepicture);
                      return <UserIcon 
                        size={30}
                        key={user_profile.user_account_id}
                        profilepic={user_profile.profilepicture}
                      />;
                    })}
                  </AvatarGroup>
                  <div>
                    {(card.completion_time_hours === null ? '' : card.completion_time_hours + 'hr - ') + (card.calendar_note ?? '#')}
                  </div>
                </CardBase>
              ) : (
                <CardBase
                  style={{
                    height: `${(card.size) ? (card.size * 68) : 68}px`,
                    borderLeftColor: card.document.priority ? StatusColorMap[card.document.priority] : '#333333',
                    borderRightColor: card.document.hasshipping ? '#44AFDE' : '',
                    borderRightWidth: card.document.hasshipping ? '8px' : '',
                    borderRightStyle: card.document.hasshipping ? 'solid' : '',
                    background: card.type ? TypeColorMap[card.type].bg : '#333',
                    color: card.type ? TypeColorMap[card.type].text : '#fff'
                  }}
                  draggable={true}
                  onDragStart={(e) => dragStart(e, card)}
                  onDragEnd={() => {
                    cardDragEnd();
                  }}
                  onClick={(e) => openCardModal(e, card)}
                  key={card.production_card_id}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    <CardTitle>{card.document.jobname}</CardTitle>
                    <div style={{
                      textAlign: 'right',
                      width: 'min-content',
                      whiteSpace: 'nowrap'
                    }}>
                      {card.prep_steps.map((step) => {
                        if (step.symbol && step.doneBy) {
                          return (
                            <span key={step.step}>
                              {step.symbol}
                            </span>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginBottom: '4px'
                  }}>
                    {card.designs.map((design, i) => (
                      <span key={i}>
                        {`${calculateDesignPrint(design)} (${design.qty})${i + 1 !== card.designs.length ? ' - ' : '' }`}
                      </span>
                    ))}
                  </div>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                    <div>
                      {(card.timeToComplete === null ? '' : card.timeToComplete + 'hr - ') + card.note}
                    </div>
                    <div style={{
                      display: 'flex',
                      maxWidth: '100%',
                      overflowX: 'hidden',
                    }}>
                      {card.designs.map((design, j) => (
                        <React.Fragment key={j} >
                          {design.printer.map((printer, i) => (
                            <ConnectedFollower
                              key={i}
                              user_id={printer}
                              size="small"
                            />
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </CardBase>
              );
            })}
          </Stack>
        );
      })}
      <div className={css({
        gridRow: `${overlappingEvents + 3 + 24}`,
        gridColumnStart: 1,
        gridColumnEnd: 8,
        height: '24px',
        backgroundColor: 'red',
        justifyContent: 'center',
        alignSelf: 'center',
        textAlign: 'center',
        background: '#333',
        color: '#fff',
      })}
      >
        <p style={{ margin: '3px' }}>24</p>
      </div>
      <ProductionCardModal
        isOpen={cardModalOpen}
        onModalClose={closeCardModal}
        style={cardModalStyle}
        editCard={editCard}
        users={users}
      />
      <DesignModal
        open={designCardModalOpen}
        onClose={() => { 
          setDesignCardModalOpen(false); 
          reloadCards();
        }}
        designId={modalDesignId}
      />
    </>
  );
}
export default connect((state) => {
  return { user_account_id: state.app.user_account_id };
})(Cards);

const CardBase = styled('div')({
  borderLeftWidth: '8px',
  borderLeftStyle: 'solid',
  height: '68px',
  padding: '4px 8px',
  fontSize: '14px',
  position: 'relative',
  alignSelf: 'start',
  color: '#fff',
  fontWeight: 500,
  boxSizing: 'border-box',
  top: '60px',
  zIndex: 1,
  width:'100%',
  overflow: 'clip',
  display: 'block'
});

const CardTitle = styled('h1')({
  fontSize: '14px',
  margin: '0',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'flex'
});
