import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Downshift from 'downshift';
import { axios } from '../../api';

const SelectorWrapper = styled('div')({
  position: 'relative'
});

export default class StyleSelector extends React.Component {
  render() {
    const { onChange, children, defaultInputValue } = this.props;
    return (
      <Downshift
        defaultInputValue={defaultInputValue}
        onChange={selection => {onChange(selection);}}
      >
        {({
          getInputProps,
          getItemProps,
          getRootProps,
          inputValue,
          selectedItem,
          highlightedIndex,
          isOpen
        }) => (
          <SelectorWrapper {...getRootProps({}, { suppressRefError: true})}>
            { children({getInputProps}) }
            {isOpen ? (
              <AutoCompleteMenu
                {...{
                  inputValue,
                  selectedItem,
                  highlightedIndex,
                  getItemProps
                }}
              />
            ) : null}
          </SelectorWrapper>
        )}
      </Downshift>
    );
  }
}

const MenuWrapper = styled('div')({
  position: 'absolute',
  left: 0,
  top: 26,
  width: 200,
  maxHeight: 100,
  overflowY: 'auto',
  zIndex: 99,
  background: '#fff',
  borderRadius: '0px 0px 3px 3px'
}, ({theme}) => ({
  boxShadow: theme.shadows.d2
}));

const Item = styled('div')({
  padding: 3,
  background: '#fff'
});

const AutoCompleteMenu = props => {
  const {
    highlightedIndex,
    getItemProps
  } = props;

  const [inventory_items, set_inventory_items] = useState([]);

  useEffect(() => {
    axios.get('/inventory', {
      params: { stylenumber: props.inputValue, limit: 50 }
    })
      .then((res) => {
        set_inventory_items(res.data);
      });
  }, [props.inputValue]);

  return (
    <MenuWrapper>
      {(inventory_items || []).slice(0, 30).map(({ stylenumber, color, id }, index) => (
        <Item
          key={index}
          {...getItemProps({
            item: `${stylenumber}|${color}`,
            index,
            key: id,
            style: {
              backgroundColor: highlightedIndex === index ? 'rgb(176, 236, 255)' : '',
              // fontWeight: selectedItem === stylenumber ? 'bold' : 'normal'
            }
          })}
        >
          {`${stylenumber}|${color}`}
        </Item>
      ))}
    </MenuWrapper>
  );
};
