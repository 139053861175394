import React, { useState, useEffect } from 'react';
import {
  Button,
  TextInput,
  ConfirmationModal,
  Select,
  FileUploadButton,
  Loading
} from './Controls';
import { connect } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { TypedHistory } from './History';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { axios, createHistory } from '../api';
import { format } from 'date-fns';
import EmailPicker from './EmailPicker.js';

const PaymentsTitle = styled('h3')({
  marginTop: 0
});

const Title = styled('h2')({
  marginTop: 0
});

const HeaderButtonsFlex = styled('div')({
  flex: 1,
  flexDirection: 'row',
  display: 'flex',
  width: '100%',
});

const HeaderButtonWrapper = styled('div')({
  flex: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex'
});

const NewPaymentWrapper = styled('div')({});

const TextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

const Text = styled('p')({
  display: 'flex',
  marginTop: 5,
  marginBottom: 5
});

export default class Payments extends React.Component {
  constructor(props) {
    super(props);
    const { doc } = props;
    const totalPaid = doc.totalPaid ? doc.totalPaid : 0;
    const remaining = parseFloat((Math.round(totalPaid * 100) / 100) - (Math.round(doc.total * 100) / 100)).toFixed(2);
    this.state = {
      form: '',
      amount: remaining,
      type: 'Card',
      note: '',
      error: ''
    };
  }
  
  openManualPayment = () => {
    this.setState({form: 'manual'});
  };

  openEmailPayment = () => {
    this.setState({form: 'email'});
  };

  openSendInvoice = () => {
    this.setState({form: 'invoice'});
  };

  changeAmount = (amount) => {
    this.setState({amount: amount});
  };

  changeNote = (note) => {
    this.setState({note: note});
  };

  changeType = (type) => {
    this.setState({type: type});
  };

  manualPayment = () => {
    const totalPaid = this.props.doc.totalPaid ? this.props.doc.totalPaid : 0;
    const newTotal = parseFloat(totalPaid) + parseFloat(this.state.amount);
    
    if (this.state.type === '') {
      this.setState({error: 'Type must be set.'});
      return;
    }

    if (isNaN(this.state.amount) || this.state.amount === '') {
      this.setState({error: 'Amount must be a number.'});
      return;
    }

    const paymentJSON = {
      amount: this.state.amount,
      note: this.state.note,
      type: this.state.type,
      previousTotal: totalPaid,
      newTotal: newTotal
    };

    axios.put('/document/singleField', {
      id: this.props.doc.document_id,
      field: 'totalPaid',
      fieldValue: newTotal
    })
      .then(() => {
        createHistory({
          document_id: this.props.doc.document_id,
          user_id: this.props.user_account_id,
          json: paymentJSON,
          type: 'payment',
          key: 'manualPayment'
        });
      });

    this.changeAmount(0);
    this.changeNote('');
    this.changeType('');
    this.props.onModalClose();
  }

  render() {
    const { form, amount, note, type} = this.state;
    const { doc } = this.props;
    const totalPaid = doc.totalPaid ? doc.totalPaid : 0;
    const remaining = parseFloat((Math.round(totalPaid * 100) / 100) - (Math.round(doc.total * 100) / 100)).toFixed(2);

    return (
      <>
        <Title>Payments</Title>
        <HeaderButtonsFlex>
          <HeaderButtonWrapper>
            <Button onClick={this.openManualPayment}>Manual payment</Button>
          </HeaderButtonWrapper>
          <HeaderButtonWrapper>
            <Button onClick={this.openEmailPayment}>Email Payment</Button>
          </HeaderButtonWrapper>
          <HeaderButtonWrapper>
            <Button onClick={this.openSendInvoice}>Send Invoice</Button>
          </HeaderButtonWrapper>
        </HeaderButtonsFlex>
        <hr />
        {form === 'manual' &&
        <>
          <NewPaymentWrapper>
            <PaymentsTitle>New Manual Payment</PaymentsTitle>
            <Select
              label="Payment Type"
              options={[
                { value: 'Card', label: 'Card' },
                { value: 'Cash', label: 'Cash' },
                { value: 'Check', label: 'Check' },
                { value: 'Other', label: 'Other' },
                { value: 'Refund', label: 'Refund' }
              ]}
              value={type || 'Cash'}
              onChange={(e) => {
                this.changeType(e.target.value);
              }}
            >
            </Select>
            <TextInput
              css={{
                flex: '1 1 auto',
                margin: 0,
                marginRight: 7
              }}
              inputCss={{
                height: '30px',
                fontSize: '14px',
                top: '7px',
              }}
              onChange={({target: {value}}) => {this.changeAmount(value);}}
              value={amount} 
              label={`Amount ($${remaining})`}
            />
            <TextInput
              css={{
                height: '150px',
              }}
              inputCss={{
                height: 'auto',
                fontSize: '14px',
                top: '7px',
              }}
              type="textarea"
              label="Note"
              width="100%"
              onChange={({target: {value}}) => {this.changeNote(value);}}
              value={note}
            />
            <Button
              onClick={() => {this.manualPayment();}}
            >
              Submit
            </Button>
            { this.state.error !== '' && <h4 style={{color: '#ff5050'}}>{this.state.error}</h4>}
          </NewPaymentWrapper>
          <hr />
        </>
        }
        {form === 'email' &&
        <EmailPayment doc={doc} onModalClose={this.props.onModalClose} />
        }
        {form === 'invoice' &&
        <SendInvoice doc={doc} onModalClose={this.props.onModalClose} />
        }
        <PaymentsTitle>Active Email Payment</PaymentsTitle>
        <ActiveEmailPayment document_id={this.props.doc.document_id}></ActiveEmailPayment>

        <hr />
        <PaymentsTitle>History</PaymentsTitle>
        <TypedHistory document_id={this.props.doc.document_id} type="payment" />
      </>
    );
  }
}
Payments = connect((state, props) => ({
  user_account_id: state.app.user_account_id,
  user: state.app.user,
  user_group_id: state.app.user_group_id,
  location: props.location,
}))(Payments);

function ActiveEmailPayment(props) {
  const [confirm, setConfirm] = useState(false);
  const [activePaymentEmail, setActivePaymentEmail] = useState(null);

  useEffect(() => {
    getActivePaymentEmail();
  }, []);

  function getActivePaymentEmail() {
    axios.get('/invoice-payments/active-email', {
      params: {
        document_id: props.document_id
      }
    })
      .then((res) => {
        setActivePaymentEmail(res?.data?.result ?? null);
      })
      .catch((e) =>  {
        console.log(e);
      });
  }

  function checkExpired(dateToCheck) {
    const date = new Date(dateToCheck);
    const now = new Date(Date.now()); 

    //Converts the milliseconds to hours and then days
    const days = (now - date) / (1000 * 60 * 60) / 24; 

    return (days > 10);
  }

  function deleteActiveEmail() {
    axios({
      method: 'POST',
      url: '/deletePayment',
      data: {document_id: props.document_id},
      crossDomain: true,
    }).then(() => {
      createHistory({
        document_id: props.document_id,
        user_id: props.user_account_id,
        type: 'payment',
        key: 'deletedActiveEmail'
      });
    }).then(() => {
      getActivePaymentEmail();
    }).catch(error => {
      console.log(error);
    });
  }

  function openConfModal() {
    setConfirm(true);
  }

  function closeConfModal() {
    setConfirm(false);
  }

  return (activePaymentEmail) ? (

    <NewPaymentWrapper
      className={css({
        padding: '15px'
      })}
    >
      {checkExpired(activePaymentEmail.sent) && 
        <TextContainer>
          <Text
            className={css({
              color:'red'
            })}
          >
            EXPIRED
          </Text>
        </TextContainer>
      }
      <TextContainer>
        <Text>
          To: {activePaymentEmail.to}
        </Text>
        <Text>
          {format(activePaymentEmail.sent, 'MMM do, YYYY')}
        </Text>
      </TextContainer>
      <TextContainer>
        <Text>
          CC: {activePaymentEmail.cc}
        </Text>
      </TextContainer>
      <TextContainer>
        <Text>Amount: ${Number(activePaymentEmail.amount).toFixed(2)}</Text>
      </TextContainer>
      <TextContainer>
        <Text
          css={{
            flex: '29'
          }}
        >Note: {activePaymentEmail.note}</Text>
        <TextContainer>
          <Button
            styles={{
              height: '10px',
              width: '25px',
              alignSelf: 'flex-end',
              backgroundColor: 'red',
              flex: '1',
              paddingTop: '3px',
              paddingRight: '29px',
              paddingBottom: '19px',
              paddingLeft: '15px'
            }}
            onClick={openConfModal}
          >
            <DeleteIcon/>
          </Button>
        </TextContainer>
      </TextContainer>
      <ConfirmationModal
        isOpen={confirm}
        onModalClose={closeConfModal}
        onConfirm={deleteActiveEmail}
        text="Are you sure you want to delete this email?"
      ></ConfirmationModal>
    </NewPaymentWrapper>

  ) : (<p>No current active payment email</p>);
} //If there is no active payment email, don't return anything
ActiveEmailPayment = connect((state) => ({
  user_account_id: state.app.user_account_id,
}))(ActiveEmailPayment);

class EmailPayment extends React.Component {

  constructor(props) {
    super(props);

    const { doc } = props;
    const totalPaid = doc.totalPaid ? doc.totalPaid : 0;
    const remaining = parseFloat((Math.round(totalPaid * 100) / 100) - (Math.round(doc.total * 100) / 100)).toFixed(2);

    this.state = {
      cc: '',
      to: '',
      amount: remaining,
      note: '',
      error: '',
      files: null,
      invoice: '',
      sending: false,
    };
  }

  componentDidMount() {
    this.getDocumentInvoiceNumber();
    axios.get('/settings', {
      params: {
        type: 'general',
        sub_type: 'email',
        sub_sub_type: 'note',
      }
    })
      .then((res) => {
        this.setState({ note: res.data.result.data.text });
      });
  }

  changeAmount = (val) => {
    this.setState({amount: val});
  };

  changeInvoice = (val) => {
    this.setState({invoice: val});
  };

  changeNote = (val) => {
    this.setState({note: val});
  };

  changeTo = (val, e) => {
    if(e == true) {
      if(this.state.cc != val){
        this.setState({to: val});
      } else {
        this.setState({to: val, cc: ''});
      }
    } else {
      this.setState({to: ''});
    }
  };

  changeCC = (val, e) => {
    if(e == true) {
      if(this.state.to != val) {
        this.setState({cc: val});
      } else {
        this.setState({cc: val, to: ''});
      }
    } else {
      this.setState({cc: ''});
    }
  };

  getDocumentInvoiceNumber = () => {
    axios.get('/document/detail', {
      params: {
        document_id: this.props.doc.document_id,
        attributename: 'billedInvoice'
      }
    })
      .then((res) => {
        console.log(res);
        this.setState({ invoice: res.data.result.value });
      })
      .catch(() => {
        this.setState({ invoice: '' });
      });
  }

  handleFile = (e) => {
    const files = e.target.files;
    this.setState({ files });
  };

  submitPayment = () => {
    if (this.state.to === '') {
      this.setState({error: 'Must select a `to` address.'});
      return;
    }

    if (isNaN(this.state.amount) || this.state.amount === '') {
      this.setState({error: 'Amount must be a number.'});
      return;
    }

    if (isNaN(this.state.invoice) || this.state.invoice === '') {
      this.setState({error: 'Invoice must be a number.'});
      return;
    }

    if (this.state.amount <= 0) {
      this.setState({error: 'Amount must be greater than 0.'});
      return;
    }

    if (!this.state.files) {
      this.setState({error: 'Must attach an invoice.'});
      return;
    }

    // Due to the way you have to send files this is not our standard axios convention

    this.setState({ loading: true });
    
    const emailData = new FormData();
    emailData.append('amount', this.state.amount);
    emailData.append('invoice', this.state.invoice);
    emailData.append('cc', this.state.cc);
    emailData.append('document_id', this.props.doc.document_id);
    emailData.append('note', this.state.note);
    emailData.append('to', this.state.to);
    emailData.append('user', this.props.user_account_id);
    emailData.append('file', this.state.files[0]);

    axios({
      method: 'POST',
      url: '/emailpayment',
      data: emailData,
      headers: { 'Content-Type': 'multipart/form-data' },
      crossDomain: true,
    })
      .then(() => {
        this.changeAmount('');
        this.changeNote('');
        this.setState({loading: false});
        this.props.onModalClose();
      })
      .catch(error => {
        console.log(error);
        this.setState({loading: false});
        this.setState({error: 'Unable to send email.'});
      });

    this.changeNote('');
    this.changeAmount(''); 
  };

  render () {
    const {
      amount,
      note,
      invoice
    } = this.state;
    const { doc } = this.props;
    const totalPaid = doc.totalPaid ? doc.totalPaid : 0;
    const remaining = parseFloat((Math.round(totalPaid * 100) / 100) - (Math.round(doc.total * 100) / 100)).toFixed(2);
    return (
      <>
        <NewPaymentWrapper>
          <PaymentsTitle>New Email payment</PaymentsTitle>
          <EmailPicker
            to={this.state.to}
            cc={this.state.cc}
            changeTo={this.changeTo}
            changeCC={this.changeCC}
            careof={doc.customer.email}
            billto={doc.customer_billto.email}
          />
          <TextInput
            label={`Amount (${remaining})`}
            onChange={({target: {value}}) => {this.changeAmount(value);}}
            value={amount}
          />
          <TextInput
            label={'Invoice'}
            onChange={({target: {value}}) => {this.changeInvoice(value);}}
            value={invoice}
          />
          <TextInput
            width="100%"
            type="textarea"
            label="Note"
            css={{height: '150px'}}
            onChange={({target: {value}}) => {this.changeNote(value);}}
            value={note}
          />
          <FileUploadButton
            onChange={this.handleFile}
          />
          <Button
            onClick={() => this.submitPayment()}
            styles={{marginLeft: '5px'}}
          > Send</Button>
          { this.state.error !== '' && <h4 style={{color: '#ff5050'}}>{this.state.error}</h4>}
        </NewPaymentWrapper>
        <hr />
        <Loading isOpen={this.state.loading} />
      </>
    );
  }
}
EmailPayment = connect((state, props) => ({
  user_account_id: state.app.user_account_id,
  user: state.app.user,
  user_group_id: state.app.user_group_id,
  location: props.location,
}))(EmailPayment);

class SendInvoice extends React.Component {

  state = {
    to: '',
    cc: '',
    note: '',
    error: '',
    loading: false
  };

  componentDidMount() {
    axios.get('/settings', {
      params: {
        type: 'general',
        sub_type: 'email',
        sub_sub_type: 'invoice_note',
      }
    })
      .then((res) => {
        this.setState({ note: res.data.result.data.text });
      });
  }

  changeTo = (val, e) => {
    if(e == true) {
      if(this.state.cc != val){
        this.setState({to: val});
      } else {
        this.setState({to: val, cc: ''});
      }
    } else {
      this.setState({to: ''});
    }
  };

  changeCC = (val, e) => {
    if(e == true) {
      if(this.state.to != val) {
        this.setState({cc: val});
      } else {
        this.setState({cc: val, to: ''});
      }
    } else {
      this.setState({cc: ''});
    }
  };

  changeNote = (val) => {
    this.setState({note: val});
  };

  handleFile = (e) => {
    const files = e.target.files;
    this.setState({ files });
  };

  sendInvoice = () => {

    if (this.state.to === '') {
      this.setState({error: 'Must select a `to` address.'});
      return;
    }

    if(!this.state.files) {
      this.setState({error: 'Must attach an invoice.'});
      return;
    }

    this.setState({ loading: true });

    const emailData = new FormData();
    emailData.append('to', this.state.to);
    emailData.append('cc', this.state.cc);
    emailData.append('note', this.state.note);
    emailData.append('user', this.props.user_account_id);
    emailData.append('document_id', this.props.doc.document_id);
    emailData.append('file', this.state.files[0]);

    axios({
      method: 'POST',
      url: '/sendinvoice',
      data: emailData,
      headers: { 'Content-Type': 'multipart/form-data' },
      crossDomain: true
    })
      .then(() => {
        this.changeNote('');
        this.setState({ loading: false });
        this.props.onModalClose();
      }).catch(error => {
        console.log(error);
        this.setState({ loading: false });
        this.setState({error: 'Unable to send email.'});
      });

  };

  render() {
    const { doc } = this.props;
    const {
      note,
    } = this.state;

    return (
      <>
        <NewPaymentWrapper>
          <PaymentsTitle>Send Invoice Email</PaymentsTitle>
          <EmailPicker
            to={this.state.to}
            cc={this.state.cc}
            changeTo={this.changeTo}
            changeCC={this.changeCC}
            careof={doc.customer.email}
            billto={doc.customer_billto.email}
          />
          <TextInput
            width="100%"
            type="textarea"
            label="Note"
            css={{height: '150px'}}
            onChange={({target: {value}}) => {this.changeNote(value);}}
            value={note}
          />
          <FileUploadButton
            onChange={this.handleFile}
          />
          <Button
            onClick={() => this.sendInvoice()}
            styles={{marginLeft: '5px'}}
          >Send</Button>
          { this.state.error !== '' && <h4 style={{color: '#ff5050'}}>{this.state.error}</h4>}
        </NewPaymentWrapper>
        <hr />
        <Loading isOpen={this.state.loading}/>
      </>
    );
  }
}
SendInvoice = connect((state, props) => ({
  user_account_id: state.app.user_account_id,
  user: state.app.user,
  user_group_id: state.app.user_group_id,
  location: props.location
}))(SendInvoice);
