import React, { useEffect, useState } from 'react';
import { Button, TextInput, Checkbox } from '@components/Controls.js';
import { axios } from '@/api.js';

export default function Approval() {
  const [approvalQuestions, setApprovalQuestions] = useState({});

  useEffect(() => {
    axios.get('/settings', {
      params: {
        type: 'approval',
        sub_type: 'email',
        sub_sub_type: 'questions'
      }
    })
      .then((res) => {
        console.log(res.data.result.data);
        setApprovalQuestions(res.data.result.data);
      });
  }, []);

  function changeApprovalQuestions(key, index, value) {
    const groups = approvalQuestions;
    groups[key][index].question = value;
    setApprovalQuestions({...groups});
  }

  function changeApprovalCheckbox(key, index, value) {
    const groups = approvalQuestions;
    groups[key][index].allowNA = value;
    setApprovalQuestions({...groups});
  }

  function changeApprovalDescription(key, index, value) {
    const groups = approvalQuestions;
    groups[key][index].description = value;
    setApprovalQuestions({...groups});
  }

  function removeQuestion(key, index) {
    const groups = approvalQuestions;
    groups[key].splice(index, 1);
    setApprovalQuestions({...groups});
  }

  function addQuestion(key) {
    const groups = approvalQuestions;
    groups[key].push({question: '', allowNA: false});
    setApprovalQuestions({...groups});
  }

  function onApprovalQuestionSubmit() {
    const data = approvalQuestions;
    axios.put('/settings', {
      type: 'approval',
      sub_type: 'email',
      sub_sub_type: 'questions',
      data
    });
  }

  return (
    <>
      <h2 style={{ margin: 0}}>Approval Questions</h2>
      <div>
        <ApprovalSetting
          questions={approvalQuestions?.meta}
          type="meta"
          label="Meta"
          changeApprovalQuestions={changeApprovalQuestions}
          changeApprovalCheckbox={changeApprovalCheckbox}
          changeApprovalDescription={changeApprovalDescription}
          removeQuestion={removeQuestion}
          addQuestion={addQuestion}
        />

        <hr
          style={{
            clear: 'both',
          }}
        />

        <ApprovalSetting
          questions={approvalQuestions?.lines}
          type="lines"
          label="Lines"
          changeApprovalQuestions={changeApprovalQuestions}
          changeApprovalCheckbox={changeApprovalCheckbox}
          changeApprovalDescription={changeApprovalDescription}
          removeQuestion={removeQuestion}
          addQuestion={addQuestion}
        />

        <hr
          style={{
            clear: 'both',
          }}
        />

        <ApprovalSetting
          questions={approvalQuestions?.artwork}
          type="artwork"
          label="Artwork"
          changeApprovalQuestions={changeApprovalQuestions}
          changeApprovalCheckbox={changeApprovalCheckbox}
          changeApprovalDescription={changeApprovalDescription}
          removeQuestion={removeQuestion}
          addQuestion={addQuestion}
        />

        <hr
          style={{
            clear: 'both'
          }}
        />

        <ApprovalSetting
          questions={approvalQuestions?.other}
          type="other"
          label="Other"
          changeApprovalQuestions={changeApprovalQuestions}
          changeApprovalCheckbox={changeApprovalCheckbox}
          changeApprovalDescription={changeApprovalDescription}
          removeQuestion={removeQuestion}
          addQuestion={addQuestion}
        />

        <Button
          onClick={onApprovalQuestionSubmit}
          styles={{
            margin: '20px',
            float: 'right',
            clear: 'both'
          }}
        >Save Questions</Button>
      </div>
    </>
  );
}

const ApprovalSetting = ({
  questions,
  type,
  label,
  changeApprovalQuestions,
  changeApprovalCheckbox,
  changeApprovalDescription,
  removeQuestion,
  addQuestion
}) => {

  return (
    <>
      {questions && questions.map((question, index) => {

        return (
          <div
            style={{ margin:'30px'}}
            key={index}
          >
            <p
              style={{
                clear: 'both'
              }}
            >{label}:</p>
            <div
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <TextInput
                label="Question"
                style={{width: '90%'}}
                value={question.question}
                onChange={event => {
                  changeApprovalQuestions(type, index, event.target.value);
                }}
              />
              <Checkbox
                label="Allow N/A"
                value={question.allowNA}
                onChange={event => {
                  changeApprovalCheckbox(type, index, event.target.checked);
                }}
              />
              <Button
                styles={{ padding: '0px 20px 0px 20px', height: '30px' }}
                onClick={() => {
                  removeQuestion(type, index);
                }}
              >Sub</Button>
            </div>
            <TextInput
              type="textarea"
              label="Description"
              inputCSS={{width: '100%'}}
              css={{width:'100%'}}
              value={question.description}
              onChange={event => {
                changeApprovalDescription(type, index, event.target.value);
              }}
            />
          </div>
        );
      })}
      <Button
        onClick={() => addQuestion(type)}
        styles={{
          float: 'right',
          marginRight: '25px',
          marginBottom: '20px'
        }}
      >Add</Button>
    </>
  );
};
