import React, { useRef, useState } from 'react';
import ModalStateController from './ModalStateController';
import styled from '@emotion/styled';
import { Row } from '../pages/Workorder/CustomerSlider';
import { TextInput, SimpleRadio, Checkbox, Select } from './Controls';
import { stateOptions, billingTypes } from '../data/options';
import { axios } from '../api';
import CustomerList from './Reusable/data/CustomerList';
import Button from '@mui/material/Button';

export default class MergeCustomerModal extends React.Component {
  render() {
    const { isOpen, onModalClose } = this.props;
    return (
      <ModalStateController
        enterAnimationClassName="fade-in"
        exitAnimationClassName="fade-out"
        isOpen={isOpen}
        onModalClose={onModalClose}
      >
        {({getProps, closeModal}) =>
          <ScrollWrapper
            {...getProps()}
            onClick={() =>
              closeModal()
            }
          >
            <ModalWrapper
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <MergeCustomer
                closeModal={closeModal}
              />
            </ModalWrapper>
          </ScrollWrapper>
        } 
      </ModalStateController>

    );
  }
}

const ScrollWrapper = styled('div')({
  overflowY: 'auto',
  width: '100%',
  height: '100%',
  padding: 30,
  zIndex: 99,
  maxHeight: '100vh',
});

const ModalWrapper = styled('div')(({ theme }) => ({
  background: '#fff',
  borderRadius: 15,
  width: '100%',
  height: '100%',
  maxWidth: 1200,
  padding: 30,
  margin: '0 auto',
  boxShadow: theme.shadows.d3,
}));


function MergeCustomer(props) {

  const [customers, setCustomers] = useState([null, null]);
  const [error, setError] = useState('');
  const leftSelect = useRef(null);
  const rightSelect = useRef(null);

  async function setCustomer(num, customer) {
    if (customer === null) {
      const customersTemp = [...customers];
      customersTemp[num] = null;
      setCustomers(customersTemp);
      return;
    }

    const new_customer = (await axios.get('/customer', {
      params: { id: customer.customer_id }
    })).data.result;

    const customerData = {
      customer_id: { value: '' },
      isorganization: {value: false, selected: false, label:'Is Organization' },
      organization: { value: '', selected: false, label: 'Organization' },
      firstname: { value: '', selected: false, label: 'First Name'},
      lastname: {value: '', selected: false, label: 'Last Name'},
      email: {value: '', selected: false, label: 'Email' },
      primaryphone: {value: '', selected: false, label: 'Primary Phone' },
      secondaryphone: {value: '', selected: false, label: 'Secondary Phone' },
      address: {value: '', selected: false, label: 'Address' },
      city: {value: '', selected: false, label: 'City' },
      state: {value: '', selected: false, label: 'State' },
      zipcode: {value: '', selected: false, label: 'Zip Code' },
      billingtype: {value: '', selected: false, label: 'BillingType' },
      taxexempt: {value: false, selected: false, label: 'Tax Exempt' },
      taxnumber: {value: '', selected: false, label: 'Tax Number' },
      requirespo: {value: false, selected: false, label: 'Requires PO' },
      notes: {value: '', selected: false, label: 'Notes' },
    };

    
    for (const property in new_customer) {
      if(!(property in customerData) || new_customer[property] === null) continue;
      customerData[property].value = new_customer[property];
    }

    const customersTemp = [...customers];
    customersTemp[num] = customerData;
    setCustomers(customersTemp);
  }

  function merge() {
    const newCustomer = {};

    setError('');

    if (!(customers[0] && customers[1])) {
      setError('You must select two customers to merge.');
      return;
    }

    customers.forEach((customer) => {
      for (const property in customer) {
        if (customer[property].selected) {

          if (property === 'organization' && customer.isorganization.selected === false) {
            continue;
          } else if (property === 'taxnumber' && customer.taxexempt.selected === false) {
            continue;
          }

          newCustomer[property] = customer[property].value;
        }
      }
    });

    if(newCustomer.isorganization && !newCustomer.organization || newCustomer.taxexempt && !newCustomer.taxnumber) {
      setError('If you have checked Organization or Tax Exempt make sure that the associated fields are not empty');
      return;
    }
    
    if(!(newCustomer.firstname && newCustomer.lastname && newCustomer.email && newCustomer.primaryphone)) {
      setError('Make sure the customer has at least a first and last name, an email, and a phone number');
      return;
    }

    if (!newCustomer.billingtype) {
      setError('Please set Billing Type.');
      return;
    }

    axios.post('/customer/merge', {
      new_customer: newCustomer,
      old_customers: [ customers[0].customer_id.value, customers[1].customer_id.value ]
    });

    props.closeModal();
  }

  function onSelect(num, name, value) {
    value = Boolean(value);
    const customers = customers;
    customers[num][name].selected = value;
    if(customers[(num)?0:1] && customers[(num)? 0 : 1][name].selected && value){
      customers[(num)? 0 : 1][name].selected = false;
    }
    setCustomers(customers);
  }

  function onChange(num, name, value) {
    const customers = customers;
    customers[num][name].value = value;
    setCustomers(customers);
  }

  return (
    <React.Fragment>
      <h2 style={{ marginTop: '1px'}}>Merge Customers</h2>
      <div style={{ display: 'flex', alignItems: 'center', height: '80%', overflowY: 'auto'}}>
        <div
          style={{ 
            width: '48%',
            display: 'inline-flex',
            justifyContent: 'center',
            height: '100%'
          }}
        >
          { !customers[0] ?
            <>
              <div
                ref={leftSelect}
                style={{ height: 50, width: '55%', zIndex: 100 }}
              ></div>
              <CustomerList 
                anchorEl={leftSelect}
                open={true}
                onClose={() => {}}
                onSelect={(customer) => { setCustomer(0, customer); }}
              /> 
            </>
            :
            <SelectCustomerInfo 
              customer={customers[0]}
              onSelect={(e) => {onSelect(0, e.name, e.value);}}
              onChange={(e) => {
                onChange(0, 
                  e.target.name, 
                  (e.target.type === 'checkbox') ? e.target.checked : e.target.value
                );
              }}
              setCustomer={(customer) => { setCustomer(0, customer); }}
            /> 
          }
        </div>
        <div style={{
          width: 1, 
          display: 'inline-flex', 
          height: '90%', 
          borderLeft:'1px solid black'
        }}>
        </div>
        <div
          style={{ 
            width: '48%',
            display: 'inline-flex',
            justifyContent: 'center',
            height: '100%'
          }}
        >
          { !customers[1] ? 
            <>
              <div
                ref={rightSelect}
                style={{ height: 50, width: '55%' }}
              ></div>
              <CustomerList 
                anchorEl={rightSelect}
                open={true}
                onClose={() => {}}
                onSelect={ (customer) => { setCustomer(1, customer); }}
              /> 
            </>
            :
            <SelectCustomerInfo 
              customer={customers[1]}
              onSelect={(e) => {onSelect(0, e.name, e.value);}}
              onChange={(e) => {
                onChange(0, 
                  e.target.name, 
                  (e.target.type === 'checkbox') ? e.target.checked : e.target.value
                );
              }}
              setCustomer={(customer) => { setCustomer(1, customer); }}
            /> 
          }
        </div>
      </div>
      <p style={{ color: 'red', margin: '10px', textAlign: 'center' }}>{error}</p>
      <Button
        css={{display: 'block', margin: '10px auto'}}
        onClick={() => {merge();}}
      >
        Merge Customers
      </Button>
    </React.Fragment>
  );
}

function SelectCustomerInfo(props) {
  const customer = props.customer;

  return ( 
    <div
      style={{
        display: 'inline-flex',
        flexFlow: 'column',
        height: '100%',
        width: '100%',
        paddingTop: '10px'
      }}
    >
      <Button 
        onClick={() => {
          props.setCustomer(null);
        }}
        variant="contained"
        sx={{ mx: 4 }}
      >
        Select Other Customer
      </Button>
      { Object.keys(customer || {}).map((key) => {
        if(key === 'customer_id') {
          return false;
        } 

        if(typeof customer[key].value === 'boolean') {
          return <RadioCheckbox
            key={key}
            name={key}
            object={customer[key]}
            label={customer[key].label}
            onChange={props.onChange}
            onSelect={props.onSelect}
          />;
        } else if(key === 'notes') {
          return <RadioTextArea
            key={key}
            name={key}
            object={customer[key]}
            label={customer[key].label}
            onChange={props.onChange}
            onSelect={props.onSelect}
          />;

        } else if(key === 'billingtype') {
          return <RadioSelect
            key={key}
            name={key}
            object={customer[key]}
            label={customer[key].label}
            onChange={props.onChange}
            onSelect={props.onSelect}
            options={billingTypes}
          />;
        } else if(key === 'state') {
          return <RadioSelect
            key={key}
            name={key}
            object={customer[key]}
            label={customer[key].label}
            onChange={props.onChange}
            onSelect={props.onSelect}
            options={stateOptions}
          />;
        } else {
          return <RadioInput
            key={key}
            name={key}
            object={customer[key]}
            label={customer[key].label}
            onChange={props.onChange}
            onSelect={props.onSelect}
          />;
        }
      })}
    </div>
  );
}

//⣿⣿⣿⣿⣇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠉⠛⠻⣿⣿⣿⣿⣿⣿
//⣿⣿⣿⣿⣿⣦⠀⠀⠀⠀⠀⠀⠀⠀⢀⣤⣄⡀⠀⢻⣿⣿⣿⣿⣿
//⣿⣿⣿⣿⣿⣿⣇⠀⠀⠀⠀⠀⠀⠀⠸⣿⣿⣿⠃⢰⣿⣿⣿⣿⣿
//⣿⣿⣿⣿⣿⣿⣿⣆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣼⣿⣿⣿⣿⣿
//⣿⣿⣿⣿⣿⣿⣿⣿⡆⠀⠀⠀⠀⠀⠀⢶⣶⣶⣾⣿⣿⣿⣿⣿⣿
//⣿⣿⣿⣿⣿⣿⣿⣿⣧⠀⢠⡀⠐⠀⠀⠀⠻⢿⣿⣿⣿⣿⣿⣿⣿
//⣿⣿⣿⣿⣿⣿⣿⣿⣿⡄⢸⣷⡄⠀⠣⣄⡀⠀⠉⠛⢿⣿⣿⣿⣿
//⣿⣿⣿⣿⣿⣿⣿⣿⣿⣇⠀⣿⣿⣦⠀⠹⣿⣷⣶⣦⣼⣿⣿⣿⣿
//⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣼⣿⣿⣿⣷⣄⣸⣿⣿⣿⣿⣿⣿⣿⣿

//Got em

const RadioInput = (props) => {
  return (
    <Row
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        margin: '10px'
      }}
    >
      <SimpleRadio value={props.object.selected} name={props.name}  onChange={props.onSelect}/>
      <TextInput 
        css={{
          flex: '1 1 auto',
          width: 'auto',
          margin: 0,
          marginRight: 7
        }}
        name={props.name}
        onChange={props.onChange}
        value={props.object.value}
        label={props.label}
      />
    </Row>
  );
};

const RadioSelect = (props) => {
  return (
    <Row
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'start',
        margin: '10px',
        marginLeft: '19%'
      }}
    >
      <SimpleRadio value={props.object.selected} name={props.name}  onChange={props.onSelect}/>
      <Select
        css={{
          flex: '1 1 auto',
          width: '30%',
          margin: 0,
          marginRight: 7
        }}
        inputCSS={{
          width: '100%'
        }}
        options={props.options}
        label={props.object.label}
        value={props.object.value}
        name={props.name}
        onChange={props.onChange}
      />
    </Row>
  );
};

const RadioTextArea = (props) => {
  return (
    <Row
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        margin: '10px'
      }}
    >
      <SimpleRadio value={props.object.selected} name={props.name}  onChange={props.onSelect}/>
      <TextInput 
        css={{
          flex: '1 1 auto',
          width: 'auto',
          margin: 0,
          marginRight: 7
        }}
        inputCSS={{
          width: '100%',
          height: '20vh',
          resize: 'vertical'
        }}
        name={props.name}
        onChange={props.onChange}
        value={props.object.value}
        label={props.label}
        type="textarea"
      />
    </Row>
  );
};

const RadioCheckbox = (props) => {
  return (
    <Row
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'start',
        margin: '10px',
        marginLeft: '19%'
      }}
    >
      <SimpleRadio value={props.object.selected} name={props.name}  onChange={props.onSelect}/>
      <Checkbox 
        css={{
          flex: '1 1 auto',
          width: 'auto',
          margin: 0,
          marginRight: 7
        }}
        name={props.name}
        onChange={props.onChange}
        value={props.object.value}
        label={props.label}
      />
    </Row>
  );
};
