import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { axios } from '../../api';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { 
  Box, 
  Chip, 
  FormControl, 
  InputLabel, 
  MenuItem, 
  OutlinedInput, 
  Select, 
  SelectChangeEvent, 
  Stack 
} from '@mui/material';

export default function Metrics() {

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [printingTasks, setPrintingTasks] = useState<any[]>([]);
  const [selectedPrintingTasks, setSelectedPrintingTasks] = useState<string[]>([]);

  useEffect(() => { getPrintingTasks(); }, []);

  function getPrintingTasks() {
    axios.get('/settings', {
      params: {
        type: 'calendar',
        sub_type: 'printing',
        sub_sub_type: 'tasks'
      }
    })
      .then((res) => {
        setPrintingTasks(res.data.result.data);
      });
  }

  function getProductionMetrics() {
    axios.get('/metrics/production', {
      params: {
        startDate,
        endDate,
        tasks_to_track: selectedPrintingTasks
      }
    })
      .then((res) => {
        const blobUrl = window.URL.createObjectURL(
          new Blob([res.data], {
            type:
              'text/csv',
          })
        );
        const a = document.createElement('a');
        a.download = 'production-metrics.csv';
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  return (
    <Box
      justifyContent="center"
      flexDirection="column"
      display="flex"
    >
      <Button
        onClick={getProductionMetrics}
        variant="contained"
        sx={{ m: 4}}
      >Get Production Metrics</Button>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Stack
          alignItems="center"
        >
        Start Date
          <div>
            <DayPicker
              selectedDays={startDate}
              onDayClick={date => { setStartDate(date); }}
            />
          </div>
        </Stack>
        <Stack
          alignItems="center"
        >
        End Date
          <div>
            <DayPicker
              selectedDays={endDate}
              onDayClick={date => { setEndDate(date); }}
            />
          </div>
        </Stack>
      </Box>
      <FormControl>
        <InputLabel id="selected-printing-tasks-label">Printing Tasks To Track</InputLabel>
        <Select
          fullWidth
          labelId="selected-printing-tasks-label"
          id="selected-printing-tasks"
          multiple
          value={selectedPrintingTasks}
          onChange={(event: SelectChangeEvent<string[]>) => {
            console.log(event.target.value);
            //@ts-ignore
            setSelectedPrintingTasks(event.target.value);
          }}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {printingTasks.filter(task => task.timing === true).map((task: any) => (
            <MenuItem
              key={task.name}
              value={task.name}
            >
              {task.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      Note that the number of screens will be the maximum reported for any of the selected tasks.
    </Box>
  );
}
