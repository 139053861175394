import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import logo from '@/common/img/BadgerLogoandAddress.png';
import { axios } from '@/api.js';

export default function WorkorderLabel(props) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [designsLoaded, setDesignsLoaded] = useState(false);
  const [document, setDocument] = useState({});
  const [designs, setDesigns] = useState([]);

  useEffect(() => {
    axios.get('/document', {
      params: {
        id: props.match.params.document_id,
        attributes: [
          'document_id',
          'lines',
          'jobname',
          'customer'
        ]
      }
    })
      .then((res) => {
        setDocument(res.data.document);
        setDocumentLoaded(true);
      });

    axios.get('/lines', {
      params: {
        document_id: props.match.params.document_id
      }
    })
      .then((res) => {
        setDesigns(res.data.results);
        setDesignsLoaded(true);
      });
  }, []);

  function calculateJobNameFontSize(jobName) {
    if(jobName.length < 10) {
      return '.7in';
    } else if(jobName.length < 27) {
      return '.5in';
    }

    return '.3in';
  }

  function calculateDesignBoxes() {
    const designBoxes = [];
    if(designs?.length > 0) {
      for(let i = 0; i < 10; i++) {
        designBoxes.push(<Square><Text>{(designs[i]?.designNumber ? designs[i].designNumber : '')}</Text></Square>);
      }
    } else if(document.lines) {
      let designs = document.lines.filter(line => line.printoption ? line.printoption.startsWith('D') : false);
      designs = designs.map(line => {
        return line.printoption.substring(1,3);
      });

      designs = Array.from(new Set(designs));

      for(let i = 0; i < 10; i++) {
        designBoxes.push(<Square><Text>{(designs[i]) ? designs[i] : ''}</Text></Square>);
      }
    }

    return designBoxes;
  }

  function ifLoadedOpenPrint() {
    if(imageLoaded && designsLoaded && documentLoaded) {
      
      window.print();
    }
  }


  if(!Object.keys(document).length || !document.jobname) return <div/>;

  ifLoadedOpenPrint();
  return (
    <LabelWrapper>
      <Row>
        <BadgerLogo
          onLoad={() => { setImageLoaded(true); }}
          src={logo}
        />
        <Bold
          style={{
            width: '100%',
            textAlign: 'center'
          }}
        >ID: {document.document_id}</Bold>
      </Row>
      <Row
        style={{
          height: '1in',
          margin: '24px'
        }}
      >
        <JobName
          style={{
            fontSize: calculateJobNameFontSize(document.jobname),
            lineHeight: calculateJobNameFontSize(document.jobname)
          }}
        >{document.jobname.toUpperCase()}</JobName>
      </Row>
      <Row>
        <Bold>DESIGN:</Bold>
      </Row>
      <Row
        style={{
          flexWrap: 'wrap'
        }}
      >
        {calculateDesignBoxes().map((box, i) => {
          return(
            <React.Fragment key={i}>
              {box}
            </React.Fragment>
          );
        })}
      </Row>
      <Row
        style={{
          marginTop: 'auto'
        }}
      >
        <Bold
          style={{
            width: '100%',
            fontSize: '44.16pt',
            textAlign: 'center'
          }}
        >BOX __ / __</Bold>
      </Row>
    </LabelWrapper>
  );
}

const LabelWrapper = styled('div')({
  width: '4in',
  height: '6in',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column'
});

const BadgerLogo = styled('img')({
  width: '2.06in'
});

const Row = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Text = styled('p')({
  fontFamily: 'Helvetica',
  fontWeight: 'normal'
});

const Bold = styled('strong')({
  fontWeight: 'bold',
  fontFamily: 'Helvetica',
});

const JobName = styled(Bold)({
});

const Square = styled('div')({
  display: 'inline-block',
  width: '.571in',
  height: '.571in',
  border: '2px solid black',
  margin: '2%',
  fontSize: '.3in',
  '& p': {
    margin: '0',
    textAlign: 'center',
    marginTop: '10px'
  }
});
