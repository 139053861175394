import React from 'react';
import styled from '@emotion/styled';
import DatePicker from '@components/DatePicker';
import ProductionInfo from '@components/ProductionInfo';
import format from 'date-fns/format';
import WorkCenterPicker from './WorkCenterPicker';
import theme from '@/theme';
import { serverSentEventSub } from '@/api.js';
import Modal from '@mui/material/Modal';
import DesignsDueDate from '../../components/Reusable/lines/DesignsDueDate';

export default class TitleBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      due: props.due,
      datePickerOpen: false,
      hasshipping: props.hasshipping,
      prodInfoOpen: false
    };
  }

  componentDidMount() {
    serverSentEventSub.addEventListener('updateSidebar', this.handleSidebarEvent);
  }

  componentWillUnmount() {
    serverSentEventSub.removeEventListener('updateSidebar', this.handleSidebarEvent);
  }

  componentDidUpdate = prevProps => {
    if (prevProps.name !== this.props.name) {
      this.setState({ name: this.props.name });
    }
    if (prevProps.due !== this.props.due) {
      this.setState({ due: this.props.due });
    }
    if (prevProps.hasshipping !== this.props.hasshipping) {
      this.setState({ hasshipping: this.props.hasshipping });
    }
  };

  handleSidebarEvent = (event) => {
    const eventData = JSON.parse(event.data);
    if(eventData.hasshipping) {
      this.setState({ hasshipping: eventData.hasshipping });
    }
  };

  setName = e => {
    this.setState({ name: e.target.value });
  };

  pushJobName = () => {
    if(this.props.name !== this.state.name){
      this.props.setJobName(this.state.name);
    }
  };

  checkSubmit = e => {
    if(e.charCode === 13){
      this.titleInput.blur();
      // this.props.setJobName(e.target.value)
    }
  };


  selectDueDate = date => {
    this.props.setDueDate(date);
  };

  openProductionInfo = () => {
    const dm = this.prodDate.getBoundingClientRect();
    this.setState({
      prodInfoOpen: true,
      prodPickerStyle: {
        left: dm.left - 100,
        top: dm.bottom
      }
    });
  };

  openDatePicker = () => {
    const dm = this.dueDate.getBoundingClientRect();
    const page = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    const left = ((dm.left - page.width) < (288 - 30)) ? (page.width - 288 - 30) : dm.left;
    this.setState({
      datePickerOpen: true,
      datePickerStyle: {
        left,
        top: dm.bottom
      }
    });
  };

  closeDatePicker = () => {
    this.setState({datePickerOpen: false});
  };

  selectWorkcenter = wcid => {
    this.props.setWorkcenter(wcid);
  };

  render() {
    const { document_id, total = 0, totalPaid = 0, activeWorkcenter, isarchived } = this.props;
    const { name = '', due, hasshipping } = this.state;
    const formattedDate = due ? format(due, 'M/D/YY') : '';
    const remaining = parseFloat((Math.round(totalPaid * 100) / 100) - (Math.round(total * 100) / 100)).toFixed(2);
    return (
      <Wrapper>
        <WorkCenterPicker activeWorkcenter={activeWorkcenter} onChange={this.selectWorkcenter} />
        <Slot style={{ marginRight: 15 }}>
          <Label>ID</Label>
          <Value
            style={{
              color: theme.neutral.color
            }}
          >
            {document_id}
          </Value>
        </Slot>
        <Slot style={{ flexGrow: 1 }}>
          <Label>Job Name {isarchived === 1 && <span style={{color: 'red'}}>- Archived</span>}</Label>
          <Input
            ref={ref=>{this.titleInput = ref;}}
            type="text"
            value={name || ''}
            onChange={this.setName}
            onBlur={this.pushJobName}
            onKeyPress={this.checkSubmit}
          />
        </Slot>
        { this.props.version <= 2 ? <Slot>
          <Label>Production</Label>
          <DueDate
            onClick={this.openProductionInfo}
            ref={ref=>{this.prodDate=ref;}}
          >
            {this.props.production_date}
          </DueDate>
          <Modal
            open={this.state.prodInfoOpen}
            onClose={() => { this.setState({prodInfoOpen: false}); } }
          >
            <div>
              <ProductionInfo
                onProductionDateChange={this.props.onProductionDateChange}
                onModalClose={() => { this.setState({prodInfoOpen: false}); } }
                style={this.state.prodPickerStyle}
                document_id={document_id}
              />
            </div>
          </Modal>
        </Slot> : <Slot>
          <Label>Production</Label>
          <DesignsDueDate document_id={document_id} />
        </Slot>}
        <Slot style={{ margin: '0px 15px 0px 10px' }}>
          <Label>In Hands</Label>
          <DueDate
            onClick={this.openDatePicker}
            ref={ref=>{this.dueDate=ref;}}
          >
            {formattedDate}
          </DueDate>
          <DatePicker
            isOpen={this.state.datePickerOpen}
            onModalClose={this.closeDatePicker}
            onDayClick={this.selectDueDate}
            style={this.state.datePickerStyle}
          />
        </Slot>
        <Slot style={{ margin: '0px 15px 0px 10px' }}>
          <Label>Balance</Label>
          <Value
            style={{
              color: remaining == 0 ? theme.success.color : theme.danger.color
            }}
          >
          ${remaining}
          </Value>
        </Slot>
        <Slot>
          <Label>Total</Label>
          <Value
            style={{
              color: theme.success.color
            }}
          >
            ${parseFloat(Math.round(total * 100) / 100).toFixed(2)}
          </Value>
        </Slot>
        {
          hasshipping ?
            <ShippingStripes/>
            : null
        }
      </Wrapper>
    );
  }
}

const Wrapper = styled('div')({
  position: 'absolute',
  width: '100%',
  minHeight: 50,
  left: 0,
  top: 0,
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  display: 'flex',
  flexDirection: 'row',
  padding: '0px 10px',
  paddingLeft: 0
});

const Label = styled('label')({
  position: 'absolute',
  left: 5,
  top: 5,
  fontSize: 13,
  fontStyle: 'italic',
  fontWeight: 600,
  pointerEvents: 'none'
}, ({ theme }) => ({
  color: theme.neutral.light
}));

const Value = styled('span')({
  flex: '1 0 auto',
  fontSize: 24,
  minHeight: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 5,
  paddingRight: 5,
  paddingTop: 18
});

const Input = styled('input')({
  width: '100%',
  height: '100%',
  maxHeight: 50,
  flex: 1,
  fontSize: 24,
  paddingTop: 18,
  paddingLeft: 5,
  boxSizing: 'border-box',
  border: 'none',

  '&:focus': {
    outline: 'none',
    background: 'rgb(243, 243, 243)'
  }
}, ({ theme }) => ({
  fontFamily: theme.primary.fontFamily
}));

const DueDate = styled('button')({
  width: '100%',
  height: '100%',
  maxHeight: 50,
  flex: 1,
  fontSize: 24,
  paddingTop: 18,
  paddingLeft: 5,
  boxSizing: 'border-box',
  border: 'none',
  cursor: 'text',
  background: 'none',
  '&:focus': {
    outline: 'none',
    background: 'rgb(243, 243, 243)'
  }
}, ({ theme }) => ({
  fontFamily: theme.primary.fontFamily
}));


const Slot = styled('div')({
  minWidth: 80,
  flex: '0 1 auto',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative'
});

const ShippingStripes = styled('div')({
  position: 'absolute',
  width: '100%',
  height: 7,
  bottom: -7,
  left: 0,
  background: 'repeating-linear-gradient(135deg,#4b99c9 0,#4b99c9 30px,transparent 30px,transparent 50px,#ef615b 50px,#ef615b 80px,transparent 80px,transparent 100px)'
});
