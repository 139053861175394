
import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import shortid from 'shortid';
import MenuIcon from 'react-icons/lib/md/more-vert';
import StyleSelector from '../../../../pages/Workorder/StyleSelector';

export default class Cell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? props.value : '',
      dragDistance: { x: 0, y: 0 },
      skipCommit: false
    };
  }

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  };

  checkEnter = e => {
    if (e.charCode === 13) {
      const direction = e.getModifierState('Shift') ? 'up' : 'down';
      if (direction === 'up') {
        if (this.props.rowIndex !== 0) {
          this.props.moveFocus(
            this.props.rowIndex,
            this.props.cellIndex,
            direction
          );
        } else {
          this.input.blur();
        }
      } else {
        if (!this.props.isLast) {
          this.props.moveFocus(this.props.rowIndex, this.props.cellIndex);
        } else {
          this.input.blur();
        }
      }
    }
  }

  checkForSelection = e => {
    if (e.shiftKey) {
      e.nativeEvent.stopImmediatePropagation();
      this.props.extendSelection({
        rowIndex: this.props.rowIndex,
        cellIndex: this.props.cellIndex
      });
      e.preventDefault();
    }
  };

  // Checks that the cell has actually been changed and then commits the change
  commitChange = (value, updateAnyway = false) => {
    if (!this.state.skipCommit) {
      let computedValue = value !== undefined ? value :  this.state.value;
      computedValue = this.props.columnTemplate.editDisplayValue ? 
        this.props.columnTemplate.editDisplayValue(this.state.value) : 
        computedValue;
      const previousValue = this.props.value || this.props.value === false ? this.props.value : '';
      const valueHasChanged = previousValue !== computedValue;

      if (!this.props.blurringSelectedCell) {
        this.props.deselectCell();
      }

      if (valueHasChanged || updateAnyway) {
        this.props.commitChange({
          rowIndex: this.props.rowIndex,
          cellIndex: this.props.cellIndex,
          value: computedValue,
          columnKey: this.props.columnTemplate.key
        });
      }

    } else {
      this.props.deselectCell();
    }

  };

  extendSelection = () => {
    this.props.extendSelection({
      rowIndex: this.props.rowIndex,
      cellIndex: this.props.cellIndex
    });
  };

  focus = () => {
    this.props.selectCell({
      rowIndex: this.props.rowIndex,
      cellIndex: this.props.cellIndex
    });
    this.input.focus();
  };

  getByType = () => {
    const value = this.state.value;
    switch (this.props.columnTemplate.type) {
    case 'stylenumber':
      return (
        <StyleSelector
          defaultInputValue={value}
          onChange={value => {this.commitChange(value);}}
        >
          {
            ({getInputProps}) => (
              <Input
                disabled={!this.isEditable()}
                title={`${value}`}
                {...getInputProps({
                  value: value ?? '',
                  onPaste: this.handlePaste,
                  onBlur: e => {
                    e.preventDefault();
                    this.commitChange(e.target.value, true);
                  },
                  onChange: (e) => {
                    this.setState({ value: e.target.value });
                  },
                  onFocus: this.select,
                  onMouseDown: this.checkForSelection,
                  onKeyPress: this.checkEnter,
                  onDragStart: e => {
                    e.preventDefault();
                    document.activeElement.blur();
                  },
                  draggable: false,
                  ref: input => {
                    this.input = input;
                  }
                })}
              />
            )
          }
        </StyleSelector>
      );
    case 'checkbox':
      return (
        <GridCheckbox
          value={value ?? false}
          disabled={!this.isEditable()}
          onChange={p => {
            this.setValue({target:{value:p}});
            this.commitChange(p);
          }}
          ref={input => {
            this.input = input;
          }}
        />
      );
    case 'number':
      return (
        <Input
          title={`${value || ''}`}
          value={value || undefined}
          onPaste={this.handlePaste}
          onChange={this.setValue}
          onBlur={()=>{this.commitChange();}}
          onFocus={this.select}
          onMouseDown={this.checkForSelection}
          onKeyPress={this.checkEnter}
          disabled={!this.isEditable()}
          onDragStart={e => {
            e.preventDefault();
            document.activeElement.blur();
          }}
          draggable={false}
          ref={input => {
            this.input = input;
          }}
        />
      );
    default:
      return (
        <Input
          title={`${value || ''}`}
          value={value || ''}
          onPaste={this.handlePaste}
          onChange={this.setValue}
          onBlur={()=>{this.commitChange();}}
          onFocus={this.select}
          onMouseDown={this.checkForSelection}
          onKeyPress={this.checkEnter}
          readOnly={!this.isEditable()}
          onDragStart={e => {
            e.preventDefault();
            document.activeElement.blur();
          }}
          draggable={false}
          ref={input => {
            this.input = input;
          }}
        />
      );
    }
  }

  getMaskedValue = (value) => {
    let returnValue;
    switch (this.props.columnTemplate.type) {
    case 'number':
      returnValue = value.replace(/[^0-9/.]*/g, '');
      return returnValue ? Number(returnValue) : undefined;
    default:
      return value;
    }
  };

  handlePaste = e => {
    const value = e.clipboardData.getData('text/plain');
    // This means that the value is from our grid, otherwise it will paste normally
    if (value.includes('\t') || value.includes('\n')) {
      this.setState(
        {
          skipCommit: true
        },
        () => {
          this.input.blur();
          this.setState({
            skipCommit: false
          });
        }
      );
      this.props.pasteCells({
        cellIndex: this.props.cellIndex,
        rowIndex: this.props.rowIndex,
        value
      });
      e.preventDefault();
    }
  };

  isEditable = () => {
    if(this.props.columnTemplate.editableFunction) {
      return this.props.columnTemplate.editableFunction(this.props.getRow());
    }

    if(this.props.columnTemplate.editable !== undefined) {
      return this.props.columnTemplate.editable;
    }

    return true;
  };

  openRowMenu = () => {
    const { x, y } = this.menuButton.getBoundingClientRect();

    if(y + 250 > window.innerHeight) {
      this.props.openRowMenu({
        menuPosition: { x, y: window.innerHeight - 250 },
        activeMenuIndex: this.props.rowIndex
      });
    } else {
      this.props.openRowMenu({
        menuPosition: { x, y },
        activeMenuIndex: this.props.rowIndex
      });
    }
  };

  select = () => {
    this.props.selectCell({
      rowIndex: this.props.rowIndex,
      cellIndex: this.props.cellIndex
    });
    this.input.select();
  };

  setValue = e => {
    const value = this.getMaskedValue(e.target.value);
    if(this.state.skipCommit) return;
    this.setState({ value });
  };

  startDrag = e => {
    e.preventDefault();
    document.activeElement.blur();
    this.props.selectCell({
      rowIndex: this.props.rowIndex,
      cellIndex: this.props.cellIndex
    });
    this.props.toggleDragging(true);
    e.dataTransfer.setDragImage(
      document.getElementById('__hidden-drag-image'),
      0,
      0
    );
  };

  render() {
    const { isFirst, selected, dragging } = this.props;
    return (
      <CellWrapper
        selected={selected}
        draggable
        onDragStart={this.startDrag}
        onMouseEnter={dragging ? this.extendSelection : undefined}
        style={{
          maxWidth: this.props.columnTemplate.maxWidth,
          minWidth: this.props.columnTemplate.minWidth
        }}
      >
        {isFirst && !this.props.readOnly && (
          <RowMenuButton
            className="row-menu-button"
            onClick={() => {
              this.openRowMenu();
            }}
            ref={r => {
              this.menuButton = r;
            }}
          >
            <MenuIcon />
          </RowMenuButton>
        )}
        {typeof this.state.value === 'object' && this.state.value != undefined ? 
          <InputWrapper>
            {this.state.value}
          </InputWrapper> : 
          <InputWrapper
          >
            {this.getByType()}
          </InputWrapper>
        }
      </CellWrapper>
    );
  }
}

class GridCheckbox extends React.Component {
  id = shortid.generate();
  render() {
    const { value, onChange } = this.props;
    return (
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        })}
      >
        <input
          disabled={this.props.disabled}
          type="checkbox"
          id={this.id}
          name={this.id}
          checked={value}
          value={value}
          onChange={e => onChange(e.target.checked)}
          className={css({
            opacity: .01,
            width: .1,
            height: .1,
            position: 'fixed',
            left: -1,
            top: -1,
            visible: 'hidden'
          })}
        />
        <StyledBox
          checked={value}
          htmlFor={this.id}
        />
      </div>
    );
  }
}

const StyledBox = styled('label')({
  position: 'relative',
  border: '2px solid rgb(189, 189, 189)',
  borderRadius: 3,
  width: 17,
  height: 17
}, ({theme, checked}) => (
  checked ? {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 2,
      top: 2,
      width: 9,
      height: 9,
      borderRadius: 1,
      background: theme.vibrant.color
    }
  } : null
));

const Input = styled('input')(({ theme }) => ({
  flex: '1 0 auto',
  border: 'none',
  width: '100%',
  height: '100%',
  padding: 5,
  cursor: 'cell',
  fontFamily: theme.primary.fontFamily,
  fontSize: 12,
  background: 'none',
  '&:focus': {
    outline: `2px solid ${theme.vibrant.color}`,
    background: theme.blue.alpha1,
    cursor: 'text'
  }
}));

const InputWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row'
});

const CellWrapper = styled('td')({
  fontSize: 13,

  // overflow: 'hidden',
  borderRight: '1px solid rgb(201, 201, 201)',

  borderBottom: '1px solid rgb(201, 201, 201)',
  willChange: 'width',
  position: 'relative',
  cursor: 'cell',
  padding: 0,

  '&:first-of-type': {
    borderLeft: '1px solid rgb(201, 201, 201)'
  }
}, ({ theme, selected }) => ({
  background: selected ? theme.vibrant.alpha3 : 'none'
}));

const RowMenuButton = styled('div')({
  position: 'absolute',
  left: -20,
  top: 0,
  background: 'none',
  border: 'none',
  height: '100%',
  width: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  opacity: 0,

  '& svg': {
    width: '100%',
    height: '100%'
  },

  cursor: 'default'
}, ({ theme }) => ({
  color: theme.neutral.color,
  '&:hover': {
    color: theme.neutral.light
  }
}));
