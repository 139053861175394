
import React from 'react';
import styled from '@emotion/styled';
import Cell from './DataGrid.Cell.js';

export default class Rows extends React.Component {
  moveFocus = (rowIndex, cellIndex, direction) => {
    if (direction === 'up') {
      this['row' + (rowIndex - 1)].focusCell(cellIndex);
    } else {
      this['row' + (rowIndex + 1)].focusCell(cellIndex);
    }
  }
  render() {
    const {
      widths,
      rows = [],
      columns,
      selectedRange,
      blurringSelectedCell,
      dragging,
    } = this.props;
    return (
      <RowsWrapper>
        {(rows || []).map((row, i) => {
          return (
            <React.Fragment
              key={row.id || i}
            >
              <Row
                row={row}
                widths={widths}
                index={i}
                isLast={i === rows.length - 1}
                moveFocus={this.moveFocus}
                commitChange={this.props.commitChange}
                openRowMenu={this.props.openRowMenu}
                selectCell={this.props.selectCell}
                deselectCell={this.props.deselectCell}
                extendSelection={this.props.extendSelection}
                blurringSelectedCell={blurringSelectedCell}
                toggleDragging={this.props.toggleDragging}
                pasteCells={this.props.pasteCells}
                dragging={dragging}
                selectedRange={selectedRange}
                ref={row => {
                  this['row' + i] = row;
                }}
                columns={columns}
                readOnly={this.props.readOnly}
                readOnlyRowClick={this.props.readOnlyRowClick}
              />
            </React.Fragment>
          );
        })}
      </RowsWrapper>
    );
  }
}

class Row extends React.Component {
  focusCell = cellIndex => {
    this['cell' + cellIndex].focus();
  };
  getRow = () => this.props.row;
  updateRow = () => {
    this.forceUpdate();
  };
  render() {
    const {
      row,
      index,
      isLast,
      moveFocus,
      commitChange,
      columns,
      selectedRange,
      blurringSelectedCell,
      dragging,
      lineAboveStyle
    } = this.props;
    return (
      <RowWrapper
        className={lineAboveStyle}
        colorOnHover={this.props.readOnly && !!this.props.readOnlyRowClick}
        onClick={ () => {
          this.props.readOnly && !!this.props.readOnlyRowClick ?
            this.props.readOnlyRowClick(row) :
            () => {};
        }}
      >
        {columns.map((t, i) => (
          <Cell
            readOnly={this.props.readOnly}
            columnTemplate={t}
            value={t.valueFunction ? t.valueFunction(row) : row[t.key]}
            isFirst={i === 0}
            getRow={this.getRow}
            isLast={isLast}
            rowIndex={index}
            cellIndex={i}
            moveFocus={moveFocus}
            commitChange={commitChange}
            openRowMenu={this.props.openRowMenu}
            selectCell={this.props.selectCell}
            deselectCell={this.props.deselectCell}
            extendSelection={this.props.extendSelection}
            blurringSelectedCell={blurringSelectedCell}
            toggleDragging={this.props.toggleDragging}
            pasteCells={this.props.pasteCells}
            dragging={dragging}
            titleText={row.desc}
            updateRow={this.updateRow}
            selected={
              selectedRange
                ? index >= selectedRange.origin.rowIndex &&
                  index <= selectedRange.target.rowIndex &&
                  i >= selectedRange.origin.cellIndex &&
                  i <= selectedRange.target.cellIndex
                : false
            }
            ref={cell => {
              this['cell' + i] = cell;
            }}
            key={row.id + i || t.key}
          />
        ))}
      </RowWrapper>
    );
  }
}



const RowsWrapper = styled('tbody')({});

const RowWrapper = styled('tr')(({ colorOnHover }) => ({
  '&:hover .row-menu-button': {
    opacity: 1
  },
  '&:hover': {
    backgroundColor: colorOnHover ? 'rgba(0, 0, 0, .2)' : ''
  }
}));
