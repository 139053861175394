import React from 'react';
import Drawer from '@mui/material/Drawer';
import SidebarRoutes from '../../Containers/auth/SidebarRoutes';

interface MobilSidebarProps {
  drawerOpen: boolean,
  closeDrawer: () => void
}
// Recieved from Collections/FilterBar
export default function MobileSidebar(props:MobilSidebarProps) {
  return(
    <Drawer
      open={props.drawerOpen}
      variant="temporary"
      sx={{
        display: { lg: 'none' },
      }}
      onClose={props.closeDrawer}
    >
      <SidebarRoutes/>
    </Drawer>
  );
}
