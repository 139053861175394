import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'normalize.css';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import theme from './theme.ts';
import store from './store';
import { createBrowserHistory } from 'history';
import { LastLocationProvider } from 'react-router-last-location';

export const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <LastLocationProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </LastLocationProvider>
  </Router>,
  document.getElementById('root')
);
window.location.protocol === 'https:' && unregister();
