import React, { Fragment, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { Tag } from '../../components/Tags';
import { getTagColorById } from '../../theme';
import { OutlinedCircleButton } from '../../components/Controls';
import AddIcon from 'react-icons/lib/md/add';
import FilterableList, {
  SelectorWrapper
} from '../../components/FilterableList';
import { PortalWithState } from 'react-portal';
import { Shade } from '../../components/shell/Shell';
import { axios } from '../../api';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  paddingTop: 8,

  '&:hover .add': {
    opacity: 1
  }
});

const ConnectedTags = props => {
  const [allTags, setAllTags] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getTags();
  }, []);

  function getTags() {
    axios.get('/tags')
      .then((res) => {
        setLoading(false);
        setAllTags(res.data.result);
      });
  }

  return (
    <Tags 
      {...props} 
      allTags={allTags} 
      tagsLoading={loading} 
    />
  );
};
export default ConnectedTags;

export class Tags extends React.Component {
  state = { coordinates: { x: 0, y: 0, maxHeight: 300 } }
  static defaultProps = {
    tags: [],
    onTagSelect: () => {},
    deleteTag: () => {},
  }
  select = tag => {
    this.props.onTagSelect(tag);
  };
  setSelectorCoordinates = () => {
    this.list.focus();
    const button = this.addTagButton.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const x = button.x + 220 > screenWidth ? screenWidth - 220 : button.x - 20;
    this.setState({
      coordinates: {
        x,
        y: button.y - 10,
        maxHeight: `calc(100vh - ${button.y + 10}px)`
      }
    });
  };
  selectFirstTag = (tag, filteredItems, closePortal) => {
    if (filteredItems.length === 1) {
      this.select(tag);
      closePortal();
    }
  };
  render() {
    const { tags, allTags: unfilteredTags, deleteTag, readOnly, size } = this.props;
    const allTags = unfilteredTags.filter(t => t.active !== false);
    return (
      <Wrapper>
        {tags.map(tag => {
          const tagColor = getTagColorById(tag.colorId);
          return (
            <Tag
              color={tagColor.color}
              textColor={tagColor.text}
              key={tag.tag_id}
              {...readOnly ? {deleteable: false} : {deleteable: true}}
              {...size ? {size: size} : {undefined}}
              onRequestDelete={() => {
                deleteTag(tag.tag_id, tag.title);
              }}
            >
              {tag.title}
            </Tag>
          );
        })}
        {!readOnly && <PortalWithState
          onOpen={this.setSelectorCoordinates}
          closeOnEsc
          closeOnOutsideClick
        >
          {({ portal, openPortal, closePortal, isOpen }) => (
            <Fragment>
              <OutlinedCircleButton
                ref={addTagButton => {
                  this.addTagButton = addTagButton;
                }}
                className="add"
                onClick={openPortal}
              >
                <AddIcon /> Add Tag
              </OutlinedCircleButton>
              {portal(
                <SelectorWrapper
                  style={{
                    left: this.state.coordinates.x,
                    top: this.state.coordinates.y,
                    maxHeight: this.state.coordinates.maxHeight,
                    zIndex: 100
                  }}
                >
                  <FilterableList
                    ref={list => {
                      this.list = list;
                    }}
                    items={allTags.filter(
                      tag => !tags.find(t => t.tag_id === tag.tag_id)
                    )}
                    maxHeight={this.state.coordinates.maxHeight}
                    onSubmit={(firstTag, filteredItems) => {
                      this.selectFirstTag(firstTag, filteredItems, closePortal);
                    }}
                    renderItem={({ item: tag, index: i, length: total }) => {
                      const tagColor = getTagColorById(tag.colorId);
                      return (
                        <Tag
                          size="large"
                          isOnly={i === 0 && total === 1}
                          {...tag}
                          color={tagColor.color}
                          textColor={tagColor.text}
                          key={tag.tag_id}
                          onClick={() => {
                            this.select(tag);
                            closePortal();
                          }}
                          clickable
                          className={css({ flex: '1 0 auto' })}
                        >
                          {tag.title}
                        </Tag>
                      );
                    }}
                    filter={(tag, filter) =>
                      tag.title.toLowerCase().includes(filter)
                    }
                  />
                </SelectorWrapper>
              )}
              {isOpen && <Shade className="fade-in" />}
            </Fragment>
          )}
        </PortalWithState>}
      </Wrapper>
    );
  }
}
