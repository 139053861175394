import React from 'react';
import styled from '@emotion/styled';
import ModalStateController from './ModalStateController';

const MenuWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column'
  },
  ({ theme }) => ({
    boxShadow: theme.shadows.d3
  })
);

export default class FloatingMenu extends React.Component {
  render() {
    const {
      buttons = [],
      isOpen,
      onModalClose,
      style = {},
      enterAnimationClassName,
      exitAnimationClassName
    } = this.props;
    return (
      <ModalStateController
        enterAnimationClassName={enterAnimationClassName || 'pop-in-left'}
        exitAnimationClassName={exitAnimationClassName || 'pop-out-left'}
        isOpen={isOpen}
        onModalClose={onModalClose}
      >
        {({ getProps, closeModal }) => (
          <MenuWithButtons
            buttons={buttons}
            wrapperProps={getProps()}
            style={style}
            onRequestModalClose={closeModal}
          />
        )}
      </ModalStateController>
    );
  }
}

export const MenuWithButtons = ({
  wrapperProps = {},
  style,
  buttons,
  onRequestModalClose,
  renderHeader
}) => (
  <MenuWrapper {...wrapperProps} style={style}>
    {renderHeader}
    {buttons.map((btn, i) => {

      if(!btn) return;
      return (
        <MenuButton
          key={i}
          onClick={() => {
            btn.onClick();
            onRequestModalClose();
          }}
        >
          {btn.label}
        </MenuButton>
      );
    })}
  </MenuWrapper>
);

export class Menu extends React.Component {
  render() {
    const { children } = this.props;
    return <MenuWrapper>{children}</MenuWrapper>;
  }
}

export const MenuButton = styled('button')(
  {
    background: '#fff',
    padding: '0px 15px',
    height: 48,
    border: 'none',
    borderBottom: '1px solid rgb(236, 236, 236)',

    '&:last-child': {
      borderBottom: 'none'
    },

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',

    '& svg': {
      width: 20,
      height: 20,
      marginRight: 10
    }
  },
  ({ theme }) => ({
    '&:hover': {
      background: theme.neutral.extraExtraLight
    }
  })
);
