import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Slider from '../../components/Slider';
import { TextInput, Select, Button } from '../../components/Controls';
import { pricingtypeOptions } from '../../data/options';
import Downshift from 'downshift';
import { axios } from '../../api.js';


export default class InventorySlider extends React.Component {

  editInventory = item => {
    const data = {
      ...item,
      timestamp: new Date(),
      updated: new Date()
    };

    axios.put('/inventory', {
      inventory_item_id: data.inventory_item_id,
      pricingtype: data.pricingtype,
      stylenumber: data.stylenumber,
      description: data.description,
      custprice: data.custprice,
      color: data.color
    })
      .then(() => {
        this.props.onModalClose();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  newInventory = item => {
    const data = {
      ...item,
      timestamp: new Date(),
      updated: new Date()
    };

    axios.post('/inventory', {
      color: data.color,
      custprice: data.custprice,
      description: data.description,
      pricingtype: data.pricingtype,
      stylenumber: data.stylenumber
    })
      .then(() => {
        this.props.onModalClose();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const {
      isOpen,
      onModalClose,
      editLabel = 'Edit Inventory',
      newLabel = 'Add Item',
      title = 'Add/Edit Inventory',
    } = this.props;
    return (
      <Slider title={title} isOpen={isOpen} onModalClose={onModalClose}>
        <InventoryForm
          // onSubmit={onSubmit}
          newLabel={newLabel}
          editLabel={editLabel}
          onEdit={this.editInventory}
          onNew={this.newInventory}
        />
      </Slider>
    );
  }
}

export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.slider.padding
}));

export const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '20px 0px',

  '&:first-of-type': {
    marginTop: 0
  },

  '&:last-child': {
    marginBottom: 0
  }
});

const SelectorWrapper = styled('div')({
  // position: 'relative'
});

const initialStyle = {
  stylenumber: '',
  description: '',
  color: '',
  custprice: '',
  inventory_item_id: '',
  pricingtype: ''
};

class InventoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialStyle, search: '', isEdit: false };
  }
  setValue = (key, value) => {
    this.setState({ [key]: value });
  };
  resetState = () => {
    this.setState({...initialStyle, isEdit: false});
  };
  submitNew = () => {
    this.props.onNew(this.state);
  };
  submitEdit = () => {
    this.props.onEdit(this.state);
    
  };
  onSearchSelect = (selection) => {
    this.setState({ ...selection, search: `${selection.stylenumber}|${selection.color}`, isEdit: true });
  };
  render() {
    const canSubmit =
      this.state.stylenumber !== '' &&
      this.state.description !== '' &&
      this.state.color !== '' &&
      this.state.pricingtype !== '';
      
    const { newLabel, editLabel } = this.props;
    return (
      <Wrapper>
        <Row>
          <Downshift
            // defaultInputValue={defaultInputValue}
            onChange={selection => {this.onSearchSelect(selection);}}
            itemToString={i => (i == null ? '' : String(i))}
            //onStateChange={this.resetState}
          >
            {({
              getInputProps,
              getItemProps,
              getRootProps,
              inputValue,
              selectedItem,
              highlightedIndex,
              isOpen,
            }) => (
              <SelectorWrapper {...getRootProps({}, { suppressRefError: true})}
                onChange={e => {
                  this.setValue('search', e.target.value);
                }}
              >
                <TextInput
                  {...getInputProps({
                    // value,
                    onPaste: this.handlePaste,
                    // onChange: this.setValue,
                    onBlur: e =>
                    {
                      e.preventDefault();
                    },
                    onFocus: this.select,
                    onMouseDown: this.checkForSelection,
                    onKeyPress: this.checkEnter,
                    onDragStart: e => {
                      e.preventDefault();
                      document.activeElement.blur();
                    },
                    draggable: false,
                    innerRef: input => {
                      this.input = input;
                    }
                  })}
                  css={{
                    flex: '1 1 auto',
                    width: 'auto',
                    margin: 0,
                  }}
                  inputCSS={{
                    width: '100%'
                  }}
                  label="Search"
                  value={this.state.search}
                  // onChange={e => {
                  //   this.setValue('style', e.target.value)
                  // }}
                />
                {isOpen ? (
                  <AutoCompleteMenu
                    {...{
                      inputValue,
                      selectedItem,
                      highlightedIndex,
                      getItemProps
                    }}
                  />
                ) : null}
              </SelectorWrapper>
            )}
          </Downshift>
        </Row>
        <Row>
        </Row>
        <Row>
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto',
              margin: 0,
            }}
            inputCSS={{
              width: '100%'
            }}
            label="Style"
            value={this.state.stylenumber}
            onChange={e => {
              this.setValue('stylenumber', e.target.value);
            }}
          />
        </Row>
        <Row>
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto',
              margin: 0,
            }}
            inputCSS={{
              width: '100%'
            }}
            label="Color"
            value={this.state.color}
            onChange={e => {
              this.setValue('color', e.target.value);
            }}
          />
        </Row>
        <Row>
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto',
              margin: 0,
              // marginLeft: 7
            }}
            inputCSS={{
              width: '100%'
            }}
            label="Description"
            value={this.state.description}
            onChange={e => {
              this.setValue('description', e.target.value);
            }}
          />
        </Row>
        <Row>
          <TextInput
            css={{
              flex: '1 1 auto',
              width: 'auto',
              margin: 0,
              // marginLeft: 7
            }}
            inputCSS={{
              width: '100%'
            }}
            label="Cost"
            value={this.state.custprice}
            onChange={e => {
              this.setValue('custprice', e.target.value);
            }}
          />
        </Row>
        <Row>
          <Select
            css={{
              flex: '1 1 auto',
              width: '30%',
              margin: 0,
              marginRight: 7
            }}
            inputCSS={{
              width: '100%'
            }}
            options={pricingtypeOptions}
            label="Pricing Type"
            value={this.state.pricingtype}
            onChange={e => {
              this.setValue('pricingtype', e.target.value);
            }}
          >  
          </Select>
        </Row>
        { this.state.isEdit && (
          <Row>
            <Button
              disabled={!canSubmit}
              css={{ flexGrow: 1 }}
              onClick={this.submitEdit}
            >
              {editLabel}
            </Button>
          </Row>
        )}
        { !this.state.isEdit && (
          <Row>
            <Button
              disabled={!canSubmit}
              css={{ flexGrow: 1 }}
              onClick={this.submitNew}
            >
              {newLabel}
            </Button>
          </Row>
        )}
      </Wrapper>
    );
  }
}

const MenuWrapper = styled('div')({
  position: 'relative',
  left: 10,
  top: 0,
  width: 200,
  maxHeight: 100,
  overflowY: 'auto',
  zIndex: 99,
  background: '#fff',
  borderRadius: '0px 0px 3px 3px'
}, ({theme}) => ({
  boxShadow: theme.shadows.d2
}));

const Item = styled('div')({
  padding: 3,
  background: '#fff'
});

const AutoCompleteMenu = props => {
  const {
    // selectedItem,
    highlightedIndex,
    getItemProps
  } = props;

  useEffect(() => {
    axios.get('/inventory', {
      params: { stylenumber: props.inputValue, limit: 50 }
    })
      .then((res) => {
        set_inventory_items(res.data);
      });
  }, [props.inputValue]);

  const [inventory_items, set_inventory_items] = useState([]);

  return (
    <MenuWrapper>
      {(inventory_items).slice(0, 30).map(({ stylenumber, color, inventory_item_id, description, custprice, pricingtype }, index) => (
        <Item
          key={index}
          {...getItemProps({
            // item: `${stylenumber}|${color}|${description}`,
            item: {stylenumber: stylenumber, color: color, description: description, inventory_item_id: inventory_item_id, custprice: custprice, pricingtype: pricingtype},
            index,
            key: inventory_item_id,
            style: {
              backgroundColor: highlightedIndex === index ? 'rgb(176, 236, 255)' : '',
              // fontWeight: selectedItem === stylenumber ? 'bold' : 'normal'
            }
          })}
        >
          {`${stylenumber}|${color}`}
        </Item>
      ))}
    </MenuWrapper>
  );
};
