const orderedTagColors = [
  {colorId: 1, color: 'rgb(237, 88, 81)', text: '#fff'},
  {colorId: 4, color: 'rgb(255, 122, 69)', text: '#fff'},
  {colorId: 6, color: 'rgb(255, 235, 59)', text: 'rgb(98, 89, 12)'},
  {colorId: 2, color: 'rgb(156, 204, 100)', text: '#fff'},
  {colorId: 10, color: 'rgb(91, 238, 220)', text: 'rgb(7, 88, 78)'},
  {colorId: 3, color: 'rgb(51, 122, 236)', text: '#fff'},
  {colorId: 5, color: 'rgb(126, 88, 194)', text: '#fff'},
  {colorId: 12, color: 'rgb(228, 106, 216)', text: '#fff'},
  {colorId: 7, color: 'rgb(120, 144, 156)', text: '#fff'},
  {colorId: 8, color: 'rgb(243, 243, 243)', text: 'rgb(47, 47, 47)'},
  {colorId: 9, color: 'rgb(49, 77, 102)', text: '#fff'},
  {colorId: 11, color: 'rgb(20, 30, 40)', text: '#fff'}
];

const defaultTagId = 9;

export const TAG_COLORS = orderedTagColors.reduce((obj, c) => ({...obj, [c.colorId]: c}), {});

export const getTagColorById = colorId => (TAG_COLORS[colorId] || TAG_COLORS[defaultTagId]);

const theme = {
  palette: {
    primary: {
      main: '#213a4b',
      colorRGB: 'rgb(33, 58, 75)',
      colorByAlpha: a => `rgba(33, 58, 75, ${a})`,
      dark: '#0b202b',
      medium: '#314d66',
      lightMedium: '#3e637f',
      light: '#3e6380',
      extraLight: '#6589a6',
      extraExtraLight: '#8eb1cd',
      textOn: '#fff',
      textOnByAlpha: a => `rgba(255,255,255, ${a})`,
      fontFamily: '"Montserrat", sans-serif'
    },
    vibrant: {
      main: '#29c5ff',
      dark: '#13b7f4',
      light: '#48cbfc',
      alpha1: 'rgba(41, 197, 255, 0.1)',
      alpha2: 'rgba(41, 197, 255, 0.2)',
      alpha3: 'rgba(41, 197, 255, 0.3)',
      alpha4: 'rgba(41, 197, 255, 0.4)',
      alpha5: 'rgba(41, 197, 255, 0.5)',
      textOn: 'rgb(255, 255, 255)'
    },
    neutral: {
      main: '#9a9a9a',
      light: '#b5b5b5',
      extraLight: '#cbcbcb',
      extraExtraLight: '#e9e9e9',
      dark: '#747474',
      alpha1: 'rgba(0,0,0,.1)'
    },
    success: {
      main: '#42b851',
      light: '#5bc969',
      extraLight: '#7ede8b',
      colorByAlpha: a => `rgba(66, 184, 81, ${a})`,
    },
    warning: {
      main: '#E5C061',
      light: '#ECCE83',
      extralight: '#EED595',
      dark: '#E0B23E'
    },
    danger: {
      main: 'rgb(227, 61, 61)',
      dark: 'rgb(181, 37, 37)',
      light: 'rgb(238, 80, 80)',
      textOn: 'rgb(255, 255, 255)',
      colorByAlpha: a => `rgba(227, 61, 61, ${a})`,
    },
    blue: {
      main: '#347bed',
      dark: '#266ee0',
      light: '#5b99ff',
      extraLight: '#8fb9ff',
      alpha1: 'rgba(52, 123, 237, 0.1)',
      alpha2: 'rgba(52, 123, 237, 0.2)'
    },
    green: {
      main: '#9ccc64',
    },
    red: {
      main: '#ED5851',
      light: '#f56962'
    },
    orange: {
      main: '#ffa22a',
      light: '#ffae46'
    },
  },
  primary: {
    color: '#213a4b',
    colorRGB: 'rgb(33, 58, 75)',
    colorByAlpha: a => `rgba(33, 58, 75, ${a})`,
    dark: '#0b202b',
    medium: '#314d66',
    lightMedium: '#3e637f',
    light: '#3e6380',
    extraLight: '#6589a6',
    extraExtraLight: '#8eb1cd',
    textOn: '#fff',
    textOnByAlpha: a => `rgba(255,255,255, ${a})`,
    fontFamily: '"Montserrat", sans-serif'
  },
  vibrant: {
    color: '#29c5ff',
    dark: '#13b7f4',
    light: '#48cbfc',
    alpha1: 'rgba(41, 197, 255, 0.1)',
    alpha2: 'rgba(41, 197, 255, 0.2)',
    alpha3: 'rgba(41, 197, 255, 0.3)',
    alpha4: 'rgba(41, 197, 255, 0.4)',
    alpha5: 'rgba(41, 197, 255, 0.5)',
    textOn: 'rgb(255, 255, 255)'
  },
  neutral: {
    color: '#9a9a9a',
    light: '#b5b5b5',
    extraLight: '#cbcbcb',
    extraExtraLight: '#e9e9e9',
    dark: '#747474',
    alpha1: 'rgba(0,0,0,.1)'
  },
  success: {
    color: '#42b851',
    light: '#5bc969',
    extraLight: '#7ede8b',
    colorByAlpha: a => `rgba(66, 184, 81, ${a})`,
  },
  danger: {
    color: 'rgb(227, 61, 61)',
    dark: 'rgb(181, 37, 37)',
    light: 'rgb(238, 80, 80)',
    textOn: 'rgb(255, 255, 255)',
    colorByAlpha: a => `rgba(227, 61, 61, ${a})`,
  },
  blue: {
    color: '#347bed',
    dark: '#266ee0',
    light: '#5b99ff',
    extraLight: '#8fb9ff',
    alpha1: 'rgba(52, 123, 237, 0.1)',
    alpha2: 'rgba(52, 123, 237, 0.2)'
  },
  green: {
    color: '#9ccc64'
  },
  red: {
    color: '#ED5851',
    light: '#f56962'
  },
  orange: {
    color: '#ffa22a',
    light: '#ffae46'
  },
  white: '#ffffff',
  slider: {
    padding: 15
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536
    },
  },
  grid: {
    grid_breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1281,
      xl: 1600
    },
    max_container_width: {
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1140
    },
    column_gutter: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0
    },
    outer_gutter: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0
    }
  },
  shadows: {
    id5: 'inset 0 19px 38px rgba(0,0,0,0.30), inset 0 15px 12px rgba(0,0,0,0.22)',
    id4: 'inset 0 14px 28px rgba(0,0,0,0.25), inset 0 10px 10px rgba(0,0,0,0.22)',
    id3: 'inset 0 10px 20px rgba(0,0,0,0.19), inset 0 6px 6px rgba(0,0,0,0.23)',
    id2: 'inset 0 3px 6px rgba(0,0,0,0.16), inset 0 3px 6px rgba(0,0,0,0.23)',
    id1: 'inset 0 1px 3px rgba(0,0,0,0.12), inset 0 1px 2px rgba(0,0,0,0.24)',
    d1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    d2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    d3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    d4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    d5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
    8: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
    0: '0'
  },
  orderedTagColors,
  tagColors: TAG_COLORS,
  typography: {
    fontFamily: '"Montserrat", Arial, Roboto',
    // This would be helpful probably heavy: '1.2em "Montserrat", Arial, Roboto'
  }
};

export default theme;
