import qbCustomer from '../quickbooks/customer.ts';

export const defaultCustomer : Customer = {
  customer_id: 0,
  organization: '',
  firstname: '',
  lastname: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  secondaryphone: '',
  primaryphone: '',
  fax: '',
  email: '',
  isorganization: false,
  requirespo: false,
  notes: '',
};

interface Customer {
  customer_id?: number,
  organization: string,
  firstname: string,
  lastname: string,
  address: string,
  city: string,
  state: string,
  zipcode: string,
  secondaryphone: string,
  primaryphone: string,
  fax: string,
  email: string,
  isorganization: boolean,
  taxexempt?: boolean,
  requirespo?: boolean,
  notes: string,
  billingtype?: string,
  taxtype?: string,
  taxnumber?: string,
  createdAt?: Date,
  updatedAt?: Date,
  quickbooksAccounts?: qbCustomer[]
}

export default Customer;
