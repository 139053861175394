export const defaultItem : InventoryItem = {
  inventory_item_id: 0,
  pricingtype: 'Printing',
  stylenumber: '',
  custprice: '0',
  description: '',
  color: '',
  taxexempt: false,
  supplier: '',
  manufacturer: '',
  QuickbooksItemID: '',
  QuickbooksNonCOGS: false
};

export interface InventoryItem {
  inventory_item_id?: number,
  pricingtype: string,
  stylenumber: string,
  custprice: string,
  description: string,
  color: string,
  taxexempt: boolean,
  supplier: string,
  manufacturer: string,
  QuickbooksItemID: string,
  QuickbooksNonCOGS: boolean,
  timestamp?: Date,
  updated?: Date
}

