import React, { useState, useEffect } from 'react';
//@ts-ignore
import InfiniteScroll from 'react-infinite-scroller';
import { axios } from '../../../api.js';
import Customer from '../../../types/models/customer.ts';

import Popover, { PopoverProps } from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CustomerModal from '../../../pages/Customers/CustomerModal.tsx';

interface CustomerListProps {
  anchorEl: PopoverProps['anchorEl'] & { current: PopoverProps['anchorEl'] },
  open: boolean,
  onClose: () => void,
  onSelect: (customer: Customer) => void
}

export default function CustomerList(props: CustomerListProps) {
  console.log(props.anchorEl);

  const [filter, setFilter] = useState<string>('');
  const [customersCount, setCustomersCount] = useState<number>(0);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [creatingCustomer, setCreatingCustomer] = useState<number>(0);

  useEffect(() => {
    getCustomersCount();
  }, []);

  useEffect(() => {
    getCustomersCount();
  }, [filter]);

  function getCustomersCount():void {
    axios.get('/customer/count', {
      params: {
        filter
      }
    })
      .then((res) => {
        setCustomersCount(res.data.result);
      });
  }

  function getCustomers():void {
    axios.get('/customer/all', {
      params: {
        filter,
        limit: 50,
        offset: customers.length
      }
    })
      .then((res) => {
        setCustomers([ ...customers, ...res.data.result ]);
      });
  }

  return (
    <Popover
      open={props.open}
      onClose={props.onClose}
      //@ts-ignore
      anchorEl={props.anchorEl?.current || props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      sx={{ backgroundColor: 'blue' }}
      PaperProps={{
        style: {
          maxHeight: '500px',
          borderColor: 'rgba(0,0,0,.7)',
          borderWidth: '2px',
          width: '300px'
        }
      }}
    >
      <TextField
        size="small"
        variant="filled"
        label="Filter"
        value={filter}
        sx={{
          width: '300px',
          position: 'fixed',
          backgroundColor: 'white',
        }}
        onChange={(e) => {
          setCustomers([]);
          setFilter(e.target.value);
        }}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                onClick={() => { setCreatingCustomer(-1); }}
                edge="end"
              >
                <PersonAddIcon />
              </IconButton>
            </InputAdornment>
        }}
      ></TextField>
      <InfiniteScroll
        loadMore={() => { 
          getCustomers(); 
        }}
        hasMore={(customersCount > customers.length) && props.open}
        loader={ <div className="loader" key={0}> Loading ... </div> }
        useWindow={false}
        pageStart={0}
      > 
        <React.Fragment>
          <Stack
            direction="column"
            pt={6}
          >
            {customers.map((customer) => {
              return (
                <React.Fragment
                  key={customer.customer_id}
                >
                  <Box
                    sx={{ 
                      p: 1,
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,.2)',
                        textShadow: '1px 1px 1px black'
                      }
                    }}
                    onClick={() => { props.onSelect(customer); }}
                  >
                    <Typography
                      sx={{ 
                        fontWeight: 700,
                      }}
                    >{(customer.firstname + ' ' + customer.lastname).toUpperCase()}</Typography>
                    {customer.isorganization && <Typography
                    >{customer.organization}</Typography>}
                  </Box>
                  <Divider 
                    sx={{ borderColor: 'rgba(0,0,0,.6)'}}
                  />
                </React.Fragment>
              );
            })}
          </Stack>
        </React.Fragment>
      </InfiniteScroll>
      <CustomerModal 
        viewingCustomer={creatingCustomer}
        onClose={() => { setCreatingCustomer(0); }}
      />
    </Popover>
  );

}
