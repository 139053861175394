import React, { Fragment, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import AddIcon from 'react-icons/lib/md/add';
import FilterableList from '../../components/FilterableList';
import { PortalWithState } from 'react-portal';
import { Shade } from '../../components/shell/Shell';
import CloseIcon from 'react-icons/lib/md/close';
import { BASE_URL, axios } from '../../api';
import userPlaceholder from '../../common/img/user_placeholder_white.svg';
import ImageFallback from '../../components/ImageFallback';

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  padding: '5px 0px',
  flexWrap: 'wrap',
  '&:hover .add': {
    opacity: 1,
  },
});

const AddFollower = styled('button')(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '100%',
  border: `3px solid ${theme.neutral.extraLight}`,
  margin: 3,
  color: theme.neutral.extraLight,
  padding: 0,
  cursor: 'pointer',
  opacity: 0,
  transition: 'opacity 200ms',
  '& svg': {
    width: '100%',
    height: '100%',
  },
  '&:hover': {
    color: theme.neutral.light,
    borderColor: theme.neutral.light,
  },
}));

export const RowWrapper = styled('div')(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 5,
  paddingRight: 5,
  flex: '0 0 auto',
  cursor: 'pointer',
  background: selected ? theme.blue.extraLight : '',
  '&:hover': {
    background: theme.blue.extraLight,
    '&:nth-of-type(even)': {
      background: theme.blue.extraLight,
    },
  },
  '&:nth-of-type(even)': {
    background: selected ? theme.blue.extraLight : 'rgba(255,255,255,.4)',
  },
}));

const SelectorWrapper = styled('div')({
  position: 'fixed',
  left: '45%',
  top: '45%',
  zIndex: 9,
});

export const Label = styled('label')({
  fontSize: 14,
  marginLeft: 4,
  lineHeight: 0,
  paddingTop: 2,
  whiteSpace: 'nowrap',
});

export const FollowersWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: -6,
  marginBottom: -6,
});

export class Followers extends React.Component {
  state = { x: 0, y: 0, maxHeight: 400 }

  static defaultProps = {
    followers: [],
    onChange: () => {},
    deleteFollower: () => {},
  }
  setSelectorCoordinates = () => {
    this.list.focus();
    const button = this.addButton.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const x = button.x + 220 > screenWidth ? screenWidth - 220 : button.x - 20;
    this.setState({
      x,
      y: button.y - 3,
      maxHeight: `calc(100vh - ${button.y + 10}px)`,
    });
  };
  selectFollower = (fol) => {
    this.props.onSelect(fol.user_account_id);
  };
  render() {
    const { followers = [], users, deleteFollower } = this.props;
    return (
      <Row>
        {followers.map(fol =>
        {
          const { user_account_id, user_profiles } = fol;
          const { first_name = 'User Deleted', last_name = '', profilepicture } = user_profiles ? user_profiles : {};
          return (
            <Follower
              background
              name={`${first_name} ${last_name}`}
              user_id={user_account_id}
              profilepicture={profilepicture}
              tooltip
              deleteable
              onRequestDelete={deleteFollower}
              key={fol.id}
            />);
        })}
        <PortalWithState
          onOpen={this.setSelectorCoordinates}
          closeOnEsc
          closeOnOutsideClick
        >
          {({ portal, isOpen, closePortal, openPortal }) => (
            <Fragment>
              <AddFollower
                onClick={openPortal}
                className="add"
                ref={addButton => {
                  this.addButton = addButton;
                }}
              >
                <AddIcon />
              </AddFollower>
              {portal(
                <SelectorWrapper
                  style={{
                    left: this.state.x,
                    top: this.state.y,
                    maxHeight: this.state.maxHeight,
                    zIndex: 100
                  }}
                >
                  <FilterableList
                    items={users.filter(
                      user =>
                        !followers.find(
                          f => user.user_account_id === f.user_account_id
                        )
                    )}
                    onSubmit={(fol) => {
                      this.selectFollower(fol);
                      closePortal();
                    }}
                    maxHeight={this.state.maxHeight}
                    filter={(fol, filter) =>
                      fol.username.toLowerCase().includes(filter)
                    }
                    ref={list => {
                      this.list = list;
                    }}
                    renderItem={({
                      item: follower,
                      selected,
                      deselect,
                    }) => (
                      <RowWrapper
                        selected={selected}
                        onClick={() => {
                          this.selectFollower(follower);
                          closePortal();
                        }}
                        key={follower.user_account_id}
                        onMouseEnter={deselect}
                      >
                        <Follower
                          profilepicture={follower.user_profile.profilepicture}
                          background
                          user_id={follower.user_account_id}
                        />
                        <Label>{follower.user_profile.first_name + ' ' + follower.user_profile.last_name}</Label>
                      </RowWrapper>
                    )}
                  />
                </SelectorWrapper>
              )}
              {isOpen && <Shade className="fade-in" />}
            </Fragment>
          )}
        </PortalWithState>
      </Row>
    );
  }
}

/*
 * This is the updated version of Followers **USE THIS ONE!!!**
 */
export class DocumentFollowers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
      maxHeight: 400,
      users: []
    };
  }

  componentDidMount() {
    // TODO: Get all of the office users
    axios.get('/users/office')
      .then((res) => {
        this.setState({ users: res.data.results });
      });
  }

  static defaultProps = {
    followers: [],
    onChange: () => {},
    deleteFollower: () => {},
  }
  setSelectorCoordinates = () => {
    this.list.focus();
    const button = this.addButton.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const x = button.x + 220 > screenWidth ? screenWidth - 220 : button.x - 20;
    this.setState({
      x,
      y: button.y - 3,
      maxHeight: `calc(100vh - ${button.y + 10}px)`,
    });
  };
  selectFollower = (fol) => {
    this.props.onSelect(fol);
  };
  render() {
    const { followers = [], deleteFollower } = this.props;
    return (
      <Row>
        {followers.map(fol =>
        {
          const { user_account_id, user_profile } = fol;
          const { first_name = 'User Deleted', last_name = '', profilepicture } = user_profile ? user_profile : {};
          return (
            <Follower
              background
              name={`${first_name} ${last_name}`}
              user_id={user_account_id}
              profilepicture={profilepicture}
              tooltip
              deleteable
              onRequestDelete={deleteFollower}
              key={fol.user_account_id}
            />);
        })}
        <PortalWithState
          onOpen={this.setSelectorCoordinates}
          closeOnEsc
          closeOnOutsideClick
        >
          {({ portal, isOpen, closePortal, openPortal }) => (
            <Fragment>
              <AddFollower
                onClick={openPortal}
                className="add"
                ref={addButton => {
                  this.addButton = addButton;
                }}
              >
                <AddIcon />
              </AddFollower>
              {portal(
                <SelectorWrapper
                  style={{
                    left: this.state.x,
                    top: this.state.y,
                    maxHeight: this.state.maxHeight,
                    zIndex: 100
                  }}
                >
                  <FilterableList
                    items={this.state.users.filter(
                      user =>
                        !followers.find(
                          f => user.user_account_id === f.user_account_id
                        )
                    )}
                    onSubmit={(fol) => {
                      this.selectFollower(fol);
                      closePortal();
                    }}
                    maxHeight={this.state.maxHeight}
                    filter={(fol, filter) =>
                      fol.username.toLowerCase().includes(filter)
                    }
                    ref={list => {
                      this.list = list;
                    }}
                    renderItem={({
                      item: follower,
                      selected,
                      deselect,
                    }) => (
                      <RowWrapper
                        selected={selected}
                        onClick={() => {
                          this.selectFollower(follower);
                          closePortal();
                        }}
                        key={follower.user_account_id}
                        onMouseEnter={deselect}
                      >
                        <Follower
                          profilepicture={follower.user_profile.profilepicture}
                          background
                          user_id={follower.user_account_id}
                        />
                        <Label>{follower.user_profile.first_name + ' ' + follower.user_profile.last_name}</Label>
                      </RowWrapper>
                    )}
                  />
                </SelectorWrapper>
              )}
              {isOpen && <Shade className="fade-in" />}
            </Fragment>
          )}
        </PortalWithState>
      </Row>
    );
  }
}

const ImageWrapper = styled('div')(({ theme, size, background }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  width: size === 'small' ? 28 : 40,
  height: size === 'small' ? 28 : 40,
  overflow: 'hidden',
  border: `${size === 'small' ? 2 : 3}px solid ${theme.neutral.light}`,
  background: background ? theme.neutral.light : '',
  position: 'relative',
  '&:first-of-type': {
    marginLeft: 0,
  },
  '&:last-child': {
    marginRight: 0,
  },
}));

const UserImage = styled('img')({
  width: '100%',
  height: '100%'
});

const TooltipWrapper = styled('div')({
  position: 'absolute',
  width: 2,
  left: '50%',
  top: -30,
  flexDirection: 'row',
  pointerEvents: 'none',
  display: 'none',
});

const Tooltip = styled('div')({
  background: '#000',
  borderRadius: 3,
  padding: '4px 5px',
  color: '#fff',
  fontSize: 11,
  transform: 'translateX(-50%)',
  position: 'relative',
  whiteSpace: 'nowrap',
  '&:before': {
    position: 'absolute',
    bottom: -4,
    left: 'calc(50% - 3px)',
    content: '" "',
    width: 8,
    height: 8,
    background: '#000',
    transform: 'rotate(45deg)',
  },
});

const FollowerWrapper = styled('div')(({ size }) => ({
  margin: size === 'small' ? 1 : 3,
  position: 'relative',
  '&:hover': {
    '& .DeleteButton': {
      display: 'flex',
    },
    '& .tooltip': {
      display: 'flex',
    },
  },
}));

export const Follower = ({
  user_id,
  profilepicture = '',
  name,
  size = '',
  background,
  tooltip = false,
  deleteable,
  onRequestDelete,
  onClick,
  tooltipPosition = 'top',
  className = '',
}) => (
  <FollowerWrapper size={size} className={className} onClick={onClick}>
    {name !== '' && tooltip && (
      <TooltipWrapper position={tooltipPosition} className="tooltip">
        <Tooltip position={tooltipPosition}>{name}</Tooltip>
      </TooltipWrapper>
    )}
    <ImageWrapper size={size} background={background}>
      {deleteable && (
        <DeleteButton
          className="DeleteButton"
          onClick={() => {
            onRequestDelete(user_id);
          }}
        >
          <CloseIcon />
        </DeleteButton>
      )}
      <ImageFallback
        fallback={<UserImage src={userPlaceholder} alt="follower" />}
      >
        {props => (
          <UserImage
            decoding="async"
            loading="lazy"
            {...props}
            src={
              profilepicture
                ? `${BASE_URL}/api/documentimages/userimage${profilepicture}`
                : userPlaceholder
            }
            alt="follower"
          />
        )}
      </ImageFallback>
    </ImageWrapper>
  </FollowerWrapper>
);

const DeleteButton = styled('button')({
  appearance: 'none',
  border: 'none',
  background: 'rgba(0,0,0,.7)',
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: '100%',
  color: '#fff',
  fontSize: 20,
  padding: 0,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'none',
});
