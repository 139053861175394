import React from 'react';
import styled from '@emotion/styled';
import { HistoryModal } from '../../components/History';
import CustomerLogModal from '../../components/CustomerLog';

const Wrapper = styled('div')({
  zIndex: -1,
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '100%',
  cursor: 'pointer',
  '&:hover': {
    '& .flr-quoted': {
      opacity: 0
    },
    '& .flr-started': {
      opacity: 0
    },
    '& .flr-designed': {
      opacity: 0
    },
    '& .view-history-button': {
      opacity: .9
    },
    '& .view-contacts-button' : {
      opacity: .9
    }
  }
});

export default class WorkorderFooter extends React.Component{
  state = {
    isOpen: false
  };

  toggleHistoryModal = () => {
    this.setState(state => ({isOpen: !state.isOpen}));
  };

  closeHistoryModal = () => {
    this.setState({isOpen: false});
  };

  toggleContactLog = () => {
    this.setState(state => ({contactLogOpen: !state.contactLogOpen}));
  };

  render(){
    return(
      <Wrapper>
        <HistoryModal
          isOpen={this.state.isOpen}
          onModalClose={this.closeHistoryModal}
          document_id={this.props.match.params.document_id}
        />
        <CustomerLogModal
          isOpen={this.state.contactLogOpen}
          onModalClose={this.toggleContactLog}
          document_id={this.props.match.params.document_id}
        />
        <div 
          onClick={this.toggleContactLog}
        >
          Contact<br/>
          Log
        </div>
        <div 
          onClick={this.toggleHistoryModal}
        >
          View<br/>
          History
        </div>
      </Wrapper>
    );
  }
}
