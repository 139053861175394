import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import { axios } from '@/api.js';
import UserIcon from '@components/Reusable/user/UserIcon.js';
import Stack from '@mui/material/Stack';

/**
 * UserList component
 * props:
 * open - required - Controls wether the component is open.
 * onClose - required - Allows control of the open prop
 * anchorEl - required - The element that the popover should anchor to
 * onUserSelect - required - Function to be called when user is selected. The user id will be supplied to the function
 * type - String Enum, must be ['both', 'production', 'office'] can be undefined
 */
export default function UserList(props) {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    axios.get('/users', {
      params: {
        type: props.type
      }
    })
      .then(res => {
        setUsers(res.data.results);
      });
  }, []);

  return(
    <Popover
      open={props.open}
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      PaperProps={{
        style: {
          maxHeight: '400px'
        }
      }}
    >
      <TextField
        variant="filled"
        label="Filter"
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      ></TextField>
      <Stack
        direction="column"
      >
        {users.filter((user) => {
          return (user.first_name + ' ' + user.last_name).toLowerCase().search(filter.toLowerCase()) !== -1;
        }).map((user) => {
          return (
            <UserInfo 
              key={user.user_account_id}
              direction="row"
              alignItems="center"
              onClick={() => { 
                props.onUserSelect(user); 
                props.onClose();
              }}
            >
              <UserIcon 
                profilepic={user.profilepicture}
              />
              <Stack
                direction="row"
                justifyContent="center"
                sx={{ width: '100%'}}
              >
                <span>{user.first_name + ' ' + user.last_name}</span>
              </Stack>
            </UserInfo>);
        })}
      </Stack>
    </Popover>
  );
}

const UserInfo = styled(Stack)(() => ({
  padding: '6px',
  '&:hover': {
    backgroundColor: 'lightgray'
  }
}));

